import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  StorePurchaseDetailsRecordState,
  storePurchaseDetailsRecordStateAdapter,
  StorePurchaseDetailsState,
} from './store-purchase-details.state';
import { Dictionary } from '@ngrx/entity';
import {
  getStorePurchaseKeyFromParams,
  StorePurchaseKey,
} from '../../../shared/models/store-purchase-key';
import { createStorePurchaseEntityKey } from '../../../shared/utilities/store-purchase-utilities';
import { selectAllStoreRecordStateEntities } from '../store/store.selectors';
import { StoreRecord } from '../../services/store/model/store-record';
import { StorePurchaseDetailsViewModel } from './models/store-purchase-details-view-model';
import { StorePurchaseDetailsTransformer } from './store-purchase-details-transformer';
import { selectFulfillmentType } from '../cart/cart.selectors';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { selectActiveCustomer } from '../session/session.selectors';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import { selectAllMaterialAvailabilityRecords } from '../material-availability/material-availability.selectors';
import { SessionActiveCustomer } from '../../services/session/models/session-record';
import { selectRouteParams } from '../router/router.selectors';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { MaterialInfoRecordState } from '../material-info/material-info.state';

const selectFeatureSelector = createFeatureSelector<StorePurchaseDetailsState>(
  'storePurchaseDetails'
);

export const selectStorePurchaseDetailsDictionary = createSelector(
  selectFeatureSelector,
  (state): Dictionary<StorePurchaseDetailsRecordState> =>
    storePurchaseDetailsRecordStateAdapter.getSelectors().selectEntities(state)
);

export const selectStorePurchaseDetailsRecord = (entityKey: string) => {
  return createSelector(
    selectStorePurchaseDetailsDictionary,
    (dictionary): StorePurchaseDetailsRecordState | undefined =>
      dictionary[entityKey]
  );
};

export const selectStorePurchaseDetailsViewModel = (
  storePurchaseHistoryKey: StorePurchaseKey
) => {
  const entityKey = createStorePurchaseEntityKey(storePurchaseHistoryKey);
  return createSelector(
    selectStorePurchaseDetailsDictionary,
    selectAllStoreRecordStateEntities,
    selectAllMaterialAvailabilityRecords,
    selectAllMaterialInfoRecordStates,
    selectFulfillmentType,
    selectActiveCustomer,
    (
      storePurchaseDetailsDictionary: Dictionary<StorePurchaseDetailsRecordState>,
      storeRecordDictionary: Dictionary<StoreRecord>,
      availabilityRecordStateDictionary: Dictionary<MaterialAvailabilityRecordState>,
      infoRecordStateDictionary: Dictionary<MaterialInfoRecordState>,
      fulfillmentType: FulfillmentType,
      sessionActiveCustomer: SessionActiveCustomer
    ): StorePurchaseDetailsViewModel =>
      StorePurchaseDetailsTransformer.getStorePurchaseDetailsViewModel(
        storePurchaseDetailsDictionary[entityKey],
        storeRecordDictionary,
        availabilityRecordStateDictionary,
        infoRecordStateDictionary,
        fulfillmentType,
        sessionActiveCustomer
      )
  );
};

export const selectStorePurchaseKeyFromUrl = createSelector(
  selectRouteParams,
  (params): StorePurchaseKey | undefined =>
    getStorePurchaseKeyFromParams(params)
);
