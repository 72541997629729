import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ProductDetailsMainComponent } from './product-details-main/product-details-main.component';
import { ProductRowModule } from '../product-row/product-row.module';
import { SharedModule } from '../shared/shared.module';
import { ProductAttributesModalComponent } from './product-attributes-modal/product-attributes-modal.component';
import { MaterialMovementComponent } from './material-movement/material-movement.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { MatIconModule } from '@angular/material/icon';
import { productDetailsResolver } from './product-details-resolver/product-details-resolver';
import { MaterialListModule } from '../material-list/material-list.module';
import { MygfsNutritionContainerComponent } from './mygfs-nutrition/mygfs-nutrition-container/mygfs-nutrition-container.component';
import { MygfsNutritionContentComponent } from './mygfs-nutrition/mygfs-nutrition-content/mygfs-nutrition-content.component';
import { MatSelectModule } from '@angular/material/select';
import { MygfsNutritionLabelComponent } from './mygfs-nutrition/mygfs-nutrition-content/mygfs-nutrition-label/mygfs-nutrition-label.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MygfsIngredientContentComponent } from './mygfs-nutrition/ingredients/ingredient-content/mygfs-ingredient-content.component';
import { MygfsIngredientListComponent } from './mygfs-nutrition/ingredients/mygfs-ingredient-list.component';
import { MygfsNutritionFactsComponent } from './mygfs-nutrition/mygfs-nutrition-facts/mygfs-nutrition-facts.component';
import { MygfsNutritionFactsContentComponent } from './mygfs-nutrition/mygfs-nutrition-facts/mygfs-nutrition-content/mygfs-nutrition-facts-content.component';
import { ProductSecondaryDetailsComponent } from './product-secondary-details/product-secondary-details.component';
import { ComplimentaryProductComponent } from './complimentary-product/complimentary-product.component';
import { MatChipsModule } from '@angular/material/chips';

const routes: Routes = [
  {
    path: ':materialNumber',
    component: ProductDetailsMainComponent,
    resolve: {
      detailsData: productDetailsResolver,
    },
  },
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    ProductRowModule,
    MatIconModule,
    MaterialListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatChipsModule,
  ],
  declarations: [
    ProductDetailsMainComponent,
    ProductAttributesModalComponent,
    MaterialMovementComponent,
    InventoryComponent,
    ImageCarouselComponent,
    MygfsNutritionContainerComponent,
    MygfsNutritionContentComponent,
    MygfsIngredientListComponent,
    MygfsIngredientContentComponent,
    MygfsNutritionLabelComponent,
    MygfsNutritionFactsComponent,
    MygfsNutritionFactsContentComponent,
    ProductSecondaryDetailsComponent,
    ComplimentaryProductComponent,
  ],
  exports: [MygfsNutritionFactsComponent],
})
export class ProductDetailsModule {}
