import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  IndicatorListsRecord,
  IndicatorListsResponse,
} from './models/indicator-lists-record';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';

import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IndicatorListsService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getIndicatorLists(): Observable<IndicatorListsRecord> {
    return this.httpClient
      .get<IndicatorListsResponse>(
        `${this.webBffService.getBff()}/api/v1/materials/indicators`
      )
      .pipe(map((response) => response?.indicatorLists));
  }
}
