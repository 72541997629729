import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MaterialWarning } from 'src/app/core/store/material-warning/material-warning';
import {
  MaterialLine,
  MaterialListStyle,
  MaterialOrderingInfo,
  MaterialOrderingTab,
  MaterialRowContext,
  MaterialRowSharedOptions,
} from 'src/app/core/store/material-row/models/material-row';
import { MatSelectChange } from '@angular/material/select';
import { MaterialPriceFacade } from '../../../../../core/store/material-price/material-price.facade';

@Component({
  selector: 'naoo-material-ordering-info',
  templateUrl: './material-ordering-info.component.html',
  styleUrls: ['./material-ordering-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialOrderingInfoComponent implements OnInit, OnDestroy {
  @Input() currentOrderingInfo: MaterialOrderingInfo;
  @Input() orderingInfos: MaterialOrderingInfo[];
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() materialWarning: MaterialWarning;
  @Input() materialOrderingTabs: MaterialOrderingTab[];
  @Input() isMobile: boolean;
  @Input() isInventoryLoaded: boolean;
  @Output() quantityInputChanged = new EventEmitter<MaterialLine>();
  @Output() viewCommodityDetailsClicked = new EventEmitter();
  @Output() tabChange = new EventEmitter<string>();

  @HostBinding('class.mobile') get mobile() {
    return this.isMobile;
  }

  quantity = 0;

  constructor(private priceFacade: MaterialPriceFacade) {}

  get isProductDetails(): boolean {
    return (
      MaterialRowContext.ProductDetails === this.materialRowOptions.context
    );
  }

  get contentStyleClass(): { [klass: string]: boolean } {
    const listStyle = this.materialRowOptions.materialListStyle;
    return {
      list: listStyle === MaterialListStyle.List,
      slim: listStyle === MaterialListStyle.Slim,
      grid: listStyle === MaterialListStyle.Grid,
      mobile: this.isMobile,
      'context-product-details': this.isProductDetails,
      complimentary: this.isComplimentaryProduct,
    };
  }

  get shouldUseInlineUom(): boolean {
    return (
      !!this.materialOrderingTabs &&
      !this.isMobile &&
      this.materialRowOptions.materialListStyle === MaterialListStyle.Slim &&
      this.materialOrderingTabs.length > 1
    );
  }

  get shouldDisplayLoyaltyPoints(): boolean {
    return (
      this.materialRowOptions.isLoyaltyProgramEligible &&
      this.materialRowOptions.materialListStyle === MaterialListStyle.List &&
      !this.isProductDetails &&
      !this.isComplimentaryProduct &&
      !this.materialRowOptions.isParEnabled
    );
  }

  get selectedIndex(): number {
    return this.materialOrderingTabs
      ?.map((x) => x.uom)
      .indexOf(this.currentOrderingInfo.uom);
  }

  get hasHiddenQuantity(): boolean {
    return (
      this.materialOrderingTabs.filter(
        (x) => x.uom !== this.currentOrderingInfo.uom
      )[0]?.quantity > 0
    );
  }

  get displayMobileHeader(): boolean {
    return (
      this.isMobile &&
      this.materialRowOptions.isParEnabled &&
      [MaterialListStyle.List, MaterialListStyle.Slim].includes(
        this.materialRowOptions.materialListStyle
      )
    );
  }

  get displayMaxAllocation(): boolean {
    return (
      !this.isMobile &&
      MaterialListStyle.Slim === this.materialRowOptions?.materialListStyle &&
      this.materialRowOptions?.materialCommodityInfo?.displayMaxAllocation
    );
  }

  get isComplimentaryProduct(): boolean {
    return (
      MaterialRowContext.ComplimentaryProduct ===
      this.materialRowOptions.context
    );
  }

  get shouldHidePortionPrice(): boolean {
    return (
      (MaterialRowContext.ProductDetails === this.materialRowOptions.context &&
        !this.currentOrderingInfo.pricingPortion) ||
      this.materialRowOptions?.isParEnabled ||
      this.materialRowOptions.materialListStyle !== MaterialListStyle.List
    );
  }

  get isNonEditableItem(): boolean {
    return (
      MaterialRowContext.NonEditableItem === this.materialRowOptions.context
    );
  }

  get totalEstimatedCost(): number {
    if (
      MaterialRowContext.OrderDetailsEditModal ===
      this.materialRowOptions.context
    ) {
      const orderingInfo = this.currentOrderingInfo;
      const price = orderingInfo?.isCatchWeight
        ? orderingInfo?.catchWeightAvg
        : orderingInfo?.price;
      return price ? price * this.quantity : 0;
    } else {
      return this.currentOrderingInfo?.totalEstimatedCost;
    }
  }

  get isPortionPricingGrid(): boolean {
    if (this.isProductDetails && !this.isMobile) {
      return true;
    }
    return this.materialRowOptions.materialListStyle === MaterialListStyle.Grid;
  }

  ngOnInit() {
    this.priceFacade.loadMaterialPrices([
      this.materialRowOptions.materialNumber,
    ]);
    this.priceFacade.watchMaterialPrices([
      this.materialRowOptions.materialNumber,
    ]);
  }

  ngOnDestroy() {
    this.priceFacade.unwatchMaterialPrice([
      this.materialRowOptions.materialNumber,
    ]);
  }

  isSelected(uom: string): boolean {
    return this.currentOrderingInfo.uom === uom;
  }

  changeTab(event$: MatSelectChange) {
    if (!this.isSelected(event$.value)) {
      this.tabChange.emit(event$.value);
    }
  }

  handleQuantityInputChanged(event: MaterialLine): void {
    this.quantity = event.quantity;
    this.quantityInputChanged.emit(event);
  }
}
