import { EntityState } from '@ngrx/entity/src/models';
import { createEntityAdapter } from '@ngrx/entity';
import { EntitlementMaterialDetail } from '../../services/entitlement/models/entitlement';

export interface EntitlementState {
  isLoading: boolean;
  overallocatedMaterialDetails: EntityState<OverallocatedMaterialDetail>;
  entitlementRecordState?: EntitlementRecordState;
  selectedMaterialNumber?: string;
}

export interface EntitlementRecordState {
  beginEffectiveDate: Date;
  endEffectiveDate: Date;
  materialDetailRecords: EntityState<EntitlementMaterialDetail>;
}

export interface OverallocatedMaterialDetail {
  materialNumber: string;
  maxCommodityCasesAmount: number;
}

export const materialDetailAdapter = createEntityAdapter<EntitlementMaterialDetail>(
  {
    selectId: (materialDetailRecord) => materialDetailRecord.materialNumber,
  }
);

export const initialMaterialDetailRecordsState: EntityState<EntitlementMaterialDetail> = materialDetailAdapter.getInitialState();

export const overallocatedMaterialDetailAdapter = createEntityAdapter<OverallocatedMaterialDetail>(
  {
    selectId: (overallocatedMaterialDetailRecord) =>
      overallocatedMaterialDetailRecord.materialNumber,
  }
);

/* eslint-disable max-len */
export const initialOverallocatedMaterialDetailsState: EntityState<OverallocatedMaterialDetail> = overallocatedMaterialDetailAdapter.getInitialState();

export const initialEntitlementState: EntitlementState = {
  isLoading: true,
  entitlementRecordState: undefined,
  selectedMaterialNumber: undefined,
  overallocatedMaterialDetails: initialOverallocatedMaterialDetailsState,
};
