import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { InventoryAvailabilityRecord } from '../../services/inventory-availability/models/inventory-availability-record';

export interface InventoryAvailabilityState
  extends EntityState<InventoryAvailabilityRecordState> {}

export interface InventoryAvailabilityRecordState {
  materialNumber: string;
  status: InventoryAvailabilityRecordStatus;
  record?: InventoryAvailabilityRecord;
}

export enum InventoryAvailabilityRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const inventoryAvailabilityAdapter = createEntityAdapter<InventoryAvailabilityRecordState>(
  {
    selectId: (state) => state.materialNumber,
  }
);

export const initialInventoryAvailabilityState = inventoryAvailabilityAdapter.getInitialState();
