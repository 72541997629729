import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NutritionBlock } from 'src/app/core/store/mygfs-nutrition/models/mygfs-nutrition';

@Component({
  selector: 'naoo-mygfs-nutrition-content',
  templateUrl: './mygfs-nutrition-content.component.html',
  styleUrls: ['./mygfs-nutrition-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MygfsNutritionContentComponent {
  @Input() blockGroups: NutritionBlock[][];

  parsedAmount(amount: number, uom: string): string {
    if (!amount) {
      return 'n/a';
    }
    const result: string = amount ? amount.toString() : '0';
    if (uom) {
      return result + ' ' + uom;
    }
    return result;
  }
}
