import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { OrderGuideChangeItem } from '../../../../core/store/order-guide-change-history/order-guide-change-history.facade';
import { NaooStringDefaulter } from '../../../../shared/string-defaulter/naoo-string-defaulter';
import { OrderGuideChangeItemStatus } from '../../../../core/services/order-guide-change-history/models/order-guide-change-history-record';
import { MaterialGuideMatch } from 'src/app/core/store/order-guide-change-history/models/order-guide-change-history';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';

@Component({
  selector: 'naoo-order-guide-changes-row',
  templateUrl: './order-guide-changes-row.component.html',
  styleUrls: ['./order-guide-changes-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderGuideChangesRowComponent implements OnInit {
  private readonly productUnavailableDescription = 'Item Details Unavailable';
  readonly productDetailsPath = NaooConstants.PRODUCT_DETAILS_PATH;

  @Input() changedItem: OrderGuideChangeItem;
  @Input() isOdd: boolean;
  @Input() isUserOnline = true;
  @Input() materialGuideMatch: MaterialGuideMatch;
  @Output() modalClosed = new EventEmitter<void>();

  isProductAvailable: boolean;
  isOpen = false;

  constructor(
    private stringDefaulter: NaooStringDefaulter,
    private localizationService: LocalizationService
  ) {}

  get descriptionClass(): string {
    if (this.isProductAvailable && this.isUserOnline) {
      return 'active-link';
    } else if (this.isProductAvailable && !this.isUserOnline) {
      return 'disabled';
    } else {
      return 'unavailable';
    }
  }

  get ariaRowDescription(): string {
    if (!this.materialGuideMatch) {
      return '';
    }
    const translatedDescription = this.stringDefaulter.getCurrentLanguageOnlyString(
      this.changedItem.description,
      this.localizationService.currentLanguage
    );
    return `${translatedDescription} #${
      this.changedItem.materialNumber
    } ${this.localizationService.instant(this.statusTranslationKey)}`;
  }

  get statusTranslationKey(): string {
    return this.changedItem.status === OrderGuideChangeItemStatus.Added
      ? 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.ADDED_ITEM_MESSAGE'
      : 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.REMOVED_ITEM_MESSAGE';
  }

  ngOnInit() {
    this.isProductAvailable =
      this.changedItem.description.en !== this.productUnavailableDescription;
  }

  closeModal(): void {
    this.modalClosed.emit();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
