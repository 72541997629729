import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CartRecord, CartUpdateRequest } from './models/cart-record';

@Injectable({ providedIn: 'root' })
export class CartService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService
  ) {}

  retrieveOrCreateActiveCart(): Observable<CartRecord> {
    return this.httpClient.post<CartRecord>(
      this.webBffService.getBff() + `/api/v8/cart`,
      null
    );
  }

  updateCart(
    cartId: string,
    cartData: CartUpdateRequest
  ): Observable<CartRecord> {
    return this.httpClient.put<CartRecord>(
      this.webBffService.getBff() + `/api/v7/cart/${cartId}`,
      cartData
    );
  }
}
