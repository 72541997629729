import {
  initialMaterialAdditionalInfoState,
  materialAdditionalInfoAdapter,
  MaterialAdditionalInfoRecordState,
  MaterialAdditionalInfoRecordStatus,
  MaterialAdditionalInfoState,
} from './material-additional-info.state';
import { MaterialAdditionalInfoActions } from './material-additional-info.actions';
import { Update } from '@ngrx/entity';
import { MaterialAdditionalInfoRecord } from '../../services/material-additional-info/models/material-additional-infos-record';
import { createReducer, on } from '@ngrx/store';

export const materialAdditionalInfoReducer = createReducer(
  initialMaterialAdditionalInfoState,
  on(
    MaterialAdditionalInfoActions.loadMaterialAdditionalInfo,
    (state, action): MaterialAdditionalInfoState =>
      loadMaterialAdditionalInfo(state, action.materialNumbers)
  ),
  on(
    MaterialAdditionalInfoActions.getMaterialAdditionalInfo,
    (state, action): MaterialAdditionalInfoState =>
      getMaterialAdditionalInfo(state, action.materialNumbers)
  ),
  on(
    MaterialAdditionalInfoActions.getMaterialAdditionalInfoSuccess,
    (state, action): MaterialAdditionalInfoState =>
      getMaterialAdditionalInfoSuccess(state, action.records)
  ),
  on(
    MaterialAdditionalInfoActions.getMaterialAdditionalInfoError,
    (state, action): MaterialAdditionalInfoState =>
      getMaterialAdditionalInfoError(state, action.materialNumbers)
  ),
  on(
    MaterialAdditionalInfoActions.clearMaterialAdditionalInfo,
    (): MaterialAdditionalInfoState => initialMaterialAdditionalInfoState
  )
);

function loadMaterialAdditionalInfo(
  state: MaterialAdditionalInfoState,
  materialNumbers: string[]
): MaterialAdditionalInfoState {
  const upsertedEntities: MaterialAdditionalInfoRecordState[] = materialNumbers
    .filter(
      (materialNumber) =>
        !state.entities[materialNumber] ||
        state.entities[materialNumber].status ===
          MaterialAdditionalInfoRecordStatus.Error
    )
    .map((materialNumber) => {
      return {
        materialNumber,
        status: MaterialAdditionalInfoRecordStatus.Queued,
        record: undefined,
      };
    });
  return materialAdditionalInfoAdapter.upsertMany(upsertedEntities, state);
}

function getMaterialAdditionalInfo(
  state: MaterialAdditionalInfoState,
  materialNumbers: string[]
): MaterialAdditionalInfoState {
  const queuedEntities: Update<MaterialAdditionalInfoRecordState>[] = materialNumbers
    .filter(
      (materialNumber) =>
        state.entities[materialNumber].status ===
        MaterialAdditionalInfoRecordStatus.Queued
    )
    .map((materialNumber) => {
      return {
        id: materialNumber,
        changes: {
          status: MaterialAdditionalInfoRecordStatus.Requested,
        },
      };
    });
  return materialAdditionalInfoAdapter.updateMany(queuedEntities, state);
}

function getMaterialAdditionalInfoSuccess(
  state: MaterialAdditionalInfoState,
  materialAdditionalInfoRecords: MaterialAdditionalInfoRecord[]
): MaterialAdditionalInfoState {
  const updatedMaterialAdditionalInfoRecordStates: Update<MaterialAdditionalInfoRecordState>[] = materialAdditionalInfoRecords.map(
    (materialAdditionalInfoRecord) => {
      return {
        id: materialAdditionalInfoRecord.materialNumber,
        changes: {
          record: materialAdditionalInfoRecord,
          status: MaterialAdditionalInfoRecordStatus.Success,
        },
      };
    }
  );
  return materialAdditionalInfoAdapter.updateMany(
    updatedMaterialAdditionalInfoRecordStates,
    state
  );
}

function getMaterialAdditionalInfoError(
  state: MaterialAdditionalInfoState,
  materialNumbers: string[]
): MaterialAdditionalInfoState {
  const updatedMaterialRecordStates: Update<MaterialAdditionalInfoRecordState>[] = materialNumbers.map(
    (materialNumber) => {
      return {
        id: materialNumber,
        changes: {
          status: MaterialAdditionalInfoRecordStatus.Error,
        },
      };
    }
  );
  return materialAdditionalInfoAdapter.updateMany(
    updatedMaterialRecordStates,
    state
  );
}
