import { Store } from '@ngrx/store';
import { LastOrderedActions } from './last-ordered.actions';
import { Observable } from 'rxjs';
import {
  selectLastOrderedRecordStates,
  selectLoadedLastOrderDate,
} from './last-ordered.selectors';
import { filter, map } from 'rxjs/operators';
import { LastOrderedRecordState } from './last-ordered.state';
import { Injectable } from '@angular/core';
import { LastOrderedRecord } from '../../services/last-ordered/models/last-ordered-records';
import { hasLastOrderedFinishedLoading } from './last-ordered.util';

export interface LastOrderDateInfos {
  [materialNumber: string]: string;
}

@Injectable({ providedIn: 'root' })
export class LastOrderedFacade {
  constructor(private store: Store) {}

  loadLastOrdered(materialNumbers: string[]) {
    this.store.dispatch(LastOrderedActions.loadLastOrdered(materialNumbers));
  }

  getLoadedLastOrderDate(materialNumber: string): Observable<string> {
    return this.store
      .select(selectLoadedLastOrderDate(materialNumber))
      .pipe(filter((lastOrderDate) => lastOrderDate !== null));
  }

  getLoadedLastOrderDateInfos(
    materialNumbers: string[]
  ): Observable<LastOrderDateInfos> {
    return this.store
      .select(selectLastOrderedRecordStates(materialNumbers))
      .pipe(
        filter((recordStates: LastOrderedRecordState[]) =>
          recordStates.every((recordState) =>
            hasLastOrderedFinishedLoading(recordState)
          )
        ),
        map((recordStates: LastOrderedRecordState[]) =>
          recordStates
            .map((recordState: LastOrderedRecordState) => recordState.record)
            .filter((record: LastOrderedRecord) => !!record)
        ),
        map((records: LastOrderedRecord[]) =>
          this.convertToLastOrderDateInfos(records)
        )
      );
  }

  clearLastOrdered() {
    this.store.dispatch(LastOrderedActions.clearLastOrdered());
  }

  private convertToLastOrderDateInfos(
    records: LastOrderedRecord[]
  ): LastOrderDateInfos {
    const result: LastOrderDateInfos = {};
    records.forEach((record) => {
      result[record.materialNumber] = record.lastOrderDate;
    });

    return result;
  }
}
