import {
  storeInitialState,
  StoreState,
  storeStateAdapter,
  StoreStateStatus,
} from './store.state';
import { createReducer, on } from '@ngrx/store';
import { StoreActions } from './store.actions';
import { StoreRecord } from '../../services/store/model/store-record';

export const storeReducer = createReducer(
  storeInitialState,
  on(StoreActions.loadStores, (state): StoreState => loadStores(state)),
  on(StoreActions.getStores, (state): StoreState => getStores(state)),
  on(
    StoreActions.getStoresSuccess,
    (state, action): StoreState => getStoreSuccess(state, action)
  ),
  on(StoreActions.getStoresFailure, (): StoreState => getStoresFailure()),
  on(StoreActions.refreshStores, (): StoreState => storeInitialState)
);

function loadStores(state: StoreState): StoreState {
  if (!state.status || StoreStateStatus.Error === state.status) {
    return {
      ...state,
      status: StoreStateStatus.Queued,
    };
  }
  return state;
}

function getStores(state: StoreState): StoreState {
  if (StoreStateStatus.Queued === state.status) {
    return {
      ...state,
      status: StoreStateStatus.Requested,
    };
  }
  return state;
}

function getStoreSuccess(
  state: StoreState,
  action: { stores: StoreRecord[] }
): StoreState {
  return {
    ...state,
    records: storeStateAdapter.setAll(
      action.stores.map((store) => store),
      state.records
    ),
    status: StoreStateStatus.Success,
  };
}

function getStoresFailure(): StoreState {
  return {
    records: storeStateAdapter.getInitialState(),
    status: StoreStateStatus.Error,
  };
}
