import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OfflineModeService } from '../../services/offline-mode/offline-mode.service';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { OfflineModeActions } from './offline-mode.actions';
import { CartActions } from '../cart/cart.actions';
import { selectSession } from '../session/session.selectors';
import { selectIsOffline } from './offline-mode.selectors';
import * as LogRocket from 'logrocket';

@Injectable()
export class OfflineModeEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private offlineService: OfflineModeService
  ) {}

  startOfflineMode$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OfflineModeActions.startOfflineModeCheck),
      switchMap(() => this.store.select(selectIsOffline)),
      switchMap((isCurrentlyOffline) =>
        this.offlineService
          .isOffline(isCurrentlyOffline)
          .pipe(startWith(isCurrentlyOffline))
      ),
      distinctUntilChanged(),
      // Application guaranteed to start in online mode, skip this emission
      // as we already have a startsWith(false) in updateOfflineMode$ below
      skip(1),
      tap((isOffline) =>
        LogRocket.track(isOffline ? 'offline_mode_enter' : 'offline_mode_leave')
      ),
      map((isOffline) => OfflineModeActions.updateOfflineMode(isOffline)),
      takeUntil(
        this.actions$.pipe(ofType(OfflineModeActions.stopOfflineModeCheck))
      )
    );
  });

  updateOfflineMode$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OfflineModeActions.updateOfflineMode),
      map((action) => action.isOffline), // distinctUntilChanged needs a boolean because it uses === to compare.
      startWith(false), // We know the application is online at the start
      distinctUntilChanged(), // Only need to emit the first value if offline mode switches.
      withLatestFrom(this.store.select(selectSession)), // eslint-disable-line @ngrx/prefer-concat-latest-from
      filter(([isOffline, session]) => !isOffline && !!session?.activeCustomer),
      map(() => CartActions.syncOfflineCart())
    );
  });
}
