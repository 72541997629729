import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import {
  MaterialOrderingInfo,
  MaterialRowSharedOptions,
} from '../../../../../../core/store/material-row/models/material-row';
import { DOCUMENT } from '@angular/common';
import { CustomGuideFacade } from '../../../../../../core/store/custom-guide/custom-guide.facade';
import { DecimalUtilities } from '../../../../../../shared/utilities/decimal-utilities';

@Component({
  selector: 'naoo-material-par-quantity-input',
  templateUrl: './material-par-quantity-input.component.html',
  styleUrls: ['./material-par-quantity-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialParQuantityInputComponent {
  @Input() currentOrderingInfo: MaterialOrderingInfo;
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() isMobile: boolean;

  @ViewChild('inventoryInput', { static: true }) inventoryElement: ElementRef;

  private inputs: HTMLInputElement[];
  private previousValue?: string;

  constructor(
    private customGuideFacade: CustomGuideFacade,
    private decimalUtilities: DecimalUtilities,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  get inventoryInputId(): string {
    return `inventory-${this.materialRowOptions.materialNumber}-${this.currentOrderingInfo.uom}`;
  }

  get inventoryInputValue(): string {
    return !!this.currentOrderingInfo.parLine &&
      !!this.currentOrderingInfo.parLine.inventoryQuantity
      ? this.currentOrderingInfo.parLine.inventoryQuantity.toString()
      : '';
  }

  onBlur() {
    const newQuantity = this.decimalUtilities.parseValue(
      this.inventoryInputElementValue()
    );
    const newValue = newQuantity ? newQuantity.toString() : '';
    this.inventoryInputElement().value = this.decimalUtilities.getLocalizedValue(
      newValue
    );

    this.customGuideFacade.updateInventoryQuantity(
      this.materialRowOptions.customGuideId,
      this.materialRowOptions.materialNumber,
      this.currentOrderingInfo.uom,
      newQuantity
    );
  }

  onFocus() {
    this.selectAll();
  }

  selectAll() {
    this.inventoryInputElement().select();
    this.inventoryInputElement().setSelectionRange(
      0,
      this.inventoryInputElementValue().length
    );
  }

  onKeyDown() {
    this.previousValue = this.inventoryInputElementValue();
  }

  onInput() {
    const newValue = this.inventoryInputElementValue();
    if (newValue !== '' && !this.decimalUtilities.validateValue(newValue)) {
      this.inventoryInputElement().value = this.previousValue || '';
    }
  }

  suppressContextMenu(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  nextInput() {
    this.indexInputs();
    const nextIndex = this.inputs[
      this.inputs.findIndex((x) => x.id === this.inventoryInputId) + 1
    ];
    if (nextIndex) {
      nextIndex.focus();
    }
  }

  previousInput() {
    this.indexInputs();
    const prevIndex = this.inputs[
      this.inputs.findIndex((x) => x.id === this.inventoryInputId) - 1
    ];
    if (prevIndex) {
      prevIndex.focus();
    }
  }

  private inventoryInputElement(): HTMLInputElement {
    return this.inventoryElement.nativeElement as HTMLInputElement;
  }

  private inventoryInputElementValue(): string {
    return this.inventoryInputElement().value as string;
  }

  private indexInputs(): void {
    this.inputs = Array.from(
      this._document.getElementsByClassName('inventory-input')
    ).filter((x) => {
      return x.getBoundingClientRect().height > 0;
    }) as HTMLInputElement[];
  }
}
