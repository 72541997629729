import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { mergeMap, Observable, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MaterialRecommendationsService } from '../../../shared/services/material-recommendations/material-recommendations.service';
import { SmartCartRecommendationsActions } from './smart-cart-recommendations.actions';
import { catchError } from 'rxjs/operators';
import { concatLatestFrom } from '@ngrx/operators';
import { selectCartMaterialNumbers } from '../cart/cart.selectors';
import { MaterialPriceActions } from '../material-price/material-price.actions';
import { selectRemainingCriticalItemMaterials } from '../critical-items/critical-items.selector';

@Injectable()
export class SmartCartRecommendationsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private materialRecommendationsService: MaterialRecommendationsService
  ) {}

  loadSmartCartRecommendations$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SmartCartRecommendationsActions.loadSmartCartRecommendations),
      concatLatestFrom(() => [
        this.store.select(selectCartMaterialNumbers),
        this.store.select(selectRemainingCriticalItemMaterials),
      ]),
      mergeMap(([_, cartMaterialNumbers, criticalItemMaterials]) => {
        const materials = [...cartMaterialNumbers, ...criticalItemMaterials];

        return !materials.length
          ? []
          : this.materialRecommendationsService
              .getSmartCartRecommendations({
                materialNumbers: materials,
              })
              .pipe(
                mergeMap((recommendations) => {
                  const materialNumbers = recommendations.map(
                    (recommendation) => recommendation.product
                  );
                  return [
                    MaterialPriceActions.loadMaterialPrices(materialNumbers),
                    SmartCartRecommendationsActions.getSmartCartRecommendationsSuccess(
                      recommendations
                    ),
                  ];
                }),
                catchError(() =>
                  of(
                    SmartCartRecommendationsActions.getSmartCartRecommendationsError
                  )
                )
              );
      })
    );
  });
}
