import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NaooConstants } from '../../shared/NaooConstants';
import { AutocompleteService } from '../../shared/services/autocomplete/autocomplete.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SearchConstants } from '../../shared/models/search/SearchConstants';
import { SearchFacade } from 'src/app/core/store/search/search.facade';

@Component({
  selector: 'naoo-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit, AfterViewInit {
  @ViewChild('searchText', { static: true }) searchBox: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: true })
  autocomplete: MatAutocompleteTrigger;
  @Input() isMobile: boolean;
  @Input() isDisabled: boolean;
  @Output() closeMobileSearchModalEmitter = new EventEmitter<boolean>();
  inputText = '';
  placeholderText: string;
  ariaText: string;
  options: string[] = [];
  MAX_INPUT_LENGTH = NaooConstants.MAX_INPUT_LENGTH;
  hideSearchText = false;

  constructor(
    private autocompleteService: AutocompleteService,
    private changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private searchFacade: SearchFacade
  ) {}

  ngOnInit(): void {
    this.updateAriaLabelAndPlaceholderText();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.hideSearchText =
        params[SearchConstants.paramHideSearchText] !== undefined;
    });
  }

  performSearch(searchText: string) {
    if (searchText && searchText.trim()) {
      searchText = searchText.trim();
      if (searchText.length > NaooConstants.MAX_INPUT_LENGTH) {
        searchText = searchText.substr(0, NaooConstants.MAX_INPUT_LENGTH);
      }

      this.searchFacade.submitSearch(searchText);

      this.closeMobileSearchModalEmitter.emit(true);
      this.autocomplete.closePanel();
      this.clearAutocomplete();
    }
  }

  updateAriaLabelAndPlaceholderText() {
    // Screen-reader reads the placeholder text when the input is empty. If not it reads what
    // you wrote in the input, the aria-label attribute and the placeholder text. For that reason
    // we set the placeholder as empty when there is some text on the input.
    if (this.inputText && this.inputText.trim().length > 0) {
      this.ariaText = 'SEARCH.TITLE';
      this.placeholderText = '';
    } else {
      this.placeholderText = this.isMobile
        ? 'SEARCH.PLACEHOLDER_MOBILE'
        : 'SEARCH.PLACEHOLDER';
      this.ariaText = this.placeholderText;
    }
    this.changeDetector.markForCheck();
  }

  getAutocompleteSuggestions(searchText: string) {
    if (searchText.length > 0) {
      this.autocompleteService.getAutocompleteSuggestions(searchText).subscribe(
        (suggestions: string[]) => {
          if (suggestions.length > 5 && this.isMobile) {
            this.options = suggestions.slice(0, 5);
          } else {
            this.options = suggestions;
          }
          this.changeDetector.markForCheck();
        },
        () => {
          // do nothing
        }
      );
    } else {
      this.clearAutocomplete();
    }
  }

  ngAfterViewInit() {
    if (this.isMobile) {
      this.clearAndFocus();
    }
  }

  clear() {
    this.inputText = '';
    this.updateAriaLabelAndPlaceholderText();
    this.clearAutocomplete();
  }

  clearAndFocus() {
    this.clear();
    (<HTMLInputElement>this.searchBox.nativeElement).focus();
  }

  setText(text: string | null) {
    if (text && !this.hideSearchText) {
      this.inputText = text;
    } else {
      this.inputText = '';
    }

    this.updateAriaLabelAndPlaceholderText();
    this.changeDetector.markForCheck();
  }

  private clearAutocomplete() {
    this.options = [];
    this.changeDetector.markForCheck();
  }
}
