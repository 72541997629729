import { DecimalPipe } from '@angular/common';
import {
  PipeTransform,
  OnDestroy,
  ChangeDetectorRef,
  Pipe,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalizationService } from '../services/translation/localization.service';

@Pipe({
  name: 'naoodecimal',
  pure: false,
})
export class NaooDecimalPipe
  extends DecimalPipe
  implements PipeTransform, OnDestroy {
  private destroyed$ = new Subject<void>();
  constructor(
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef
  ) {
    super(localizationService.currentLocale);
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(value: null | undefined, digitsInfo?: string): null;
  transform(value: number | string, digitsInfo?: string): string | null;
  transform(value: any, digitsInfo?: string): string | null {
    return super.transform(
      value,
      digitsInfo,
      this.localizationService.currentLocale
    );
  }
}
