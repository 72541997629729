import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import { Observable } from 'rxjs';
import { MultipleCartsRecord } from './models/multiple-carts-record';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MultipleCartsService {
  constructor(
    private naooHttpClient: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getSavedCarts(): Observable<MultipleCartsRecord> {
    return this.naooHttpClient.get<MultipleCartsRecord>(
      this.webBffService.getBff() + `/api/v4/saved-carts`
    );
  }

  saveActiveCart(cartId: string): Observable<MultipleCartsRecord> {
    return this.naooHttpClient.post<MultipleCartsRecord>(
      this.webBffService.getBff() + `/api/v4/cart/${cartId}/save`,
      null
    );
  }

  submitCartForApproval(cartId: string): Observable<MultipleCartsRecord> {
    return this.naooHttpClient.post<MultipleCartsRecord>(
      this.webBffService.getBff() +
        `/api/v4/cart/${cartId}/submit-for-approval`,
      null
    );
  }

  activateSavedCart(
    cartId: string,
    shouldSaveActiveCart: boolean
  ): Observable<MultipleCartsRecord> {
    return this.naooHttpClient.post<MultipleCartsRecord>(
      this.webBffService.getBff() + `/api/v4/cart/${cartId}/activate`,
      { shouldSaveActiveCart }
    );
  }

  deleteSavedCart(cartId: string): Observable<MultipleCartsRecord> {
    return this.naooHttpClient.post<MultipleCartsRecord>(
      this.webBffService.getBff() + `/api/v4/cart/${cartId}/delete`,
      null
    );
  }
}
