import {
  ChangeDetectionStrategy,
  Component,
  Input,
  SecurityContext,
} from '@angular/core';
import {
  NutritionInfo,
  NutritionLabel,
} from 'src/app/core/store/mygfs-nutrition/models/mygfs-nutrition';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'naoo-ingredient-content',
  templateUrl: './mygfs-ingredient-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MygfsIngredientContentComponent {
  constructor(private sanitizer: DomSanitizer) {}

  ingredientsHtml: string;
  selectedInfo: NutritionInfo;

  @Input()
  set nutritionLabel(nutritionLabel: NutritionLabel) {
    this.selectedInfo = nutritionLabel?.nutritionInfos?.[0];
    this.ingredientsHtml = this.buildIngredientsHtml();
  }

  private buildIngredientsHtml(): string {
    if (!this.selectedInfo?.ingredients) {
      return '';
    }
    let ingredients = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.selectedInfo.ingredients
    );
    for (const [
      rawUrl,
      safeUrl,
    ] of this.selectedInfo.ingredientsUrlMap.entries()) {
      ingredients = ingredients.replace(
        rawUrl,
        MygfsIngredientContentComponent.linkTo(safeUrl, rawUrl)
      );
    }
    return ingredients;
  }

  private static linkTo(safeUrl: string, rawUrl: string): string {
    return `<a href="${safeUrl}" rel="noopener noreferrer" target="_blank">${rawUrl}</a>`;
  }
}
