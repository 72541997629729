import { Injectable } from '@angular/core';
import { WebBffService } from '../web-bff/web-bff.service';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CatalogResponse } from '../../models/catalog/catalog-response';
import { CategoryImageSources } from '../../models/catalog/category-image-sources';
import { NaooConstants } from '../../NaooConstants';
import { catchError, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  NAOOErrorCode,
  NaooErrorUtils,
} from '../../error-handler/NaooErrorUtils';

@Injectable({ providedIn: 'root' })
export class CatalogService {
  public static readonly CATALOG_API = '/api/v6/catalog/';
  private catalogCache = new Map<string, BehaviorSubject<CatalogResponse>>();

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
    private router: Router
  ) {}

  /** Load and cache a category tree from, and including, the given node. A node in the category tree
   * is comprised of an id and level. */
  loadCategoryTree(categoryKey: string): void {
    this.getCategoryKey(categoryKey).pipe(take(1)).subscribe();
  }

  getCategoryKey(categoryKey: string): Observable<CatalogResponse> {
    if (this.catalogCache.get(categoryKey) != null) {
      return this.catalogCache.get(categoryKey).asObservable();
    }

    return this.httpClient
      .get<CatalogResponse>(
        this.webBffService.getBff() +
          CatalogService.CATALOG_API +
          `${categoryKey}`
      )
      .pipe(
        catchError((error) => this.handleErrorAndNavigate(error)),
        tap((category) => this.setInCache(categoryKey, category))
      );
  }

  private handleErrorAndNavigate(error: HttpErrorResponse): Observable<never> {
    if (
      NaooErrorUtils.getNaooError(error).code ===
      NAOOErrorCode.INVALID_CATEGORY.toString()
    ) {
      this.router.navigate([NaooConstants.CATEGORIES_PATH]);
      return EMPTY;
    }
    return throwError(() => error);
  }

  observeCategoryResponse(
    categoryKey: string
  ): BehaviorSubject<CatalogResponse> {
    const key = categoryKey;
    let subject = this.catalogCache.get(key);
    if (subject == null) {
      subject = new BehaviorSubject<CatalogResponse>(null);
      this.catalogCache.set(key, subject);
    }
    return this.catalogCache.get(key);
  }

  buildImageSrcUrls(
    category: CatalogResponse,
    fileExtension: string
  ): CategoryImageSources {
    const definedImageSizes = ['1x', '2x', '3x'];
    const urlSet: Array<string> = [];
    definedImageSizes.forEach((size) => {
      urlSet.push(
        `${NaooConstants.CategoryImagePath}${category.categoryKey}@${size}.${fileExtension} ${size}`
      );
    });
    const catalogImageSrcSet = urlSet.join(', ');
    const catalogImageSrc = `${NaooConstants.CategoryImagePath}${category.categoryKey}@1x.${fileExtension}`;

    return {
      srcset: catalogImageSrcSet,
      src: catalogImageSrc,
    };
  }

  clearCache(): void {
    this.catalogCache = new Map<string, BehaviorSubject<CatalogResponse>>();
  }

  private setInCache(categoryKey: string, category: CatalogResponse) {
    if (category) {
      const key = categoryKey;
      let subject = this.catalogCache.get(key);
      if (subject == null) {
        subject = new BehaviorSubject<CatalogResponse>(category);
      }
      subject.next(category);
      this.catalogCache.set(key, subject);
    }
  }
}
