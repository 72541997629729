import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { StoreRecord } from '../../core/services/store/model/store-record';
import {
  getAddressLineTwo,
  getFormattedPhoneNumber,
} from '../modals/order-method-modal/util/order-method-modal.utils';
import { MatTooltip } from '@angular/material/tooltip';
import {
  getOffsetDifferenceInMinutes,
  getStoreHoursDisplayForDayOfWeek,
} from '../../core/store/store/store.utilities';
import moment from 'moment/moment';
import { LocalizationService } from '../services/translation/localization.service';
import { Moment } from 'moment';
import { StoreDisplayContext } from './store-display';

@Component({
  selector: 'naoo-store-display',
  templateUrl: './store-display.component.html',
  styleUrls: ['./store-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreDisplayComponent {
  @Input() storeRecord: StoreRecord;
  @Input() customerTimeZone: string;
  @Input() isMobileWidth: boolean;
  @Input() isMobileHeader: boolean;
  @Input() currentMoment: Moment = moment();
  @Input() context: StoreDisplayContext =
    StoreDisplayContext.OrderMethodSelection;

  @ViewChild('tooltip') manualTooltip: MatTooltip;

  constructor(private localizationService: LocalizationService) {}

  get isNonBoldedStoreName(): boolean {
    return StoreDisplayContext.PurchaseHistory === this.context;
  }

  get formattedPhoneNumber(): string {
    return getFormattedPhoneNumber(this.storeRecord?.phoneNumber);
  }

  get addressLineTwo(): string {
    return getAddressLineTwo(this.storeRecord?.address);
  }

  get tooltipText(): string {
    if (!this.storeRecord || !this.customerTimeZone) {
      return '';
    }
    const offsetInMinutes = getOffsetDifferenceInMinutes(
      this.customerTimeZone,
      this.storeRecord.timezone
    );

    let tooltip = '';
    if (this.isMobileHeader) {
      tooltip = tooltip.concat(
        `${this.formattedPhoneNumber}\n\n${this.storeRecord?.address.address1}\n${this.addressLineTwo}\n\n`
      );
    }

    tooltip = tooltip.concat(
      `${this.localizationService.instant(
        'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.OPEN'
      )}\n`
    );

    for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
      const storeHoursForDay = getStoreHoursDisplayForDayOfWeek(
        dayOfWeek,
        this.storeRecord.hours,
        this.localizationService.currentLocale,
        offsetInMinutes
      );

      tooltip = tooltip.concat(
        `${storeHoursForDay} ${this.getLocalizedTimezoneAbbr()}\n`
      );
    }
    return tooltip;
  }

  private getLocalizedTimezoneAbbr() {
    return this.localizationService.instant(
      `TIMEZONES.${this.currentMoment.tz(this.customerTimeZone).zoneAbbr()}`
    );
  }
}
