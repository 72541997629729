import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MaterialComparisonState } from './material-comparison.state';
import { selectCurrentPathName } from '../router/router.selectors';
import { NaooConstants } from '../../../shared/NaooConstants';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';

export const maximumMaterialComparisons = 5;

export const selectMaterialComparisonState = createFeatureSelector<MaterialComparisonState>(
  'materialComparison'
);

export const selectIsMaterialComparisonCollapsed = createSelector(
  selectMaterialComparisonState,
  (materialComparisonState) => materialComparisonState.isCollapsed
);

export const selectMaterialComparisonNumbers = createSelector(
  selectMaterialComparisonState,
  (materialComparisonState): string[] =>
    materialComparisonState.materialNumbers || []
);

export const selectIsMaterialSelectedForComparing = (materialNumber: string) =>
  createSelector(selectMaterialComparisonNumbers, (materialNumbers) =>
    materialNumbers.includes(materialNumber)
  );

export const selectCanCompareMoreMaterials = createSelector(
  selectMaterialComparisonNumbers,
  (materialNumbers) => materialNumbers.length < maximumMaterialComparisons
);

export const selectShouldShowComparingDocket = createSelector(
  selectMaterialComparisonState,
  selectCurrentPathName,
  selectIsOffline,
  (
    materialComparisonState: MaterialComparisonState,
    currentPath: string,
    isOffline: boolean
  ) => {
    return (
      !isOffline &&
      materialComparisonState.materialNumbers.length > 0 &&
      !materialComparisonState.shouldForceHideDocket &&
      isCompareDocketEnabledRoute(currentPath)
    );
  }
);

function isCompareDocketEnabledRoute(currentPath: string): boolean {
  return NaooConstants.SHOW_COMPARE_DOCKET_ROUTES.some(
    (routeRegex) => !!currentPath && currentPath.match(routeRegex)
  );
}
