import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntitlementFacade } from '../../../core/store/entitlement/entitlement.facade';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { createMaterialListRow } from '../../../core/store/material-row/models/material-row';
import { MaterialListRow } from '../../../material-list/models/material-list';
import { Observable } from 'rxjs';

@Component({
  selector: 'naoo-material-overallocation-container',
  template: `
    <naoo-material-overallocation-content
      [materialListRows]="materialListRows"
      [hasOverallocatedCartMaterials]="hasOverallocatedCartMaterials$ | async"
      (closeModalEmitter)="closeModal()"
    >
    </naoo-material-overallocation-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialOverallocationContainerComponent
  implements OnInit, OnDestroy {
  private readonly openAnalyticsLabel = 'open material overallocation modal';
  private readonly closeAnalyticsLabel = 'close material overallocation modal';
  private readonly overallocatedMaterials: string[];
  readonly materialListRows: MaterialListRow[];

  hasOverallocatedCartMaterials$: Observable<boolean>;

  constructor(
    private analytics: NaooAnalyticsManager,
    private entitlementFacade: EntitlementFacade,
    private dialogRef: MatDialogRef<MaterialOverallocationContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    private overallocatedMaterialsMap: Map<string, object>
  ) {
    this.overallocatedMaterials = Array.from(
      this.overallocatedMaterialsMap.keys()
    );
    this.materialListRows = this.overallocatedMaterials?.map((material) =>
      createMaterialListRow(material)
    );
  }

  ngOnInit(): void {
    this.trackAnalytics(this.openAnalyticsLabel);
    this.entitlementFacade.setOverallocatedMaterialTotals(
      this.overallocatedMaterialsMap
    );
    this.hasOverallocatedCartMaterials$ = this.entitlementFacade.hasOverallocatedCartMaterials();
  }

  ngOnDestroy(): void {
    this.trackAnalytics(this.closeAnalyticsLabel);
    this.entitlementFacade.clearOverallocatedMaterialTotals();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private trackAnalytics(labelPrefix: string): void {
    this.analytics.trackAnalyticsEvent({
      action: 'click',
      category: 'Ecommerce',
      label: `${labelPrefix} - ${this.overallocatedMaterials}`,
    });
  }
}
