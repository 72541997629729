import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectHasOverallocatedCartMaterials,
  selectMappedEntitlementMaterialDetails,
  selectSelectedMaterialNumber,
} from './entitlement.selectors';
import { EntitlementMaterialDetail } from '../../services/entitlement/models/entitlement';
import { EntitlementActions } from './entitlement.actions';

@Injectable({ providedIn: 'root' })
export class EntitlementFacade {
  constructor(private store: Store) {}

  getEntitlementMaterialDetailMap(
    materialNumbers: string[]
  ): Observable<Map<string, EntitlementMaterialDetail>> {
    return this.store.select(
      selectMappedEntitlementMaterialDetails(materialNumbers)
    );
  }

  hasOverallocatedCartMaterials(): Observable<boolean> {
    return this.store.select(selectHasOverallocatedCartMaterials);
  }

  getSelectedMaterialNumber(): Observable<string> {
    return this.store.select(selectSelectedMaterialNumber);
  }

  setSelectedMaterialNumber(materialNumber: string): void {
    this.store.dispatch(
      EntitlementActions.setSelectedEntitlementMaterial(materialNumber)
    );
  }

  clearSelectedMaterialNumber(): void {
    this.store.dispatch(EntitlementActions.clearSelectedEntitlementMaterial());
  }

  setOverallocatedMaterialTotals(
    overallocatedMaterialsMap: Map<string, object>
  ): void {
    this.store.dispatch(
      EntitlementActions.setOverallocatedMaterialTotals(
        overallocatedMaterialsMap
      )
    );
  }

  clearOverallocatedMaterialTotals(): void {
    this.store.dispatch(EntitlementActions.clearOverallocatedMaterialTotals());
  }

  refreshEntitlement(): void {
    this.store.dispatch(EntitlementActions.refreshEntitlement());
  }
}
