import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { MaterialAdditionalInfosRecord } from './models/material-additional-infos-record';

@Injectable({ providedIn: 'root' })
export class MaterialAdditionalInfoService {
  constructor(private http: HttpClient, private webBffService: WebBffService) {}

  getMaterialAdditionalInfo(
    requestedMaterialNumbers: string[]
  ): Observable<MaterialAdditionalInfosRecord> {
    return this.http.post<MaterialAdditionalInfosRecord>(
      `${this.webBffService.getBff()}/api/v1/materials/additionalInfo`,
      requestedMaterialNumbers
    );
  }
}
