import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StorePurchaseHistoryActions } from './store-purchase-history.actions';
import { OrderHistoryTransformationService } from './store-purchase-history.transformation.service';
import { StorePurchaseHistoryViewModel } from './models/store-purchase-history-view-model';
import { Observable } from 'rxjs';
import { DateRange } from '../../../shared/date-range-selector/date-range';

@Injectable({ providedIn: 'root' })
export class StorePurchaseHistoryFacade {
  constructor(
    private orderHistoryTransformationService: OrderHistoryTransformationService,
    private store: Store
  ) {}

  loadStorePurchaseHistory(dateRange: DateRange): void {
    this.store.dispatch(
      StorePurchaseHistoryActions.loadStorePurchaseHistory(
        dateRange.startDate,
        dateRange.endDate
      )
    );
  }

  getStorePurchaseHistoryViewModel(): Observable<StorePurchaseHistoryViewModel> {
    return this.orderHistoryTransformationService.getStorePurchaseHistoryViewModel();
  }
}
