import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerUnit } from '../../multi-customer-select/multi-customer-select.component';
import { NaooAnalyticsManager } from '../../analytics/NaooAnalyticsManager';
import { CopyGuideStep } from './copy-guide-step.enum';
import { CustomGuideAction } from '../../../lists/dashboard/custom-guide-action';
import { ListsAnalyticsConstants } from '../../../lists/lists-analytics.constants';

@Component({
  selector: 'naoo-copy-guide-modal',
  templateUrl: './copy-guide-modal.component.html',
  styleUrls: ['./copy-guide-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyGuideModalComponent {
  readonly successImage = 'assets/images/copy-icon.svg';
  readonly failureImage = 'assets/images/copy-unsuccessful-icon.svg';
  readonly copyGuideStep = CopyGuideStep;

  copyGuideName: string;
  copyGuideId: string;
  selectedCustomerCompositeIds: string[] = [];
  currentStep = CopyGuideStep.SelectCustomer;
  subtitle = 'COPY_GUIDE_SUCCESS.SUB_TITLE';

  constructor(
    private analytics: NaooAnalyticsManager,
    public dialogRef: MatDialogRef<CopyGuideModalComponent>,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: CustomGuideAction
  ) {
    this.copyGuideName = this.data.customGuideName || '';
    this.copyGuideId = this.data.customGuideId;
  }

  copyToCustomerUnits(units: CustomerUnit[]) {
    this.selectedCustomerCompositeIds = units.map((unit) => unit.compositeId);
    this.currentStep = CopyGuideStep.CopyInProgress;
    this.setSubtitle();
    this.trackAnalyticsForCopyStart(this.selectedCustomerCompositeIds.length);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  copySucceeded() {
    this.currentStep = CopyGuideStep.Success;
  }

  copyFailed() {
    this.currentStep = CopyGuideStep.Fail;
  }

  private setSubtitle() {
    if (this.selectedCustomerCompositeIds.length > 1) {
      this.subtitle = 'COPY_GUIDE_SUCCESS.SUB_TITLE_PLURAL';
      this.changeDetector.markForCheck();
    }
  }

  private trackAnalyticsForCopyStart(numberOfCustomers: number) {
    const event = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'copy custom guide',
    };

    const copyNumberDimension = {
      index: 34,
      value: numberOfCustomers,
    };

    this.analytics.trackAnalyticsEvent(event, [copyNumberDimension]);
  }
}
