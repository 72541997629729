import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  CartReview,
  selectCartCountsForCartReview,
  selectCartReview,
  selectCreditPaymentActionCompleted,
  selectCutoffSplitNextValidDeliverySchedules,
  selectDateHasBeenResolved,
  selectHasFailedFulfillmentSplitOrder,
  selectIsCartReviewLoading,
  selectSelectedCreditCardOption,
} from './cart-review.selectors';
import { filter, switchMap, tap } from 'rxjs/operators';
import { CartReviewActions } from './cart-review.actions';
import { DeliveryScheduleEntryRecord } from '../../services/delivery-schedule/models/delivery-schedules-record';
import { CartCounts } from '../cart/cart.selectors';
import {
  CartReviewSection,
  CartReviewSectionName,
  CreditWidgetLoadingState,
} from './cart-review.state';

@Injectable({ providedIn: 'root' })
export class CartReviewFacade {
  constructor(private store: Store, private _window: Window) {}

  clearCartReview(): void {
    this.store.dispatch(CartReviewActions.clearCartReview());
  }

  getLoadedCartReview(): Observable<CartReview> {
    return this.store
      .select(selectCartReview)
      .pipe(filter((cartReview) => !!cartReview));
  }

  loadCartReview(): void {
    this.store.dispatch(CartReviewActions.loadCartReview());
  }

  isCartReviewLoading(): Observable<boolean> {
    return this.store.select(selectIsCartReviewLoading);
  }

  refreshCartReview(): void {
    this.store.dispatch(CartReviewActions.refreshCartReview());
  }

  hasFailedFulfillmentSplitOrder(): Observable<boolean> {
    return this.store.select(selectHasFailedFulfillmentSplitOrder);
  }

  refreshDropShipMetadata() {
    this.store.dispatch(CartReviewActions.refreshDropShipMetadata());
  }

  removeAllFailedFulfillmentMaterials() {
    this.store.dispatch(CartReviewActions.removeFailedFulfillmentMaterials());
  }

  saveSelectedCreditCardOption(creditCardOptionSelected: boolean): void {
    this.store.dispatch(
      CartReviewActions.saveSelectedCreditCardOption(creditCardOptionSelected)
    );
  }

  isCreditCardOptionSelected(): Observable<boolean> {
    return this.store.select(selectSelectedCreditCardOption);
  }

  getLoadedCutoffSplitNextValidDeliverySchedules(): Observable<
    DeliveryScheduleEntryRecord[]
  > {
    return this.store
      .select(selectCutoffSplitNextValidDeliverySchedules)
      .pipe(
        filter((nextValidDeliverySchedules) => !!nextValidDeliverySchedules)
      );
  }

  updateCartReviewSection(
    name: CartReviewSectionName,
    index: number,
    changes: Partial<CartReviewSection>,
    isSplit: boolean
  ): void {
    this.store.dispatch(
      CartReviewActions.updateCartReviewSection(name, index, changes, isSplit)
    );
  }

  getCartCountsForCartReview(): Observable<
    Map<CartReviewSectionName, CartCounts[]>
  > {
    return this.store.select(selectCartCountsForCartReview);
  }

  getDateHasBeenResolved(): Observable<boolean> {
    return this.store.select(selectDateHasBeenResolved);
  }

  submitCreditPaymentIfNeeded(): Observable<boolean> {
    return this.isCreditCardOptionSelected().pipe(
      tap((isCreditPaymentSelected) => {
        if (isCreditPaymentSelected) {
          this._window.wpwl.executePayment('wpwl-container-card');
        }
      }),
      switchMap(() => this.store.select(selectCreditPaymentActionCompleted))
    );
  }

  markExecutePaymentDone() {
    return this.store.dispatch(
      CartReviewActions.updateCartReviewAciHasResolved(true)
    );
  }

  updateCreditWidgetLoadingState(state: CreditWidgetLoadingState) {
    return this.store.dispatch(
      CartReviewActions.updateCreditWidgetLoadingState(state)
    );
  }
}
