import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CriticalItemsActions } from './critical-items.actions';
import { Observable } from 'rxjs';
import {
  selectCriticalItemsGuide,
  selectRemainingCriticalItems,
  selectValidCriticalItems,
  selectCriticalItemsGuideExportMaterialsInput,
} from './critical-items.selector';
import { filter } from 'rxjs/operators';
import { CriticalItemsGuide } from './models/critical-items-guide';
import { SortByType } from '../../../guides/shared/guides';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';

@Injectable({ providedIn: 'root' })
export class CriticalItemsFacade {
  constructor(private store: Store) {}

  addCriticalItem(materialNumber: string): void {
    this.store.dispatch(CriticalItemsActions.addCriticalItem(materialNumber));
  }

  deleteCriticalItem(materialNumber: string): void {
    this.store.dispatch(
      CriticalItemsActions.deleteCriticalItem(materialNumber)
    );
  }

  getLoadedValidCriticalItems(): Observable<string[]> {
    return this.store
      .select(selectValidCriticalItems)
      .pipe(filter((criticalItems) => !!criticalItems));
  }

  getLoadedRemainingCriticalItems(): Observable<string[]> {
    return this.store
      .select(selectRemainingCriticalItems)
      .pipe(filter((criticalItems) => !!criticalItems));
  }

  getCriticalItemsGuide(): Observable<CriticalItemsGuide> {
    return this.store.select(selectCriticalItemsGuide);
  }

  getExportMaterialsInput(isPrint: boolean): Observable<ExportMaterialsInput> {
    return this.store.select(
      selectCriticalItemsGuideExportMaterialsInput(isPrint)
    );
  }

  updateSearchText(searchQuery: string) {
    this.store.dispatch(CriticalItemsActions.updateSearchText(searchQuery));
  }

  updateSortBy(newSortBy: SortByType) {
    this.store.dispatch(CriticalItemsActions.updateSortBy(newSortBy));
  }
}
