import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IndicatorListsState } from './indicator-lists.state';
import { MaterialFlagType } from '../../../material-flag/material-flag';

export const selectIndicatorListsState = createFeatureSelector<IndicatorListsState>(
  'indicatorLists'
);

const selectHasLoaded = createSelector(
  selectIndicatorListsState,
  (state) => state.hasLoaded
);

const selectIndicatorLists = createSelector(
  selectHasLoaded,
  selectIndicatorListsState,
  (hasLoaded, state) => {
    return hasLoaded ? state.indicatorLists : {};
  }
);

export const selectIndicatorFlags = (materialNumber: string) =>
  createSelector(
    selectIndicatorLists,
    (resultSet): MaterialFlagType[] => resultSet[materialNumber] || []
  );

export const selectContractIndicatorMaterialsSet = createSelector(
  selectIndicatorLists,
  (resultSet): Set<string> =>
    new Set(
      Object.entries(resultSet)
        .filter(([_, flagTypes]) =>
          flagTypes.includes(MaterialFlagType.Contract)
        )
        .map(([material, _]) => material)
    )
);
