import {
  pickupSchedulesAdapter,
  pickupSchedulesInitialState,
  PickupSchedulesRecordState,
  PickupSchedulesState,
  PickupSchedulesStatus,
  pickupScheduleStateAdapter,
  pickupScheduleStateInitialState,
} from './pickup-schedules.state';
import { PickupSchedulesActions } from './pickup-schedules.actions';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PickupScheduleRecord } from '../../services/pickup-schedules/models/pickup-schedule-record';

export const PickupSchedulesReducer = createReducer(
  pickupScheduleStateInitialState,
  on(
    PickupSchedulesActions.loadPickupSchedules,
    (state, action): PickupSchedulesState =>
      loadPickupSchedules(state, action.storePlantIds)
  ),
  on(
    PickupSchedulesActions.getPickupSchedules,
    (state, action): PickupSchedulesState =>
      getPickupSchedules(state, action.storePlantIds)
  ),
  on(
    PickupSchedulesActions.getPickupSchedulesSuccess,
    (state, action): PickupSchedulesState =>
      getPickupSchedulesSuccess(state, action.pickupSchedulesMap)
  ),
  on(PickupSchedulesActions.getPickupSchedulesFailure, (state, action) =>
    getPickupSchedulesFailure(state, action.storePlantIds)
  ),
  on(
    PickupSchedulesActions.clearPickupSchedules,
    (): PickupSchedulesState => pickupScheduleStateInitialState
  )
);

function loadPickupSchedules(
  state: PickupSchedulesState,
  storePlantIds: string[]
): PickupSchedulesState {
  const upsertedEntities: PickupSchedulesRecordState[] = storePlantIds
    .filter(
      (id) =>
        !state.entities[id] ||
        state.entities[id].status === PickupSchedulesStatus.Error
    )
    .map((id) => {
      return {
        storePlantId: id,
        pickupScheduleEntityState: pickupSchedulesInitialState,
        status: PickupSchedulesStatus.Queued,
      };
    });
  return pickupScheduleStateAdapter.upsertMany(upsertedEntities, state);
}

function getPickupSchedules(
  state: PickupSchedulesState,
  storePlantIds: string[]
): PickupSchedulesState {
  const queuedEntities: Update<PickupSchedulesRecordState>[] = storePlantIds
    .filter((id) => state.entities[id].status === PickupSchedulesStatus.Queued)
    .map((id) => {
      return {
        id: id,
        changes: {
          status: PickupSchedulesStatus.Requested,
        },
      };
    });
  return pickupScheduleStateAdapter.updateMany(queuedEntities, state);
}

function getPickupSchedulesSuccess(
  state: PickupSchedulesState,
  pickupSchedulesMap: { [key: string]: PickupScheduleRecord[] }
): PickupSchedulesState {
  const upsertedEntities: Update<PickupSchedulesRecordState>[] = Object.entries(
    pickupSchedulesMap
  ).map(([key, value]) => {
    return {
      id: key,
      changes: {
        pickupScheduleEntityState: pickupSchedulesAdapter.setAll(
          value,
          pickupSchedulesInitialState
        ),
        status: PickupSchedulesStatus.Success,
      },
    };
  });

  return pickupScheduleStateAdapter.updateMany(upsertedEntities, state);
}

function getPickupSchedulesFailure(
  state: PickupSchedulesState,
  storePlantIds: string[]
): PickupSchedulesState {
  const updatedPickupSchedulesState: Update<PickupSchedulesRecordState>[] = storePlantIds.map(
    (storePlantId) => {
      return {
        id: storePlantId,
        changes: {
          status: PickupSchedulesStatus.Error,
        },
      };
    }
  );
  return pickupScheduleStateAdapter.updateMany(
    updatedPickupSchedulesState,
    state
  );
}
