import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { OrderItem } from '../../shared/models/order-confirmation';
import {
  MaterialListStyle,
  MaterialRow,
  MaterialRowContext,
} from '../../../core/store/material-row/models/material-row';
import { SessionFacade } from '../../../core/store/session/session.facade';
import { MaterialRecommendations } from '../../../shared/services/material-recommendations/models/material-recommendations';
import { EcommerceAnalyticsFacade } from '../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';

@Component({
  selector: 'naoo-material-related-content',
  templateUrl: './material-related-content.component.html',
  styleUrls: ['./material-related-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialRelatedContentComponent {
  @Input() itemException: OrderItem;
  @Input() materialWithSubstitutes: MaterialRow;
  @Input() isMobile: boolean;
  @Output() closeButtonClicked = new EventEmitter();

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Input() set isLoading(_isLoading: boolean) {
    this._isLoading = _isLoading;
    this.trackGoogleViewItemList();
  }

  @Input() set materialRecommendations(
    materialRecommendations: MaterialRecommendations[]
  ) {
    this._materialRecommendations = materialRecommendations;
    this.trackGoogleViewItemList();
  }

  private readonly trackGoogleViewItemListTimeoutMs = 2000;

  private _isLoading: boolean;
  private _materialRecommendations: MaterialRecommendations[];
  private trackGoogleViewItemListTimeoutId: ReturnType<typeof setTimeout>;

  readonly context = MaterialRowContext.Substitutes;
  readonly listStyle = MaterialListStyle.List;
  readonly recommendationEngineName = 'Similar Items';

  isLoyaltyProgramEligible$ = this.sessionFacade.isLoyaltyProgramEligible();

  constructor(
    private sessionFacade: SessionFacade,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade
  ) {}

  get materials(): string[] {
    return (
      this._materialRecommendations?.[0]?.materialListRows?.map(
        (i) => i.value as string
      ) ?? []
    );
  }

  get modalTitle(): string {
    return this.itemException
      ? 'ORDER_CONFIRMATION.ITEM_EXCEPTIONS.MODAL.TITLE'
      : 'PRODUCT.SIMILAR_ITEMS_MODAL_HEADER';
  }

  closeModal(): void {
    this.closeButtonClicked.emit();
  }

  private trackGoogleViewItemList(): void {
    const materials = this.materials;

    if (this.isLoading || !materials?.length) {
      return;
    }
    if (this.trackGoogleViewItemListTimeoutId) {
      clearTimeout(this.trackGoogleViewItemListTimeoutId);
    }
    this.trackGoogleViewItemListTimeoutId = setTimeout(
      () =>
        this.ecommerceAnalyticsFacade.trackGoogleViewItemList(
          materials,
          this.context,
          {
            analytics: {
              recommendationEngineName: this.recommendationEngineName,
            },
          }
        ),
      this.trackGoogleViewItemListTimeoutMs
    );
  }
}
