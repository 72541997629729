import { NaooIcon } from './services/images/naoo-icon.service';
import { GroupByType, SortByType } from '../guides/shared/guides';

export class NaooConstants {
  public static readonly Uom = {
    Case: 'CS',
    Unit: 'EA',
  };

  public static readonly CartStatus = {
    Active: 'ACTIVE',
    PunchedOut: 'PUNCHED_OUT',
    Deleted: 'DELETED',
    Saved: 'SAVED',
    Submitted: 'SUBMITTED',
  };

  public static readonly LANGUAGE_HEADER = 'Accept-Language';
  public static readonly X_AUTH_TOKEN_HEADER = 'x-auth-token';
  public static readonly LOGIN_LOCATION_HEADER = 'Login-Location';
  public static readonly LOG_ROCKET_URL_HEADER = 'X-LogRocket-URL';
  public static readonly X_XSRF_HEADER = 'X-XSRF-Token';
  public static readonly XSRF_TOKEN = 'XSRF-TOKEN';
  public static readonly X_REQUESTED_WITH_HEADER = 'X-Requested-With';
  public static readonly REGION_COOKIE = 'GOR';
  public static readonly PERSONALIZATION_COOKIE = '_gfs_id';
  public static readonly PREVIOUS_URL = 'previousUrl';
  public static readonly PRIVACY_URL = '/assets/privacy.html';
  public static readonly LOGIN_URL = '/assets/login.html';
  public static readonly LOGOUT_URL = '/api/logout';
  public static readonly CONNECTIVITY_RETRY_UI_TIMER = 5000;
  public static readonly SERVICE_RETRY_MAX_ATTEMPT = 3;
  public static readonly CART_DEBOUNCE_REQUEST_INTERVAL = 750;
  public static readonly PO_NUMBER_MAX_LENGTH = 20;
  public static readonly COUPON_CODE_MAX_LENGTH = 50;
  public static readonly ALLOWED_CHARACTERS_REGEX =
    '^[a-zA-Z0-9À-ÿ-.,!@$%&*‘’”“~[_ #"\'^()=+\\]}{:/]*$';
  public static readonly LOADING_DISPLAY_DELAY = 500; // in ms
  public static readonly DOUBLE_TAP_INTERVAL = 500; // in ms
  public static readonly ANIMATION_TAP_INTERVAL = 250;
  public static readonly MAX_INPUT_LENGTH = 1024;
  public static readonly NDASH = '–';

  public static readonly enDayOfWeekNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  public static readonly frDayOfWeekNames = ['d', 'l', 'm', 'm', 'j', 'v', 's'];

  // must match StoreHoursWithOrderCutoffDto.OPENING_BUFFER_MINUTES in inventory-service
  public static readonly pickupOpeningBufferMinutes = 60;
  public static readonly pickupSubmissionBufferMinutes = 120;

  /**
   * The global timeout value for all retried HTTP requests.
   * @see https://jira.gfs.com/jira/browse/NAOO-16806
   * @see https://github.com/angular/angular/issues/26453
   */
  public static readonly RETRY_HTTP_REQUEST_TIMEOUT = 30_000;

  // Feature Paths
  public static readonly HOME_PAGE_PATH = '/home';
  public static readonly ROOT_PATH = '/';
  public static readonly PRODUCT_DETAILS_PATH = '/product';
  public static readonly PDP_PATH_WITH_TRAILING_SLASH = `${NaooConstants.PRODUCT_DETAILS_PATH}/`;
  public static readonly CART_PATH = '/cart';
  public static readonly PREVIEW_CART_PATH = `${NaooConstants.CART_PATH}?activeTab=saved-carts&preview=true`;
  public static readonly MOBILE_HAMBURGER_MENU_PATH = '/mobile-hamburger-menu';
  public static readonly ORDERS_PATH = '/orders';
  public static readonly PAGE_NOT_FOUND_PATH = '/page-not-found';
  public static readonly INFO_UNAVAILABLE_PATH = '/info-unavailable';
  public static readonly CUSTOMER_UNIT_SELECTION_PATH =
    '/customer-unit-selection';
  public static readonly PRODUCT_COMPARISON = '/product-comparison';
  public static readonly PREFERENCES_PATH = '/preferences';
  public static readonly SCHEDULED_MAINTENANCE_PATH = '/scheduled-maintenance';
  public static readonly OFFLINE_ERROR_PATH = '/offline-error';
  public static readonly CATEGORIES_PATH = '/categories';
  public static readonly MY_ID_CARD_PATH = '/my-id-card';
  public static readonly CATEGORIES_RESULTS_PATH = `${NaooConstants.CATEGORIES_PATH}/results`;
  public static readonly SEARCH_URL = '/search';
  public static readonly CART_REVIEW_URL = '/cart-review';
  public static readonly ORDER_CONFIRMATION_URL = '/order-confirmation';
  public static readonly CART_TRANSFER_URL = '/cart/transfer';
  public static readonly STORE_PURCHASE_DETAILS_PATH_PREFIX =
    'store-purchase-details';
  public static readonly MARKETING_GUIDE_PATH = '/collections';
  public static readonly PUNCH_OUT_URL = '/punch-out';
  public static readonly SITE_URL = '/site';

  // Guide Feature Paths
  public static readonly GUIDES_PATH = '/guides';
  public static readonly ORDER_GUIDE_PATH = `${NaooConstants.GUIDES_PATH}/order-guide`;
  public static readonly CRITICAL_ITEMS_GUIDE_PATH = `${NaooConstants.GUIDES_PATH}/critical-items-guide`;
  public static readonly CUSTOM_GUIDE_PATH = `${NaooConstants.GUIDES_PATH}/custom-guide`;
  public static readonly GUIDE_EDIT_PATH_SUFFIX = '/organize';

  // Analytics event properties
  public static readonly ANALYTICS_CATEGORY = 'warning';
  public static readonly ANALYTICS_CATEGORY_STORE_PURCHASE_HISTORY =
    'store purchase history';
  public static readonly ANALYTICS_ACTION_DISPLAYED = 'displayed';

  // Cart transfer content
  public static readonly CART_TRANSFER_CONTENT = 'naoo-cart-transfer-content';

  // Assets
  public static readonly icons: { [iconName: string]: NaooIcon } = {
    address: {
      name: 'address-icon',
      location: './assets/images/address-icon-v2.svg',
    },
    arrow: {
      name: 'arrow-icon',
      location: './assets/images/arrow.svg',
    },
    arrow_gray: {
      name: 'arrow-gray',
      location: './assets/images/arrow-gray.svg',
    },
    arrow_v2: {
      name: 'arrow-v2',
      location: './assets/images/arrow-v2.svg',
    },
    alert: {
      name: 'alert-icon',
      location: './assets/images/alert-icon.svg',
    },
    bell: {
      name: 'bell-icon',
      location: './assets/images/bell-icon.svg',
    },
    calendar: {
      name: 'calendar-icon',
      location: './assets/images/ic-calendar-v2.svg',
    },
    calendar_v3: {
      name: 'calendar-icon-v3',
      location: './assets/images/ic-calendar-v3.svg',
    },
    calendar_invalid: {
      name: 'calendar-invalid',
      location: 'assets/images/calendar-invalid-v2.svg',
    },
    store: {
      name: 'store-icon',
      location: './assets/images/ic-store.svg',
    },
    number: {
      name: 'number-icon',
      location: './assets/images/number-icon-v2.svg',
    },
    empty: {
      name: 'empty-icon',
      location: './assets/images/empty-v2.svg',
    },
    success: {
      name: 'success-icon',
      location: './assets/images/success-icon.svg',
    },
    success_v1: {
      name: 'success-icon-v1',
      location: './assets/images/success-icon-v1.svg',
    },
    error: {
      name: 'error-icon',
      location: './assets/images/error-icon.svg',
    },
    warningV1: {
      name: 'warning-icon-v1',
      location: './assets/images/item-error-icon.svg',
    },
    warning: {
      name: 'warning-icon',
      location: './assets/images/warning-icon-v2.svg',
      color: 'yellow',
    },
    warning_v3: {
      name: 'warning-icon-v3',
      location: './assets/images/warning-icon-v3.svg',
      color: 'yellow',
    },
    invoiced_v1: {
      name: 'invoiced-icon-v1',
      location: './assets/images/invoiced-icon-v1.svg',
    },
    invoiced: {
      name: 'invoiced-icon',
      location: './assets/images/invoiced-icon.svg',
    },
    credit_card: {
      name: 'credit_card_icon',
      location: './assets/images/credit-card-icon.svg',
    },
    secure_payment: {
      name: 'secure_payment',
      location: './assets/images/secure-payment-icon.svg',
    },
    credit_order: {
      name: 'credit-order-icon',
      location: './assets/images/credit-order-icon.svg',
    },
    new_window: {
      name: 'new-window-icon',
      location: './assets/images/new-window-icon.svg',
    },
    triple_dot_menu: {
      name: 'triple_dot_menu',
      location: './assets/images/triple-dot-menu.svg',
    },
    list_icon_v2: {
      name: 'list_icon_v2',
      location: './assets/images/list-icon-v2.svg',
    },
    grid_icon_v2: {
      name: 'grid_icon_v2',
      location: './assets/images/grid-icon-v2.svg',
    },
    slim_icon: {
      name: 'slim_icon',
      location: './assets/images/slim-icon.svg',
    },
    import_icon_v2: {
      name: 'import_icon_v2',
      location: './assets/images/import-icon-v2.svg',
    },
    import_icon: {
      name: 'import_icon',
      location: './assets/images/import-icon-v3.svg',
    },
    create_custom_guide_icon: {
      name: 'create_custom_guide_icon',
      location: './assets/images/create-custom-guide-icon.svg',
    },
    print_v2: {
      name: 'print_v2',
      location: './assets/images/print-icon-v2.svg',
    },
    add_icon: {
      name: 'add-icon',
      location: './assets/images/add-icon.svg',
    },
    category_up_active: {
      name: 'category_up_active',
      location: './assets/images/category_up.svg',
    },
    category_down_active: {
      name: 'category_down_active',
      location: './assets/images/category_down.svg',
    },
    triple_dot_menu_white: {
      name: 'triple_dot_menu_white',
      location: './assets/images/triple-dot-menu-white.svg',
    },
    cart_icon: {
      name: 'cart-icon',
      location: './assets/images/cart-icon-gray-v2.svg',
    },
    cart_icon_v2: {
      name: 'cart_icon_v2',
      location: './assets/images/cart-icon-v2.svg',
    },
    import_guide_document: {
      name: 'import-guide-document',
      location: './assets/images/import-guide-document.svg',
    },
    hand_string: {
      name: 'hand_string',
      location: './assets/images/hand-string-v2.svg',
    },
    critical_item_star: {
      name: 'critical_item_star',
      location: './assets/images/critical-item-star-v2.svg',
    },
    edit_icon: {
      name: 'edit-icon',
      location: './assets/images/edit-icon-v2.svg',
    },
    trash_icon: {
      name: 'trash-icon',
      location: './assets/images/trash-icon.svg',
    },
    trash_icon_v3: {
      name: 'trash-icon-v3',
      location: './assets/images/trash-icon-v3.svg',
    },
    email: {
      name: 'email',
      location: './assets/images/email.svg',
    },
    plus: {
      name: 'plus',
      location: './assets/images/plus.svg',
    },
    plus_v2: {
      name: 'plus-v2',
      location: './assets/images/plus-v2.svg',
    },
    full_error: {
      name: 'full-error',
      location: './assets/images/full-error.svg',
    },
    exception_icon: {
      name: 'exception-icon',
      location: './assets/images/exception-icon-v0.svg',
    },
    exception_icon_v1: {
      name: 'exception-icon-v1',
      location: './assets/images/exception-icon-v1.svg',
    },
    exception_icon_v2: {
      name: 'exception-icon-v2',
      location: './assets/images/exception-icon-v2.svg',
      color: 'red',
    },
    unavailable: {
      name: 'unavailable',
      location: './assets/images/unavailable.svg',
    },
    copy_items: {
      name: 'copy-items',
      location: './assets/images/copy-items.svg',
    },
    plus_icon: {
      name: 'plus_icon',
      location: './assets/images/plus-icon.svg',
    },
    clock_v1: {
      name: 'clock-icon-v1',
      location: './assets/images/clock-icon-v1.svg',
    },
    clock: {
      name: 'clock-icon',
      location: './assets/images/clock-icon.svg',
    },
    clock_filled: {
      name: 'clock-filled-icon',
      location: './assets/images/clock-filled-icon.svg',
    },
    copy_custom_guide_in_progress: {
      name: 'copy-custom-guide-progress',
      location: './assets/images/copy-in-process-icon.svg',
    },
    copy_custom_guide_failed: {
      name: 'copy-custom-guide-failed',
      location: './assets/images/copy-unsuccessful-icon.svg',
    },
    copy_custom_guide_successful: {
      name: 'copy-custom-guide-success',
      location: './assets/images/copy-icon.svg',
    },
    info_icon: {
      name: 'info_icon',
      location: './assets/images/info_icon.svg',
    },
    filled_info_icon: {
      name: 'filled_info_icon',
      location: './assets/images/info_icon_hover.svg',
    },
    review_icon: {
      name: 'review_icon',
      location: './assets/images/review-icon.svg',
    },
    search_icon_v2: {
      name: 'search_icon_v2',
      location: './assets/images/search-icon-v2.svg',
    },
    clear_icon: {
      name: 'clear_icon',
      location: './assets/images/close-icon.svg',
    },
    clear_icon_v2: {
      name: 'clear_icon_v2',
      location: './assets/images/close-icon-v2.svg',
    },
    clear_filter_icon: {
      name: 'clear_filter_icon',
      location: './assets/images/clear-icon-v2.svg',
    },
    checkmark_icon: {
      name: 'checkmark-icon',
      location: './assets/images/checkmark-icon.svg',
      color: 'green',
    },
    refresh_icon: {
      name: 'refresh_icon',
      location: './assets/images/refresh-icon-v2.svg',
    },
    quick_add_icon: {
      name: 'quick_add_icon',
      location: './assets/images/quick-add-icon.svg',
    },
    minus: {
      name: 'minus',
      location: './assets/images/minus.svg',
    },
    pdf_icon: {
      name: 'pdf_icon',
      location: './assets/images/pdf-icon-v2.svg',
    },
    alarm_icon: {
      name: 'alarm_icon',
      location: './assets/images/alarm.svg',
    },
    find_replace: {
      name: 'find_replace',
      location: './assets/images/find-replace.svg',
    },
    download: {
      name: 'download',
      location: './assets/images/download.svg',
    },
    download_v2: {
      name: 'download_v2',
      location: './assets/images/download-v2.svg',
    },
    horizontal_triple_dot: {
      name: 'horizontal_triple_dot',
      location: './assets/images/horizontal-triple-dot.svg',
    },
    horizontal_triple_dot_v2: {
      name: 'horizontal_triple_dot_v2',
      location: './assets/images/horizontal-triple-dot-v2.svg',
    },
    filter_icon: {
      name: 'filter_icon',
      location: './assets/images/filter.svg',
    },
    bang_icon: {
      name: 'bang_icon',
      location: './assets/images/bang-v1.svg',
    },
    cancel_icon: {
      name: 'cancel_icon',
      location: './assets/images/cancel-icon-v1.svg',
    },
    change_circle: {
      name: 'change_circle',
      location: './assets/images/change_circle.svg',
    },
    delivered_icon: {
      name: 'delivered-icon',
      location: './assets/images/delivered-icon.svg',
    },
    shipping_icon: {
      name: 'shipping-icon',
      location: './assets/images/shipping-icon.svg',
    },
    shipping_icon_v1: {
      name: 'shipping-icon-v1',
      location: './assets/images/shipping-icon-v1.svg',
    },
    category_down_v2: {
      name: 'category_down_v2',
      location: './assets/images/category_down_v2.svg',
    },
    language_icon: {
      name: 'language_icon',
      location: './assets/images/language-icon.svg',
    },
    store_icon_gray: {
      name: 'store_icon_gray',
      location: './assets/images/store-icon-gray-v2.svg',
    },
    gear_icon: {
      name: 'gear_icon',
      location: './assets/images/gear-icon-v2.svg',
    },
    hamburger_icon: {
      name: 'hamburger_icon',
      location: './assets/images/hamburger-icon_v2.svg',
    },
    move_to_icon: {
      name: 'move-to-icon',
      location: './assets/images/move-to-v2.svg',
    },
    offline_icon: {
      name: 'offline-icon',
      location: './assets/images/offline.svg',
    },
    taxonomy_bracket_icon: {
      name: 'taxonomy-bracket-icon',
      location: '/assets/images/taxonomy_bracket.svg',
    },
    info_filled_icon_v2: {
      name: 'info_filled_icon',
      location: '/assets/images/info_filled-v2.svg',
    },
    gordon_icon: {
      name: 'gordon-icon',
      location: './assets/images/gordon-icon.svg',
    },
    united_ordering_icon: {
      name: 'united-ordering-icon',
      location: './assets/images/united-ordering-icon-2.svg',
    },
    open_new_tab: {
      name: 'open-new-tab',
      location: './assets/images/open_new_tab.svg',
    },
    semi_truck: {
      name: 'semi_truck',
      location: './assets/images/semi-truck.svg',
    },
    rounded_corner: {
      name: 'rounded_corner',
      location: './assets/images/rounded-corner.svg',
    },
    rounded_corner_selected: {
      name: 'rounded_corner_selected',
      location: './assets/images/rounded-corner-selected.svg',
    },
    single_pickup_blue: {
      name: 'single_pickup_blue',
      location: './assets/images/single-pickup-blue.svg',
    },
    single_pickup_red: {
      name: 'single_pickup_red',
      location: './assets/images/single-pickup-red.svg',
    },
    split_order_blue: {
      name: 'split_order_blue',
      location: './assets/images/split-order-blue.svg',
    },
    split_order_red: {
      name: 'split_order_red',
      location: './assets/images/split-order-red.svg',
    },
    ispu_in_stock_icon: {
      name: 'ispu_in_stock_icon',
      location: './assets/images/ispu-in-stock-icon.svg',
    },
    loyalty_icon: {
      name: 'loyalty_icon',
      location: './assets/images/loyalty-icon-v2.svg',
    },
    loyalty_tier_base: {
      name: 'loyalty_tier_base',
      location: './assets/images/loyalty_tier_base_v2.svg',
    },
    loyalty_tier_bronze: {
      name: 'loyalty_tier_bronze',
      location: './assets/images/loyalty_tier_bronze_v2.svg',
    },
    loyalty_tier_silver: {
      name: 'loyalty_tier_silver',
      location: './assets/images/loyalty_tier_silver_v2.svg',
    },
    loyalty_tier_gold: {
      name: 'loyalty_tier_gold',
      location: './assets/images/loyalty_tier_gold_v2.svg',
    },
    loyalty_tier_platinum: {
      name: 'loyalty_tier_platinum',
      location: './assets/images/loyalty_tier_platinum_v2.svg',
    },
    loyalty_tier_elite: {
      name: 'loyalty_tier_elite',
      location: './assets/images/loyalty_tier_elite_v2.svg',
    },
    paper_check: {
      name: 'paper_check',
      location: './assets/images/paper_check.svg',
    },
    keep_cart: {
      name: 'keep_cart',
      location: './assets/images/keep-cart.svg',
    },
    remove_cart: {
      name: 'remove_cart',
      location: './assets/images/remove-cart.svg',
    },
    save_cart: {
      name: 'save_cart',
      location: './assets/images/save-cart.svg',
    },
    privacy: {
      name: 'privacy',
      location: './assets/images/privacy.svg',
    },
  };

  public static readonly CategoryImagePath = 'assets/images/catalog/';

  public static readonly placeHolderImagePath =
    './assets/images/no-image-v2.svg';

  public static readonly maximumCartCoupons = 5;
  public static readonly unassignedCategory = 'Unassigned';

  // NOTE: Make sure breakpoints match the ones defined in variables.scss file
  public static readonly DEVICE = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE',
  };

  public static readonly OMIT_HEADER_ROUTES = [
    `^${NaooConstants.MOBILE_HAMBURGER_MENU_PATH}$`,
    `^${NaooConstants.CART_REVIEW_URL}$`,
  ];

  public static readonly SHOW_COMPARE_DOCKET_ROUTES = [
    `^${NaooConstants.SEARCH_URL}$`,
    `^${NaooConstants.PRODUCT_DETAILS_PATH}`,
    `^${NaooConstants.CATEGORIES_RESULTS_PATH}`,
    `^${NaooConstants.ORDER_GUIDE_PATH}$`,
    `^${NaooConstants.CRITICAL_ITEMS_GUIDE_PATH}$`,
    `^${NaooConstants.CUSTOM_GUIDE_PATH}/([A-Za-z0-9-]+)$`,
    `^${NaooConstants.MARKETING_GUIDE_PATH}/([A-Za-z0-9-]+)$`,
    `^${NaooConstants.HOME_PAGE_PATH}$`,
  ];

  public static readonly HERO_PAGE_ROUTES = [
    `^${NaooConstants.GUIDES_PATH}$`,
    `^${NaooConstants.ORDERS_PATH}$`,
    `^${NaooConstants.CATEGORIES_PATH}$`,
    `^${NaooConstants.HOME_PAGE_PATH}$`,
    `^${NaooConstants.SCHEDULED_MAINTENANCE_PATH}$`,
  ];

  public static readonly CENTERED_PAGE_ROUTES = [
    `^${NaooConstants.CART_REVIEW_URL}$`,
    `^${NaooConstants.CART_PATH}$`,
    `^${NaooConstants.SITE_URL}/(.*)$`,
  ];

  public static readonly FLUSH_PAGE_ROUTES = [
    `^${NaooConstants.SEARCH_URL}$`,
    `^${NaooConstants.CATEGORIES_RESULTS_PATH}`,
    `^${NaooConstants.ORDER_GUIDE_PATH}$`,
    `^${NaooConstants.CRITICAL_ITEMS_GUIDE_PATH}$`,
    `^${NaooConstants.CUSTOM_GUIDE_PATH}/([A-Za-z0-9-]+)$`,
    `^${NaooConstants.MARKETING_GUIDE_PATH}/([A-Za-z0-9-]+)$`,
  ];

  // List style pages that should maintain a white background rather than the light gray default
  public static readonly WHITE_BACKGROUND_ROUTES = [
    `^${NaooConstants.ORDER_CONFIRMATION_URL}$`,
    `^${NaooConstants.CART_PATH}$`,
    `^${NaooConstants.MOBILE_HAMBURGER_MENU_PATH}$`,
    `^${NaooConstants.PRODUCT_DETAILS_PATH}`,
    `^${NaooConstants.ORDERS_PATH}`,
    `^${NaooConstants.PAGE_NOT_FOUND_PATH}$`,
    `^${NaooConstants.INFO_UNAVAILABLE_PATH}$`,
    `^${NaooConstants.CART_REVIEW_URL}$`,
    `^${NaooConstants.SCHEDULED_MAINTENANCE_PATH}$`,
    `^${NaooConstants.SEARCH_URL}$`,
    `^${NaooConstants.CATEGORIES_RESULTS_PATH}`,
    `^${NaooConstants.OFFLINE_ERROR_PATH}$`,
    `^${NaooConstants.ORDER_GUIDE_PATH}$`,
    `^${NaooConstants.ORDER_GUIDE_PATH}${NaooConstants.GUIDE_EDIT_PATH_SUFFIX}$`,
    `^${NaooConstants.GUIDES_PATH}$`,
    `^${NaooConstants.CRITICAL_ITEMS_GUIDE_PATH}$`,
    `^${NaooConstants.CUSTOM_GUIDE_PATH}/([A-Za-z0-9-]+)$`,
    `^${NaooConstants.CUSTOM_GUIDE_PATH}/([A-Za-z0-9-]+)${NaooConstants.GUIDE_EDIT_PATH_SUFFIX}$`,
    `^${NaooConstants.MARKETING_GUIDE_PATH}/([A-Za-z0-9-]+)$`,
    `^${NaooConstants.PREFERENCES_PATH}$`,
    `^${NaooConstants.PREFERENCES_PATH}/([A-Za-z0-9-]+)$`,
    `^${NaooConstants.MY_ID_CARD_PATH}$`,
  ];

  public static readonly offlineSupportedRoutes = [
    `^${NaooConstants.CART_PATH}$`,
    `^${NaooConstants.MOBILE_HAMBURGER_MENU_PATH}$`,
    `^${NaooConstants.GUIDES_PATH}$`,
    `^${NaooConstants.ORDER_GUIDE_PATH}$`,
    `^${NaooConstants.CRITICAL_ITEMS_GUIDE_PATH}$`,
    `^${NaooConstants.CUSTOM_GUIDE_PATH}/([A-Za-z0-9-]+)$`,
    `^${NaooConstants.ORDER_CONFIRMATION_URL}$`,
  ];

  public static readonly CUSTOM_GUIDE_NAME_MAX_LENGTH = 40;

  public static readonly CREATE_CATEGORY_MODAL_ID = 'create-custom-category';
  public static readonly CUSTOM_CATEGORY_MAX_LENGTH = 30;

  public static readonly ORDERGUIDE_CUSTOM_CATEGORY_MAX_LENGTH = 50;

  public static readonly LOADING_MODAL_ID = 'loading-modal';
  public static readonly SUBMIT_LOADING_MODAL_ID = 'submit-loading-modal';
  public static readonly SUBMIT_LOADING_MODAL_TIMEOUT = 30000;

  public static readonly TOAST_MESSAGE_DURATION_DEFAULT = 4000; // ms

  public static readonly UNASSIGNED_GUIDE_CATEGORY_KEY = '';

  public static readonly ORDER_GUIDE_EN = 'Order Guide';
  public static readonly ORDER_GUIDE_FR = 'Fiche de commande';

  public static readonly SCROLL_EVENT_LISTENER_TYPE = 'scroll';

  public static readonly SIDE_MENU_EXPAND_STATE_KEY = 'side-menu-expand-state';

  public static readonly ORDER_GUIDE_SORTBY_STATE_KEY =
    'order-guide-sortby-state';

  public static readonly SORT_BY_OPTIONS = [
    {
      display: 'LISTS.SORT_ITEMS_BY.CUSTOM_OPTION',
      name: SortByType.Custom,
    },
    {
      display: 'LISTS.SORT_ITEMS_BY.DESCRIPTION_OPTION',
      name: SortByType.Description,
    },
    {
      display: 'LISTS.SORT_ITEMS_BY.BRAND_OPTION',
      name: SortByType.Brand,
    },
    {
      display: 'LISTS.SORT_ITEMS_BY.ITEM_CODE_OPTION',
      name: SortByType.ItemCode,
    },
    {
      display: 'LISTS.SORT_ITEMS_BY.ORDER_DATE',
      name: SortByType.OrderDate,
    },
  ];

  public static readonly GROUP_BY_OPTIONS = [
    {
      display: 'LISTS.GROUP_ITEMS_BY.CUSTOM_OPTION',
      name: GroupByType.Custom,
      analyticsLabel: 'custom categories',
    },
    {
      display: 'LISTS.GROUP_ITEMS_BY.STORAGE_OPTION',
      name: GroupByType.Storage,
      analyticsLabel: 'storage area',
    },
    {
      display: 'LISTS.GROUP_ITEMS_BY.TAXONOMY_OPTION',
      name: GroupByType.Taxonomy,
      analyticsLabel: 'default taxonomy',
    },
  ];

  // Images to preload for offline
  public static readonly PRE_LOAD_CACHED = [
    './assets/images/trouble-connecting.mp4',
    './assets/images/no-connection.svg',
    NaooConstants.placeHolderImagePath,
    './assets/images/empty-cart.svg',
    './assets/images/custom-guide-empty/english-v1.mp4',
    './assets/images/custom-guide-empty/french-v1.mp4',
    './assets/images/edit-icon-v2.svg',
    './assets/images/item-added.svg',
    './assets/images/item-removed.svg',
    './assets/images/empty-critical-items-guide/empty-critical-items-en-v4.mp4',
    './assets/images/empty-critical-items-guide/empty-critical-items-fr-v4.mp4',
    './assets/images/close-icon.svg',
    './assets/images/logo_en_CA.svg',
    './assets/images/logo_fr_CA.svg',
    './assets/images/logo_en_CA_print-v2.svg',
    './assets/images/logo_fr_CA_print-v2.svg',
    './assets/images/urs_logo_black_2.svg',
    './assets/images/urs_logo_black_2.svg',
    './assets/images/custom_guide_help_en_CA_1x.png',
    './assets/images/custom_guide_help_en_CA_2x.png',
    './assets/images/custom-guide-help/custom-guide-help-en.svg',
    './assets/images/custom-guide-help/custom-guide-help-fr.svg',
  ];

  // Icons to preload and be available on offline mode
  // Remove any icon registration on modules if it's added in this list.
  public static readonly SVG_PRE_LOAD_CACHED = [
    NaooConstants.icons.address,
    NaooConstants.icons.offline_icon,
    NaooConstants.icons.full_error,
    NaooConstants.icons.triple_dot_menu,
    NaooConstants.icons.triple_dot_menu_white,
    NaooConstants.icons.edit_icon,
    NaooConstants.icons.download,
    NaooConstants.icons.clear_icon,
    NaooConstants.icons.arrow,
    NaooConstants.icons.arrow_gray,
    NaooConstants.icons.arrow_v2,
    NaooConstants.icons.minus,
    NaooConstants.icons.plus,
    NaooConstants.icons.plus_v2,
    NaooConstants.icons.plus_icon,
    NaooConstants.icons.cancel_icon,
    NaooConstants.icons.search_icon_v2,
    NaooConstants.icons.hamburger_icon,
    NaooConstants.icons.bang_icon,
    NaooConstants.icons.clear_icon_v2,
    NaooConstants.icons.store_icon_gray,
    NaooConstants.icons.quick_add_icon,
    NaooConstants.icons.cart_icon_v2,
    NaooConstants.icons.language_icon,
    NaooConstants.icons.calendar_v3,
    NaooConstants.icons.bell,
    NaooConstants.icons.calendar_invalid,
    NaooConstants.icons.clear_filter_icon,
    NaooConstants.icons.filter_icon,
    NaooConstants.icons.gear_icon,
    NaooConstants.icons.print_v2,
    NaooConstants.icons.trash_icon_v3,
    NaooConstants.icons.trash_icon,
    NaooConstants.icons.hand_string,
    NaooConstants.icons.move_to_icon,
    NaooConstants.icons.critical_item_star,
    NaooConstants.icons.horizontal_triple_dot,
    NaooConstants.icons.horizontal_triple_dot_v2,
    NaooConstants.icons.number,
    NaooConstants.icons.calendar,
    NaooConstants.icons.store,
    NaooConstants.icons.filled_info_icon,
    NaooConstants.icons.info_filled_icon_v2,
    NaooConstants.icons.warningV1,
    NaooConstants.icons.import_icon,
    NaooConstants.icons.create_custom_guide_icon,
    NaooConstants.icons.refresh_icon,
    NaooConstants.icons.list_icon_v2,
    NaooConstants.icons.grid_icon_v2,
    NaooConstants.icons.slim_icon,
    NaooConstants.icons.gordon_icon,
    NaooConstants.icons.united_ordering_icon,
    NaooConstants.icons.loyalty_icon,
  ];
}
