import { createSelector } from '@ngrx/store';
import { selectActiveStoreFulfillment } from '../store/store.selectors';
import { OrderMethodWidgetViewModel } from './order-method-widget-view-model';
import moment, { Moment } from 'moment';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import {
  selectActiveCustomerTimezone,
  selectCurrentSystem,
} from '../session/session.selectors';
import {
  selectCustomerArrivalDate,
  selectRouteDate,
} from '../cart/cart.selectors';
import { CurrentSystem } from '../../services/session/models/session-record';

export const selectOrderMethodWidgetViewModel = createSelector(
  selectCurrentSystem,
  selectActiveStoreFulfillment,
  selectCustomerArrivalDate,
  selectRouteDate,
  selectActiveCustomerTimezone,
  (
    currentSystem,
    activeStoreFulfillment,
    cachedCustomerArrivalDate,
    cachedRouteDate,
    customerTimeZone
  ): OrderMethodWidgetViewModel => {
    let routeDate: Moment;
    let customerArrivalDate: Date;
    let fulfillmentType: FulfillmentType = FulfillmentType.NONE;
    let deliveryWindowStartTimestamp: string;
    let deliveryWindowEndTimestamp: string;
    let selectedStoreName: string;
    const isSapOrMygfs = CurrentSystem.isMygfsOrSap(currentSystem);
    const expressWindow = activeStoreFulfillment?.storeFulfillment;
    if (expressWindow) {
      fulfillmentType = FulfillmentType.EXPRESS;
      deliveryWindowStartTimestamp = expressWindow.deliveryWindowStartTimestamp;
      deliveryWindowEndTimestamp = expressWindow.deliveryWindowEndTimestamp;
      routeDate = moment(deliveryWindowStartTimestamp);
    } else if (activeStoreFulfillment?.store?.name) {
      fulfillmentType = FulfillmentType.PICKUP;
      routeDate = activeStoreFulfillment.selectedDate;
      selectedStoreName = activeStoreFulfillment.store.name;
    } else if (isSapOrMygfs && !!cachedCustomerArrivalDate) {
      fulfillmentType = FulfillmentType.TRUCK;
      customerArrivalDate = cachedCustomerArrivalDate;
    } else if (!isSapOrMygfs && !!cachedRouteDate) {
      fulfillmentType = FulfillmentType.TRUCK;
      routeDate = moment(cachedRouteDate);
    }

    return {
      fulfillmentType,
      customerTimeZone,
      isSapOrMygfs,
      routeDate,
      customerArrivalDate,
      deliveryWindowStartTimestamp,
      deliveryWindowEndTimestamp,
      selectedStoreName,
    };
  }
);
