import {
  initialSalesCriticalItemsState,
  SalesCriticalItemsState,
} from './sales-critical-items.state';
import { createReducer, on } from '@ngrx/store';
import { SalesCriticalItemsActions } from './sales-critical-items.actions';

export const salesCriticalItemsReducer = createReducer(
  initialSalesCriticalItemsState,
  on(
    SalesCriticalItemsActions.getSalesCriticalItemsSuccess,
    (state, action): SalesCriticalItemsState => ({
      ...state,
      salesCriticalItems: action.salesCriticalItems,
    })
  ),
  on(
    SalesCriticalItemsActions.getSalesCriticalItemsFailure,
    (state): SalesCriticalItemsState => ({
      ...state,
      salesCriticalItems: [],
    })
  ),
  on(
    SalesCriticalItemsActions.refreshSalesCriticalItems,
    (): SalesCriticalItemsState => initialSalesCriticalItemsState
  ),
  on(
    SalesCriticalItemsActions.getSalesCriticalItems,
    (state): SalesCriticalItemsState => state
  )
);
