import { Localized } from '../../../../shared/models/localized';

export enum StockType {
  SpecialOrder = 'SO',
  SpecialOrderSAP = 'NSM',
  DropShip = 'EPO',
  Jit = 'JIT',
}

export interface MaterialAvailabilitiesRecord {
  materialAvailabilities: MaterialAvailabilityRecord[];
}

export interface MaterialAvailabilityRecord {
  materialNumber: string;
  cutoffCode: string;
  stockType: StockType;
  units: UnitAvailabilityRecord[];
  isContract: boolean;
  isLocal: boolean;
  isOrderable: boolean;
  salesStatus: Localized<string>;
}

export interface UnitAvailabilityRecord {
  uom: string;
  validationQty: number;
}

export function isAvailableByUom(
  materialAvailability: MaterialAvailabilityRecord,
  uom: string
): boolean {
  return materialAvailability
    ? materialAvailability.isOrderable &&
        materialAvailability.units.map((unit) => unit.uom).includes(uom)
    : false;
}
