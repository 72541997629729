import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MultipleCartsActions } from './multiple-carts.actions';
import { Observable } from 'rxjs';
import {
  selectIsLoadingSavedCarts,
  selectSavedCarts,
} from './multiple-carts.selectors';
import { SavedCart, SavedCarts } from './multiple-carts.state';
import { CartActionType } from '../../../shared/bulk-add-modal/bulk-add-modal.component';
import { CartQuantityUpdate } from '../cart/cart.actions';

@Injectable({ providedIn: 'root' })
export class MultipleCartsFacade {
  constructor(private store: Store) {}

  throttledRefreshSavedCarts() {
    this.store.dispatch(MultipleCartsActions.throttledRefreshSavedCarts());
  }

  refreshSavedCarts() {
    this.store.dispatch(MultipleCartsActions.refreshSavedCarts());
  }

  saveActiveCart(
    redirectToSavedCarts?: boolean,
    cartActionType?: CartActionType,
    materialsToBeAddedToCart?: CartQuantityUpdate[]
  ) {
    this.store.dispatch(
      MultipleCartsActions.saveActiveCart(
        redirectToSavedCarts,
        cartActionType,
        materialsToBeAddedToCart
      )
    );
  }

  submitCartForApproval() {
    this.store.dispatch(MultipleCartsActions.submitCartForApproval());
  }

  activateSavedCart(
    savedCart: SavedCart,
    shouldSaveActiveCart: boolean,
    mergeWithCurrentCart?: boolean
  ) {
    this.store.dispatch(
      MultipleCartsActions.activateSavedCart(
        savedCart,
        shouldSaveActiveCart,
        mergeWithCurrentCart
      )
    );
  }

  deleteSavedCart(cartId: string) {
    this.store.dispatch(MultipleCartsActions.deleteSavedCart(cartId));
  }

  getSavedCarts(): Observable<SavedCarts> {
    return this.store.select(selectSavedCarts);
  }

  isLoadingSavedCarts(): Observable<boolean> {
    return this.store.select(selectIsLoadingSavedCarts);
  }
}
