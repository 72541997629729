import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Moment } from 'moment';
import { ExpressDeliveryWindow } from '../../../../../../../core/services/express-schedules/models/express-schedule-record';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { FulfillmentType } from '../../../../../../../core/services/cart/models/cart-record';

@Component({
  selector: 'naoo-select-date-step-express',
  templateUrl: './select-date-step-express.component.html',
  styleUrls: ['./select-date-step-express.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDateStepExpressComponent {
  @Input() fulfillmentType: FulfillmentType;
  @Input() availableDates: string[];
  @Input() availableExpressDeliveryWindows: ExpressDeliveryWindow[];
  @Input() selectedDate: Moment;
  @Input() selectedExpressDeliveryWindow: ExpressDeliveryWindow;
  @Input() customerTimeZone: string;
  @Input() isMobileWidth: boolean;
  @Input() isRouteDateExpired: boolean;

  @Output() dateChangeEmitter = new EventEmitter<Moment>();
  @Output()
  expressDeliveryWindowChangeEmitter = new EventEmitter<ExpressDeliveryWindow>();

  @ViewChildren('timeFocus') timeFocus: QueryList<MatRadioButton>;

  selectTime(event: MatRadioChange): void {
    this.expressDeliveryWindowChangeEmitter.emit(
      event.value as ExpressDeliveryWindow
    );
  }

  tabToTimeSelection(): void {
    setTimeout(() =>
      this.timeFocus.find((element) => element.checked === true)?.focus()
    );
  }
}
