import { Injectable } from '@angular/core';
import { BehaviorSubject, delay } from 'rxjs';
import { CartFacade } from '../../../core/store/cart/cart.facade';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvalidSessionService {
  private readonly waitForInflightRequestsToCompleteMs = 2000;
  private sessionInvalid$ = new BehaviorSubject<boolean>(false);

  constructor(private cartFacade: CartFacade) {
    this.sessionInvalid$
      .pipe(
        delay(this.waitForInflightRequestsToCompleteMs),
        distinctUntilChanged()
      )
      .subscribe((isInvalid) => {
        if (isInvalid) {
          this.cartFacade.openFulfillmentModal();
          this.sessionInvalid$.next(false); // reset
        }
      });
  }

  public setSessionInvalid(): void {
    this.sessionInvalid$.next(true);
  }
}
