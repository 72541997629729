import { Component, Input } from '@angular/core';
import { LogoutService } from '../../../shared/services/logout/logout.service';
import { Router } from '@angular/router';
import { SessionFacade } from '../../../core/store/session/session.facade';
import { map } from 'rxjs/operators';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { CustomerBrand } from '../../../core/services/session/models/session-record';

@Component({
  selector: 'naoo-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent {
  @Input() isDisabled: boolean;
  @Input() customerBrand: CustomerBrand;

  isCustomerSelected$ = this.sessionFacade
    .getLoadedSession()
    .pipe(map((session) => !!session.activeCustomer));
  isMenuOpen = false;

  constructor(
    private router: Router,
    private logoutService: LogoutService,
    private sessionFacade: SessionFacade,
    private dialogService: CustomDialogService
  ) {}

  navigateToPreferences() {
    this.router.navigateByUrl('/preferences');
  }

  showSupportDialog() {
    this.dialogService.supportContactModal(this.customerBrand);
  }

  logOut() {
    this.logoutService.logout();
  }
}
