import { createActionGroup, emptyProps } from '@ngrx/store';
import { StorePurchaseDetailsRecord } from '../../services/store-purchase-details/model/store-purchase-details-record';
import { StorePurchaseKey } from '../../../shared/models/store-purchase-key';

export const StorePurchaseDetailsActions = createActionGroup({
  source: 'Store Purchase Details',
  events: {
    'Load Store Purchase Details': (storePurchaseKey: StorePurchaseKey) => ({
      storePurchaseKey,
    }),
    'Get Store Purchase Details': (storePurchaseKey: StorePurchaseKey) => ({
      storePurchaseKey,
    }),
    'Get Store Purchase Details Success': (
      storePurchaseDetailsRecord: StorePurchaseDetailsRecord
    ) => ({ storePurchaseDetailsRecord }),
    'Get Store Purchase Details Error': (
      storePurchaseKey: StorePurchaseKey
    ) => ({
      storePurchaseKey,
    }),
    'Refresh Store Purchase Details': emptyProps(),
    'Clear Store Purchase Details': emptyProps(),
  },
});
