import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomerNotificationPreferencesActions } from './customer-notification-preferences.actions';
import { NotificationEntryState } from './customer-notification-preferences.state';
import { Action, Store } from '@ngrx/store';
import {
  selectEmailNotificationStates,
  selectShouldDisplayErrorPage,
  selectSmsNotificationStates,
} from './customer-notification-preferences.selectors';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import { CustomerNotificationPreferencesService } from '../../services/customer-notification-preferences/customer-notification-preferences.service';
import { ErrorActions } from '../error/error.actions';
import { NotificationRecord } from '../../services/customer-notification-preferences/models/customer-notification-preferences-record';
import { SharedActions } from '../shared/shared.actions';
import { Observable, of } from 'rxjs';
import { OfflineModeFacade } from '../offline-mode/offline-mode.facade';
import { CustomerNotificationPreferencesFacade } from './customer-notification-preferences.facade';

@Injectable()
export class CustomerNotificationPreferencesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private defaultDialogService: DefaultDialogService,
    private customerNotificationPreferencesFacade: CustomerNotificationPreferencesFacade,
    private customerNotificationPreferencesService: CustomerNotificationPreferencesService,
    private offlineModeFacade: OfflineModeFacade
  ) {}

  getCustomerNotificationPreferences$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CustomerNotificationPreferencesActions.getCustomerNotificationPreferences
      ),
      switchMap(() => {
        return this.customerNotificationPreferencesService
          .getCustomerNotificationPreferences()
          .pipe(
            map((preferencesRecord) => {
              return CustomerNotificationPreferencesActions.getCustomerNotificationPreferencesSuccess(
                preferencesRecord
              );
            }),
            catchError(() =>
              of(
                CustomerNotificationPreferencesActions.getCustomerNotificationPreferencesFailure()
              )
            ),
            takeUntil(this.refreshCustomerNotificationPreferences$)
          );
      })
    );
  });

  refreshCustomerNotificationPreferences$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          CustomerNotificationPreferencesActions.refreshCustomerNotificationPreferences
        ),
        map(() =>
          CustomerNotificationPreferencesActions.getCustomerNotificationPreferences()
        )
      );
    }
  );

  getCustomerNotificationPreferencesFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CustomerNotificationPreferencesActions.getCustomerNotificationPreferencesFailure
      ),
      concatLatestFrom(() => [
        this.store.select(selectShouldDisplayErrorPage),
        this.offlineModeFacade.getIsOnline(),
      ]),
      map(([_, errorPageDisplayed, isOnline]) => {
        if (!errorPageDisplayed && isOnline) {
          this.defaultDialogService.twoButtonModal(
            'refresh-customer-preferences',
            'PREFERENCES.MODALS.ERROR',
            'PREFERENCES.MODALS.REFRESH',
            'PREFERENCES.MODALS.CANCEL',
            this.customerNotificationPreferencesFacade
              .refreshCustomerNotificationPreferences,
            () => {},
            true,
            true
          );
          return SharedActions.noOperation(
            'getCustomerNotificationPreferencesFailure$: showModal: true'
          );
        }
        return SharedActions.noOperation(
          'getCustomerNotificationPreferencesFailure$: showModal: false'
        );
      })
    );
  });

  updateEmailNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerNotificationPreferencesActions.updateEmailNotifications),
      concatLatestFrom(() => [
        this.store.select(selectEmailNotificationStates),
        this.store.select(selectSmsNotificationStates),
      ]),
      map(([_, emailState, smsState]) =>
        CustomerNotificationPreferencesActions.updateCustomerNotificationPreferences(
          this.transformState(emailState),
          this.transformState(smsState)
        )
      )
    );
  });

  updateSmsNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerNotificationPreferencesActions.updateSmsNotifications),
      concatLatestFrom(() => [
        this.store.select(selectEmailNotificationStates),
        this.store.select(selectSmsNotificationStates),
      ]),
      map(([_, emailState, smsState]) =>
        CustomerNotificationPreferencesActions.updateCustomerNotificationPreferences(
          this.transformState(emailState),
          this.transformState(smsState)
        )
      )
    );
  });

  updateCustomerNotificationPreferences$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          CustomerNotificationPreferencesActions.updateCustomerNotificationPreferences
        ),
        switchMap((action) => {
          return this.customerNotificationPreferencesService
            .updateCustomerNotificationPreferences(
              action.emailNotifications,
              action.smsNotifications
            )
            .pipe(
              map((updatedCustomerPreferenceRecord) => {
                return CustomerNotificationPreferencesActions.updateCustomerNotificationPreferencesSuccess(
                  updatedCustomerPreferenceRecord
                );
              }),
              catchError((error) => of(ErrorActions.fatalError(error))),
              takeUntil(this.refreshCustomerNotificationPreferences$)
            );
        })
      );
    }
  );

  deleteEmailNotifications = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerNotificationPreferencesActions.deleteEmailNotification),
      map(() => {
        return CustomerNotificationPreferencesActions.updateEmailNotifications();
      })
    );
  });

  saveEmailNotifications = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerNotificationPreferencesActions.saveEmailNotification),
      map(() => {
        return CustomerNotificationPreferencesActions.updateEmailNotifications();
      })
    );
  });

  deleteSmsNotifications = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerNotificationPreferencesActions.deleteSmsNotification),
      map(() => {
        return CustomerNotificationPreferencesActions.updateSmsNotifications();
      })
    );
  });

  saveSmsNotifications = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerNotificationPreferencesActions.saveSmsNotification),
      map(() => {
        return CustomerNotificationPreferencesActions.updateSmsNotifications();
      })
    );
  });

  private transformState(
    notificationStates: NotificationEntryState[]
  ): NotificationRecord[] {
    const nestedRecords = notificationStates.map((notificationState) => {
      return notificationState.languages.map((language) => {
        return {
          methodName: notificationState.name,
          methodValue: notificationState.contactInfo,
          languageCode: language,
          subscriptionTypes: notificationState.subscriptionTypes,
          customerReminders: notificationState.customerReminders,
        };
      });
    });
    return ([] as NotificationRecord[]).concat(...nestedRecords);
  }
}
