import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ActiveCartSummaryState,
  CartSortType,
} from './active-cart-summary.state';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { CartEntityState } from '../cart/cart.state';
import { Dictionary } from '@ngrx/entity';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import {
  CartCounts,
  selectCartCounts,
  selectCartEntity,
  selectPoNumber,
} from '../cart/cart.selectors';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';
import {
  ActiveCartSummary,
  OrderQuantity,
} from '../../../cart/cart-summary/active-cart/shared/models/active-cart-summary';
import { ActiveCartSummaryTransformer } from './active-cart-summary-transformer';
import {
  selectActiveCustomer,
  selectLanguage,
} from '../session/session.selectors';
import { CustomerHeaderInfo } from '../cart-review/cart-review.selectors';
import {
  CustomerPermission,
  SessionActiveCustomer,
  Language,
} from '../../services/session/models/session-record';
import { selectRemainingCriticalItemMaterials } from '../critical-items/critical-items.selector';

export interface ActiveCartDetails {
  orderQuantity: OrderQuantity;
  customerHeaderInfo: CustomerHeaderInfo;
  poNumber: string;
  isPoRequired: boolean;
  sortBy: CartSortType;
}

const selectActiveCartSummaryState = createFeatureSelector<ActiveCartSummaryState>(
  'activeCartSummary'
);

export const selectQuickAddEnabled = createSelector(
  selectActiveCartSummaryState,
  (state) => state.quickAddEnabled
);

export const selectCartSortType = createSelector(
  selectActiveCartSummaryState,
  (state) => state.sortBy
);

const selectActiveCartDetails = createSelector(
  selectCartCounts,
  selectActiveCustomer,
  selectPoNumber,
  selectCartSortType,
  (
    cartCounts: CartCounts,
    customer: SessionActiveCustomer,
    poNumber: string,
    sortBy: CartSortType
  ): ActiveCartDetails => {
    return {
      orderQuantity: {
        lines: cartCounts.cartLineCount,
        items: cartCounts.totalQuantity,
      },
      customerHeaderInfo: {
        customerName: customer.name,
        customerId: customer.customerDisplayId,
      },
      poNumber,
      isPoRequired: customer.permissions.includes(
        CustomerPermission.PoRequired
      ),
      sortBy,
    };
  }
);

export const selectActiveCartSummary = createSelector(
  selectCartEntity,
  selectAllMaterialInfoRecordStates,
  selectQuickAddEnabled,
  selectActiveCartDetails,
  selectIsOffline,
  selectLanguage,
  selectRemainingCriticalItemMaterials,
  (
    cartEntityState: CartEntityState,
    infoMap: Dictionary<MaterialInfoRecordState>,
    isQuickAddEnabled: boolean,
    activeCartDetails: ActiveCartDetails,
    isOffline: boolean,
    currentLanguage: Language,
    criticalItems: string[]
  ): ActiveCartSummary => {
    return ActiveCartSummaryTransformer.transformActiveCartSummary(
      cartEntityState,
      infoMap,
      isQuickAddEnabled,
      activeCartDetails,
      isOffline,
      currentLanguage,
      criticalItems
    );
  }
);
