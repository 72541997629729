import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { customerUnitSelectionGuard } from './shared/guards/customer-unit-selection.guard';
import { orderConfirmationResolver } from './order-confirmation/shared/order-confirmation-resolver';
import { lockedToOrderGuideGuard } from './shared/guards/locked-to-order-guide.guard';
import { scheduledMaintenanceGuard } from './shared/guards/scheduled-maintenance.guard';
import { cartLoadedGuard } from './shared/guards/cart-loaded-guard';
import { expressSchedulesValidGuard } from './shared/guards/express-schedules-valid.guard';
import { punchOutGuard } from './shared/guards/punch-out.guard';
import { redirectGuard } from './shared/guards/redirect.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home-page/home-page.module').then((m) => m.HomePageModule),
    canActivate: [
      customerUnitSelectionGuard,
      lockedToOrderGuideGuard,
      scheduledMaintenanceGuard,
    ],
  },
  {
    path: 'customer-unit-selection',
    loadChildren: () =>
      import('./customer-unit-selection/customer-unit-selection.module').then(
        (m) => m.CustomerUnitSelectionModule
      ),
    canActivate: [customerUnitSelectionGuard],
    canDeactivate: [redirectGuard],
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('./categories/categories.module').then((m) => m.CategoriesModule),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'collections',
    loadChildren: () =>
      import('./marketing-guide/marketing-guide.module').then(
        (m) => m.MarketingGuideModule
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'mobile-hamburger-menu',
    loadChildren: () =>
      import('./mobile-hamburger-menu/mobile-hamburger-menu.module').then(
        (m) => m.MobileHamburgerMenuModule
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchModule),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./cart/cart-summary/cart-summary.module').then(
        (m) => m.CartSummaryModule
      ),
    canActivate: [
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
      cartLoadedGuard,
    ],
  },
  {
    path: 'product',
    loadChildren: () =>
      import('./product-details/product-details.module').then(
        (m) => m.ProductDetailsModule
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'my-id-card',
    loadChildren: () =>
      import('./my-id-card/my-id-card.module').then((m) => m.MyIdCardModule),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'guides',
    loadChildren: () =>
      import('./lists/lists.module').then((m) => m.ListsModule),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'cart-review',
    loadChildren: () =>
      import('./cart/cart-review/cart-review.module').then(
        (m) => m.CartReviewModule
      ),
    canActivate: [
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
      cartLoadedGuard,
      expressSchedulesValidGuard,
    ],
  },
  {
    path: 'order-confirmation',
    loadChildren: () =>
      import('./order-confirmation/order-confirmation.module').then(
        (m) => m.OrderConfirmationModule
      ),
    canActivate: [
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
      expressSchedulesValidGuard,
    ],
    resolve: {
      orderConfirmation: orderConfirmationResolver,
    },
  },
  {
    path: 'preferences',
    loadChildren: () =>
      import('./account/preferences/preferences.module').then(
        (m) => m.PreferencesModule
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundModule),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'info-unavailable',
    loadChildren: () =>
      import('./info-unavailable/info-unavailable.module').then(
        (m) => m.InfoUnavailableModule
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'scheduled-maintenance',
    loadChildren: () =>
      import('./scheduled-maintenance/scheduled-maintenance.module').then(
        (m) => m.ScheduledMaintenanceModule
      ),
    canActivate: [customerUnitSelectionGuard],
  },
  {
    path: 'product-comparison',
    loadChildren: () =>
      import('./material-comparison/material-comparison.module').then(
        (m) => m.MaterialComparisonModule
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'offline-error',
    loadChildren: () =>
      import('./offline/offline.module').then((m) => m.OfflineModule),
  },
  {
    path: 'punch-out',
    loadChildren: () =>
      import('./punch-out/punch-out.module').then((m) => m.PunchOutModule),
    canActivate: [
      punchOutGuard,
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
    ],
  },
  {
    path: 'site',
    loadChildren: () => import('./site/site.module').then((m) => m.SiteModule),
    canActivate: [customerUnitSelectionGuard],
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
