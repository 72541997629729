<mat-progress-bar
  class="loading-bar"
  *ngIf="isLoading$ | async"
  mode="indeterminate"
></mat-progress-bar>
<naoo-banner-message
  (click)="clicked()"
  *ngIf="!isOfflineBannerVisible"
></naoo-banner-message>
<naoo-banner-offline-container
  (visibilityChanged)="setOfflineBannerVisible($event)"
></naoo-banner-offline-container>
<ng-container *ngIf="!(isMobile$ | async); else mobileHeader">
  <div class="top-section">
    <div class="boxed-content">
      <naoo-header-tabs
        *ngIf="!showThinHeader"
        [isDisabled]="isOffline"
      ></naoo-header-tabs>
      <div class="right-side">
        <naoo-customer-unit-selector
          *ngIf="!showThinHeader"
          [isDisabled]="isOffline"
          [ngClass]="{
            disabled: isOffline
          }"
        ></naoo-customer-unit-selector>
        <naoo-language-menu
          class="naoo-language-menu sub-header-action"
          [isDisabled]="isOffline"
          [ngClass]="{
            disabled: isOffline
          }"
        ></naoo-language-menu>
        <naoo-account-menu
          class="sub-header-action"
          [isDisabled]="isOffline"
          [customerBrand]="customerBrand"
          [ngClass]="{
            disabled: isOffline
          }"
        ></naoo-account-menu>
      </div>
    </div>
  </div>
  <div class="mid-section">
    <div class="boxed-content">
      <naoo-generic-disableable-link
        class="logo"
        className="logo"
        [routingUrl]="HOME_PAGE_PATH"
        (clickEvent)="trackLogoClick()"
        [isDisabled]="isOffline"
      >
        <img
          *ngIf="!isOffline; else blackLogo"
          src="{{ 'LOGO.URL' | naoobrand: customerBrand | translate }}"
          alt="{{ 'LOGO.TEXT' | naoobrand: customerBrand | translate }}"
        />
      </naoo-generic-disableable-link>

      <ng-template [ngIf]="!showThinHeader">
        <naoo-search-bar
          [isMobile]="false"
          [isDisabled]="isOffline"
          #searchBar
        ></naoo-search-bar>
        <naoo-delivery-schedule-widget
          *ngIf="!isSapWithPermissions"
          [ngClass]="{
            disabled: isOffline
          }"
          [disabled]="isOffline"
        ></naoo-delivery-schedule-widget>

        <naoo-select-order-method-widget
          *ngIf="isSapWithPermissions"
          [ngClass]="{
            disabled: isOffline
          }"
          [disabled]="isOffline"
        ></naoo-select-order-method-widget>
        <div class="cart">
          <naoo-cart-icon class="cart"></naoo-cart-icon>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template [ngIf]="!showThinHeader">
    <div class="bottom-section">
      <div class="boxed-content">
        <naoo-sub-header
          [isDisabled]="isOffline"
          class="naoo-sub-header"
        ></naoo-sub-header>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #mobileHeader>
  <ng-container>
    <header>
      <div class="header mid-section">
        <naoo-hamburger-menu class="hamburger-container"></naoo-hamburger-menu>
        <naoo-generic-disableable-link
          class="logo"
          className="logo"
          [routingUrl]="HOME_PAGE_PATH"
          (clickEvent)="trackLogoClick()"
          [isDisabled]="isOffline"
        >
          <img
            *ngIf="!isOffline && !showBlackLogo; else blackLogo"
            src="{{ 'LOGO.URL' | naoobrand: customerBrand | translate }}"
            alt="{{ 'LOGO.TEXT' | naoobrand: customerBrand | translate }}"
          />
        </naoo-generic-disableable-link>
        <div class="cart" *ngIf="!showThinHeader">
          <naoo-search-button-mobile
            [isDisabled]="isOffline"
          ></naoo-search-button-mobile>
          <naoo-select-order-method-widget
            *ngIf="isSapWithPermissions"
            [ngClass]="{
              disabled: isOffline
            }"
            [disabled]="isOffline"
          ></naoo-select-order-method-widget>
          <naoo-cart-icon [isMobile]="isMobile$ | async"></naoo-cart-icon>
        </div>
      </div>
    </header>
  </ng-container>
</ng-template>
<ng-template #blackLogo>
  <img
    class="black-logo-img"
    src="{{ 'LOGO.STATIC' | naoobrand: customerBrand | translate }}"
    alt="{{ 'LOGO.TEXT' | naoobrand: customerBrand | translate }}"
  />
</ng-template>
