import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'naoo-delete-custom-guide-toast',
  templateUrl: './delete-custom-guide-toast.component.html',
})
export class DeleteCustomGuideToastComponent implements OnInit {
  customGuideName: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { customGuideName: string }
  ) {}

  ngOnInit() {
    this.customGuideName = this.data.customGuideName;
  }
}
