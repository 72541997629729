import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { CustomerPreferencesActions } from './customer-preferences.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { ErrorActions } from '../error/error.actions';
import { CustomerPreferencesService } from '../../services/customer-preferences/customer-preferences.service';
import { selectOrderConfirmationEmailNotificationsOptOut } from './customer-preferences.selectors';
import { SharedActions } from '../shared/shared.actions';
import { selectPreferencesNotifications } from '../customer-notification-preferences/customer-notification-preferences.selectors';
import {
  NotificationRecord,
  NotificationSubscriptionType,
} from '../../services/customer-notification-preferences/models/customer-notification-preferences-record';
import { CustomerNotificationPreferencesActions } from '../customer-notification-preferences/customer-notification-preferences.actions';

@Injectable()
export class CustomerPreferencesEffects {
  constructor(
    private actions$: Actions,
    private customerPreferencesService: CustomerPreferencesService,
    private store: Store
  ) {}

  getCustomerPreferences$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerPreferencesActions.getCustomerPreferences),
      switchMap(() => {
        return this.customerPreferencesService.getPreferences().pipe(
          map((preferencesRecord) => {
            return CustomerPreferencesActions.getCustomerPreferencesSuccess(
              preferencesRecord
            );
          }),
          catchError(() =>
            of(CustomerPreferencesActions.getCustomerPreferencesFailure())
          ),
          takeUntil(this.refreshCustomerPreferences$)
        );
      })
    );
  });

  updateExportDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerPreferencesActions.updateExportDetailsPreferences),
      mergeMap((action) => {
        return this.customerPreferencesService
          .updateExportDetailPreferences(action.exportDetail)
          .pipe(
            map((updatedCustomerPreferenceRecord) => {
              return CustomerPreferencesActions.updateCustomerPreferencesSuccess(
                updatedCustomerPreferenceRecord
              );
            }),
            catchError((error) => of(ErrorActions.silentError(error)))
          );
      })
    );
  });

  updatePreferredStorePlantId$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerPreferencesActions.updateCustomerPreference),
      switchMap((action) => {
        return this.customerPreferencesService
          .updateCustomerPreferences(action.updatedCustomerPreferences)
          .pipe(
            map((updatedCustomerPreferenceRecord) => {
              return CustomerPreferencesActions.updateCustomerPreferencesSuccess(
                updatedCustomerPreferenceRecord
              );
            }),
            catchError((error) => of(ErrorActions.silentError(error)))
          );
      })
    );
  });

  orderConfirmationEmailOptOut$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerPreferencesActions.orderConfirmationEmailOptOut),
      concatLatestFrom(() =>
        this.store.select(selectOrderConfirmationEmailNotificationsOptOut)
      ),
      mergeMap(([action, currentOptOuts]) => {
        if (currentOptOuts) {
          const uniqueOptOuts = new Set(
            currentOptOuts.map((optOut) => optOut.toLowerCase())
          );
          if (
            uniqueOptOuts.has(
              action.orderConfirmationEmailNotificationsOptOut.toLowerCase()
            )
          ) {
            return of(
              SharedActions.noOperation('Email Address Already In Opt Out.')
            );
          }
        }

        return this.customerPreferencesService
          .updateOptOutEmailList(
            action.orderConfirmationEmailNotificationsOptOut
          )
          .pipe(
            map((customerPreferencesRecord) =>
              CustomerPreferencesActions.updateCustomerPreferencesSuccess(
                customerPreferencesRecord
              )
            ),
            catchError((error) => of(ErrorActions.fatalError(error))),
            takeUntil(this.refreshCustomerPreferences$)
          );
      })
    );
  });

  orderConfirmationEmailOptIn$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerPreferencesActions.orderConfirmationEmailOptIn),
      concatLatestFrom(() => this.store.select(selectPreferencesNotifications)),
      map(([action, notifications]) => {
        const newEmailNotification: NotificationRecord = {
          methodName: '',
          methodValue: action.email,
          languageCode: action.language,
          subscriptionTypes: [NotificationSubscriptionType.ORDER_CONFIRMATION],
        };

        if (!notifications.emailNotifications) {
          return CustomerNotificationPreferencesActions.updateCustomerNotificationPreferences(
            [newEmailNotification],
            notifications.smsNotifications
          );
        }
        const currentLowerCaseEmails = notifications.emailNotifications
          .filter((email) =>
            email.subscriptionTypes.includes(
              NotificationSubscriptionType.ORDER_CONFIRMATION
            )
          )
          .map((notification) => notification.methodValue.toLowerCase());

        if (currentLowerCaseEmails.includes(action.email.toLowerCase())) {
          return SharedActions.noOperation(
            'Email Address Already In Email Notifications.'
          );
        }

        return CustomerNotificationPreferencesActions.updateCustomerNotificationPreferences(
          notifications.emailNotifications.concat(newEmailNotification),
          notifications.smsNotifications
        );
      })
    );
  });

  refreshCustomerPreferences$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerPreferencesActions.refreshCustomerPreferences),
      map(() => CustomerPreferencesActions.getCustomerPreferences())
    );
  });
}
