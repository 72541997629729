import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { NaooConstants } from '../NaooConstants';

export const punchOutGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): boolean => {
  const router = inject(Router);
  const punchOutOrderMessage = router.getCurrentNavigation()?.extras?.state
    ?.punchOutOrderMessage;

  const isValid = !!punchOutOrderMessage;
  if (!isValid) {
    router.navigate([NaooConstants.HOME_PAGE_PATH]);
  }
  return isValid;
};
