import { Injectable } from '@angular/core';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { MaterialAvailabilitiesRecord } from './model/material-availabilities-record';

@Injectable({
  providedIn: 'root',
})
export class MaterialAvailabilityService {
  constructor(
    private naooHttpClient: NaooHttpClient,
    private bffService: WebBffService
  ) {}

  getMaterialAvailabilities(
    materialNumbers: string[]
  ): Observable<MaterialAvailabilitiesRecord> {
    return this.naooHttpClient.post<MaterialAvailabilitiesRecord>(
      `${this.bffService.getBff()}/api/v1/materials/availability`,
      materialNumbers
    );
  }
}
