import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { EcommerceAnalyticsActions } from './ecommerce-analytics.actions';
import { MaterialRecommendationsRecord } from '../../../shared/services/material-recommendations/models/material-recommendations-record';
import { CartUpdateRequest } from '../../services/cart/models/cart-record';
import {
  AuxiliaryAnalyticsData,
  GAEvent,
  PromotionEventData,
} from '../../services/ecommerce-analytics/models/google-events';
import { MaterialRowContext } from '../material-row/models/material-row';

@Injectable({ providedIn: 'root' })
export class EcommerceAnalyticsFacade {
  constructor(private store: Store) {}

  updateMaterialRecommendations(
    materialRecommendations: MaterialRecommendationsRecord[]
  ): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.updateMaterialRecommendations(
        materialRecommendations
      )
    );
  }

  updateBehavioralAdvertising(behavioralAdvertising: boolean): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.updateBehavioralAdvertising(
        behavioralAdvertising
      )
    );
  }

  trackGoogleAnalyticsEvent(gaEvent: GAEvent): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.googleAnalyticsEvent(gaEvent)
    );
  }

  trackRecommendationViewEvent(materialNumber: string): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.recommendationViewEvent(materialNumber)
    );
  }

  trackRecommendationClickEvent(materialNumber: string): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.recommendationClickEvent(materialNumber)
    );
  }

  trackPromotionViewEvent(data: PromotionEventData): void {
    this.store.dispatch(EcommerceAnalyticsActions.promotionViewEvent(data));
  }

  trackPromotionClickEvent(data: PromotionEventData): void {
    this.store.dispatch(EcommerceAnalyticsActions.promotionClickEvent(data));
  }

  trackMaterialDetailsViewEvent(materialNumber: string): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.materialDetailsViewEvent(materialNumber)
    );
  }

  trackCartUpdateEvent(cartUpdateRequest: CartUpdateRequest): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.updateCartEvent(cartUpdateRequest)
    );
  }

  trackPurchaseCartEvent(hasPendingOrders: boolean): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.purchaseEvent(hasPendingOrders)
    );
  }

  trackCheckoutCartEvent(): void {
    this.store.dispatch(EcommerceAnalyticsActions.checkoutEvent());
  }

  trackSavedCartEvent(activateSavedCart: boolean): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.googleSavedCartEvent(activateSavedCart)
    );
  }

  trackCustomerLogoutEvent(): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.googleCustomerLoginEvent(false)
    );
  }

  trackGoogleViewItemList(
    materialNumbers: string[],
    context: MaterialRowContext,
    data?: {
      analytics?: AuxiliaryAnalyticsData;
      startIndex?: number;
    }
  ): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.googleViewItemListEvent(
        materialNumbers,
        context,
        data
      )
    );
  }

  trackNumberOfLoyaltyChecksSubmitted(numberOfChecks: number): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.redeemCheckRequest(numberOfChecks)
    );
  }

  trackGoogleViewItem(
    materialNumber: string,
    context: MaterialRowContext,
    analytics?: AuxiliaryAnalyticsData
  ): void {
    this.store.dispatch(
      EcommerceAnalyticsActions.googleViewItemEvent(
        materialNumber,
        context,
        analytics
      )
    );
  }

  trackAddToCartConflict(
    modalName: string,
    addToCartType: string,
    index: number,
    actionId: number,
    ctaText: string
  ): void {
    return this.store.dispatch(
      EcommerceAnalyticsActions.addToCartConflict(
        modalName,
        addToCartType,
        index,
        actionId,
        ctaText
      )
    );
  }
}
