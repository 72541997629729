import { Injectable } from '@angular/core';
import {
  NutritionBlock,
  NutritionBlockLine,
  NutritionInfo,
  NutritionLabel,
  NutritionLine,
} from './models/mygfs-nutrition';
import { PicRecord } from '../../services/mygfs-nutrition/models/pic-record';
import {
  MygfsNutritionConstants,
  NutrientMetadata,
} from './mygfs-nutrition-constants';
import { Language } from '../../services/session/models/session-record';
import { getCollator } from '../../../shared/collator/collator';

@Injectable({ providedIn: 'root' })
export class MygfsNutritionTransformerService {
  private static readonly detectUrlsRegex = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?\S*)?/gi;
  private static readonly detectEmailsRegex = /([a-zA-Z\d._-]+@[a-zA-Z\d._-]+\.[a-zA-Z\d_-]+)/gi;
  private static readonly detectExtensionRegex = /((\.)(biz|com|edu|gov|net|org)\b)/gi;

  private static convertPicRecord(record: PicRecord): NutritionInfo {
    const ingredients = record.itemIngredients;
    const ingredientsUrlMap = new Map<string, string>();

    ingredients
      ?.replace(MygfsNutritionTransformerService.detectEmailsRegex, '')
      .match(MygfsNutritionTransformerService.detectUrlsRegex)
      ?.filter(
        (url) =>
          !!url.match(MygfsNutritionTransformerService.detectExtensionRegex)
      )
      .forEach((url) => {
        const updatedUrl = url.includes('://') ? url : `https://${url}`;
        ingredientsUrlMap.set(url, updatedUrl);
      });

    return {
      name: record.itemDesc,
      ingredients,
      ingredientsUrlMap,
      servingInformation: MygfsNutritionTransformerService.buildServingInfo(
        record
      ),
      calorieInformation: MygfsNutritionTransformerService.buildCalorieInfo(
        record
      ),
      nutrients: MygfsNutritionTransformerService.buildNutrients(
        record,
        MygfsNutritionConstants.nutrients
      ),
      focusedNutrients: MygfsNutritionTransformerService.buildNutrients(
        record,
        MygfsNutritionConstants.focusedNutrients
      ),
      nutritionBlocks: MygfsNutritionTransformerService.buildNutritionBlocks(
        record
      ),
    };
  }

  private static buildServingInfo(record: PicRecord): NutritionLine {
    return {
      amount: record.servingSize,
      roundedAmount: undefined,
      referenceDailyIntakeAmount: 0,
      uom: record.servingSizeUnit,
      label: record.gramEquivalent + 'g',
      level: 0,
      shouldReferenceDailyIntake: true,
    };
  }

  private static buildCalorieInfo(record: PicRecord): NutritionLine {
    return {
      amount: record.caloriesKCLAmt,
      roundedAmount: record.caloriesKCLNLEAAmt,
      referenceDailyIntakeAmount: 0,
      uom: record.caloriesKCLUnitOfMeasure,
      label: record.caloriesKCLLabel,
      level: 0,
      shouldReferenceDailyIntake: true,
    };
  }

  private static buildNutritionBlocks(record: PicRecord): NutritionBlock[] {
    return [
      {
        label: 'School Equivalents',
        lineItems: MygfsNutritionTransformerService.buildSchoolLunchEquivalents(
          record,
          MygfsNutritionConstants.schoolEquivalents
        ),
      },
      {
        label: 'Fat Soluble Vitamins',
        lineItems: MygfsNutritionTransformerService.buildNutrientBlockLine(
          record,
          MygfsNutritionConstants.fatSolubleVitamins
        ),
      },
      {
        label: 'Water Soluble Vitamins',
        lineItems: MygfsNutritionTransformerService.buildNutrientBlockLine(
          record,
          MygfsNutritionConstants.waterSolubleVitamins
        ),
      },
      {
        label: 'Minerals',
        lineItems: MygfsNutritionTransformerService.buildNutrientBlockLine(
          record,
          MygfsNutritionConstants.minerals
        ),
      },
    ];
  }

  private static buildNutrients(
    record: PicRecord,
    nutrientMetadata: NutrientMetadata[]
  ): NutritionLine[] {
    return nutrientMetadata.map((metadata) => {
      const prefix: string = metadata.prefix;
      const line = {} as NutritionLine;
      Object.keys(MygfsNutritionConstants.suffixMapping).forEach(
        (nutritionLineKey) => {
          const accessKey = `${prefix}${
            MygfsNutritionConstants.suffixMapping[
              nutritionLineKey as keyof NutritionLine
            ]
          }`;

          line[nutritionLineKey as keyof NutritionLine] = record[
            accessKey
          ] as never;
        }
      );
      line.level = metadata.level;
      line.shouldReferenceDailyIntake = metadata.shouldReferenceDailyIntake;
      return line;
    });
  }

  private static buildNutrientBlockLine(
    record: PicRecord,
    nutrientMetadata: NutrientMetadata[]
  ): NutritionBlockLine[] {
    return nutrientMetadata.map((metadata) => {
      const prefix: string = metadata.prefix;
      const line = {} as NutritionBlockLine;
      Object.keys(MygfsNutritionConstants.nutrientBlockSuffixMapping).forEach(
        (nutritionLineKey) => {
          const accessKey = `${prefix}${
            MygfsNutritionConstants.nutrientBlockSuffixMapping[
              nutritionLineKey as keyof NutritionBlockLine
            ]
          }`;
          line[nutritionLineKey as keyof NutritionBlockLine] = record[
            accessKey
          ] as string;
        }
      );
      return line;
    });
  }

  private static buildSchoolLunchEquivalents(
    record: PicRecord,
    nutrientMetadata: NutrientMetadata[]
  ): NutritionBlockLine[] {
    return nutrientMetadata.map((metadata) => {
      const prefix: string = metadata.prefix;
      const line = {} as NutritionBlockLine;
      Object.keys(MygfsNutritionConstants.sleSuffixMapping).forEach(
        (nutritionLineKey) => {
          const accessKey = `${prefix}${
            MygfsNutritionConstants.sleSuffixMapping[
              nutritionLineKey as keyof NutritionBlockLine
            ]
          }`;
          line[nutritionLineKey as keyof NutritionBlockLine] = record[
            accessKey
          ] as string;
        }
      );
      return line;
    });
  }

  transformPicRecords(picRecords: PicRecord[]): NutritionLabel {
    return {
      nutritionInfos: picRecords
        .map(MygfsNutritionTransformerService.convertPicRecord)
        .sort((a, b) => getCollator(Language.en).compare(a.name, b.name)),
    };
  }
}
