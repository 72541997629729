import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { StorePurchaseDetailsRecord } from '../../services/store-purchase-details/model/store-purchase-details-record';

export interface StorePurchaseDetailsState
  extends EntityState<StorePurchaseDetailsRecordState> {}

export interface StorePurchaseDetailsRecordState {
  entityKey: string;
  hasLoaded: boolean;
  record: StorePurchaseDetailsRecord;
}

export const storePurchaseDetailsRecordStateAdapter = createEntityAdapter<StorePurchaseDetailsRecordState>(
  { selectId: (state) => state.entityKey }
);

export const initialStorePurchaseDetailsRecordState: StorePurchaseDetailsState = storePurchaseDetailsRecordStateAdapter.getInitialState();
