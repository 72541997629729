import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { OpenOrderItemsService } from '../../services/open-order-items/open-order-items.service';
import { selectRouteDate } from '../cart/cart.selectors';
import { SharedActions } from '../shared/shared.actions';
import { OpenOrderItemsActions } from './open-order-items.actions';

@Injectable()
export class OpenOrderItemsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private openOrderItemsService: OpenOrderItemsService
  ) {}

  getOpenOrderItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpenOrderItemsActions.getOpenOrderItems),
      concatLatestFrom(() => this.store.select(selectRouteDate)),
      mergeMap(([_, routeDate]) => {
        if (!routeDate) {
          return of(SharedActions.noOperation('No route date selected'));
        }

        return this.openOrderItemsService.getOpenOrderItems(routeDate).pipe(
          map((openOrderItems) =>
            OpenOrderItemsActions.getOpenOrderItemsSuccess(openOrderItems)
          ),
          catchError(() => of(OpenOrderItemsActions.getOpenOrderItemsError())),
          takeUntil(
            this.actions$.pipe(
              ofType(
                OpenOrderItemsActions.clearOpenOrderItems,
                OpenOrderItemsActions.refreshOpenOrderItems
              )
            )
          )
        );
      })
    );
  });

  refreshOpenOrderItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpenOrderItemsActions.refreshOpenOrderItems),
      map(() => OpenOrderItemsActions.getOpenOrderItems())
    );
  });
}
