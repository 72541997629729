import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { selectSearchResults } from './search.selectors';
import { SearchResults } from './shared/search-results';
import { SearchActions } from './search.actions';

@Injectable({ providedIn: 'root' })
export class SearchFacade {
  constructor(private store: Store) {}

  submitSearch(searchText: string) {
    this.store.dispatch(SearchActions.submitSearch(searchText));
  }

  newCatalogSearch(categoryKey: string) {
    this.store.dispatch(SearchActions.newCatalogSearch(categoryKey));
  }

  getSearchResults(): Observable<SearchResults> {
    return this.store.select(selectSearchResults);
  }

  loadMoreResults() {
    this.store.dispatch(SearchActions.loadMoreSearchResults());
  }

  toggleOrderGuideFilter(toggle: boolean) {
    this.store.dispatch(SearchActions.toggleOrderGuideFilter(toggle));
  }

  toggleAvailableTodayFilter(toggle: boolean) {
    this.store.dispatch(SearchActions.toggleAvailableTodayFilter(toggle));
  }

  viewMoreDefaultFilter(filterGroupNameEN: string, isViewingMore: boolean) {
    this.store.dispatch(
      SearchActions.viewMoreDefaultFilter(filterGroupNameEN, isViewingMore)
    );
  }

  collapseDefaultFilter(filterGroupNameEN: string, isCollapsed: boolean) {
    this.store.dispatch(
      SearchActions.collapseDefaultFilter(filterGroupNameEN, isCollapsed)
    );
  }

  toggleDefaultFilter(
    filterGroupNameEN: string,
    filterParam: string,
    filterNameEN: string,
    queryParamValue: string,
    isToggled: boolean
  ) {
    this.store.dispatch(
      SearchActions.toggleDefaultFilter(
        filterGroupNameEN,
        filterParam,
        filterNameEN,
        queryParamValue,
        isToggled
      )
    );
  }

  toggleTaxonomyFilter(categoryKey: string, taxonomyNameEN: string) {
    this.store.dispatch(
      SearchActions.toggleTaxonomyFilter(categoryKey, taxonomyNameEN)
    );
  }

  clearAllFilters() {
    this.store.dispatch(SearchActions.clearAllFilters());
  }

  searchMaterialClick(materialNumber: string) {
    this.store.dispatch(SearchActions.searchMaterialClick(materialNumber));
  }
}
