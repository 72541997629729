import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface GenericHelpModalComponentData {
  values: HelpModalValues;
  closeAction: () => void;
}

export interface HelpModalValues {
  title: string;
  sections?: HelpModalFixedSection[];
  expansions?: HelpModalExpansionSection[];
}

export interface HelpModalFixedSection {
  title: string;
  paragraphs: string[];
}

export interface HelpModalExpansionSection {
  title: string;
  paragraphs: string[];
  imageDetails?: HelpModalImageDetails;
}

export interface HelpModalImageDetails {
  listItems: string[];
  image: {
    altText: string;
    src: string;
    width: number;
    height: number;
  };
}

@Component({
  selector: 'naoo-generic-help-modal',
  templateUrl: './generic-help-modal.component.html',
  styleUrls: ['./generic-help-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericHelpModalComponent implements AfterViewInit {
  displayValues: HelpModalValues | null;
  closeAction: () => void;

  disableAnimation = true;

  constructor(@Inject(MAT_DIALOG_DATA) data: GenericHelpModalComponentData) {
    this.displayValues = this.parseHelpModalValues(data.values);
    this.closeAction = data.closeAction;
  }

  /**
   * There is a bug that the material expansion panels flash as expanded,
   * when inside of a material dialog component, when first opening a modal.
   * For now, this is the workaround found here https://github.com/angular/components/issues/13870
   */
  ngAfterViewInit(): void {
    setTimeout(() => (this.disableAnimation = false));
  }

  get getTitle() {
    return this.displayValues ? this.displayValues.title : '';
  }

  private parseHelpModalValues(
    data: string | HelpModalValues
  ): HelpModalValues | null {
    if (data && typeof data === 'object') {
      return data;
    }
    return null;
  }
}
