import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { EntitlementResponse } from './models/entitlement';

@Injectable({
  providedIn: 'root',
})
export class EntitlementService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getEntitlements(
    daysToQuery: number,
    deliveryDate: string
  ): Observable<EntitlementResponse> {
    return this.httpClient.post<EntitlementResponse>(
      this.webBffService.getBff() + '/api/v1/commodity/entitlements',
      {
        daysToQuery: daysToQuery,
        deliveryDate: deliveryDate,
      }
    );
  }
}
