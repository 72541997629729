import { Injectable } from '@angular/core';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { StorePurchaseHistoryRecords } from './model/store-purchase-history-record';
import { Observable } from 'rxjs';
import { StorePurchaseHistoryRequest } from './model/store-purchase-history-request';

@Injectable({ providedIn: 'root' })
export class StorePurchaseHistoryService {
  constructor(
    private naooHttpClient: NaooHttpClient,
    private bffService: WebBffService
  ) {}

  getStorePurchaseHistory(
    storePurchaseHistoryRequest: StorePurchaseHistoryRequest
  ): Observable<StorePurchaseHistoryRecords> {
    return this.naooHttpClient.post<StorePurchaseHistoryRecords>(
      `${this.bffService.getBff()}/api/v1/orders/store-purchase-history`,
      storePurchaseHistoryRequest
    );
  }
}
