import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FulfillmentType } from '../../../core/services/cart/models/cart-record';
import { Subject } from 'rxjs';
import {
  StepperOrientation,
  StepperSelectionEvent,
} from '@angular/cdk/stepper';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CartFacade } from '../../../core/store/cart/cart.facade';
import {
  ExpressDeliveryWindow,
  ExpressScheduleRecord,
} from '../../../core/services/express-schedules/models/express-schedule-record';
import { Moment } from 'moment';
import {
  OrderFulfillmentViewModel,
  OrderMethodModalSharedViewModel,
  StoreDetailsViewModel,
} from '../../../core/store/order-method-modal/order-method-modal-view-model';
import { OrderMethodModalFacade } from '../../../core/store/order-method-modal/order-method-modal.facade';
import {
  getDefaultDate,
  getDefaultExpressDeliveryWindow,
  getFulfillmentText,
  getSelectedDeliveryScheduleEntryRecord,
  getSelectedExpressScheduleRecord,
  isDateStepCompleted,
  momentExistsInAvailableDates,
} from './util/order-method-modal.utils';
import { DeliveryScheduleEntryRecord } from '../../../core/services/delivery-schedule/models/delivery-schedules-record';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { GordonNowChatService } from '../../gordon-now/gordon-now-chat.service';
import { EnvironmentSpecificService } from '../../services/environment-specific/environment-specific.service';
import { CustomDialogService } from '../../services/dialog/custom-dialog/custom-dialog.service';
import { BulkAddWorkflow } from '../../bulk-add-modal/bulk-add-modal.component';

export interface OrderMethodModalResult {
  fulfillmentType: FulfillmentType;
}

export enum OrderMethodStep {
  FULFILLMENT = 'fulfillment',
  DATE = 'date',
  LOCATION = 'location',
}

@Component({
  selector: 'naoo-order-method-modal',
  templateUrl: './order-method-modal.component.html',
  styleUrls: ['./order-method-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderMethodModalComponent implements OnDestroy, OnInit {
  private readonly tabletBreakpoint = '(max-width: 1150px)';
  private readonly mobileBreakpoint = '(max-width: 600px)';
  private readonly unsetAnimationDuration = '0';
  private readonly definedAnimationDuration = '500';
  readonly orderMethodTitle = 'ORDER_METHOD_MODAL.ORDER_METHOD.LABEL';
  readonly locationTitle = 'ORDER_METHOD_MODAL.LOCATION.LABEL';
  readonly pickupFulfillment = FulfillmentType.PICKUP;
  readonly truckFulfillment = FulfillmentType.TRUCK;

  @ViewChild('stepper') stepper: MatStepper;

  sharedViewModel: OrderMethodModalSharedViewModel;
  animationDuration: string = this.unsetAnimationDuration;
  isMobileWidth: boolean;

  private destroyed$ = new Subject<void>();
  private userSelectedStorePlantId: string;
  private userSelectedDate: Moment;
  private userSelectedExpressDeliveryWindow: ExpressDeliveryWindow;
  private userSelectedFulfillmentType: FulfillmentType;
  private hasFirstAnimationCompleted: boolean;

  // eslint-disable-next-line max-params
  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private cartFacade: CartFacade,
    private dialogRef: MatDialogRef<OrderMethodModalComponent>,
    private orderMethodModalFacade: OrderMethodModalFacade,
    private windowService: Window,
    private environmentSpecificService: EnvironmentSpecificService,
    private gnChatService: GordonNowChatService,
    private customDialogService: CustomDialogService,
    @Inject(MAT_DIALOG_DATA)
    public data?: {
      step?: OrderMethodStep;
      isImportOrder?: boolean;
      closeAction?: () => void;
    }
  ) {}

  ngOnDestroy(): void {
    if (this.data.closeAction) {
      this.data.closeAction();
    }
    this.gnChatService.updateGordonNow({ hidden: false });
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;

    this.breakpointObserver
      .observe([this.tabletBreakpoint, this.mobileBreakpoint])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        const isTabletBreakpoint = result.breakpoints[this.tabletBreakpoint];
        this.isMobileWidth = result.breakpoints[this.mobileBreakpoint];
        if (this.gnChatService.isChatbotHidden() !== isTabletBreakpoint) {
          this.gnChatService.updateGordonNow({ hidden: isTabletBreakpoint });
        }
        this.changeDetectorRef.markForCheck();
      });

    this.orderMethodModalFacade
      .getOrderMethodModalSharedViewModel()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sharedViewModel) => {
        this.sharedViewModel = sharedViewModel;
        this.changeDetectorRef.markForCheck();
      });
  }

  get dateTitle(): string {
    return FulfillmentType.PICKUP === this.fulfillmentType
      ? 'ORDER_METHOD_MODAL.DATE.PICKUP_LABEL'
      : 'ORDER_METHOD_MODAL.DATE.LABEL';
  }

  get viewModel(): OrderFulfillmentViewModel {
    return this.sharedViewModel?.viewModelMap?.get(this.fulfillmentType);
  }

  get fulfillmentType(): FulfillmentType | undefined {
    return (
      this.userSelectedFulfillmentType ||
      this.sharedViewModel?.selectedFulfillmentType ||
      this.sharedViewModel?.defaultFulfillmentType
    );
  }

  get expiredDateViewModel(): OrderFulfillmentViewModel {
    const currentSelectedViewModel = this.sharedViewModel?.viewModelMap?.get(
      this.sharedViewModel?.selectedFulfillmentType
    );
    return currentSelectedViewModel?.isSelectedDateExpired
      ? currentSelectedViewModel
      : undefined;
  }

  get expiredBannerTitleTranslationKey(): string {
    return FulfillmentType.PICKUP ===
      this.sharedViewModel?.selectedFulfillmentType
      ? 'ORDER_METHOD_MODAL.DATE.FORM.DATE_PICKER_EXPIRED_PICKUP_MESSAGE'
      : 'ORDER_METHOD_MODAL.DATE.FORM.DATE_PICKER_EXPIRED_MESSAGE';
  }

  get expiredBannerMessageTranslationKey(): string {
    return FulfillmentType.EXPRESS ===
      this.sharedViewModel?.selectedFulfillmentType
      ? 'ORDER_METHOD_MODAL.DATE.FORM.DATE_PICKER_EXPIRED_CURRENT_DATE_TIME'
      : 'ORDER_METHOD_MODAL.DATE.FORM.DATE_PICKER_EXPIRED_CURRENT_DATE';
  }

  get selectedStorePlantId(): string {
    return this.userSelectedStorePlantId || this.viewModel?.storePlantId;
  }

  get orientation(): StepperOrientation {
    return this.isMobileWidth ? 'vertical' : 'horizontal';
  }

  get date(): Moment | undefined {
    return (
      this.userSelectedDate ||
      this.viewModel?.selectedDate ||
      getDefaultDate(this.availableDates)
    );
  }

  get expressDeliveryWindow(): ExpressDeliveryWindow | undefined {
    return (
      this.userSelectedExpressDeliveryWindow ||
      getDefaultExpressDeliveryWindow(
        this.viewModel,
        this.fulfillmentType,
        this.date
      )
    );
  }

  get selectedExpressScheduleRecord(): ExpressScheduleRecord | undefined {
    return getSelectedExpressScheduleRecord(this.viewModel, this.date);
  }

  get isDateStepCompleted(): boolean {
    return (
      isDateStepCompleted(
        this.fulfillmentType,
        this.date,
        this.expressDeliveryWindow,
        this.selectedStorePlantId
      ) && momentExistsInAvailableDates(this.date, this.availableDates)
    );
  }

  get isOrderStepNextDisabled(): boolean {
    return !this.viewModel?.hasAvailableDates;
  }

  get cutoffDateTime(): string {
    return getSelectedDeliveryScheduleEntryRecord(this.viewModel, this.date)
      ?.cutoffDateTime;
  }

  get availableDates(): string[] {
    return FulfillmentType.PICKUP === this.fulfillmentType
      ? this.viewModel?.storeDetailsMap?.get(this.selectedStorePlantId)
          ?.availableDates || []
      : this.viewModel?.availableDates || [];
  }

  get storeDetailsViewModel(): StoreDetailsViewModel {
    return this.viewModel?.storeDetailsMap?.get(this.selectedStorePlantId);
  }

  stepperDoneAnimating(): void {
    if (this.hasFirstAnimationCompleted) {
      if (this.unsetAnimationDuration === this.animationDuration) {
        this.animationDuration = this.definedAnimationDuration;
      }
    } else {
      this.stepper.selectedIndex = this.getIndexForStep(this.data?.step);
      this.hasFirstAnimationCompleted = true;
    }
    this.changeDetectorRef.markForCheck();
  }

  private getIndexForStep(step: OrderMethodStep): number {
    if (!step) {
      if (
        this.viewModel?.selectedDate &&
        this.availableDates?.length &&
        !this.viewModel.isSelectedDateExpired
      ) {
        step = OrderMethodStep.DATE;
      } else {
        this.animationDuration = this.definedAnimationDuration;
        return 0;
      }
    }
    switch (step) {
      case OrderMethodStep.FULFILLMENT:
        return 0;
      case OrderMethodStep.LOCATION:
        return 1;
      case OrderMethodStep.DATE:
        return this.isFulfillmentType(FulfillmentType.PICKUP) ? 2 : 1;
      default:
        return 0;
    }
  }

  close(): void {
    this.dialogRef.close();
    if (this.data?.isImportOrder) {
      this.customDialogService.bulkAddImportChecker(BulkAddWorkflow.IMPORT);
    }
  }

  isFulfillmentType(fulfillmentType: FulfillmentType): boolean {
    return this.fulfillmentType === fulfillmentType;
  }

  isStepSelected(step: MatStep): boolean {
    return this?.stepper?.selected === step;
  }

  stepDidChange(event: StepperSelectionEvent): void {
    const orderStepSelected = event.selectedIndex === 0;
    // Reset user selected date when user goes to order method step
    if (orderStepSelected) {
      this.updateSelectedStorePlantId(undefined);
    }
  }

  stepBackActionForOrderMethod() {
    return () => {
      this.close();
    };
  }

  stepBackActionForDateStep() {
    return () => {
      this.updateUserDate(undefined);
    };
  }

  stepNextActionForDate() {
    return () => this.saveAction();
  }

  updateUserFulfillmentType(selectedFulfillmentType: FulfillmentType): void {
    this.userSelectedFulfillmentType = selectedFulfillmentType;
    this.changeDetectorRef.markForCheck();
  }

  updateSelectedStorePlantId(selectedStorePlantId: string): void {
    this.userSelectedStorePlantId = selectedStorePlantId;
    this.updateUserDate(undefined);
    this.changeDetectorRef.markForCheck();
  }

  updateUserDate(selectedDate: Moment): void {
    this.userSelectedDate = selectedDate;
    this.updateUserSelectedExpressDeliveryWindow(undefined);
    this.changeDetectorRef.markForCheck();
  }

  updateUserSelectedExpressDeliveryWindow(
    expressDeliveryWindow: ExpressDeliveryWindow
  ): void {
    this.userSelectedExpressDeliveryWindow = expressDeliveryWindow;
    this.changeDetectorRef.markForCheck();
  }

  fulfillmentText(fulfillmentType: FulfillmentType): string {
    return getFulfillmentText(fulfillmentType);
  }

  private saveAction(): void {
    if (FulfillmentType.PICKUP === this.fulfillmentType) {
      this.cartFacade.updatePickupStoreFulfillmentForOrderMethodModal(
        this.selectedStorePlantId,
        this.date
      );
    } else if (FulfillmentType.EXPRESS === this.fulfillmentType) {
      this.cartFacade.updateExpressStoreFulfillment(this.expressDeliveryWindow);
    } else if (FulfillmentType.TRUCK === this.fulfillmentType) {
      const deliveryScheduleEntryRecord: DeliveryScheduleEntryRecord = getSelectedDeliveryScheduleEntryRecord(
        this.viewModel,
        this.date
      );
      this.cartFacade.updateRouteDate(
        new Date(deliveryScheduleEntryRecord.routeDate),
        new Date(deliveryScheduleEntryRecord.customerArrivalDate)
      );
    }
    this.dialogRef.close(this.createOrderMethodModalResult());
    if (this.data?.isImportOrder) {
      this.customDialogService.bulkAddImportChecker(BulkAddWorkflow.IMPORT);
    }
  }

  private createOrderMethodModalResult(): OrderMethodModalResult {
    return {
      fulfillmentType: this.fulfillmentType,
    };
  }
}
