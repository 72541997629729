import { OrderGuideChangeHistoryRecord } from '../../services/order-guide-change-history/models/order-guide-change-history-record';
import { createActionGroup, emptyProps } from '@ngrx/store';

export const OrderGuideChangeHistoryActions = createActionGroup({
  source: 'OrderGuideChangeHistory',
  events: {
    'Get Order Guide Change History': emptyProps(),
    'Get Order Guide Change History Success': (
      payload: OrderGuideChangeHistoryRecord[]
    ) => ({ payload }),
    'Refresh Order Guide Change History': emptyProps(),
  },
});
