<ng-container *ngIf="!hasOrderHistoryError && orders?.length > 0">
  <div class="table-info">
    <article class="order-history-header has-orders">
      <h2 class="order-history-sub-title">
        {{ 'ORDERS.HISTORY.SUB_TITLE' | translate }}
      </h2>
    </article>
    <naoo-search-field
      class="search-field"
      #searchField
      (searchTextEmitter)="filterOrders($event)"
      (blurEmitter)="updateSearchParam($event)"
      [value]="searchTerm"
      [searchPlaceholderText]="'ORDERS.HISTORY.SEARCH'"
      [searchHintText]="'ORDERS.HISTORY.SEARCH_HINT'"
    ></naoo-search-field>
  </div>
  <div class="drop-shadow-spacer">
    <naoo-no-results
      *ngIf="filteredOrderViewModels.length === 0"
      [searchText]="this.searchTerm"
      [isMobile]="isMobile"
    ></naoo-no-results>
  </div>
  <article
    *ngIf="filteredOrderViewModels.length > 0"
    class="order-history-row order-header"
  >
    <div class="order-cell delivery-info">
      {{ 'ORDERS.HISTORY.DELIVERY_ETA' | translate }}
    </div>
    <div class="order-cell order-type">
      {{ 'ORDERS.HISTORY.ORDER_TYPE' | translate }}
    </div>
    <div class="order-cell order-number">
      {{ 'ORDERS.HISTORY.ORDER_NUMBER' | translate }}
    </div>
    <div class="order-cell order-submitted">
      {{ 'ORDERS.HISTORY.SUBMITTED' | translate }}
    </div>
    <div class="order-cell order-po">
      {{ 'ORDERS.HISTORY.PURCHASE_ORDER' | translate }}
    </div>
    <div class="order-cell total-weight">
      {{ 'ORDERS.HISTORY.TOTAL_WEIGHT' | translate }}
    </div>
    <div class="order-cell order-total">
      {{ 'ORDERS.HISTORY.INVOICE_AMOUNT' | translate }}
    </div>
    <div class="order-cell order-status">
      <div class="status-spacer">
        {{ 'ORDERS.HISTORY.STATUS' | translate }}
      </div>
    </div>
    <div class="order-cell order-details"></div>
  </article>
  <virtual-scroller
    #scroll
    [scrollAnimationTime]="0"
    [compareItems]="compareViewModels"
    [items]="filteredOrderViewModels"
    [enableUnequalChildrenSizes]="true"
    [parentScroll]="parentScrollElement"
  >
    <article
      [id]="'order-' + orderViewModel.orderNumber"
      class="order-history-row order-main"
      *ngFor="
        let orderViewModel of scroll.viewPortItems;
        trackBy: trackByOrderNumber
      "
      (click)="goToOrderDetails(orderViewModel.routeParams)"
    >
      <div class="order-cell delivery-info-label">
        {{ 'ORDERS.HISTORY.DELIVERY_ETA' | translate }}
      </div>
      <div class="order-cell delivery-info">
        <div>
          <div class="delivery-info-date-value">
            {{
              !!orderViewModel.deliveryInfoDate
                ? orderViewModel.deliveryInfoDate
                : ('ORDERS.HISTORY.TBD' | translate)
            }}
          </div>
          <div
            *ngIf="!!orderViewModel.deliveryInfoTime"
            class="delivery-info-time-value"
          >
            {{ orderViewModel.deliveryInfoTime }}
          </div>
          <div
            *ngIf="!!orderViewModel.deliveryInfoTracking"
            class="delivery-info-tracking-value"
          >
            {{ orderViewModel.deliveryInfoTracking }}
          </div>
        </div>
      </div>
      <div class="order-cell order-type">
        <div class="order-type-label">
          {{ 'ORDERS.HISTORY.ORDER_TYPE' | translate }}
        </div>
        <div class="order-type-info">
          <div *ngIf="orderViewModel.orderTypeKey" class="order-type-value">
            {{ orderViewModel.orderTypeKey | translate }}
          </div>
        </div>
      </div>
      <div
        class="order-cell order-number"
        [matTooltip]="orderViewModel.orderNumber"
      >
        <div class="order-number-label">
          {{ 'ORDERS.HISTORY.ORDER_NUMBER' | translate }}
        </div>
        <div class="order-number-info truncate">
          <div *ngIf="orderViewModel.orderNumber" class="order-number-value">
            {{ orderViewModel.orderNumber }}
          </div>
        </div>
      </div>
      <div class="order-cell order-submitted">
        <div class="order-submitted-label">
          {{ 'ORDERS.HISTORY.SUBMITTED' | translate }}
        </div>
        <div class="order-submitted-info">
          <div
            *ngIf="orderViewModel.orderDateTime"
            class="order-submitted-value"
          >
            {{ orderViewModel.orderDateTime }}
          </div>
          <div
            *ngIf="orderViewModel.submittedUserName"
            class="order-username-value truncate"
            [matTooltip]="orderViewModel.submittedUserName"
          >
            {{ 'ORDERS.HISTORY.BY' | translate }}
            {{ orderViewModel.submittedUserName }}
          </div>
        </div>
      </div>
      <div
        class="order-cell order-po"
        [matTooltip]="orderViewModel.customerPurchaseOrder"
      >
        <div class="order-po-label">
          {{ 'ORDERS.HISTORY.PURCHASE_ORDER' | translate }}
        </div>
        <div class="order-po-value truncate">
          {{ orderViewModel.customerPurchaseOrder }}
        </div>
      </div>
      <div
        class="order-cell total-weight"
        [matTooltip]="orderViewModel.totalWeightFull"
      >
        <div class="total-weight-label">
          {{ 'ORDERS.HISTORY.TOTAL_WEIGHT' | translate }}
        </div>
        <div class="total-weight-value-truncated">
          {{ orderViewModel.totalWeightTruncated }}
        </div>
        <div class="total-weight-value-full">
          {{ orderViewModel.totalWeightFull }}
        </div>
      </div>
      <div
        class="order-cell order-total"
        [matTooltip]="orderViewModel.totalPrice | naooprice"
      >
        <div class="order-total-label">
          {{ 'ORDERS.HISTORY.INVOICE_AMOUNT' | translate }}
        </div>
        <div class="order-total-value truncate">
          {{ orderViewModel.totalPrice | naooprice }}
        </div>
      </div>
      <div
        class="order-cell order-status"
        (click)="orderStatusClick($event)"
        [matTooltip]="orderViewModel.orderStatusTooltip | stringdefaulter"
      >
        <mat-icon
          svgIcon="{{ orderViewModel.orderStatusIcon }}"
          class="order-status-icon"
          [ngClass]="orderViewModel.orderStatusIconColor"
        ></mat-icon>
        <span
          class="order-status-value"
          [ngClass]="orderViewModel.orderStatusTextColor"
        >
          {{ orderViewModel.orderStatusText | stringdefaulter }}
        </span>
      </div>
      <div class="order-cell order-details">
        <a
          href="javascript:void(0)"
          (click)="goToOrderDetails(orderViewModel.routeParams)"
          >{{ 'ORDERS.HISTORY.VIEW_DETAILS' | translate }}</a
        >
      </div>
    </article>
  </virtual-scroller>
</ng-container>
<ng-container *ngIf="!hasOrderHistoryError && orders?.length === 0">
  <article class="order-history-header">
    <h2 class="order-history-sub-title">
      {{ 'ORDERS.HISTORY.SUB_TITLE' | translate }}
    </h2>
  </article>
  <article class="order-history-empty">
    <mat-icon class="order-history-empty-icon" svgIcon="empty-icon"></mat-icon>
    <h2 class="order-history-empty-title naoo-heading">
      {{ 'ORDERS.HISTORY.NO_HISTORY.TITLE' | translate }}
    </h2>
    <div class="order-history-empty-subtitle">
      {{ 'ORDERS.HISTORY.NO_HISTORY.SUBTITLE' | translate }}
    </div>
    <div class="order-history-empty-buttons">
      <button
        class="secondary-button large-button button-guides"
        (click)="goToGuides()"
      >
        {{ 'ORDERS.HISTORY.VIEW_GUIDES' | translate }}
      </button>
      <button
        class="primary-button large-button button-categories"
        (click)="goToCategories()"
      >
        {{ 'ORDERS.HISTORY.BROWSE_CATEGORIES' | translate }}
      </button>
    </div>
  </article>
</ng-container>
<ng-container *ngIf="hasOrderHistoryError">
  <article class="order-history-header">
    <h2 class="order-history-sub-title">
      {{ 'ORDERS.HISTORY.SUB_TITLE' | translate }}
    </h2>
  </article>
  <div class="order-history-error">
    <naoo-error-state
      alt="{{ 'ORDERS.HISTORY.ERROR.ALT' | translate }}"
      errorImageSource="assets/images/full-error.svg"
      errorTitle="{{ 'ERROR.TITLE' | translate }}"
      errorSubtitle="{{ 'ORDERS.HISTORY.ERROR.SUBTITLE' | translate }}"
      [showErrorButton]="false"
    >
    </naoo-error-state>
  </div>
</ng-container>
