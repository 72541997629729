import { createActionGroup } from '@ngrx/store';
import { MarketingGuideRecord } from '../../services/marketing-guide/models/marketing-guide-record';

export const MarketingGuideActions = createActionGroup({
  source: 'Marketing Guide',
  events: {
    'Get Marketing Guide': (pathUrl: string) => ({
      pathUrl,
    }),
    'Get Marketing Guide Success': (
      marketingGuideRecord: MarketingGuideRecord
    ) => ({
      marketingGuideRecord,
    }),
    'Get Marketing Guide Failure': (pathUrl: string) => ({
      pathUrl,
    }),
    'Update Marketing Guide Search Text': (
      pathUrl: string,
      searchText: string
    ) => ({
      pathUrl,
      searchText,
    }),
  },
});
