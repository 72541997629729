import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { StorePurchaseDetailsService } from '../../services/store-purchase-details/store-purchase-details.service';
import { Observable, of } from 'rxjs';
import { StorePurchaseDetailsActions } from './store-purchase-details.actions';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import {
  selectStorePurchaseDetailsDictionary,
  selectStorePurchaseKeyFromUrl,
} from './store-purchase-details.selectors';
import { SharedActions } from '../shared/shared.actions';
import { createStorePurchaseEntityKey } from '../../../shared/utilities/store-purchase-utilities';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { NaooConstants } from '../../../shared/NaooConstants';

@Injectable()
export class StorePurchaseDetailsEffects {
  readonly analyticsLabelViewDetails = 'view details';
  readonly analyticsLabelServiceError = 'store purchase details service error';

  constructor(
    private actions$: Actions,
    private store: Store,
    private storePurchaseDetailsService: StorePurchaseDetailsService,
    private analytics: NaooAnalyticsManager
  ) {}

  loadStorePurchaseDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorePurchaseDetailsActions.loadStorePurchaseDetails),
      concatLatestFrom(() =>
        this.store.select(selectStorePurchaseDetailsDictionary)
      ),
      map(([action, storeDetails]) => {
        this.trackPurchaseHistoryAnalytics(this.analyticsLabelViewDetails);
        const entityKey = createStorePurchaseEntityKey(action.storePurchaseKey);
        const recordState = storeDetails[entityKey];
        if (recordState?.record || false === recordState?.hasLoaded) {
          return SharedActions.noOperation(
            'loadStorePurchaseDetails$ storePurchaseDetailsRecord already exists'
          );
        }
        return StorePurchaseDetailsActions.getStorePurchaseDetails(
          action.storePurchaseKey
        );
      })
    );
  });

  getStorePurchaseDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorePurchaseDetailsActions.getStorePurchaseDetails),
      mergeMap((action) => {
        return this.storePurchaseDetailsService
          .getStorePurchaseDetails(action.storePurchaseKey)
          .pipe(
            mergeMap((purchaseDetailsRecord) => {
              const materialNumbers: string[] = purchaseDetailsRecord.saleItems.map(
                (saleItem) => saleItem.materialNumber
              );
              return [
                MaterialInfoActions.loadMaterialInfo(materialNumbers),
                MaterialAvailabilityActions.loadMaterialAvailability(
                  materialNumbers
                ),
                StorePurchaseDetailsActions.getStorePurchaseDetailsSuccess(
                  purchaseDetailsRecord
                ),
              ];
            }),
            catchError(() => {
              this.trackPurchaseHistoryAnalytics(
                this.analyticsLabelServiceError
              );
              return of(
                StorePurchaseDetailsActions.getStorePurchaseDetailsError(
                  action.storePurchaseKey
                )
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(StorePurchaseDetailsActions.clearStorePurchaseDetails)
              )
            )
          );
      })
    );
  });

  refreshStorePurchaseDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorePurchaseDetailsActions.refreshStorePurchaseDetails),
      concatLatestFrom(() => this.store.select(selectStorePurchaseKeyFromUrl)),
      map(([_, storePurchaseKey]) => {
        if (!storePurchaseKey) {
          return SharedActions.noOperation(
            'refreshStorePurchaseDetails$ not on purchase details record'
          );
        }
        return StorePurchaseDetailsActions.loadStorePurchaseDetails(
          storePurchaseKey
        );
      })
    );
  });

  private trackPurchaseHistoryAnalytics(label: string) {
    this.analytics.trackAnalyticsEvent({
      action: NaooConstants.ANALYTICS_ACTION_DISPLAYED,
      category: NaooConstants.ANALYTICS_CATEGORY_STORE_PURCHASE_HISTORY,
      label,
    });
  }
}
