import { CartStoreModule } from './cart/cart-store.module';
import { ActiveCartSummaryStoreModule } from './active-cart/active-cart-summary-store.module';
import { CriticalItemsStoreModule } from './critical-items/critical-items-store.module';
import { CustomerPreferencesStoreModule } from './customer-preferences/customer-preferences-store.module';
import { CustomerMaterialStoreModule } from './customer-material/customer-material-store.module';
import { DeliveryScheduleStoreModule } from './delivery-schedule/delivery-schedule-store.module';
import { EffectsModule } from '@ngrx/effects';
import { CustomerNotificationPreferencesStoreModule } from './customer-notification-preferences/customer-notification-preferences-store.module';
import { ErrorStoreModule } from './error/error-store.module';
import { InventoryAvailabilityStoreModule } from './inventory-availability/inventory-availability-store.module';
import { LastOrderedStoreModule } from './last-ordered/last-ordered-store.module';
import { MaterialAdditionalInfoStoreModule } from './material-additional-info/material-additional-info-store.module';
import { MaterialAvailabilityStoreModule } from './material-availability/material-availability-store.module';
import { MaterialComparisonStoreModule } from './material-comparison/material-comparison-store.module';
import { MaterialInfoStoreModule } from './material-info/material-info-store.module';
import { MaterialUnitsStoreModule } from './material-units/material-units-store.module';
import { MaterialWarningStoreModule } from './material-warning/material-warning-store.module';
import { ModalStoreModule } from './modal/modal-store.module';
import { MultipleCartsStoreModule } from './multiple-carts/multiple-carts-store.module';
import { NgModule } from '@angular/core';
import { OfflineModeStoreModule } from './offline-mode/offline-mode-store.module';
import { OpenOrderItemsStoreModule } from './open-order-items/open-order-items-store.module';
import { OrderGuideChangeHistoryStoreModule } from './order-guide-change-history/order-guide-change-history-store.module';
import { OrderGuideStoreModule } from './order-guide/order-guide-store.module';
import { MaterialCutoffStoreModule } from './material-cutoff/material-cutoff-store.module';
import { RouterStoreModule } from './router/router-store.module';
import { SessionStoreModule } from './session/session-store.module';
import { SearchStoreModule } from './search/search-store.module';
import { StoreModule } from '@ngrx/store';
import { BannerAdsStoreModule } from './banner-ads/banner-ads-store.module';
import { MaterialRowStoreModule } from './material-row/material-row-store.module';
import { CustomGuideStoreModule } from './custom-guide/custom-guide-store.module';
import { EcommerceAnalyticsStoreModule } from './ecommerce-analytics/ecommerce-analytics-store.module';
import { MaterialRelatedStoreModule } from './material-related/material-related-store.module';
import { SpecialOrdersStoreModule } from './special-orders/special-orders-store.module';
import { MygfsNutritionStoreModule } from './mygfs-nutrition/mygfs-nutrition-store.module';
import { EntitlementStoreModule } from './entitlement/entitlement-store.module';
import { SalesCriticalItemsStoreModule } from './sales-critical-items/sales-critical-items.module';
import { CommodityPriceStoreModule } from './commodity-price/commodity-price-store.module';
import { StoreStoreModule } from './store/store-store.module';
import { IndicatorListsStoreModule } from './indicator-lists/indicator-lists-store.module';
import { OpenSpecialOrderItemsModule } from './open-special-order-items/open-special-order-items.module';
import { ExpressSchedulesStoreModule } from './express-schedules/express-schedules-store.module';
import { CartReviewStoreModule } from './cart-review/cart-review-store.module';
import { MaterialPriceStoreModule } from './material-price/material-price-store.module';
import { extModules } from '../../../environments/build-specifics';
import { PickupSchedulesStoreModule } from './pickup-schedules/pickup-schedules-store.module';
import { StorePurchaseHistoryStoreModule } from './store-purchase-history/store-purchase-history-store.module';
import { StorePurchaseDetailsStoreModule } from './store-purchase-details/store-purchase-details-store.module';
import { MaterialRecommendationsStoreModule } from './material-recommendations/material-recommendations-store.module';
import { MaterialDetailsStoreModule } from './material-details/material-details-store.module';
import { MarketingGuideStoreModule } from './marketing-guide/marketing-guide-store.module';
import { SmartCartRecommendationsModule } from './smart-cart-recommendations/smart-cart-recommendations.module';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    extModules,
    BannerAdsStoreModule,
    CartStoreModule,
    CommodityPriceStoreModule,
    ActiveCartSummaryStoreModule,
    CartReviewStoreModule,
    CriticalItemsStoreModule,
    CustomerPreferencesStoreModule,
    CustomGuideStoreModule,
    CustomerMaterialStoreModule,
    DeliveryScheduleStoreModule,
    EcommerceAnalyticsStoreModule,
    EntitlementStoreModule,
    CustomerNotificationPreferencesStoreModule,
    ErrorStoreModule,
    ExpressSchedulesStoreModule,
    IndicatorListsStoreModule,
    InventoryAvailabilityStoreModule,
    LastOrderedStoreModule,
    MarketingGuideStoreModule,
    MaterialAdditionalInfoStoreModule,
    MaterialAvailabilityStoreModule,
    MaterialComparisonStoreModule,
    MaterialCutoffStoreModule,
    MaterialDetailsStoreModule,
    MaterialInfoStoreModule,
    MaterialPriceStoreModule,
    MaterialRecommendationsStoreModule,
    MaterialRelatedStoreModule,
    MaterialRowStoreModule,
    MaterialUnitsStoreModule,
    MaterialWarningStoreModule,
    ModalStoreModule,
    MultipleCartsStoreModule,
    MygfsNutritionStoreModule,
    OfflineModeStoreModule,
    OpenOrderItemsStoreModule,
    OpenSpecialOrderItemsModule,
    OrderGuideChangeHistoryStoreModule,
    OrderGuideStoreModule,
    PickupSchedulesStoreModule,
    RouterStoreModule,
    SalesCriticalItemsStoreModule,
    SearchStoreModule,
    SessionStoreModule,
    SpecialOrdersStoreModule,
    StoreStoreModule,
    StorePurchaseHistoryStoreModule,
    StorePurchaseDetailsStoreModule,
    SmartCartRecommendationsModule,
  ],
})
export class AppStoreModule {}
