import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MaterialRowActions } from './material-row.actions';
import {
  MaterialRowSelectInput,
  selectMaterialRow,
} from './material-row.selector';
import {
  MaterialListStyle,
  MaterialRow,
  MaterialRowContext,
} from './models/material-row';

@Injectable({ providedIn: 'root' })
export class MaterialRowFacade {
  constructor(private store: Store) {}

  updatePreferredMaterialView(materialListStyle: MaterialListStyle): void {
    this.store.dispatch(
      MaterialRowActions.updatePreferredMaterialView(materialListStyle)
    );
  }

  getMaterialRow(
    partialModel?: Partial<MaterialRowSelectInput>
  ): Observable<MaterialRow> {
    const defaultModel = {
      context: MaterialRowContext.Browse,
      materialListStyle: MaterialListStyle.List,
    } as MaterialRowSelectInput;

    return this.store.select(
      selectMaterialRow({ ...defaultModel, ...partialModel })
    );
  }
}
