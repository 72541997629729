import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import {
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
} from '../material-info/material-info.state';
import {
  orderGuideAdapter,
  OrderGuideState,
  OrderGuideV1,
} from './order-guide.state';
import { Dictionary } from '@ngrx/entity';
import { GuideItem } from '../../../shared/models/guide-item';
import { unavailableMaterialRecord } from '../../services/material-info/models/materials-info-record';
import { selectLoadedOrderGuideChangeHistory } from '../order-guide-change-history/order-guide-change-history.selectors';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';
import { OrderGuide } from 'src/app/core/store/order-guide/models/order-guide';
import { OrderGuideTransformer } from './order-guide-transformer';
import { selectPreferredMaterialView } from '../material-row/material-view.selectors';
import {
  selectHasPermissionEnabled,
  selectLanguage,
} from '../session/session.selectors';
import { selectAllLastOrderedRecords } from '../last-ordered/last-ordered.selectors';
import { selectCustomerMaterialRecord } from '../customer-material/customer-material.selector';
import {
  createMaterialInfoFromMaterialInfoRecordState,
  hasMaterialInfoFinishedLoading,
} from '../material-info/material-info.util';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { CustomerPermission } from '../../services/session/models/session-record';
import { createProductInfoFromMaterialInfo } from '../../../shared/models/product-info';

const selectOrderGuideState = createFeatureSelector<OrderGuideState>(
  'orderGuide'
);

export const selectOrderGuideRecords = createSelector(
  selectOrderGuideState,
  (state) =>
    state ? orderGuideAdapter.getSelectors().selectAll(state.records) : []
);

export const selectIsOrderGuideEditable = createSelector(
  selectHasPermissionEnabled(CustomerPermission.OrderGuideEditAccess),
  selectOrderGuideState,
  (hasPermission, orderGuideState): boolean =>
    hasPermission && orderGuideState.isOrderGuideEditable
);

export const selectOrderGuideMaterialNumbers = createSelector(
  selectOrderGuideRecords,
  (records): string[] => {
    return records
      .map((category) => category.materialNumbers)
      .reduce((lhs, rhs) => lhs.concat(rhs), []);
  }
);

export const selectOrderGuideLoadingStatus = createSelector(
  selectOrderGuideState,
  (state) => state.hasLoaded
);

const selectOrderGuideSortBy = createSelector(
  selectOrderGuideState,
  (state) => state.sortBy
);

export const selectOrderGuideUnsavedChanges = createSelector(
  selectOrderGuideState,
  (state) => state.hasLoaded && state.hasUnsavedChanges
);

export const selectOrderGuideV1 = createSelector(
  selectOrderGuideState,
  selectOrderGuideRecords,
  selectOrderGuideSortBy,
  selectAllMaterialInfoRecordStates,
  (
    currentState,
    orderGuideRecords,
    sortBy,
    materialInfoRecordStates
  ): OrderGuideV1 | undefined => {
    if (!currentState.hasLoaded) {
      return undefined;
    }

    const hasLoadedMaterialInfoRecords = orderGuideRecords
      .map((category) => category.materialNumbers)
      .reduce((lhs, rhs) => lhs.concat(rhs), [])
      .map((materialNumber) => materialInfoRecordStates[materialNumber])
      .find(hasMaterialInfoFinishedLoading);

    if (!hasLoadedMaterialInfoRecords) {
      return undefined;
    }

    return {
      categorizedMaterials: orderGuideRecords.map((record) => {
        return {
          categoryName: record.categoryName,
          items: record.materialNumbers
            .filter(
              (materialNumber) =>
                materialInfoRecordStates[materialNumber] &&
                materialInfoRecordStates[materialNumber].record
            )
            .map((materialNumber, index) =>
              buildGuideItem(materialNumber, materialInfoRecordStates, index)
            ),
          categoryOrder: undefined,
          categoryType: record.categoryType,
          categoryId: record.categoryId,
        };
      }),
      sortBy: sortBy,
    };
  }
);

export const selectLoadedOrderGuideMaterialNumbers = createSelector(
  selectOrderGuideState,
  selectOrderGuideMaterialNumbers,
  selectAllMaterialInfoRecordStates,
  (currentState, materialNumbers, materialInfoRecords) => {
    if (!currentState || !currentState.hasLoaded) {
      return undefined;
    }

    return new Set(
      materialNumbers.filter(
        (materialNumber) =>
          !!materialInfoRecords[materialNumber] &&
          materialInfoRecords[materialNumber].status ===
            MaterialInfoRecordStatus.Success
      )
    );
  }
);

export const selectIsInOrderGuide = (materialNumber: string) =>
  createSelector(selectLoadedOrderGuideMaterialNumbers, (orderGuideRecords) => {
    return orderGuideRecords ? orderGuideRecords.has(materialNumber) : false;
  });

export const selectOrderGuide = createSelector(
  selectOrderGuideState,
  selectLoadedOrderGuideChangeHistory,
  selectPreferredMaterialView,
  selectIsOffline,
  selectAllMaterialInfoRecordStates,
  selectCustomerMaterialRecord,
  selectAllLastOrderedRecords,
  selectLanguage,
  (
    orderGuideState,
    guideChanges,
    preferredView,
    isOffline,
    materialInfoRecords,
    customerMaterialRecord,
    lastOrderedRecords,
    currentLocale
  ): OrderGuide =>
    orderGuideState.hasLoaded
      ? OrderGuideTransformer.transformOrderGuide(
          orderGuideState,
          guideChanges,
          materialInfoRecords,
          customerMaterialRecord,
          lastOrderedRecords,
          preferredView,
          isOffline,
          currentLocale
        )
      : undefined
);

export const selectOrderGuideExportMaterialsInput = (isPrint: boolean) =>
  createSelector(
    selectOrderGuideState,
    selectAllMaterialInfoRecordStates,
    selectAllLastOrderedRecords,
    selectCustomerMaterialRecord,
    selectLanguage,
    (
      orderGuideState,
      materialInfos,
      lastOrderedRecords,
      customerMaterialRecord,
      language
    ): ExportMaterialsInput =>
      OrderGuideTransformer.transformOrderGuideExportMaterialsInput(
        orderGuideState,
        materialInfos,
        lastOrderedRecords,
        customerMaterialRecord,
        language,
        isPrint
      )
  );

function buildGuideItem(
  materialNumber: string,
  materialInfoRecordStates: Dictionary<MaterialInfoRecordState>,
  index: number
): GuideItem {
  const materialInfo = createMaterialInfoFromMaterialInfoRecordState(
    materialInfoRecordStates[materialNumber]
  );
  return {
    order: index,
    product: createProductInfoFromMaterialInfo(materialInfo),
    material:
      materialInfoRecordStates[materialNumber] &&
      materialInfoRecordStates[materialNumber].record
        ? materialInfoRecordStates[materialNumber].record
        : unavailableMaterialRecord(materialNumber),
  };
}
