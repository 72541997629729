import { MaterialListRow } from '../../../material-list/models/material-list';
import { MaterialRowContext } from '../material-row/models/material-row';
import { DeliveryScheduleEntryRecord } from '../../services/delivery-schedule/models/delivery-schedules-record';
import { CutoffSplitRecord } from '../../services/delivery-schedule/models/cutoff-split-record';
import {
  FulfillmentOrderSplit,
  StoreOrderPaymentInfo,
  StoreOrderSplitOptions,
} from '../../services/pre-submit/model/order-split-record';
import { Site } from '../../services/session/models/session-record';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { OrderConfirmationEmailOptInStatus } from '../customer-preferences/customer-preferences.selectors';
import { StoreRecord } from '../../services/store/model/store-record';
import { StoreOrderPaymentOptions } from './cart-review.selectors';
import { ExpressDeliveryWindow } from '../../services/express-schedules/models/express-schedule-record';
import { Moment } from 'moment';
import { NaooIcon } from '../../../shared/services/images/naoo-icon.service';

export enum CartReviewSectionName {
  CutoffSplitItemsSection = 'CutoffSplitItemsSection',
  DropShipRetalixItemsSection = 'DropShipRetalixItemsSection',
  DropShipItemsSection = 'DropShipItemsSection',
  SpecialItemsSection = 'SpecialItemsSection',
  StandardItemsSection = 'StandardItemsSection',
  StockWarningItemsSection = 'StockWarningItemsSection',
  UnavailableItemsSection = 'UnavailableItemsSection',
  FulfillmentSystemErrorSection = 'FulfillmentSystemErrorSection',
  PickupItemsSection = 'PickupItemsSection',
  ExpressItemsSection = 'ExpressItemsSection',
}

export enum CartReviewSectionType {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success',
}

export enum CartReviewHeaderType {
  Default = 'Default',
  Success = 'Success',
  MissedCutoff = 'MissedCutoff',
  DropShip = 'DropShip',
  StoreSuccess = 'StoreSuccess',
  StoreError = 'StoreError',
}

export interface CartReviewSectionGroup {
  name: CartReviewSectionName;
  sections: CartReviewSection[];
  displayOrder: number;
  hasMaterials: boolean;
}

export interface OrderConfirmationEmailOptIn {
  status: OrderConfirmationEmailOptInStatus;
  email: string;
}

export enum CreditWidgetLoadingState {
  NOT_LOADING = 'NOT_LOADING',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export interface CartReviewSection {
  context: MaterialRowContext;
  headerKey: string;
  headerKeyId: string;
  headerType: CartReviewHeaderType;
  icon: NaooIcon;
  materialNumbers: MaterialListRow[];
  name: CartReviewSectionName;
  sectionCounter: number;
  type: CartReviewSectionType;
  alwaysShowError?: boolean;
  customerName?: string;
  customerNumber?: string;
  deliveryKey?: string;
  estimatedCostKey?: string;
  fulfillmentOrderSplit?: FulfillmentOrderSplit;
  nextValidDeliverySchedules?: DeliveryScheduleEntryRecord[];
  poNumber?: string;
  routeDate?: Date | Moment;
  routeDateKey?: string;
  selectAddressKey?: string;
  selectedSiteId?: string;
  selectShippingKey?: string;
  shippingCostKey?: string;
  shippingOptionsKey?: string;
  sites?: Site[];
  subHeaderKeys?: CartReviewSubHeader[];
  subTotalKey?: string;
  store?: StoreRecord;
  suggestedAvailabilityDateTime?: Moment;
  originalPickupDate?: Moment;
  pickupResolutionDate?: Moment;
  originalExpressDate?: ExpressDeliveryWindow;
  expressResolutionDate?: ExpressDeliveryWindow;
  isPoNumberRequired?: boolean;
}

export interface CartReviewSubHeader {
  key: string;
  link?: CartReviewSubHeaderLink;
}

export interface CartReviewSubHeaderLink {
  key: string;
  route: string;
  params?: {
    [key: string]: string;
  };
}

export interface CartReviewMetadata {
  cutoffSplitRecord: CutoffSplitRecord;
  fulfillmentOrderSplits?: FulfillmentOrderSplit[];
  failedMaterials?: string[];
  selectedDropShipSiteId?: string;
  storeOrderSplitOptions?: StoreOrderSplitOptions;
  paymentInfo?: StoreOrderPaymentInfo;
}

export interface CartReviewState {
  isLoading: boolean;
  selectedCreditCard: boolean;
  aciExecutePaymentResolved: boolean;
  groups: EntityState<CartReviewSectionGroup>;
  emailOptIn?: OrderConfirmationEmailOptIn;
  failedDropShipSplitMaterials?: string[];
  storePaymentOptions?: StoreOrderPaymentOptions;
  creditWidgetLoadingState?: CreditWidgetLoadingState;
}

export const cartReviewSectionAdapter = createEntityAdapter<CartReviewSectionGroup>(
  {
    selectId: (cartReviewSectionGroup) => cartReviewSectionGroup.name,
  }
);

export const initialCartReviewState: CartReviewState = {
  isLoading: false,
  groups: cartReviewSectionAdapter.getInitialState(),
  selectedCreditCard: false,
  aciExecutePaymentResolved: false,
  creditWidgetLoadingState: CreditWidgetLoadingState.NOT_LOADING,
};
