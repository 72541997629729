import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  MaterialCompareCheckbox,
  MaterialRowSharedOptions,
  MaterialRowContext,
} from 'src/app/core/store/material-row/models/material-row';
import { MaterialComparisonFacade } from 'src/app/core/store/material-comparison/material-comparison.facade';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ToastMessageService } from 'src/app/shared/services/toast-message/toast-message.service';
import { maximumMaterialComparisons } from 'src/app/core/store/material-comparison/material-comparison.selectors';
import { NaooAnalyticsManager } from 'src/app/shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from 'src/app/shared/analytics/analytics-event-info';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';

@Component({
  selector: 'naoo-material-compare-checkbox',
  templateUrl: './material-compare-checkbox.component.html',
  styleUrls: ['./material-compare-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialCompareCheckboxComponent {
  @Input() compareCheckbox: MaterialCompareCheckbox;
  @Input() materialRowOptions: MaterialRowSharedOptions;

  constructor(
    private materialComparisonFacade: MaterialComparisonFacade,
    private toastMessageService: ToastMessageService,
    private localizationService: LocalizationService,
    private analytics: NaooAnalyticsManager,
    private searchFacade: SearchFacade
  ) {}

  get isDisabled(): boolean {
    return (
      (!this.compareCheckbox.isComparing && !this.compareCheckbox.canCompare) ||
      this.materialRowOptions.isOffline
    );
  }

  handleCheckBoxClick() {
    const cannotCompare =
      !this.compareCheckbox.isComparing && !this.compareCheckbox.canCompare;
    if (cannotCompare && !this.materialRowOptions.isOffline) {
      this.toastMessageService.showToastMessage(
        this.localizationService.instant('LISTS.COMPARE_UP_TO', {
          maxComparisonCount: maximumMaterialComparisons,
        })
      );
    }
  }

  toggleMaterialComparison(event$: MatCheckboxChange) {
    if (event$.checked) {
      this.sendMaterialComparisonAnalytics();
      this.materialComparisonFacade.addMaterialComparison(
        this.materialRowOptions.materialNumber
      );
    } else {
      this.materialComparisonFacade.removeMaterialComparison(
        this.materialRowOptions.materialNumber
      );
    }

    if (this.materialRowOptions.context === MaterialRowContext.Browse) {
      this.searchFacade.searchMaterialClick(
        this.materialRowOptions.materialNumber
      );
    }
  }

  private sendMaterialComparisonAnalytics() {
    const eventInfo: AnalyticsEventInfo = {
      action: 'selected',
      category: 'product comparison',
      label: `compare checkbox - ${this.materialRowOptions.materialNumber}`,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }
}
