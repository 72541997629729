import { UnitsAvailability } from '../../core/services/inventory-availability/models/inventory-availability-record';
import { UnitsAvailabilityViewModel } from '../models/inventory-availability';

export function getUnitsAvailable(
  unitsAvailability: UnitsAvailability[],
  unitOfMeasure: string
): number {
  const unitAvailable = unitsAvailability.find(
    (unit) => unit.uom === unitOfMeasure
  );

  return !!unitAvailable ? unitAvailable.unitsAvailable : 0;
}

export function getCurrentUnitsAvailable(
  unitsAvailability: UnitsAvailabilityViewModel[],
  unitOfMeasure: string
): number {
  const unitAvailable = unitsAvailability.find(
    (unit) => unit.uom === unitOfMeasure
  );
  return !!unitAvailable ? unitAvailable.currentUnitsAvailable : 0;
}

export function getNextPoDate(unitsAvailability: UnitsAvailability[]): string {
  return unitsAvailability.length > 0
    ? unitsAvailability.find((unit) => !!unit?.nextPoDate)?.nextPoDate
    : '';
}

export function getNextStoreDeliveryTime(
  unitsAvailability: UnitsAvailability[]
): string {
  return unitsAvailability.length > 0
    ? unitsAvailability.find((unit) => !!unit?.nextStoreDeliveryTime)
        ?.nextStoreDeliveryTime
    : '';
}

export function getEstInStockDateViewModel(
  unitsAvailability: UnitsAvailabilityViewModel[]
): string {
  return unitsAvailability.length > 0
    ? unitsAvailability[0].estInStockDate
    : '';
}

export function getTotalOnOrderViewModel(
  unitsAvailability: UnitsAvailabilityViewModel[]
): number {
  return unitsAvailability.length > 0 ? unitsAvailability[0].totalOnOrder : 0;
}
