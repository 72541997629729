import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  StoreMapMarkerOptions,
  StoreMapMarkers,
} from '../../service/google-maps.service';
import { MapConstants } from '../../GoogleMapConstants';
import { KeyValue } from '@angular/common';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GoogleMap } from '@angular/google-maps';
import { Subject } from 'rxjs';
import { StoreRecord } from '../../../../core/services/store/model/store-record';
import { getSortedVisibleStoreRecords } from '../../util/google-maps.util';
import LatLngLiteral = google.maps.LatLngLiteral;

@Component({
  selector: 'naoo-google-map',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleMapsNaooComponent implements AfterViewInit, OnDestroy {
  @Input() userLatLong: LatLngLiteral;
  @Output() selectedStorePlantIdEmitter = new EventEmitter<string>();
  @Output() visibleStoreRecords = new EventEmitter<StoreRecord[]>();
  @ViewChild(GoogleMap) googleMapChild: GoogleMap;

  private maxZoom: number;
  private destroyed$ = new Subject<void>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  get googleMap(): google.maps.Map | undefined {
    return this.googleMapChild?.googleMap;
  }

  @Input()
  set selectedAddressLatLong(selectedAddressLatLong: LatLngLiteral) {
    if (selectedAddressLatLong) {
      this.maxZoom = undefined;
      this.setZoom(MapConstants.DEFAULT_ZOOM);
      this.setCenter(selectedAddressLatLong);
      this.zoomOut();
    }
  }

  private _storeMapMarkers: StoreMapMarkers;

  get storeMapMarkers(): StoreMapMarkers {
    return this._storeMapMarkers;
  }

  @Input()
  set storeMapMarkers(storeMapMarkers: StoreMapMarkers) {
    this._storeMapMarkers = storeMapMarkers;
    this.setCenter();
  }

  private _selectedStorePlantId: string;

  get selectedStorePlantId(): string {
    return this._selectedStorePlantId;
  }

  @Input()
  set selectedStorePlantId(selectedStorePlantId: string) {
    this._selectedStorePlantId = selectedStorePlantId;
    this.setCenter();
  }

  ngAfterViewInit(): void {
    this.googleMapChild.boundsChanged
      .pipe(debounceTime(100), takeUntil(this.destroyed$))
      .subscribe(() => this.emitVisibleStores());

    this.googleMap?.setOptions(MapConstants.DEFAULT_MAP_OPTIONS);
    this.setCenter();
    this.setZoom(MapConstants.DEFAULT_ZOOM);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackByStorePlantId(
    _index: number,
    keyValue: KeyValue<string, StoreMapMarkerOptions>
  ): string {
    return keyValue.value.storePlantId;
  }

  selectPin(storeMapMarkerOptions: StoreMapMarkerOptions): void {
    this.selectedStorePlantIdEmitter.emit(storeMapMarkerOptions.storePlantId);
  }

  getIcon(storeMapMarkerOptions: StoreMapMarkerOptions): google.maps.Icon {
    return this.isSelectedMarker(storeMapMarkerOptions)
      ? MapConstants.LARGE_ICON
      : MapConstants.DEFAULT_ICON;
  }

  private getSelectedMarker(
    storeMapMarkers: StoreMapMarkers
  ): StoreMapMarkerOptions {
    return this.selectedStorePlantId
      ? storeMapMarkers?.storeMapMarkersMap.get(this.selectedStorePlantId)
      : storeMapMarkers?.currentFulfillmentMarker;
  }

  private isSelectedMarker(
    storeMapMarkerOptions: StoreMapMarkerOptions
  ): boolean {
    return (
      storeMapMarkerOptions.storePlantId ===
      this.getSelectedMarker(this.storeMapMarkers)?.storePlantId
    );
  }

  private setCenter(latLngLiteral?: LatLngLiteral): void {
    this.googleMap?.setCenter(
      latLngLiteral ||
        this.getSelectedMarker(this.storeMapMarkers)?.position ||
        this.userLatLong
    );
    this.changeDetectorRef.markForCheck();
  }

  private emitVisibleStores(): void {
    if (this.storeMapMarkers?.storeMapMarkersMap) {
      this.visibleStoreRecords.emit(this.getSortedVisibleStoreRecords());
    }
  }

  private getSortedVisibleStoreRecords(): StoreRecord[] {
    return getSortedVisibleStoreRecords(
      Array.from(this.storeMapMarkers.storeMapMarkersMap.values()),
      this.googleMapChild.getBounds()
    );
  }

  private zoomOut(): void {
    this.changeDetectorRef.markForCheck();
    const currentZoom = this.googleMap?.getZoom();
    if (!currentZoom) {
      return;
    }

    if (this.storeMapMarkers?.storeMapMarkersMap.size) {
      setTimeout(() => {
        const visibleStores = this.getSortedVisibleStoreRecords().length;
        if (visibleStores && !this.maxZoom) {
          this.maxZoom = currentZoom - 2;
        }
        const increaseZoom = visibleStores === 1 && currentZoom > this.maxZoom;

        if (!visibleStores || increaseZoom) {
          this.setZoom(currentZoom - 1);
          this.zoomOut();
        }
      });
    }
  }

  private setZoom(zoom: number): void {
    this.googleMap?.setZoom(zoom);
  }
}
