import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialRow } from '../material-row/models/material-row';

export interface SmartCartRecommendation {
  product: string;
  uom: string;
  quantity: number;
}

export interface SmartCartRecommendationState {
  smartCartRecommendation: EntityState<SmartCartRecommendation>;
  hasLoaded: boolean;
  hasErrored: boolean;
}

export interface SmartCartRecommendationViewModel {
  recommendation: SmartCartRecommendation;
  materialRow: MaterialRow;
}

export interface SmartCartRequest {
  materialNumbers: string[];
}

export const smartCartRecommendationAdapter = createEntityAdapter<SmartCartRecommendation>(
  {
    selectId: (recommendation) => recommendation.product,
  }
);

export const initialSmartCartRecommendationState: SmartCartRecommendationState = {
  smartCartRecommendation: smartCartRecommendationAdapter.getInitialState(),
  hasLoaded: false,
  hasErrored: false,
};
