<div class="navigation-wrapper">
  <nav
    mat-tab-nav-bar
    disableRipple="true"
    mat-stretch-tabs="false"
    [tabPanel]="tabPanel"
  >
    <a
      class="mat-mdc-tab-link guides-tab"
      [routerLink]="guidesLink.link"
      [ngClass]="{ focused: guidesLink.isActive }"
    >
      {{ guidesLink.text | translate }}
    </a>
    <naoo-generic-disableable-link
      [className]="
        'mat-mdc-tab-link ' +
        (categoriesLink.isActive ? 'focused' : '') +
        (isDisabled ? 'disabled' : '')
      "
      [routingUrl]="categoriesLink.link"
      [isDisabled]="isDisabled"
    >
      {{ categoriesLink.text | translate }}
    </naoo-generic-disableable-link>
    <button
      id="ordersBtn"
      [attr.aria-label]="'MENU.ORDERS' | translate"
      class="mat-mdc-tab-link orders-button"
      [ngClass]="{ focused: isOrdersTab, disabled: isDisabled }"
      [matMenuTriggerFor]="myOrderMenu"
      (menuClosed)="isMenuOpen = false"
      (menuOpened)="isMenuOpen = true"
      [disabled]="isDisabled"
    >
      <span>{{ 'MENU.ORDERS' | translate }}</span>
      <mat-icon
        [ngClass]="{ 'rotate-arrow': isMenuOpen }"
        class="dropdown-arrow-icon"
        svgIcon="arrow-v2"
      ></mat-icon>
    </button>
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  <div class="action-wrapper">
    <naoo-order-tools
      class="sub-header-action"
      [ngClass]="{ disabled: isDisabled || !isCartLoaded }"
      id="order-tools"
      *ngIf="!hideOrderTools"
      [isDisabled]="isDisabled || !isCartLoaded"
    ></naoo-order-tools>
  </div>
</div>

<mat-menu #myOrderMenu="matMenu" xPosition="before">
  <div class="subheader-menu">
    <a
      *ngFor="let link of myOrderLinks; trackBy: trackLink"
      class="subheader-menu-item"
      mat-menu-item
      [attr.aria-label]="link.label | translate"
      [routerLink]="link.link"
      [queryParams]="link.queryParams"
      [ngClass]="{
        active:
          !!link.queryParams &&
          !!currentUrl &&
          currentUrl.includes(link.queryParams.activeTab)
      }"
    >
      <ng-container *ngIf="link.label === goPointsLabel; else label">
        <naoo-go-points-logo
          [context]="'naoo-sub-header'"
        ></naoo-go-points-logo>
      </ng-container>
      <ng-template #label>
        {{ link.label | translate }}
      </ng-template>
    </a>
  </div>
</mat-menu>
