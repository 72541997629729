import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  debounce,
  interval,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { NaooConstants } from '../../NaooConstants';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  private onStateChange: Subject<boolean> = new ReplaySubject<boolean>(1);
  private userEvents: BehaviorSubject<boolean>;

  constructor() {
    this.userEvents = new BehaviorSubject(false);
    this.onStateChange.next(false);
    this.userEvents
      .pipe(
        distinctUntilChanged(),
        debounce(() => interval(NaooConstants.LOADING_DISPLAY_DELAY))
      )
      .subscribe((state) => {
        this.onStateChange.next(state);
      });
  }

  start() {
    this.userEvents.next(true);
  }

  stop() {
    this.userEvents.next(false);
  }

  /**
   * Returns the current loading state debounced by 500ms,
   * to stop loading indicators from flashing in and out if
   * a loading state is short lived.
   */
  get debouncedLoadingState(): Observable<boolean> {
    return this.onStateChange.asObservable();
  }

  /**
   * Returns the current loading state without a debounce.
   * Should be used in cases where the true loading state is
   * required.
   */
  get loadingState(): Observable<boolean> {
    return this.userEvents.asObservable();
  }
}
