import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import { ModalActions } from './modal.actions';
import { Observable } from 'rxjs';

@Injectable()
export class ModalEffects {
  constructor(
    private actions$: Actions,
    private dialogService: DefaultDialogService
  ) {}

  handleOneButtonModal$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ModalActions.oneButtonModal),
        switchMap((action) =>
          this.overrideDialog(() =>
            this.dialogService.oneButtonModal(
              action.id,
              action.messageData,
              action.primaryButtonText,
              action.primaryButtonAction,
              action.isClosable
            )
          )
        )
      );
    },
    { dispatch: false }
  );

  handleTwoButtonModal$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ModalActions.twoButtonModal),
        switchMap((action) =>
          this.overrideDialog(() =>
            this.dialogService.twoButtonModal(
              action.id,
              action.messageData,
              action.primaryButtonText,
              action.secondaryButtonText,
              action.primaryButtonAction,
              action.secondaryButtonAction
            )
          )
        )
      );
    },
    { dispatch: false }
  );

  private overrideDialog(openDialog: () => void): Observable<void> {
    return this.dialogService.closeAllDialogs().pipe(tap(openDialog));
  }
}
