import { NotificationSubscriptionType } from '../../../../core/services/customer-notification-preferences/models/customer-notification-preferences-record';
export interface Notifications {
  customer: NotificationsCustomer;
  totalNotifications: number;
  customerNotifications: CustomerNotification[];
  isAddDisabled: boolean;
  enabledEmailSubscriptionTypes: string[];
  enabledSMSSubscriptionTypes: string[];
  reminderDetails?: ReminderDetails;
}

export interface NotificationsCustomer {
  name: string;
  address: string;
}

export interface CustomerNotification {
  ordinal: number;
  name: string;
  contactInfo: string;
  language: NotificationLanguage;
  editableStatus: EditableStatus;
  methodCode: NotificationMethodCode;
  subscriptionTypes?: NotificationSubscriptionType[];
  customerReminders?: ReminderPreference[];
}

export interface ReminderDetails {
  customerTimezone: string;
  supportedReminderMessages: ReminderMessage[];
  countryCode: string;
}

export interface ReminderPreference {
  messageId: string;
  dayOfWeek: string;
  hourOfDay: string;
  timezone: string;
}

export enum NotificationLanguage {
  English = 'SHARED.ENGLISH',
  French = 'SHARED.FRENCH',
}

export enum EditableStatus {
  Active,
  Disabled,
  Enabled,
}

export class NotificationsDisplayConstants {
  public static maxNotificationsCount = 9;
}

export enum NotificationMethodCode {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export enum ReminderMessage {
  MEAT = 'REMIND_MEAT_SEAFOOD_ORDER',
  ORDER = 'REMIND_GFS_ORDER',
  CUTOFF = 'REMIND_EARLY_CUTOFF_ITEMS',
  INVOICE = 'REMIND_PAY_INVOICE',
}

export enum ReminderWeekDay {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export enum ReminderHourOfTheDay {
  Zero,
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty,
  TwentyOne,
  TwentyTwo,
  TwentyThree,
}
