import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NutritionLabel } from './models/mygfs-nutrition';
import { MygfsNutritionActions } from './mygfs-nutrition.actions';
import { selectLoadedMygfsNutrition } from './mygfs-nutrition.selectors';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MygfsNutritionFacade {
  constructor(private store: Store) {}

  loadMygfsNutrition(materialNumbers: string[]) {
    this.store.dispatch(
      MygfsNutritionActions.loadMygfsNutrition(materialNumbers)
    );
  }

  getLoadedMygfsNutrition(materialNumber: string): Observable<NutritionLabel> {
    return this.store
      .select(selectLoadedMygfsNutrition(materialNumber))
      .pipe(filter((nutritionLabel) => !!nutritionLabel));
  }
}
