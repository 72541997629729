import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import {
  OrderDetailsLineViewModel,
  UomLine,
} from '../../../shared/models/order-details';
import {
  EditOrderDetailsViewModel,
  EditOrderTransformService,
} from './edit-order-transform.service';
import {
  MaterialLine,
  MaterialListStyle,
  MaterialRowContext,
} from '../../../core/store/material-row/models/material-row';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';

export interface EditOrderModalResult {
  editDetails: EditDetails;
}

export interface EditOrderChanges {
  qty: number;
  salesDocumentItem: string;
}

export interface EditDetails {
  materialNumber: string;
  changes: EditOrderChanges[];
}

@Component({
  selector: 'naoo-edit-order-modal',
  templateUrl: './edit-order-modal.component.html',
  styleUrls: ['./edit-order-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditOrderModalComponent implements OnDestroy, OnInit {
  readonly context = MaterialRowContext.OrderDetailsEditModal;
  readonly mobileBreakPoint = '(max-width: 425px)';
  readonly gridStyle = MaterialListStyle.Grid;
  readonly listStyle = MaterialListStyle.List;

  destroyed$ = new Subject<void>();
  isMobile: boolean;
  orderItem: EditOrderDetailsViewModel;
  quantityChanges: MaterialLine[];
  quantityOrdered: MaterialLine[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public orderDetailsLineViewModel: OrderDetailsLineViewModel,
    public dialogRef: MatDialogRef<EditOrderModalComponent>,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private editOrderTransformService: EditOrderTransformService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe([this.mobileBreakPoint])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.isMobile = result.breakpoints[this.mobileBreakPoint];
        this.changeDetectorRef.markForCheck();
      });

    this.orderItem = this.editOrderTransformService.transform(
      this.orderDetailsLineViewModel
    );
    this.quantityChanges = [];
    this.quantityOrdered = [];

    this.orderItem.units
      .filter((unit) => !!unit?.editable)
      .forEach((unit) =>
        this.quantityOrdered.push({
          uom: unit.uom,
          displayCode: unit.displayCode,
          quantity: unit.quantityOrdered,
        })
      );
  }

  get isSaveDisabled(): boolean {
    return (
      this.orderItem.units
        .map((unit) => this.isQtySame(unit.uom))
        .find((isSame) => !isSame) ?? true
    );
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  handleQuantityInputChanged(event: MaterialLine): void {
    const currentUom = this.quantityChanges.find(
      (current) => current.uom === event.uom
    );
    if (!currentUom) {
      this.quantityChanges.push(event);
      return;
    }
    this.quantityChanges = this.quantityChanges.map((current) =>
      current.uom === event.uom ? event : current
    );
  }

  saveChanges(): void {
    const changes = this.orderItem.units
      .map((unit) => this.getEditOrderChanges(unit.uom))
      .filter((unit) => !!unit);

    const editDetails: EditDetails = {
      materialNumber: this.orderItem.materialNumber,
      changes,
    };
    const result: EditOrderModalResult = {
      editDetails: editDetails.changes.length ? editDetails : undefined,
    };
    this.dialogRef.close(result);
  }

  private isQtySame(uom: string): boolean {
    const uomLine = this.getUomLine(uom);
    if (!uomLine?.editable) {
      return true;
    }
    const qtyChanges = this.getQtyChanges(uom);
    const qtyOrdered = uomLine?.quantityOrdered;
    if (qtyOrdered > 0 && qtyChanges === 0) {
      return false;
    }
    return qtyChanges ? qtyOrdered === qtyChanges : true;
  }

  private getEditOrderChanges(uom: string): EditOrderChanges {
    const qtyChanges = this.getQtyChanges(uom);
    if (qtyChanges === undefined || this.isQtySame(uom)) {
      return undefined;
    }
    return {
      qty: qtyChanges,
      salesDocumentItem: this.getUomLine(uom)?.salesDocumentItem,
    };
  }

  private getQtyChanges(uom: string): number {
    return this.quantityChanges.find((change) => change.uom === uom)?.quantity;
  }

  private getUomLine(uom: string): UomLine | undefined {
    return this.orderItem.units.find((unit) => unit.uom === uom);
  }
}
