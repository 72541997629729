import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GoPointsService,
  GoPointsViewModel,
} from '../../../core/services/go-points/go-points.service';

@Component({
  selector: 'naoo-go-points-container',
  template: ` <naoo-go-points-content
    [viewModel]="viewModel$ | async"
    (checkRedemptionEvent)="checkRedemption($event)"
  >
  </naoo-go-points-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoPointsContainerComponent {
  viewModel$: Observable<GoPointsViewModel> = this.goPointsService.retrieveGoPointsViewModel();

  constructor(private goPointsService: GoPointsService) {}

  checkRedemption(numberOfChecks: number): void {
    this.goPointsService.checkRedemption(numberOfChecks);
  }
}
