<ng-container *ngIf="isTabView; else desktopOrdering">
  <naoo-material-ordering-tab
    [materialOrdering]="materialOrdering"
    [materialRowOptions]="materialRowOptions"
    [isMobile]="isMobile"
    [displayGoPointsLogo]="displayGoPointsLogo"
    (viewCommodityDetailsClicked)="viewCommodityDetailsClicked.emit()"
  >
  </naoo-material-ordering-tab>
</ng-container>

<ng-template #desktopOrdering>
  <ng-container *ngIf="isGrid; else nonGridWarning">
    <div class="warning-grid-content-wrapper">
      <span *ngIf="displayMaxAllocation" class="commodity-available">
        <button (click)="viewCommodityDetailsClicked.emit()">
          {{
            materialRowOptions.materialCommodityInfo.maxAllocationDisplayKey
              | translate
                : {
                    count:
                      materialRowOptions.materialCommodityInfo
                        .maxAllocationDisplay
                  }
          }}
        </button>
      </span>
      <div class="size-placeholder"></div>
      <div class="price-placeholder"></div>
      <div class="warning-content grid">
        <naoo-material-warning
          *ngIf="materialOrdering.materialWarning"
          [warning]="materialOrdering.materialWarning"
          [materialRowOptions]="materialRowOptions"
          [openDirection]="warningOpenDirection"
          [isInventoryLoaded]="materialOrdering.isInventoryLoaded"
          [selectedOrderingInfoIndex]="selectedOrderingInfoIndex"
          [isMobile]="false"
          class="material-warning"
        ></naoo-material-warning>
      </div>
      <div class="total-price-placeholder"></div>
    </div>
  </ng-container>
  <ng-template #nonGridWarning>
    <div class="warning-content">
      <naoo-material-warning
        *ngIf="materialOrdering.materialWarning"
        [warning]="materialOrdering.materialWarning"
        [materialRowOptions]="materialRowOptions"
        [openDirection]="warningOpenDirection"
        [isInventoryLoaded]="materialOrdering.isInventoryLoaded"
        [selectedOrderingInfoIndex]="selectedOrderingInfoIndex"
        [isMobile]="isMobile"
      ></naoo-material-warning>
    </div>
  </ng-template>

  <div class="ordering-content" [ngClass]="{ grid: isGrid }">
    <ng-container *ngIf="!shouldUseDropdownForUoms; else uomDropdown">
      <naoo-material-ordering-info
        *ngFor="
          let uom of uoms;
          index as index;
          first as isFirst;
          last as isLast;
          trackBy: trackByUom
        "
        [currentOrderingInfo]="materialOrdering.materialOrderingInfos[index]"
        [orderingInfos]="materialOrdering.materialOrderingInfos"
        [materialRowOptions]="materialRowOptions"
        [materialWarning]="materialOrdering.materialWarning"
        [isMobile]="false"
        [ngClass]="{ first: isFirst, last: isLast }"
        (quantityInputChanged)="quantityInputChanged.emit($event)"
        (viewCommodityDetailsClicked)="viewCommodityDetailsClicked.emit()"
      >
      </naoo-material-ordering-info>
    </ng-container>
    <ng-template #uomDropdown>
      <naoo-material-ordering-info
        class="inline-uom-info"
        [currentOrderingInfo]="
          materialOrdering.materialOrderingInfos[selectedOrderingInfoIndex]
        "
        [orderingInfos]="materialOrdering.materialOrderingInfos"
        [materialRowOptions]="materialRowOptions"
        [materialWarning]="materialOrdering.materialWarning"
        [materialOrderingTabs]="materialOrdering.tabs"
        [isMobile]="false"
        (tabChange)="changeTab($event)"
      >
      </naoo-material-ordering-info>
    </ng-template>
  </div>
</ng-template>
