import {
  CartLineRecord,
  CartRecord,
} from '../../../services/cart/models/cart-record';
import {
  CartEntityState,
  cartLineAdapter,
  cartMaterialAdapter,
  CartMaterialState,
  initialCartLineState,
  initialCartMaterialState,
} from '../../cart/cart.state';
import { filterNonEmptyCartLines } from '../../../../shared/utilities/cart-material-utilities';
import { Injectable } from '@angular/core';
import { parseDate } from '../../../../shared/utilities/date-utilities';
import { CartQuantityUpdate } from '../../cart/cart.actions';
import { MaterialRowContext } from '../../material-row/models/material-row';
import { SavedCart } from '../multiple-carts.state';
import { MaterialInfo } from '../../../../shared/models/material-info';
import { Localized } from '../../../../shared/models/localized';
import {
  CombinedPricingRecord,
  CombinedUnitPriceRecord,
} from '../../material-price/material-price.util';

export interface ViewModelCartLineRecord {
  uom: string;
  quantity: number;
  displayUom: string;
  price: CombinedUnitPriceRecord;
}

export interface ViewModelMaterial {
  materialNumber: string;
  lines: ViewModelCartLineRecord[];
  description?: Localized<string>;
  isCatchWeight?: boolean;
}

export interface CartPreviewViewModel {
  materials: ViewModelMaterial[];
  totalPrice: number;
  lastUpdated: Date;
}

@Injectable({ providedIn: 'root' })
export class MultipleCartsTransformer {
  public static toCartPreviewViewModel(
    pricingRecords: CombinedPricingRecord[],
    materialInfos: MaterialInfo[],
    savedCart: SavedCart
  ): CartPreviewViewModel {
    const materials = savedCart.materials.map((cartMaterialRecord) => {
      const info = materialInfos.find(
        (info) => info?.materialNumber === cartMaterialRecord.materialNumber
      );
      return {
        materialNumber: cartMaterialRecord.materialNumber,
        lines: cartMaterialRecord.lines.map((line) => {
          return {
            displayUom: MultipleCartsTransformer.findDisplayCode(
              line,
              materialInfos,
              cartMaterialRecord.materialNumber
            ),
            uom: line.uom,
            quantity: line.quantity,
            price: MultipleCartsTransformer.getPrice(
              pricingRecords,
              cartMaterialRecord.materialNumber,
              line.uom
            ),
          } as ViewModelCartLineRecord;
        }),
        description: info?.description,
        isCatchWeight: info?.isCatchWeight,
      };
    });

    const totalPrice = savedCart.materials.reduce(
      (price, cartMaterialRecord) => {
        return (
          price +
          cartMaterialRecord.lines.reduce((innerPrice, line) => {
            const priceRecord = this.getPrice(
              pricingRecords,
              cartMaterialRecord.materialNumber,
              line.uom
            );
            return (
              innerPrice + (priceRecord ? priceRecord.price * line.quantity : 0)
            );
          }, 0)
        );
      },
      0
    );
    const lastUpdated = savedCart.lastUpdatedTimestamp;
    return {
      materials,
      totalPrice,
      lastUpdated,
    };
  }

  private static getPrice(
    pricingRecords: CombinedPricingRecord[],
    materialNumber: string,
    uom: string
  ): CombinedUnitPriceRecord | undefined {
    return pricingRecords
      ?.find(
        (combinedPricingRecord) =>
          combinedPricingRecord.materialNumber === materialNumber
      )
      ?.unitPrices?.find((i) => i.salesUom === uom);
  }

  private static findDisplayCode(
    line: CartLineRecord,
    infos: MaterialInfo[],
    materialNumber: string
  ): string | undefined {
    const info = infos.find((info) => info.materialNumber === materialNumber);
    if (!info?.units) {
      return line.uom;
    }
    const materialUnitInfo = info.units.find(
      (unitInfo) => unitInfo.uom === line.uom
    );
    return materialUnitInfo?.displayCode ?? line.uom;
  }

  public toCartEntity(cartRecord: CartRecord): CartEntityState {
    const materials: CartMaterialState[] = cartRecord.materials.map(
      (materialRecord) => {
        return {
          materialNumber: materialRecord.materialNumber,
          lines: cartLineAdapter.setAll(
            materialRecord.lines,
            initialCartLineState
          ),
          isDeleted: false,
          isRestored: false,
          isAddedFromCriticalItem: false,
          isAddedFromCriticalItemsSection: false,
          isAddedFromMaterialComparison: false,
          isRollUpConversion: false,
          originTrackingId: materialRecord.originTrackingId,
        };
      }
    );

    const filteredCartMaterials = materials.filter((material) => {
      return filterNonEmptyCartLines(material.lines.entities).length !== 0;
    });

    return {
      id: cartRecord.id,
      customerPoNumber: cartRecord.customerPoNumber,
      fulfillmentType: cartRecord.fulfillmentType,
      truckFulfillment: cartRecord.truckFulfillment
        ? {
            routeDate: parseDate(cartRecord.truckFulfillment.routeDate),
            customerArrivalDate: parseDate(
              cartRecord.truckFulfillment.customerArrivalDate
            ),
          }
        : undefined,
      storeFulfillment: cartRecord?.storeFulfillment,
      couponCodes: cartRecord.couponCodes,
      splitOrders: undefined,
      materials: cartMaterialAdapter.setAll(
        filteredCartMaterials,
        initialCartMaterialState
      ),
    };
  }

  public toCartQuantities(
    cart: CartRecord,
    context: MaterialRowContext
  ): CartQuantityUpdate[] {
    return cart.materials.map((material) => {
      return {
        materialNumber: material.materialNumber,
        lines: material.lines,
        context,
      };
    });
  }
}
