import { createActionGroup, emptyProps } from '@ngrx/store';
import { CategorizedCriticalItemRecord } from '../../services/critical-items/model/categorized-critical-items';
import { SortByType } from '../../../guides/shared/guides';

export const CriticalItemsActions = createActionGroup({
  source: 'Critical Items',
  events: {
    'Get Critical Items': emptyProps(),
    'Get Critical Items Success': (
      categorizedCriticalItems: CategorizedCriticalItemRecord[]
    ) => ({ categorizedCriticalItems }),
    'Get Critical Items Failure': emptyProps(),
    'Add Critical Item': (materialNumber: string) => ({ materialNumber }),
    'Delete Critical Item': (materialNumber: string) => ({ materialNumber }),
    'Refresh Critical Items': emptyProps(),
    'Update Search Text': (searchText: string) => ({ searchText }),
    'Update Sort By': (sortBy: SortByType) => ({ sortBy }),
  },
});
