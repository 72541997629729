import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  EntitlementRecordState,
  EntitlementState,
  OverallocatedMaterialDetail,
} from './entitlement.state';
import { selectHasPermissionEnabled } from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import { EntitlementMaterialDetail } from '../../services/entitlement/models/entitlement';
import { EntityState } from '@ngrx/entity/src/models';
import { selectAllOrderableCartMaterials } from '../cart/cart.selectors';
import { CartMaterialState } from '../cart/cart.state';
import { getQuantityFromCartMaterial } from '../../../shared/utilities/cart-material-utilities';
import { NaooConstants } from '../../../shared/NaooConstants';
import { MaterialCommodityShieldColor } from '../material-row/models/material-row';
import { getMaterialCommodityShieldColor } from './entitlement.util';

const selectEntitlementFeature = createFeatureSelector<EntitlementState>(
  'entitlement'
);

const selectEntitlementRecordState = createSelector(
  selectEntitlementFeature,
  (state: EntitlementState): EntitlementRecordState | undefined =>
    state?.entitlementRecordState
);

export const selectOverallocatedMaterialDetails = createSelector(
  selectEntitlementFeature,
  (state: EntitlementState): EntityState<OverallocatedMaterialDetail> =>
    state.overallocatedMaterialDetails
);

export const selectSelectedMaterialNumber = createSelector(
  selectEntitlementFeature,
  (state: EntitlementState): string | undefined => state?.selectedMaterialNumber
);

export const selectAllEntitlementMaterialDetails = createSelector(
  selectEntitlementRecordState,
  (
    state: EntitlementRecordState | undefined
  ): EntityState<EntitlementMaterialDetail> | undefined =>
    state?.materialDetailRecords
);

export const selectIsEntitlementLoading = createSelector(
  selectEntitlementFeature,
  selectHasPermissionEnabled(CustomerPermission.CommodityAccess),
  (state: EntitlementState, hasCommodityAccess: boolean): boolean =>
    hasCommodityAccess && state.isLoading
);

export const selectOverallocatedMaterialDetail = (materialNumber: string) =>
  createSelector(
    selectOverallocatedMaterialDetails,
    (
      overallocatedMaterialDetailRecords: EntityState<OverallocatedMaterialDetail>
    ): OverallocatedMaterialDetail | undefined =>
      overallocatedMaterialDetailRecords.entities[materialNumber]
  );

export const selectEntitlementMaterialDetail = (materialNumber: string) =>
  createSelector(
    selectAllEntitlementMaterialDetails,
    (
      materialDetailRecords: EntityState<EntitlementMaterialDetail> | undefined
    ): EntitlementMaterialDetail | undefined =>
      materialDetailRecords?.entities[materialNumber]
  );

export const selectEntitlementMaterialDetails = (materialNumbers: string[]) =>
  createSelector(
    selectAllEntitlementMaterialDetails,
    (
      materialDetailRecords: EntityState<EntitlementMaterialDetail> | undefined
    ): EntitlementMaterialDetail[] =>
      materialNumbers.map(
        (materialNumber) => materialDetailRecords?.entities[materialNumber]
      )
  );

export const selectMappedEntitlementMaterialDetails = (
  materialNumbers: string[]
) =>
  createSelector(
    selectEntitlementMaterialDetails(materialNumbers),
    (entitlementMaterialDetail) =>
      new Map(
        entitlementMaterialDetail
          .filter((i) => !!i)
          .map((i) => [i.materialNumber, i])
      )
  );

export const selectHasOverallocatedCartMaterials = createSelector(
  selectAllEntitlementMaterialDetails,
  selectOverallocatedMaterialDetails,
  selectAllOrderableCartMaterials,
  (
    entitlementDetails: EntityState<EntitlementMaterialDetail> | undefined,
    overallocatedDetails: EntityState<OverallocatedMaterialDetail>,
    cartMaterials: CartMaterialState[]
  ): boolean => {
    if (!entitlementDetails && overallocatedDetails.ids.length === 0) {
      return false;
    }
    return cartMaterials.some((material) => {
      const overallocatedMaterialDetail: OverallocatedMaterialDetail =
        overallocatedDetails.entities[material.materialNumber];

      if (overallocatedMaterialDetail) {
        return (
          getQuantityFromCartMaterial(material, NaooConstants.Uom.Case) >
          overallocatedMaterialDetail.maxCommodityCasesAmount
        );
      } else {
        const entitlementDetail: EntitlementMaterialDetail =
          entitlementDetails?.entities[material.materialNumber];
        const color: MaterialCommodityShieldColor = getMaterialCommodityShieldColor(
          entitlementDetail
        );

        return MaterialCommodityShieldColor.GREEN === color
          ? getQuantityFromCartMaterial(material, NaooConstants.Uom.Case) >
              entitlementDetail.maxCommodityCasesAmount
          : false;
      }
    });
  }
);
