import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialAvailabilityRecord } from '../../services/material-availability/model/material-availabilities-record';

export interface MaterialAvailabilityState
  extends EntityState<MaterialAvailabilityRecordState> {}

export interface MaterialAvailabilityRecordState {
  materialNumber: string;
  status: MaterialAvailabilityRecordStatus;
  record?: MaterialAvailabilityRecord;
}

export enum MaterialAvailabilityRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const materialAvailabilityAdapter = createEntityAdapter<MaterialAvailabilityRecordState>(
  {
    selectId: (state) => state.materialNumber,
  }
);

export const initialMaterialAvailabilityState = materialAvailabilityAdapter.getInitialState();
