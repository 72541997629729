import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { MaterialAvailabilityService } from '../../services/material-availability/material-availability.service';
import { defaultIfEmpty, Observable, of } from 'rxjs';
import { MaterialAvailabilityActions } from './material-availability.actions';
import {
  catchError,
  delayWhen,
  first,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { selectAllMaterialAvailabilityRecords } from './material-availability.selectors';
import { MaterialAvailabilityRecordStatus } from './material-availability.state';
import { selectCurrentSystem } from '../session/session.selectors';
import { MaterialCutoffActions } from '../material-cutoff/material-cutoff.actions';
import { CurrentSystem } from '../../services/session/models/session-record';
import { selectIsOnlineCartLoaded } from '../cart/cart.selectors';
import { selectListMaterialNumbers } from '../list/list.selectors';
import { SharedActions } from '../shared/shared.actions';
import { chunkArray } from '../../../shared/utilities/array-utilities';

@Injectable()
export class MaterialAvailabilityEffects {
  private readonly maxMaterialsPerRequest = 1000;

  constructor(
    private actions$: Actions,
    private store: Store,
    private materialAvailabilityService: MaterialAvailabilityService
  ) {}

  loadMaterialAvailability$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialAvailabilityActions.loadMaterialAvailability),
      concatLatestFrom(() =>
        this.store.select(selectAllMaterialAvailabilityRecords)
      ),
      mergeMap(([action, storedMaterialAvailabilities]) => {
        const queuedMaterialNumbers = action.materialNumbers.filter(
          (materialNumber) =>
            storedMaterialAvailabilities[materialNumber].status ===
            MaterialAvailabilityRecordStatus.Queued
        );

        if (queuedMaterialNumbers.length < this.maxMaterialsPerRequest) {
          return of(
            MaterialAvailabilityActions.getMaterialAvailability(
              queuedMaterialNumbers
            )
          );
        }

        const dispatchActions: Action[] = [];
        chunkArray(
          queuedMaterialNumbers,
          this.maxMaterialsPerRequest
        ).forEach((idBatch) =>
          dispatchActions.push(
            MaterialAvailabilityActions.getMaterialAvailability(idBatch)
          )
        );

        return dispatchActions;
      })
    );
  });

  getMaterialAvailability$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialAvailabilityActions.getMaterialAvailability),
      delayWhen(() =>
        this.store.select(selectIsOnlineCartLoaded).pipe(
          first((isOnlineCartLoaded) => isOnlineCartLoaded),
          takeUntil(
            this.actions$.pipe(
              ofType(MaterialAvailabilityActions.clearMaterialAvailability)
            )
          ),
          defaultIfEmpty(true)
        )
      ),
      concatLatestFrom(() => [
        this.store.select(selectAllMaterialAvailabilityRecords),
        this.store.select(selectIsOnlineCartLoaded),
      ]),
      mergeMap(([action, storedMaterialAvailabilities, isOnlineCartLoaded]) => {
        if (!isOnlineCartLoaded) {
          return of(
            SharedActions.noOperation(
              'getMaterialAvailability$: online cart is not loaded'
            )
          );
        }
        const requestedMaterialNumbers = action.materialNumbers?.filter(
          (materialNumber) =>
            storedMaterialAvailabilities[materialNumber]?.status ===
            MaterialAvailabilityRecordStatus.Requested
        );
        if (requestedMaterialNumbers.length === 0) {
          return of(
            SharedActions.noOperation(
              'Material Availability items are being requested'
            )
          );
        }
        return this.materialAvailabilityService
          .getMaterialAvailabilities(requestedMaterialNumbers)
          .pipe(
            switchMap((availabilitiesRecord) => {
              const successMaterialNumbers = availabilitiesRecord.materialAvailabilities.map(
                (record) => record.materialNumber
              );
              const errorMaterialNumbers = requestedMaterialNumbers.filter(
                (materialNumber) =>
                  !successMaterialNumbers.includes(materialNumber)
              );
              const dispatchedActions: Action[] = [
                MaterialAvailabilityActions.getMaterialAvailabilitySuccess(
                  availabilitiesRecord.materialAvailabilities
                ),
              ];
              if (errorMaterialNumbers.length > 0) {
                dispatchedActions.push(
                  MaterialAvailabilityActions.getMaterialAvailabilityError(
                    errorMaterialNumbers
                  )
                );
              }

              return dispatchedActions;
            }),
            catchError(() =>
              of(
                MaterialAvailabilityActions.getMaterialAvailabilityError(
                  requestedMaterialNumbers
                )
              )
            ),
            takeUntil(
              this.actions$.pipe(
                ofType(MaterialAvailabilityActions.clearMaterialAvailability)
              )
            )
          );
      })
    );
  });

  getMaterialAvailabilitySuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialAvailabilityActions.getMaterialAvailabilitySuccess),
      concatLatestFrom(() => this.store.select(selectCurrentSystem)),
      map(([action, currentSystem]) => {
        const validCutoffs = action.materialAvailabilityRecords
          .filter((availability) => !!availability.cutoffCode)
          .map((record) => record.cutoffCode);
        const uniqueJitCodes = Array.from(new Set(validCutoffs));

        return CurrentSystem.isMygfsOrSap(currentSystem)
          ? SharedActions.noOperation(
              'Customer is not Retalix, skipping LoadMaterialCutoffsAction'
            )
          : MaterialCutoffActions.loadMaterialCutoffs(
              currentSystem,
              uniqueJitCodes
            );
      })
    );
  });

  refreshListAvailability$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialAvailabilityActions.refreshListMaterialAvailability),
      concatLatestFrom(() => this.store.select(selectListMaterialNumbers)),
      mergeMap(([_, listMaterials]) =>
        of(MaterialAvailabilityActions.loadMaterialAvailability(listMaterials))
      )
    );
  });
}
