import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { SessionFacade } from '../../core/store/session/session.facade';
import { CurrentSystem } from '../../core/services/session/models/session-record';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Pipe({
  name: 'deliveryOrShip',
})
export class NaooDeliveryOrShipPipe implements OnDestroy, PipeTransform {
  private destroyed$ = new Subject<void>();

  private readonly shipDate = 'SHIP_DATE';
  private readonly deliveryDate = 'DELIVERY_DATE';
  private prefix = this.shipDate;

  constructor(private sessionFacade: SessionFacade) {
    this.sessionFacade
      .getLoadedCurrentSystem()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (currentSystem) =>
          (this.prefix = CurrentSystem.isMygfsOrSap(currentSystem)
            ? this.deliveryDate
            : this.shipDate)
      );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(key: string): string | null {
    return `${this.prefix}.${key}`;
  }
}
