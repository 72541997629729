import {
  initialMaterialRelatedState,
  materialRelatedAdapter,
  MaterialRelatedRecordState,
  MaterialRelatedState,
} from './material-related.state';
import { Update } from '@ngrx/entity';
import { MaterialRelatedActions } from './material-related.actions';
import { MaterialRelatedRecord } from '../../services/material-related/model/material-related-record';
import { createReducer, on } from '@ngrx/store';

export const materialRelatedReducer = createReducer(
  initialMaterialRelatedState,
  on(
    MaterialRelatedActions.clearMaterialRelated,
    MaterialRelatedActions.loadMaterialRelated,
    (): MaterialRelatedState => initialMaterialRelatedState
  ),
  on(
    MaterialRelatedActions.getMaterialRelated,
    (state, action): MaterialRelatedState =>
      getMaterialRelated(state, action.materialNumbers)
  ),
  on(
    MaterialRelatedActions.getMaterialRelatedSuccess,
    (state, action): MaterialRelatedState =>
      getMaterialRelatedSuccess(state, action.records.related)
  ),
  on(
    MaterialRelatedActions.getMaterialRelatedError,
    (state, action): MaterialRelatedState =>
      getMaterialRelatedError(state, action.materialNumbers)
  )
);

function getMaterialRelated(
  state: MaterialRelatedState,
  materialNumbers: string[]
): MaterialRelatedState {
  const records: MaterialRelatedRecordState[] = materialNumbers.map(
    (materialNumber) => {
      return {
        materialNumber,
        hasLoaded: false,
        record: {
          materialNumber,
          substitutes: [],
          similar: [],
        },
      };
    }
  );

  return materialRelatedAdapter.upsertMany(records, state);
}

function getMaterialRelatedSuccess(
  state: MaterialRelatedState,
  records: MaterialRelatedRecord[]
): MaterialRelatedState {
  const updates: Update<MaterialRelatedRecordState>[] = records.map(
    (record) => {
      return {
        id: record.materialNumber,
        changes: {
          hasLoaded: true,
          record: {
            materialNumber: record.materialNumber,
            substitutes: record.substitutes,
            similar: record.similar,
          },
        },
      };
    }
  );

  return materialRelatedAdapter.updateMany(updates, state);
}

function getMaterialRelatedError(
  state: MaterialRelatedState,
  materialNumbers: string[]
): MaterialRelatedState {
  const updates: Update<MaterialRelatedRecordState>[] = materialNumbers.map(
    (materialNumber) => {
      return {
        id: materialNumber,
        changes: {
          hasLoaded: true,
        },
      };
    }
  );

  return materialRelatedAdapter.updateMany(updates, state);
}
