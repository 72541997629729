import {
  CriticalItemsState,
  initialCriticalItemsState,
} from './critical-items.state';
import { CriticalItemsActions } from './critical-items.actions';
import { createReducer, on } from '@ngrx/store';

export const criticalItemsReducer = createReducer(
  initialCriticalItemsState,
  on(
    CriticalItemsActions.addCriticalItem,
    CriticalItemsActions.deleteCriticalItem,
    CriticalItemsActions.getCriticalItems,
    (state): CriticalItemsState => state
  ),
  on(
    CriticalItemsActions.updateSortBy,
    (state, action): CriticalItemsState => ({ ...state, sortBy: action.sortBy })
  ),
  on(
    CriticalItemsActions.updateSearchText,
    (state, action): CriticalItemsState => ({
      ...state,
      searchText: action.searchText,
    })
  ),
  on(
    CriticalItemsActions.refreshCriticalItems,
    (): CriticalItemsState => initialCriticalItemsState
  ),
  on(
    CriticalItemsActions.getCriticalItemsFailure,
    (state): CriticalItemsState => ({ ...state, categorizedCriticalItems: [] })
  ),
  on(
    CriticalItemsActions.getCriticalItemsSuccess,
    (state, action): CriticalItemsState => ({
      ...state,
      categorizedCriticalItems: action.categorizedCriticalItems,
    })
  )
);
