import { Injectable } from '@angular/core';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { MarketingGuideRecord } from './models/marketing-guide-record';

@Injectable({
  providedIn: 'root',
})
export class MarketingGuideService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getMarketingGuide(pathUrl: string): Observable<MarketingGuideRecord> {
    return this.httpClient.get<MarketingGuideRecord>(
      `${this.webBffService.getBff()}/api/v1/marketing-guides/${pathUrl}`
    );
  }
}
