import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PickupScheduleRecord } from '../../services/pickup-schedules/models/pickup-schedule-record';
import {
  selectDaysAfterFromPickupSchedules,
  selectPickupScheduleRecord,
} from './pickup-schedules.selectors';
import { Observable } from 'rxjs';
import { Moment } from 'moment';

@Injectable({ providedIn: 'root' })
export class PickupSchedulesFacade {
  constructor(private store: Store) {}

  getPickupScheduleRecord(
    storePlantId: string,
    selectedDate: string
  ): Observable<PickupScheduleRecord | undefined> {
    return this.store.select(
      selectPickupScheduleRecord(storePlantId, selectedDate)
    );
  }

  getDaysAfterFromPickupSchedules(
    storePlantId: string,
    startMoment: Moment,
    count: number
  ): Observable<Moment[]> {
    return this.store.select(
      selectDaysAfterFromPickupSchedules(storePlantId, startMoment, count)
    );
  }
}
