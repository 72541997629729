import { ErrorActions } from './error.actions';
import { ErrorState, initialErrorState } from './error.state';
import { createReducer, on } from '@ngrx/store';

export const errorReducer = createReducer(
  initialErrorState,
  on(
    ErrorActions.fatalError,
    (state, action): ErrorState => ({
      ...state,
      fatalError: action.error,
    })
  ),
  on(
    ErrorActions.silentError,
    (state, action): ErrorState => ({
      ...state,
      silentError: action.payload,
    })
  )
);
