import {
  CustomGuideCategoryRecord,
  CustomGuideMaterialRecord,
  CustomGuideParLineRecord,
  CustomGuideRecord,
} from '../../services/custom-guide/model/custom-guide-record';
import { GuideItem } from '../../../shared/models/guide-item';
import { createProductInfoFromMaterialInfo } from '../../../shared/models/product-info';
import { CategorizedMaterials } from '../../../shared/models/categorized-materials';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import {
  CategorizedCustomGuideRecordState,
  CategorizedMaterialNumbers,
  CustomGuideRecordState,
} from './custom-guide.state';
import { Dictionary } from '@ngrx/entity';
import { CustomGuideMaterialParOrder } from './custom-guide.selectors';
import {
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
} from '../material-info/material-info.state';
import {
  createMaterialInfoFromMaterialInfoRecordState,
  hasMaterialInfoFinishedLoading,
} from '../material-info/material-info.util';
import { LastOrderedRecordState } from '../last-ordered/last-ordered.state';
import { intersection } from 'lodash-es';
import { Localized } from '../../../shared/models/localized';
import {
  CustomGuideCreationSourceAnalyticLabel,
  ListsAnalyticsConstants,
} from '../../../lists/lists-analytics.constants';
import { GroupByType } from 'src/app/guides/shared/guides';
import { Language } from '../../services/session/models/session-record';

export function cloneCustomGuideRecord(
  record: CustomGuideRecord
): CustomGuideRecord {
  return {
    id: record.id,
    name: record.name,
    sortBy: record.sortBy,
    groupBy: record.groupBy,
    parOrderingEnabled: record.parOrderingEnabled,
    categories: record.categories.map((category) => {
      return {
        name: { ...category.name },
        materials: category.materials.map((material) => {
          return {
            materialNumber: material.materialNumber,
            parLines: material.parLines.map((parLine) => {
              return {
                uom: parLine.uom,
                parQuantity: parLine.parQuantity,
                inventoryQuantity: parLine.inventoryQuantity,
              };
            }),
          };
        }),
      };
    }),
  };
}

export function getCategoryRecordForMaterial(
  record: CustomGuideRecord,
  materialNumber: string
): CustomGuideCategoryRecord {
  return record
    ? record.categories.find((category) =>
        category.materials
          .map((material) => material.materialNumber)
          .includes(materialNumber)
      )
    : undefined;
}

function getMaterialRecordForMaterial(
  category: CustomGuideCategoryRecord,
  materialNumber: string
): CustomGuideMaterialRecord {
  return category.materials.find(
    (material) => material.materialNumber === materialNumber
  );
}

export function getMaterialRecordFromCustomGuideRecord(
  record: CustomGuideRecord,
  materialNumber: string
): CustomGuideMaterialRecord {
  const category = getCategoryRecordForMaterial(record, materialNumber);
  return category
    ? getMaterialRecordForMaterial(category, materialNumber)
    : undefined;
}

export function getParLineRecordForMaterial(
  record: CustomGuideRecord,
  materialNumber: string,
  uom: string
): CustomGuideParLineRecord {
  const material = getMaterialRecordFromCustomGuideRecord(
    record,
    materialNumber
  );
  return material
    ? material.parLines.find((parLine) => parLine.uom === uom)
    : undefined;
}

export function getCategoryIndexForMaterial(
  record: CustomGuideRecord,
  materialNumber: string
): number {
  const categoryIndex = record.categories.findIndex((category) =>
    category.materials
      .map((material) => material.materialNumber)
      .includes(materialNumber)
  );
  return categoryIndex < 0 ? undefined : categoryIndex;
}

export function getMaterialIndexForMaterial(
  category: CustomGuideCategoryRecord,
  materialNumber: string
): number {
  const materialIndex = category.materials.findIndex(
    (material) => material.materialNumber === materialNumber
  );
  return materialIndex < 0 ? undefined : materialIndex;
}

export function getMaterialNumbersForCategories(
  categories: CustomGuideCategoryRecord[]
): string[] {
  return categories
    .reduce((acc, rec) => acc.concat(rec.materials), [])
    .map((material: CustomGuideMaterialRecord) => material.materialNumber)
    .filter((materialNumbers) => !!materialNumbers);
}

export function getMaterialNumbersForCategorizedMaterials(
  categorizedMaterials: CategorizedMaterials[]
): string[] {
  return categorizedMaterials
    .reduce(
      (acc, categoryMaterial) =>
        acc.concat(
          categoryMaterial.items.map((item) => item.material.materialNumber)
        ),
      []
    )
    .filter((materialNumbers) => !!materialNumbers);
}

export function getTrimmedCategoryName(
  category: CategorizedMaterialNumbers
): Localized<string> {
  return Object.keys(category.categoryName).reduce(
    (localized, language: Language) => {
      return {
        ...localized,
        [language]: category.categoryName[language]?.trim() || '',
      };
    },
    { en: '', fr: '' }
  );
}

export function buildCategorizedMaterials(
  categories: CategorizedMaterialNumbers[],
  materialInfoRecords: Dictionary<MaterialInfoRecordState>,
  lastOrderedRecords: Dictionary<LastOrderedRecordState>,
  categoryType?: string
): CategorizedMaterials[] {
  const categorizedMaterials: CategorizedMaterials[] = [];
  if (categories) {
    categories.forEach((category, idx) => {
      categorizedMaterials.push({
        categoryName: getTrimmedCategoryName(category),
        categoryOrder: idx,
        items: buildGuideItems(
          category,
          materialInfoRecords,
          lastOrderedRecords
        ),
        categoryType: categoryType,
      });
    });
  }
  return categorizedMaterials;
}

export function buildGuideItems(
  category: CategorizedMaterialNumbers,
  materialInfoRecords: Dictionary<MaterialInfoRecordState>,
  lastOrderedRecords: Dictionary<LastOrderedRecordState>
): GuideItem[] {
  const guideItems: GuideItem[] = [];
  category.materialNumbers.forEach((materialNumber, idx) => {
    const lastOrdered = lastOrderedRecords
      ? lastOrderedRecords[materialNumber]
      : undefined;
    const materialInfo = createMaterialInfoFromMaterialInfoRecordState(
      materialInfoRecords[materialNumber],
      lastOrdered
    );
    if (
      !!materialInfo &&
      !!materialInfo.materialNumber &&
      !materialInfo.isEmpty
    ) {
      guideItems.push({
        order: idx,
        material: materialInfo,
        product: createProductInfoFromMaterialInfo(materialInfo),
      });
    }
  });
  return guideItems;
}

export function buildCategorizedMaterialNumbers(
  record: CustomGuideRecord
): CategorizedMaterialNumbers[] {
  return record.categories.map((category) => {
    return {
      categoryName: category.name,
      materialNumbers: category.materials.map(
        (material) => material.materialNumber
      ),
    };
  });
}

export function createCustomGuideCreationAnalyticEvent(
  sourceCreationLabel: CustomGuideCreationSourceAnalyticLabel,
  groupBy: GroupByType
): AnalyticsEventInfo {
  return {
    action: ListsAnalyticsConstants.clickAction,
    category: ListsAnalyticsConstants.customGuideCategory,
    label: `${sourceCreationLabel} - ${groupBy}`,
  };
}

export function getCustomGuideMaterialParOrderMap(
  customGuideRecord: CustomGuideRecord
): Map<string, CustomGuideMaterialParOrder> {
  const materialParOrders: Map<string, CustomGuideMaterialParOrder> = new Map();
  customGuideRecord.categories.forEach((category) => {
    category.materials.forEach((material) => {
      const materialParOrder: CustomGuideMaterialParOrder = {
        id: customGuideRecord.id,
        materialNumber: material.materialNumber,
        parLineRecords: material.parLines,
        isParOrderingEnabled: customGuideRecord.parOrderingEnabled,
      };
      materialParOrders.set(material.materialNumber, materialParOrder);
    });
  });

  return materialParOrders;
}

export function hasCategorizedMaterialsFinishedLoading(
  recordState: CategorizedCustomGuideRecordState
): boolean {
  return !!recordState && recordState.hasLoaded;
}

function hasCategorizedMaterialsByGroupFinishedLoading(
  recordState: CustomGuideRecordState,
  groupBy: GroupByType
): boolean {
  switch (groupBy) {
    case GroupByType.Taxonomy:
      return hasCategorizedMaterialsFinishedLoading(
        recordState.taxonomyCategory
      );
    case GroupByType.Storage:
      return hasCategorizedMaterialsFinishedLoading(
        recordState.storageAreaCategory
      );
    case GroupByType.Custom:
    default:
      return !!recordState.record;
  }
}

function hasAllRequestedMaterialsFinishedLoading(
  materialInfos: Dictionary<MaterialInfoRecordState>,
  materialNumbers: string[]
): boolean {
  return materialNumbers.every((materialNumber) =>
    hasMaterialInfoFinishedLoading(materialInfos[materialNumber])
  );
}

export function getCustomGuideCategorizedMaterials(
  recordState: CustomGuideRecordState,
  materialInfoRecords: Dictionary<MaterialInfoRecordState>,
  lastOrderedRecords: Dictionary<LastOrderedRecordState>,
  customGuideGroupBy?: GroupByType,
  categoryType?: string
): CategorizedMaterials[] {
  const materialNumbers = getMaterialNumbersForCategories(
    recordState.record.categories
  );
  if (
    !hasAllRequestedMaterialsFinishedLoading(
      materialInfoRecords,
      materialNumbers
    ) ||
    !hasCategorizedMaterialsByGroupFinishedLoading(
      recordState,
      customGuideGroupBy
    )
  ) {
    return undefined;
  }

  switch (customGuideGroupBy) {
    case GroupByType.Taxonomy:
      return buildCategorizedMaterials(
        recordState.taxonomyCategory.categories,
        materialInfoRecords,
        lastOrderedRecords,
        categoryType
      );
    case GroupByType.Storage:
      return buildCategorizedMaterials(
        recordState.storageAreaCategory.categories,
        materialInfoRecords,
        lastOrderedRecords,
        categoryType
      );
    case GroupByType.Custom:
    default:
      return buildCategorizedMaterials(
        buildCategorizedMaterialNumbers(recordState.record),
        materialInfoRecords,
        lastOrderedRecords,
        categoryType
      );
  }
}

export function getCustomGuideCategorizedRecordState(
  initialCategorizedRecordState: CategorizedCustomGuideRecordState,
  customGuideRecord: CustomGuideRecord
): CategorizedCustomGuideRecordState {
  if (
    !!customGuideRecord &&
    !!customGuideRecord.categories &&
    !!initialCategorizedRecordState &&
    !!initialCategorizedRecordState.categories
  ) {
    const materialNumbers = getMaterialNumbersForCategories(
      customGuideRecord.categories
    );
    const record = initialCategorizedRecordState.categories.reduce(
      (materialAccumulator, categorizedMaterial) => {
        const newMaterialNumbers = intersection(
          categorizedMaterial.materialNumbers,
          materialNumbers
        );
        if (!!newMaterialNumbers && newMaterialNumbers.length !== 0) {
          materialAccumulator.push(<CategorizedMaterialNumbers>{
            ...categorizedMaterial,
            materialNumbers: newMaterialNumbers,
          });
        }
        return materialAccumulator;
      },
      []
    );
    return {
      ...initialCategorizedRecordState,
      categories: record,
    };
  }
  return initialCategorizedRecordState;
}

export function getGuideMaterialCount(
  categories: CustomGuideCategoryRecord[],
  materialInfoRecords: Dictionary<MaterialInfoRecordState>
): number {
  const materialNumbers = getMaterialNumbersForCategories(categories);
  return materialNumbers.filter(
    (materialNumber) =>
      !!materialInfoRecords[materialNumber] &&
      materialInfoRecords[materialNumber].status !==
        MaterialInfoRecordStatus.Unavailable
  ).length;
}
