import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { NAOOErrorCode, NaooErrorUtils } from '../error-handler/NaooErrorUtils';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { InvalidSessionService } from '../services/session/invalid-session.service';

@Injectable()
export class NaooInvalidSessionInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private invalidSessionService: InvalidSessionService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && this.isSessionInvalid(err)) {
          this.invalidSessionService.setSessionInvalid();
          return EMPTY;
        }
        return throwError(err);
      })
    );
  }

  private isSessionInvalid(err: HttpErrorResponse) {
    return (
      NaooErrorUtils.getNaooError(err).code === NAOOErrorCode.INVALID_SESSION
    );
  }
}
