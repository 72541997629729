<div class="button-arrow">
  <button
    class="arrow-left"
    (click)="arrowPrevious()"
    [disabled]="arrowPreviousDisabled"
    [style.visibility]="arrowVisibility"
    attr.aria-label="{{ 'HOME_PAGE.ARROW_TEXT' | translate }}"
  >
    <mat-icon
      [ngClass]="arrowPreviousDisabled ? 'arrow-disabled' : 'arrow-icon'"
      svgIcon="arrow-v2"
    ></mat-icon>
  </button>
  <div
    #materialRowContainer
    class="recommendation-row"
    (swipeLeft)="arrowNext()"
    (swipeRight)="arrowPrevious()"
    [style.touch-action]="'pan-y'"
  >
    <ng-container
      *ngFor="let materialRow of visibleMaterialRows; trackBy: trackMaterial"
    >
      <div
        [style.width]="dynamicWidth"
        [ngClass]="{
          'material-row-container': !isMaterialComparisons(),
          'material-row-container-comparisons': isMaterialComparisons()
        }"
      >
        <div *ngIf="showCount" class="count-element">
          {{
            'PRODUCT_COMPARISON.SHOWING_REFERENCE_MOBILE'
              | translate
                : {
                    currentItem: cardIndex(materialRow),
                    totalCount: materialListRows.length
                  }
          }}
        </div>
        <naoo-material-row-container
          [materialNumber]="materialRow.value"
          [context]="materialContext"
          [listStyle]="materialListStyle"
          [isMobile]="false"
          [cardWidth]="dynamicWidth"
          [recommendationEngineName]="recommendationEngineName"
          (click)="handleMaterialClicked(materialRow)"
        >
        </naoo-material-row-container>
      </div>
    </ng-container>
  </div>
  <button
    class="arrow-right"
    (click)="arrowNext()"
    [disabled]="arrowNextDisabled"
    [style.visibility]="arrowVisibility"
    attr.aria-label="{{ 'HOME_PAGE.ARROW_TEXT' | translate }}"
  >
    <mat-icon
      [ngClass]="arrowNextDisabled ? 'arrow-disabled' : 'arrow-icon'"
      svgIcon="arrow-v2"
    ></mat-icon>
  </button>
</div>
