import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaterialCutoffRequest } from './models/material-cutoff-request';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { MaterialCutoffRecord } from './models/material-cutoff-record';
import { silentRequestConfiguration } from '../../../shared/http-client/naoo-request-configuration';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';

@Injectable({ providedIn: 'root' })
export class MaterialCutoffService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getCutoffTimes(
    request: MaterialCutoffRequest
  ): Observable<MaterialCutoffRecord> {
    return this.httpClient.post<MaterialCutoffRecord>(
      this.webBffService.getBff() + '/api/v3/cutoff-times',
      request,
      undefined,
      silentRequestConfiguration
    );
  }
}
