import { NgZone } from '@angular/core';
import { Subscription, SchedulerLike, SchedulerAction, Observable } from 'rxjs';

/* eslint-disable @typescript-eslint/no-unused-vars */

class LeaveZoneScheduler<T> implements SchedulerLike {
  constructor(private zone: NgZone, private scheduler: SchedulerLike) {}

  now(): number {
    return this.scheduler.now();
  }

  schedule<T>(
    work: (this: SchedulerAction<T>, state?: T) => void,
    delay?: number,
    state?: T
  ): Subscription {
    return this.zone.runOutsideAngular(() => {
      return this.scheduler.schedule<T>(work, delay, state);
    });
  }
}

class EnterZoneScheduler<T> implements SchedulerLike {
  constructor(private zone: NgZone, private scheduler: SchedulerLike) {}

  now(): number {
    return this.scheduler.now();
  }

  schedule<T>(
    work: (this: SchedulerAction<T>, state?: T) => void,
    delay?: number,
    state?: T
  ): Subscription {
    return this.zone.run(() => {
      return this.scheduler.schedule<T>(work, delay, state);
    });
  }
}

export class ZoneUtilities {
  /**
   * leaveZone(NgZone, SchedulerLike)
   *
   * returns a new LeaveZoneScheduler that will run outside of the angular zone,
   * generally done to avoid over-triggering change detection.
   *
   * Example:
   * bufferTime(1000, leaveZone(this.ngZone, asyncScheduler))
   */
  public static leaveZone(
    zone: NgZone,
    scheduler: SchedulerLike
  ): SchedulerLike {
    return new LeaveZoneScheduler(zone, scheduler);
  }

  /**
   * enterZone(NgZone, SchedulerLike)
   *
   * returns a new EnterZoneScheduler that will run inside of the angular zone,
   * generally done once something running outside the angular zone has found something
   * of interest.
   *
   * Example:
   * timer(500, 2000), //emit after .5s, then every 2 seconds after that
   * bufferTime(1000, leaveZone(this.ngZone, asyncScheduler)), //collect emitted values for 1 second outside of Angular
   * filter(values => values.length > 1), //filter out any values emitted from bufferTime that have less than 1 item
   * //now we have something interesting so re-enter Angular using subscribeOn and our enterZone
   * subscribeOn(enterZone(this.ngZone, asyncScheduler)),
   */
  public static enterZone(
    zone: NgZone,
    scheduler: SchedulerLike
  ): SchedulerLike {
    return new EnterZoneScheduler(zone, scheduler);
  }

  /**
   * onZoneStable(NgZone)
   *
   * Returns the `onZoneStable` EventEmitter of the provided NgZone as an Observable for easier mocking in component tests.
   *
   * Example:
   * onZoneStable(this.ngZone).pipe(first()).subscribe(() => {})
   */
  public static onZoneStable(zone: NgZone): Observable<any> {
    return zone.onStable.asObservable();
  }
}
