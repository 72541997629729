import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface MessageData {
  messageKey: string;
  parameters?: object;
}

export interface ImageData {
  imageLocation: string;
  altText: string;
}

export interface GenericModalComponentData {
  messageData: string | MessageData;
  helperMessageData: string | MessageData;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  closeAction: () => void;
  isDestructive: boolean;
  image: ImageData;
  title: string;
}

@Component({
  selector: 'naoo-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
  messageData: MessageData | null;
  helperMessageData: MessageData | null;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  closeAction: () => void;
  isDestructive: boolean;
  image: ImageData;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<GenericModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: GenericModalComponentData
  ) {
    this.messageData = this.parseMessageData(data.messageData);
    this.helperMessageData = this.parseMessageData(data.helperMessageData);
    this.primaryButtonText = data.primaryButtonText;
    this.secondaryButtonText = data.secondaryButtonText;
    this.primaryButtonAction = data.primaryButtonAction;
    this.secondaryButtonAction = data.secondaryButtonAction;
    this.closeAction = data.closeAction;
    this.isDestructive = data.isDestructive;
    this.image = data.image;
    this.title = data.title;
  }

  private parseMessageData(
    messageData: string | MessageData
  ): MessageData | null {
    if (messageData && typeof messageData === 'object') {
      return messageData;
    } else if (typeof messageData === 'string') {
      return {
        messageKey: messageData,
      };
    }
    return null;
  }

  get hasVideoData(): boolean {
    return this.image?.imageLocation.endsWith('.mp4');
  }
}
