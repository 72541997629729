<img
  *ngIf="!shouldUseWarningIcon"
  [ngClass]="{
    'disable-link': isImageLinkDisabled,
    mobile: isMobile,
    complimentary: isComplimentaryProduct
  }"
  [class]="materialViewClass"
  [src]="(materialRowOptions?.materialNumber | naooimage: imageSize)?.src"
  [srcset]="(materialRowOptions?.materialNumber | naooimage: imageSize)?.srcSet"
  [alt]="materialRowOptions?.materialNumber"
  [tabIndex]="isImageLinkDisabled ? -1 : 0"
  (click)="navigateToProductDetails()"
  (keyup.enter)="navigateToProductDetails()"
  (error)="noImageAvailableURL($event)"
  [height]="imageSize"
  [width]="imageSize"
/>
<div
  class="warning-icon"
  *ngIf="shouldUseWarningIcon"
  [ngClass]="{ mobile: isMobile }"
>
  <mat-icon svgIcon="warning-icon-v1"> </mat-icon>
</div>
