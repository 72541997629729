import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NaooConstants } from '../../NaooConstants';
import { AddToGuideToastComponent } from '../../toast-messages/add-to-guide-toast/add-to-guide-toast.component';
import { MoveToCategoryToastComponent } from '../../toast-messages/move-to-category-toast/move-to-category-toast.component';
import { RemoveFromCategoryToastComponent } from '../../toast-messages/remove-from-category-toast/remove-from-category-toast.component';
import { DeleteCategoryToastComponent } from '../../toast-messages/delete-category-toast/delete-category-toast.component';
import { NavigationStart, Router } from '@angular/router';
import { DeleteCustomGuideToastComponent } from '../../toast-messages/delete-custom-guide-toast/delete-custom-guide-toast.component';
import { DuplicateCustomGuideToastComponent } from '../../toast-messages/duplicate-custom-guide-toast/duplicate-custom-guide-toast.component';
import { RemoveFromCriticalItemsGuideToastComponent } from '../../toast-messages/remove-from-critical-items-guide-toast/remove-from-critical-items-guide-toast.component';
import { AddToCriticalItemsGuideToastComponent } from '../../toast-messages/add-to-critical-items-guide-toast/add-to-critical-items-guide-toast.component';
import { CreateCustomGuideToastComponent } from '../../toast-messages/create-custom-guide-toast/create-custom-guide-toast.component';
import { LocalizationService } from '../translation/localization.service';
import { CartImportToastComponent } from '../../toast-messages/cart-import/cart-import-toast/cart-import-toast.component';

const enum ToastMessagePanelClass {
  CustomGuide = 'naoo-custom-guide-toast-message',
  ActionableMessage = 'naoo-actionable-toast-message',
  CreateCustomGuide = 'naoo-create-custom-guide-toast-message',
}

@Injectable({ providedIn: 'root' })
export class ToastMessageService {
  constructor(
    private matSnackBar: MatSnackBar,
    private router: Router,
    private localizationService: LocalizationService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.hideToastMessage();
      }
    });
  }

  showLocalizedToastMessage(
    messageKey: string,
    interpolateParams?: object,
    duration?: number
  ) {
    this.showToastMessage(
      this.localizationService.instant(messageKey, interpolateParams),
      duration
    );
  }

  showToastMessage(message: string, duration?: number) {
    this.matSnackBar.open(message, undefined, {
      duration: duration || NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
    });
  }

  showCartImportToastMessage(
    itemQuantity: number,
    viewCart?: boolean,
    duration?: number
  ) {
    this.matSnackBar.openFromComponent(CartImportToastComponent, {
      duration: duration || NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      data: {
        itemQuantity,
        viewCart,
      },
    });
  }

  showAddedToGuideMessage(
    message: string,
    customGuideId?: string | null,
    customGuideName?: string,
    duration?: number
  ) {
    this.matSnackBar.openFromComponent(AddToGuideToastComponent, {
      duration: duration || NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      data: {
        message: message,
        customGuideId: customGuideId,
        customGuideName: customGuideName,
      },
    });
  }

  showAddedToCriticalItemsGuideMessage(message: string, duration?: number) {
    this.matSnackBar.openFromComponent(AddToCriticalItemsGuideToastComponent, {
      duration: duration || NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      data: {
        message: message,
      },
    });
  }

  showMovedToCategoryMessage(categoryName: string, success: boolean) {
    this.matSnackBar.openFromComponent(MoveToCategoryToastComponent, {
      duration: NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      data: { categoryName: categoryName, success: success },
      panelClass: ToastMessagePanelClass.CustomGuide,
    });
  }

  showRemovedFromCustomGuideMessage(categoryName: string, success: boolean) {
    this.matSnackBar.openFromComponent(RemoveFromCategoryToastComponent, {
      duration: NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      data: { categoryName: categoryName, success: success },
      panelClass: ToastMessagePanelClass.CustomGuide,
    });
  }

  showRemovedFromCriticalItemsGuideMessage(duration?: number) {
    this.matSnackBar.openFromComponent(
      RemoveFromCriticalItemsGuideToastComponent,
      {
        duration: duration || NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
        panelClass: ToastMessagePanelClass.CustomGuide,
      }
    );
  }

  showDeletedCategoryMessage(
    categoryName: string | null,
    guideName: string | null
  ) {
    this.matSnackBar.openFromComponent(DeleteCategoryToastComponent, {
      duration: NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      data: {
        categoryName: categoryName,
        guideName: guideName,
      },
      panelClass: ToastMessagePanelClass.CustomGuide,
    });
  }

  showDeletedCustomGuideMessage(customGuideName: string) {
    this.matSnackBar.openFromComponent(DeleteCustomGuideToastComponent, {
      duration: NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      data: {
        customGuideName: customGuideName,
      },
      panelClass: ToastMessagePanelClass.CustomGuide,
    });
  }

  showDuplicatedCustomGuideMessage(
    customGuideId: string,
    customGuideName: string
  ) {
    this.matSnackBar.openFromComponent(DuplicateCustomGuideToastComponent, {
      duration: NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      data: {
        customGuideId: customGuideId,
        customGuideName: customGuideName,
      },
      panelClass: ToastMessagePanelClass.CustomGuide,
    });
  }

  showActionableToastMessage(message: string, actionText: string) {
    this.matSnackBar.open(message, actionText, {
      panelClass: ToastMessagePanelClass.ActionableMessage,
    });
  }

  showCreatedCustomGuideMessage(
    customGuideId: string,
    customGuideName: string
  ) {
    this.matSnackBar.openFromComponent(CreateCustomGuideToastComponent, {
      duration: NaooConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      data: {
        customGuideId: customGuideId,
        customGuideName: customGuideName,
      },
      panelClass: ToastMessagePanelClass.CreateCustomGuide,
    });
  }

  hideToastMessage() {
    this.matSnackBar.dismiss();
  }
}
