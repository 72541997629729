import { createReducer, on } from '@ngrx/store';
import { ModalActions } from './modal.actions';
import { initialModalState, ModalState } from './modal.state';

export const modalReducer = createReducer(
  initialModalState,
  on(
    ModalActions.oneButtonModal,
    ModalActions.twoButtonModal,
    (state): ModalState => state
  )
);
