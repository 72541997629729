import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  OrderConfirmationEmailOptInStatus,
  selectExportDetails,
  selectOrderConfirmationEmailOptInStatus,
} from './customer-preferences.selectors';
import { Observable } from 'rxjs';
import { CustomerPreferencesActions } from './customer-preferences.actions';
import { ExportDetailsRecord } from '../../services/customer-preferences/models/customer-preferences-record';
import { Language } from '../../services/session/models/session-record';

@Injectable({ providedIn: 'root' })
export class CustomerPreferencesFacade {
  constructor(private store: Store) {}

  shouldPromptOrderConfirmationEmailOptIn(): Observable<OrderConfirmationEmailOptInStatus> {
    return this.store.select(selectOrderConfirmationEmailOptInStatus);
  }

  addOptInEmail(email: string, language: Language) {
    this.store.dispatch(
      CustomerPreferencesActions.orderConfirmationEmailOptIn(email, language)
    );
  }

  addOptOutEmail(email: string) {
    this.store.dispatch(
      CustomerPreferencesActions.orderConfirmationEmailOptOut(email)
    );
  }

  updateCustomerExportDetailsPreferences(exportDetail: ExportDetailsRecord) {
    this.store.dispatch(
      CustomerPreferencesActions.updateExportDetailsPreferences(exportDetail)
    );
  }

  getLoadedExportPreferences(): Observable<ExportDetailsRecord[]> {
    return this.store.select(selectExportDetails);
  }
}
