import { createSelector, createFeatureSelector } from '@ngrx/store';
import { MaterialRowState } from './material-row.state';

const selectMaterialRowFeature = createFeatureSelector<MaterialRowState>(
  'materialRow'
);

export const selectPreferredMaterialView = createSelector(
  selectMaterialRowFeature,
  (materialRowState: MaterialRowState) => materialRowState.preferredMaterialView
);
