import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { SpecialOrdersService } from '../../services/special-orders/special-orders.service';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import { SpecialOrdersActions } from './special-orders.actions';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';

@Injectable()
export class SpecialOrdersEffects {
  constructor(
    private actions$: Actions,
    private specialOrdersService: SpecialOrdersService
  ) {}

  getSpecialOrders$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpecialOrdersActions.getSpecialOrders),
      mergeMap((_) => {
        return this.specialOrdersService.getSpecialOrders().pipe(
          mergeMap((specialOrdersResponse) => {
            const specialOrdersMaterialNumbers = specialOrdersResponse.specialOrders.map(
              (specialOrderItem) => specialOrderItem.materialNumber
            );
            return [
              MaterialInfoActions.loadMaterialInfo(
                specialOrdersMaterialNumbers
              ),
              MaterialAvailabilityActions.loadMaterialAvailability(
                specialOrdersMaterialNumbers
              ),
              SpecialOrdersActions.getSpecialOrdersSuccess(
                specialOrdersResponse.specialOrders
              ),
            ];
          }),
          catchError(() => of(SpecialOrdersActions.getSpecialOrdersError())),
          takeUntil(
            this.actions$.pipe(
              ofType(SpecialOrdersActions.refreshSpecialOrders)
            )
          )
        );
      })
    );
  });

  refreshSpecialOrders$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpecialOrdersActions.refreshSpecialOrders),
      map((_) => SpecialOrdersActions.getSpecialOrders())
    );
  });
}
