<div
  *ngIf="shouldShowWarning"
  class="warning-container"
  (click)="toggleWarningOpen()"
  tabindex="0"
  (keyup.enter)="toggleWarningOpen()"
  [attr.aria-label]="warning.message | translate: warning.messageParams"
>
  <div
    class="icon-wrapper"
    [ngClass]="[warningActiveClass, warningTooltipClass, warningIconClass]"
  >
    <mat-icon
      *ngIf="!this.warning.isOpen"
      [svgIcon]="'bang_icon'"
      @fadeInAndOut
    >
    </mat-icon>

    <mat-icon
      *ngIf="this.warning.isOpen"
      [svgIcon]="'cancel_icon'"
      class="warning-tooltip"
      [ngClass]="[
        warningActiveClass,
        warningTooltipClass,
        tooltipIconClass,
        tooltipClassForTwoLine
      ]"
      [attr.tooltip]="warning.message | translate: warning.messageParams"
      @fadeInAndOut
    >
    </mat-icon>
  </div>
</div>
