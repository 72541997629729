import { RowSanitizer } from '../../parse-spreadsheet/row-sanitizer';

export class ImportGuideSanitizer implements RowSanitizer {
  static readonly CATEGORY_UNTRUSTED_CHARACTERS = new RegExp(
    /[^a-zA-Z0-9À-ÿ-.,!@$%&*‘’”“~[_ #"'^()=+\]}{:/]/gi
  );
  static readonly OFFERING_ID_UNTRUSTED_CHARACTERS = new RegExp(
    /[^A-Za-z0-9.,+-]/gi
  );

  sanitize(row: string[]): string[] {
    if (row !== null) {
      return row.map((value, index) => {
        if (value) {
          if (index === 0) {
            return value.replace(
              ImportGuideSanitizer.CATEGORY_UNTRUSTED_CHARACTERS,
              ''
            );
          } else if (index === 1) {
            return value.replace(
              ImportGuideSanitizer.OFFERING_ID_UNTRUSTED_CHARACTERS,
              ''
            );
          }
          return null;
        }
        return value;
      });
    } else {
      return [];
    }
  }
}
