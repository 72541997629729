import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NutritionLabel } from 'src/app/core/store/mygfs-nutrition/models/mygfs-nutrition';

@Component({
  selector: 'naoo-mygfs-nutrition-facts-container',
  template: ` <naoo-mygfs-nutrition-facts-content
    [nutritionLabel]="nutritionLabel"
  >
  </naoo-mygfs-nutrition-facts-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MygfsNutritionFactsComponent {
  @Input() materialNumber: string;
  @Input() nutritionLabel: NutritionLabel;
}
