import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InventoryAvailabilityRecord } from './models/inventory-availability-record';
import { Injectable } from '@angular/core';
import { silentRequestConfiguration } from '../../../shared/http-client/naoo-request-configuration';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';

interface InventoryAvailabilityResponse {
  items: InventoryAvailabilityRecord[];
}

@Injectable({ providedIn: 'root' })
export class InventoryAvailabilityService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getInventoryAvailabilities(
    materialNumbers: string[]
  ): Observable<InventoryAvailabilityRecord[]> {
    return this.httpClient
      .post<InventoryAvailabilityResponse>(
        this.webBffService.getBff() + '/api/v3/inventory',
        materialNumbers,
        undefined,
        silentRequestConfiguration
      )
      .pipe(map((response) => (response ? response.items : null)));
  }
}
