import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { MaterialPriceActions } from './material-price.actions';
import {
  bufferTime,
  catchError,
  concatMap,
  delayWhen,
  filter,
  first,
  map,
  mergeMap,
  observeOn,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { asyncScheduler, defaultIfEmpty, Observable, of } from 'rxjs';
import {
  selectAllMaterialPriceRecordStates,
  selectWatchedIds,
} from './material-price.selectors';
import { Injectable, NgZone } from '@angular/core';
import { MaterialPriceRecordStatus } from './material-price.state';
import { ZoneUtilities } from 'src/app/shared/utilities/zone-utilities';
import {
  selectAllCartCouponCodes,
  selectAllCartMaterials,
  selectIsOnlineCartLoaded,
} from '../cart/cart.selectors';
import { CommodityPriceActions } from '../commodity-price/commodity-price.actions';
import { selectHasPermissionEnabled } from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import { CartActions } from '../cart/cart.actions';
import { LoadingService } from '../../../shared/services/loading-service/loading.service';
import { CustomDimension } from '../../../shared/analytics/custom-dimension';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { ToastMessageService } from '../../../shared/services/toast-message/toast-message.service';
import { selectAllCriticalItems } from '../critical-items/critical-items.selector';
import { MaterialPriceService } from '../../services/material-price/material-price.service';
import { SharedActions } from '../shared/shared.actions';
import { chunkArray } from '../../../shared/utilities/array-utilities';

enum CouponAction {
  Added = 'Added',
  Removed = 'Removed',
  Rejected = 'Rejected',
}

@Injectable()
export class MaterialPriceEffects {
  private readonly maxMaterialsPerRequest = 60;
  private readonly couponCategory = 'Coupon';
  private readonly couponLabel = 'Coupon Code';

  constructor(
    private actions$: Actions,
    private store: Store,
    private materialPriceService: MaterialPriceService,
    private ngZone: NgZone,
    private loadingService: LoadingService,
    private analytics: NaooAnalyticsManager,
    private toastMessageService: ToastMessageService
  ) {}

  loadMaterialPrice$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialPriceActions.loadMaterialPrices),
      bufferTime(1000, ZoneUtilities.leaveZone(this.ngZone, asyncScheduler)),
      filter((actions) => actions.length > 0),
      observeOn(ZoneUtilities.enterZone(this.ngZone, asyncScheduler)),
      concatLatestFrom(() => [
        this.store.select(selectAllMaterialPriceRecordStates),
        this.store.select(
          selectHasPermissionEnabled(CustomerPermission.CommodityAccess)
        ),
      ]),
      mergeMap(([actions, priceRecords, hasCommodityAccess]) => {
        const allMaterialNumbers: string[] = [
          ...new Set(
            [].concat(...actions.map((action) => action.materialNumbers))
          ),
        ];
        if (allMaterialNumbers.length === 0) {
          return of(SharedActions.noOperation('No material numbers provided'));
        }

        const couponCodesToAdd: string[] = [
          ...new Set(
            [].concat(
              ...actions
                .filter((action) => !!action.couponCodeToAdd)
                .map((action) => action.couponCodeToAdd)
            )
          ),
        ];

        const queuedMaterialNumbers = allMaterialNumbers.filter(
          (materialNumber) =>
            MaterialPriceRecordStatus.Queued ===
            priceRecords[materialNumber]?.status
        );

        const dispatchActions: Action[] = [];
        if (couponCodesToAdd.length) {
          // Request without batching
          dispatchActions.push(
            MaterialPriceActions.getMaterialPrices(
              queuedMaterialNumbers,
              couponCodesToAdd
            )
          );
        } else {
          // Batch the requests
          chunkArray(
            queuedMaterialNumbers,
            this.maxMaterialsPerRequest
          ).forEach((idBatch) =>
            dispatchActions.push(
              MaterialPriceActions.getMaterialPrices(idBatch, couponCodesToAdd)
            )
          );
        }

        if (hasCommodityAccess) {
          dispatchActions.push(
            CommodityPriceActions.loadCommodityPrices(allMaterialNumbers)
          );
        }
        return dispatchActions;
      })
    );
  });

  getMaterialPrice$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialPriceActions.getMaterialPrices),
      delayWhen(() =>
        this.store.select(selectIsOnlineCartLoaded).pipe(
          first((isOnlineCartLoaded) => isOnlineCartLoaded),
          takeUntil(
            this.actions$.pipe(
              ofType(
                MaterialPriceActions.clearMaterialPrices,
                MaterialPriceActions.refreshMaterialPrices
              )
            )
          ),
          defaultIfEmpty(true)
        )
      ),
      concatLatestFrom(() => [
        this.store.select(selectAllMaterialPriceRecordStates),
        this.store.select(selectAllCartCouponCodes),
        this.store.select(selectIsOnlineCartLoaded),
      ]),
      concatMap(([action, priceRecords, couponCodes, isOnlineCartLoaded]) => {
        if (!isOnlineCartLoaded) {
          return of(
            SharedActions.noOperation(
              'getMaterialPrice$: online cart is not loaded'
            )
          );
        }
        const requestedMaterialNumbers = action.materialNumbers.filter(
          (id: string) => {
            return (
              priceRecords[id].status === MaterialPriceRecordStatus.Requested
            );
          }
        );
        if (requestedMaterialNumbers.length === 0) {
          return of(
            SharedActions.noOperation('Material prices are in the store.')
          );
        }
        const upperCaseCoupons = couponCodes.map((coupon) =>
          coupon.toUpperCase()
        );
        const newCoupons: string[] = action.couponCodesToAdd
          .map((couponCode) => couponCode.toUpperCase())
          .filter((coupon) => upperCaseCoupons.indexOf(coupon) < 0);

        const requestedCoupons: string[] = upperCaseCoupons.concat(newCoupons);

        return this.materialPriceService
          .getMaterialPricing(requestedMaterialNumbers, false, requestedCoupons)
          .pipe(
            switchMap((priceRecord) => {
              const dispatchedActions: Action[] = [
                MaterialPriceActions.getMaterialPricesSuccess(priceRecord),
              ];
              const successMaterials = priceRecord.materialPrices.map(
                (record) => record.materialNumber
              );

              const hasMissingMaterialPrices =
                successMaterials.length < requestedMaterialNumbers.length;
              if (hasMissingMaterialPrices) {
                dispatchedActions.push(
                  MaterialPriceActions.getMaterialPricesError(
                    requestedMaterialNumbers.filter(
                      (material) => !successMaterials.includes(material)
                    )
                  )
                );
              }

              if (newCoupons.length) {
                newCoupons.forEach((couponToAdd) => {
                  const isValidCouponToAdd = !priceRecord.invalidCoupons?.includes(
                    couponToAdd
                  );
                  if (isValidCouponToAdd) {
                    this.trackAnalytics(CouponAction.Added, couponToAdd);
                    dispatchedActions.push(
                      CartActions.addCouponToCart(couponToAdd)
                    );
                  } else {
                    this.trackAnalytics(CouponAction.Rejected, couponToAdd);
                    this.showLocalizedToastMessage(
                      'CART_COUPON.UNABLE_TO_APPLY',
                      couponToAdd
                    );
                  }
                });
              }
              return dispatchedActions;
            }),
            catchError(() => {
              return of(
                MaterialPriceActions.getMaterialPricesError(
                  requestedMaterialNumbers
                )
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  MaterialPriceActions.clearMaterialPrices,
                  MaterialPriceActions.refreshMaterialPrices
                ),
                tap(() => this.loadingService.stop())
              )
            )
          );
      }),
      tap(() => this.loadingService.stop())
    );
  });

  refreshMaterialPrice$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialPriceActions.refreshMaterialPrices),
      concatLatestFrom(() => [
        this.store.select(selectWatchedIds),
        this.store.select(selectAllCartMaterials),
        this.store.select(selectAllCriticalItems),
        this.store.select(
          selectHasPermissionEnabled(CustomerPermission.CommodityAccess)
        ),
      ]),
      mergeMap(
        ([
          action,
          watchedIds,
          cartMaterials,
          criticalItems,
          hasCommodityAccess,
        ]) => {
          let idsToRefresh = watchedIds.concat(
            cartMaterials.map((cartMaterial) => cartMaterial.materialNumber)
          );
          idsToRefresh = idsToRefresh.concat(criticalItems);

          if (idsToRefresh.length === 0) {
            return of(SharedActions.noOperation('No materialNumbers to load'));
          }
          const actions: Action[] = [
            MaterialPriceActions.loadMaterialPrices(
              idsToRefresh,
              action.couponCodeToAdd
            ),
          ];
          if (hasCommodityAccess) {
            actions.push(
              CommodityPriceActions.refreshCommodityPrices(idsToRefresh)
            );
          }

          return actions;
        }
      )
    );
  });

  addMaterialPriceCoupon$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialPriceActions.addMaterialPriceCoupon),
      tap(() => this.loadingService.start()),
      map((action) =>
        MaterialPriceActions.refreshMaterialPrices(action.couponCodeToAdd)
      )
    );
  });

  removeMaterialPriceCoupon$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialPriceActions.removeMaterialPriceCoupon),
      tap((action) => {
        this.trackAnalytics(CouponAction.Removed, action.couponCodeToRemove);
        this.loadingService.start();
      }),
      switchMap((action) => [
        CartActions.removeCouponFromCart(action.couponCodeToRemove),
        MaterialPriceActions.refreshMaterialPrices(),
      ])
    );
  });

  private showLocalizedToastMessage(messageKey: string, couponCode?: string) {
    this.toastMessageService.showLocalizedToastMessage(messageKey, {
      value: couponCode,
    });
  }

  private trackAnalytics(action: CouponAction, couponCode: string) {
    const customDimension43: CustomDimension = {
      index: 43,
      value: couponCode.toUpperCase(),
    };

    const eventInfo: AnalyticsEventInfo = {
      action: action.toString(),
      category: this.couponCategory,
      label: this.couponLabel,
    };

    this.analytics.trackAnalyticsEvent(eventInfo, [customDimension43]);
  }
}
