import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaterialAvailabilityActions } from './material-availability.actions';
import { filter, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { MaterialAvailabilityRecordState } from './material-availability.state';
import {
  selectMaterialAvailabilities,
  selectMaterialAvailability,
  selectMaterialAvailabilityRecordState,
  selectMaterialAvailabilityRecordStates,
} from './material-availability.selectors';
import { MaterialAvailability } from '../../../shared/models/material-availability';
import { hasMaterialAvailabilityFinishedLoading } from './material-availability.util';

@Injectable({ providedIn: 'root' })
export class MaterialAvailabilityFacade {
  constructor(private store: Store) {}

  loadMaterialAvailabilities(materialNumbers: string[]) {
    this.store.dispatch(
      MaterialAvailabilityActions.loadMaterialAvailability(materialNumbers)
    );
  }

  getLoadedMaterialAvailability(
    materialNumber: string
  ): Observable<MaterialAvailability> {
    return this.store
      .select(selectMaterialAvailabilityRecordState(materialNumber))
      .pipe(
        filter((state) => hasMaterialAvailabilityFinishedLoading(state)),
        withLatestFrom(
          this.store.select(selectMaterialAvailability(materialNumber))
        ),
        map(([_, materialAvailability]) => materialAvailability)
      );
  }

  getLoadedMaterialAvailabilities(
    materialNumbers: string[]
  ): Observable<MaterialAvailability[]> {
    return this.store
      .select(selectMaterialAvailabilityRecordStates(materialNumbers))
      .pipe(
        filter((recordStates: MaterialAvailabilityRecordState[]) =>
          recordStates.every((recordState) =>
            hasMaterialAvailabilityFinishedLoading(recordState)
          )
        ),
        withLatestFrom(
          this.store.select(selectMaterialAvailabilities(materialNumbers))
        ),
        map(([_, materialAvailabilities]) => materialAvailabilities)
      );
  }

  getLoadedMaterialAvailabilitiesMap(
    materialNumbers: string[]
  ): Observable<Map<string, MaterialAvailability>> {
    return this.getLoadedMaterialAvailabilities(materialNumbers).pipe(
      map(
        (materialAvailabilities) =>
          new Map(
            materialAvailabilities
              .filter((i) => !!i)
              .map((i) => [i.materialNumber, i])
          )
      )
    );
  }
}
