import {
  initialMaterialWarningState,
  materialWarningEntityAdapter,
  MaterialWarningEntityState,
  MaterialWarningState,
} from './material-warning.state';
import {
  ChangedFieldType,
  MaterialWarningActions,
  ResetMaterialWarningRequest,
} from './material-warning.actions';
import { MaterialWarningType } from './material-warning';
import { createReducer, on } from '@ngrx/store';

const caseWarningsToDelete = [
  MaterialWarningType.UnitCaseConversion,
  MaterialWarningType.PartialStockCase,
  MaterialWarningType.RepeatingDigitCase,
  MaterialWarningType.QuantityThresholdExceededCase,
];

const unitWarningsToDelete = [
  MaterialWarningType.UnitCaseConversion,
  MaterialWarningType.PartialStockCaseUnit,
  MaterialWarningType.RepeatingDigitUnit,
  MaterialWarningType.QuantityThresholdExceededUnit,
];

export const materialWarningReducer = createReducer(
  initialMaterialWarningState,
  on(
    MaterialWarningActions.resetMaterialWarning,
    (state, action): MaterialWarningState => resetWarning(state, action)
  ),
  on(
    MaterialWarningActions.openMaterialWarning,
    (state, action): MaterialWarningState => openWarning(state, action)
  ),
  on(
    MaterialWarningActions.closeMaterialWarning,
    (state, action): MaterialWarningState => closeWarning(state, action)
  ),
  on(
    MaterialWarningActions.acknowledgeMaterialWarning,
    (state, action): MaterialWarningState => acknowledgeWarning(state, action)
  ),
  on(
    MaterialWarningActions.displayedAnalyticsMaterialWarning,
    (state, action): MaterialWarningState =>
      displayedAnalyticsWarning(state, action)
  ),
  on(
    MaterialWarningActions.clearMaterialWarning,
    (): MaterialWarningState => initialMaterialWarningState
  ),
  on(
    MaterialWarningActions.toggleHidingWarning,
    (state, action): MaterialWarningState => toggleHideWarning(state, action)
  ),
  on(
    MaterialWarningActions.resetToggleHidingWarning,
    (state, action): MaterialWarningState =>
      resetToggleHideWarning(state, action)
  )
);

function resetWarning(
  state: MaterialWarningState,
  action: {
    resetMaterialWarningRequest: ResetMaterialWarningRequest[];
  }
): MaterialWarningState {
  const resetWarnings = action.resetMaterialWarningRequest.map((payload) => {
    if (
      payload.changedField === ChangedFieldType.Both ||
      !state.entities[payload.materialNumber]
    ) {
      return {
        materialNumber: payload.materialNumber,
        displayedAnalyticSentWarnings: [],
        acknowledgedWarnings: [],
        isOpen: true,
        shouldHideWarning: false,
      };
    } else {
      const displayedAnalyticSentWarnings =
        state.entities[payload.materialNumber].displayedAnalyticSentWarnings;
      const acknowledgedWarnings =
        state.entities[payload.materialNumber].acknowledgedWarnings;
      const warningsToDelete =
        payload.changedField === ChangedFieldType.Case
          ? caseWarningsToDelete
          : unitWarningsToDelete;

      return {
        materialNumber: payload.materialNumber,
        displayedAnalyticSentWarnings: displayedAnalyticSentWarnings.filter(
          (warningType) => !warningsToDelete.includes(warningType)
        ),
        acknowledgedWarnings: acknowledgedWarnings.filter(
          (warningType) => !warningsToDelete.includes(warningType)
        ),
        isOpen: true,
        shouldHideWarning: false,
      };
    }
  });

  return materialWarningEntityAdapter.upsertMany(resetWarnings, state);
}

function openWarning(
  state: MaterialWarningState,
  action: {
    materialNumber: string;
  }
): MaterialWarningState {
  return materialWarningEntityAdapter.upsertOne(
    {
      ...getDefinedEntityStateOrFallbackToEmptyState(
        state,
        action.materialNumber
      ),
      isOpen: true,
    },
    state
  );
}

function closeWarning(
  state: MaterialWarningState,
  action: {
    materialNumber: string;
  }
): MaterialWarningState {
  return materialWarningEntityAdapter.upsertOne(
    {
      ...getDefinedEntityStateOrFallbackToEmptyState(
        state,
        action.materialNumber
      ),
      isOpen: false,
    },
    state
  );
}

function toggleHideWarning(
  state: MaterialWarningState,
  action: {
    materialNumbers: string[];
  }
): MaterialWarningState {
  const updatedWarnings = action.materialNumbers.map((materialNumber) => {
    if (state.entities[materialNumber]) {
      const materialWarning = state.entities[materialNumber];

      return {
        ...materialWarning,
        shouldHideWarning: !materialWarning.shouldHideWarning,
      };
    }
  });
  return updatedWarnings.length === 0
    ? state
    : materialWarningEntityAdapter.upsertMany(updatedWarnings, state);
}

function resetToggleHideWarning(
  state: MaterialWarningState,
  action: {
    materialNumbers: string[];
  }
): MaterialWarningState {
  const updatedWarnings = action.materialNumbers.map((materialNumber) => {
    if (state.entities[materialNumber]) {
      const materialWarning = state.entities[materialNumber];
      return {
        ...materialWarning,
        shouldHideWarning: false,
      };
    }
  });
  return updatedWarnings.length === 0
    ? state
    : materialWarningEntityAdapter.upsertMany(updatedWarnings, state);
}

function acknowledgeWarning(
  state: MaterialWarningState,
  action: {
    materialNumber: string;
    materialWarningType: MaterialWarningType;
  }
): MaterialWarningState {
  const definedState = getDefinedEntityStateOrFallbackToEmptyState(
    state,
    action.materialNumber
  );

  if (!definedState.acknowledgedWarnings.includes(action.materialWarningType)) {
    const acknowledgedWarnings = [
      ...definedState.acknowledgedWarnings,
      action.materialWarningType,
    ];

    return materialWarningEntityAdapter.upsertOne(
      { ...definedState, acknowledgedWarnings, isOpen: false },
      state
    );
  }

  return state;
}

function displayedAnalyticsWarning(
  state: MaterialWarningState,
  action: {
    materialNumber: string;
    materialWarningType: MaterialWarningType;
  }
): MaterialWarningState {
  const definedState = getDefinedEntityStateOrFallbackToEmptyState(
    state,
    action.materialNumber
  );

  const displayedAnalyticSentWarnings = [
    ...definedState.displayedAnalyticSentWarnings,
    action.materialWarningType,
  ];

  return materialWarningEntityAdapter.upsertOne(
    { ...definedState, displayedAnalyticSentWarnings },
    state
  );
}

function getDefinedEntityStateOrFallbackToEmptyState(
  state: MaterialWarningState,
  materialNumber: string
): MaterialWarningEntityState {
  if (state.entities[materialNumber]) {
    return state.entities[materialNumber];
  }

  return {
    materialNumber,
    isOpen: true,
    acknowledgedWarnings: [],
    displayedAnalyticSentWarnings: [],
    shouldHideWarning: false,
  };
}
