import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { EcommerceAnalyticsActions } from './ecommerce-analytics.actions';
import { catchError, filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { EcommerceAnalyticsService } from '../../services/ecommerce-analytics/ecommerce-analytics.service';
import {
  buildEcommerceCartChangeEvents,
  buildEcommerceCheckoutEvent,
  buildEcommerceDetailViewEvent,
  buildEcommerceImpressionData,
  buildEcommerceProductsDataFromCart,
  buildEcommercePromotionClickEvent,
  buildEcommercePromotionData,
  buildEcommercePromotionViewEvent,
  buildEcommercePurchaseEvent,
  buildEcommerceRecommendationClickEvent,
  buildEcommerceRecommendationViewEvent,
  clearEcommerceEvent,
} from './ecommerce-analytics-transformer';
import { EcommercePromotionData } from '../../services/ecommerce-analytics/models/ecommerce-promotion-data';
import {
  selectAnalyticsPreviousCartEntity,
  selectCartEntity,
} from '../cart/cart.selectors';
import {
  selectCartPriceTotals,
  selectCurrency,
} from '../material-price/material-price.selectors';
import { NaooConstants } from '../../../shared/NaooConstants';
import {
  selectBehavioralAdvertising,
  selectMaterialRecommendations,
} from './ecommerce-analytics.selectors';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { selectCurrentUrl } from '../router/router.selectors';
import { EcommerceAnalyticsMaterialService } from '../../services/ecommerce-analytics/ecommerce-analytics-material.service';
import { MaterialRecord } from '../../services/material-info/models/materials-info-record';
import { CartEntityState } from '../cart/cart.state';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { RouterStateUrl } from '../router/router-state-serializer';
import {
  buildFacebookAddToCartEvents,
  buildFacebookCheckoutPurchaseEvent,
  buildFacebookViewContentEvent,
  FacebookAddToCartEvent,
  FacebookCheckoutPurchaseEvent,
  FacebookEventType,
  FacebookViewContentEvent,
} from '../../../shared/analytics/facebook-tracking/facebook-events';
import { CartMaterialUpdateRequest } from '../../services/cart/models/cart-record';
import { BannerAdsService } from '../../services/banner-ads/banner-ads.service';
import { CombinedPricingRecord } from '../material-price/material-price.util';
import { FacebookConversionsService } from '../../../shared/analytics/facebook-conversions/facebook-conversions.service';
import { selectLanguage, selectSession } from '../session/session.selectors';
import { environment } from '../../../../environments/environment';
import { SharedActions } from '../shared/shared.actions';

@Injectable()
export class EcommerceAnalyticsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private ecommerceAnalyticsService: EcommerceAnalyticsService,
    private materialDataService: EcommerceAnalyticsMaterialService,
    private bannerAdsService: BannerAdsService,
    private analyticsManager: NaooAnalyticsManager,
    private facebookConversionsService: FacebookConversionsService
  ) {}

  ecommerceEvent$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EcommerceAnalyticsActions.ecommerceEvent),
        tap((action) => {
          this.ecommerceAnalyticsService.trackEcommerceEvent(
            action.ecommerceEvent
          );
        })
      );
    },
    { dispatch: false }
  );

  recommendationViewEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.recommendationViewEvent),
      concatLatestFrom(() => [
        this.store.select(selectMaterialRecommendations),
        this.store.select(selectLanguage),
      ]),
      mergeMap(([action, materialRecommendations, language]) => {
        return this.getMaterialData(action.materialNumber, false).pipe(
          map(([materialInfo, _, clientId]) => {
            const materialRecommendation = materialRecommendations.get(
              action.materialNumber
            );
            if (!materialInfo || !materialRecommendation) {
              return SharedActions.noOperation(
                'Missing material info or recommendation info'
              );
            }

            const impressionData = buildEcommerceImpressionData(
              action.materialNumber,
              materialInfo,
              materialRecommendation,
              clientId,
              language
            );

            this.analyticsManager.trackAnalyticsEvent(
              {
                action: 'ec:addImpression',
                ...impressionData,
              },
              [
                { index: 48, value: impressionData.dimension48 },
                { index: 49, value: clientId },
              ]
            );

            return EcommerceAnalyticsActions.ecommerceEvent(
              buildEcommerceRecommendationViewEvent([impressionData])
            );
          })
        );
      })
    );
  });

  recommendationClickEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.recommendationClickEvent),
      concatLatestFrom(() => [
        this.store.select(selectMaterialRecommendations),
        this.store.select(selectLanguage),
      ]),
      mergeMap(([action, materialRecommendations, language]) => {
        return this.getMaterialData(action.materialNumber, false).pipe(
          map(([materialInfo, _, clientId]) => {
            const materialRecommendation = materialRecommendations.get(
              action.materialNumber
            );
            if (!materialInfo || !materialRecommendation) {
              return SharedActions.noOperation(
                'Missing material info or recommendation info'
              );
            }

            const impressionData = buildEcommerceImpressionData(
              action.materialNumber,
              materialInfo,
              materialRecommendation,
              clientId,
              language
            );

            return EcommerceAnalyticsActions.ecommerceEvent(
              buildEcommerceRecommendationClickEvent(impressionData)
            );
          })
        );
      })
    );
  });

  promotionViewEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.promotionViewEvent),
      map((action) => {
        const promotions: EcommercePromotionData[] = action.data.bannerAds?.map(
          (bannerAd) => {
            this.bannerAdsService.sendBannerAdsImpression(
              bannerAd.impressionUrl
            );
            return buildEcommercePromotionData(bannerAd.ctTargetUrl);
          }
        );
        if (!promotions?.length) {
          return SharedActions.noOperation('No banner ads');
        }

        return EcommerceAnalyticsActions.ecommerceEvent(
          buildEcommercePromotionViewEvent(promotions)
        );
      })
    );
  });

  promotionClickEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.promotionClickEvent),
      map((action) => {
        const bannerAd = action.data.bannerAds?.[0];
        if (!bannerAd) {
          return SharedActions.noOperation('No banner ad');
        }
        this.bannerAdsService.sendBannerAdClickEvent(bannerAd.clickUrl);
        return EcommerceAnalyticsActions.ecommerceEvent(
          buildEcommercePromotionClickEvent([
            buildEcommercePromotionData(bannerAd.ctTargetUrl),
          ])
        );
      })
    );
  });

  materialDetailsViewEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.materialDetailsViewEvent),
      mergeMap((action) => {
        return this.getMaterialData(action.materialNumber, true).pipe(
          map(([materialInfo, materialPrice, _]) => {
            if (!materialInfo) {
              return SharedActions.noOperation('Missing material info');
            }

            const detailViewEvent = buildEcommerceDetailViewEvent(
              materialInfo,
              materialPrice
            );
            return EcommerceAnalyticsActions.ecommerceEvent(detailViewEvent);
          })
        );
      })
    );
  });

  updateCartEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.updateCartEvent),
      concatLatestFrom(() => [
        this.store.select(selectCartEntity),
        this.store.select(selectAnalyticsPreviousCartEntity),
        this.store.select(selectMaterialRecommendations),
        this.store.select(selectCurrentUrl),
        this.store.select(selectCurrency),
        this.store.select(selectLanguage),
      ]),
      mergeMap(
        ([
          action,
          cart,
          previousCart,
          materialRecommendations,
          currentUrl,
          currency,
          language,
        ]) => {
          if (!cart || !previousCart) {
            return [SharedActions.noOperation('Missing cart or previous cart')];
          }

          const changedMaterials: CartMaterialUpdateRequest[] =
            action.cartUpdateRequest.materials;
          const materialNumbers = changedMaterials.map(
            (material) => material.materialNumber
          );

          return this.getMaterialsData(materialNumbers).pipe(
            mergeMap(([materialInfos, combinedPrices, clientId]) => {
              const dispatchActions: Action[] = [];
              if (environment.hasFacebookTracking) {
                const addToCartEvents: FacebookAddToCartEvent[] = buildFacebookAddToCartEvents(
                  changedMaterials,
                  cart,
                  previousCart,
                  materialInfos,
                  combinedPrices,
                  language,
                  currency
                );

                addToCartEvents.forEach((event) =>
                  dispatchActions.push(
                    EcommerceAnalyticsActions.sendFacebookConversions(
                      FacebookEventType.AddToCart,
                      event
                    )
                  )
                );
              }

              let list = currentUrl;
              const isHomePage = currentUrl === NaooConstants.HOME_PAGE_PATH;
              if (isHomePage) {
                for (const changedMaterial of changedMaterials) {
                  const recommendation = materialRecommendations.get(
                    changedMaterial.materialNumber
                  );
                  if (recommendation) {
                    list = recommendation.name[language];
                    break;
                  }
                }
              }

              const ecommerceEvents = buildEcommerceCartChangeEvents(
                changedMaterials,
                cart,
                previousCart,
                materialInfos,
                combinedPrices,
                isHomePage ? materialRecommendations : new Map(),
                clientId,
                list,
                currency,
                language
              );
              ecommerceEvents.forEach((event) =>
                dispatchActions.push(
                  EcommerceAnalyticsActions.ecommerceEvent(event)
                )
              );
              return dispatchActions;
            })
          );
        }
      )
    );
  });

  checkoutEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.checkoutEvent),
      mergeMap(() => this.getLoadedCart()),
      mergeMap((cart) => {
        const materialNumbers = cart.materials.ids as string[];

        return this.getMaterialsData(materialNumbers).pipe(
          concatLatestFrom(() => [
            this.store.select(selectCartPriceTotals(true)),
            this.store.select(selectCurrency),
            this.store.select(selectLanguage),
          ]),
          mergeMap(
            ([
              [materialInfos, materialPrices, clientId],
              cartPriceTotals,
              currency,
              language,
            ]) => {
              const dispatchedActions: Action[] = [];
              if (environment.hasFacebookTracking) {
                const checkoutEvent: FacebookCheckoutPurchaseEvent = buildFacebookCheckoutPurchaseEvent(
                  cart,
                  cartPriceTotals.estimatedCost,
                  currency
                );
                dispatchedActions.push(
                  EcommerceAnalyticsActions.sendFacebookConversions(
                    FacebookEventType.Checkout,
                    checkoutEvent
                  )
                );
              }

              const productsData = buildEcommerceProductsDataFromCart(
                cart,
                materialInfos,
                materialPrices,
                clientId,
                language
              );

              dispatchedActions.push(
                EcommerceAnalyticsActions.ecommerceEvent(
                  buildEcommerceCheckoutEvent(productsData)
                )
              );
              return dispatchedActions;
            }
          )
        );
      })
    );
  });

  purchaseEvent$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.purchaseEvent),
      concatLatestFrom(() => [
        this.store.select(selectCartEntity),
        this.store.select(selectCurrency),
      ]),
      mergeMap(([_, cart, currency]) => {
        if (!cart) {
          return [SharedActions.noOperation('Missing cart')];
        }

        const materialNumbers = cart.materials.ids as string[];

        return this.getMaterialsData(materialNumbers).pipe(
          concatLatestFrom(() => [
            this.store.select(selectCartPriceTotals(true)),
            this.store.select(selectLanguage),
          ]),
          mergeMap(
            ([
              [materialInfos, materialPrices, clientId],
              cartPriceTotals,
              language,
            ]) => {
              const dispatchedActions: Action[] = [];
              if (environment.hasFacebookTracking) {
                const purchaseEvent: FacebookCheckoutPurchaseEvent = buildFacebookCheckoutPurchaseEvent(
                  cart,
                  cartPriceTotals.estimatedCost,
                  currency
                );
                dispatchedActions.push(
                  EcommerceAnalyticsActions.sendFacebookConversions(
                    FacebookEventType.Purchase,
                    purchaseEvent
                  )
                );
              }

              const productsData = buildEcommerceProductsDataFromCart(
                cart,
                materialInfos,
                materialPrices,
                clientId,
                language
              );

              dispatchedActions.push(
                EcommerceAnalyticsActions.ecommerceEvent(
                  buildEcommercePurchaseEvent(
                    cart.id,
                    cartPriceTotals.estimatedCost,
                    productsData
                  )
                )
              );
              return dispatchedActions;
            }
          )
        );
      })
    );
  });

  clearEcommerceData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RouterNavigatedAction<RouterStateUrl>>(ROUTER_NAVIGATED),
      map((_) =>
        EcommerceAnalyticsActions.ecommerceEvent(clearEcommerceEvent())
      )
    );
  });

  sendFacebookConversions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EcommerceAnalyticsActions.sendFacebookConversions),
      filter(() => environment.hasFacebookTracking),
      concatLatestFrom(() => [
        this.store.select(selectSession),
        this.store.select(selectBehavioralAdvertising),
      ]),
      mergeMap(([action, session, behavioralAdvertising]) => {
        if (!behavioralAdvertising) {
          return Promise.resolve(of());
        }
        return this.facebookConversionsService.sendFacebookConversions(
          session,
          action.eventType,
          action.event
        );
      }),
      mergeMap((data) =>
        data.pipe(
          map((response) =>
            EcommerceAnalyticsActions.sendFacebookConversionsSuccess(response)
          ),
          catchError((error) =>
            of(EcommerceAnalyticsActions.sendFacebookConversionsFailure(error))
          )
        )
      )
    );
  });

  facebookContentViewEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RouterNavigatedAction<RouterStateUrl>>(ROUTER_NAVIGATED),
      filter(() => environment.hasFacebookTracking),
      map((action) => {
        const viewContentEvent: FacebookViewContentEvent = buildFacebookViewContentEvent(
          action.payload.routerState.url
        );
        return EcommerceAnalyticsActions.sendFacebookConversions(
          FacebookEventType.ViewContent,
          viewContentEvent
        );
      })
    );
  });

  private getLoadedCart(): Observable<CartEntityState> {
    return this.store.select(selectCartEntity).pipe(
      filter((cart) => !!cart),
      first()
    );
  }

  private getMaterialData(
    materialNumber: string,
    includePricing: boolean
  ): Observable<[MaterialRecord, CombinedPricingRecord, string]> {
    const materialInfo$ = this.materialDataService.getLoadedMaterialRecord(
      materialNumber
    );
    const materialPrice$ = includePricing
      ? this.materialDataService.getLoadedCombinedPricingRecord(materialNumber)
      : of(undefined);
    const clientId$ = this.ecommerceAnalyticsService.getClientId();
    return forkJoin([materialInfo$, materialPrice$, clientId$]);
  }

  private getMaterialsData(
    materialNumbers: string[]
  ): Observable<
    [Map<string, MaterialRecord>, Map<string, CombinedPricingRecord>, string]
  > {
    const materialInfo$ = this.materialDataService.getLoadedMaterialRecords(
      materialNumbers
    );
    const combinedPrice$ = this.materialDataService.getLoadedCombinedPricingRecords(
      materialNumbers
    );
    const clientId$ = this.ecommerceAnalyticsService.getClientId();
    return forkJoin([materialInfo$, combinedPrice$, clientId$]);
  }
}
