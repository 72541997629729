import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SpecialOrder,
  SpecialOrdersState,
  SpecialOrdersStatus,
} from './special-orders.state';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { Dictionary } from '@ngrx/entity';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { SpecialOrderRowViewModel } from '../../../orders/orders/special-order/special-order-row-view-model';
import {
  selectActiveCustomer,
  selectSession,
} from '../session/session.selectors';
import { hasMaterialInfoFinishedLoading } from '../material-info/material-info.util';
import {
  CustomerBrand,
  SessionActiveCustomer,
  SessionRecord,
} from '../../services/session/models/session-record';

export interface SpecialOrderInfo {
  status: SpecialOrdersStatus;
  timeZone: string;
  customerBrand: CustomerBrand;
  rowViewModels: SpecialOrderRowViewModel[];
}

const selectSpecialOrdersState = createFeatureSelector<SpecialOrdersState>(
  'specialOrders'
);

export const selectSpecialOrders = createSelector(
  selectSpecialOrdersState,
  (state: SpecialOrdersState): SpecialOrder[] => state.records
);

export const selectHasOpenSpecialOrders = (materialNumber: string) =>
  createSelector(
    selectSpecialOrders,
    (specialOrders: SpecialOrder[]): boolean =>
      hasOpenSpecialOrders(materialNumber, specialOrders)
  );

export const selectSpecialOrderRows = createSelector(
  selectSpecialOrdersState,
  selectActiveCustomer,
  selectSession,
  selectAllMaterialInfoRecordStates,
  (
    state: SpecialOrdersState,
    customer: SessionActiveCustomer,
    session: SessionRecord,
    materialInfos: Dictionary<MaterialInfoRecordState>
  ): SpecialOrderInfo => {
    if (
      !state ||
      !state.records ||
      !customer ||
      !haveAllMaterialInfosLoaded(state, materialInfos)
    ) {
      return undefined;
    }

    return {
      status: state.status,
      timeZone: customer.timeZone,
      customerBrand: session.activeCustomer.brand,
      rowViewModels: state.records.map((specialOrder) => {
        return new SpecialOrderRowViewModel(
          specialOrder,
          materialInfos[specialOrder.materialNumber].record,
          session.activeCustomer.currentSystem
        );
      }),
    };
  }
);

function haveAllMaterialInfosLoaded(
  state: SpecialOrdersState,
  materialInfos: Dictionary<MaterialInfoRecordState>
) {
  return state.records.every((specialOrder) =>
    hasMaterialInfoFinishedLoading(materialInfos[specialOrder.materialNumber])
  );
}

export function hasOpenSpecialOrders(
  materialNumber: string,
  specialOrders: SpecialOrder[]
): boolean {
  return (
    !!specialOrders &&
    specialOrders
      .filter((specialOrder) => specialOrder.materialNumber === materialNumber)
      .some((specialOrder) => specialOrder.statusCode !== 'DELIVERED')
  );
}
