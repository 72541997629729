import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalActions } from './modal.actions';
import { MessageData } from '../../../shared/modals/generic-modal/generic-modal.component';

@Injectable({ providedIn: 'root' })
export class ModalFacade {
  constructor(private store: Store) {}

  promptOneButtonModal(
    id: string,
    messageData: string | MessageData,
    primaryButtonText: string,
    primaryButtonAction: () => void,
    isClosable: boolean
  ) {
    this.store.dispatch(
      ModalActions.oneButtonModal(
        id,
        messageData,
        primaryButtonText,
        primaryButtonAction,
        isClosable
      )
    );
  }

  promptTwoButtonModal(
    id: string,
    messageData: string | MessageData,
    primaryButtonText: string,
    secondaryButtonText: string,
    primaryButtonAction: () => void,
    secondaryButtonAction: () => void
  ) {
    this.store.dispatch(
      ModalActions.twoButtonModal(
        id,
        messageData,
        primaryButtonText,
        secondaryButtonText,
        primaryButtonAction,
        secondaryButtonAction
      )
    );
  }
}
