import {
  deliveryScheduleAdapter,
  DeliveryScheduleState,
  initialDeliveryScheduleState,
} from './delivery-schedule.state';
import { DeliveryScheduleActions } from './delivery-schedule.actions';
import { createReducer, on } from '@ngrx/store';

export const deliveryScheduleReducer = createReducer(
  initialDeliveryScheduleState,
  on(
    DeliveryScheduleActions.getDeliverySchedules,
    (state): DeliveryScheduleState => state
  ),
  on(
    DeliveryScheduleActions.getDeliverySchedulesSuccess,
    (state, action): DeliveryScheduleState => ({
      ...state,
      deliverySchedules: deliveryScheduleAdapter.setAll(
        action.payload,
        state.deliverySchedules
      ),
      hasLoaded: true,
      hasErrored: false,
    })
  ),
  on(
    DeliveryScheduleActions.getDeliverySchedulesFailure,
    (state): DeliveryScheduleState => ({
      ...state,
      hasLoaded: true,
      hasErrored: true,
    })
  ),
  on(
    DeliveryScheduleActions.refreshDeliverySchedules,
    (): DeliveryScheduleState => initialDeliveryScheduleState
  )
);
