import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MaterialRecommendationsRecordState,
  MaterialRecommendationsRequestStatus,
  MaterialRecommendationsState,
} from './material-recommendations.state';
import { MaterialRecommendations } from '../../../shared/services/material-recommendations/models/material-recommendations';
import { selectAllMaterialAvailabilityRecords } from '../material-availability/material-availability.selectors';
import { selectAllLastOrderedRecords } from '../last-ordered/last-ordered.selectors';
import { Dictionary } from '@ngrx/entity';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import { LastOrderedRecordState } from '../last-ordered/last-ordered.state';
import {
  hasLastOrderedFinishedLoading,
  lastOrderedComparator,
} from '../last-ordered/last-ordered.util';

export const selectMaterialRecommendationsState = createFeatureSelector<MaterialRecommendationsState>(
  'materialRecommendations'
);

const selectMaterialRecommendationsRecordState = (page: string) =>
  createSelector(
    selectMaterialRecommendationsState,
    (
      materialRecommendationsState: MaterialRecommendationsState
    ): MaterialRecommendationsRecordState | undefined =>
      materialRecommendationsState?.entities[page]
  );

export const selectMaterialRecommendationsRecordStateIsLoading = (
  page: string
) =>
  createSelector(
    selectMaterialRecommendationsRecordState(page),
    (materialRecommendationsRecordState): boolean =>
      [
        MaterialRecommendationsRequestStatus.Queued,
        MaterialRecommendationsRequestStatus.Requested,
      ].includes(materialRecommendationsRecordState?.status)
  );

export const selectMaterialRecommendations = (
  page: string,
  sortByLastOrdered?: boolean
) =>
  createSelector(
    selectMaterialRecommendationsRecordState(page),
    selectAllMaterialAvailabilityRecords,
    selectAllLastOrderedRecords,
    (
      materialRecommendationsRecordState,
      availabilityRecords,
      lastOrderedRecords
    ): MaterialRecommendations[] => {
      const orderableRecommendations = filteredOrderableMaterials(
        materialRecommendationsRecordState?.materialRecommendations,
        availabilityRecords
      );
      if (!sortByLastOrdered) {
        return orderableRecommendations;
      }

      return sortMaterialRecommendationsByLastOrdered(
        orderableRecommendations,
        lastOrderedRecords
      );
    }
  );

function filteredOrderableMaterials(
  recommendations: MaterialRecommendations[],
  availabilityRecords: Dictionary<MaterialAvailabilityRecordState>
): MaterialRecommendations[] {
  if (!recommendations) {
    return [];
  }
  const orderableRecommendations: MaterialRecommendations[] = [];
  recommendations.forEach((materialRecommendations) => {
    orderableRecommendations.push({
      ...materialRecommendations,
      materialListRows: materialRecommendations.materialListRows.filter(
        (materialListRow) => {
          const materialNumber = materialListRow.value as string;
          return availabilityRecords[materialNumber]?.record?.isOrderable;
        }
      ),
    });
  });

  return orderableRecommendations;
}

function sortMaterialRecommendationsByLastOrdered(
  recommendations: MaterialRecommendations[],
  lastOrderedRecords: Dictionary<LastOrderedRecordState>
): MaterialRecommendations[] {
  const materialRecommendations = [...recommendations];
  materialRecommendations.forEach((recommendations) => {
    const materialNumbers = recommendations.materialListRows.reduce(
      (acc, curr) => acc.concat(curr.value as string),
      []
    );
    const filteredLastOrderRecordStates = materialNumbers.map(
      (relatedMaterialNumber) => lastOrderedRecords[relatedMaterialNumber]
    );
    const hasLoaded = filteredLastOrderRecordStates.every((recordState) =>
      hasLastOrderedFinishedLoading(recordState)
    );

    if (hasLoaded) {
      filteredLastOrderRecordStates.sort(lastOrderedComparator);

      const materialSortOrder = filteredLastOrderRecordStates.map(
        (record) => record.materialNumber
      );

      const itemPositions: Dictionary<number> = {};
      for (const [index, materialNumber] of materialSortOrder.entries()) {
        itemPositions[materialNumber] = index;
      }

      recommendations.materialListRows.sort(
        (a, b) =>
          itemPositions[a.value as string] - itemPositions[b.value as string]
      );
    }
  });

  return materialRecommendations;
}
