import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SessionFacade } from '../../../core/store/session/session.facade';
import { DeliveryDatePickerService } from '../../delivery-date-picker/shared/delivery-date-picker.service';
import { SelectOrderMethodService } from '../select-order-method/select-order-method.service';

@Injectable({
  providedIn: 'root',
})
export class FulfillmentModalService {
  private hasIspuOrExpressPermissions: boolean;
  destroyed$ = new Subject();

  constructor(
    private sessionFacade: SessionFacade,
    private selectOrderMethodService: SelectOrderMethodService,
    private deliveryDatePickerService: DeliveryDatePickerService
  ) {
    this.sessionFacade
      .hasIspuOrExpressPermissions()
      .subscribe(
        (hasIspuOrExpressPermissions) =>
          (this.hasIspuOrExpressPermissions = hasIspuOrExpressPermissions)
      );
  }

  openFulfillmentModal(
    shouldRefreshDeliverySchedules: boolean = false,
    isFirstDisplay: boolean = false,
    forceOpen = false,
    shouldDisplay = false,
    isImportOrder: boolean = false,
    isPastCutoff: boolean = false,
    closeAction?: () => void
  ): void {
    if (this.hasIspuOrExpressPermissions && !shouldDisplay) {
      this.selectOrderMethodService.openSelectOrderMethodDialog(
        null,
        isImportOrder,
        closeAction,
        isPastCutoff
      );
    } else if (!this.hasIspuOrExpressPermissions) {
      if (forceOpen) {
        this.deliveryDatePickerService.showDeliveryDatePicker(
          shouldRefreshDeliverySchedules,
          isImportOrder,
          isPastCutoff
        );
      } else {
        this.deliveryDatePickerService.showDeliveryDatePickerIfNeeded(
          isFirstDisplay,
          isImportOrder,
          isPastCutoff,
          shouldRefreshDeliverySchedules,
          closeAction
        );
      }
    }
  }
}
