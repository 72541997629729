import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { MaterialAdditionalInfoActions } from './material-additional-info.actions';
import {
  selectComparisonAttributesViewModel,
  selectMaterialAdditionalInfoRecordState,
  selectMaterialAdditionalInfoRecordStates,
} from './material-additional-info.selectors';
import {
  MaterialAdditionalInfoRecordState,
  MaterialAdditionalInfoRecordStatus,
} from './material-additional-info.state';
import { MaterialAdditionalInfo } from 'src/app/shared/models/material-additional-info';
import { ComparisonAttributesViewModel } from '../../../material-comparison/material-column/shared/models/comparison-attributes';
import { buildMaterialAdditionalInfo } from './material-additional-info.util';

@Injectable({ providedIn: 'root' })
export class MaterialAdditionalInfoFacade {
  constructor(private store: Store) {}

  loadMaterialAdditionalInfos(materialNumbers: string[]) {
    this.store.dispatch(
      MaterialAdditionalInfoActions.loadMaterialAdditionalInfo(materialNumbers)
    );
  }

  getLoadedMaterialAdditionalInfo(
    materialNumber: string
  ): Observable<MaterialAdditionalInfo> {
    return this.store
      .select(selectMaterialAdditionalInfoRecordState(materialNumber))
      .pipe(
        filter((additionalInfoRecordState) =>
          this.hasFinishedLoading(additionalInfoRecordState)
        ),
        map((additionalInfoRecordState) =>
          buildMaterialAdditionalInfo(additionalInfoRecordState.record)
        )
      );
  }

  getLoadedMaterialAdditionalInfos(
    materialNumbers: string[]
  ): Observable<MaterialAdditionalInfo[]> {
    return this.store
      .select(selectMaterialAdditionalInfoRecordStates(materialNumbers))
      .pipe(
        filter((additionalInfoRecordStates) =>
          additionalInfoRecordStates.every((recordState) =>
            this.hasFinishedLoading(recordState)
          )
        ),
        map((additionalInfoRecordStates) =>
          additionalInfoRecordStates.map((recordState) =>
            buildMaterialAdditionalInfo(recordState.record)
          )
        )
      );
  }

  getLoadedMaterialAdditionalInfoMap(
    materialNumbers: string[]
  ): Observable<Map<string, MaterialAdditionalInfo>> {
    return this.getLoadedMaterialAdditionalInfos(materialNumbers).pipe(
      map(
        (materialAdditionalInfo) =>
          new Map(
            materialAdditionalInfo
              .filter((i) => !!i)
              .map((i) => [i.materialNumber, i])
          )
      )
    );
  }

  clearMaterialAdditionalInfo() {
    this.store.dispatch(
      MaterialAdditionalInfoActions.clearMaterialAdditionalInfo()
    );
  }

  getComparisonAttributesViewModel(
    materialNumbers: string[]
  ): Observable<ComparisonAttributesViewModel> {
    return this.store.select(
      selectComparisonAttributesViewModel(materialNumbers)
    );
  }

  private hasFinishedLoading(
    recordState: MaterialAdditionalInfoRecordState
  ): boolean {
    return (
      !!recordState &&
      [
        MaterialAdditionalInfoRecordStatus.Error,
        MaterialAdditionalInfoRecordStatus.Success,
      ].includes(recordState.status)
    );
  }
}
