import { CartReviewActions } from './cart-review.actions';
import {
  CartReviewSection,
  cartReviewSectionAdapter,
  CartReviewSectionName,
  CartReviewState,
  CreditWidgetLoadingState,
  initialCartReviewState,
} from './cart-review.state';
import { createReducer, on } from '@ngrx/store';

export const cartReviewReducer = createReducer(
  initialCartReviewState,
  on(
    CartReviewActions.buildSystemError,
    (state, { failedDropShipMaterials }): CartReviewState => ({
      ...state,
      isLoading: false,
      failedDropShipSplitMaterials: failedDropShipMaterials,
    })
  ),
  on(CartReviewActions.buildCartReviewSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    emailOptIn: action.data?.emailOptIn,
    groups: cartReviewSectionAdapter.setAll(action.data?.groups, state.groups),
    failedDropShipSplitMaterials: action.data?.failedDropShipSplitMaterials,
    storePaymentOptions: action.data?.storeOrderPaymentDetails,
  })),
  on(CartReviewActions.refreshDropShipMetadataSuccess, (state, action) => ({
    ...state,
    groups: cartReviewSectionAdapter.upsertOne(
      action.dropShipSectionGroup,
      state.groups
    ),
  })),
  on(
    CartReviewActions.loadCartReview,
    (state): CartReviewState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    CartReviewActions.saveSelectedCreditCardOption,
    (state, { creditCardOptionSelected }): CartReviewState => ({
      ...state,
      selectedCreditCard: creditCardOptionSelected,
    })
  ),
  on(
    CartReviewActions.clearCartReview,
    CartReviewActions.getCartReviewMetadataFailure,
    (): CartReviewState => initialCartReviewState
  ),
  on(
    CartReviewActions.removeFailedFulfillmentMaterialsSuccess,
    (state): CartReviewState => handleRemovalOfFulfillmentMaterials(state)
  ),
  on(CartReviewActions.updateCartReviewSection, (state, action) =>
    updateCartReviewSection(state, action)
  ),
  on(CartReviewActions.updateCreditWidgetLoadingState, (state, action) =>
    updateCreditWidgetLoadingState(state, action)
  ),
  on(
    CartReviewActions.updateCartReviewAciHasResolved,
    (state, { hasResolved }): CartReviewState => ({
      ...state,
      aciExecutePaymentResolved: hasResolved,
    })
  ),
  on(
    CartReviewActions.removeFailedFulfillmentMaterials,
    CartReviewActions.getDropShipMetadataFailure,
    CartReviewActions.refreshDropShipMetadata,
    CartReviewActions.refreshCartReview,
    CartReviewActions.buildCartReview,
    (state): CartReviewState => state
  )
);

function updateCartReviewSection(
  state: CartReviewState,
  action: {
    name: CartReviewSectionName;
    index: number;
    changes: Partial<CartReviewSection>;
    isSplit: boolean;
  }
): CartReviewState {
  const singlePickupMapper = (value: CartReviewSection) => {
    return { ...value, ...action.changes };
  };
  const splitPickupMapper = (value: CartReviewSection, index: number) =>
    index === action.index ? { ...value, ...action.changes } : value;

  return {
    ...state,
    groups: cartReviewSectionAdapter.updateOne(
      {
        id: action.name,
        changes: {
          sections: state.groups.entities[action.name].sections.map(
            action.index === undefined ? singlePickupMapper : splitPickupMapper
          ),
        },
      },
      state.groups
    ),
  };
}

function updateCreditWidgetLoadingState(
  state: CartReviewState,
  action: {
    loadingState: CreditWidgetLoadingState;
  }
): CartReviewState {
  return {
    ...state,
    creditWidgetLoadingState: action.loadingState,
  };
}

function handleRemovalOfFulfillmentMaterials(
  state: CartReviewState
): CartReviewState {
  let systemErrorGroup =
    state.groups.entities[CartReviewSectionName.FulfillmentSystemErrorSection];
  const fulfillmentSystemErrorSection = systemErrorGroup.sections.find(
    (section) =>
      section.name === CartReviewSectionName.FulfillmentSystemErrorSection
  );
  const sectionIndex = systemErrorGroup.sections.indexOf(
    fulfillmentSystemErrorSection
  );
  const sections = [...systemErrorGroup.sections];

  sections[sectionIndex] = {
    ...fulfillmentSystemErrorSection,
    subHeaderKeys: [
      {
        key:
          'CART_REVIEW.FULFILLMENT_SYSTEM_ERROR_SECTION.BANNER.SUB_HEADER_ITEMS_REMOVED',
      },
    ],
  };

  systemErrorGroup = {
    ...systemErrorGroup,
    sections,
  };
  return {
    ...state,
    failedDropShipSplitMaterials: [],
    groups: cartReviewSectionAdapter.upsertOne(systemErrorGroup, state.groups),
  };
}
