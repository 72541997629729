import { NaooStringDefaulter } from './naoo-string-defaulter';
import {
  ChangeDetectorRef,
  Injectable,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Localized } from '../models/localized';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocalizationService } from '../services/translation/localization.service';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'stringdefaulter',
  pure: false,
})
export class NaooStringDefaulterPipe implements PipeTransform, OnDestroy {
  private stringDefaulter: NaooStringDefaulter;
  private destroyed$ = new Subject<void>();

  constructor(
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef
  ) {
    this.stringDefaulter = new NaooStringDefaulter();
    this.localizationService
      .language()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(
    value: Localized<string>,
    currentLanguageOnly: boolean = false
  ): string | null {
    if (currentLanguageOnly) {
      return this.stringDefaulter.getCurrentLanguageOnlyString(
        value,
        this.localizationService.currentLanguage
      );
    } else {
      return this.stringDefaulter.getString(
        value,
        this.localizationService.currentLanguage
      );
    }
  }
}
