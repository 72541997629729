import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { DeliveryScheduleEntryRecord } from '../../services/delivery-schedule/models/delivery-schedules-record';

export interface DeliveryScheduleState {
  deliverySchedules: EntityState<DeliveryScheduleEntryRecord>;
  hasLoaded: boolean;
  hasErrored: boolean;
}

export const deliveryScheduleAdapter = createEntityAdapter<DeliveryScheduleEntryRecord>(
  {
    selectId: (deliveryScheduleEntryRecord) =>
      deliveryScheduleEntryRecord.routeDate,
  }
);

export const initialDeliveryScheduleState: DeliveryScheduleState = {
  deliverySchedules: deliveryScheduleAdapter.getInitialState(),
  hasLoaded: false,
  hasErrored: false,
};
