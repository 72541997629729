import { Injectable } from '@angular/core';
import {
  SearchResultResponse,
  SponsoredRecommendations,
} from './search.service';
import {
  DefaultFilterGroupRecord,
  OrderGuideFilter,
  SearchResultRecord,
  TaxonomyFilterGroupRecord,
  TaxonomyFilterRecord,
  ToggleFieldFilterRecord,
} from './models/search-record';
import { Filter } from 'src/app/shared/models/search/filter';
import { FilterCategory } from '../../../shared/models/search/filter-category';
import {
  FilterRecordState,
  SponsoredRecommendationsState,
} from '../../store/search/search.state';
import { RouterStateUrl } from '../../store/router/router-state-serializer';
import { SearchConstants } from 'src/app/shared/models/search/SearchConstants';
import { NaooConstants } from 'src/app/shared/NaooConstants';
import { Localized } from 'src/app/shared/models/localized';
import { CatalogResponse } from '../../../shared/models/catalog/catalog-response';

interface SortedFilters {
  defaultFilters: DefaultFilterGroupRecord[];
  taxonomyFilter: TaxonomyFilterGroupRecord;
  orderGuideFilter: OrderGuideFilter;
  availableTodayFilter: ToggleFieldFilterRecord;
}

enum FilterType {
  Default = 'defaultFilter',
  Taxonomy = 'taxonomyFilter',
  OrderGuide = 'orderGuideFilter',
  AvailableToday = 'storeFilter',
}

@Injectable({ providedIn: 'root' })
export class SearchTransformationService {
  transformSearchResultResponse(
    routerState: RouterStateUrl,
    searchResponse: SearchResultResponse
  ): SearchResultRecord {
    const sortedFilters = this.sortFilters(searchResponse.filterList);
    const shouldHideSearchText = this.shouldHideSearchText(routerState);
    return {
      defaultFilters: sortedFilters.defaultFilters,
      taxonomyFilters: sortedFilters.taxonomyFilter,
      orderGuideFilter: sortedFilters.orderGuideFilter,
      availableTodayFilter: sortedFilters.availableTodayFilter,
      shouldHideSearchText: shouldHideSearchText,
      resultSet: {
        materialNumbers: searchResponse.materialNumbers,
        totalResults: searchResponse.totalResults,
        suggestedText: searchResponse.suggestedSearchText,
        breadCrumbTree: searchResponse.categoryTree,
        sponsoredRecommendations: this.transformRecommendationsUrls(
          searchResponse.recommendations
        ),
      },
      searchText: this.parseSearchText(routerState, searchResponse),
    };
  }

  private transformRecommendationsUrls(
    recommendations: SponsoredRecommendations
  ): SponsoredRecommendationsState {
    if (!recommendations) {
      return undefined;
    }

    const transformedResponse = {
      ...recommendations,
      productRecommendations: new Map(
        recommendations?.productRecommendations.map((b) => [b.materialId, b])
      ),
    };

    transformedResponse.banners?.forEach(
      (banner) =>
        (banner.imageVersions = banner.imageVersions?.map((image) => {
          return {
            ...image,
            imagePath: `/creative/${image.imagePath}`,
          };
        }))
    );

    return transformedResponse;
  }

  private sortFilters(filterList: Filter[]): SortedFilters {
    const sortedFilters: SortedFilters = {
      defaultFilters: [],
      taxonomyFilter: undefined,
      orderGuideFilter: undefined,
      availableTodayFilter: undefined,
    };

    filterList?.forEach((filter) => {
      switch (filter.type) {
        case FilterType.Default:
          sortedFilters.defaultFilters = sortedFilters.defaultFilters.concat([
            this.transformDefaultFilters(filter),
          ]);
          break;
        case FilterType.Taxonomy:
          sortedFilters.taxonomyFilter = this.buildTaxonomyFilters(filter);
          break;
        case FilterType.OrderGuide:
          sortedFilters.orderGuideFilter = this.buildOrderGuideFilter(filter);
          break;
        case FilterType.AvailableToday:
          sortedFilters.availableTodayFilter = this.getToggleFieldFilter(
            filter
          );
          break;
        default:
          break;
      }
    });

    return sortedFilters;
  }

  private shouldHideSearchText(routerState: RouterStateUrl): boolean {
    const hideSearchText =
      routerState.queryParams[SearchConstants.paramHideSearchText];

    return !!hideSearchText;
  }

  private parseSearchText(
    routerState: RouterStateUrl,
    searchResponse: SearchResultResponse
  ): Localized<string> {
    const searchText = routerState.queryParams[SearchConstants.paramSearchText];
    if (routerState.url.includes(NaooConstants.SEARCH_URL) && !!searchText) {
      return {
        en: searchText,
        fr: searchText,
      };
    }

    return this.traverseCategoryTree(searchResponse.categoryTree);
  }

  private traverseCategoryTree(
    catalogTree: CatalogResponse
  ): Localized<string> {
    if (!catalogTree) {
      return {
        en: '',
        fr: '',
      };
    }

    if (!catalogTree.subCategories || catalogTree.subCategories.length === 0) {
      return catalogTree.name;
    }

    return this.traverseCategoryTree(catalogTree.subCategories[0]);
  }

  private transformDefaultFilters(filter: Filter): DefaultFilterGroupRecord {
    return {
      name: filter.name,
      viewMoreThreshold: filter.collapseThreshold,
      queryParameterName: filter.param,
      filters: filter.options.map(
        (option): FilterRecordState => {
          return {
            count: option.count,
            name: option.name,
            isSelected: option.selected,
            queryParameterValue: option.value,
          };
        }
      ),
    };
  }

  private buildOrderGuideFilter(filter: Filter): OrderGuideFilter {
    return (filter as unknown) as OrderGuideFilter;
  }

  private buildTaxonomyFilters(filter: Filter): TaxonomyFilterGroupRecord {
    return {
      queryParameterValue: filter.param,
      name: filter.name,
      subCategories: this.buildTaxonomyFiltersCategories(filter.categories),
    };
  }

  private buildTaxonomyFiltersCategories(
    categories: FilterCategory[]
  ): TaxonomyFilterRecord[] {
    if (!categories || categories.length === 0) {
      return [];
    } else {
      return categories.map(
        (category): TaxonomyFilterRecord => {
          return {
            name: category.name,
            queryParameterValue: category.id,
            count: category.count,
            isSelected: category.selected,
            subCategories: this.buildTaxonomyFiltersCategories(
              category.categories
            ),
          };
        }
      );
    }
  }

  private getToggleFieldFilter(filter: Filter): ToggleFieldFilterRecord {
    const toggleField = filter.options[filter.options.length - 1];
    return {
      isEnabled: toggleField.selected,
      count: toggleField.count,
    };
  }
}
