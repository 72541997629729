<div
  class="description"
  [ngClass]="{
    mobile: isMobile,
    gridView: 'Grid' === materialRowOptions.materialListStyle,
    listView: 'List' === materialRowOptions.materialListStyle
  }"
>
  {{ unorderableText | translate }}
</div>
