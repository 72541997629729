import { Localized } from '../../../shared/models/localized';
import { MaterialInfo } from '../../../shared/models/material-info';
import { SpecialOrder } from '../../../core/store/special-orders/special-orders.state';
import { CurrentSystem } from '../../../core/services/session/models/session-record';
import { StatusSeverity } from '../../../shared/models/status-severity';

export enum SpecialOrderStatus {
  Requested = 'ORDERS.SPECIAL.ORDER_STATUS.REQUESTED',
  WaitingForPO = 'ORDERS.SPECIAL.ORDER_STATUS.WAITING_FOR_PO',
  OnOrder = 'ORDERS.SPECIAL.ORDER_STATUS.ON_ORDER',
  ReadyToShip = 'ORDERS.SPECIAL.ORDER_STATUS.READY_TO_SHIP',
  Delivered = 'ORDERS.SPECIAL.ORDER_STATUS.DELIVERED',
  ReceivedAtWarehouse = 'ORDERS.SPECIAL.ORDER_STATUS.RECEIVED_AT_WAREHOUSE',
  EnRoute = 'ORDERS.SPECIAL.ORDER_STATUS.EN_ROUTE',
  Other = '',
}

export class SeverityIconInfo {
  severityIcon: string;
  severityIconColor: string;
}

export class SpecialOrderRowViewModel {
  readonly materialNumber: string;
  readonly materialInfo: MaterialInfo;
  readonly hasError: boolean;
  readonly statusBars: { status: SpecialOrderStatus; highlighted: boolean }[];
  readonly statusDescription: Localized<string>;
  readonly statusSecondaryDescription: Localized<string>;
  readonly deliveryDate: string;
  readonly requestedQuantity: string;
  readonly displayedSpecialOrderStatusTypes: SpecialOrderStatus[];
  readonly poNumber: string;
  readonly severityCode?: StatusSeverity;
  readonly severityIconInfo?: SeverityIconInfo;

  constructor(
    specialOrder: SpecialOrder,
    materialInfo: MaterialInfo,
    currentSystem?: CurrentSystem
  ) {
    this.materialNumber = specialOrder.materialNumber;
    this.materialInfo = materialInfo;
    this.deliveryDate = specialOrder.plantArrivalDateTime;
    this.displayedSpecialOrderStatusTypes = SpecialOrderRowViewModel.getDisplayedSpecialOrderStatusTypes(
      currentSystem
    );
    this.hasError = !this.shouldShowStatusBar(specialOrder);
    this.statusBars = this.generateStatusBars(specialOrder);
    this.statusDescription = specialOrder.statusDescription;
    this.statusSecondaryDescription = specialOrder.statusSecondaryDescription;
    this.requestedQuantity = specialOrder.requestedQuantity;
    this.poNumber = specialOrder.poNumber;
    this.severityCode = specialOrder.severityCode;
    this.severityIconInfo = this.severityCodeIconInfo(
      specialOrder.severityCode
    );
  }

  private static getDisplayedSpecialOrderStatusTypes(
    currentSystem?: CurrentSystem
  ): SpecialOrderStatus[] {
    if (!!currentSystem && currentSystem === CurrentSystem.Sap) {
      return [
        SpecialOrderStatus.OnOrder,
        SpecialOrderStatus.ReceivedAtWarehouse,
        SpecialOrderStatus.EnRoute,
        SpecialOrderStatus.Delivered,
      ];
    }
    return [
      SpecialOrderStatus.Requested,
      SpecialOrderStatus.WaitingForPO,
      SpecialOrderStatus.OnOrder,
      SpecialOrderStatus.ReceivedAtWarehouse,
      SpecialOrderStatus.ReadyToShip,
      SpecialOrderStatus.Delivered,
    ];
  }

  private static mapStringToSpecialOrderStatus(
    orderStatus: string
  ): SpecialOrderStatus {
    switch (orderStatus) {
      case 'REQUESTED':
        return SpecialOrderStatus.Requested;
      case 'WAITING_FOR_PO':
        return SpecialOrderStatus.WaitingForPO;
      case 'ON_ORDER':
        return SpecialOrderStatus.OnOrder;
      case 'READY_TO_SHIP':
        return SpecialOrderStatus.ReadyToShip;
      case 'DELIVERED':
        return SpecialOrderStatus.Delivered;
      case 'RECEIVED_AT_WAREHOUSE':
        return SpecialOrderStatus.ReceivedAtWarehouse;
      case 'EN_ROUTE':
        return SpecialOrderStatus.EnRoute;
      default:
        return SpecialOrderStatus.Other;
    }
  }

  private generateStatusBars(specialOrder: SpecialOrder) {
    return this.displayedSpecialOrderStatusTypes.map(
      (status: SpecialOrderStatus) => {
        return {
          highlighted: this.isStatusHighlighted(status, specialOrder),
          status: status,
        };
      }
    );
  }

  private shouldShowStatusBar(order: SpecialOrder): boolean {
    const orderStatus = order ? order.statusCode : null;
    const specialOrderStatus = SpecialOrderRowViewModel.mapStringToSpecialOrderStatus(
      orderStatus
    );
    return (
      specialOrderStatus !== SpecialOrderStatus.Other &&
      this.displayedSpecialOrderStatusTypes.indexOf(specialOrderStatus) > -1
    );
  }

  private isStatusHighlighted(
    status: SpecialOrderStatus,
    specialOrder: SpecialOrder
  ): boolean {
    const specialOrderStatus = SpecialOrderRowViewModel.mapStringToSpecialOrderStatus(
      specialOrder.statusCode
    );

    const displayedStatuses = this.displayedSpecialOrderStatusTypes;
    return (
      displayedStatuses.indexOf(status) <=
      displayedStatuses.indexOf(specialOrderStatus)
    );
  }

  private severityCodeIconInfo(severityCode: StatusSeverity): SeverityIconInfo {
    switch (severityCode) {
      case StatusSeverity.Warning:
        return { severityIcon: 'warning-icon-v3', severityIconColor: 'yellow' };
      case StatusSeverity.Error:
        return { severityIcon: 'exception-icon-v1', severityIconColor: 'red' };
      default:
        return undefined;
    }
  }
}
