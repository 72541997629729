import { CustomerNotificationPreferencesActions } from './customer-notification-preferences.actions';
import {
  customerNotificationEntryAdapter,
  CustomerNotificationPreferencesState,
  CustomerNotificationsConstants,
  initialCustomerNotificationsState,
  NotificationEntryState,
  NotificationsState,
} from './customer-notification-preferences.state';
import { createReducer, on } from '@ngrx/store';
import { NotificationMethodCode } from '../../../account/preferences/shared/models/customer-notifications';
import {
  CustomerNotificationPreferencesRecord,
  NotificationRecord,
} from '../../services/customer-notification-preferences/models/customer-notification-preferences-record';

export const customerNotificationPreferencesReducer = createReducer(
  initialCustomerNotificationsState,
  on(
    CustomerNotificationPreferencesActions.getCustomerNotificationPreferencesSuccess,
    (state, action): CustomerNotificationPreferencesState =>
      getCustomerNotificationsSuccess(state, action)
  ),
  on(
    CustomerNotificationPreferencesActions.getCustomerNotificationPreferencesFailure,
    (state): CustomerNotificationPreferencesState =>
      getCustomerNotificationsFailure(state)
  ),
  on(
    CustomerNotificationPreferencesActions.addEmailNotification,
    (state): CustomerNotificationPreferencesState =>
      updateEmailEditingOrdinal(
        state,
        CustomerNotificationsConstants.newNotificationOrdinal
      )
  ),
  on(
    CustomerNotificationPreferencesActions.editEmailNotification,
    (state, action): CustomerNotificationPreferencesState =>
      updateEmailEditingOrdinal(state, action.ordinal)
  ),
  on(
    CustomerNotificationPreferencesActions.deleteEmailNotification,
    (state, action): CustomerNotificationPreferencesState =>
      deleteEmailNotification(state, action.ordinal)
  ),
  on(
    CustomerNotificationPreferencesActions.saveEmailNotification,
    (state, action): CustomerNotificationPreferencesState =>
      saveEmailNotification(state, action.emailNotificationState)
  ),
  on(
    CustomerNotificationPreferencesActions.cancelEditEmailNotification,
    (state): CustomerNotificationPreferencesState =>
      updateEmailEditingOrdinal(
        state,
        CustomerNotificationsConstants.noneSelectedOrdinal
      )
  ),
  on(
    CustomerNotificationPreferencesActions.addSmsNotification,
    (state): CustomerNotificationPreferencesState =>
      updateSmsEditingOrdinal(
        state,
        CustomerNotificationsConstants.newNotificationOrdinal
      )
  ),
  on(
    CustomerNotificationPreferencesActions.editSmsNotification,
    (state, action): CustomerNotificationPreferencesState =>
      updateSmsEditingOrdinal(state, action.ordinal)
  ),
  on(
    CustomerNotificationPreferencesActions.deleteSmsNotification,
    (state, action): CustomerNotificationPreferencesState =>
      deleteSmsNotification(state, action.ordinal)
  ),
  on(
    CustomerNotificationPreferencesActions.saveSmsNotification,
    (state, action): CustomerNotificationPreferencesState =>
      saveSmsNotification(state, action.smsNotificationState)
  ),
  on(
    CustomerNotificationPreferencesActions.cancelEditSmsNotification,
    (state): CustomerNotificationPreferencesState =>
      updateSmsEditingOrdinal(
        state,
        CustomerNotificationsConstants.noneSelectedOrdinal
      )
  ),
  on(
    CustomerNotificationPreferencesActions.refreshCustomerNotificationPreferences,
    (state, action): CustomerNotificationPreferencesState =>
      refreshCustomerPreferences(state, action.userRefreshed)
  ),
  on(
    CustomerNotificationPreferencesActions.clearCustomerNotifications,
    (): CustomerNotificationPreferencesState =>
      initialCustomerNotificationsState
  ),
  on(
    CustomerNotificationPreferencesActions.updateCustomerNotificationPreferencesSuccess,
    (state, action): CustomerNotificationPreferencesState =>
      updateCustomerNotificationPreferencesSuccess(state, action)
  ),
  on(
    CustomerNotificationPreferencesActions.updateCustomerNotificationPreferences,
    (state): CustomerNotificationPreferencesState => state
  )
);

function refreshCustomerPreferences(
  state: CustomerNotificationPreferencesState,
  userRefreshed: boolean
): CustomerNotificationPreferencesState {
  return {
    ...state,
    hasRefreshed: userRefreshed,
    hasError: false,
  };
}

function updateCustomerNotificationPreferencesSuccess(
  state: CustomerNotificationPreferencesState,
  action: {
    customerNotificationPreferences: CustomerNotificationPreferencesRecord;
  }
): CustomerNotificationPreferencesState {
  return {
    ...state,
    customerNotificationPreferences: action.customerNotificationPreferences,
    hasError: false,
    hasRefreshed: false,
  };
}

function getCustomerNotificationsSuccess(
  state: CustomerNotificationPreferencesState,
  action: {
    customerNotificationPreferences: CustomerNotificationPreferencesRecord;
  }
): CustomerNotificationPreferencesState {
  const emailState = transformRecords(
    NotificationMethodCode.Email,
    action.customerNotificationPreferences?.emailNotifications
  );
  const smsState = transformRecords(
    NotificationMethodCode.Sms,
    action.customerNotificationPreferences?.smsNotifications
  );

  return {
    ...state,
    customerNotificationPreferences: action.customerNotificationPreferences,
    email: {
      notifications: customerNotificationEntryAdapter.setAll(
        emailState,
        state.email.notifications
      ),
      editingOrdinal: CustomerNotificationsConstants.noneSelectedOrdinal,
      hasLoaded: true,
      nextOrdinal: emailState.length,
    },
    sms: {
      notifications: customerNotificationEntryAdapter.setAll(
        smsState,
        state.sms.notifications
      ),
      editingOrdinal: CustomerNotificationsConstants.noneSelectedOrdinal,
      hasLoaded: true,
      nextOrdinal: smsState.length,
    },
    hasError: false,
    hasRefreshed: false,
  };
}

function transformRecords(
  type: NotificationMethodCode,
  notificationRecords: NotificationRecord[]
): NotificationEntryState[] {
  if (notificationRecords == null) {
    return [];
  }

  let ordinal = 0;
  const notificationStates: NotificationEntryState[] = [];

  notificationRecords.forEach((notificationRecord) => {
    const notificationToUpdate = notificationStates.find(
      (previousNotification) =>
        `${previousNotification.contactInfo}` ===
        `${notificationRecord.methodValue}`
    );

    if (notificationToUpdate) {
      notificationToUpdate.languages.push(notificationRecord.languageCode);
    } else {
      notificationStates.push({
        type: type,
        ordinal: ordinal,
        name: notificationRecord.methodName,
        contactInfo: notificationRecord.methodValue,
        languages: [notificationRecord.languageCode],
        subscriptionTypes: notificationRecord.subscriptionTypes,
        customerReminders: notificationRecord.customerReminders,
      });
      ordinal++;
    }
  });

  return notificationStates;
}

function getCustomerNotificationsFailure(
  state: CustomerNotificationPreferencesState
): CustomerNotificationPreferencesState {
  return {
    ...state,
    hasError: true,
  };
}

function saveSmsNotification(
  state: CustomerNotificationPreferencesState,
  actionState: NotificationEntryState
): CustomerNotificationPreferencesState {
  if (
    state.sms.editingOrdinal ===
    CustomerNotificationsConstants.newNotificationOrdinal
  ) {
    return {
      ...state,
      sms: addNotificationToStartOfList(state.sms, actionState),
    };
  } else {
    return {
      ...state,
      sms: updateNotificationState(state.sms, actionState),
    };
  }
}

function saveEmailNotification(
  state: CustomerNotificationPreferencesState,
  actionState: NotificationEntryState
): CustomerNotificationPreferencesState {
  if (
    state.email.editingOrdinal ===
    CustomerNotificationsConstants.newNotificationOrdinal
  ) {
    return {
      ...state,
      email: addNotificationToStartOfList(state.email, actionState),
    };
  } else {
    return {
      ...state,
      email: updateNotificationState(state.email, actionState),
    };
  }
}

function deleteSmsNotification(
  state: CustomerNotificationPreferencesState,
  ordinal: number
) {
  return {
    ...state,
    sms: {
      ...state.sms,
      notifications: customerNotificationEntryAdapter.removeOne(
        ordinal,
        state.sms.notifications
      ),
      editingOrdinal: CustomerNotificationsConstants.noneSelectedOrdinal,
    },
  };
}

function deleteEmailNotification(
  state: CustomerNotificationPreferencesState,
  ordinal: number
) {
  return {
    ...state,
    email: {
      ...state.email,
      notifications: customerNotificationEntryAdapter.removeOne(
        ordinal,
        state.email.notifications
      ),
      editingOrdinal: CustomerNotificationsConstants.noneSelectedOrdinal,
    },
  };
}

function updateEmailEditingOrdinal(
  state: CustomerNotificationPreferencesState,
  currentOrdinal: number
): CustomerNotificationPreferencesState {
  return {
    ...state,
    email: {
      ...state.email,
      editingOrdinal: currentOrdinal,
    },
  };
}

function updateSmsEditingOrdinal(
  state: CustomerNotificationPreferencesState,
  currentOrdinal: number
): CustomerNotificationPreferencesState {
  return {
    ...state,
    sms: {
      ...state.sms,
      editingOrdinal: currentOrdinal,
    },
  };
}

function updateNotificationState(
  state: NotificationsState,
  entryState: NotificationEntryState
) {
  const savedNotification: NotificationEntryState = {
    ...entryState,
  };
  if (
    state.editingOrdinal ===
    CustomerNotificationsConstants.newNotificationOrdinal
  ) {
    savedNotification.ordinal = state.nextOrdinal;
  }

  return {
    ...state,
    notifications: customerNotificationEntryAdapter.upsertOne(
      savedNotification,
      state.notifications
    ),
    editingOrdinal: CustomerNotificationsConstants.noneSelectedOrdinal,
    nextOrdinal:
      state.editingOrdinal ===
      CustomerNotificationsConstants.newNotificationOrdinal
        ? state.nextOrdinal + 1
        : state.nextOrdinal,
  };
}

/*
 * Adds new notification to the start of the list of notifications
 * Duplicates all notifications and increments ordinal values
 * */
function addNotificationToStartOfList(
  state: NotificationsState,
  entryState: NotificationEntryState
) {
  const duplicateNotifications = [
    {
      type: entryState.type,
      ordinal: 0,
      name: entryState.name,
      contactInfo: entryState.contactInfo,
      languages: entryState.languages,
      subscriptionTypes: entryState.subscriptionTypes,
      customerReminders: entryState.customerReminders,
    },
  ];
  state.notifications.ids.forEach((id: string | number) => {
    const notification = state.notifications.entities[id];
    duplicateNotifications.push({
      type: entryState.type,
      ordinal: notification.ordinal + 1,
      contactInfo: notification.contactInfo,
      name: notification.name,
      languages: notification.languages,
      subscriptionTypes: notification.subscriptionTypes,
      customerReminders: notification.customerReminders,
    });
  });

  return {
    ...state,
    notifications: customerNotificationEntryAdapter.upsertMany(
      duplicateNotifications,
      customerNotificationEntryAdapter.getInitialState()
    ),
    editingOrdinal: CustomerNotificationsConstants.noneSelectedOrdinal,
    nextOrdinal: state.nextOrdinal + 1,
  };
}
