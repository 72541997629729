import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mergeMap, Observable } from 'rxjs';
import { MaterialDetailsActions } from './material-details.action';
import { MaterialAdditionalInfoActions } from '../material-additional-info/material-additional-info.actions';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { LastOrderedActions } from '../last-ordered/last-ordered.actions';
import { MygfsNutritionActions } from '../mygfs-nutrition/mygfs-nutrition.actions';
import { MaterialRecommendationsActions } from '../material-recommendations/material-recommendations.actions';
import { Page } from '../material-recommendations/material-recommendations.state';
import { Injectable } from '@angular/core';
import { MaterialInfoActions } from '../material-info/material-info.actions';

@Injectable()
export class MaterialDetailsEffects {
  constructor(private actions$: Actions) {}

  loadMaterialDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialDetailsActions.loadMaterialDetails),
      mergeMap((action) => {
        const pdpMaterial = action.materialInfo.materialNumber;
        const complimentaryMaterials =
          action.materialInfo.complimentaryMaterials || [];
        const combinedMaterialNumbers = [pdpMaterial].concat(
          complimentaryMaterials
        );
        return [
          MaterialInfoActions.loadMaterialInfo(complimentaryMaterials),
          MaterialAdditionalInfoActions.loadMaterialAdditionalInfo(
            combinedMaterialNumbers
          ),
          MaterialAvailabilityActions.loadMaterialAvailability(
            combinedMaterialNumbers
          ),
          LastOrderedActions.loadLastOrdered([pdpMaterial]),
          MygfsNutritionActions.loadMygfsNutrition([pdpMaterial]),
          MaterialRecommendationsActions.loadMaterialRecommendations(
            Page.PDP,
            pdpMaterial
          ),
        ];
      })
    );
  });
}
