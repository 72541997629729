import { SalesCriticalItem } from '../../services/sales-critical-items/model/sales-critical-items';

export function doesQuantityExceedMaxHardStop(
  salesCriticalItem: SalesCriticalItem | null,
  cartMaterialQuantity: number
): boolean {
  return (
    !!salesCriticalItem &&
    salesCriticalItem.maximumQuantity > 0 &&
    salesCriticalItem.maximumQuantityErrorFlag &&
    salesCriticalItem.onOrderQuantity + cartMaterialQuantity >
      salesCriticalItem.maximumQuantity
  );
}

export function doesQuantityHaveMinSoftStop(
  salesCriticalItem: SalesCriticalItem | null,
  cartMaterialQuantity: number
): boolean {
  return (
    !!salesCriticalItem &&
    salesCriticalItem.onOrderQuantity + cartMaterialQuantity <
      salesCriticalItem.minimumQuantity
  );
}

export function doesQuantityExceedMaxSoftStop(
  salesCriticalItem: SalesCriticalItem | null,
  cartMaterialQuantity: number
): boolean {
  return (
    !!salesCriticalItem &&
    salesCriticalItem.maximumQuantity > 0 &&
    !salesCriticalItem.maximumQuantityErrorFlag &&
    salesCriticalItem.onOrderQuantity + cartMaterialQuantity >
      salesCriticalItem.maximumQuantity
  );
}
