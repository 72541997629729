import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorActions } from './error.actions';
import { tap } from 'rxjs/operators';
import { NaooLogger } from '../../../shared/logger/NaooLogger.service';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import { NaooErrorUtils } from '../../../shared/error-handler/NaooErrorUtils';

@Injectable()
export class ErrorEffects {
  constructor(
    private actions$: Actions,
    private dialogService: DefaultDialogService,
    private logger: NaooLogger
  ) {}

  handleFatalError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ErrorActions.fatalError),
        tap((action) => {
          // This is to circumvent editing all existing effects using this Action type,
          // if a service call fails with a 500 Status we still want to throw the FatalErrorAction and open the Error Modal
          if (NaooErrorUtils.isOfflineError(action.error)) {
            this.logger.warn(action.error);
          } else {
            this.logger.error(action.error);
            const errorReason = action.customAnalytic
              ? action.customAnalytic
              : action.error.toString();
            this.dialogService.defaultErrorModal(errorReason, true);
          }
        })
      );
    },
    { dispatch: false }
  );

  logSilentError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ErrorActions.silentError),
        tap((action) => {
          if (NaooErrorUtils.isOfflineError(action.payload)) {
            this.logger.warn(action.payload);
          } else {
            this.logger.error(action.payload);
          }
        })
      );
    },
    { dispatch: false }
  );
}
