<button
  class="cart-icon-button"
  [disabled]="!isCartLoaded"
  (click)="navigateToCart()"
  [ngClass]="{ 'wide-layout': wideLayout }"
  [attr.aria-label]="
    cartWCAGTitle
      | translate
        : {
            count: itemsInCart,
            estimatedTotalCost:
              estimatedTotalCost | naoocurrency: 'CAD':'symbol-narrow':'1.2-2'
          }
  "
>
  <div class="cart-icon-badge" [ngClass]="{ wide: wideLayout }">
    <mat-icon
      svgIcon="cart_icon_v2"
      class="cart-icon-image"
      [ngClass]="{ wide: wideLayout, disabled: !isCartLoaded }"
    ></mat-icon>
    <div
      *ngIf="!wideLayout"
      class="product-count-badge"
      [ngClass]="{ disabled: !isCartLoaded }"
      [@badgeIcon]="badgeAnimationState"
      (@badgeIcon.done)="animationComplete()"
    >
      <div class="item-count" [ngClass]="{ disabled: !isCartLoaded }">
        {{ itemsInCart | naoodecimal: '1.0-0' }}
      </div>
    </div>
  </div>
  <div class="text-container" [ngClass]="{ wide: wideLayout }">
    <div class="text" [ngClass]="{ 'wide-layout': wideLayout }">
      <div
        *ngIf="!isMobile"
        class="view-order"
        [ngClass]="{ disabled: !isCartLoaded }"
        title="{{ 'CART.VIEW_ORDER' | translate }}"
      >
        {{ 'CART.VIEW_ORDER' | translate }}
      </div>
      <div class="cart-total" [ngClass]="{ disabled: !isCartLoaded }">
        {{
          isCalculatingInitialTotalCost
            ? '–'
            : (estimatedTotalCost | naoocurrency: 'CAD':'symbol-narrow':'1.2-2')
        }}
      </div>
      <div class="cart-icon-badge wide" *ngIf="wideLayout">
        <div
          class="product-count-badge wide"
          [@badgeIcon]="badgeAnimationState"
          (@badgeIcon.done)="animationComplete()"
        >
          <div class="item-count">
            {{ itemsInCart | naoodecimal: '1.0-0' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-icon *ngIf="wideLayout" svgIcon="arrow-v2" class="arrow"></mat-icon>
</button>
