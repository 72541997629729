import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { dateFormats, timeZoneCode } from '../utilities/date-utilities';
import { LocalizationService } from '../services/translation/localization.service';
import moment from 'moment';

@Pipe({
  name: 'naooDate',
  pure: false,
})
export class NaooDatePipe extends DatePipe implements PipeTransform, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef
  ) {
    super(localizationService.currentLocale);
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(
    value: null | undefined,
    format?: string,
    timezoneAbbr?: string,
    localePassed?: string,
    timezone?: string
  ): null;
  transform(
    value: string | number | Date,
    format?: string,
    timezoneAbbr?: string,
    localePassed?: string,
    timezone?: string
  ): string | null;
  transform(
    value: any,
    format?: string,
    timezoneAbbr?: string,
    localePassed?: string,
    timezone?: string
  ): string | null {
    const locale = localePassed
      ? localePassed
      : this.localizationService.currentLocale;
    if (!dateFormats[locale][format]) {
      throw new Error(
        `${format} is not a supported date format for NaooDatePipe.`
      );
    }
    if ('longDateAbbr' === format && moment(value).toDate().getMonth() === 4) {
      format = 'longDateAbbrMay';
    }
    if (
      'shortWeekdayMonthDay' === format &&
      moment(value).isSame(new Date(), 'day')
    ) {
      format = 'todayMonthDay';
    }
    if (!!timezone && !timezoneAbbr && value) {
      timezoneAbbr = timeZoneCode(value, timezone);
    }
    return super.transform(
      value,
      dateFormats[locale][format],
      timezoneAbbr,
      locale
    );
  }
}
