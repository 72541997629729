import { StorePurchaseHistoryRecord } from '../../services/store-purchase-history/model/store-purchase-history-record';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface StorePurchaseHistoryState {
  records: EntityState<StorePurchaseHistoryRecordState>;
  loadedStartDate: string;
  loadedEndDate: string;
  status?: StorePurchaseHistoryStateStatus;
}

export interface StorePurchaseHistoryRecordState {
  entityKey: string;
  record: StorePurchaseHistoryRecord;
}

export enum StorePurchaseHistoryStateStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const storePurchaseHistoryRecordAdapter = createEntityAdapter<StorePurchaseHistoryRecordState>(
  { selectId: (state) => state.entityKey }
);

export const initialStorePurchaseHistoryRecordState: StorePurchaseHistoryState = {
  records: storePurchaseHistoryRecordAdapter.getInitialState(),
  loadedStartDate: undefined,
  loadedEndDate: undefined,
  status: undefined,
};
