import { Injectable } from '@angular/core';
import { MaterialInfo } from '../../../shared/models/material-info';
import {
  OrderDetailsLineViewModel,
  UomLine,
} from '../../../shared/models/order-details';

export interface EditOrderDetailsViewModel {
  material: MaterialInfo;
  materialNumber: string;
  units: UomLine[];
}

@Injectable({
  providedIn: 'root',
})
export class EditOrderTransformService {
  public transform(
    orderDetailsLineViewModel: OrderDetailsLineViewModel
  ): EditOrderDetailsViewModel {
    const { material, materialNumber, units } = orderDetailsLineViewModel;
    return {
      material,
      materialNumber,
      units,
    };
  }
}
