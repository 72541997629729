import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ExpressSchedulesFacade } from '../../core/store/express-schedules/express-schedules.facade';
import { CartFacade } from '../../core/store/cart/cart.facade';
import { map } from 'rxjs/operators';

export const expressSchedulesValidGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<boolean> => {
  return isAfterCutoff();
};

function isAfterCutoff(): Observable<boolean> {
  const expressSchedulesFacade = inject(ExpressSchedulesFacade);
  const cartFacade = inject(CartFacade);

  expressSchedulesFacade.refreshExpressSchedules();
  return expressSchedulesFacade
    .getLoadedIsCurrentExpressDeliveryWindowAfterCutoff()
    .pipe(
      map((isAfterCutoff) => {
        if (isAfterCutoff) {
          cartFacade.openFulfillmentModal();
        }
        return !isAfterCutoff;
      })
    );
}
