import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NaooConstants } from '../NaooConstants';
import { LocalizationService } from '../services/translation/localization.service';
import { Locale } from 'src/app/core/services/session/models/session-record';

@Injectable()
export class NaooLangHttpInterceptor implements HttpInterceptor {
  constructor(private localizationService: LocalizationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let langReq;
    if (req.url.match(window.location.hostname)) {
      langReq = req.clone({
        setHeaders: {
          [NaooConstants.LANGUAGE_HEADER]:
            this.localizationService.currentLocale || Locale.en_CA,
        },
      });
    } else {
      langReq = req;
    }

    return next.handle(langReq);
  }
}
