import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { AnalyticsEventInfo } from './analytics-event-info';
import { CustomDimension } from './custom-dimension';
import { isEmpty } from 'lodash-es';
import { NaooLogger } from '../logger/NaooLogger.service';

interface EventTrack {
  action: string;
  properties?: {
    category?: string;
    label?: string;
    value?: number;
    gtmCustom?: {
      [customDimension: string]: string | number;
    };
  };
}

@Injectable({ providedIn: 'root' })
export class NaooAnalyticsManager {
  private static readonly CUSTOM_DIMENSION = 'customDimension';
  constructor(
    private angulartics2: Angulartics2,
    private logger: NaooLogger,
    private _window: Window
  ) {}

  trackAnalyticsEvent(
    eventInfo: AnalyticsEventInfo,
    customDimensions?: CustomDimension[]
  ): void {
    const { action, ...properties } = eventInfo;
    const event: EventTrack = {
      action,
    };

    if (!isEmpty(properties)) {
      event.properties = properties;
    }

    if (customDimensions) {
      if (!event.properties) {
        event.properties = {};
      }
      event.properties.gtmCustom = {};
      customDimensions.forEach((customDimension) => {
        const customDimensionIndex =
          NaooAnalyticsManager.CUSTOM_DIMENSION + customDimension.index;
        event.properties.gtmCustom[customDimensionIndex] =
          customDimension.value;
      });
    }

    this.angulartics2.eventTrack.next(event);
  }

  pushCustomDimension(customDimension: CustomDimension) {
    const dataLayer: unknown[] = this._window.dataLayer;
    if (!dataLayer || !dataLayer.push) {
      this.logger.error(
        `Unable to store custom dimension: dataLayer is unavailable. dataLayer == ${dataLayer}`
      );
      return;
    }

    const customDimensionIndex: string =
      NaooAnalyticsManager.CUSTOM_DIMENSION + customDimension.index;
    const event = { [customDimensionIndex]: customDimension.value };
    dataLayer.push(event);
  }
}
