import { CartMaterial } from './cart-material';
import { SplitOrder } from '../../core/services/cart-order/models/cart-order';
import {
  StoreFulfillment,
  TruckFulfillment,
} from '../../core/store/cart/cart.state';
import { FulfillmentType } from '../../core/services/cart/models/cart-record';

export class Cart {
  id: string;
  customerPo: string;
  materials: CartMaterial[];
  coupons: string[];
  fulfillmentType: FulfillmentType;
  splitOrders?: SplitOrder[];
  truckFulfillment?: TruckFulfillment;
  storeFulfillment?: StoreFulfillment;

  constructor(
    id: string,
    materials: CartMaterial[],
    coupons: string[],
    customerPo: string = '',
    fulfillmentType: FulfillmentType = FulfillmentType.NONE,
    splitOrders?: SplitOrder[],
    truckFulfillment?: TruckFulfillment,
    storeFulfillment?: StoreFulfillment
  ) {
    this.id = id;
    this.materials = materials;
    this.coupons = coupons;
    this.customerPo = customerPo;
    this.fulfillmentType = fulfillmentType;
    this.splitOrders = splitOrders;
    this.truckFulfillment = truckFulfillment;
    this.storeFulfillment = storeFulfillment;
  }

  get routeDate(): Date | undefined {
    return this.fulfillmentType === FulfillmentType.TRUCK
      ? this.truckFulfillment?.routeDate
      : undefined;
  }
}
