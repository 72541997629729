import {
  LastOrderedRecordState,
  LastOrderedRecordStatus,
} from './last-ordered.state';
import { parseDate } from '../../../shared/utilities/date-utilities';

export function hasLastOrderedFinishedLoading(
  recordState: LastOrderedRecordState
): boolean {
  return (
    !!recordState &&
    [LastOrderedRecordStatus.Error, LastOrderedRecordStatus.Success].includes(
      recordState.status
    )
  );
}

export function lastOrderedComparator(
  lhs: LastOrderedRecordState,
  rhs: LastOrderedRecordState
): number {
  const lhsDate =
    (lhs?.record && parseDate(lhs.record.lastOrderDate)) || new Date(0);
  const rhsDate =
    (rhs?.record && parseDate(rhs.record.lastOrderDate)) || new Date(0);

  return +rhsDate - +lhsDate;
}
