import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialRecord } from '../../services/material-info/models/materials-info-record';

export interface MaterialInfoState
  extends EntityState<MaterialInfoRecordState> {}

export interface MaterialInfoRecordState {
  materialNumber: string;
  status: MaterialInfoRecordStatus;
  record?: MaterialRecord;
}

export enum MaterialInfoRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
  Unavailable = 'Unavailable',
}

export const materialInfoAdapter = createEntityAdapter<MaterialInfoRecordState>(
  {
    selectId: (state) => state.materialNumber,
  }
);

export const initialMaterialInfoState = materialInfoAdapter.getInitialState();
