import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MaterialUnitsState } from './material-units.state';
import { EntityState } from '@ngrx/entity';
import { MaterialUnitsRecord } from '../../services/material-units/models/material-units-record';

const selectMaterialUnitsState = createFeatureSelector<MaterialUnitsState>(
  'materialUnits'
);

export const selectAllMaterialUnitsRecords = createSelector(
  selectMaterialUnitsState,
  (materialUnitsState) => materialUnitsState.materialUnits
);

export const selectHasLoadedMaterialUnits = createSelector(
  selectMaterialUnitsState,
  (materialUnitsState) => materialUnitsState.hasLoaded
);

export const selectMaterialUnitsEntities = createSelector(
  selectAllMaterialUnitsRecords,
  selectHasLoadedMaterialUnits,
  (allRecords: EntityState<MaterialUnitsRecord>, hasLoaded: boolean) => {
    return hasLoaded ? allRecords.entities : undefined;
  }
);

export const selectMaterialUnitsEntryRecord = (displayCode: string) => {
  return createSelector(
    selectAllMaterialUnitsRecords,
    selectHasLoadedMaterialUnits,
    (allRecords: EntityState<MaterialUnitsRecord>, hasLoaded: boolean) => {
      return getMaterialUnitsRecord(allRecords, hasLoaded, displayCode);
    }
  );
};

function getMaterialUnitsRecord(
  allRecords: EntityState<MaterialUnitsRecord>,
  hasLoaded: boolean,
  displayCode: string
): MaterialUnitsRecord {
  if (!hasLoaded) {
    return undefined;
  }
  return allRecords.entities[displayCode];
}
