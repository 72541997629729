import {
  Injectable,
  Pipe,
  PipeTransform,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { LocalizedUtilities } from '../utilities/localized-utilities';
import { LocalizationService } from '../services/translation/localization.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'naooprice',
  pure: false,
})
export class NaooPricePipe implements PipeTransform, OnDestroy {
  static EN_SMALL_DECIMAL_VALUE = new RegExp(/(^(?:\$))(0[.]00)/);
  static FR_SMALL_DECIMAL_VALUE = new RegExp(/^(0,00)(?:(\s\$))/g);

  static EN_LESS_THAN_CENT_EXP = '$1<0.01';
  static FR_LESS_THAN_CENT_EXP = '<0,01$2';

  private destroyed$ = new Subject<void>();

  constructor(
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef
  ) {
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(
    value: string | number,
    weightUom?: string,
    defaultValue: string = '–'
  ): string {
    const localizedPrice =
      LocalizedUtilities.getLocalizedPrice(
        this.localizationService.currentLocale,
        value,
        weightUom
      ) || defaultValue;

    return Number(value)
      ? localizedPrice
          .replace(
            NaooPricePipe.EN_SMALL_DECIMAL_VALUE,
            NaooPricePipe.EN_LESS_THAN_CENT_EXP
          )
          .replace(
            NaooPricePipe.FR_SMALL_DECIMAL_VALUE,
            NaooPricePipe.FR_LESS_THAN_CENT_EXP
          )
      : localizedPrice;
  }
}
