import { Injectable } from '@angular/core';
import { GenericModalComponent } from '../modals/generic-modal/generic-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DefaultDialogService } from '../services/dialog/default-dialog/default-dialog.service';
import { NaooAnalyticsManager } from '../analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../analytics/analytics-event-info';
import { first } from 'rxjs/operators';

enum RetryDialogType {
  Retrying = 'Retrying',
  NoConnection = 'No Connection',
  None = 'None',
}

@Injectable({ providedIn: 'root' })
export class RetryUIService {
  private retryDialogType = RetryDialogType.None;
  private openedDialog: MatDialogRef<GenericModalComponent>;

  constructor(
    private router: Router,
    private defaultDialogService: DefaultDialogService,
    private analytics: NaooAnalyticsManager
  ) {}

  openRetryingConnectivityModal(): void {
    if (this.retryDialogType === RetryDialogType.None) {
      this.closeConnectivityModal();
      this.retryDialogType = RetryDialogType.Retrying;

      const imageData = {
        imageLocation: './assets/images/trouble-connecting.mp4',
        altText: 'CONNECTIVITY.TROUBLE_HEADER',
      };
      this.openedDialog = this.defaultDialogService.prompt(
        'trouble-connecting-header',
        'CONNECTIVITY.TROUBLE_HEADER',
        false,
        false,
        imageData,
        'CONNECTIVITY.TROUBLE_DESCRIPTION'
      );

      this.setAfterCloseBehavior();
      this.trackRetryModalShown();
    }
  }

  openNoConnectionModal(): void {
    if (this.retryDialogType !== RetryDialogType.NoConnection) {
      this.closeConnectivityModal();
      this.retryDialogType = RetryDialogType.NoConnection;

      const imageData = {
        imageLocation: './assets/images/no-connection.svg',
        altText: 'CONNECTIVITY.NO_CONNECTION_HEADER',
      };
      this.openedDialog = this.defaultDialogService.prompt(
        'no-connection-header',
        'CONNECTIVITY.NO_CONNECTION_HEADER',
        true,
        true,
        imageData,
        'CONNECTIVITY.NO_CONNECTION_DESCRIPTION',
        'CONNECTIVITY.NO_CONNECTION_BUTTON',
        () => {
          this.router.navigate(['/']);
        }
      );

      this.setAfterCloseBehavior();
      this.trackFailureModalShown();
    }
  }

  closeConnectivityModal(): void {
    if (this.openedDialog) {
      this.openedDialog.close();
    }
    this.resetOpenModal();
  }

  private setAfterCloseBehavior() {
    if (this.openedDialog) {
      this.openedDialog
        .afterClosed()
        .pipe(first())
        .subscribe(() => this.resetOpenModal());
    }
  }

  private resetOpenModal() {
    this.openedDialog = undefined;
    this.retryDialogType = RetryDialogType.None;
  }

  private trackRetryModalShown() {
    const eventInfo: AnalyticsEventInfo = {
      action: 'displayed',
      category: 'general',
      label: 'connectivity',
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackFailureModalShown() {
    const eventInfo: AnalyticsEventInfo = {
      action: 'displayed',
      category: 'general',
      label: 'connectivity failure',
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }
}
