import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialAvailabilityRecord } from '../../services/material-availability/model/material-availabilities-record';

export const MaterialAvailabilityActions = createActionGroup({
  source: 'Material Availability',
  events: {
    'Load Material Availability': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Material Availability': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Material Availability Success': (
      materialAvailabilityRecords: MaterialAvailabilityRecord[]
    ) => ({ materialAvailabilityRecords }),
    'Get Material Availability Error': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Clear Material Availability': emptyProps(),
    'Refresh List Material Availability': emptyProps(),
  },
});
