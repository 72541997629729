import {
  MaterialWarning,
  MaterialWarningMessageParam,
  MaterialWarningType,
} from './material-warning';

export class MaterialWarningFactory {
  static get(
    materialNumber: string,
    warningType: MaterialWarningType,
    isOpen: boolean,
    hasSentAnalytics: boolean,
    hasAcknowledged: boolean,
    warningParams?: MaterialWarningMessageParam
  ): MaterialWarning {
    let materialWarning: MaterialWarning;
    switch (warningType) {
      case MaterialWarningType.OpenSpecialOrders:
        materialWarning = createOpenSpecialOrdersWarning(
          isOpen,
          materialNumber
        );
        break;
      case MaterialWarningType.NoStock:
        materialWarning = createNoStockWarning(
          isOpen,
          materialNumber,
          warningParams
        );
        break;
      case MaterialWarningType.PartialStockCase:
        materialWarning = createPartialStockCase(
          isOpen,
          materialNumber,
          warningParams
        );
        break;
      case MaterialWarningType.PartialStockCaseUnit:
        materialWarning = createPartialStockCaseUnit(
          isOpen,
          materialNumber,
          warningParams
        );
        break;
      case MaterialWarningType.RepeatingDigitCase:
      case MaterialWarningType.RepeatingDigitUnit:
        materialWarning = createRepeatingDigit(
          isOpen,
          warningType,
          materialNumber
        );
        break;
      case MaterialWarningType.QuantityThresholdExceededCase:
      case MaterialWarningType.QuantityThresholdExceededUnit:
        materialWarning = createQuantityThresholdExceeded(
          isOpen,
          warningType,
          materialNumber
        );
        break;
      case MaterialWarningType.UnitCaseConversion:
        materialWarning = createUnitCaseConversion(isOpen, materialNumber);
        break;
      case MaterialWarningType.MissedCutoff:
        materialWarning = createMissedCutoff(isOpen, materialNumber);
        break;
      case MaterialWarningType.MaximumQuantityHardStop:
        materialWarning = createMaximumQuantityExceededHardStop(
          isOpen,
          warningType,
          materialNumber,
          warningParams
        );
        break;
      case MaterialWarningType.MaximumQuantitySoftStop:
        materialWarning = createMaximumQuantityExceededSoftStop(
          isOpen,
          warningType,
          materialNumber,
          warningParams
        );
        break;
      case MaterialWarningType.MinimumQuantitySoftStop:
        materialWarning = createMinimumQuantitySoftStop(
          isOpen,
          warningType,
          materialNumber,
          warningParams
        );
        break;
      case MaterialWarningType.OverAllocation:
        materialWarning = createOverAllocation(
          isOpen,
          warningType,
          materialNumber,
          warningParams
        );
    }

    materialWarning.analytics.displayed = hasSentAnalytics
      ? undefined
      : materialWarning.analytics.displayed;
    materialWarning.analytics.suppressed = hasAcknowledged
      ? undefined
      : materialWarning.analytics.suppressed;

    return materialWarning;
  }
}

function createOpenSpecialOrdersWarning(
  isOpen: boolean,
  materialNumber: string
): MaterialWarning {
  return {
    type: MaterialWarningType.OpenSpecialOrders,
    isOpen,
    message: 'MATERIAL_WARNING.OPEN_SPECIAL_ORDER',
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `special order reminder - ${materialNumber}`,
      },
      suppressed: {
        action: 'removed',
        category: 'warning',
        label: `special order reminder - ${materialNumber}`,
      },
    },
  };
}

function createNoStockWarning(
  isOpen: boolean,
  materialNumber: string,
  warningParams?: MaterialWarningMessageParam
): MaterialWarning {
  return {
    type: MaterialWarningType.NoStock,
    isOpen,
    message: warningParams?.nextAvailable
      ? 'MATERIAL_WARNING.NO_STOCK_WITH_NEXT_PO_DATE'
      : 'MATERIAL_WARNING.NO_STOCK',
    messageParams: warningParams,
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `no stock - ${materialNumber}`,
      },
      suppressed: {
        action: 'removed',
        category: 'warning',
        label: `no stock - ${materialNumber}`,
      },
    },
  };
}

function createPartialStockCase(
  isOpen: boolean,
  materialNumber: string,
  messageParams: MaterialWarningMessageParam
): MaterialWarning {
  return {
    type: MaterialWarningType.PartialStockCase,
    isOpen,
    message: 'MATERIAL_WARNING.PARTIAL_STOCK',
    messageParams: messageParams,
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `partial stock (case) - ${materialNumber}`,
      },
      suppressed: {
        action: 'removed',
        category: 'warning',
        label: `partial stock (case) - ${materialNumber}`,
      },
    },
  };
}

function createPartialStockCaseUnit(
  isOpen: boolean,
  materialNumber: string,
  messageParams: MaterialWarningMessageParam
): MaterialWarning {
  return {
    type: MaterialWarningType.PartialStockCaseUnit,
    isOpen,
    message: 'MATERIAL_WARNING.PARTIAL_STOCK_BREAKABLE',
    messageParams: messageParams,
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `partial stock (case + unit) - ${materialNumber}`,
      },
      suppressed: {
        action: 'removed',
        category: 'warning',
        label: `partial stock (case + unit) - ${materialNumber}`,
      },
    },
  };
}

function createUnitCaseConversion(
  isOpen: boolean,
  materialNumber: string
): MaterialWarning {
  return {
    type: MaterialWarningType.UnitCaseConversion,
    isOpen,
    message: 'MATERIAL_WARNING.UNIT_CASE_CONVERSION',
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `case unit conversion message - ${materialNumber}`,
      },
    },
  };
}

function createMissedCutoff(
  isOpen: boolean,
  materialNumber: string
): MaterialWarning {
  return {
    type: MaterialWarningType.MissedCutoff,
    isOpen,
    message: 'MATERIAL_WARNING.MISSED_CUTOFF',
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `missed cutoff message - ${materialNumber}`,
      },
    },
  };
}

function createRepeatingDigit(
  isOpen: boolean,
  type: MaterialWarningType,
  materialNumber: string
): MaterialWarning {
  return {
    isOpen,
    type,
    message: 'MATERIAL_WARNING.REPEATING_DIGITS',
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `repeating digit message - ${materialNumber}`,
      },
      suppressed: {
        action: 'dismissed',
        category: 'warning',
        label: `repeating digit message - ${materialNumber}`,
      },
    },
  };
}

function createOverAllocation(
  isOpen: boolean,
  type: MaterialWarningType,
  materialNumber: string,
  warningParams?: MaterialWarningMessageParam
): MaterialWarning {
  return {
    type,
    isOpen,
    isHardWarning: true,
    message: 'MATERIAL_WARNING.OVER_ALLOCATION',
    messageParams: warningParams,
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `over allocation message - ${materialNumber}`,
      },
      suppressed: {
        action: 'dismissed',
        category: 'warning',
        label: `over allocation message - ${materialNumber}`,
      },
    },
  };
}

function createQuantityThresholdExceeded(
  isOpen: boolean,
  type: MaterialWarningType,
  materialNumber: string
): MaterialWarning {
  return {
    type,
    isOpen,
    message: 'MATERIAL_WARNING.LARGE_QUANTITY',
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `large quantity message - ${materialNumber}`,
      },
      suppressed: {
        action: 'dismissed',
        category: 'warning',
        label: `large quantity message - ${materialNumber}`,
      },
    },
  };
}

function createMaximumQuantityExceededHardStop(
  isOpen: boolean,
  type: MaterialWarningType,
  materialNumber: string,
  warningParams: MaterialWarningMessageParam
): MaterialWarning {
  const message =
    warningParams?.onOrderCount > 0
      ? 'MATERIAL_WARNING.MAX_QUANTITY_ALREADY_ORDERED'
      : 'MATERIAL_WARNING.MAX_QUANTITY';
  return {
    type: MaterialWarningType.MaximumQuantityHardStop,
    isHardWarning: true,
    isMultiLine: warningParams?.onOrderCount > 0,
    isOpen,
    message,
    messageParams: warningParams,
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `case max hard - ${materialNumber}`,
      },
      suppressed: {
        action: 'dismissed',
        category: 'warning',
        label: `case max hard - ${materialNumber}`,
      },
    },
  };
}

function createMaximumQuantityExceededSoftStop(
  isOpen: boolean,
  type: MaterialWarningType,
  materialNumber: string,
  warningParams: MaterialWarningMessageParam
): MaterialWarning {
  const message =
    warningParams?.onOrderCount > 0
      ? 'MATERIAL_WARNING.SUGGESTED_MAX_QUANTITY_ALREADY_ORDERED'
      : 'MATERIAL_WARNING.SUGGESTED_MAX_QUANTITY';
  return {
    type: MaterialWarningType.MaximumQuantitySoftStop,
    isHardWarning: false,
    isMultiLine: warningParams?.onOrderCount > 0,
    isOpen,
    message,
    messageParams: warningParams,
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `case max soft - ${materialNumber}`,
      },
      suppressed: {
        action: 'dismissed',
        category: 'warning',
        label: `case max soft - ${materialNumber}`,
      },
    },
  };
}

function createMinimumQuantitySoftStop(
  isOpen: boolean,
  type: MaterialWarningType,
  materialNumber: string,
  warningParams: MaterialWarningMessageParam
): MaterialWarning {
  const message =
    warningParams?.onOrderCount > 0
      ? 'MATERIAL_WARNING.SUGGESTED_MIN_QUANTITY_ALREADY_ORDERED'
      : 'MATERIAL_WARNING.SUGGESTED_MIN_QUANTITY';
  return {
    type: MaterialWarningType.MinimumQuantitySoftStop,
    isHardWarning: false,
    isMultiLine: warningParams?.onOrderCount > 0,
    isOpen,
    message,
    messageParams: warningParams,
    analytics: {
      displayed: {
        action: 'displayed',
        category: 'warning',
        label: `case minimum - ${materialNumber}`,
      },
      suppressed: {
        action: 'dismissed',
        category: 'warning',
        label: `case minimum - ${materialNumber}`,
      },
    },
  };
}
