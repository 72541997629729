import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CustomGuideRecord } from './model/custom-guide-record';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import {
  CopyCustomGuideRequest,
  CustomGuideRequest,
} from './model/custom-guide-request';
import { GroupByType } from 'src/app/guides/shared/guides';

interface CustomGuidesRecord {
  customGuides: CustomGuideRecord[];
}

@Injectable({ providedIn: 'root' })
export class CustomGuideService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getAllCustomGuides() {
    return this.httpClient
      .get<CustomGuidesRecord>(
        `${this.webBffService.getBff()}/api/v6/lists/custom-guides`
      )
      .pipe(map((response) => (response ? response.customGuides : [])));
  }

  createCustomGuide(request: CustomGuideRequest) {
    return this.httpClient.post<CustomGuideRecord>(
      `${this.webBffService.getBff()}/api/v6/lists/custom-guides`,
      request
    );
  }

  copyCustomGuide(id: string, compositeCustomerIds: string[]) {
    const copyRequest: CopyCustomGuideRequest = {
      naooCustomerIds: compositeCustomerIds,
    };
    return this.httpClient.post<void>(
      `${this.webBffService.getBff()}/api/v7/lists/custom-guides/${id}/copy`,
      copyRequest
    );
  }

  deleteCustomGuide(id: string) {
    return this.httpClient.delete<void>(
      `${this.webBffService.getBff()}/api/v6/lists/custom-guides/${id}`
    );
  }

  updateCustomGuide(id: string, request: CustomGuideRequest) {
    return this.httpClient.put<CustomGuideRecord>(
      `${this.webBffService.getBff()}/api/v6/lists/custom-guides/${id}`,
      request
    );
  }

  getCategorizedCustomGuide(id: string, groupBy: GroupByType) {
    return this.httpClient.get<CustomGuideRecord>(
      `${this.webBffService.getBff()}/api/v6/lists/custom-guides/${id}`,
      { params: { groupBy } }
    );
  }
}
