import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { ReOrderValidationResult } from '../re-order-validation-result';
import { ModalHeaderComponent } from '../../shared/modal-header/modal-header.component';
import { NaooConstants } from '../../shared/NaooConstants';
import { Subject } from 'rxjs';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';

@Component({
  selector: 'naoo-re-order-modal',
  templateUrl: './re-order-modal.component.html',
  styleUrls: ['./re-order-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReOrderModalComponent implements OnInit {
  private static readonly ANALYTICS_CATEGORY = 'Reorder From History';
  private static readonly ANALYTICS_ACTION_CLICK = 'Click';
  private static readonly ANALYTICS_ACTION_ERROR = 'Error';
  private static readonly ANALYTICS_LABEL_UNAVAILABLE_PRODUCTS_CONTINUE =
    'Add Items To Cart - Products Unavailable';
  private static readonly ANALYTICS_LABEL_UNAVAILABLE_PRODUCTS_CANCEL =
    'Cancel - Products Unavailable';
  private static readonly ANALYTICS_LABEL_ITEM_UNAVAILABLE =
    'Item Unavailable - ';

  destroyed$ = new Subject();
  showCloseButton = true;
  orderableCount = 0;

  @ViewChild(ModalHeaderComponent, { static: true })
  modalHeader: ModalHeaderComponent;

  constructor(
    private matDialogRef: MatDialogRef<ReOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public validationResult: ReOrderValidationResult,
    private router: Router,
    private analyticsManager: NaooAnalyticsManager,
    private changeDetectorRef: ChangeDetectorRef,
    private customDialogService: CustomDialogService
  ) {}

  ngOnInit() {
    this.orderableCount = Object.keys(this.validationResult.orderable).length;

    Object.keys(this.validationResult.nonOrderable).forEach((offeringId) =>
      this.trackEvent(
        ReOrderModalComponent.ANALYTICS_ACTION_ERROR,
        `${ReOrderModalComponent.ANALYTICS_LABEL_ITEM_UNAVAILABLE}${offeringId}`
      )
    );

    this.changeDetectorRef.markForCheck();
  }

  onClose(): void {
    this.matDialogRef.close();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onCancel(): void {
    this.trackEvent(
      ReOrderModalComponent.ANALYTICS_ACTION_CLICK,
      ReOrderModalComponent.ANALYTICS_LABEL_UNAVAILABLE_PRODUCTS_CANCEL
    );
    this.onClose();
  }

  onContinueUnavailableMaterials(userInitiated: boolean): void {
    if (userInitiated) {
      this.trackEvent(
        ReOrderModalComponent.ANALYTICS_ACTION_CLICK,
        ReOrderModalComponent.ANALYTICS_LABEL_UNAVAILABLE_PRODUCTS_CONTINUE
      );
    }
    this.validationResult.nonOrderable = {};
    this.customDialogService.reOrderChecker(this.validationResult);
    this.onClose();
  }

  goToCart(): void {
    this.router.navigate([NaooConstants.CART_PATH]);
    this.onClose();
  }

  private trackEvent(action: string, label: string): void {
    this.analyticsManager.trackAnalyticsEvent({
      action,
      category: ReOrderModalComponent.ANALYTICS_CATEGORY,
      label,
    });
  }
}
