import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { DOCUMENT } from '@angular/common';
import {
  MaterialRowContext,
  MaterialRowInfo,
  MaterialRowSharedOptions,
  MaterialRowStatus,
} from '../../../../core/store/material-row/models/material-row';

@Component({
  selector: 'naoo-material-deleted',
  templateUrl: './material-deleted.component.html',
  styleUrls: ['./material-deleted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialDeletedComponent implements OnDestroy {
  @Input() materialInfo: MaterialRowInfo;
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() status: MaterialRowStatus;
  @Input() isMobile: boolean;

  @HostBinding('class.mobile') get mobile() {
    return this.isMobile;
  }

  constructor(
    private cartFacade: CartFacade,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnDestroy() {
    if (this.isOK) {
      setTimeout(() => {
        const activeMaterialOptionsMenu = Array.from(
          this._document.getElementsByClassName(
            `${this.materialRowOptions.materialNumber}_OPTIONS`
          )
        ).find((element) => {
          return !!element && element.getBoundingClientRect().bottom > 0;
        }) as HTMLElement;

        if (activeMaterialOptionsMenu) {
          activeMaterialOptionsMenu.focus();
        }
      });
    }
  }

  restoreMaterial(): void {
    const { analytics, context, materialNumber } = this.materialRowOptions;

    this.cartFacade.restoreCartMaterial(materialNumber, context, analytics);
  }

  get isErrorProcessing(): boolean {
    return (
      this.materialRowOptions.context === MaterialRowContext.ErrorProcessingItem
    );
  }

  get isOK(): boolean {
    return this.status === MaterialRowStatus.Ok;
  }

  get isUnorderable(): boolean {
    return this.status === MaterialRowStatus.Unorderable;
  }

  get isUnavailable(): boolean {
    return this.status === MaterialRowStatus.Unavailable;
  }
}
