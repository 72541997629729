import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PicRecord } from './models/pic-record';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MygfsNutritionService {
  constructor(private httpClient: HttpClient) {}

  /**
   * @deprecated use getNutrition instead
   */
  getMygfsNutrition(materialNumber: string): Observable<PicRecord[]> {
    return this.httpClient.get<PicRecord[]>(
      `/nutritionService/getNutritionInfo?offeringGroupId=00001&languageTypeCode=en&offeringId=${materialNumber}`
    );
  }

  getNutrition(materialNumber: string): Observable<PicRecord> {
    return this.httpClient.get<PicRecord>(
      `/api/v1/materials/${materialNumber}/nutrition`
    );
  }
}
