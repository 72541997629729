import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { SplitOrder } from '../../services/cart-order/models/cart-order';

export interface CartState {
  cart?: CartEntityState;
  analyticsPreviousCart?: CartEntityState;
  queuedMaterialNumbers: string[];
  outstandingUpdateRequests: number;
  hasPendingFulfillmentChange: boolean;
  focusedMaterial?: FocusedMaterialState;
}

export interface CartEntityState {
  id: string;
  customerPoNumber?: string;
  fulfillmentType: FulfillmentType;
  truckFulfillment?: TruckFulfillment;
  storeFulfillment?: StoreFulfillment;
  materials: EntityState<CartMaterialState>;
  couponCodes: string[];
  splitOrders?: SplitOrder[];
  selectedDropShipSiteId?: string;
}

export interface TruckFulfillment {
  routeDate?: Date;
  customerArrivalDate?: Date;
}

export interface StoreFulfillment {
  storePlantId?: string;
  requestedPickupTimestamp?: string;
  deliveryWindowStartTimestamp?: string;
  deliveryWindowEndTimestamp?: string;
  expressRouteId?: number;
}

export interface CartMaterialState {
  materialNumber: string;
  lines: EntityState<CartLineState>;
  isDeleted: boolean;
  isRestored: boolean;
  isAddedFromMaterialComparison: boolean;
  isRollUpConversion: boolean;
  originTrackingId: string;
  isAddedFromCriticalItem: boolean;
  isAddedFromCriticalItemsSection: boolean;
}

export interface CartLineState {
  uom: string;
  quantity: number;
}

export interface FocusedMaterialState {
  materialNumber: string;
  lines: EntityState<CartLineState>;
  isRollUpConversion: boolean;
}

export enum CartUpdateType {
  UpdateRouteDate = 'updating order ship date',
  UpdatePoNumber = 'updating cart po number',
  UpdateCartQuantities = 'updating cart',
  UpdateStoreFulfillment = 'updating store fulfillment',
  AddCartCoupons = 'Adding cart coupons',
  RemoveCartCoupons = 'Remove cart coupons',
  OfflineCartSync = 'offline cart sync',
}

export const cartMaterialAdapter = createEntityAdapter<CartMaterialState>({
  selectId: (material) => material.materialNumber,
});

export const initialCartMaterialState: EntityState<CartMaterialState> = cartMaterialAdapter.getInitialState();

export const cartLineAdapter = createEntityAdapter<CartLineState>({
  selectId: (line) => line.uom,
});

export const initialCartLineState: EntityState<CartLineState> = cartLineAdapter.getInitialState();

export const initialCartState: CartState = {
  cart: undefined,
  analyticsPreviousCart: undefined,
  queuedMaterialNumbers: [],
  outstandingUpdateRequests: 0,
  hasPendingFulfillmentChange: false,
};
