<naoo-material-compare-checkbox
  [ngClass]="{ 'checkbox-hidden': hideCheckbox }"
  [compareCheckbox]="materialRowFooter.compareCheckbox"
  [materialRowOptions]="materialRowOptions"
>
</naoo-material-compare-checkbox>

<div
  class="footer-details"
  [ngClass]="{ 'display-commodity-allocation': displayMaxAllocation }"
>
  <span *ngIf="displayMaxAllocation" class="commodity-available">
    <button (click)="viewCommodityDetailsClicked.emit()">
      {{
        materialRowOptions.materialCommodityInfo.maxAllocationDisplayKey
          | translate
            : {
                count:
                  materialRowOptions.materialCommodityInfo.maxAllocationDisplay
              }
      }}
    </button>
  </span>
  <div class="last-order" *ngIf="!!materialRowFooter.lastOrdered">
    {{ 'PRODUCT.ORDERED' | translate }}:
    <span class="date">{{
      materialRowFooter.lastOrdered.date | naooDate: 'shortYearDate':''
    }}</span>
    <span class="quantity" *ngIf="!!materialRowFooter.lastOrdered.line"
      >,
      {{
        {
          uomCode: materialRowFooter.lastOrdered.line.displayCode,
          quantity: materialRowFooter.lastOrdered.line.quantity
        } | materialunits: true
      }}</span
    >
  </div>
</div>
