import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CustomerMaterialService } from '../../services/customer-material/customer-material.service';
import { Observable, of } from 'rxjs';
import { CustomerMaterialActions } from './customer-material.actions';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { ErrorActions } from '../error/error.actions';

@Injectable()
export class CustomerMaterialEffects {
  constructor(
    private actions$: Actions,
    private customerMaterialService: CustomerMaterialService
  ) {}

  getCustomerMaterial$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerMaterialActions.getCustomerMaterial),
      switchMap(() => {
        return this.customerMaterialService.getCustomerMaterial().pipe(
          map((customerMaterial) =>
            CustomerMaterialActions.getCustomerMaterialSuccess(customerMaterial)
          ),
          catchError((err) => {
            return of(ErrorActions.silentError(err));
          }),
          takeUntil(
            this.actions$.pipe(
              ofType(CustomerMaterialActions.refreshCustomerMaterialCodes)
            )
          )
        );
      })
    );
  });

  refreshCustomerMaterial$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerMaterialActions.refreshCustomerMaterialCodes),
      map(() => CustomerMaterialActions.getCustomerMaterial())
    );
  });
}
