import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialAdditionalInfoRecord } from '../../services/material-additional-info/models/material-additional-infos-record';

export const MaterialAdditionalInfoActions = createActionGroup({
  source: 'Material Additional Info',
  events: {
    'Load Material Additional Info': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Material Additional Info': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Material Additional Info Success': (
      records: MaterialAdditionalInfoRecord[]
    ) => ({ records }),
    'Get Material Additional Info Error': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Clear Material Additional Info': emptyProps(),
  },
});
