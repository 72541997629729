import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastMessageService } from '../../services/toast-message/toast-message.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface SubmitLoadingModalData {
  isDone$: EventEmitter<void>;
}

@Component({
  selector: 'naoo-submit-loading-modal',
  templateUrl: './submit-loading-modal.component.html',
  styleUrls: ['./submit-loading-modal.component.scss'],
})
export class SubmitLoadingModalComponent implements OnInit, OnDestroy {
  readonly diameter = 70;
  readonly strokeWidth = 7;
  private readonly closeDialogDelay = 1000;

  //Chose this interval to have spinner fill to 100% in 30 seconds
  private readonly timerInterval = 333;
  private readonly spinnerMaxValue = 100;
  private readonly totalMessages = 5;
  private readonly messageInterval = this.spinnerMaxValue / this.totalMessages;
  private destroyed$ = new Subject<void>();
  private progressIndex = 0;
  private intervalId: any;

  constructor(
    private dialogRef: MatDialogRef<SubmitLoadingModalComponent>,
    private toastMessageService: ToastMessageService,
    @Inject(MAT_DIALOG_DATA) public data: SubmitLoadingModalData
  ) {}

  ngOnInit(): void {
    this.start();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();

    clearInterval(this.intervalId);
  }

  get isStillLoading(): boolean {
    return this.progressIndex < this.spinnerMaxValue;
  }

  get progressValue(): number {
    return this.progressIndex;
  }

  get progressMessageKey(): string {
    const value = Math.floor(this.progressIndex / this.messageInterval);

    return this.totalMessages > value
      ? `LOADING_MODAL.PROGRESS_MESSAGE_${value}`
      : '';
  }

  private start(): void {
    const isDone = this.data.isDone$;
    if (isDone) {
      isDone.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.stop();
      });
    }

    this.intervalId = setInterval(() => {
      this.progressIndex++;
      if (!this.isStillLoading) {
        this.stop();
      }
    }, this.timerInterval);
  }

  private stop(): void {
    clearInterval(this.intervalId);

    while (this.progressIndex < this.spinnerMaxValue) {
      this.progressIndex++;
    }

    this.toastMessageService.showLocalizedToastMessage(
      'LOADING_MODAL.DONE_MESSAGE'
    );
    setTimeout(() => this.dialogRef.close(), this.closeDialogDelay);
  }
}
