import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectCartCouponsFromPrice,
  selectCartSectionTotal,
  selectCombinedPrices,
  selectAllCombinedPrices,
} from './material-price.selectors';
import { filter, map } from 'rxjs/operators';
import { MaterialPriceActions } from './material-price.actions';
import { Observable } from 'rxjs';
import {
  CombinedPricingRecord,
  convertCombinedPriceToMap,
} from './material-price.util';
import { CartCoupons } from '../../../cart/cart-coupon/shared/cart-coupon';

export interface UnitPrice {
  uom: string;
  price: number;
  catchWeightPrice: number | null;
}

export interface MaterialPrice {
  materialNumber: string;
  weightUom: string;
  unitPrices: Map<string, UnitPrice>;
}

export class CombinedPriceCalculation {
  combinedPrice: CombinedPricingRecord | null;
  lineTotals: Map<string, number>;

  constructor(
    combinedPrice: CombinedPricingRecord = null,
    lineTotals: Map<string, number> = new Map<string, number>()
  ) {
    this.combinedPrice = combinedPrice;
    this.lineTotals = lineTotals;
  }
}

@Injectable({ providedIn: 'root' })
export class MaterialPriceFacade {
  constructor(private store: Store) {}
  loadMaterialPrices(materialNumbers: string[]): void {
    this.store.dispatch(
      MaterialPriceActions.loadMaterialPrices(materialNumbers)
    );
  }

  getLoadedCombinedPrices(
    materialNumbers: string[]
  ): Observable<CombinedPricingRecord[]> {
    return this.store
      .select(selectAllCombinedPrices(materialNumbers))
      .pipe(filter((combinedPricingRecord) => !!combinedPricingRecord));
  }

  getLoadedCombinedPriceMap(
    materialNumbers: string[]
  ): Observable<Map<string, CombinedPricingRecord>> {
    return this.getLoadedCombinedPrices(materialNumbers).pipe(
      map((materialPrice) => convertCombinedPriceToMap(materialPrice))
    );
  }

  getCombinedPriceMap(
    materialNumbers: string[]
  ): Observable<Map<string, CombinedPricingRecord>> {
    return this.getCombinedPrices(materialNumbers).pipe(
      map((materialPrice) => convertCombinedPriceToMap(materialPrice))
    );
  }

  watchMaterialPrices(materialNumbers: string[]): void {
    this.store.dispatch(
      MaterialPriceActions.watchMaterialPrices(materialNumbers)
    );
  }

  unwatchMaterialPrice(materialNumbers: string[]): void {
    this.store.dispatch(
      MaterialPriceActions.unwatchMaterialPrices(materialNumbers)
    );
  }

  clearMaterialPrices(): void {
    this.store.dispatch(MaterialPriceActions.clearMaterialPrices());
  }

  getCartSectionTotal(materialNumbers: string[]): Observable<number> {
    return this.store.select(selectCartSectionTotal(materialNumbers));
  }

  addCoupon(couponCode: string) {
    this.store.dispatch(
      MaterialPriceActions.addMaterialPriceCoupon(couponCode)
    );
  }

  removeCoupon(couponCode: string) {
    this.store.dispatch(
      MaterialPriceActions.removeMaterialPriceCoupon(couponCode)
    );
  }

  getCartCoupons(): Observable<CartCoupons> {
    return this.store.select(selectCartCouponsFromPrice);
  }

  private getCombinedPrices(
    materialNumbers: string[]
  ): Observable<CombinedPricingRecord[]> {
    return this.store.select(selectCombinedPrices(materialNumbers));
  }
}
