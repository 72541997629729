<div
  class="header-container"
  [ngClass]="{
    'full-width-header': isInsideContainer,
    'sub-header': isUnderCategoryHeader
  }"
>
  <div class="item-header">
    {{ 'CART.HEADER.ITEM' | translate }}
  </div>

  <div
    class="right-container"
    [ngClass]="{
      'par-enabled': isParEnabled,
      'loyalty-enabled': shouldDisplayLoyaltyPoints,
      'hide-go-points-logo': shouldHideGoPointsLogo
    }"
  >
    <div class="label-section">
      <div class="warning-spacer"></div>
      <div class="ordering-info-section">
        <naoo-go-points-logo
          [context]="'material-list-header'"
          *ngIf="shouldDisplayLoyaltyPoints"
        ></naoo-go-points-logo>
        <div class="size-header">
          {{ 'CART.HEADER.SIZE' | translate }}
        </div>
        <div class="price-header-container">
          <div
            *ngIf="!isParEnabled && !isSlimView"
            class="portion-pricing-header"
          ></div>
          <div class="price-header">
            {{ 'CART.HEADER.PRICE' | translate }}
          </div>
        </div>

        <div class="quantity-container">
          <div class="par-quantity-header" *ngIf="isParEnabled">
            <div class="par-header">
              {{ 'CART.HEADER.PAR' | translate }}
            </div>
            <div class="inventory-header">
              {{ 'CART.HEADER.INVENTORY' | translate }}
            </div>
          </div>

          <div
            class="cart-quantity-header"
            [attr.aria-label]="'CART.HEADER.QTY_WCAG' | translate"
          >
            {{ 'CART.HEADER.QTY' | translate }}
          </div>
        </div>

        <div class="total-header">
          {{ 'CART.HEADER.TOTAL' | translate }}
        </div>
      </div>
    </div>

    <div class="header-spacer" *ngIf="displayHeaderSpacer"></div>
  </div>
</div>
