import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { NaooConstants } from '../../shared/NaooConstants';
import { inject } from '@angular/core';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import { ProductDetailsMainComponent } from '../product-details-main/product-details-main.component';
import { MaterialInfoFacade } from '../../core/store/material-info/material-info.facade';
import { SessionFacade } from '../../core/store/session/session.facade';
import { MaterialInfo } from '../../shared/models/material-info';
import { MaterialDetailsFacade } from '../../core/store/material-details/material-details.facade';
import { EcommerceAnalyticsFacade } from '../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { AuxiliaryAnalyticsData } from '../../core/services/ecommerce-analytics/models/google-events';

export interface ProductDetailsData {
  materialInfo: MaterialInfo;
  analytics?: AuxiliaryAnalyticsData;
}

export const productDetailsResolver: ResolveFn<ProductDetailsData> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<ProductDetailsData> => {
  const materialInfoFacade = inject(MaterialInfoFacade);
  const router = inject(Router);
  const defaultDialogService = inject(DefaultDialogService);
  const sessionFacade = inject(SessionFacade);
  const materialDetailsFacade = inject(MaterialDetailsFacade);
  const ecommerceAnalyticsFacade = inject(EcommerceAnalyticsFacade);
  const materialNumbers = [route.paramMap.get('materialNumber')];

  return sessionFacade.isCustomerUpdated().pipe(
    filter((isUpdated) => isUpdated),
    tap(() => materialInfoFacade.loadMaterialInfos(materialNumbers)),
    switchMap(() =>
      materialInfoFacade.getLoadedMaterialInfos(materialNumbers).pipe(
        first(),
        catchError((err) => {
          defaultDialogService.defaultErrorModal(
            ProductDetailsMainComponent.LOAD_PRODUCT_INFO_FAILURE_TAG
          );
          return throwError(err);
        }),
        map((materialInfos) => {
          const materialInfo = materialInfos[0];
          if (!materialInfo || materialInfo.isEmpty) {
            router.navigateByUrl(NaooConstants.INFO_UNAVAILABLE_PATH);
          } else {
            materialDetailsFacade.loadOnMaterialDetailsPage(materialInfo);
            ecommerceAnalyticsFacade.trackMaterialDetailsViewEvent(
              materialInfo.materialNumber
            );

            const { analytics } =
              router.getCurrentNavigation()?.extras?.state ?? {};

            return {
              materialInfo,
              analytics,
            };
          }
        })
      )
    ),
    first()
  );
};
