import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { MygfsNutritionService } from '../../services/mygfs-nutrition/mygfs-nutrition.service';
import { Observable, of } from 'rxjs';
import { MygfsNutritionActions } from './mygfs-nutrition.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { selectAllMygfsNutritionRecords } from './mygfs-nutrition.selectors';
import { MygfsNutritionRecordStatus } from './mygfs-nutrition.state';
import { MygfsNutritionTransformerService } from './mygfs-nutrition-transformer.service';
import { PicRecord } from '../../services/mygfs-nutrition/models/pic-record';
import { SharedActions } from '../shared/shared.actions';
import {
  selectCustomerBrand,
  selectIsFeatureEnabled,
} from '../session/session.selectors';
import { CustomerBrand } from '../../services/session/models/session-record';

@Injectable()
export class MygfsNutritionEffects {
  private readonly newNutritionFeatureFlag = 'new-us-nutrition';

  constructor(
    private actions$: Actions,
    private store: Store,
    private mygfsNutritionService: MygfsNutritionService,
    private mygfsNutritionTransformer: MygfsNutritionTransformerService
  ) {}

  loadMygfsNutrition$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MygfsNutritionActions.loadMygfsNutrition),
      concatLatestFrom(() => [
        this.store.select(selectAllMygfsNutritionRecords),
        this.store.select(selectCustomerBrand),
      ]),
      mergeMap(([action, mygfsNutritionRecords, customerBrand]) => {
        const queuedMaterialNumbers = action.materialNumbers.filter(
          (materialNumber) =>
            MygfsNutritionRecordStatus.Queued ===
            mygfsNutritionRecords[materialNumber]?.status
        );
        if (
          CustomerBrand.GFS_US !== customerBrand ||
          !queuedMaterialNumbers.length
        ) {
          return of(
            SharedActions.noOperation(
              'Mygfs Nutrition for material numbers are not in queued status'
            )
          );
        }
        const dispatchActions: Action[] = [];
        queuedMaterialNumbers.forEach((materialNumber) =>
          dispatchActions.push(
            MygfsNutritionActions.getMygfsNutrition(materialNumber)
          )
        );
        return dispatchActions;
      })
    );
  });

  getMygfsNutrition$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MygfsNutritionActions.getMygfsNutrition),
      concatLatestFrom(() => [
        this.store.select(selectAllMygfsNutritionRecords),
        this.store.select(selectIsFeatureEnabled(this.newNutritionFeatureFlag)),
      ]),
      mergeMap(
        ([action, mygfsNutritionRecords, isNewNutritionFeatureEnabled]) => {
          const materialNumber = action.materialNumber;
          if (
            mygfsNutritionRecords[materialNumber].status !==
            MygfsNutritionRecordStatus.Requested
          ) {
            return of(
              SharedActions.noOperation(
                'Mygfs Nutrition for material number not in requested status'
              )
            );
          }

          if (isNewNutritionFeatureEnabled) {
            return this.mygfsNutritionService.getNutrition(materialNumber).pipe(
              map((record) =>
                MygfsNutritionActions.getMygfsNutritionSuccess(
                  materialNumber,
                  this.mygfsNutritionTransformer.transformPicRecords([record])
                )
              ),
              catchError(() =>
                of(MygfsNutritionActions.getMygfsNutritionError(materialNumber))
              ),
              takeUntil(
                this.actions$.pipe(
                  ofType(MygfsNutritionActions.clearMygfsNutrition)
                )
              )
            );
          }

          return this.mygfsNutritionService
            .getMygfsNutrition(materialNumber)
            .pipe(
              switchMap((records: PicRecord[]) =>
                of(
                  MygfsNutritionActions.getMygfsNutritionSuccess(
                    materialNumber,
                    this.mygfsNutritionTransformer.transformPicRecords(records)
                  )
                )
              ),
              catchError(() =>
                of(MygfsNutritionActions.getMygfsNutritionError(materialNumber))
              ),
              takeUntil(
                this.actions$.pipe(
                  ofType(MygfsNutritionActions.clearMygfsNutrition)
                )
              )
            );
        }
      )
    );
  });
}
