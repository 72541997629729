import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { materialAdditionalInfoReducer } from './material-additional-info.reducer';
import { MaterialAdditionalInfoEffects } from './material-additional-info.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'materialAdditionalInfo',
      materialAdditionalInfoReducer
    ),
    EffectsModule.forFeature([MaterialAdditionalInfoEffects]),
  ],
})
export class MaterialAdditionalInfoStoreModule {}
