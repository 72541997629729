import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DeliverySchedulesRecord } from './models/delivery-schedules-record';

@Injectable({ providedIn: 'root' })
export class DeliveryScheduleService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService
  ) {}

  getDeliverySchedules(): Observable<DeliverySchedulesRecord> {
    return this.httpClient.get<DeliverySchedulesRecord>(
      this.webBffService.getBff() + '/api/v3/delivery-schedules'
    );
  }
}
