import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'naoo-add-to-guide-toast',
  templateUrl: './add-to-guide-toast.component.html',
  styleUrls: ['./add-to-guide-toast.component.scss'],
})
export class AddToGuideToastComponent implements OnInit {
  message: string;
  customGuideId: string;
  customGuideName: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
      customGuideId?: string;
      customGuideName?: string;
    },
    private router: Router
  ) {}

  ngOnInit() {
    this.message = this.data.message;
    this.customGuideId = this.data.customGuideId;
    this.customGuideName = this.data.customGuideName;
  }

  navigate() {
    if (this.customGuideId) {
      this.router.navigate(['guides', 'custom-guide', this.customGuideId]);
    } else {
      this.router.navigate(['guides', 'order-guide']);
    }
  }
}
