import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { MultipleCartsActions } from './multiple-carts.actions';
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { ErrorActions } from '../error/error.actions';
import { MultipleCartsService } from '../../services/multiple-carts/multiple-carts.service';
import { selectCartEntity } from '../cart/cart.selectors';
import { Router } from '@angular/router';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import { ToastMessageService } from '../../../shared/services/toast-message/toast-message.service';
import { NaooConstants } from '../../../shared/NaooConstants';
import { CartSummaryTab } from '../../../cart/cart-summary/cart-summary-container.component';
import { selectActiveCustomer } from '../session/session.selectors';
import {
  MultipleCartErrorRefresh,
  MultipleCartsUtilities,
} from './shared/multiple-carts-utility';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { FulfillmentModalService } from '../../../shared/services/fulfillment-modal/fulfillment-modal.service';
import { EcommerceAnalyticsFacade } from '../ecommerce-analytics/ecommerce-analytics.facade';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { ReOrderService } from '../../../re-order/re-order.service';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { SavedCart } from './multiple-carts.state';
import { SalesCriticalItemsFacade } from '../sales-critical-items/sales-critical-items.facade';

@Injectable()
export class MultipleCartsEffects {
  private readonly throttleTime = 15000;
  private readonly activeTabParameter = 'activeTab';

  // eslint-disable-next-line max-params
  constructor(
    private actions$: Actions,
    private store: Store,
    private multipleCartsService: MultipleCartsService,
    private defaultDialogService: DefaultDialogService,
    private customDialogService: CustomDialogService,
    private toastMessageService: ToastMessageService,
    private localizationService: LocalizationService,
    private reOrderService: ReOrderService,
    private router: Router,
    private utilities: MultipleCartsUtilities,
    private fulfillmentModalService: FulfillmentModalService,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private salesCriticalItemsFacade: SalesCriticalItemsFacade
  ) {}

  throttledRefreshSavedCarts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultipleCartsActions.throttledRefreshSavedCarts),
      throttleTime(this.throttleTime),
      map(() => MultipleCartsActions.refreshSavedCarts())
    );
  });

  refreshSavedCarts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultipleCartsActions.refreshSavedCarts),
      map((action) =>
        MultipleCartsActions.getSavedCarts(action.promptForApprover)
      )
    );
  });

  getSavedCarts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultipleCartsActions.getSavedCarts),
      switchMap((action) => {
        return this.multipleCartsService.getSavedCarts().pipe(
          map((multipleCartsRecord) => {
            return MultipleCartsActions.getSavedCartsSuccess(
              multipleCartsRecord,
              action.promptForApprover
            );
          }),
          catchError((error) =>
            of(MultipleCartsActions.getSavedCartsError(error))
          ),
          takeUntil(
            this.actions$.pipe(ofType(MultipleCartsActions.refreshSavedCarts))
          )
        );
      })
    );
  });

  getSavedCartsSuccessAction$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MultipleCartsActions.getSavedCartsSuccess),
        concatLatestFrom(() => this.store.select(selectActiveCustomer)),
        map(([action, currentCustomer]) => {
          if (action.promptForApprover && currentCustomer) {
            this.utilities.notifyCartsSubmittedForApproval(
              action.multipleCartsRecord,
              currentCustomer
            );
          }
        })
      );
    },
    { dispatch: false }
  );

  getSavedCartsError$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultipleCartsActions.getSavedCartsError),
      map((action) => ErrorActions.fatalError(action.error))
    );
  });

  saveActiveCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultipleCartsActions.saveActiveCart),
      tap(() => this.ecommerceAnalyticsFacade.trackSavedCartEvent(false)),
      concatLatestFrom(() => this.store.select(selectCartEntity)),
      switchMap(([action, cart]) =>
        this.utilities.serviceCall(
          () => {
            return this.multipleCartsService.saveActiveCart(cart.id);
          },
          () =>
            this.router
              .navigate([NaooConstants.CART_PATH], {
                relativeTo: null,
                queryParams: {
                  [this.activeTabParameter]: action.redirectToSavedCarts
                    ? CartSummaryTab.SavedCarts
                    : CartSummaryTab.ActiveCart,
                },
                replaceUrl: true,
              })
              .then(() => {
                this.toastMessageService.showToastMessage(
                  this.localizationService.instant(
                    'CART.MOVED_TO_SAVED_CARTS_TOAST_SUCCESS'
                  )
                );

                let closeFunction;
                if (action.materialsToBeAddedToCart) {
                  this.reOrderService.addMaterialsToCart(
                    action.materialsToBeAddedToCart
                  );
                  closeFunction = () => {
                    this.toastMessageService.showCartImportToastMessage(
                      action.materialsToBeAddedToCart.length,
                      true
                    );
                  };
                }

                this.fulfillmentModalService.openFulfillmentModal(
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  closeFunction
                );
                if (action.cartActionType) {
                  this.customDialogService.importOrderModal(
                    action.cartActionType
                  );
                }
              }),
          false,
          MultipleCartErrorRefresh.Cart
        )
      )
    );
  });

  submitCartForApproval$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultipleCartsActions.submitCartForApproval),
      concatLatestFrom(() => this.store.select(selectCartEntity)),
      switchMap(([_, cart]) =>
        this.utilities.serviceCall(
          () => {
            return this.multipleCartsService.submitCartForApproval(cart.id);
          },
          () =>
            this.router
              .navigate([NaooConstants.CART_PATH], {
                relativeTo: null,
                queryParams: {
                  [this.activeTabParameter]: CartSummaryTab.SavedCarts,
                },
                replaceUrl: true,
              })
              .then(() =>
                this.toastMessageService.showToastMessage(
                  this.localizationService.instant(
                    'CART.SUBMITTED_CARTS.SUBMITTED_FOR_APPROVAL_TOAST_SUCCESS'
                  )
                )
              ),
          false,
          MultipleCartErrorRefresh.All,
          () =>
            this.router.navigate([NaooConstants.CART_PATH], {
              relativeTo: null,
              queryParams: {
                [this.activeTabParameter]: CartSummaryTab.ActiveCart,
              },
              replaceUrl: true,
            })
        )
      )
    );
  });

  activateSavedCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultipleCartsActions.activateSavedCart),
      tap(() => this.ecommerceAnalyticsFacade.trackSavedCartEvent(true)),
      switchMap((action) =>
        this.utilities.serviceCall(
          () => {
            return this.multipleCartsService.activateSavedCart(
              action.savedCart.id,
              action.shouldSaveActiveCart
            );
          },
          () =>
            this.router
              .navigate([NaooConstants.CART_PATH], {
                relativeTo: null,
                queryParams: {
                  [this.activeTabParameter]: CartSummaryTab.ActiveCart,
                },
                replaceUrl: true,
              })
              .then(() => {
                this.defaultDialogService.closeLoadingModal();

                if (this.openFulfillmentModal(action.savedCart)) {
                  this.fulfillmentModalService.openFulfillmentModal(
                    false,
                    true
                  );
                }
                this.toastMessageService.showCartImportToastMessage(
                  action.savedCart.totalQuantity
                );
                this.salesCriticalItemsFacade.refreshSalesCriticalItems();
              }),
          true,
          MultipleCartErrorRefresh.SavedCarts,
          undefined,
          action.mergeWithCurrentCart
        )
      )
    );
  });

  deleteSavedCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultipleCartsActions.deleteSavedCart),
      switchMap((action) =>
        this.utilities.serviceCall(() => {
          return this.multipleCartsService.deleteSavedCart(action.cartId);
        })
      )
    );
  });

  private openFulfillmentModal(savedCart: SavedCart): boolean {
    const now = new Date();

    switch (savedCart.fulfillmentType) {
      case FulfillmentType.TRUCK:
        return now.getTime() > savedCart.truckFulfillment?.routeDate?.getTime();
      case FulfillmentType.EXPRESS:
        const deliveryTime = new Date(
          savedCart.storeFulfillment?.deliveryWindowStartTimestamp
        );
        return now.getTime() > deliveryTime.getTime();
      case FulfillmentType.PICKUP:
        now.setHours(0, 0, 0, 0);
        const pickupTime = new Date(
          savedCart.storeFulfillment?.requestedPickupTimestamp
        );
        pickupTime.setHours(0, 0, 0, 0);
        return now.getTime() > pickupTime.getTime();
      default:
        return true;
    }
  }
}
