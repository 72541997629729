import { createActionGroup, emptyProps } from '@ngrx/store';
import { NutritionLabel } from './models/mygfs-nutrition';

export const MygfsNutritionActions = createActionGroup({
  source: 'MygfsNutrition',
  events: {
    'Load Mygfs Nutrition': (materialNumbers: string[]) => ({
      materialNumbers,
    }),
    'Get Mygfs Nutrition': (materialNumber: string) => ({ materialNumber }),
    'Get Mygfs Nutrition Success': (
      materialNumber: string,
      nutritionLabel: NutritionLabel
    ) => ({
      materialNumber,
      nutritionLabel,
    }),
    'Get Mygfs Nutrition Error': (materialNumber: string) => ({
      materialNumber,
    }),
    'Clear Mygfs Nutrition': emptyProps(),
  },
});
