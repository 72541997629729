import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CartFacade } from '../../core/store/cart/cart.facade';
import { filter, first } from 'rxjs/operators';

export const cartLoadedGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<boolean> => {
  const cartFacade = inject(CartFacade);
  return cartFacade.isCartLoaded().pipe(
    filter((isCartLoaded: boolean) => isCartLoaded),
    first()
  );
};
