import { Language } from 'src/app/core/services/session/models/session-record';

const collators: Map<Language, Intl.Collator> = new Map();
Object.keys(Language).forEach((lang: Language) =>
  collators.set(lang, new Intl.Collator(lang))
);

export function getCollator(language: Language): Intl.Collator {
  return collators.get(language) ?? collators.get(Language.en);
}
