import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaterialRow } from '../../../../core/store/material-row/models/material-row';
import { CustomDialogService } from '../../../../shared/services/dialog/custom-dialog/custom-dialog.service';

@Component({
  selector: 'naoo-material-similar-items',
  templateUrl: './material-similar-items.component.html',
  styleUrls: ['./material-similar-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialSimilarItemsComponent {
  @Input() isMobile: boolean;
  @Input() materialWithSubstitutes: MaterialRow;

  constructor(private customDialogService: CustomDialogService) {}

  onClick() {
    this.customDialogService.materialRelatedModal(
      undefined,
      this.materialWithSubstitutes,
      this.isMobile
    );
  }
}
