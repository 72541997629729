import { CartMaterialState } from '../cart.state';
import { UnitInfoRecord } from '../../../services/material-info/models/materials-info-record';
import { CartQuantityUpdate } from '../cart.actions';
import { getQuantityFromCartMaterial } from '../../../../shared/utilities/cart-material-utilities';
import { UnitAvailabilityRecord } from '../../../services/material-availability/model/material-availabilities-record';
import {
  findLargestSellableUom,
  findSecondLargestSellableUom,
} from '../../../../shared/utilities/material-units-utilities';
import { MaterialRowContext } from '../../material-row/models/material-row';

export class CartRollupTransformer {
  public static getCartRollupUpdate(
    materialNumber: string,
    cartMaterialState: CartMaterialState,
    infoRecords: UnitInfoRecord[],
    availabilities: UnitAvailabilityRecord[],
    context: MaterialRowContext
  ): CartQuantityUpdate {
    const primaryUom = findLargestSellableUom(infoRecords, availabilities);
    const secondaryUom = findSecondLargestSellableUom(
      infoRecords,
      availabilities,
      primaryUom
    );

    const actualPrimaryQuantity = getQuantityFromCartMaterial(
      cartMaterialState,
      primaryUom
    );
    const actualSecondaryQuantity = getQuantityFromCartMaterial(
      cartMaterialState,
      secondaryUom
    );

    const equivalentQuantity = this.getRollupQuantity(
      primaryUom,
      secondaryUom,
      infoRecords
    );

    const rolledUpPrimaryQuantity = Math.trunc(
      actualSecondaryQuantity / equivalentQuantity
    );

    if (!rolledUpPrimaryQuantity) {
      return undefined;
    }

    const rolledUpSecondaryQuantity =
      rolledUpPrimaryQuantity * equivalentQuantity;
    const remainingSecondaryQuantity =
      actualSecondaryQuantity - rolledUpSecondaryQuantity;
    const totalPrimaryQuantity =
      actualPrimaryQuantity + rolledUpPrimaryQuantity;

    if (totalPrimaryQuantity > 999) {
      return undefined;
    }

    return {
      materialNumber: materialNumber,
      lines: [
        {
          uom: primaryUom,
          quantity: totalPrimaryQuantity,
        },
        {
          uom: secondaryUom,
          quantity: remainingSecondaryQuantity,
        },
      ],
      isRollUpConversion: true,
      isAddedFromCriticalItem: cartMaterialState.isAddedFromCriticalItem,
      isAddedFromMaterialComparison:
        cartMaterialState.isAddedFromMaterialComparison,
      context,
    };
  }

  private static getRollupQuantity(
    primaryUom: string,
    secondaryUom: string,
    conversions: UnitInfoRecord[]
  ): number {
    let baseInfoRecord = conversions.find(
      (record) => record.uom === secondaryUom
    );
    if (!baseInfoRecord) {
      return undefined;
    }
    let equivalentQuantity = +baseInfoRecord.qtyInParent;

    while (baseInfoRecord.parentUom !== primaryUom) {
      const parentInfoRecord = conversions.find(
        (record) => record.uom === baseInfoRecord.parentUom
      );
      equivalentQuantity *= +parentInfoRecord.qtyInParent;
      baseInfoRecord = parentInfoRecord;
    }
    return equivalentQuantity;
  }
}
