<div
  *ngIf="isArrowVisible"
  class="arrow-up"
  [ngClass]="{ 'arrow-up-urgent': warning?.urgent }"
  [style.right]="arrowOffset"
></div>
<div
  class="warning-alert"
  [ngClass]="{
    'rounded-corners': isInContainer,
    'warning-urgent': warning?.urgent,
    'added-padding': !isArrowVisible
  }"
>
  <span class="warning-message"
    ><span *ngIf="warning?.title"
      ><strong
        >{{ warning?.title | translate: warning?.getTitleParams() }}:
      </strong></span
    >{{ warning?.message | translate: warning?.getMessageParams() }}</span
  >
  <button
    *ngIf="warning?.actionMessage"
    (focus)="hasFocus = true"
    (blur)="hasFocus = false"
    (click)="dismissWarning()"
    class="action-button"
  >
    {{ warning?.actionMessage | translate }}
  </button>
</div>
