import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import {
  catchError,
  delayWhen,
  first,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { defaultIfEmpty, Observable, of } from 'rxjs';
import { MaterialInfoActions } from './material-info.actions';
import { selectAllMaterialInfoRecordStates } from './material-info.selectors';
import { MaterialInfoRecordStatus } from './material-info.state';
import { MaterialInfoService } from '../../services/material-info/material-info.service';
import { ErrorActions } from '../error/error.actions';
import { selectIsOnlineCartLoaded } from '../cart/cart.selectors';
import { selectListMaterialNumbers } from '../list/list.selectors';
import { SharedActions } from '../shared/shared.actions';
import { chunkArray } from '../../../shared/utilities/array-utilities';

@Injectable()
export class MaterialInfoEffects {
  private readonly maxMaterialsPerRequest = 1000;

  constructor(
    private actions$: Actions,
    private store: Store,
    private materialInfoService: MaterialInfoService
  ) {}

  loadMaterialInfo$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialInfoActions.loadMaterialInfo),
      concatLatestFrom(() =>
        this.store.select(selectAllMaterialInfoRecordStates)
      ),
      mergeMap(([action, storedMaterialInfos]) => {
        const queuedMaterialNumbers = action.materialNumbers.filter(
          (materialNumber) =>
            storedMaterialInfos[materialNumber].status ===
            MaterialInfoRecordStatus.Queued
        );

        if (queuedMaterialNumbers.length < this.maxMaterialsPerRequest) {
          return of(MaterialInfoActions.getMaterialInfo(queuedMaterialNumbers));
        }

        const dispatchActions: Action[] = [];
        chunkArray(
          queuedMaterialNumbers,
          this.maxMaterialsPerRequest
        ).forEach((idBatch) =>
          dispatchActions.push(MaterialInfoActions.getMaterialInfo(idBatch))
        );

        return dispatchActions;
      })
    );
  });

  getMaterialInfo$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialInfoActions.getMaterialInfo),
      delayWhen(() =>
        this.store.select(selectIsOnlineCartLoaded).pipe(
          first((isOnlineCartLoaded) => isOnlineCartLoaded),
          takeUntil(
            this.actions$.pipe(ofType(MaterialInfoActions.clearMaterialInfo))
          ),
          defaultIfEmpty(true)
        )
      ),
      concatLatestFrom(() => [
        this.store.select(selectAllMaterialInfoRecordStates),
        this.store.select(selectIsOnlineCartLoaded),
      ]),
      mergeMap(([action, storedMaterialInfos, isOnlineCartLoaded]) => {
        if (!isOnlineCartLoaded) {
          return of(
            SharedActions.noOperation(
              'getMaterialInfo$: online cart is not loaded'
            )
          );
        }
        const requestedMaterialNumbers = action.materialNumbers.filter(
          (materialNumber) =>
            storedMaterialInfos[materialNumber].status ===
            MaterialInfoRecordStatus.Requested
        );
        if (requestedMaterialNumbers.length === 0) {
          return of(
            SharedActions.noOperation('MaterialInfo items are being requested')
          );
        }

        return this.materialInfoService
          .getMaterialsInfoRecord(requestedMaterialNumbers)
          .pipe(
            switchMap((materialInfoRecords) => {
              const successMaterialNumbers = materialInfoRecords.map(
                (record) => record.materialNumber
              );
              const unavailableMaterialNumbers = requestedMaterialNumbers.filter(
                (requestedMaterialNumber) =>
                  !successMaterialNumbers.includes(requestedMaterialNumber)
              );

              const dispatchedActions: Action[] = [
                MaterialInfoActions.getMaterialInfoSuccess(materialInfoRecords),
              ];
              if (unavailableMaterialNumbers.length > 0) {
                dispatchedActions.push(
                  MaterialInfoActions.getMaterialInfoUnavailable(
                    unavailableMaterialNumbers
                  )
                );
              }
              return dispatchedActions;
            }),
            catchError((error) => {
              return of(
                MaterialInfoActions.getMaterialInfoError(
                  requestedMaterialNumbers
                ),
                ErrorActions.fatalError(error, 'product info list')
              );
            }),
            takeUntil(
              this.actions$.pipe(ofType(MaterialInfoActions.clearMaterialInfo))
            )
          );
      })
    );
  });

  refreshListInfo$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialInfoActions.refreshListMaterialInfo),
      concatLatestFrom(() => this.store.select(selectListMaterialNumbers)),
      mergeMap(([_, listMaterials]) =>
        of(MaterialInfoActions.loadMaterialInfo(listMaterials))
      )
    );
  });
}
