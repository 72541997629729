import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NaooSessionStorage } from '../../../shared/storage/session-storage/session-storage.service';
import { Observable } from 'rxjs';
import { MaterialRowActions } from './material-row.actions';
import { map } from 'rxjs/operators';
import { MaterialListStyle } from './models/material-row';
import { SharedActions } from '../shared/shared.actions';

@Injectable()
export class MaterialRowEffects {
  private readonly preferredMaterialViewKey = 'preferredMaterialView';

  constructor(
    private actions$: Actions,
    private naooSessionStorage: NaooSessionStorage
  ) {}

  updatePreferredMaterialView$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MaterialRowActions.updatePreferredMaterialView),
        map((action) => {
          this.naooSessionStorage.setItem(
            this.preferredMaterialViewKey,
            action.viewType
          );
        })
      );
    },
    { dispatch: false }
  );

  hydratePreferredMaterialView$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialRowActions.hydratePreferredMaterialView),
      map(() => {
        const loadMaterialView = this.naooSessionStorage.getItem(
          this.preferredMaterialViewKey
        );

        return loadMaterialView
          ? MaterialRowActions.updatePreferredMaterialView(
              loadMaterialView as MaterialListStyle
            )
          : SharedActions.noOperation(
              'No materialView found in session storage'
            );
      })
    );
  });
}
