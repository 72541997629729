import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MaterialOrderingInfo,
  MaterialRow,
  MaterialRowContext,
} from '../../core/store/material-row/models/material-row';
import { MaterialRowFacade } from '../../core/store/material-row/material-row.facade';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WarningOpenDirection } from '../../core/store/material-warning/material-warning';

@Component({
  selector: 'naoo-complimentary-product',
  templateUrl: './complimentary-product.component.html',
  styleUrls: ['./complimentary-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplimentaryProductComponent implements OnInit, OnDestroy {
  @Input() materialNumber: string;

  get currentOrderingInfo(): MaterialOrderingInfo {
    return this.materialRow.materialOrdering?.materialOrderingInfos?.[
      this.selectedIndex
    ];
  }

  selectedIndex: number = 0;
  materialRow: MaterialRow;

  readonly warningDirection: WarningOpenDirection = WarningOpenDirection.Up;
  private destroyed$ = new Subject<void>();

  constructor(
    private materialRowFacade: MaterialRowFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.materialRowFacade
      .getMaterialRow({
        context: MaterialRowContext.ComplimentaryProduct,
        materialNumber: this.materialNumber,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((materialRow) => {
        this.materialRow = materialRow;
        this.changeDetectorRef.markForCheck();
      });
  }

  chipSelectionChange(index: number): void {
    this.selectedIndex = index;
    this.changeDetectorRef.markForCheck();
  }

  trackByDisplayCode(
    _: number,
    materialOrderingInfo: MaterialOrderingInfo
  ): string {
    return materialOrderingInfo.displayCode;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
