import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IndicatorListsActions } from './indicator-lists.actions';
import { IndicatorListsService } from '../../services/indicator-lists/indicator-lists.service';

@Injectable()
export class IndicatorListsEffects {
  constructor(
    private actions$: Actions,
    private indicatorlistService: IndicatorListsService
  ) {}

  refreshIndicatorLists$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(IndicatorListsActions.refreshIndicatorLists),
      map(() => {
        return IndicatorListsActions.getAllIndicatorLists();
      })
    );
  });

  getIndicatorLists$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(IndicatorListsActions.getAllIndicatorLists),
      mergeMap(() => {
        return this.indicatorlistService.getIndicatorLists().pipe(
          map((indicatorLists) =>
            IndicatorListsActions.getAllIndicatorListsSuccess(indicatorLists)
          ),
          catchError(() =>
            of(IndicatorListsActions.getAllIndicatorListFailure())
          )
        );
      })
    );
  });
}
