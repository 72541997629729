import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  selectIsInOrderGuide,
  selectIsOrderGuideEditable,
  selectLoadedOrderGuideMaterialNumbers,
  selectOrderGuide,
  selectOrderGuideExportMaterialsInput,
  selectOrderGuideUnsavedChanges,
  selectOrderGuideV1,
} from './order-guide.selectors';
import { OrderGuideV1 } from './order-guide.state';
import { OrderGuide } from './models/order-guide';
import { SortByType } from '../../../guides/shared/guides';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { MaterialAdditionalInfoFacade } from '../material-additional-info/material-additional-info.facade';
import { selectCurrentSystem } from '../session/session.selectors';
import { OrderGuideActions } from './order-guide.actions';

@Injectable({ providedIn: 'root' })
export class OrderGuideFacade {
  constructor(
    private store: Store,
    private materialAdditionalInfoFacade: MaterialAdditionalInfoFacade
  ) {}

  getLoadedCategorizedOrderGuide(): Observable<OrderGuideV1> {
    return this.store
      .select(selectOrderGuideV1)
      .pipe(filter((loadedOrderGuide) => !!loadedOrderGuide));
  }

  isInOrderGuide(materialNumber: string): Observable<boolean> {
    return this.store.select(selectIsInOrderGuide(materialNumber));
  }

  isOrderGuideEditable(): Observable<boolean> {
    return this.store.select(selectIsOrderGuideEditable);
  }

  isEditOrderGuidePageAccessible(): Observable<boolean> {
    return combineLatest([this.store.select(selectCurrentSystem)]).pipe(
      map(([_]) => {
        return false;
      })
    );
  }

  updateSortBy(sortBy: SortByType) {
    this.store.dispatch(OrderGuideActions.updateOrderGuideSortBy(sortBy));
  }

  getOrderGuide(): Observable<OrderGuide> {
    return this.store.select(selectOrderGuide);
  }

  getLoadedOrderGuideMaterials(): Observable<Set<string>> {
    return this.store.select(selectLoadedOrderGuideMaterialNumbers);
  }

  updateSearchText(searchText: string) {
    this.store.dispatch(
      OrderGuideActions.updateOrderGuideSearchText(searchText)
    );
  }

  getExportMaterialsInput(isPrint: boolean): Observable<ExportMaterialsInput> {
    return this.store.select(selectOrderGuideExportMaterialsInput(isPrint));
  }

  addItemToOrderGuide(materialNumber: string) {
    this.materialAdditionalInfoFacade.loadMaterialAdditionalInfos([
      materialNumber,
    ]);
    this.materialAdditionalInfoFacade
      .getLoadedMaterialAdditionalInfo(materialNumber)
      .subscribe((additionalInfo) =>
        this.store.dispatch(
          OrderGuideActions.addItemToOrderGuide(
            materialNumber,
            additionalInfo?.categoryLevel1.en
          )
        )
      );
  }

  removeItemFromOrderGuide(materialNumber: string) {
    this.store.dispatch(
      OrderGuideActions.removeItemFromOrderGuide(materialNumber)
    );
  }

  removeItemFromOrderGuideInLocal(materialNumber: string) {
    this.store.dispatch(
      OrderGuideActions.removeOrderGuideMaterialsInLocal(materialNumber)
    );
  }

  createOrderGuideCategory(name: string): void {
    this.store.dispatch(OrderGuideActions.createOrderGuideCategory(name));
  }

  renameOrderGuideCategory(categoryIndex: number, name: string): void {
    this.store.dispatch(
      OrderGuideActions.renameOrderGuideCategory(categoryIndex, name)
    );
  }

  removeOrderGuideCategory(categoryIndex: number): void {
    this.store.dispatch(
      OrderGuideActions.removeOrderGuideCategory(categoryIndex)
    );
  }

  moveOrderGuideCategory(
    originalCategoryIndex: number,
    newCategoryIndex: number
  ): void {
    this.store.dispatch(
      OrderGuideActions.moveOrderGuideCategory(
        originalCategoryIndex,
        newCategoryIndex
      )
    );
  }

  moveOrderGuideMaterials(
    materialNumber: string,
    newCategoryIndex: number,
    newMaterialIndex: number
  ): void {
    this.store.dispatch(
      OrderGuideActions.moveOrderGuideMaterials(
        materialNumber,
        newCategoryIndex,
        newMaterialIndex
      )
    );
  }

  reloadOrderGuide() {
    this.store.dispatch(OrderGuideActions.refreshOrderGuide());
  }

  saveOrderGuideChanges() {
    this.store.dispatch(OrderGuideActions.updateOrderGuide());
  }

  hasUnsavedChanges(): Observable<boolean> {
    return this.store.select(selectOrderGuideUnsavedChanges);
  }

  removeEmptyCategories() {
    this.store.dispatch(OrderGuideActions.removeEmptyCategories());
  }
}
