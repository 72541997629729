import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  materialAdditionalInfoAdapter,
  MaterialAdditionalInfoRecordState,
  MaterialAdditionalInfoRecordStatus,
  MaterialAdditionalInfoState,
} from './material-additional-info.state';
import {
  MaterialAdditionalInfoRecord,
  VendorInfoRecord,
} from '../../services/material-additional-info/models/material-additional-infos-record';
import {
  selectCustomerBrand,
  selectLanguage,
} from '../session/session.selectors';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { selectAllCombinedPricingRecords } from '../material-price/material-price.selectors';
import {
  CustomerBrand,
  Language,
} from '../../services/session/models/session-record';
import { Dictionary } from '@ngrx/entity';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { CombinedPricingRecord } from '../material-price/material-price.util';
import {
  ComparisonAttributeDetails,
  ComparisonAttributesViewModel,
  VisibleDimensions,
} from '../../../material-comparison/material-column/shared/models/comparison-attributes';
import {
  AllergenInfo,
  NutritionalInfo,
} from '../../../shared/models/material-additional-info';
import { NaooConstants } from '../../../shared/NaooConstants';
import { selectMygfsNutritionRecordDictionary } from '../mygfs-nutrition/mygfs-nutrition.selectors';
import { MygfsNutritionRecordState } from '../mygfs-nutrition/mygfs-nutrition.state';
import { NutritionLabel } from '../mygfs-nutrition/models/mygfs-nutrition';

const selectFeature = createFeatureSelector<MaterialAdditionalInfoState>(
  'materialAdditionalInfo'
);

export const selectMaterialAdditionalInfosRecord = createSelector(
  selectFeature,
  materialAdditionalInfoAdapter.getSelectors().selectEntities
);

export const selectMaterialAdditionalInfoRecordState = (
  materialNumber: string
) => {
  return createSelector(
    selectMaterialAdditionalInfosRecord,
    (materialAdditionalInfoRecords): MaterialAdditionalInfoRecordState =>
      materialAdditionalInfoRecords[materialNumber]
  );
};

const LoadingCompleteStatuses = [
  MaterialAdditionalInfoRecordStatus.Error,
  MaterialAdditionalInfoRecordStatus.Success,
];

export const selectLoadedMaterialAdditionalInfoRecordState = (
  materialNumber: string
) => {
  return createSelector(
    selectMaterialAdditionalInfosRecord,
    (materialAdditionalInfoRecords): MaterialAdditionalInfoRecordState => {
      const materialAdditionalInfoRecord =
        materialAdditionalInfoRecords[materialNumber];
      return LoadingCompleteStatuses.includes(
        materialAdditionalInfoRecord?.status
      )
        ? materialAdditionalInfoRecord
        : undefined;
    }
  );
};

export const selectMaterialAdditionalInfoRecordStates = (
  materialNumbers: string[]
) => {
  return createSelector(
    selectMaterialAdditionalInfosRecord,
    (materialAdditionalInfoRecords): MaterialAdditionalInfoRecordState[] =>
      materialNumbers
        .map((materialNumber) => materialAdditionalInfoRecords[materialNumber])
        .filter((record) => record)
  );
};

export const selectMaterialVendorInfoRecord = (materialNumber: string) => {
  return createSelector(
    selectMaterialAdditionalInfoRecordState(materialNumber),
    (materialAdditionalInfoRecord): VendorInfoRecord | undefined =>
      materialAdditionalInfoRecord?.record?.vendorInfo
  );
};

export const selectComparisonAttributesViewModel = (
  materialNumbers: string[]
) =>
  createSelector(
    selectLanguage,
    selectAllMaterialInfoRecordStates,
    selectMaterialAdditionalInfosRecord,
    selectAllCombinedPricingRecords(false, materialNumbers),
    selectCustomerBrand,
    selectMygfsNutritionRecordDictionary(materialNumbers),
    (
      language: Language,
      materialInfoRecordStates: Dictionary<MaterialInfoRecordState>,
      materialAdditionalInfoRecordStates: Dictionary<MaterialAdditionalInfoRecordState>,
      combinedPricingRecords: Dictionary<CombinedPricingRecord>,
      customerBrand: CustomerBrand,
      mygfsNutritionRecords: Dictionary<MygfsNutritionRecordState>
    ) => {
      const materialDetailsMap = new Map<string, ComparisonAttributeDetails>();
      materialNumbers.forEach((materialNumber) => {
        const materialInfo = materialInfoRecordStates?.[materialNumber]?.record;
        const materialAdditionalInfo =
          materialAdditionalInfoRecordStates?.[materialNumber]?.record;
        const allergenInfoRecord = materialAdditionalInfo?.allergenInfo;
        const allergenInfo =
          allergenInfoRecord &&
          Object.values(allergenInfoRecord).some((value) => value)
            ? <AllergenInfo>{ ...allergenInfoRecord }
            : undefined;
        const combinedPricingRecord = combinedPricingRecords?.[materialNumber];
        const caseUnit = combinedPricingRecord?.unitPrices?.find(
          (unit) => NaooConstants.Uom.Case === unit.salesUom
        );
        let nutritionalInfo: NutritionalInfo = undefined;
        let nutritionLabel: NutritionLabel = undefined;
        if (CustomerBrand.GFS_US === customerBrand) {
          nutritionLabel = mygfsNutritionRecords[materialNumber]?.record;
        } else {
          const hasNutritionRecords = Object.values(
            materialAdditionalInfo?.nutritionalInfo || []
          ).some((value) => value);
          nutritionalInfo = hasNutritionRecords
            ? <NutritionalInfo>{ ...materialAdditionalInfo.nutritionalInfo }
            : undefined;
        }

        materialDetailsMap.set(materialNumber, {
          allergenInfo,
          attributes: materialAdditionalInfo?.materialAttributes
            ?.filter((attribute) => attribute?.name?.[language])
            ?.map((attribute) => attribute.name[language])
            ?.sort(),
          baseUomWeight: materialInfo?.baseUomWeight,
          brand: materialAdditionalInfo?.vendorInfo?.displayName,
          dimensions: getDimensions(materialAdditionalInfo),
          ingredients: materialAdditionalInfo?.ingredients?.[language],
          nutritionalInfo,
          packagingStorage:
            materialAdditionalInfo?.packagingStorage?.[language],
          price: materialInfo?.isCatchWeight
            ? caseUnit?.catchWeightPrice
            : caseUnit?.price / +materialInfo?.baseUomWeight?.net,
          sellingBulletPoints: materialAdditionalInfo?.sellingBulletPoints?.[
            language
          ]?.filter((i) => i),
          sellingParagraphs: materialAdditionalInfo?.sellingParagraphs?.[
            language
          ]?.filter((i) => i),
          servingSuggestions:
            materialAdditionalInfo?.servingSuggestions?.[language],
          weightUom: combinedPricingRecord?.weightUom,
          nutritionLabel,
        });
      });
      return <ComparisonAttributesViewModel>{
        materialDetailsMap,
      };
    }
  );

export function getDimensions(
  materialAdditionalInfo: MaterialAdditionalInfoRecord
): VisibleDimensions {
  const dimensions = materialAdditionalInfo?.materialDimensions;
  if (!dimensions || dimensions?.unit?.toLowerCase() !== 'in') {
    return undefined;
  }
  const inchesToCm = 2.54;
  const validDimensions = [
    dimensions.height,
    dimensions.width,
    dimensions.depth,
  ]
    .map((dimension) => Number(dimension))
    .filter((dimension) => dimension > 0);

  if (validDimensions.length < 2) {
    return undefined;
  }
  return {
    dimensionsIn: validDimensions
      .map((dimension) => dimension.toFixed(2))
      .join(' x '),
    dimensionsCm: validDimensions
      .map((dimension) => (dimension * inchesToCm).toFixed(2))
      .join(' x '),
  };
}
