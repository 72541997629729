import { Injectable } from '@angular/core';
import { OpenSpecialOrderItemsService } from '../../services/open-special-order-items/open-special-order-items.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { OpenSpecialOrderItemsActions } from './open-special-order-items.actions';

@Injectable()
export class OpenSpecialOrderItemsEffects {
  constructor(
    private actions$: Actions,
    private openSpecialOrderItemsService: OpenSpecialOrderItemsService
  ) {}

  getOpenSpecialOrderItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpenSpecialOrderItemsActions.getOpenSpecialOrderItems),
      mergeMap(() =>
        this.openSpecialOrderItemsService.getOpenSpecialOrderItems().pipe(
          mergeMap((openSpecialOrderItems) => [
            OpenSpecialOrderItemsActions.getOpenSpecialOrderItemsSuccess({
              openSpecialOrderMaterialNumbers:
                openSpecialOrderItems.openSpecialOrderMaterialNumbers,
            }),
          ]),
          catchError(() =>
            of(OpenSpecialOrderItemsActions.getOpenSpecialOrderItemsError())
          ),
          takeUntil(
            this.actions$.pipe(
              ofType(OpenSpecialOrderItemsActions.refreshOpenSpecialOrderItems)
            )
          )
        )
      )
    );
  });

  refreshOpenSpecialOrderItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpenSpecialOrderItemsActions.refreshOpenSpecialOrderItems),
      map(() => OpenSpecialOrderItemsActions.getOpenSpecialOrderItems())
    );
  });
}
