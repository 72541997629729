import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MaterialRecord,
  MaterialsInfoRecord,
} from './models/materials-info-record';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MaterialInfoService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService
  ) {}

  getMaterialsInfoRecord(
    materialNumbers: string[]
  ): Observable<MaterialRecord[]> {
    return this.httpClient
      .post<MaterialsInfoRecord>(
        this.webBffService.getBff() + '/api/v1/materials/info',
        materialNumbers
      )
      .pipe(map((materialInfo) => materialInfo.materialInfos));
  }
}
