import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ExportModalDataPoint } from '../../../models/export-modal-data-point';
import { TranslatedDataPoint } from '../export-modal.component';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';

@Component({
  selector: 'naoo-export-selected-data',
  templateUrl: './export-selected-data.component.html',
  styleUrls: ['./export-selected-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportSelectedDataComponent implements OnChanges {
  @Input() selectedDataPoints: ExportModalDataPoint[];
  @Input() isPrintModal: boolean;
  @Input() totalDataPoints: number;
  @Output() removedItems = new EventEmitter<ExportModalDataPoint[]>();
  @Output() updatedOrderItems = new EventEmitter<ExportModalDataPoint[]>();

  translatedDataPoints: TranslatedDataPoint[];
  disabled: boolean;

  constructor(private localizationService: LocalizationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedDataPoints']) {
      this.update();
    }
  }

  removeAll() {
    this.selectedDataPoints.forEach((item) => (item.isSelected = false));
    this.updateSelected();
  }

  remove(item: ExportModalDataPoint) {
    item.isSelected = false;
    this.updateSelected();
  }

  updateSelected() {
    this.removedItems.emit(
      this.selectedDataPoints.filter((item) => !item.isSelected)
    );
  }

  update() {
    this.translatedDataPoints = this.selectedDataPoints.map((listItem) => {
      return {
        name: this.localizationService.instant(listItem.label),
        dataPoint: listItem,
      };
    });
    this.disabled = this.selectedDataPoints.length === 0;
  }

  moveUp(index: number) {
    if (index < 1) {
      return;
    }
    this.move(index, index - 1);
  }

  moveDown(index: number) {
    if (index > this.translatedDataPoints.length - 1) {
      return;
    }
    this.move(index, index + 1);
  }

  private move(index: number, newIndex: number) {
    const movingItem = this.translatedDataPoints[newIndex];
    this.translatedDataPoints.splice(
      newIndex,
      1,
      this.translatedDataPoints[index]
    );
    this.translatedDataPoints.splice(index, 1, movingItem);
    this.updateOrder();
  }

  private updateOrder() {
    this.updatedOrderItems.emit(
      this.translatedDataPoints.map((item) => item.dataPoint)
    );
  }
}
