import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerMaterialState } from './customer-material.state';
import { CustomerMaterialRecord } from '../../services/customer-material/model/customer-material-record';

const selectCustomerMaterialState = createFeatureSelector<CustomerMaterialState>(
  'customerMaterial'
);

export const selectCustomerMaterialRecord = createSelector(
  selectCustomerMaterialState,
  (state) => state.customerMaterialRecord
);

export const selectCustomerMaterialInfo = (materialNumber: string) =>
  createSelector(
    selectCustomerMaterialRecord,
    (record) => record[materialNumber]
  );

export const selectCustomerMaterialRecords = (materialNumbers: string[]) =>
  createSelector(selectCustomerMaterialRecord, (record) => {
    const selectedCustomerMaterialRecord: CustomerMaterialRecord = {};
    materialNumbers.forEach((materialNumber) => {
      selectedCustomerMaterialRecord[materialNumber] = record[materialNumber];
    });

    return selectedCustomerMaterialRecord;
  });

export const selectHasCustomerMaterial = createSelector(
  selectCustomerMaterialRecord,
  (customerMaterialRecord) => !!Object.keys(customerMaterialRecord).length
);
