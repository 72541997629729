import { createActionGroup } from '@ngrx/store';
import { MaterialCutoffRecord } from '../../services/material-cutoff/models/material-cutoff-record';
import { CurrentSystem } from '../../services/session/models/session-record';

export const MaterialCutoffActions = createActionGroup({
  source: 'Material Cutoff',
  events: {
    'Load Material Cutoffs': (
      currentSystem: CurrentSystem,
      cutoffCodes?: string[]
    ) => ({ currentSystem, cutoffCodes }),
    'Get Material Cutoffs': (
      currentSystem: CurrentSystem,
      cutoffCodes?: string[]
    ) => ({ currentSystem, cutoffCodes }),
    'Get Material Cutoffs Success': (
      currentSystem: CurrentSystem,
      materialCutoffRecord: MaterialCutoffRecord
    ) => ({ currentSystem, materialCutoffRecord }),
    'Get Material Cutoffs Error': (
      currentSystem: CurrentSystem,
      cutoffCodes?: string[]
    ) => ({ currentSystem, cutoffCodes }),
    'Refresh Material Cutoffs': (currentSystem: CurrentSystem) => ({
      currentSystem,
    }),
    'Clear Material Cutoffs': (currentSystem: CurrentSystem) => ({
      currentSystem,
    }),
  },
});
