import { Injectable } from '@angular/core';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomerMaterialRecord } from './model/customer-material-record';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CustomerMaterialService {
  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService
  ) {}

  getCustomerMaterial(): Observable<CustomerMaterialRecord> {
    return this.httpClient
      .get<CustomerMaterialResponse>(
        `${this.webBffService.getBff()}/api/v1/customer-material`
      )
      .pipe(map((response) => response.materialInfo));
  }
}

interface CustomerMaterialResponse {
  materialInfo: CustomerMaterialRecord;
}
