import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  StoreMapMarkers,
  GoogleMapsNaooService,
} from '../service/google-maps.service';
import LatLngLiteral = google.maps.LatLngLiteral;
import { StoreRecord } from '../../../core/services/store/model/store-record';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'naoo-google-maps-container',
  templateUrl: './google-maps-container.component.html',
  styleUrls: ['./google-maps-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleMapsNaooContainerComponent implements OnInit {
  @Input() selectedStorePlantId: string;
  @Input() customerTimeZone: string;
  @Input() isMobileWidth: boolean;
  @Output() selectedStorePlantIdEmitter = new EventEmitter<string>();

  readonly hasLoaded$: Observable<boolean> = this.googleMapsService.hasLoaded();
  readonly userLatLong$: Observable<LatLngLiteral> = this.googleMapsService.getUserLatLongLiteral();
  readonly storeMapMarkers$: Observable<StoreMapMarkers> = this.googleMapsService.getStoreMapMarkers();

  selectedAddressLatLong: LatLngLiteral;
  visibleStoreRecords: StoreRecord[] = [];

  constructor(
    private googleMapsService: GoogleMapsNaooService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    this.googleMapsService.initialize();
  }

  selectStorePlantId(storePlantId: string): void {
    this.selectedStorePlantIdEmitter.emit(storePlantId);
    this.focusIndexPosition(0);
  }

  selectLatLong(latLong: LatLngLiteral): void {
    this.selectedAddressLatLong = latLong;
  }

  updateVisibleStores(storeRecords: StoreRecord[]): void {
    this.visibleStoreRecords = storeRecords;
    this.changeDetectorRef.markForCheck();
  }

  trackByStorePlantId(index: number, storeRecord: StoreRecord): string {
    return storeRecord.storePlantId;
  }

  focusIndexPosition(indexPosition: number): void {
    const allInputs = this.queryForAllInputs();
    const element = allInputs[indexPosition];
    if (element) {
      element.focus();
    }
  }

  private queryForAllInputs(): HTMLInputElement[] {
    return Array.from(
      this._document.getElementsByClassName('store-select')
    ).filter((x) => {
      return x.getBoundingClientRect().height > 0;
    }) as HTMLInputElement[];
  }
}
