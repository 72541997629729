import { Dictionary } from '@ngrx/entity';
import { LastOrderedRecordState } from '../last-ordered/last-ordered.state';
import { createMaterialListRow } from '../material-row/models/material-row';
import {
  hasLastOrderedFinishedLoading,
  lastOrderedComparator,
} from '../last-ordered/last-ordered.util';
import { MaterialRelatedRecordState } from './material-related.state';
import { MaterialRelated } from './models/material-related';
import { MaterialListRow } from '../../../material-list/models/material-list';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';

export function transformMaterialRelated(
  materialNumber: string,
  relatedRecords: Dictionary<MaterialRelatedRecordState>,
  lastOrderRecords: Dictionary<LastOrderedRecordState>,
  availabilityRecords: Dictionary<MaterialAvailabilityRecordState>
): MaterialRelated {
  const relatedRecord = relatedRecords[materialNumber]
    ? relatedRecords[materialNumber].record
    : undefined;

  if (!relatedRecord || !relatedRecords[materialNumber].hasLoaded) {
    return {
      materialNumber,
      isLoading: true,
      availableRelated: [],
    };
  }

  const filteredSubstitutes: string[] = filterOrderableMaterials(
    relatedRecord.substitutes,
    availabilityRecords
  );
  const filteredSimilar: string[] = filterOrderableMaterials(
    relatedRecord.similar,
    availabilityRecords
  );

  const filteredLastOrderRecordStates: LastOrderedRecordState[] = filteredSimilar.map(
    (relatedMaterialNumber) => lastOrderRecords[relatedMaterialNumber]
  );
  const hasLoaded = filteredLastOrderRecordStates.every((recordState) =>
    hasLastOrderedFinishedLoading(recordState)
  );

  if (!hasLoaded) {
    return {
      materialNumber,
      isLoading: true,
      availableRelated: removeSubstitutesFromSimilarAndCombine(
        filteredSubstitutes,
        filteredSimilar
      ),
    };
  }

  filteredLastOrderRecordStates.sort(lastOrderedComparator);

  const related = removeSubstitutesFromSimilarAndCombine(
    filteredSubstitutes,
    filteredLastOrderRecordStates.map((record) => record.materialNumber)
  );

  return {
    materialNumber,
    isLoading: false,
    availableRelated: related,
  };
}

function removeSubstitutesFromSimilarAndCombine(
  substituteMaterials: string[],
  similarMaterials: string[]
): MaterialListRow[] {
  const similarWithoutSubstitutes = similarMaterials.filter(
    (materialNumber) => !substituteMaterials.includes(materialNumber)
  );

  return [...substituteMaterials, ...similarWithoutSubstitutes].map(
    createMaterialListRow
  );
}

function filterOrderableMaterials(
  materials: string[],
  availabilityRecords: Dictionary<MaterialAvailabilityRecordState>
): string[] {
  return materials.filter((relatedMaterial) => {
    return (
      !!availabilityRecords[relatedMaterial] &&
      !!availabilityRecords[relatedMaterial].record &&
      availabilityRecords[relatedMaterial].record.isOrderable
    );
  });
}
