import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from './router-state-serializer';
import { Params } from '@angular/router';

export const selectRouterState = createSelector(
  (state: AppState) => state.router,
  (value) => value
);

export const selectCurrentUrl = createSelector(
  selectRouterState,
  (state: RouterReducerState<RouterStateUrl>): string | undefined =>
    state && state.state && state.state.url
);

export const selectCurrentPathName = createSelector(
  selectCurrentUrl,
  (currentUrl: string) => {
    if (!currentUrl) {
      return undefined;
    }

    const queryParamIndex = currentUrl.indexOf('?');
    return queryParamIndex === -1
      ? currentUrl
      : currentUrl.substring(0, queryParamIndex);
  }
);

export const selectRouteParams = createSelector(
  selectRouterState,
  (state: RouterReducerState<RouterStateUrl>): Params | undefined =>
    state?.state?.params
);

export const selectMaterialNumberFromRouteParams = createSelector(
  selectRouteParams,
  (params: Params): string | undefined => {
    if (params?.hasOwnProperty('materialNumber')) {
      return params.materialNumber as string;
    }
    return undefined;
  }
);
