<naoo-material-list-header
  class="material-list-header"
  [context]="context"
  [listStyle]="listStyle"
  [isParEnabled]="isParEnabled"
  [isSlimView]="isSlimView"
  [isLoyaltyProgramEligible]="isLoyaltyProgramEligible$ | async"
  [displayGoPointsLogo]="displayGoPointsLogo"
  *ngIf="(!isMobile || shouldRenderPrint) && shouldRenderHeader"
>
</naoo-material-list-header>

<div #rowContainers class="not-printable">
  <virtual-scroller
    #scroll
    [items]="materialListItems"
    [bufferAmount]="virtualScrollBuffer$ | async"
    [scrollAnimationTime]="virtualScrollAnimationTime"
    [useMarginInsteadOfTranslate]="true"
    [parentScroll]="parentScroll"
    [childHeight]="rowHeight$ | async"
    (vsEnd)="vsEnd($event)"
    [ngClass]="{ 'centered-grid': isGridView }"
    [firstItemMargin]="firstItemMargin$ | async"
  >
    <ng-container
      *ngFor="
        let viewPortItem of scroll.viewPortItems;
        trackBy: trackByMaterialRowValue;
        last as isLast;
        first as isFirst
      "
    >
      <ng-container
        *ngIf="castToMaterialListRow(viewPortItem) as materialListItem"
      >
        <naoo-material-row-container
          *ngIf="materialListItem.type === materialRowType; else categoryHeader"
          [materialNumber]="materialListItem.value"
          [context]="context"
          [listStyle]="listStyle"
          [isLast]="isLast"
          [isFirst]="isFirst"
          [isMobile]="isMobile"
          [customGuideId]="customGuideId"
          [displayGoPointsLogo]="true"
          [analytics]="getAnalytics(materialListItem)"
        >
        </naoo-material-row-container>

        <ng-template #categoryHeader>
          <naoo-material-category-header
            [categoryHeader]="materialListItem.value"
            [listStyle]="listStyle"
            [context]="context"
            [isMobile]="isMobile"
            [isFirst]="
              isFirst && scroll.viewPortInfo.startIndexWithBuffer === 0
            "
            [isParEnabled]="isParEnabled"
            [isLoyaltyProgramEligible]="isLoyaltyProgramEligible$ | async"
            (categoryJump)="scrollToIndex($event)"
          ></naoo-material-category-header>
        </ng-template>
      </ng-container>
    </ng-container>
  </virtual-scroller>
</div>

<div class="print-list" *ngIf="shouldRenderPrint">
  <naoo-material-row-container
    *ngFor="
      let materialListItem of materialListItems;
      trackBy: trackByMaterialRowValue;
      last as isLast;
      first as isFirst
    "
    [materialNumber]="materialListItem.value"
    [context]="context"
    [listStyle]="listStyle"
    [isLast]="isLast"
    [isFirst]="isFirst"
    [isMobile]="false"
    [analytics]="getAnalytics(materialListItem)"
  >
  </naoo-material-row-container>
</div>
