<ng-container>
  <button
    class="language-button"
    [matMenuTriggerFor]="appMenu"
    attr.aria-label="{{
      'MENU.LANGUAGE_MENU_SELECTED'
        | translate: { value: getSelectedLanguageDisplayName() }
    }}"
    (menuClosed)="isMenuOpen = false"
    (menuOpened)="isMenuOpen = true"
    [disabled]="isDisabled"
  >
    <mat-icon
      class="language-icon"
      [ngClass]="{
        disabled: isDisabled
      }"
      svgIcon="language_icon"
    ></mat-icon>
    <div
      class="button-text"
      [ngClass]="{
        disabled: isDisabled
      }"
    >
      {{ getSelectedLanguageDisplayName() }}
    </div>
    <mat-icon
      class="down-arrow"
      [ngClass]="{ 'rotate-arrow': isMenuOpen }"
      svgIcon="arrow-v2"
      iconPositionEnd
    ></mat-icon>
  </button>
  <mat-menu #appMenu="matMenu">
    <button
      class="mat-menu-item"
      attr.aria-label="{{ language.display }}"
      mat-menu-item
      *ngFor="let language of languages"
      (click)="setLanguage(language.lang)"
    >
      {{ language.display }}
    </button>
  </mat-menu>
</ng-container>
