import { createActionGroup, emptyProps } from '@ngrx/store';
import { MultipleCartsRecord } from '../../services/multiple-carts/models/multiple-carts-record';
import { CartActionType } from '../../../shared/bulk-add-modal/bulk-add-modal.component';
import { CartQuantityUpdate } from '../cart/cart.actions';
import { SavedCart } from './multiple-carts.state';

export const MultipleCartsActions = createActionGroup({
  source: 'MultipleCarts',
  events: {
    'Throttled Refresh Saved Carts': emptyProps(),
    'Refresh Saved Carts': (promptForApprover?: boolean) => ({
      promptForApprover,
    }),
    'Get Saved Carts': (promptForApprover?: boolean) => ({
      promptForApprover,
    }),
    'Get Saved Carts Success': (
      multipleCartsRecord: MultipleCartsRecord,
      promptForApprover?: boolean
    ) => ({
      multipleCartsRecord,
      promptForApprover,
    }),
    'Get Saved Carts Error': (error: unknown) => ({
      error,
    }),
    'Save Active Cart': (
      redirectToSavedCarts: boolean = true,
      cartActionType?: CartActionType,
      materialsToBeAddedToCart?: CartQuantityUpdate[]
    ) => ({
      redirectToSavedCarts,
      cartActionType,
      materialsToBeAddedToCart,
    }),
    'Submit Cart For Approval': emptyProps(),
    'Activate Saved Cart': (
      savedCart: SavedCart,
      shouldSaveActiveCart: boolean,
      mergeWithCurrentCart: boolean = false
    ) => ({
      savedCart,
      shouldSaveActiveCart,
      mergeWithCurrentCart,
    }),
    'Delete Saved Cart': (cartId: string) => ({ cartId }),
  },
});
