import { createSelector } from '@ngrx/store';
import { selectFulfillmentSelectionNotCompleted } from '../cart/cart.selectors';
import {
  selectHasDeliverySchedulesFinishedLoading,
  selectRouteDateExpired,
} from '../delivery-schedule/delivery-schedule.selectors';
import {
  selectExpressScheduleExpired,
  selectHasExpressSchedulesLoaded,
} from '../express-schedules/express-schedules.selectors';
import {
  selectCurrentPickupLoaded,
  selectPickupScheduleExpired,
} from '../pickup-schedules/pickup-schedules.selectors';
import { selectCurrentUrl } from '../router/router.selectors';

export const selectFulfillmentDataLoaded = createSelector(
  selectHasDeliverySchedulesFinishedLoading,
  selectHasExpressSchedulesLoaded,
  selectCurrentPickupLoaded,
  selectCurrentUrl,
  (
    deliverySchedulesFinishedLoading,
    expressSchedulesLoaded,
    currentPickupLoaded,
    currentUrl
  ): boolean =>
    deliverySchedulesFinishedLoading &&
    expressSchedulesLoaded &&
    currentPickupLoaded &&
    !!currentUrl
);

export const selectRequiresFulfillmentChange = createSelector(
  selectFulfillmentDataLoaded,
  selectFulfillmentSelectionNotCompleted,
  selectRouteDateExpired,
  selectExpressScheduleExpired,
  selectPickupScheduleExpired,
  (
    hasLoaded,
    fulfillmentSelectionNotCompleted,
    isRouteDateExpired,
    isExpressScheduleExpired,
    isPickupScheduleExpired
  ): boolean | undefined =>
    hasLoaded
      ? fulfillmentSelectionNotCompleted ||
        !!isRouteDateExpired ||
        !!isExpressScheduleExpired ||
        !!isPickupScheduleExpired
      : undefined
);
