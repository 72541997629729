import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { PickupSchedulesActions } from './pickup-schedules.actions';
import { PickupSchedulesStatus } from './pickup-schedules.state';
import { PickupSchedulesService } from '../../services/pickup-schedules/pickup-schedules.service';
import { selectAllPickupSchedulesStateEntities } from './pickup-schedules.selectors';
import { SharedActions } from '../shared/shared.actions';

@Injectable()
export class PickupSchedulesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private pickupSchedulesService: PickupSchedulesService
  ) {}

  loadPickupSchedules$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(PickupSchedulesActions.loadPickupSchedules),
      concatLatestFrom(() =>
        this.store.select(selectAllPickupSchedulesStateEntities)
      ),
      mergeMap(([action, storedSchedules]) => {
        const queuedStorePlantIds: string[] = action.storePlantIds.filter(
          (storePlantId) =>
            storedSchedules[storePlantId]?.status ===
            PickupSchedulesStatus.Queued
        );
        if (queuedStorePlantIds.length < 1) {
          return of(
            SharedActions.noOperation(
              'Already requested all pickup schedules data'
            )
          );
        }
        return of(
          PickupSchedulesActions.getPickupSchedules(queuedStorePlantIds)
        );
      })
    );
  });

  getPickupSchedules$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(PickupSchedulesActions.getPickupSchedules),
      concatLatestFrom(() =>
        this.store.select(selectAllPickupSchedulesStateEntities)
      ),
      mergeMap(([action, storedPickupSchedules]) => {
        const requestedStorePlantIds = action.storePlantIds.filter(
          (plantId) =>
            storedPickupSchedules[plantId].status ===
            PickupSchedulesStatus.Requested
        );
        if (requestedStorePlantIds.length < 1) {
          return of(
            SharedActions.noOperation(
              'Already requested all pickup schedules data'
            )
          );
        }
        return this.pickupSchedulesService
          .getPickupSchedules(requestedStorePlantIds)
          .pipe(
            map((pickupSchedulesMap) =>
              PickupSchedulesActions.getPickupSchedulesSuccess(
                pickupSchedulesMap
              )
            ),
            catchError(() => {
              return of(
                PickupSchedulesActions.getPickupSchedulesFailure(
                  requestedStorePlantIds
                )
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(PickupSchedulesActions.clearPickupSchedules)
              )
            )
          );
      })
    );
  });
}
