import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialAdditionalInfoRecord } from '../../services/material-additional-info/models/material-additional-infos-record';

export interface MaterialAdditionalInfoState
  extends EntityState<MaterialAdditionalInfoRecordState> {}

export interface MaterialAdditionalInfoRecordState {
  materialNumber: string;
  status: MaterialAdditionalInfoRecordStatus;
  record?: MaterialAdditionalInfoRecord;
}

export enum MaterialAdditionalInfoRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const materialAdditionalInfoAdapter = createEntityAdapter<MaterialAdditionalInfoRecordState>(
  {
    selectId: (state) => state.materialNumber,
  }
);

export const initialMaterialAdditionalInfoState = materialAdditionalInfoAdapter.getInitialState();
