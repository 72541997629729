import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NaooConstants } from '../../../shared/NaooConstants';
import { CustomDimension } from '../../../shared/analytics/custom-dimension';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { MaterialComparisonFacade } from '../../../core/store/material-comparison/material-comparison.facade';
import { maximumMaterialComparisons } from '../../../core/store/material-comparison/material-comparison.selectors';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'naoo-compare-docket-content',
  templateUrl: './compare-docket-content.component.html',
  styleUrls: ['./compare-docket-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareDocketContentComponent implements OnInit {
  @Input() shouldShowCompareDocket: boolean;
  @Input() isCollapsed: boolean;
  @Input() materialComparisonNumbers: string[];

  isCurrentPathComparison: boolean;

  readonly maxProductCount = maximumMaterialComparisons;

  private readonly productComparisonPath = NaooConstants.PRODUCT_COMPARISON;
  private readonly comparisonAnalyticsEvent: AnalyticsEventInfo = {
    action: 'click',
    category: 'product comparison',
    label: 'compare docket',
  };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private materialComparisonFacade: MaterialComparisonFacade,
    private analytics: NaooAnalyticsManager,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.isCurrentPathComparison =
          event.urlAfterRedirects === this.productComparisonPath;
        this.changeDetectorRef.markForCheck();
      });
  }

  toggleExpandedState(): void {
    this.materialComparisonFacade.toggleCollapseMaterialComparison(
      !this.isCollapsed
    );
  }

  clearAll(): void {
    this.materialComparisonFacade.removeAllMaterialComparison();
  }

  navigateToProductComparison(): void {
    this.materialComparisonFacade.toggleCollapseMaterialComparison(true);

    this.handleProductComparisonAnalytics();
    this.router.navigate([this.productComparisonPath]);
  }

  private handleProductComparisonAnalytics(): void {
    const customDimension37: CustomDimension = {
      index: 37,
      value: this.materialComparisonNumbers.toString(),
    };

    this.analytics.trackAnalyticsEvent(this.comparisonAnalyticsEvent, [
      customDimension37,
    ]);
  }
}
