import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { InventoryAvailabilityService } from '../../services/inventory-availability/inventory-availability.service';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { selectInventoryAvailabilityRecordStates } from './inventory-availability.selectors';
import { InventoryAvailabilityRecordStatus } from './inventory-availability.state';
import { selectAllCartMaterials } from '../cart/cart.selectors';
import { InventoryAvailabilityActions } from './inventory-availability.actions';
import { SharedActions } from '../shared/shared.actions';

@Injectable()
export class InventoryAvailabilityEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private inventoryAvailabilityService: InventoryAvailabilityService
  ) {}

  loadCartInventoryAvailability$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryAvailabilityActions.loadCartInventoryAvailability),
      concatLatestFrom(() => this.store.select(selectAllCartMaterials)),
      map(([action, cartMaterials]) => {
        const materialNumbers = cartMaterials.map(
          (cartMaterial) => cartMaterial.materialNumber
        );
        const itemIds = action.materialNumbers.filter(
          (itemId) => materialNumbers.indexOf(itemId) !== -1
        );
        return InventoryAvailabilityActions.loadInventoryAvailability(itemIds);
      })
    );
  });

  loadInventoryAvailability$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryAvailabilityActions.loadInventoryAvailability),
      concatLatestFrom(() =>
        this.store.select(selectInventoryAvailabilityRecordStates)
      ),
      mergeMap(([action, inventoryAvailabilityStates]) => {
        const queuedItemIds = action.materialNumbers.filter(
          (itemId) =>
            inventoryAvailabilityStates[itemId].status ===
            InventoryAvailabilityRecordStatus.Queued
        );
        return of(
          InventoryAvailabilityActions.getInventoryAvailability(queuedItemIds)
        );
      })
    );
  });

  getInventoryAvailability$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryAvailabilityActions.getInventoryAvailability),
      concatLatestFrom(() =>
        this.store.select(selectInventoryAvailabilityRecordStates)
      ),
      mergeMap(([action, inventoryAvailabilityStates]) => {
        const requestedItemIds = action.materialNumbers.filter(
          (itemId) =>
            inventoryAvailabilityStates[itemId].status ===
            InventoryAvailabilityRecordStatus.Requested
        );
        if (requestedItemIds.length === 0) {
          return of(
            SharedActions.noOperation(
              'Inventory Availability items are being requested'
            )
          );
        }

        return this.inventoryAvailabilityService
          .getInventoryAvailabilities(requestedItemIds)
          .pipe(
            mergeMap((inventoryRecords) => {
              const successItemIds = inventoryRecords.map(
                (record) => record.materialNumber
              );
              const errorItemIds = requestedItemIds.filter(
                (requestedItemId) => !successItemIds.includes(requestedItemId)
              );

              const dispatchedActions: Action[] = [
                InventoryAvailabilityActions.getInventoryAvailabilitySuccess(
                  inventoryRecords
                ),
              ];
              if (errorItemIds.length > 0) {
                dispatchedActions.push(
                  InventoryAvailabilityActions.getInventoryAvailabilityError(
                    errorItemIds
                  )
                );
              }
              return dispatchedActions;
            }),
            catchError(() =>
              of(
                InventoryAvailabilityActions.getInventoryAvailabilityError(
                  requestedItemIds
                )
              )
            ),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  InventoryAvailabilityActions.refreshCartInventoryAvailability,
                  InventoryAvailabilityActions.clearInventoryAvailability
                )
              )
            )
          );
      })
    );
  });

  refreshCartInventoryAvailability$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryAvailabilityActions.refreshCartInventoryAvailability),
      concatLatestFrom(() => this.store.select(selectAllCartMaterials)),
      map(([_, cartMaterials]) => {
        return InventoryAvailabilityActions.loadInventoryAvailability(
          cartMaterials.map((cartMaterial) => cartMaterial.materialNumber)
        );
      })
    );
  });
}
