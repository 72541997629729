import { Component, OnDestroy, OnInit } from '@angular/core';
import moment, { ISO_8601 } from 'moment-timezone';
import { InvoiceCredit } from '../../../shared/models/invoice-credit';
import { InvoicesCreditsService } from '../../../shared/services/invoices-credits/invoices-credits.service';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  concatMap,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { LoadingService } from '../../../shared/services/loading-service/loading.service';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { ExportModalResponse } from '../../../shared/modals/export-modal/export-modal.component';
import { ExportModalDataPoint } from '../../../shared/models/export-modal-data-point';
import { InvoiceDetail } from '../../../shared/models/invoice-detail';
import { ExportInvoiceDetail } from '../../../shared/models/export/export-invoice-detail';
import { ExportService } from '../../../shared/services/export-service/export.service';
import { ToastMessageService } from '../../../shared/services/toast-message/toast-message.service';
import {
  ExportDataPoint,
  ExportFeatureType,
  ExportFileType,
} from '../../../shared/models/export/export-properties';
import { SessionFacade } from '../../../core/store/session/session.facade';
import { OrderLine } from '../../../shared/models/order-line';
import { DeviceIdentifierService } from 'src/app/shared/services/device-identifier/device-identifier.service';
import { formatDate } from '@angular/common';
import { dateFormats } from 'src/app/shared/utilities/date-utilities';
import { LocalizedUtilities } from 'src/app/shared/utilities/localized-utilities';
import {
  CurrentSystem,
  Language,
  Locale,
} from '../../../core/services/session/models/session-record';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { DateRange } from '../../../shared/date-range-selector/date-range';

@Component({
  selector: 'naoo-invoices-credits',
  templateUrl: './invoices-credits.component.html',
  styleUrls: ['./invoices-credits.component.scss'],
})
export class InvoicesCreditsComponent implements OnInit, OnDestroy {
  private static readonly DEFAULT_FILE_TYPES = [
    {
      name: ExportFileType.EXCEL,
      value: 'excel',
      isChecked: true,
      i18nTag: 'EXPORT_MODAL.EXCEL',
    },
    {
      name: ExportFileType.CSV,
      value: 'csv',
      isChecked: false,
      i18nTag: 'EXPORT_MODAL.CSV',
    },
  ];

  private static readonly DEFAULT_EXPORT_LIST = [
    {
      name: ExportDataPoint.ItemNumber,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.ITEM_NUMBER',
      isSelected: true,
    },
    {
      name: ExportDataPoint.ItemDescription,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.ITEM_DESCRIPTION',
      isSelected: true,
    },
    {
      name: ExportDataPoint.Pack,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PACK',
      isSelected: true,
    },
    {
      name: ExportDataPoint.PackUnit,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PACK_UNIT',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Size,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.SIZE',
      isSelected: true,
    },
    {
      name: ExportDataPoint.QuantityShipped,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QUANTITY_SHIPPED',
      isSelected: true,
    },
    {
      name: ExportDataPoint.CasePrice,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PRICE_CASE_UNIT',
      isSelected: true,
    },
    {
      name: ExportDataPoint.ExtendedPrice,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PRICE_EXTENDED',
      isSelected: true,
    },
    {
      name: ExportDataPoint.Gtin,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.GTIN',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Unit,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.UNIT',
      isSelected: false,
    },
    {
      name: ExportDataPoint.QuantityOrdered,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QUANTITY_ORDERED',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Brand,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.BRAND',
      isSelected: false,
    },
  ];

  private static readonly SAP_USER_EXPORT_LIST = [
    {
      name: ExportDataPoint.PurchaseUnit,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PURCHASE_UNIT',
      isSelected: false,
    },
    {
      name: ExportDataPoint.CatchWeight,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.CATCH_WEIGHT',
      isSelected: false,
    },
    {
      name: ExportDataPoint.TotalCatchWeightActualWeight,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.TOTAL_CATCH_WEIGHT_ACTUAL_WEIGHT',
      isSelected: false,
    },
  ];

  private static readonly TOAST_MESSAGE = 'EXPORT_MODAL.TOAST_MESSAGE';
  private static readonly INVOICE_EXPORT_FILE_NAME = 'EXPORT.EXPORT_FILE_NAME';

  private static readonly ANALYTICS_CATEGORY = 'Invoices/Credits Display';
  private static readonly ANALYTICS_CREDIT_LABEL = 'Credit PDF';
  private static readonly ANALYTICS_INVOICE_LABEL = 'Invoice PDF';
  private static readonly ANALYTICS_DEBIT_LABEL = 'Debit PDF';
  private static readonly ANALYTICS_ERROR_ACTION = 'Error';
  private static readonly ANALYTICS_ERROR_LABEL = 'Table Unavailable';
  private static readonly ANALYTICS_SELECT_ACTION = 'Selected';
  private static readonly ANALYTICS_SELECT_LABEL_LAST_100_DAYS =
    'Last 100 Days';
  private static readonly ANALYTICS_SELECT_MONTH_FORMAT = 'MMM';

  private readonly nonBreakingSpace = /[\u202F\u00A0]/;

  readonly ANALYTICS_VIEW_ACTION = 'View Document';
  readonly ANALYTICS_EXPORT_ACTION = 'Export';

  readonly INVOICES_PATH = InvoicesCreditsService.INVOICES_PATH;

  private translatedCreditString = '';
  private translatedInvoiceString = '';
  private translatedDebitString = '';
  private invoiceCredits$: Observable<InvoiceCredit[]>;
  private firstDateRangeLoad = true;

  searchText$ = new BehaviorSubject<string>('');
  private destroyed$ = new Subject<void>();
  selectedDateRange: DateRange;
  filteredInvoices$: Observable<InvoiceCredit[]>;
  isMobile$: Observable<boolean>;
  currentLocale: Locale;
  currentLanguage: Language;
  hasInvoicesCreditsError = false;

  constructor(
    private invoicesCreditsService: InvoicesCreditsService,
    private loadingService: LoadingService,
    private analyticsManager: NaooAnalyticsManager,
    private customDialogService: CustomDialogService,
    private exportService: ExportService,
    private toastMessageService: ToastMessageService,
    private localizationService: LocalizationService,
    private sessionFacade: SessionFacade,
    private deviceIdentifierService: DeviceIdentifierService
  ) {
    this.currentLocale = this.localizationService.currentLocale;
  }

  ngOnInit() {
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((locale) => {
        this.currentLocale = locale;
        this.currentLanguage = this.localizationService.currentLanguage;
        this.translatedCreditString = this.localizationService.instant(
          'ORDERS.INVOICES_CREDITS.CREDIT'
        );
        this.translatedInvoiceString = this.localizationService.instant(
          'ORDERS.INVOICES_CREDITS.INVOICE'
        );
        this.translatedDebitString = this.localizationService.instant(
          'ORDERS.INVOICES_CREDITS.DEBIT'
        );
      });

    this.isMobile$ = this.deviceIdentifierService.observeDeviceType();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onChangeDate(event?: DateRange) {
    this.selectedDateRange = event;

    if (this.firstDateRangeLoad) {
      this.firstDateRangeLoad = false;
    } else {
      this.trackAnalytics(
        InvoicesCreditsComponent.ANALYTICS_SELECT_ACTION,
        this.createDateChangeAnalyticsLabel()
      );
    }

    this.loadingService.start();
    this.hasInvoicesCreditsError = false;

    const startDate: Date = moment(
      this.selectedDateRange?.startDate,
      ISO_8601
    ).toDate();

    const endDate: Date =
      this.selectedDateRange?.label !== 'DATE_SELECTOR.LAST_100_DAYS'
        ? moment(this.selectedDateRange?.endDate, ISO_8601).toDate()
        : undefined;

    this.invoiceCredits$ = this.invoicesCreditsService
      .getHistory(startDate, endDate)
      .pipe(
        catchError(() => {
          this.hasInvoicesCreditsError = true;
          this.trackAnalytics(
            InvoicesCreditsComponent.ANALYTICS_ERROR_ACTION,
            InvoicesCreditsComponent.ANALYTICS_ERROR_LABEL
          );
          return EMPTY;
        }),
        finalize(() => {
          this.loadingService.stop();
        }),
        map((response) => (response?.invoices ? response.invoices : []))
      );

    this.filteredInvoices$ = combineLatest([
      this.invoiceCredits$,
      this.searchText$,
    ]).pipe(
      map(([invoices, searchText]) => {
        return invoices.filter((invoice) =>
          this.doesInvoicePassFilter(invoice, searchText)
        );
      })
    );
  }

  updateSearchText(searchText: string) {
    this.searchText$.next(searchText);
  }

  doesInvoicePassFilter(invoice: InvoiceCredit, searchText: string): boolean {
    if (!searchText) {
      return true;
    }

    const caseInsensitiveSearchText = searchText
      .toLowerCase()
      .replace(this.nonBreakingSpace, ' ');

    let invoiceTypeSearchableProperty: string;
    if (invoice.debitInvoice) {
      invoiceTypeSearchableProperty = this.translatedDebitString;
    } else {
      invoiceTypeSearchableProperty = invoice.creditOrder
        ? this.translatedCreditString
        : this.translatedInvoiceString;
    }

    const searchablePrice =
      LocalizedUtilities.getLocalizedPrice(
        this.currentLocale,
        invoice.invoiceAmount
      )?.replace(this.nonBreakingSpace, ' ') ?? '-';

    const searchableProperties = [
      formatDate(
        invoice.transactionDate,
        dateFormats[this.currentLocale].shortDate,
        this.currentLocale
      ),
      invoice.transactionNumber,
      invoice.poNumber != null ? invoice.poNumber : '',
      searchablePrice,
      invoice.invoiceAmount,
      invoiceTypeSearchableProperty,
    ].map((property) => property.toString().toLowerCase());

    return searchableProperties.some((property) =>
      property.includes(caseInsensitiveSearchText)
    );
  }

  handleExportAction(invoiceCredit: InvoiceCredit) {
    this.openExportModal(invoiceCredit);
  }

  sendAnalytics(invoiceCredit: InvoiceCredit, actionType: string): void {
    let eventLabel: string;
    if (invoiceCredit.debitInvoice) {
      eventLabel = InvoicesCreditsComponent.ANALYTICS_DEBIT_LABEL;
    } else {
      eventLabel = invoiceCredit.creditOrder
        ? InvoicesCreditsComponent.ANALYTICS_CREDIT_LABEL
        : InvoicesCreditsComponent.ANALYTICS_INVOICE_LABEL;
    }

    this.trackAnalytics(actionType, eventLabel);
  }

  openExportModal(invoiceCredit: InvoiceCredit) {
    let dataPoints: ExportModalDataPoint[];
    let fileFormat: ExportFileType;

    // openExportDialog return an observable of ExportModalResponse which contain the file format and the selected data points
    this.openExportDialog(invoiceCredit.creditOrder, invoiceCredit.debitInvoice)
      .pipe(
        first(),
        concatMap((exportModalResponse) => {
          if (!!exportModalResponse && !!exportModalResponse.dataPoints) {
            dataPoints = exportModalResponse.dataPoints;
            fileFormat = exportModalResponse.fileFormat;
            // we call to backend to get the invoice details and product Info
            return this.getInvoiceDetails(invoiceCredit);
          } else {
            // concatMap need to have a return of an observable. We use the null value in the subscribe
            // to check if export modal didn't return values
            return of(null);
          }
        })
      )
      .subscribe((invoiceDetail: InvoiceDetail) => {
        const invoiceExportData: ExportInvoiceDetail[] = [];
        if (invoiceDetail) {
          invoiceDetail.invoiceOrderLines.forEach((orderLine) =>
            invoiceExportData.push(...this.separateByOrderLine(orderLine))
          );
          const exportData = this.generateExportData(
            invoiceExportData,
            dataPoints
          );
          this.doExport(fileFormat, invoiceCredit, exportData);
        }
      });
  }

  /**
   * Call to getInvoiceDetails to get the orderLines with productInfo on it
   * @param invoiceCredit
   */
  private getInvoiceDetails(
    invoiceCredit: InvoiceCredit
  ): Observable<InvoiceDetail> {
    return this.invoicesCreditsService.getInvoiceDetails(
      invoiceCredit.transactionNumber.toString(),
      invoiceCredit.transactionDate
    );
  }

  private doExport(
    fileFormat: ExportFileType,
    invoiceCredit: InvoiceCredit,
    exportData: unknown[]
  ) {
    this.showToastMessage();
    switch (fileFormat) {
      case ExportFileType.CSV:
        this.exportService.downloadCsv(
          exportData,
          this.localizationService.instant(
            InvoicesCreditsComponent.INVOICE_EXPORT_FILE_NAME,
            { orderNumber: invoiceCredit.transactionNumber }
          )
        );
        break;
      case ExportFileType.EXCEL:
        this.exportService.downloadXlsx(
          exportData,
          this.localizationService.instant(
            InvoicesCreditsComponent.INVOICE_EXPORT_FILE_NAME,
            { orderNumber: invoiceCredit.transactionNumber }
          )
        );
        break;
      default:
        break;
    }
  }

  private showToastMessage() {
    this.toastMessageService.showToastMessage(
      this.localizationService.instant(InvoicesCreditsComponent.TOAST_MESSAGE)
    );
  }

  private generateExportData(
    exportInvoiceDetail: ExportInvoiceDetail[],
    dataPoints: ExportModalDataPoint[]
  ) {
    const exportData: unknown[] = [];
    exportData.push(this.getCsvHeaders(dataPoints));
    exportInvoiceDetail.forEach((item) => {
      const exportObject: string[] = [];
      dataPoints.forEach((data) => {
        const dataPoint = item.getItem(data.name);
        exportObject.push(dataPoint ? dataPoint.toString() : '');
      });
      exportData.push(exportObject);
    });
    return exportData;
  }

  private getCsvHeaders(dataPoints: ExportModalDataPoint[]): string[] {
    const headers: string[] = [];
    dataPoints.forEach((dataPoint) => {
      headers.push(this.localizationService.instant(dataPoint.label));
    });
    return headers;
  }

  private trackAnalytics(eventAction: string, eventLabel: string): void {
    const eventInfo: AnalyticsEventInfo = {
      action: eventAction,
      category: InvoicesCreditsComponent.ANALYTICS_CATEGORY,
      label: eventLabel,
    };

    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private createDateChangeAnalyticsLabel(): string {
    if (this.selectedDateRange?.label === 'DATE_SELECTOR.LAST_100_DAYS') {
      return InvoicesCreditsComponent.ANALYTICS_SELECT_LABEL_LAST_100_DAYS;
    }

    return `${this.selectedDateRange.year} ${moment(
      this.selectedDateRange.startDate
    ).format(
      InvoicesCreditsComponent.ANALYTICS_SELECT_MONTH_FORMAT
    )} - ${moment(this.selectedDateRange.endDate).format(
      InvoicesCreditsComponent.ANALYTICS_SELECT_MONTH_FORMAT
    )}`;
  }

  private openExportDialog(
    creditOrder: boolean,
    debitInvoice: boolean
  ): Observable<ExportModalResponse> {
    let title: string;
    let id: string;
    if (debitInvoice) {
      title = 'EXPORT_MODAL.EXPORT_DEBIT.DETAILS';
      id = 'export-debit';
    } else {
      title = creditOrder
        ? 'EXPORT_MODAL.EXPORT_CREDIT.DETAILS'
        : 'EXPORT_MODAL.EXPORT_INVOICE.DETAILS';
      id = creditOrder ? 'export-credit' : 'export-invoice';
    }
    return this.customDialogService
      .exportModal(
        id,
        title,
        InvoicesCreditsComponent.DEFAULT_FILE_TYPES,
        'Invoice/Credits Display',
        this.getDataPointsBasedOnCurrentSystem(),
        ExportFeatureType.INVOICE_DETAILS
      )
      .pipe(
        switchMap((dialog) => {
          return dialog.afterClosed();
        })
      );
  }

  private getDataPointsBasedOnCurrentSystem(): ExportModalDataPoint[] {
    let defaultExportList: ExportModalDataPoint[] = [];
    this.sessionFacade
      .getLoadedCurrentSystem()
      .pipe(first())
      .subscribe((currentSystem) => {
        if (currentSystem === CurrentSystem.Sap) {
          defaultExportList = InvoicesCreditsComponent.DEFAULT_EXPORT_LIST.concat(
            InvoicesCreditsComponent.SAP_USER_EXPORT_LIST
          );
        } else {
          defaultExportList = InvoicesCreditsComponent.DEFAULT_EXPORT_LIST;
        }
      });

    return defaultExportList;
  }

  private separateByOrderLine(orderLine: OrderLine): ExportInvoiceDetail[] {
    const isStockShort: boolean =
      orderLine.quantityShipped < orderLine.quantityOrdered;

    return this.processAsIndividualOrderLine(orderLine, isStockShort);
  }

  private processAsIndividualOrderLine(
    orderLine: OrderLine,
    isStockShort: boolean
  ): ExportInvoiceDetail[] {
    const exportInvoiceDetails: ExportInvoiceDetail[] = [];
    if (isStockShort) {
      const shippedItemsLine = Object.assign({}, orderLine);
      shippedItemsLine.quantityOrdered = orderLine.quantityShipped;
      if (shippedItemsLine.quantityOrdered !== 0) {
        exportInvoiceDetails.push(
          new ExportInvoiceDetail(this.currentLanguage, shippedItemsLine)
        );
      }
      exportInvoiceDetails.push(
        this.createNonSentItemsReportInvoiceOrderLine(orderLine)
      );
    } else {
      exportInvoiceDetails.push(
        new ExportInvoiceDetail(this.currentLanguage, orderLine)
      );
    }
    return exportInvoiceDetails;
  }

  private createSingleOrderLineForEachCase(orderLine: OrderLine): OrderLine {
    return {
      ...orderLine,
      quantityShipped: 1,
      quantityOrdered: 1,
    };
  }

  private createNonSentItemsReportInvoiceOrderLine(
    orderLine: OrderLine
  ): ExportInvoiceDetail {
    const nonSentItems = this.createSingleOrderLineForEachCase(orderLine);
    nonSentItems.total = 0;
    nonSentItems.quantityShipped = 0;
    nonSentItems.quantityOrdered =
      orderLine.quantityOrdered - orderLine.quantityShipped;
    return new ExportInvoiceDetail(this.currentLanguage, nonSentItems);
  }

  getTypeLabel(invoiceCredit: InvoiceCredit): string {
    if (invoiceCredit.debitInvoice) {
      return 'ORDERS.INVOICES_CREDITS.DEBIT';
    }
    return invoiceCredit.creditOrder
      ? 'ORDERS.INVOICES_CREDITS.CREDIT'
      : 'ORDERS.INVOICES_CREDITS.INVOICE';
  }
}
