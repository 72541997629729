import {
  commodityPriceRecordEntityAdapter,
  CommodityPriceRecordState,
  CommodityPriceRecordStatus,
  CommodityPriceState,
  initialCommodityPriceState,
} from './commodity-price.state';
import { CommodityPriceActions } from './commodity-price.actions';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MaterialPriceInfoRecord } from '../../services/material-price/material-price.service';

export const commodityPriceReducer = createReducer(
  initialCommodityPriceState,
  on(
    CommodityPriceActions.clearCommodityPrices,
    CommodityPriceActions.refreshCommodityPrices,
    (): CommodityPriceState => initialCommodityPriceState
  ),
  on(CommodityPriceActions.getCommodityPricesError, (state, action) =>
    getCommodityPricesError(state, action)
  ),
  on(CommodityPriceActions.getCommodityPricesSuccess, (state, action) =>
    getCommodityPricesSuccess(state, action)
  ),
  on(CommodityPriceActions.abortCommodityPrices, (state, action) =>
    abortCommodityPrices(state, action)
  ),
  on(CommodityPriceActions.getCommodityPrices, (state, action) =>
    getCommodityPrices(state, action)
  ),
  on(CommodityPriceActions.loadCommodityPrices, (state, action) =>
    loadCommodityPrices(state, action)
  )
);

function loadCommodityPrices(
  state: CommodityPriceState,
  action: {
    materialNumbers: string[];
  }
): CommodityPriceState {
  const upsertedEntities: CommodityPriceRecordState[] = action.materialNumbers
    .filter((id) => {
      return (
        !state.prices.entities[id] ||
        state.prices.entities[id].status === CommodityPriceRecordStatus.Error
      );
    })
    .map((id) => {
      return {
        materialNumber: id,
        status: CommodityPriceRecordStatus.Queued,
        isRegularPricing: false,
        record: undefined,
      };
    });
  return {
    ...state,
    prices: commodityPriceRecordEntityAdapter.upsertMany(
      upsertedEntities,
      state.prices
    ),
  };
}

function getCommodityPrices(
  state: CommodityPriceState,
  action: {
    materialNumbers: string[];
  }
): CommodityPriceState {
  const updatedEntities: Update<CommodityPriceRecordState>[] = action.materialNumbers
    .filter(
      (id) =>
        state.prices.entities[id] &&
        state.prices.entities[id].status === CommodityPriceRecordStatus.Queued
    )
    .map((id) => {
      return {
        id: id,
        changes: {
          status: CommodityPriceRecordStatus.Requested,
        },
      };
    });
  return {
    ...state,
    prices: commodityPriceRecordEntityAdapter.updateMany(
      updatedEntities,
      state.prices
    ),
  };
}

function abortCommodityPrices(
  state: CommodityPriceState,
  action: {
    materialNumbers: string[];
  }
): CommodityPriceState {
  const updatedCommodityRecordStates: Update<CommodityPriceRecordState>[] = action.materialNumbers.map(
    (materialNumber) => {
      return {
        id: materialNumber,
        changes: {
          status: CommodityPriceRecordStatus.Success,
          isRegularPricing: true,
        },
      };
    }
  );
  return {
    ...state,
    prices: commodityPriceRecordEntityAdapter.updateMany(
      updatedCommodityRecordStates,
      state.prices
    ),
  };
}

function getCommodityPricesSuccess(
  state: CommodityPriceState,
  action: {
    priceInfoRecord: MaterialPriceInfoRecord;
  }
): CommodityPriceState {
  const updatedCommodityRecordStates: Update<CommodityPriceRecordState>[] = action.priceInfoRecord.materialPrices.map(
    (priceRecord) => {
      return {
        id: priceRecord.materialNumber,
        changes: {
          record: priceRecord,
          status: CommodityPriceRecordStatus.Success,
        },
      };
    }
  );
  return {
    ...state,
    prices: commodityPriceRecordEntityAdapter.updateMany(
      updatedCommodityRecordStates,
      state.prices
    ),
  };
}

function getCommodityPricesError(
  state: CommodityPriceState,
  action: {
    materialNumbers: string[];
  }
): CommodityPriceState {
  const updatedEntities: Update<CommodityPriceRecordState>[] = action.materialNumbers.map(
    (id) => {
      return {
        id: id,
        changes: {
          status: CommodityPriceRecordStatus.Error,
        },
      };
    }
  );
  return {
    ...state,
    prices: commodityPriceRecordEntityAdapter.updateMany(
      updatedEntities,
      state.prices
    ),
  };
}
