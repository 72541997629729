import { OrderLine } from '../order-line';
import { ExportDataPoint } from './export-properties';
import { Language } from '../../../core/services/session/models/session-record';

export class ExportInvoiceDetail {
  private items: Map<string, unknown>;

  constructor(private language: Language, private invoiceOrderLine: OrderLine) {
    this.items = new Map();
    this.buildExportableInvoiceDetail();
  }

  getItem(key: string) {
    return this.items.get(key);
  }

  private buildExportableInvoiceDetail(): ExportInvoiceDetail {
    this.items.set(ExportDataPoint.Brand, this.getItemBrand());
    this.items.set(ExportDataPoint.Unit, this.getItemUnit());
    this.items.set(ExportDataPoint.Gtin, this.invoiceOrderLine.gtin);
    this.items.set(
      ExportDataPoint.ItemDescription,
      this.getItemDescription(this.invoiceOrderLine)
    );
    this.items.set(
      ExportDataPoint.ItemNumber,
      this.invoiceOrderLine.materialNumber
    );
    const pack = this.invoiceOrderLine.pack;
    this.items.set(ExportDataPoint.Pack, pack ? pack.toString() : null);
    this.items.set(
      ExportDataPoint.CasePrice,
      this.invoiceOrderLine.productPrice
    );
    this.items.set(ExportDataPoint.ExtendedPrice, this.getItemExtendedPrice());
    this.items.set(ExportDataPoint.QuantityOrdered, this.getItemQtyOrdered());
    this.items.set(ExportDataPoint.QuantityShipped, this.getItemQtyShipped());
    this.items.set(ExportDataPoint.Size, this.invoiceOrderLine.size);
    this.items.set(ExportDataPoint.CatchWeight, this.getItemCatchWeight());
    this.items.set(
      ExportDataPoint.PurchaseUnit,
      this.invoiceOrderLine.purchaseUnit
    );
    this.items.set(ExportDataPoint.PackUnit, this.invoiceOrderLine.packUnit);
    this.items.set(
      ExportDataPoint.TotalCatchWeightActualWeight,
      this.invoiceOrderLine.catchWeightActualWeight
    );

    return this;
  }

  private getItemCatchWeight() {
    return this.invoiceOrderLine.isCatchWeight
      ? this.language === Language.fr
        ? 'O'
        : 'Y'
      : 'N';
  }

  private getItemQtyShipped() {
    return this.invoiceOrderLine.quantityShipped === 0
      ? '0'
      : this.invoiceOrderLine.quantityShipped;
  }

  private getItemQtyOrdered() {
    return this.invoiceOrderLine.quantityOrdered
      ? this.invoiceOrderLine.quantityOrdered.toString()
      : null;
  }

  private getItemExtendedPrice() {
    return this.invoiceOrderLine.total === 0
      ? '0'
      : this.invoiceOrderLine.total;
  }

  private getItemUnit() {
    return this.invoiceOrderLine.brokenCase != null
      ? this.invoiceOrderLine.brokenCase
        ? this.language === Language.fr
          ? 'O'
          : 'Y'
        : 'N'
      : '';
  }

  private getItemBrand() {
    return this.invoiceOrderLine.brand
      ? this.language === Language.fr
        ? this.invoiceOrderLine.brand.fr
        : this.invoiceOrderLine.brand.en
      : null;
  }

  private getItemDescription(orderLine: OrderLine): string {
    return orderLine.itemDescription
      ? this.language === Language.fr
        ? orderLine.itemDescription.fr
        : orderLine.itemDescription.en
      : null;
  }
}
