import { snakeCase } from 'lodash-es';
import { Localized } from '../models/localized';
import {
  MaterialDetailsPageViewModel,
  MaterialDetailsSection,
} from '../../core/store/material-details/models/material-details.model';
import { Language } from '../../core/services/session/models/session-record';

export class ProductDetailsUtilities {
  public static hasVendorInfo(
    viewModel: MaterialDetailsPageViewModel
  ): boolean {
    const vendorInfo = viewModel.materialAdditionalInfo?.vendorInfo;
    return !!vendorInfo?.vendorMaterialNumber || !!vendorInfo?.displayName;
  }

  public static hasMaterialDimensions(
    viewModel: MaterialDetailsPageViewModel
  ): boolean {
    return !!viewModel.materialAdditionalInfo?.materialDimensions;
  }

  public static hasSchoolEquivalents(
    viewModel: MaterialDetailsPageViewModel
  ): boolean {
    return !!viewModel.nutritionLabel?.nutritionInfos?.[0]?.nutritionBlocks?.some(
      (nutritionBlock) => 'School Equivalents' === nutritionBlock.label
    );
  }

  public static hasVitaminsAndMinerals(
    viewModel: MaterialDetailsPageViewModel
  ): boolean {
    return !!viewModel.nutritionLabel?.nutritionInfos?.[0]?.nutritionBlocks?.some(
      (nutritionBlock) =>
        ['Minerals', 'Water Soluble Vitamins', 'Fat Soluble Vitamins'].includes(
          nutritionBlock.label
        )
    );
  }

  public static hasIngredients(
    viewModel: MaterialDetailsPageViewModel
  ): boolean {
    return !!viewModel.nutritionLabel?.nutritionInfos?.[0]?.ingredients;
  }

  public static hasMaterialMovement(
    viewModel: MaterialDetailsPageViewModel
  ): boolean {
    return (
      viewModel.materialMovementDetails?.materialMovementEntries?.length > 0
    );
  }

  public static hasNutritionInformation(
    viewModel: MaterialDetailsPageViewModel
  ): boolean {
    if (viewModel.isGfsUsCustomerBrand) {
      return viewModel.nutritionLabel?.nutritionInfos?.length > 0;
    }
    if (!viewModel.materialAdditionalInfo?.nutritionalInfo) {
      return false;
    }
    const nutritionDetails = viewModel.materialAdditionalInfo.nutritionalInfo;
    return Object.keys(nutritionDetails).some((key) => !!nutritionDetails[key]);
  }

  public static hasAllergenInfo(
    viewModel: MaterialDetailsPageViewModel
  ): boolean {
    const info = viewModel.materialAdditionalInfo; // to shorten each line below...
    return (
      !!info?.allergenInfo &&
      Object.values(info.allergenInfo).some((value) => value && value !== '\\N')
    );
  }

  public static formatSectionText(section: MaterialDetailsSection): string {
    return `PRODUCT_DETAILS.${snakeCase(section)}`.toUpperCase();
  }

  public static formatMaterialMovementSubSectionText(
    viewModel: MaterialDetailsPageViewModel
  ): string {
    // Defaults to empty list
    if (viewModel.materialMovementDetails) {
      if (
        viewModel.materialMovementDetails?.materialMovementEntries?.length > 0
      ) {
        return 'PRODUCT_DETAILS.MATERIAL_MOVEMENT.VALID_COPY';
      } else {
        return viewModel.materialInfo?.lastOrderDate
          ? ''
          : 'PRODUCT_DETAILS.MATERIAL_MOVEMENT.NO_DATA_COPY';
      }
    } else {
      return 'PRODUCT_DETAILS.MATERIAL_MOVEMENT.INVALID_COPY';
    }
  }

  public static getLocalized<T>(
    currentLanguage: Language,
    value: Localized<T>
  ): T | undefined {
    return value ? value[currentLanguage] : undefined;
  }

  public static isBasic(section: MaterialDetailsSection): boolean {
    return ![
      MaterialDetailsSection.MaterialAttributes,
      MaterialDetailsSection.MaterialDimensions,
      MaterialDetailsSection.NutritionFacts,
      MaterialDetailsSection.VendorInfo,
    ].includes(section);
  }

  public static hasDataForSection(
    viewModel: MaterialDetailsPageViewModel,
    section: MaterialDetailsSection
  ): boolean {
    return !!this.getDataForSection(viewModel, section)?.length;
  }

  public static getDataForSection(
    viewModel: MaterialDetailsPageViewModel,
    section: MaterialDetailsSection
  ): any {
    return this.getLocalized(
      viewModel?.currentLanguage,
      viewModel.materialAdditionalInfo?.[section] as Localized<any>
    );
  }
}
