import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ExpressScheduleRecord,
  ExpressScheduleRecords,
} from './models/express-schedule-record';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';

import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ExpressSchedulesService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getExpressSchedules(): Observable<ExpressScheduleRecord[]> {
    return this.httpClient
      .get<ExpressScheduleRecords>(
        `${this.webBffService.getBff()}/api/v1/customer/express-schedules`
      )
      .pipe(map((response) => response?.expressSchedules || []));
  }
}
