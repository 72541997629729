<div class="select-date-step">
  <div class="calendar-wrapper">
    <ng-container
      *ngIf="isMobileWidth && isRouteDateExpired"
      [ngTemplateOutlet]="expiredRoute"
    ></ng-container>
    <naoo-select-date-step-calendar
      [fulfillmentType]="fulfillmentType"
      [availableDates]="availableDates"
      [selectedDate]="selectedDate"
      (dateChangeEmitter)="dateChangeEmitter.emit($event)"
      (tabPressedEmitter)="tabToTimeSelection()"
    >
    </naoo-select-date-step-calendar>
  </div>
  <div class="calendar-details">
    <ng-container
      *ngIf="!isMobileWidth && isRouteDateExpired"
      [ngTemplateOutlet]="expiredRoute"
    ></ng-container>
    <div class="calendar-body">
      <ng-container *ngIf="selectedExpressDeliveryWindow">
        <div class="window-title">
          {{ 'ORDER_METHOD_MODAL.DATE.FORM.DELIVERY_WINDOW' | translate }}
        </div>
        <mat-radio-group
          aria-labelledby="available-delivery-windows"
          class="delivery-windows"
        >
          <mat-radio-button
            #timeFocus
            class="delivery-window"
            *ngFor="
              let expressDeliveryWindow of availableExpressDeliveryWindows
            "
            [checked]="selectedExpressDeliveryWindow === expressDeliveryWindow"
            [value]="expressDeliveryWindow"
            (change)="selectTime($event)"
          >
            <span class="delivery-time-info">
              <div>
                {{
                  expressDeliveryWindow.deliveryWindowStart
                    | naooTimeRange
                      : expressDeliveryWindow.deliveryWindowEnd
                      : customerTimeZone
                }}
              </div>
              <ng-container
                *ngIf="selectedExpressDeliveryWindow === expressDeliveryWindow"
              >
                <div class="deadline-header">
                  {{ 'ORDER_METHOD_MODAL.DATE.FORM.DEADLINE' | translate }}
                </div>
                {{
                  expressDeliveryWindow.orderEntryCutoff
                    | naooDate
                      : 'monthDayTime'
                      : undefined
                      : undefined
                      : customerTimeZone
                }}
              </ng-container>
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #expiredRoute>
  <div class="invalid-date-text">
    {{ 'ORDER_METHOD_MODAL.DATE.FORM.DATE_PICKER_EXPIRED_MESSAGE' | translate }}
  </div>
</ng-template>
