import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectDaysAfterFromExpressSchedules,
  selectIsCurrentExpressDeliveryWindowAfterCutoff,
} from './express-schedules.selectors';
import { filter, Observable } from 'rxjs';
import { ExpressDeliveryWindow } from '../../services/express-schedules/models/express-schedule-record';
import { Moment } from 'moment';
import { ExpressSchedulesActions } from './express-schedules.actions';

@Injectable({ providedIn: 'root' })
export class ExpressSchedulesFacade {
  constructor(private store: Store) {}

  getDaysAfterFromExpressSchedules(
    startMoment: Moment,
    count: number
  ): Observable<ExpressDeliveryWindow[]> {
    return this.store.select(
      selectDaysAfterFromExpressSchedules(startMoment, count)
    );
  }

  refreshExpressSchedules(): void {
    this.store.dispatch(ExpressSchedulesActions.refreshExpressSchedules());
  }

  getLoadedIsCurrentExpressDeliveryWindowAfterCutoff(): Observable<boolean> {
    return this.store
      .select(selectIsCurrentExpressDeliveryWindowAfterCutoff)
      .pipe(filter((isAfterCutoff) => isAfterCutoff !== undefined));
  }
}
