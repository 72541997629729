import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MaterialRecommendationsEffects } from './material-recommendations.effects';
import { materialRecommendationsReducer } from './material-recommendations.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'materialRecommendations',
      materialRecommendationsReducer
    ),
    EffectsModule.forFeature([MaterialRecommendationsEffects]),
  ],
})
export class MaterialRecommendationsStoreModule {}
