import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { HeaderLinkType, MoreToolsLink } from '../header.types';
import { naooAnimations } from '../../shared/animations/animations';
import {
  CustomerPermission,
  Language,
} from '../../core/services/session/models/session-record';
import { LocalizationService } from '../../shared/services/translation/localization.service';

@Component({
  selector: 'naoo-more-tools',
  templateUrl: './more-tools.component.html',
  styleUrls: ['./more-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [naooAnimations.showHide],
})
export class MoreToolsComponent {
  static readonly MORE_TOOLS_PERMISSIONS = [
    CustomerPermission.GordonRestaurantProAccess,
    CustomerPermission.OnlinePaymentAccess,
    CustomerPermission.CycleMenuManagementAccess,
    CustomerPermission.DiningRdAccess,
    CustomerPermission.GordonReportingAccess,
    CustomerPermission.GordonEducationReportingAccess,
    CustomerPermission.GordonHealthReportingAccess,
    CustomerPermission.ExperienceAccess,
    CustomerPermission.ResourcesAccess,
  ];
  readonly reportingTitle = 'TITLE.GORDON_REPORTING';
  isMenuOpen: boolean = false;
  showHideAnimationStatus: string = 'hide';
  @Input() permissions: CustomerPermission[];
  @Input() isDisabled: boolean;
  @Input() isMobile: boolean;
  @Output() headerRoutingEvent = new EventEmitter<HeaderLinkType>();

  constructor(
    private localizationService: LocalizationService,
    private changeDetectRef: ChangeDetectorRef
  ) {}

  redirectTo(name: HeaderLinkType): void {
    this.headerRoutingEvent.emit(name);
  }

  toggleSubMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.showHideAnimationStatus = this.isMenuOpen ? 'show' : 'hide';
    this.changeDetectRef.markForCheck();
  }

  get moreToolsLinks(): Record<string, MoreToolsLink> {
    return {
      [CustomerPermission.GordonRestaurantProAccess]: {
        translationName: 'TITLE.GORDON_RESTAURANT_PRO',
        headerLinkType: HeaderLinkType.GORDON_RESTAURANT_PRO,
      },
      [CustomerPermission.OnlinePaymentAccess]: {
        translationName: 'TITLE.ONLINE_PAYMENT',
        headerLinkType: HeaderLinkType.ONLINE_PAYMENT,
      },
      [CustomerPermission.CycleMenuManagementAccess]: {
        translationName: 'TITLE.CYCLE_MENU_MANAGEMENT',
        headerLinkType: HeaderLinkType.CYCLE_MENU_MANAGEMENT,
      },
      [CustomerPermission.DiningRdAccess]: {
        translationName: 'TITLE.DINING_RD',
        headerLinkType: HeaderLinkType.DINING_RD,
      },
      [CustomerPermission.GordonReportingAccess]: {
        translationName: this.reportingTitle,
        headerLinkType:
          this.localizationService.currentLanguage === Language.fr
            ? HeaderLinkType.GORDON_REPORTING_FR
            : HeaderLinkType.GORDON_REPORTING,
      },
      [CustomerPermission.GordonEducationReportingAccess]: {
        translationName: this.reportingTitle,
        headerLinkType:
          this.localizationService.currentLanguage === Language.fr
            ? HeaderLinkType.GORDON_EDUCATION_REPORTING_FR
            : HeaderLinkType.GORDON_EDUCATION_REPORTING,
      },
      [CustomerPermission.GordonHealthReportingAccess]: {
        translationName: this.reportingTitle,
        headerLinkType:
          this.localizationService.currentLanguage === Language.fr
            ? HeaderLinkType.GORDON_HEALTH_REPORTING_FR
            : HeaderLinkType.GORDON_HEALTH_REPORTING,
      },
      [CustomerPermission.ExperienceAccess]: {
        translationName: 'TITLE.EXPERIENCE',
        headerLinkType: HeaderLinkType.EXPERIENCE,
      },
      [CustomerPermission.ResourcesAccess]: {
        translationName: 'TITLE.RESOURCES',
        headerLinkType: HeaderLinkType.RESOURCES,
      },
    };
  }
}
