import { NutritionLabel } from './models/mygfs-nutrition';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export enum MygfsNutritionRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export interface MygfsNutritionRecordState {
  materialNumber: string;
  status: MygfsNutritionRecordStatus;
  record?: NutritionLabel;
}

export interface MygfsNutritionState {
  nutritionRecords: EntityState<MygfsNutritionRecordState>;
}

export const mygfsNutritionAdapter = createEntityAdapter<MygfsNutritionRecordState>(
  {
    selectId: (state) => state.materialNumber,
  }
);

export const initialMygfsNutritionState: MygfsNutritionState = {
  nutritionRecords: mygfsNutritionAdapter.getInitialState(),
};
