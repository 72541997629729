import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { OfflineModeFacade } from '../../core/store/offline-mode/offline-mode.facade';
import { OfflineActionBannerState } from '../banner-offline-message/banner-offline-message.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'naoo-banner-offline-container',
  template: `
    <naoo-banner-offline-message
      *ngIf="isVisible"
      [bannerState]="bannerState"
    ></naoo-banner-offline-message>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerOfflineContainerComponent implements OnInit, OnDestroy {
  @Output() visibilityChanged = new EventEmitter<boolean>();
  readonly BACK_ONLINE_DELAY_TIMEOUT = 5000;
  isVisible = false;
  bannerState = OfflineActionBannerState.Hidden;

  private backOnlineTimerId = 0;
  private destroyed$ = new Subject<void>();

  constructor(
    private offlineModeFacade: OfflineModeFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.offlineModeFacade
      .getIsOffline()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((offline) => {
        if (offline !== this.isVisible) {
          if (offline) {
            this.showBanner();
          } else {
            this.countdownToHideBanner();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private showBanner() {
    this.clearTimer();
    this.bannerState = OfflineActionBannerState.Display;
    this.isVisible = true;
    this.visibilityChanged.emit(this.isVisible);
    this.changeDetectorRef.markForCheck();
  }

  private countdownToHideBanner() {
    this.clearTimer();
    this.backOnlineTimerId = window.setTimeout(() => {
      this.hideBanner();
    }, this.BACK_ONLINE_DELAY_TIMEOUT);
    this.bannerState = OfflineActionBannerState.Hidden;
    this.isVisible = true;
    this.visibilityChanged.emit(this.isVisible);
    this.changeDetectorRef.markForCheck();
  }

  private hideBanner() {
    this.clearTimer();
    this.bannerState = OfflineActionBannerState.Hidden;
    this.isVisible = false;
    this.visibilityChanged.emit(this.isVisible);
    this.changeDetectorRef.markForCheck();
  }

  private clearTimer() {
    if (this.backOnlineTimerId !== 0) {
      window.clearTimeout(this.backOnlineTimerId);
      this.backOnlineTimerId = 0;
    }
  }
}
