import { Injectable } from '@angular/core';
import { Localized } from '../models/localized';
import { LocalizedUtilities } from '../utilities/localized-utilities';
import { Language } from '../../core/services/session/models/session-record';

@Injectable({ providedIn: 'root' })
export class NaooStringDefaulter {
  getString(localizedString: Localized<string>, language: Language): string {
    return LocalizedUtilities.getLocalizedStringValue(
      localizedString,
      language
    );
  }

  getCurrentLanguageOnlyString(
    localizedString: Localized<string>,
    language: Language
  ): string {
    return LocalizedUtilities.getLocalizedStringValue(
      localizedString,
      language,
      false
    );
  }
}
