import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LocalizationService } from '../services/translation/localization.service';
import { EntitlementFacade } from '../../core/store/entitlement/entitlement.facade';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import {
  MaterialFlag,
  MaterialFlagType,
} from '../../material-flag/material-flag';
import { GenericDocketComponent } from '../generic-docket/generic-docket.component';
import { MaterialRowFacade } from '../../core/store/material-row/material-row.facade';
import {
  MaterialCommodityInfo,
  MaterialRow,
} from '../../core/store/material-row/models/material-row';
import { VendorInfo } from '../models/material-additional-info';

@Component({
  selector: 'naoo-commodity-docket',
  templateUrl: './commodity-docket.component.html',
  styleUrls: ['./commodity-docket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommodityDocketComponent implements OnInit, OnDestroy {
  get materialCommodityInfo(): MaterialCommodityInfo | undefined {
    return this.focusedMaterialRow?.materialRowOptions?.materialCommodityInfo;
  }

  get vendorInfo(): VendorInfo | undefined {
    return this.materialCommodityInfo?.vendorInfo;
  }

  get entitlementFlag(): MaterialFlag {
    return this.focusedMaterialRow?.flags?.find(
      (flag) => MaterialFlagType.Entitlement === flag.type
    );
  }

  get tooltip(): string {
    const ptv = this.localizationService.instant('ENTITLEMENT.PTV');
    const ddf = this.localizationService.instant('ENTITLEMENT.DDF');
    const allocation = this.localizationService.instant(
      'ENTITLEMENT.ALLOCATION'
    );
    const percentage = this.localizationService.instant(
      'ENTITLEMENT.PERCENTAGE'
    );
    return `${ptv}\n\n${ddf}\n\n${allocation}\n\n${percentage}`;
  }

  constructor(
    private localizationService: LocalizationService,
    private entitlementFacade: EntitlementFacade,
    private materialRowFacade: MaterialRowFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  private destroyed$ = new Subject<void>();
  materialRowSubscription$: Subscription;
  focusedMaterialRow: MaterialRow | null;
  selectedMaterialNumber: string;

  @ViewChild(GenericDocketComponent) genericDocket: GenericDocketComponent;

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.entitlementFacade
      .getSelectedMaterialNumber()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((materialNumber) => {
        this.selectedMaterialNumber = materialNumber;
        this.setMaterialRow(materialNumber);
        this.changeDetectorRef.markForCheck();
      });
    fromEvent(window, 'resize')
      .pipe(debounceTime(100), startWith(undefined), takeUntil(this.destroyed$))
      .subscribe(() =>
        document.documentElement.style.setProperty(
          '--vh',
          `${window.innerHeight}px`
        )
      );
  }

  setMaterialRow(materialNumber: string): void {
    this.removeMaterialRowSubscription();

    if (materialNumber == null) {
      this.focusedMaterialRow = null;
      return;
    }
    this.materialRowSubscription$ = this.materialRowFacade
      .getMaterialRow({ materialNumber })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((materialRow) => {
        this.focusedMaterialRow = materialRow;
        this.changeDetectorRef.markForCheck();

        setTimeout(() => {
          this.genericDocket?.requestFocus();
        });
      });
  }

  handleClose(): void {
    this.entitlementFacade.clearSelectedMaterialNumber();
    this.removeMaterialRowSubscription();
  }

  private removeMaterialRowSubscription(): void {
    if (this.materialRowSubscription$) {
      this.materialRowSubscription$.unsubscribe();
    }
  }
}
