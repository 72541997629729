import { createSelector } from '@ngrx/store';
import { selectAllCustomGuideMaterialNumbers } from '../custom-guide/custom-guide.selectors';
import { selectOrderGuideMaterialNumbers } from '../order-guide/order-guide.selectors';
import { selectAllCriticalItemsOrEmptyArray } from '../critical-items/critical-items.selector';
import { selectSearchedMaterialsOrEmptyArray } from '../search/search.selectors';
import { selectAllMaterialRelatedSubstitutesAndSimilar } from '../material-related/material-related.selectors';
import { selectMarketingGuideMaterials } from '../marketing-guide/marketing-guide.selectors';

export const selectListMaterialNumbers = createSelector(
  selectAllCustomGuideMaterialNumbers,
  selectOrderGuideMaterialNumbers,
  selectAllCriticalItemsOrEmptyArray,
  selectSearchedMaterialsOrEmptyArray,
  selectAllMaterialRelatedSubstitutesAndSimilar,
  selectMarketingGuideMaterials,
  (
    customGuideMaterials,
    orderGuideMaterials,
    criticalItemMaterials,
    searchResultMaterials,
    materialRelatedSubstituteMaterials,
    marketingGuideMaterials
  ): string[] => [
    ...new Set([
      ...customGuideMaterials,
      ...orderGuideMaterials,
      ...criticalItemMaterials,
      ...searchResultMaterials,
      ...materialRelatedSubstituteMaterials,
      ...marketingGuideMaterials,
    ]),
  ]
);
