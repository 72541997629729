import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialUnitsRecord } from '../../services/material-units/models/material-units-record';

export const MaterialUnitsActions = createActionGroup({
  source: 'Material Units',
  events: {
    'Get Material Units Information': emptyProps(),
    'Get Material Units Information Success': (
      records: MaterialUnitsRecord[]
    ) => ({ records }),
    'Get Material Units Information Error': emptyProps(),
    'Refresh Material Units Information': emptyProps(),
  },
});
