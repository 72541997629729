import { createReducer, on } from '@ngrx/store';
import {
  initialStorePurchaseDetailsRecordState,
  StorePurchaseDetailsRecordState,
  storePurchaseDetailsRecordStateAdapter,
  StorePurchaseDetailsState,
} from './store-purchase-details.state';
import { StorePurchaseDetailsActions } from './store-purchase-details.actions';
import { StorePurchaseDetailsRecord } from '../../services/store-purchase-details/model/store-purchase-details-record';
import { StorePurchaseKey } from '../../../shared/models/store-purchase-key';
import { createStorePurchaseEntityKey } from '../../../shared/utilities/store-purchase-utilities';

export const storePurchaseDetailsReducer = createReducer(
  initialStorePurchaseDetailsRecordState,
  on(
    StorePurchaseDetailsActions.getStorePurchaseDetails,
    (state, action): StorePurchaseDetailsState =>
      getStorePurchaseDetailsRecords(state, action.storePurchaseKey)
  ),
  on(
    StorePurchaseDetailsActions.getStorePurchaseDetailsSuccess,
    (state, action): StorePurchaseDetailsState =>
      upsertStorePurchaseDetailsRecord(state, action.storePurchaseDetailsRecord)
  ),
  on(
    StorePurchaseDetailsActions.getStorePurchaseDetailsError,
    (state, action): StorePurchaseDetailsState =>
      errorStorePurchaseDetailsRecord(state, action.storePurchaseKey)
  ),
  on(
    StorePurchaseDetailsActions.refreshStorePurchaseDetails,
    StorePurchaseDetailsActions.clearStorePurchaseDetails,
    (): StorePurchaseDetailsState => initialStorePurchaseDetailsRecordState
  )
);

function getStorePurchaseDetailsRecords(
  state: StorePurchaseDetailsState,
  storePurchaseKey: StorePurchaseKey
): StorePurchaseDetailsState {
  const upsertedEntity: StorePurchaseDetailsRecordState = {
    entityKey: createStorePurchaseEntityKey(storePurchaseKey),
    hasLoaded: false,
    record: undefined,
  };
  return storePurchaseDetailsRecordStateAdapter.upsertOne(
    upsertedEntity,
    state
  );
}

function upsertStorePurchaseDetailsRecord(
  state: StorePurchaseDetailsState,
  purchaseDetailsRecord: StorePurchaseDetailsRecord
): StorePurchaseDetailsState {
  const upsertEntity: StorePurchaseDetailsRecordState = {
    entityKey: createStorePurchaseEntityKey(purchaseDetailsRecord),
    hasLoaded: true,
    record: purchaseDetailsRecord,
  };
  return storePurchaseDetailsRecordStateAdapter.upsertOne(upsertEntity, state);
}

function errorStorePurchaseDetailsRecord(
  state: StorePurchaseDetailsState,
  storePurchaseKey: StorePurchaseKey
): StorePurchaseDetailsState {
  const upsertedEntity: StorePurchaseDetailsRecordState = {
    entityKey: createStorePurchaseEntityKey(storePurchaseKey),
    hasLoaded: true,
    record: undefined,
  };
  return storePurchaseDetailsRecordStateAdapter.upsertOne(
    upsertedEntity,
    state
  );
}
