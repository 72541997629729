import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { ErrorActions } from '../error/error.actions';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import {
  selectOrderGuideLoadingStatus,
  selectOrderGuideRecords,
  selectOrderGuideUnsavedChanges,
} from './order-guide.selectors';
import { OrderGuideService } from '../../services/order-guide/order-guide.service';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { ToastMessageService } from '../../../shared/services/toast-message/toast-message.service';
import { OrderGuideChangeHistoryActions } from '../order-guide-change-history/order-guide-change-history.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { CategorizedMaterialsRecord } from '../../services/order-guide/models/categorized-materials-record';
import { CategorizedMaterialsUpdateRecord } from '../../services/order-guide/models/categorized-materials-update-record';
import { OrderGuideActions } from './order-guide.actions';
import { SharedActions } from '../shared/shared.actions';
import { Router } from '@angular/router';
import { OrderGuideUpdateRecord } from '../../services/order-guide/models/order-guide-update-record';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';

@Injectable()
export class OrderGuideEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private orderGuideService: OrderGuideService,
    private toastMessageService: ToastMessageService,
    private router: Router,
    private defaultDialogService: DefaultDialogService
  ) {}

  getOrderGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.getOrderGuide),
      concatLatestFrom(() => this.store.select(selectOrderGuideLoadingStatus)),
      exhaustMap(([_action, hasLoaded]) => {
        if (hasLoaded) {
          return [SharedActions.noOperation('Order Guide Already Loaded')];
        }

        return this.orderGuideService.getOrderGuide().pipe(
          map((orderGuideRecord) => {
            return OrderGuideActions.getOrderGuideSuccess(
              orderGuideRecord ? orderGuideRecord : null
            );
          }),
          catchError((error) => of(ErrorActions.fatalError(error))),
          takeUntil(
            this.actions$.pipe(ofType(OrderGuideActions.refreshOrderGuide))
          )
        );
      })
    );
  });

  getOrderGuideSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.getOrderGuideSuccess),
      concatLatestFrom(() => this.store.select(selectOrderGuideRecords)),
      mergeMap(([_action, categorizedProductsRecords]) => {
        const materialNumbers = categorizedProductsRecords
          .map((category) => category.materialNumbers)
          .reduce((lhs, rhs) => lhs.concat(rhs), []);
        return [
          MaterialInfoActions.loadMaterialInfo(materialNumbers),
          MaterialAvailabilityActions.loadMaterialAvailability(materialNumbers),
          OrderGuideActions.updateOrderGuideUnsavedChanges(false),
        ];
      })
    );
  });

  refreshOrderGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.refreshOrderGuide),
      map(() => OrderGuideActions.getOrderGuide())
    );
  });

  addItemToOrderGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.addItemToOrderGuide),
      mergeMap((action) => {
        return this.orderGuideService
          .addItemToOrderGuide(action.materialNumber)
          .pipe(
            map((orderGuideUpdateRecord) => {
              const category = this.getNewlyCreatedCategory(
                orderGuideUpdateRecord,
                action.materialNumber
              );
              const categoryId = category
                ? category.categoryId
                : action.categoryName;
              const categoryName = category
                ? category.categoryName
                : action.categoryName;

              return OrderGuideActions.addItemToOrderGuideSuccess(
                action.materialNumber,
                categoryName,
                categoryId
              );
            }),
            catchError((error: HttpErrorResponse) =>
              this.handleErrorResponse(error)
            )
          );
      })
    );
  });

  addItemToOrderGuideSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.addItemToOrderGuideSuccess),
      map(() => {
        this.toastMessageService.showAddedToGuideMessage(
          'LISTS.ADDED_TO_ORDER_GUIDE'
        );
        return OrderGuideChangeHistoryActions.refreshOrderGuideChangeHistory();
      })
    );
  });

  removeItemFromOrderGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.removeItemFromOrderGuide),
      switchMap((action) => {
        return this.orderGuideService
          .removeItemFromOrderGuide(action.materialNumber)
          .pipe(
            map(() =>
              OrderGuideActions.removeItemFromOrderGuideSuccess(
                action.materialNumber
              )
            ),
            catchError((error: HttpErrorResponse) =>
              this.handleErrorResponse(error)
            )
          );
      })
    );
  });

  removeItemFromOrderGuideSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.removeItemFromOrderGuideSuccess),
      map(() => {
        this.toastMessageService.showLocalizedToastMessage(
          'LISTS.REMOVED_FROM_ORDER_GUIDE'
        );
        return OrderGuideChangeHistoryActions.refreshOrderGuideChangeHistory();
      })
    );
  });

  updateOrderGuide$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.updateOrderGuide),
      concatLatestFrom(() => [
        this.store.select(selectOrderGuideRecords),
        this.store.select(selectOrderGuideUnsavedChanges),
      ]),
      switchMap(([_action, orderGuideCategories, hasUnsavedChanges]) => {
        if (hasUnsavedChanges) {
          const orderGuideUpdateRecord = {
            guideCategories: this.mapToCategorizedMaterialsUpdateRecord(
              orderGuideCategories
            ),
          };

          this.defaultDialogService.openLoadingModal();
          return this.orderGuideService
            .updateOrderGuide(orderGuideUpdateRecord)
            .pipe(
              mergeMap((updatedOrderGuideRecord) => [
                OrderGuideActions.updateOrderGuideSuccess(
                  updatedOrderGuideRecord
                ),
                OrderGuideActions.updateOrderGuideUnsavedChanges(false),
                OrderGuideActions.navigateToOrderGuide(),
              ]),
              catchError((error) => {
                this.defaultDialogService.closeLoadingModal();
                return of(ErrorActions.fatalError(error));
              })
            );
        }
        return [OrderGuideActions.navigateToOrderGuide()];
      })
    );
  });

  navigateToOrderGuide$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderGuideActions.navigateToOrderGuide),
        map((_) => {
          this.defaultDialogService.closeLoadingModal();
          this.router.navigate(['guides', 'order-guide']);
        })
      );
    },
    { dispatch: false }
  );

  changeOrderGuideCategory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        OrderGuideActions.createOrderGuideCategory,
        OrderGuideActions.renameOrderGuideCategory,
        OrderGuideActions.removeOrderGuideCategory,
        OrderGuideActions.moveOrderGuideMaterials,
        OrderGuideActions.removeOrderGuideMaterialsInLocal
      ),
      map((_) => OrderGuideActions.updateOrderGuideUnsavedChanges(true))
    );
  });

  moveOrderGuideCategory$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.moveOrderGuideCategory),
      map((action) => {
        if (action.newCategoryIndex === action.originalCategoryIndex) {
          return SharedActions.noOperation('Category index has not changed');
        }

        return OrderGuideActions.updateOrderGuideUnsavedChanges(true);
      })
    );
  });

  removeEmptyCategories$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderGuideActions.removeEmptyCategories),
      map(() => OrderGuideActions.updateOrderGuide())
    );
  });

  private handleErrorResponse(error: HttpErrorResponse): Observable<Action> {
    this.toastMessageService.showLocalizedToastMessage(
      'LISTS.ORDER_GUIDE_UPDATE_FAILURE'
    );
    return of(SharedActions.noOperation(error.message));
  }

  private mapToCategorizedMaterialsUpdateRecord(
    orderGuideCategories: CategorizedMaterialsRecord[]
  ): CategorizedMaterialsUpdateRecord[] {
    return orderGuideCategories
      .filter((category) => category.materialNumbers?.length)
      .map((category) => {
        return {
          categoryId: category.categoryId.toUpperCase().includes('UNASSIGNED')
            ? null
            : category.categoryId,
          materialNumbers: category.materialNumbers,
          categoryName: category.categoryName.en,
          categoryType: category.categoryType,
        };
      });
  }

  private getNewlyCreatedCategory(
    orderGuideUpdateRecord: OrderGuideUpdateRecord,
    materialNumber: string
  ): CategorizedMaterialsUpdateRecord {
    return orderGuideUpdateRecord.guideCategories?.find((category) =>
      category.materialNumbers.includes(materialNumber)
    );
  }
}
