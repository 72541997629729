import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { MaterialCutoffRequest } from '../../services/material-cutoff/models/material-cutoff-request';
import { MaterialCutoffService } from '../../services/material-cutoff/material-cutoff.service';
import { MaterialCutoffActions } from './material-cutoff.actions';
import {
  selectAllMaterialCutoffRecords,
  selectMaterialCutoffEntities,
} from './material-cutoff.selectors';
import { MaterialCutoffRecordStatus } from './material-cutoff.state';
import { selectFulfillmentType, selectRouteDate } from '../cart/cart.selectors';
import { selectCurrentOrFirstAvailableDeliverySchedule } from '../delivery-schedule/delivery-schedule.selectors';
import { formatDate } from '../../../shared/utilities/date-utilities';
import { CurrentSystem } from '../../services/session/models/session-record';
import { SharedActions } from '../shared/shared.actions';
import { FulfillmentType } from '../../services/cart/models/cart-record';

@Injectable()
export class MaterialCutoffEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private materialCutoffService: MaterialCutoffService
  ) {}

  loadMaterialCutoffs$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialCutoffActions.loadMaterialCutoffs),
      concatLatestFrom(() => this.store.select(selectMaterialCutoffEntities)),
      mergeMap(([action, storedCutoffTimes]) => {
        switch (action.currentSystem) {
          case CurrentSystem.Mygfs:
          case CurrentSystem.Sap:
            return of(
              MaterialCutoffActions.getMaterialCutoffs(action.currentSystem)
            );
          default:
            const queuedCutoffCodes = action.cutoffCodes.filter(
              (cutoffCode) =>
                storedCutoffTimes[cutoffCode].status ===
                MaterialCutoffRecordStatus.Queued
            );
            return of(
              MaterialCutoffActions.getMaterialCutoffs(
                action.currentSystem,
                queuedCutoffCodes
              )
            );
        }
      })
    );
  });

  /**
   * Side effect of `GetMaterialCutoffsAction` results in making a service call to
   * get Material cutoff data. Must validate that there is a routeDate; Retalix
   * requests also require a routeId, and filter out previously stored data.
   * `GetMaterialCutoffsSuccessAction` will be dispatched and update the store
   * when the service call completes.
   * `GetMaterialCutoffsErrorAction` is dispatched on failure.
   */

  getCutoffs$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialCutoffActions.getMaterialCutoffs),
      concatLatestFrom(() => [
        this.store.select(selectFulfillmentType),
        this.store.select(selectRouteDate),
        this.store.select(selectCurrentOrFirstAvailableDeliverySchedule),
        this.store.select(selectMaterialCutoffEntities),
      ]),
      mergeMap(
        ([
          action,
          fulfillmentType,
          routeDate,
          deliverySchedule,
          storedCutoffTimes,
        ]) => {
          if (FulfillmentType.TRUCK !== fulfillmentType || !deliverySchedule) {
            return of(
              SharedActions.noOperation(
                'Material cutoff times require a deliverySchedule'
              )
            );
          }

          const formattedRouteDate = routeDate
            ? formatDate(routeDate)
            : undefined;
          const requestedRouteDate =
            formattedRouteDate || deliverySchedule.routeDate;

          let request: MaterialCutoffRequest = {
            routeDate: requestedRouteDate,
          };
          const isRetalix = action.currentSystem === CurrentSystem.Retalix;
          if (isRetalix) {
            if (!routeDate) {
              return of(
                SharedActions.noOperation(
                  'Material cutoff times require routeDate'
                )
              );
            }

            const cutoffCodesNotStored = action.cutoffCodes.filter(
              (cutoffCode) =>
                storedCutoffTimes[cutoffCode].status ===
                MaterialCutoffRecordStatus.Requested
            );
            if (
              cutoffCodesNotStored.length === 0 &&
              action.cutoffCodes.length > 0
            ) {
              return of(
                SharedActions.noOperation(
                  'Material cutoff times already requested'
                )
              );
            }

            request = {
              routeDate: formattedRouteDate,
              routeId: deliverySchedule.routeId,
              jitCutoffCodes: cutoffCodesNotStored,
            };
          }

          return this.materialCutoffService.getCutoffTimes(request).pipe(
            map((cutoffs) =>
              MaterialCutoffActions.getMaterialCutoffsSuccess(
                action.currentSystem,
                cutoffs
              )
            ),
            catchError(() =>
              of(
                MaterialCutoffActions.getMaterialCutoffsError(
                  action.currentSystem,
                  request.jitCutoffCodes
                )
              )
            ),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  MaterialCutoffActions.refreshMaterialCutoffs,
                  MaterialCutoffActions.clearMaterialCutoffs
                )
              )
            )
          );
        }
      )
    );
  });

  /**
   * Side effect of `RefreshMaterialCutoffsAction` clears the store Material cutoff data
   * and gets the previously requested cutoff codes using the new routeDate for Retalix
   * customers. MyGfs/SAP clears and requests for any cutoff code that is available for them.
   */

  refreshCutoffs$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialCutoffActions.refreshMaterialCutoffs),
      concatLatestFrom(() => this.store.select(selectAllMaterialCutoffRecords)),
      map(([action, cutoffRecords]) => {
        switch (action.currentSystem) {
          case CurrentSystem.Sap:
          case CurrentSystem.Mygfs:
            return MaterialCutoffActions.getMaterialCutoffs(
              action.currentSystem
            );
          default:
            const refreshedCutoffCodes = cutoffRecords.map(
              (record) => record.cutoffCode
            );
            return MaterialCutoffActions.getMaterialCutoffs(
              action.currentSystem,
              refreshedCutoffCodes
            );
        }
      })
    );
  });
}
