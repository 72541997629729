import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CriticalItemsState } from './critical-items.state';
import { CategorizedCriticalItemRecord } from '../../services/critical-items/model/categorized-critical-items';
import { selectOpenOrderItems } from '../open-order-items/open-order-items.selectors';
import {
  selectCurrentCartMaterialNumbers,
  selectCartEntity,
  selectAllCartMaterials,
} from '../cart/cart.selectors';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { selectAllLastOrderedRecords } from '../last-ordered/last-ordered.selectors';
import { selectPreferredMaterialView } from '../material-row/material-view.selectors';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';
import { CriticalItemsGuide } from './models/critical-items-guide';
import { CriticalItemsGuideTransformer } from './critical-items-guide-transformer';
import { selectLanguage } from '../session/session.selectors';
import { selectCustomerMaterialRecord } from '../customer-material/customer-material.selector';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { MaterialInfoRecordState } from '../material-info/material-info.state';

const selectCriticalItemsState = createFeatureSelector<CriticalItemsState>(
  'criticalItems'
);

export const selectAllCategorizedCriticalItems = createSelector(
  selectCriticalItemsState,
  (state) => state.categorizedCriticalItems
);

export const selectAllCriticalItems = createSelector(
  selectCriticalItemsState,
  (state) =>
    state.categorizedCriticalItems
      ? getFlattenedCriticalItems(state.categorizedCriticalItems)
      : undefined
);

export const selectAllCriticalItemsOrEmptyArray = createSelector(
  selectAllCriticalItems,
  (criticalItems) => (criticalItems ? criticalItems : [])
);

export const selectValidCriticalItems = createSelector(
  selectAllCriticalItems,
  selectAllMaterialInfoRecordStates,
  (criticalItems, materialInfos) => {
    if (!criticalItems) {
      return undefined;
    }
    return criticalItems.filter(
      (criticalItem) =>
        !!materialInfos[criticalItem] && !!materialInfos[criticalItem].record
    );
  }
);

export const selectRemainingCriticalItems = createSelector(
  selectCartEntity,
  selectOpenOrderItems,
  selectAllCriticalItems,
  selectCurrentCartMaterialNumbers,
  (cart, openOrderItems, criticalItems, materialNumbers) => {
    if (!!cart && !cart.truckFulfillment?.routeDate) {
      return [];
    }

    if (!openOrderItems || !criticalItems) {
      return undefined;
    }

    const itemsToFilter: string[] = materialNumbers.concat(openOrderItems);

    return criticalItems.filter((item) => !itemsToFilter.includes(item));
  }
);

export const selectRemainingCriticalItemsInfo = createSelector(
  selectRemainingCriticalItems,
  selectAllMaterialInfoRecordStates,
  (
    criticalItemMaterialNumbers,
    materialInfos
  ): MaterialInfoRecordState[] | undefined =>
    criticalItemMaterialNumbers
      ? criticalItemMaterialNumbers.map(
          (materialNumber) => materialInfos[materialNumber]
        )
      : undefined
);

export const selectRemainingCriticalItemMaterials = createSelector(
  selectAllCriticalItemsOrEmptyArray,
  selectAllMaterialInfoRecordStates,
  selectAllCartMaterials,
  selectOpenOrderItems,
  (
    criticalItemMaterialNumbers,
    materialInfos,
    cartMaterials,
    openOrderItems
  ): string[] =>
    CriticalItemsGuideTransformer.transformCriticalItemsMaterialList(
      criticalItemMaterialNumbers,
      materialInfos,
      cartMaterials,
      openOrderItems
    )
);

export const selectCriticalItemsGuide = createSelector(
  selectCriticalItemsState,
  selectPreferredMaterialView,
  selectIsOffline,
  selectAllMaterialInfoRecordStates,
  selectCustomerMaterialRecord,
  selectAllLastOrderedRecords,
  selectLanguage,
  (
    criticalItemsGuideState,
    preferredView,
    isOffline,
    materialInfoRecords,
    customerMaterialRecord,
    lastOrderedRecords,
    language
  ): CriticalItemsGuide =>
    CriticalItemsGuideTransformer.transformCriticalItemsGuide(
      criticalItemsGuideState,
      materialInfoRecords,
      customerMaterialRecord,
      lastOrderedRecords,
      preferredView,
      isOffline,
      language
    )
);

export const selectCriticalItemsGuideExportMaterialsInput = (
  isPrint: boolean
) =>
  createSelector(
    selectCriticalItemsState,
    selectAllMaterialInfoRecordStates,
    selectAllLastOrderedRecords,
    selectCustomerMaterialRecord,
    selectLanguage,
    (
      criticalItemsState,
      materialInfos,
      lastOrderedRecords,
      customerMaterialRecord,
      language
    ): ExportMaterialsInput =>
      CriticalItemsGuideTransformer.transformCriticalItemsGuideExportMaterialsInput(
        criticalItemsState,
        materialInfos,
        lastOrderedRecords,
        customerMaterialRecord,
        language,
        isPrint
      )
  );

function getFlattenedCriticalItems(
  categorizedCriticalItems: CategorizedCriticalItemRecord[]
): string[] {
  return categorizedCriticalItems
    .map((categorizedCriticalItem) => categorizedCriticalItem.materialNumbers)
    .reduce((acc, materialNumbers) => acc.concat(materialNumbers), []);
}
