<ng-container *ngIf="!isMobile; else mobileMenu">
  <div class="application-selection header-disabled" *ngIf="isCustomerLoaded">
    <a
      href="javascript:void(0)"
      class="header-link ordering-link link selected"
      role="link"
      >{{ 'TITLE.ORDERING' | translate }}</a
    >
    <a
      *ngIf="isRecipeEnabled && !isDisabled"
      href="javascript:void(0)"
      class="header-link recipe-link"
      (click)="redirectTo(HeaderLinksTypes.RECIPE)"
    >
      {{ 'TITLE.RECIPE' | translate }}
    </a>
    <div class="header-disabled" *ngIf="isRecipeEnabled && isDisabled">
      {{ 'TITLE.RECIPE' | translate }}
    </div>
    <div class="inventory" *ngIf="isInventoryEnabled && !isDisabled">
      <a
        class="header-link inventory-link"
        href="javascript:void(0)"
        (click)="redirectTo(HeaderLinksTypes.INVENTORY)"
      >
        {{ 'TITLE.INVENTORY' | translate }}
      </a>
    </div>
    <div class="header-disabled" *ngIf="isInventoryEnabled && isDisabled">
      {{ 'TITLE.INVENTORY' | translate }}
    </div>
    <naoo-more-tools
      *ngIf="isMoreToolsEnabled"
      [isMobile]="isMobile"
      [isDisabled]="isDisabled"
      (headerRoutingEvent)="redirectTo($event, true)"
      [permissions]="customerMoreToolsPermissions"
    ></naoo-more-tools>
  </div>
</ng-container>

<ng-template #mobileMenu>
  <div *ngIf="!isInventoryEnabled && !isRecipeEnabled && !isMoreToolsEnabled">
    <a href="javascript:void(0)" class="ordering-link" role="link">{{
      'TITLE.ORDERING' | translate
    }}</a>
  </div>
  <button
    #orderingButton
    id="ordering-mobile-menu-trigger"
    class="menu-button"
    *ngIf="isInventoryEnabled || isRecipeEnabled || isMoreToolsEnabled"
    (click)="toggleMenu()"
    [disabled]="isOffline"
    [ngClass]="{ disabled: isOffline }"
    aria-haspopup="true"
    [attr.aria-expanded]="isMenuOpen"
    aria-controls="ordering-mobile-menu"
  >
    <span>{{ 'TITLE.ORDERING' | translate }}</span>
    <mat-icon
      class="dropdown-arrow-icon"
      [ngClass]="{ 'rotate-arrow': isMenuOpen }"
      svgIcon="arrow-v2"
    ></mat-icon>
  </button>

  <div
    *ngIf="isMenuOpen"
    class="mobile-menu-overlay"
    (click)="toggleMenu()"
  ></div>
  <div
    #orderingMobileMenu
    id="ordering-mobile-menu"
    class="mobile-menu"
    [attr.aria-hidden]="!isMenuOpen"
    [ngClass]="{ visible: isMenuOpen }"
    (keydown.arrowdown)="nextMenuItem()"
    (keydown.arrowup)="previousMenuItem()"
    (keydown.escape)="toggleMenu()"
    (keydown.tab)="tabKeydown($event)"
    (keydown.shift.tab)="tabKeydown($event)"
  >
    <div
      class="menu-items-wrapper"
      role="menu"
      aria-labelledby="ordering-mobile-menu-trigger"
    >
      <button class="menu-item selected" role="menuitem" tabindex="-1">
        {{ 'TITLE.ORDERING' | translate }}
      </button>
      <button
        role="menuitem"
        tabindex="-1"
        *ngIf="isRecipeEnabled"
        class="menu-item"
        (click)="redirectTo(HeaderLinksTypes.RECIPE)"
      >
        {{ 'TITLE.RECIPE' | translate }}
      </button>
      <button
        role="menuitem"
        tabindex="-1"
        *ngIf="isInventoryEnabled"
        class="menu-item"
        (click)="redirectTo(HeaderLinksTypes.INVENTORY)"
      >
        {{ 'TITLE.INVENTORY' | translate }}
      </button>
      <naoo-more-tools
        *ngIf="isMoreToolsEnabled"
        [isMobile]="isMobile"
        (headerRoutingEvent)="redirectTo($event, true)"
        [permissions]="customerMoreToolsPermissions"
      ></naoo-more-tools>
      <button
        role="menuitem"
        tabindex="-1"
        class="menu-item cancel-button"
        (click)="toggleMenu()"
      >
        <mat-icon class="cancel-button-icon" svgIcon="clear_icon_v2"></mat-icon>
        {{ 'CANCEL.TEXT' | translate }}
      </button>
    </div>
  </div>
</ng-template>
