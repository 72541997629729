import { Injectable } from '@angular/core';
import { OrderConfirmationRetalixTransformationService } from './order-confirmation-retalix-transformation.service';
import { OrderConfirmation } from './models/order-confirmation';
import { catchError, first, map, timeout } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { CartOrderResponse } from 'src/app/core/services/cart-order/models/cart-order';
import { CartOrderService } from '../../core/services/cart-order/cart-order.service';
import { MaterialInfoFacade } from '../../core/store/material-info/material-info.facade';
import { SessionFacade } from '../../core/store/session/session.facade';
import { CustomerMaterialFacade } from '../../core/store/customer-material/customer-material.facade';
import { CustomerMaterialRecord } from '../../core/services/customer-material/model/customer-material-record';
import { MaterialInfo } from '../../shared/models/material-info';
import { MaterialRelatedFacade } from '../../core/store/material-related/material-related.facade';
import { MaterialPriceFacade } from '../../core/store/material-price/material-price.facade';
import { CombinedPricingRecord } from '../../core/store/material-price/material-price.util';

@Injectable({
  providedIn: 'root',
})
export class OrderConfirmationRetalixAggregatorService {
  constructor(
    private sessionFacade: SessionFacade,
    private materialPriceFacade: MaterialPriceFacade,
    private materialInfoFacade: MaterialInfoFacade,
    private customerMaterialFacade: CustomerMaterialFacade,
    private transformationService: OrderConfirmationRetalixTransformationService,
    private cartOrderService: CartOrderService,
    private materialRelatedFacade: MaterialRelatedFacade
  ) {}

  aggregateAndTransform(
    cartOrderResponse: CartOrderResponse,
    cartRouteDate?: Date
  ): Observable<OrderConfirmation> {
    const materialNumbers: string[] = [];
    cartOrderResponse.cartOrders.forEach((cartOrder) => {
      cartOrder.materials.forEach((material) => {
        materialNumbers.push(material.materialNumber);
      });
    });

    const activeCustomer$ = this.sessionFacade
      .getLoadedActiveCustomer()
      .pipe(first());
    const itemPricings$: Observable<
      CombinedPricingRecord[]
    > = this.materialPriceFacade.getLoadedCombinedPrices(materialNumbers).pipe(
      first(),
      timeout(5000),
      catchError(() => of([]))
    );

    const itemInfos$ = this.materialInfoFacade
      .getLoadedMaterialInfos(materialNumbers)
      .pipe(first());
    const customerMaterial$: Observable<CustomerMaterialRecord> = this.customerMaterialFacade
      .getLoadedCustomerMaterial(materialNumbers)
      .pipe(
        first(),
        timeout(5000),
        catchError(() => of({}))
      );

    return forkJoin([
      activeCustomer$,
      itemPricings$,
      itemInfos$,
      customerMaterial$,
    ]).pipe(
      map(([activeCustomer, itemPricings, itemInfos, customerMaterial]) => {
        const pricingMap = new Map<string, CombinedPricingRecord>();
        itemPricings.forEach((itemPricing) => {
          pricingMap.set(itemPricing.materialNumber, itemPricing);
        });
        const infoMap: Map<string, MaterialInfo> = new Map();
        itemInfos.forEach((itemInfo) =>
          infoMap.set(itemInfo.materialNumber, itemInfo)
        );
        this.cartOrderService.markSubmitModalDone();
        const orderConfirmation: OrderConfirmation = this.transformationService.transformOrderConfirmation(
          cartOrderResponse,
          activeCustomer,
          pricingMap,
          infoMap,
          customerMaterial,
          cartRouteDate
        );

        if (
          orderConfirmation.orders.errorMaterials &&
          orderConfirmation.orders.errorMaterials.length > 0
        ) {
          const exceptionMaterialNumbers = orderConfirmation.orders
            .errorMaterials
            ? orderConfirmation.orders.errorMaterials.map(
                (itemException) => itemException.itemDetail.id
              )
            : [];
          this.materialRelatedFacade.loadMaterialRelated(
            exceptionMaterialNumbers
          );
        }

        return orderConfirmation;
      })
    );
  }
}
