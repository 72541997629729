import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { DoubleTapDetector } from '../../shared/utilities/double-tap-detector';
import { NaooConstants } from '../../shared/NaooConstants';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MaterialImageSources } from 'src/app/shared/services/images/material-image-sources.service';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';

export interface CarouselImage {
  largeImageUrl: MaterialImageSources;
  thumbnailImageUrl: MaterialImageSources;
}

@Component({
  selector: 'naoo-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCarouselComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  static readonly IMAGE_CAROUSEL_ANALYTICS = {
    CATEGORY: 'Product Details Page',
    BACK_ACTION: 'Back',
    BACK_LABEL: 'View Previous Image button',
    FORWARD_ACTION: 'Forward',
    FORWARD_LABEL: 'View Next Image button',
  };

  static readonly SLIDER_SENSITIVITY = 2.0;
  static readonly SNAP_TO_SLIDE_SENSITIVITY = 25;

  readonly arrowDirectionNext = 1;
  readonly arrowDirectionBack = -1;

  @Input() images: CarouselImage[] = [];
  @Input() imageAlt = '';
  @Input() isFallbackImage = false;
  @Input() isMobile = false;
  @Input() isAccordionView = false;
  @Input() materialNumber: string;
  @Output() selectImageEmitter = new EventEmitter<CarouselImage>();
  @ViewChild('sliderElement') sliderElement: ElementRef;

  private _selectedImage: CarouselImage;
  set selectedImage(image: CarouselImage) {
    this._selectedImage = image;
    const currentImageIndex = this.indexOfImage(image);
    this.goToSlide(currentImageIndex);
  }

  get selectedImage(): CarouselImage {
    return this._selectedImage;
  }

  // the number of thumbnails which will display beneath the primary image
  private readonly maxThumbnails = 3;

  // the index in the overall image array used to map images into thumbnails
  private thumbnailIndex = 0;
  private doubleTapDetector = new DoubleTapDetector();
  private destroyed$ = new Subject<void>();

  timerId: number;
  activeSlideIndex = 0;
  mainImageCarouselSlidePercentage = 0;
  thumbnails: CarouselImage[] = [];
  leftArrowDisabled = true;
  rightArrowDisabled = true;

  constructor(
    private analytics: NaooAnalyticsManager,
    private deviceIdentifierService: DeviceIdentifierService,
    private renderer: Renderer2,
    private _window: Window,
    private defaultDialogService: DefaultDialogService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.setupMainImageCarousel(this.images);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { images, materialNumber } = changes;
    const imagesCurrent = images?.currentValue;

    if (
      images?.previousValue?.toString() !== imagesCurrent?.toString() ||
      materialNumber?.previousValue !== materialNumber?.currentValue
    ) {
      this.setupMainImageCarousel(changes.images?.currentValue);
      this.selectImageAtIndex(0);
      this.adjustThumbnailIndex(0);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setupMainImageCarousel(images?: CarouselImage[]): void {
    if (!this.sliderElement || !images || images.length <= 0) {
      return;
    }
    this.renderer.setStyle(
      this.sliderElement.nativeElement,
      'width',
      `${images.length * 100}%`
    );
  }

  handlePanEvent(hammerInput: HammerInput): void {
    const slideCount = this.images.length;
    const slideIndex = this.activeSlideIndex;
    const percentage = this.calculateShiftedPercentage(hammerInput.deltaX);

    const transformPercentage = percentage - (100 / slideCount) * slideIndex;

    this.shiftSelectedImage(transformPercentage);

    if (hammerInput.isFinal) {
      const imageIndex = this.calculateIndexAfterPan(
        hammerInput.velocityX,
        percentage
      );
      this.selectImageAtIndex(imageIndex);
    }
  }

  goToSlide(nextSlideIndex: number): void {
    if (!this.sliderElement) {
      return;
    }

    this.mainImageCarouselSlidePercentage =
      -(100 / this.images.length) * nextSlideIndex;
    this.renderer.addClass(this.sliderElement.nativeElement, 'is-animating');
    this.shiftSelectedImage(this.mainImageCarouselSlidePercentage);

    if (this.timerId) {
      this._window.clearTimeout(this.timerId);
    }
    this.timerId = this._window.setTimeout(() => {
      if (this.sliderElement) {
        this.renderer.removeClass(
          this.sliderElement.nativeElement,
          'is-animating'
        );
      }
    }, 200);

    this.activeSlideIndex = nextSlideIndex;
  }

  arrowClick($event: MouseEvent, direction: number): void {
    if (
      !this.doubleTapDetector.suppressDoubleTap(
        $event,
        NaooConstants.ANIMATION_TAP_INTERVAL
      )
    ) {
      this.moveImageSelection(direction);

      this.trackArrowAnalytics(direction);
    }
  }

  selectImage(image: CarouselImage): void {
    if (!image) {
      return;
    }
    this.selectImageEmitter.emit(image);

    this.selectedImage = image;
    this.ensureSelectedThumbnailIsVisible();
    this.updateArrowDisabledState();

    this.changeDetector.markForCheck();
  }

  suppressContextMenu($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  isThumbnailSelected(thumbnail: CarouselImage): boolean {
    return (
      this.selectedImage?.largeImageUrl?.src === thumbnail?.largeImageUrl?.src
    );
  }

  adjustThumbnailIndex(adjustBy: number): void {
    if (!this.images) {
      this.thumbnailIndex = 0;
      this.thumbnails = [];
      this.updateArrowDisabledState();
      return;
    }

    let adjustedIndex = this.thumbnailIndex + adjustBy;
    const maxThumbnailsDisplayed = Math.min(
      this.maxThumbnails,
      this.thumbnails.length
    );

    if (adjustedIndex <= 0) {
      adjustedIndex = 0;
    } else if (adjustedIndex > this.images.length - maxThumbnailsDisplayed) {
      adjustedIndex = this.images.length - maxThumbnailsDisplayed;
    }

    this.thumbnailIndex = adjustedIndex;
    this.thumbnails = this.images.slice(
      adjustedIndex,
      adjustedIndex + this.maxThumbnails
    );

    this.updateArrowDisabledState();
  }

  trackByImage(_: number, image: CarouselImage): string {
    return image.largeImageUrl.src;
  }

  openImageModal(): void {
    const imageMap = this.selectedImage?.largeImageUrl?.srcMap;

    const defaultImage = this.selectedImage?.largeImageUrl?.src
      ? this.selectedImage.largeImageUrl.src
      : NaooConstants.placeHolderImagePath;

    const imageLocation = imageMap
      ? imageMap.get(this.findImageSize())
      : defaultImage;

    const imageData = {
      imageLocation,
      altText: '',
    };

    this.defaultDialogService.prompt(
      'product-details-image-modal',
      '',
      true,
      true,
      imageData,
      '',
      '',
      undefined,
      '',
      'product-detail-full-image'
    );
  }

  private trackArrowAnalytics(direction: number): void {
    if (direction === this.arrowDirectionBack) {
      this.trackAnalytics(
        ImageCarouselComponent.IMAGE_CAROUSEL_ANALYTICS.BACK_ACTION,
        ImageCarouselComponent.IMAGE_CAROUSEL_ANALYTICS.BACK_LABEL
      );
    } else if (direction == this.arrowDirectionNext) {
      this.trackAnalytics(
        ImageCarouselComponent.IMAGE_CAROUSEL_ANALYTICS.FORWARD_ACTION,
        ImageCarouselComponent.IMAGE_CAROUSEL_ANALYTICS.FORWARD_LABEL
      );
    }
  }

  private indexOfImage(image: CarouselImage): number {
    if (!image || !this.images) {
      return -1;
    }
    return this.images
      .map((img) => img?.thumbnailImageUrl?.src)
      .indexOf(image.thumbnailImageUrl?.src);
  }

  private moveImageSelection(direction: number): void {
    const selectedIndex = this.indexOfImage(this.selectedImage);
    this.selectImageAtIndex(selectedIndex + direction);
    this.ensureSelectedThumbnailIsVisible();
  }

  private selectImageAtIndex(newIndex: number): void {
    if (!this.images) {
      return;
    }

    let image: CarouselImage;
    if (newIndex < 0) {
      image = this.images[0];
    } else if (newIndex >= this.images.length) {
      image = this.images[this.images.length - 1];
    } else {
      image = this.images[newIndex];
    }

    this.selectImage(image);
  }

  private ensureSelectedThumbnailIsVisible(): void {
    const indexOfSelectedImage = this.indexOfImage(this.selectedImage);
    const thumbnailIndexLowerBound = this.thumbnailIndex;
    const thumbnailIndexUpperBound =
      thumbnailIndexLowerBound + this.maxThumbnails - 1;

    if (indexOfSelectedImage < thumbnailIndexLowerBound) {
      this.adjustThumbnailIndex(indexOfSelectedImage - this.thumbnailIndex);
    } else if (indexOfSelectedImage > thumbnailIndexUpperBound) {
      this.adjustThumbnailIndex(
        indexOfSelectedImage - this.thumbnailIndex - this.maxThumbnails + 1
      );
    }
  }

  private updateArrowDisabledState(): void {
    const indexOfSelectedImage = this.indexOfImage(this.selectedImage);

    this.leftArrowDisabled = indexOfSelectedImage <= 0;
    this.rightArrowDisabled =
      indexOfSelectedImage === -1 ||
      indexOfSelectedImage === this.images.length - 1;
  }

  private trackAnalytics(action: string, label: string): void {
    const event = {
      action: action,
      category: ImageCarouselComponent.IMAGE_CAROUSEL_ANALYTICS.CATEGORY,
      label: label,
    };

    this.analytics.trackAnalyticsEvent(event);
  }

  private calculateIndexAfterPan(
    slideVelocityX: number,
    slidePercentage: number
  ): number {
    let imageIndex: number;
    const slideIndex = this.activeSlideIndex;
    const slideCount = this.images.length;

    const snapToSlideSensitivity =
      ImageCarouselComponent.SNAP_TO_SLIDE_SENSITIVITY / slideCount;

    if (slideVelocityX > 1) {
      imageIndex = slideIndex - 1;
    } else if (slideVelocityX < -1) {
      imageIndex = slideIndex + 1;
    } else if (slidePercentage <= -snapToSlideSensitivity) {
      imageIndex = slideIndex + 1;
    } else if (slidePercentage >= snapToSlideSensitivity) {
      imageIndex = slideIndex - 1;
    } else {
      imageIndex = slideIndex;
    }

    return imageIndex;
  }

  private calculateShiftedPercentage(mouseDeltaX: number): number {
    return (
      ((100 / this.images.length) *
        mouseDeltaX *
        ImageCarouselComponent.SLIDER_SENSITIVITY) /
      this._window.innerWidth
    );
  }

  private shiftSelectedImage(percentage: number): void {
    this.renderer.setStyle(
      this.sliderElement.nativeElement,
      'transform',
      `translateX( ${percentage}% )`
    );
  }

  private findImageSize(): number {
    if ((!this.isMobile && !this.isAccordionView) || this.isAccordionView) {
      return 2;
    } else {
      return 1;
    }
  }

  get thereIsAnImage(): boolean {
    return !this?.selectedImage?.largeImageUrl?.src?.endsWith('svg');
  }
}
