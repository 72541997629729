import { createEntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { ExpressScheduleRecord } from '../../services/express-schedules/models/express-schedule-record';

export interface ExpressSchedulesState {
  records: EntityState<ExpressScheduleRecord>;
  status?: ExpressSchedulesStatus;
}

export enum ExpressSchedulesStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export const expressScheduleStateAdapter = createEntityAdapter<ExpressScheduleRecord>(
  {
    selectId: (expressSchedule) => expressSchedule.deliveryDate,
  }
);

export const expressScheduleInitialState: ExpressSchedulesState = {
  records: expressScheduleStateAdapter.getInitialState(),
  status: undefined,
};
