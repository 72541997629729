import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NaooConstants } from '../../../../shared/NaooConstants';
import {
  MaterialListStyle,
  MaterialRowContext,
  MaterialRowSharedOptions,
  MaterialRowSize,
} from '../../../../core/store/material-row/models/material-row';
import { SearchFacade } from 'src/app/core/store/search/search.facade';

@Component({
  selector: 'naoo-material-image',
  templateUrl: './material-image.component.html',
  styleUrls: ['./material-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialImageComponent {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() isUnorderable: boolean;
  @Input() isMobile: boolean;

  private readonly cartHeightDesktop = 92;
  private readonly cartHeightMobile = 64;

  readonly productDetailsPath = NaooConstants.PRODUCT_DETAILS_PATH;
  readonly imageSizes: Record<
    MaterialListStyle,
    Record<MaterialRowSize, number>
  > = {
    List: {
      Desktop: 127,
      Mobile: 64,
    },
    Slim: {
      Desktop: 40,
      Mobile: 44,
    },
    SlimGrid: {
      Desktop: 0,
      Mobile: 0,
    },
    Grid: {
      Desktop: 110,
      Mobile: 110,
    },
  };

  constructor(private router: Router, private searchFacade: SearchFacade) {}

  get isExtendedHeightList(): boolean {
    return (
      (![
        MaterialRowContext.CartReview,
        MaterialRowContext.CartSummary,
        MaterialRowContext.CriticalItem,
      ].includes(this.materialRowOptions.context) &&
        this.materialRowOptions.materialListStyle === MaterialListStyle.List) ||
      this.materialRowOptions.materialListStyle !== MaterialListStyle.List
    );
  }

  get imageSize(): number {
    if (!this.isExtendedHeightList) {
      return this.isMobile ? this.cartHeightMobile : this.cartHeightDesktop;
    }
    return this.imageSizes[this.materialRowOptions.materialListStyle][
      this.isMobile ? MaterialRowSize.Mobile : MaterialRowSize.Desktop
    ];
  }

  get isImageLinkDisabled(): boolean {
    return (
      this.materialRowOptions.isOffline ||
      this.materialRowOptions.context === MaterialRowContext.Substitutes
    );
  }

  get shouldUseWarningIcon(): boolean {
    return (
      this.isUnorderable &&
      [MaterialRowContext.CartSummary, MaterialRowContext.CartReview].includes(
        this.materialRowOptions.context
      )
    );
  }

  get materialViewClass(): string {
    switch (this.materialRowOptions.materialListStyle) {
      case MaterialListStyle.List:
        return `list ${this.materialRowOptions?.materialNumber}`;
      case MaterialListStyle.Grid:
        return `grid ${this.materialRowOptions?.materialNumber}`;
      case MaterialListStyle.Slim:
        return `slim ${this.materialRowOptions?.materialNumber}`;
    }
    return undefined;
  }

  get isComplimentaryProduct(): boolean {
    return (
      this.materialRowOptions.context ===
      MaterialRowContext.ComplimentaryProduct
    );
  }

  noImageAvailableURL($event: UIEvent): void {
    (<HTMLImageElement>$event.target).src = NaooConstants.placeHolderImagePath;
    (<HTMLImageElement>$event.target).srcset =
      NaooConstants.placeHolderImagePath;
  }

  navigateToProductDetails(): void {
    const { analytics, context, materialNumber } = this.materialRowOptions;

    if (context === MaterialRowContext.Browse) {
      this.searchFacade.searchMaterialClick(materialNumber);
    }
    if (!this.isImageLinkDisabled) {
      this.router.navigateByUrl(
        `${this.productDetailsPath}/${materialNumber}`,
        {
          state: {
            analytics,
            context,
          },
        }
      );
    }
  }
}
