import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import {
  OrderGuideChangeItem,
  OrderGuideChangeHistoryFacade,
} from '../../../core/store/order-guide-change-history/order-guide-change-history.facade';
import { OfflineModeFacade } from '../../../core/store/offline-mode/offline-mode.facade';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  MaterialGuideMatch,
  MaterialGuideMapping,
} from 'src/app/core/store/order-guide-change-history/models/order-guide-change-history';

@Component({
  selector: 'naoo-order-guide-changes-modal',
  templateUrl: './order-guide-changes-modal.component.html',
  styleUrls: ['./order-guide-changes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderGuideChangesModalComponent implements OnInit, OnDestroy {
  static readonly orderGuideChangesModalAnalyticsAction = 'Click';
  static readonly orderGuideChangesModalAnalyticsCategory = 'Order Guide';
  static readonly orderGuideChangesModalAnalyticsCancelLabel = 'X Button';

  changesData: OrderGuideChangeItem[];
  isUserOnline = true;
  materialGuideMapping: MaterialGuideMapping;
  private destroyed$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<OrderGuideChangesModalComponent>,
    private analytics: NaooAnalyticsManager,
    private offlineModeFacade: OfflineModeFacade,
    private changeDetector: ChangeDetectorRef,
    private orderGuideChangesFacade: OrderGuideChangeHistoryFacade,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data: OrderGuideChangeItem[]
  ) {
    this.changesData = Array.from(data);
  }

  ngOnInit(): void {
    this.offlineModeFacade
      .getIsOnline()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isOnline) => {
        this.isUserOnline = isOnline;
        this.changeDetector.markForCheck();
      });

    this.orderGuideChangesFacade
      .getChangesMaterialGuideMapping()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((materialGuideMapping) => {
        this.materialGuideMapping = materialGuideMapping;
      });

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.closeModal();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getMaterialGuideMatch(changeItem: OrderGuideChangeItem): MaterialGuideMatch {
    return this.materialGuideMapping
      ? this.materialGuideMapping[changeItem.materialNumber]
      : undefined;
  }

  closeModal() {
    this.dialogRef.close();
    this.trackAnalyticsEvent();
  }

  trackByMaterialNumber(
    index: number,
    changeItem: OrderGuideChangeItem
  ): string {
    return changeItem.materialNumber;
  }

  private trackAnalyticsEvent() {
    const eventInfo: AnalyticsEventInfo = {
      action:
        OrderGuideChangesModalComponent.orderGuideChangesModalAnalyticsAction,
      category:
        OrderGuideChangesModalComponent.orderGuideChangesModalAnalyticsCategory,
      label: `OrderGuideChangesModal ${OrderGuideChangesModalComponent.orderGuideChangesModalAnalyticsCancelLabel}`,
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }
}
