import { createActionGroup } from '@ngrx/store';
import { MessageData } from '../../../shared/modals/generic-modal/generic-modal.component';

export const ModalActions = createActionGroup({
  source: 'Modal',
  events: {
    'One Button Modal': (
      id: string,
      messageData: string | MessageData,
      primaryButtonText: string,
      primaryButtonAction: () => void,
      isClosable: boolean
    ) => ({
      id,
      messageData,
      primaryButtonText,
      primaryButtonAction,
      isClosable,
    }),
    'Two Button Modal': (
      id: string,
      messageData: string | MessageData,
      primaryButtonText: string,
      secondaryButtonText: string,
      primaryButtonAction: () => void,
      secondaryButtonAction: () => void
    ) => ({
      id,
      messageData,
      primaryButtonText,
      secondaryButtonText,
      primaryButtonAction,
      secondaryButtonAction,
    }),
  },
});
