import {
  CommodityPriceRecordState,
  CommodityPriceRecordStatus,
} from './commodity-price.state';

export function hasCommodityPricingFinishedLoading(
  hasCommodityAccess: boolean,
  recordState: CommodityPriceRecordState
): boolean {
  if (!hasCommodityAccess) {
    return true;
  }
  const finishedStatuses: CommodityPriceRecordStatus[] = [
    CommodityPriceRecordStatus.Error,
    CommodityPriceRecordStatus.Success,
  ];
  return !!recordState && finishedStatuses.includes(recordState.status);
}
