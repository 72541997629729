import { Injectable, NgZone } from '@angular/core';
import { EnvironmentSpecificService } from '../environment-specific/environment-specific.service';
import { asyncScheduler, Observable, of, SchedulerLike } from 'rxjs';
import { subscribeOn } from 'rxjs/operators';
import { ZoneUtilities } from '../../utilities/zone-utilities';

@Injectable({ providedIn: 'root' })
export class ZoneSchedulerService {
  constructor(
    private environmentSpecificService: EnvironmentSpecificService,
    private ngZone: NgZone
  ) {}

  leaveZoneScheduler(): SchedulerLike | undefined {
    return this.environmentSpecificService.isUnitTest()
      ? undefined
      : ZoneUtilities.leaveZone(this.ngZone, asyncScheduler);
  }

  reenterZone<T>(value: T): Observable<T> {
    return this.environmentSpecificService.isUnitTest()
      ? of(value)
      : of(value).pipe(
          subscribeOn(ZoneUtilities.enterZone(this.ngZone, asyncScheduler))
        );
  }
}
