import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { CommodityPriceActions } from './commodity-price.actions';
import {
  catchError,
  concatMap,
  delayWhen,
  first,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { defaultIfEmpty, Observable, of } from 'rxjs';
import { selectAllCommodityPriceRecordStates } from './commodity-price.selectors';
import { Injectable } from '@angular/core';
import {
  MaterialPriceInfoRecord,
  MaterialPriceService,
} from '../../services/material-price/material-price.service';
import {
  selectAllEntitlementMaterialDetails,
  selectIsEntitlementLoading,
} from '../entitlement/entitlement.selectors';
import { selectHasPermissionEnabled } from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import { CommodityPriceRecordStatus } from './commodity-price.state';
import { EntitlementAllowedPricingType } from '../../services/entitlement/models/entitlement';
import { selectIsOnlineCartLoaded } from '../cart/cart.selectors';
import { SharedActions } from '../shared/shared.actions';

@Injectable()
export class CommodityPriceEffects {
  private readonly maxProductsPerRequest = 60;

  constructor(
    private actions$: Actions,
    private store: Store,
    private materialPriceService: MaterialPriceService
  ) {}

  loadCommodityPrice$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommodityPriceActions.loadCommodityPrices),
      delayWhen(() =>
        this.store.select(selectIsEntitlementLoading).pipe(
          first((isEntitlementLoading) => !isEntitlementLoading),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CommodityPriceActions.clearCommodityPrices,
                CommodityPriceActions.refreshCommodityPrices
              )
            )
          ),
          defaultIfEmpty(true)
        )
      ),
      // eslint-disable-next-line @ngrx/prefer-concat-latest-from
      withLatestFrom(
        this.store.select(selectAllCommodityPriceRecordStates),
        this.store.select(selectAllEntitlementMaterialDetails),
        this.store.select(
          selectHasPermissionEnabled(CustomerPermission.CommodityAccess)
        ),
        this.store.select(selectIsEntitlementLoading)
      ),
      mergeMap(
        ([
          action,
          commodityPriceRecords,
          entitlementDetails,
          hasCommodityAccess,
          isEntitlementLoading,
        ]) => {
          if (isEntitlementLoading) {
            return of(
              CommodityPriceActions.getCommodityPricesError(
                action.materialNumbers
              )
            );
          }
          if (!hasCommodityAccess) {
            return of(
              CommodityPriceActions.abortCommodityPrices(action.materialNumbers)
            );
          }
          const commodityMaterials: string[] = [];
          const regularMaterials: string[] = [];
          action.materialNumbers.forEach((materialNumber) => {
            const entitlementDetail =
              entitlementDetails?.entities[materialNumber];
            if (
              EntitlementAllowedPricingType.COMMODITY ===
              entitlementDetail?.allowedPricingType
            ) {
              commodityMaterials.push(materialNumber);
            } else {
              regularMaterials.push(materialNumber);
            }
          });

          const batchedActions: Action[] = commodityMaterials
            .filter(
              (materialNumber) =>
                !!commodityPriceRecords[materialNumber] &&
                commodityPriceRecords[materialNumber].status ===
                  CommodityPriceRecordStatus.Queued
            )
            .reduce(
              (chunks, chunk, index) =>
                (index % this.maxProductsPerRequest
                  ? chunks[chunks.length - 1].push(chunk)
                  : chunks.push([chunk])) && chunks,
              []
            )
            .map((idBatch) =>
              CommodityPriceActions.getCommodityPrices(idBatch)
            );

          if (regularMaterials.length > 0) {
            batchedActions.push(
              CommodityPriceActions.abortCommodityPrices(regularMaterials)
            );
          }

          return batchedActions;
        }
      )
    );
  });

  getCommodityPrice$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommodityPriceActions.getCommodityPrices),
      delayWhen(() =>
        this.store.select(selectIsOnlineCartLoaded).pipe(
          first((isOnlineCartLoaded) => isOnlineCartLoaded),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CommodityPriceActions.clearCommodityPrices,
                CommodityPriceActions.refreshCommodityPrices
              )
            )
          ),
          defaultIfEmpty(true)
        )
      ),
      concatLatestFrom(() => [
        this.store.select(selectAllCommodityPriceRecordStates),
        this.store.select(selectIsOnlineCartLoaded),
      ]),
      concatMap(([action, priceRecords, isOnlineCartLoaded]) => {
        if (!isOnlineCartLoaded) {
          return of(
            SharedActions.noOperation(
              'getCommodityPrice$: online cart is not loaded'
            )
          );
        }
        const missingPrices = action.materialNumbers.filter((id: string) => {
          return (
            priceRecords[id].status === CommodityPriceRecordStatus.Requested
          );
        });

        if (missingPrices.length === 0) {
          return of(
            SharedActions.noOperation('Commodity prices are in the store.')
          );
        }

        return this.materialPriceService
          .getMaterialPricing(missingPrices, true)
          .pipe(
            switchMap((priceInfoRecord: MaterialPriceInfoRecord) => {
              const successItemIds = priceInfoRecord.materialPrices.map(
                (record) => record.materialNumber
              );
              const errorItemIds = missingPrices.filter(
                (missingPrice) => !successItemIds.includes(missingPrice)
              );

              const dispatchedActions: Action[] = [
                CommodityPriceActions.getCommodityPricesSuccess(
                  priceInfoRecord
                ),
              ];
              if (errorItemIds.length > 0) {
                dispatchedActions.push(
                  CommodityPriceActions.getCommodityPricesError(errorItemIds)
                );
              }
              return dispatchedActions;
            }),
            catchError(() => {
              return of(
                CommodityPriceActions.getCommodityPricesError(missingPrices)
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  CommodityPriceActions.clearCommodityPrices,
                  CommodityPriceActions.refreshCommodityPrices
                )
              )
            )
          );
      })
    );
  });

  refreshCommodityPrice$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommodityPriceActions.refreshCommodityPrices),
      map((action) =>
        CommodityPriceActions.loadCommodityPrices(action.materialNumbers)
      )
    );
  });
}
