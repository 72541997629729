import { CartUpdateRequest } from '../../services/cart/models/cart-record';
import { CartEntityState, CartUpdateType } from './cart.state';

export function fulfillmentDataChanged(
  updateRequest: CartUpdateRequest,
  cartEntity: CartEntityState,
  cartUpdateType: CartUpdateType
): boolean {
  let isFulfillmentDataChanged = false;
  if (
    (!!updateRequest.fulfillmentType &&
      cartEntity.fulfillmentType !== updateRequest.fulfillmentType) ||
    [
      CartUpdateType.UpdateRouteDate,
      CartUpdateType.UpdateStoreFulfillment,
    ].includes(cartUpdateType)
  ) {
    isFulfillmentDataChanged = true;
  }

  return isFulfillmentDataChanged;
}
