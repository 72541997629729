import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { NaooConstants } from '../../shared/NaooConstants';
import { LoadingService } from '../../shared/services/loading-service/loading.service';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { NavigationEnd, Router } from '@angular/router';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { filter, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { HeaderHeightService } from '../../shared/services/header-height/header-height.service';
import { MessageService } from 'src/app/shared/services/message/message.service';
import { CustomerBrand } from '../../core/services/session/models/session-record';
import { SessionFacade } from '../../core/store/session/session.facade';

@Component({
  selector: 'naoo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  static readonly LOGO_ANALYTICS_CATEGORY = 'general';
  static readonly LOGO_ANALYTICS_ACTION = 'click';
  static readonly LOGO_ANALYTICS_LABEL = 'logo';

  readonly HOME_PAGE_PATH = NaooConstants.ROOT_PATH;

  @Input() customerBrand: CustomerBrand;
  @Input() isOffline: boolean;

  @ViewChild('searchBar') searchBar: SearchBarComponent;
  isMobile$ = this.deviceIdentifierService.observeDeviceType();
  destroyed$ = new Subject();

  showBlackLogo: boolean;
  showThinHeader: boolean;
  isLoading$: Observable<boolean>;
  isOfflineBannerVisible: boolean;

  isSapWithPermissions: boolean;

  constructor(
    private deviceIdentifierService: DeviceIdentifierService,
    private analytics: NaooAnalyticsManager,
    public loadingService: LoadingService,
    public router: Router,
    private changeDetector: ChangeDetectorRef,
    private headerHeightService: HeaderHeightService,
    private messageService: MessageService,
    private sessionFacade: SessionFacade
  ) {}

  ngOnInit() {
    this.sessionFacade
      .hasIspuOrExpressPermissions()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((hasIspuOrExpressPermissions) => {
        this.isSapWithPermissions = hasIspuOrExpressPermissions;
      });

    combineLatest([
      this.messageService.isDismissed(),
      this.messageService.isCollapsed(),
      this.messageService.getLastSystemNotification(),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.headerHeightService.triggerHeaderHeightChange());

    this.isLoading$ = this.loadingService.debouncedLoadingState;

    this.showThinHeader = this.shouldShowThinHeader();
    this.showBlackLogo = this.shouldShowBlackLogo();
    combineLatest([
      this.isMobile$,
      this.router.events.pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      ),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([isMobile, { url }]) => {
        this.showThinHeader = this.shouldShowThinHeader();
        this.showBlackLogo = this.shouldShowBlackLogo();

        if (!isMobile && this.searchBar) {
          if (!url.startsWith(NaooConstants.SEARCH_URL)) {
            this.searchBar.clear();
          } else {
            const queryParams = this.router
              .parseUrl(url)
              .queryParamMap.get('searchText');
            this.searchBar.setText(queryParams);
          }
        }
        this.changeDetector.markForCheck();
        this.headerHeightService.triggerHeaderHeightChange();
      });
  }

  ngAfterViewInit(): void {
    this.headerHeightService.triggerHeaderHeightChange();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  shouldShowThinHeader(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.startsWith(NaooConstants.CUSTOMER_UNIT_SELECTION_PATH) ||
      currentUrl.startsWith(NaooConstants.SCHEDULED_MAINTENANCE_PATH)
    );
  }

  shouldShowBlackLogo(): boolean {
    const currentUrl = this.router.url;
    return currentUrl === NaooConstants.SCHEDULED_MAINTENANCE_PATH;
  }

  trackLogoClick() {
    const eventInfo: AnalyticsEventInfo = {
      action: HeaderComponent.LOGO_ANALYTICS_ACTION,
      category: HeaderComponent.LOGO_ANALYTICS_CATEGORY,
      label: HeaderComponent.LOGO_ANALYTICS_LABEL,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  clicked(): void {
    this.headerHeightService.triggerHeaderHeightChange();
  }

  setOfflineBannerVisible(isVisible: boolean) {
    this.isOfflineBannerVisible = isVisible;
    this.changeDetector.markForCheck();
  }
}
