import { Params } from '@angular/router';

export enum NotificationPriority {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export interface NotificationMessage {
  body: string;
  title?: string;
  route?: { routerLink: string; queryParams: Params | null; text: string };
}

export class NotificationCallback {
  constructor(
    public scope: ThisType<unknown>,
    public callbackFunction: () => void
  ) {}
}

export class Notification {
  constructor(
    public message: NotificationMessage,
    public retryCallback?: NotificationCallback,
    public priority: NotificationPriority = NotificationPriority.LOW
  ) {}
}
