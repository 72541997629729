import {
  EntitlementAllowedPricingType,
  EntitlementMaterialDetail,
} from '../../services/entitlement/models/entitlement';
import { MaterialCommodityShieldColor } from '../material-row/models/material-row';
import { OverallocatedMaterialDetail } from './entitlement.state';

export function getDoesQuantityExceedMaxAllocationHardStop(
  entitlementMaterialDetail: EntitlementMaterialDetail | null,
  overallocatedMaterialDetail: OverallocatedMaterialDetail | undefined,
  cartMaterialQuantity: number
): boolean {
  if (!entitlementMaterialDetail) {
    return !overallocatedMaterialDetail
      ? false
      : overallocatedMaterialDetail.maxCommodityCasesAmount <
          cartMaterialQuantity;
  }
  const color: MaterialCommodityShieldColor =
    MaterialCommodityShieldColor[
      entitlementMaterialDetail.shieldColorDescription as keyof typeof MaterialCommodityShieldColor
    ];

  const maxCommodityCasesAmount = overallocatedMaterialDetail
    ? overallocatedMaterialDetail.maxCommodityCasesAmount
    : entitlementMaterialDetail.maxCommodityCasesAmount;

  return (
    MaterialCommodityShieldColor.GREEN === color &&
    maxCommodityCasesAmount < cartMaterialQuantity
  );
}

export function getMaterialCommodityShieldColor(
  entitlementMaterialDetail: EntitlementMaterialDetail
): MaterialCommodityShieldColor | undefined {
  return entitlementMaterialDetail?.shieldColorDescription
    ? MaterialCommodityShieldColor[
        entitlementMaterialDetail.shieldColorDescription as keyof typeof MaterialCommodityShieldColor
      ]
    : undefined;
}

export function isEntitlementMaterialDetailUnorderable(
  entitlementMaterialDetail: EntitlementMaterialDetail
): boolean {
  return (
    MaterialCommodityShieldColor.RED ===
      getMaterialCommodityShieldColor(entitlementMaterialDetail) ||
    EntitlementAllowedPricingType.NONE ===
      entitlementMaterialDetail?.allowedPricingType
  );
}
