import {
  ChangeDetectorRef,
  Injectable,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DecimalUtilities } from '../utilities/decimal-utilities';
import { LocalizationService } from '../services/translation/localization.service';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'naoofloatingpoint',
  pure: false,
})
export class NaooFloatingPointPipe implements PipeTransform, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    private decimalUtilities: DecimalUtilities,
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef
  ) {
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(value: string): string {
    return this.decimalUtilities.getLocalizedValue(value);
  }
}
