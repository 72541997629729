<div class="unavailable-products-container">
  <h2 class="title">{{ 'RE_ORDER.UNAVAILABLE_PRODUCTS.TITLE' | translate }}</h2>
  <div class="orderable-products">
    <mat-icon svgIcon="success-icon"></mat-icon>
    <div
      class="message"
      [innerHTML]="
        (orderableCount === 1
          ? 'RE_ORDER.UNAVAILABLE_PRODUCTS.ORDERABLE_PRODUCT'
          : 'RE_ORDER.UNAVAILABLE_PRODUCTS.ORDERABLE_PRODUCTS'
        ) | translate: { count: orderableCount }
      "
    ></div>
  </div>
  <hr />
  <div class="non-orderable-products">
    <mat-icon svgIcon="unavailable"></mat-icon>
    <div
      class="message"
      [innerHTML]="
        nonOrderableItems.length === 1
          ? ('RE_ORDER.UNAVAILABLE_PRODUCTS.NON_ORDERABLE_PRODUCT' | translate)
          : ('RE_ORDER.UNAVAILABLE_PRODUCTS.NON_ORDERABLE_PRODUCTS'
            | translate: { count: nonOrderableItems.length })
      "
    ></div>
  </div>
  <div class="table-container">
    <div class="header-container">
      <div class="cell description">
        {{ 'RE_ORDER.UNAVAILABLE_PRODUCTS.DESCRIPTION' | translate }}
      </div>
      <div class="cell item-code">
        {{ 'RE_ORDER.UNAVAILABLE_PRODUCTS.ITEM_CODE' | translate }}
      </div>
      <div class="cell variant">
        {{ 'RE_ORDER.UNAVAILABLE_PRODUCTS.VARIANT_LABEL' | translate }}
      </div>
    </div>
    <div class="body-container">
      <div
        *ngFor="let items of nonOrderableItems; trackBy: rowId; let odd = odd"
      >
        <ng-container
          [ngTemplateOutlet]="productRow"
          [ngTemplateOutletContext]="{
            items: items,
            odd: odd,
            rowId: rowId(items)
          }"
        ></ng-container>
      </div>
    </div>
  </div>
  <button class="copy-to-clipboard" (click)="onCopyToClipboard()">
    <mat-icon svgIcon="copy-items"></mat-icon>
    <div class="copy-label">
      {{ 'RE_ORDER.UNAVAILABLE_PRODUCTS.COPY_TO_CLIPBOARD' | translate }}
    </div>
  </button>
</div>

<div class="buttons-container">
  <button class="cancel" (click)="onCancel()">
    {{ 'RE_ORDER.UNAVAILABLE_PRODUCTS.CANCEL' | translate }}
  </button>
  <button class="add-items-to-cart" (click)="onAddItemsToCart()">
    {{ 'RE_ORDER.UNAVAILABLE_PRODUCTS.ADD_ITEMS_TO_CART' | translate }}
  </button>
</div>

<ng-template #productRow let-items="items" let-odd="odd" let-rowId="rowId">
  <div
    class="row"
    [ngClass]="{
      odd: odd,
      expanded: rowId === expandedRowId,
      collapsed: rowId !== expandedRowId
    }"
  >
    <div class="cell description">
      <span class="value">{{ items[0].description | stringdefaulter }}</span>
    </div>
    <div class="cell item-code">
      <span class="label"
        >{{ 'RE_ORDER.UNAVAILABLE_PRODUCTS.ITEM_CODE' | translate }}:
      </span>
      <span class="value">{{ items[0].materialNumber }}</span>
    </div>
    <div class="cell variant">
      <span class="label"
        >{{ 'RE_ORDER.UNAVAILABLE_PRODUCTS.VARIANT_LABEL' | translate }}:
      </span>
      <span class="value">{{
        items.length === 2
          ? ('RE_ORDER.UNAVAILABLE_PRODUCTS.VARIANT_CASE_UNIT' | translate)
          : ({ uomCode: items[0].uom } | materialunits)
      }}</span>
    </div>
    <button
      class="dropdown-button"
      (click)="onSelect(rowId)"
      [attr.aria-label]="
        (rowId === expandedRowId ? 'COLLAPSE' : 'EXPAND') | translate
      "
    >
      <mat-icon svgIcon="arrow-gray"></mat-icon>
    </button>
  </div>
</ng-template>
