import {
  ChangeDetectorRef,
  Injectable,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { MaterialUnitInfo } from '../models/material-info';
import { Subject } from 'rxjs';
import { MaterialUnitsFacade } from '../../core/store/material-units/material-units.facade';
import { takeUntil } from 'rxjs/operators';
import { Dictionary, EntityState } from '@ngrx/entity';
import { MaterialUnitsRecord } from '../../core/services/material-units/models/material-units-record';
import {
  findParentUomDisplayDescription,
  getMaterialUnitFromUomCode,
} from '../utilities/material-units-utilities';
import { LocalizationService } from '../services/translation/localization.service';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'packsize',
  pure: false,
})
export class PackSizePipe implements PipeTransform, OnDestroy {
  private materialUnitsRecords: Dictionary<MaterialUnitsRecord>;
  private destroyed$ = new Subject<void>();

  constructor(
    private materialUnitsFacade: MaterialUnitsFacade,
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef
  ) {
    this.materialUnitsFacade
      .getLoadedMaterialUnitsRecords()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((entityState: EntityState<MaterialUnitsRecord>) => {
        this.materialUnitsRecords = entityState.entities;
      });

    this.localizationService
      .language()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(units: MaterialUnitInfo[], innerPackSize?: string): string {
    return !units?.length
      ? ''
      : this.formatPackSizes(
          [...units.filter((unit) => !this.isSizeUnit(unit))].reverse(),
          innerPackSize
        );
  }

  private formatPackSizes(
    units: MaterialUnitInfo[],
    innerPackSize?: string
  ): string {
    let packSizeResult = '';
    if (!units?.length) {
      return packSizeResult;
    }
    const unitMap: Map<string, MaterialUnitInfo> = new Map(
      units.map((unit) => [unit.uom, unit])
    );

    if (innerPackSize) {
      packSizeResult += `${innerPackSize}/${getMaterialUnitFromUomCode(
        +units[0].qtyInParent,
        units[0].displayCode,
        this.materialUnitsRecords,
        this.localizationService.currentLanguage,
        true
      )}`;
    }

    units.forEach((unitInfo: MaterialUnitInfo) => {
      if (unitInfo.parentUom) {
        if (packSizeResult.length) {
          packSizeResult += ', ';
        }
        packSizeResult += getMaterialUnitFromUomCode(
          +unitInfo.qtyInParent,
          unitInfo.displayCode,
          this.materialUnitsRecords,
          this.localizationService.currentLanguage,
          false,
          true
        );

        const parentUomDescription = findParentUomDisplayDescription(
          unitMap.get(unitInfo.parentUom)?.displayCode,
          this.materialUnitsRecords,
          this.localizationService.currentLanguage
        );
        if (parentUomDescription) {
          packSizeResult += `/${parentUomDescription}`;
        }
      }
    });

    return packSizeResult;
  }

  private isSizeUnit(unit: MaterialUnitInfo): boolean {
    return !unit.parentUom && !!unit.qtyInParent;
  }
}
