import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  NutritionInfo,
  NutritionLabel,
} from 'src/app/core/store/mygfs-nutrition/models/mygfs-nutrition';

@Component({
  selector: 'naoo-mygfs-nutrition-facts-content',
  templateUrl: './mygfs-nutrition-facts-content.component.html',
  styleUrls: ['./mygfs-nutrition-facts-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MygfsNutritionFactsContentComponent {
  @Input()
  set nutritionLabel(nutritionLabel: NutritionLabel) {
    this.nutritionInfos = nutritionLabel?.nutritionInfos;
    this.selectedInfo = this.nutritionInfos
      ? this.nutritionInfos[0]
      : undefined;
  }

  nutritionInfos: NutritionInfo[];
  selectedInfo: NutritionInfo;
  shouldRound = true;
}
