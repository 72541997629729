import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable, timer } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  finalize,
  first,
  map,
  take,
  tap,
  timeout,
} from 'rxjs/operators';
import {
  OrderConfirmationInfoRecord,
  OrderConfirmationPollRecord,
  OrderConfirmationRecord,
} from './models/order-confirmation-record';
import { OrderConfirmationRequest } from './models/order-confirmation-request';
import { EcommerceAnalyticsFacade } from '../../store/ecommerce-analytics/ecommerce-analytics.facade';
import { MaterialCutoffFacade } from '../../store/material-cutoff/material-cutoff.facade';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { NaooConstants } from 'src/app/shared/NaooConstants';
import {
  ORDER_CONFIRMATION_POLLING_DELAY_MILLIS,
  ORDER_CONFIRMATION_POLLING_INTERVAL_MILLIS,
} from '../../injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class OrderConfirmationService {
  private readonly pollingTimeoutMilliseconds =
    NaooConstants.SUBMIT_LOADING_MODAL_TIMEOUT;

  private polledOrders: OrderConfirmationRecord[];

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private materialCutoffFacade: MaterialCutoffFacade,
    private naooAnalyticsManager: NaooAnalyticsManager,
    @Inject(ORDER_CONFIRMATION_POLLING_DELAY_MILLIS)
    private pollingInitialDelayMilliseconds: number,
    @Inject(ORDER_CONFIRMATION_POLLING_INTERVAL_MILLIS)
    private pollingIntervalMilliseconds: number
  ) {}

  pollForProcessedOrders(
    orderIds: string[]
  ): Observable<OrderConfirmationPollRecord> {
    this.polledOrders = [];
    const orderIdsToPoll = [...orderIds];

    return timer(
      this.pollingInitialDelayMilliseconds,
      this.pollingIntervalMilliseconds
    ).pipe(
      exhaustMap(() => this.retrieveOrderConfirmations(orderIdsToPoll)),
      tap((orderConfirmationInfoResponse) => {
        this.removeAlreadyProcessedOrderIds(
          orderConfirmationInfoResponse,
          orderIdsToPoll
        );
        this.polledOrders.push(...orderConfirmationInfoResponse.orders);
      }),
      filter(() => this.allOrdersProcessed(orderIds)),
      take(1),
      map(() => {
        return this.buildOrderConfirmationPollResponse(orderIdsToPoll);
      }),
      timeout(this.pollingTimeoutMilliseconds),
      catchError(() => {
        return this.retrieveOrderConfirmations(orderIdsToPoll).pipe(
          first(),
          tap((orderConfirmationInfoResponse) => {
            this.polledOrders.push(...orderConfirmationInfoResponse.orders);
            this.removeAlreadyProcessedOrderIds(
              orderConfirmationInfoResponse,
              orderIdsToPoll
            );
          }),
          map(() => this.buildOrderConfirmationPollResponse(orderIdsToPoll))
        );
      }),
      finalize(() => this.finalizeSubmission(!!orderIdsToPoll.length))
    );
  }

  private removeAlreadyProcessedOrderIds(
    orderConfirmationInfoResponse: OrderConfirmationInfoRecord,
    orderIdsToPoll: string[]
  ) {
    orderConfirmationInfoResponse.orders.forEach((order) => {
      const index = orderIdsToPoll.indexOf(order.pooOrderId);
      if (index !== -1) {
        orderIdsToPoll.splice(index, 1);
      }
    });
  }

  private buildOrderConfirmationPollResponse(
    processingOrderIds: string[]
  ): OrderConfirmationPollRecord {
    return {
      processingOrderIds: processingOrderIds,
      orders: this.polledOrders,
    };
  }

  private allOrdersProcessed(orderIds: string[]): boolean {
    return this.polledOrders.length === orderIds.length;
  }

  private retrieveOrderConfirmations(
    orderIds: string[]
  ): Observable<OrderConfirmationInfoRecord> {
    const request: OrderConfirmationRequest = {
      cartOrderIds: [...orderIds],
    };
    const url = `${this.webBffService.getBff()}/api/v2/orders/confirmation`;
    return this.httpClient.post<OrderConfirmationInfoRecord>(url, request);
  }

  private finalizeSubmission(hasPendingOrders: boolean) {
    this.trackOrderConfirmationAnalyticEvent(hasPendingOrders);
    this.materialCutoffFacade.clearCache();
    this.ecommerceAnalyticsFacade.trackPurchaseCartEvent(hasPendingOrders);
  }

  private trackOrderConfirmationAnalyticEvent(hasPendingOrders: boolean) {
    const event: AnalyticsEventInfo = {
      category: 'Ecommerce',
      action: 'Purchase',
      label: hasPendingOrders ? 'Pending' : undefined,
    };
    this.naooAnalyticsManager.trackAnalyticsEvent(
      JSON.parse(JSON.stringify(event))
    );
  }
}
