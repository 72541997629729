import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MaterialComparisonFacade } from '../core/store/material-comparison/material-comparison.facade';
import { Subject } from 'rxjs';
import { LastOrderedFacade } from '../core/store/last-ordered/last-ordered.facade';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'naoo-material-comparison',
  template: `
    <naoo-material-comparison-container
      [materialNumbers]="materialNumbers$ | async"
    >
    </naoo-material-comparison-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialComparisonComponent implements OnDestroy, OnInit {
  materialNumbers$: Observable<string[]>;
  private destroyed$ = new Subject<void>();

  constructor(
    private materialComparisonFacade: MaterialComparisonFacade,
    private lastOrderedFacade: LastOrderedFacade
  ) {}

  ngOnInit() {
    this.materialNumbers$ = this.materialComparisonFacade.getMaterialComparisonNumbers();
    this.materialNumbers$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((materialNumbers) => {
        this.lastOrderedFacade.loadLastOrdered(materialNumbers);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
