import { MygfsNutritionEffects } from './mygfs-nutrition.effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { mygfsNutritionReducer } from './mygfs-nutrition.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('mygfsNutrition', mygfsNutritionReducer),
    EffectsModule.forFeature([MygfsNutritionEffects]),
  ],
})
export class MygfsNutritionStoreModule {}
