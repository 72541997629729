import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  pickupSchedulesAdapter,
  PickupSchedulesRecordState,
  PickupSchedulesState,
  PickupSchedulesStatus,
  pickupScheduleStateAdapter,
  pickupScheduleStateInitialState,
} from './pickup-schedules.state';
import {
  selectFulfillmentType,
  selectIsCartLoaded,
  selectPickupDateString,
  selectStoreFulfillment,
} from '../cart/cart.selectors';
import { PickupScheduleRecord } from '../../services/pickup-schedules/models/pickup-schedule-record';
import { Dictionary } from '@ngrx/entity';
import {
  selectActiveCustomerTimezone,
  selectHasPermissionEnabled,
} from '../session/session.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { selectAllStoreRecordStateEntities } from '../store/store.selectors';
import { NaooConstants } from '../../../shared/NaooConstants';
import moment, { Moment } from 'moment';

const selectPickupSchedulesFeature = createFeatureSelector<PickupSchedulesState>(
  'pickupSchedules'
);

export const selectAllPickupSchedulesStateEntities = createSelector(
  selectPickupSchedulesFeature,
  (pickupSchedulesFeature) =>
    pickupSchedulesFeature
      ? pickupScheduleStateAdapter
          .getSelectors()
          .selectEntities(pickupSchedulesFeature)
      : pickupScheduleStateInitialState.entities
);

const selectCurrentPickupSchedulesRecordState = createSelector(
  selectAllPickupSchedulesStateEntities,
  selectStoreFulfillment,
  (
    pickupSchedulesRecordStates,
    storeFulfillment
  ): PickupSchedulesRecordState | undefined =>
    pickupSchedulesRecordStates?.[storeFulfillment?.storePlantId]
);

export const selectCurrentPickupLoaded = createSelector(
  selectCurrentPickupSchedulesRecordState,
  selectIsCartLoaded,
  selectFulfillmentType,
  selectHasPermissionEnabled(CustomerPermission.InStorePickup),
  (
    pickupSchedulesRecordState,
    hasCartLoaded,
    fulfillmentType,
    hasIspuPermission
  ): boolean => {
    const currentPickupLoaded = [
      PickupSchedulesStatus.Success,
      PickupSchedulesStatus.Error,
    ].includes(pickupSchedulesRecordState?.status);

    return (
      !hasIspuPermission ||
      (hasCartLoaded && FulfillmentType.PICKUP != fulfillmentType) ||
      currentPickupLoaded
    );
  }
);

export const selectCurrentPickupSchedule = createSelector(
  selectCurrentPickupSchedulesRecordState,
  selectPickupDateString,
  (pickupSchedulesRecordState, pickupDate): PickupScheduleRecord | undefined =>
    pickupSchedulesRecordState?.pickupScheduleEntityState.entities[pickupDate]
);

export const selectPickupScheduleExpired = createSelector(
  selectPickupDateString,
  selectCurrentPickupSchedule,
  selectCurrentPickupLoaded,
  (pickupDate, currentPickupScheduleRecord, hasLoaded): boolean | undefined => {
    return hasLoaded ? !!pickupDate && !currentPickupScheduleRecord : undefined;
  }
);

export const selectIsPickupPastCurrentCutoffTime = createSelector(
  selectPickupDateString,
  selectCurrentPickupSchedule,
  selectCurrentPickupLoaded,
  selectPickupScheduleExpired,
  selectActiveCustomerTimezone,
  (
    pickupDate: string,
    currentPickupScheduleRecord: PickupScheduleRecord,
    hasLoaded: boolean,
    isPickupScheduleExpired: boolean,
    customerTimeZone: string
  ): boolean | undefined => {
    if (
      !currentPickupScheduleRecord?.date ||
      isPickupScheduleExpired ||
      pickupDate !== currentPickupScheduleRecord.date
    ) {
      return false;
    }
    const storeDateTime = moment.tz(
      `${currentPickupScheduleRecord.date} ${currentPickupScheduleRecord.orderCutoffTime}`,
      customerTimeZone
    );
    return moment().tz(customerTimeZone).isSameOrAfter(storeDateTime);
  }
);

export const selectPickupScheduleRecord = (
  storePlantId: string,
  date: string
) => {
  return createSelector(
    selectAllPickupSchedulesStateEntities,
    (pickupScheduleRecordStates): PickupScheduleRecord | undefined => {
      const entityState: PickupSchedulesRecordState =
        pickupScheduleRecordStates[storePlantId];
      return entityState?.pickupScheduleEntityState.entities[date];
    }
  );
};

export const selectDaysAfterFromPickupSchedules = (
  storePlantId: string,
  startMoment: Moment,
  count: number
) => {
  return createSelector(
    selectAllPickupSchedulesStateEntities,
    selectAllStoreRecordStateEntities,
    selectActiveCustomerTimezone,
    (
      pickupSchedulesRecordStates: Dictionary<PickupSchedulesRecordState>,
      stores,
      customerTz
    ): Moment[] => {
      const entityState = pickupSchedulesRecordStates[storePlantId];
      if (!entityState) {
        return [];
      }

      const allPickupSchedules = pickupSchedulesAdapter
        .getSelectors()
        .selectAll(entityState.pickupScheduleEntityState);

      const storeTz = stores[storePlantId]?.timezone;
      const targetPickupScheduleIdx =
        allPickupSchedules.findIndex((record) => {
          const bufferedOpening = getBufferedOpeningTimeMoment(
            record,
            storeTz,
            customerTz
          );
          const orderCutoffTime = getOrderCutoffTimeMoment(
            record,
            storeTz,
            customerTz
          );
          return (
            startMoment.isSameOrAfter(bufferedOpening) &&
            startMoment.isBefore(orderCutoffTime)
          );
        }) + 1;
      return allPickupSchedules
        .slice(targetPickupScheduleIdx, targetPickupScheduleIdx + count)
        .map((record) =>
          getBufferedOpeningTimeMoment(record, storeTz, customerTz)
        );
    }
  );

  function getBufferedOpeningTimeMoment(
    pickupScheduleRecord: PickupScheduleRecord,
    storeTz: string,
    customerTz: string
  ): Moment {
    const openingTimeInCustomerTz = getStoreDateTimeInCustomerTimezone(
      pickupScheduleRecord.date,
      pickupScheduleRecord.openingTime,
      storeTz,
      customerTz
    );
    return openingTimeInCustomerTz.add(
      NaooConstants.pickupOpeningBufferMinutes,
      'minutes'
    );
  }

  function getOrderCutoffTimeMoment(
    pickupScheduleRecord: PickupScheduleRecord,
    storeTz: string,
    customerTz: string
  ): Moment {
    return getStoreDateTimeInCustomerTimezone(
      pickupScheduleRecord.date,
      pickupScheduleRecord.orderCutoffTime,
      storeTz,
      customerTz
    );
  }

  function getStoreDateTimeInCustomerTimezone(
    date: string,
    time: string,
    storeTz: string,
    customerTz: string
  ): Moment {
    const dateTimeInStoreTz = moment.tz(`${date} ${time}`, storeTz);
    return moment.tz(dateTimeInStoreTz, customerTz);
  }
};

export const selectHasSubmittablePickupFulfillment = createSelector(
  selectCurrentPickupSchedulesRecordState,
  selectFulfillmentType,
  selectCurrentPickupSchedule,
  (state, fulfillmentType, currentPickupSchedule) =>
    FulfillmentType.PICKUP === fulfillmentType &&
    (!!currentPickupSchedule || PickupSchedulesStatus.Error === state?.status)
);
