import { createActionGroup, emptyProps } from '@ngrx/store';
import {
  Language,
  SessionRecord,
} from '../../services/session/models/session-record';
import { UpdateSessionRequest } from '../../services/session/models/update-session-request';

export const SessionActions = createActionGroup({
  source: 'Session',
  events: {
    'Get Session': emptyProps(),
    'Get Session Success': (sessionRecord: SessionRecord) => ({
      sessionRecord,
    }),
    'Refresh Customer Data': (
      customerId: string,
      compositeCustomerId: string,
      plantNumber: string
    ) => ({
      customerId,
      compositeCustomerId,
      plantNumber,
    }),
    'Refresh Customer Data Success': (compositeCustomerId: string) => ({
      compositeCustomerId,
    }),
    'Update Session': (updateSessionRequest: UpdateSessionRequest) => ({
      updateSessionRequest,
    }),
    'Update Session Success': (sessionRecord: SessionRecord) => ({
      sessionRecord,
    }),
    'Update Selected Customer': (compositeCustomerId: string) => ({
      compositeCustomerId,
    }),
    'Update Selected Language': (language: Language) => ({ language }),
    'Update Gordon Now Chat': emptyProps(),
  },
});
