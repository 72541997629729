import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WebBffService } from '../web-bff/web-bff.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AutocompleteService {
  autocompleteSuggestions = '/api/v1/materials/autocomplete';
  autocompleteMaxLength = 500;

  constructor(private http: HttpClient, private webBffService: WebBffService) {}

  public getAutocompleteSuggestions(
    searchPrefix: string
  ): Observable<string[]> {
    if (!!searchPrefix && searchPrefix.length > this.autocompleteMaxLength) {
      return of([]);
    }
    const params = new HttpParams().set(
      'searchPrefix',
      encodeURIComponent(searchPrefix)
    );
    return this.http.get<string[]>(
      this.webBffService.getBff() + this.autocompleteSuggestions,
      { params: params }
    );
  }
}
