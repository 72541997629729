import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import {
  GenericModalComponent,
  ImageData,
  MessageData,
} from '../../../modals/generic-modal/generic-modal.component';
import { NaooAnalyticsManager } from '../../../analytics/NaooAnalyticsManager';
import { LogoutService } from '../../logout/logout.service';
import { ScrollStrategy } from '@angular/cdk/overlay';
import { AnalyticsEventInfo } from '../../../analytics/analytics-event-info';
import { CustomDimension } from '../../../analytics/custom-dimension';
import { LoadingModalComponent } from '../../../modals/loading-modal/loading-modal.component';
import { NaooConstants } from '../../../NaooConstants';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  GenericHelpModalComponent,
  HelpModalValues,
} from '../../../modals/generic-helper-modal/generic-help-modal.component';

export const enum DialogPanelClass {
  Generic = 'generic-modal',
  GenericHelp = 'generic-help-modal',
}

type ActionFn = () => void;

@Injectable({ providedIn: 'root' })
export class DefaultDialogService {
  constructor(
    private dialog: MatDialog,
    private analytics: NaooAnalyticsManager,
    private logoutService: LogoutService
  ) {}

  public closeAllDialogs(): Observable<void> {
    this.dialog.closeAll();
    return this.dialog.afterAllClosed.pipe(first());
  }

  /**
   * Display a modal with a single button and a message.
   *
   * @remarks
   * This function is subject to change as more modals are transitioned to use the generic modal and needs may change
   *
   * @param id {string} Text we pass along to GA4 as the modal name
   * @param messageData {string | object} The main message to display in the modal. Supports parameter interpolation via the translate pipe.
   * To make use of this pass in an object {messageKey: 'key to the message', parameters: {key: value}}
   * @param primaryButtonText {string} Text for the button, should be passed in as a translation key
   * @param primaryButtonAction {Function} Function to be executed upon clicking the primary button.
   * @param isCloseable {boolean} Will display an X button in the upper right hand corner of the modal to close it,
   * defaults to false
   * @param isDestructive {boolean} Will show the modal in a red color scheme if true, used for deletion modals,
   * defaults to false
   * @param helperMessageData? {string | MessageData} Helper message data for the modal.
   * Displays in a smaller font underneath the main method.
   * @param image? {ImageData} Displays image for the the modal above the messageData.
   */
  public oneButtonModal(
    id: string,
    messageData: string | MessageData,
    primaryButtonText: string,
    primaryButtonAction: ActionFn,
    isCloseable: boolean = false,
    isDestructive: boolean = false,
    helperMessageData?: string | MessageData,
    image?: ImageData,
    closeFunction?: ActionFn
  ): MatDialogRef<GenericModalComponent> {
    if (this.areAllDialogsClosed()) {
      return this.dialog.open(GenericModalComponent, {
        data: {
          messageData: messageData,
          helperMessageData: helperMessageData,
          primaryButtonText: primaryButtonText,
          secondaryButtonText: null,
          primaryButtonAction: primaryButtonAction,
          secondaryButtonAction: null,
          closeAction: isCloseable
            ? () => {
                this.dialog.closeAll();
                if (closeFunction) {
                  closeFunction();
                }
              }
            : null,
          isDestructive: isDestructive,
          image: image,
        },
        id,
        panelClass: DialogPanelClass.Generic,
        disableClose: true,
      });
    }
  }

  /**
   * Will display a modal with a main message, and two buttons.
   *
   * @remarks
   * This function is subject to change as more modals are transitioned to use the generic modal and needs may change.
   * Primary button is considered the button that will complete the action that initiated the modal. If the modal is confirming a delete
   * the primary button should be the one that completes the delete.
   *
   * @param id {string} Text we pass along to GA4 as the modal name
   * @param messageData {string | MessageData} The main message to display in the modal.
   * Supports parameter interpolation via the translate pipe.
   * To make use of this pass in an object {messageKey: 'key to the message', parameters: {key: value}}
   * @param primaryButtonText {string} Text for the primary button, should be passed in as a translation key
   * @param secondaryButtonText {string} Text for the secondary button, should be passed in as a translation key
   * @param primaryButtonAction {Function} Function to be executed upon clicking the primary button.
   * @param secondaryButtonAction {Function} Function to be executed upon clicking the secondary button.
   * @param isCloseable {boolean} Will display an X button in the upper right hand corner of the modal to close it,
   * defaults to false
   * @param isDestructive {boolean} Will show the modal in a red color scheme if true, used for deletion modals,
   * defaults to false
   * @param helperMessageData? {string | MessageData} Helper message data for the modal.
   * Displays in a smaller font underneath the main method.
   * @param scrollStrategy? {ScrollStrategy} scroll strategy for the modal.
   */
  // eslint-disable-next-line max-params
  public twoButtonModal(
    id: string,
    messageData: string | MessageData,
    primaryButtonText: string,
    secondaryButtonText: string,
    primaryButtonAction: ActionFn,
    secondaryButtonAction: ActionFn,
    isCloseable: boolean = false,
    isDestructive: boolean = false,
    helperMessageData?: string | MessageData,
    scrollStrategy?: ScrollStrategy,
    allDialogsClosedOverride?: boolean
  ): MatDialogRef<GenericModalComponent> {
    if (this.areAllDialogsClosed() || allDialogsClosedOverride) {
      return this.dialog.open(GenericModalComponent, {
        data: {
          messageData: messageData,
          helperMessageData: helperMessageData,
          primaryButtonText: primaryButtonText,
          secondaryButtonText: secondaryButtonText,
          primaryButtonAction: primaryButtonAction,
          secondaryButtonAction: secondaryButtonAction,
          closeAction: isCloseable ? () => this.dialog.closeAll() : null,
          isDestructive: isDestructive,
          image: null,
        },
        id,
        scrollStrategy: scrollStrategy,
        panelClass: DialogPanelClass.Generic,
        disableClose: true,
      });
    }
  }

  /**
   * Display a prompt, a no button modal that can be forced open
   *
   * @remarks
   * This function is subject to change as more modals are transitioned to use the generic modal and needs may change
   *
   * @param id {string} Text we pass along to GA4 as the modal name
   * @param messageData {string | object} {string | object} The main message to display in the modal.
   * Supports parameter interpolation via the translate pipe. To make use of this pass in an object:
   * {
   *    message: '',
   *    parameter: {
   *      key: value
   *    }
   * }. With the key in the parameter being the string to replace in during interpolation.
   * @param isCloseable {boolean} Will display an X button in the upper right hand corner of the modal to close it,
   * defaults to false
   * @param shouldForceOpen {boolean} If true will force the modal to open over other modals that may be present,
   * useful for error modals. Defaults to false.
   * @param image? {ImageData=} Optional parameter for an image to display.
   * See {@link GenericModalComponent}'s [ImageData Interface]{@link GenericModalComponent#ImageData}
   * @param helperMessageData? {string | MessageData} Helper message data for the modal.
   * Displays in a smaller font underneath the main method.
   */
  // eslint-disable-next-line max-params
  public prompt(
    id: string,
    messageData: string | MessageData,
    isCloseable: boolean = false,
    shouldForceOpen: boolean = false,
    image?: ImageData,
    helperMessageData?: string | MessageData,
    primaryButtonText?: string,
    primaryButtonAction?: ActionFn,
    title?: string,
    panelClass: string = DialogPanelClass.Generic
  ): MatDialogRef<GenericModalComponent> {
    if (this.shouldAllowPrompt(shouldForceOpen)) {
      return this.dialog.open(GenericModalComponent, {
        data: {
          messageData,
          helperMessageData,
          primaryButtonText,
          secondaryButtonText: null,
          primaryButtonAction,
          secondaryButtonAction: null,
          closeAction: isCloseable ? () => this.dialog.closeAll() : null,
          isDestructive: false,
          image,
          title,
        },
        id,
        panelClass,
        disableClose: !isCloseable,
      });
    }
  }

  /**
   * Display a default error modal, more commonly known as "the oops modal"
   *
   * Displays "oops" message with button to reload the page and optional button to close the modal
   *
   * @remarks
   * The modal displayed by this function will be changed via NAOO-9284 to display a second "sign out" button
   *
   * @param customReason {string} A custom reason to passed to analytics for the error that occurred
   * @param isCloseable {boolean} If true will allow the modal to be closed
   * @param shouldForceOpen {boolean} If true the modal will force itself open, see NAOO-3910
   */
  public defaultErrorModal(
    customReason: string,
    shouldForceOpen?: boolean
  ): MatDialogRef<GenericModalComponent> {
    this.trackApplicationErrorModal(customReason);
    if (this.shouldAllowPrompt(shouldForceOpen)) {
      return this.dialog.open(GenericModalComponent, {
        data: {
          messageData: 'ERROR_MODAL.MESSAGE',
          helperMessageData: null,
          primaryButtonText: 'ERROR_MODAL.BUTTON_TEXT',
          secondaryButtonText: 'LOGOUT.TEXT',
          primaryButtonAction: () => window.location.reload(),
          secondaryButtonAction: () => this.logoutService.logout(),
          closeAction: () => this.dialog.closeAll(),
          isDestructive: false,
          image: null,
        },
        id: 'error-modal',
        panelClass: DialogPanelClass.Generic,
        disableClose: true,
      });
    }
  }

  /**
   * Display a helper modal
   *
   * @param id {string} Text we pass along to GA4 as the modal name
   * @param displayValues {HelpModalValues} The values to display in the generic helper component
   */
  public helperModal(
    id: string,
    displayValues: HelpModalValues
  ): MatDialogRef<GenericHelpModalComponent> {
    if (this.areAllDialogsClosed()) {
      return this.dialog.open(GenericHelpModalComponent, {
        data: {
          values: displayValues,
          closeAction: () => this.dialog.closeAll(),
        },
        id,
        panelClass: DialogPanelClass.GenericHelp,
        disableClose: false,
      });
    }
  }

  public openLoadingModal(dialogConfig?: Partial<MatDialogConfig>) {
    if (this.areAllDialogsClosed()) {
      const defaultConfig: MatDialogConfig = {
        id: NaooConstants.LOADING_MODAL_ID,
        panelClass: 'naoo-mat-dialog',
        disableClose: true,
      };
      const config: MatDialogConfig = {
        ...defaultConfig,
        ...dialogConfig,
      };
      if (!this.dialog.getDialogById(config.id)) {
        this.dialog.open(LoadingModalComponent, config);
      }
    }
  }

  public closeLoadingModal(dialogId?: string) {
    const dialog = this.dialog.getDialogById(
      dialogId ?? NaooConstants.LOADING_MODAL_ID
    );
    if (dialog) {
      dialog.close();
    }
  }

  private shouldAllowPrompt(shouldForceOpen?: boolean): boolean {
    return shouldForceOpen || this.areAllDialogsClosed();
  }

  private trackApplicationErrorModal(customReason: string) {
    const eventInfo: AnalyticsEventInfo = {
      action: 'displayed',
      category: 'error',
      label: 'app failure',
    };
    const customDimension: CustomDimension[] = [
      {
        index: 28,
        value: customReason,
      },
    ];
    this.analytics.trackAnalyticsEvent(eventInfo, customDimension);
  }

  private areAllDialogsClosed() {
    return this.dialog.openDialogs.every(
      (dialogRef) => MatDialogState.CLOSED === dialogRef.getState()
    );
  }
}
