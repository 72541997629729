import { MaterialRecommendationState } from './ecommerce-analytics.state';
import { EcommerceImpressionData } from '../../services/ecommerce-analytics/models/ecommerce-impression-data';
import { EcommercePromotionData } from '../../services/ecommerce-analytics/models/ecommerce-promotion-data';
import { EcommerceProductData } from '../../services/ecommerce-analytics/models/ecommerce-product-data';
import { EcommerceEvent } from '../../services/ecommerce-analytics/models/ecommerce-event';
import { DefaultUrlSerializer } from '@angular/router';
import {
  CartEntityState,
  CartLineState,
  CartMaterialState,
} from '../cart/cart.state';
import { CartMaterialUpdateRequest } from '../../services/cart/models/cart-record';
import { MaterialRecord } from '../../services/material-info/models/materials-info-record';
import { Dictionary } from '@ngrx/entity';
import { CombinedPricingRecord } from '../material-price/material-price.util';
import { Language } from '../../services/session/models/session-record';

export function clearEcommerceEvent(): EcommerceEvent {
  return {
    event: undefined,
    ecommerce: undefined,
  };
}

export function buildEcommerceRecommendationViewEvent(
  impressions: EcommerceImpressionData[]
): EcommerceEvent {
  return {
    event: 'productView',
    ecommerce: {
      impressions,
    },
  };
}

export function buildEcommerceRecommendationClickEvent(
  impression: EcommerceImpressionData
): EcommerceEvent {
  return {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: impression.list },
        products: [
          {
            name: impression.name,
            id: impression.id,
            brand: impression.brand,
            position: impression.position,
            dimension48: impression.dimension48,
            dimension49: impression.dimension49,
          },
        ],
      },
    },
  };
}

export function buildEcommercePromotionViewEvent(
  promotions: EcommercePromotionData[]
): EcommerceEvent {
  return {
    event: 'promotionView',
    ecommerce: {
      promoView: {
        promotions,
      },
    },
  };
}

export function buildEcommercePromotionClickEvent(
  promotions: EcommercePromotionData[]
): EcommerceEvent {
  return {
    event: 'promotionClick',
    ecommerce: {
      promoClick: {
        promotions,
      },
    },
  };
}

export function buildEcommerceDetailViewEvent(
  materialInfo: MaterialRecord,
  combinedPrice?: CombinedPricingRecord
): EcommerceEvent {
  const productData: EcommerceProductData = {
    id: materialInfo.materialNumber,
    name: materialInfo.description?.en,
    brand: materialInfo.brand?.en,
    category: 'empty',
    variant: materialInfo.baseUom,
  };

  if (combinedPrice) {
    productData.price = getCombinedPrice(combinedPrice, materialInfo.baseUom);
  }

  return {
    event: 'detail',
    ecommerce: {
      detail: {
        products: [productData],
      },
    },
  };
}

export function buildEcommerceAddToCartEvent(
  products: EcommerceProductData[],
  list: string,
  currency: string
): EcommerceEvent {
  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: currency,
      add: {
        actionField: {
          list,
        },
        products,
      },
    },
  };
}

export function buildEcommerceRemoveFromCartEvent(
  products: EcommerceProductData[],
  list: string,
  currency: string
): EcommerceEvent {
  return {
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: currency,
      remove: {
        actionField: {
          list,
        },
        products,
      },
    },
  };
}

export function buildEcommerceCheckoutEvent(
  products: EcommerceProductData[]
): EcommerceEvent {
  return {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
        },
        products,
      },
    },
  };
}

export function buildEcommercePurchaseEvent(
  cartId: string,
  totalCost: number,
  products: EcommerceProductData[]
): EcommerceEvent {
  return {
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: cartId,
          affiliation: 'Online Ordering',
          revenue: totalCost,
        },
        products,
      },
    },
  };
}

// eslint-disable-next-line max-params
export function buildEcommerceCartChangeEvents(
  changedMaterials: CartMaterialUpdateRequest[],
  currentCart: CartEntityState,
  previousCart: CartEntityState,
  materialInfos: Map<string, MaterialRecord>,
  materialPrices: Map<string, CombinedPricingRecord>,
  materialRecommendations: Map<string, MaterialRecommendationState>,
  clientId: string,
  list: string,
  currency: string,
  language: Language
): EcommerceEvent[] {
  const addToCartLines: EcommerceProductData[] = [];
  const removeFromCartLines: EcommerceProductData[] = [];

  changedMaterials.forEach((changedMaterial) => {
    const cartMaterialEntity =
      currentCart.materials.entities[changedMaterial.materialNumber];
    const previousMaterial =
      previousCart.materials.entities[changedMaterial.materialNumber];
    const materialInfo = materialInfos.get(changedMaterial.materialNumber);
    const materialPrice = materialPrices.get(changedMaterial.materialNumber);
    const materialRecommendation = materialRecommendations.get(
      changedMaterial.materialNumber
    );

    if (!!previousMaterial && !changedMaterial.restored) {
      if (cartMaterialEntity) {
        // New line added to existing material
        changedMaterial.lines
          .filter(
            (changedLine) => !previousMaterial.lines.entities[changedLine.uom]
          )
          .forEach((changedLine) =>
            addToCartLines.push(
              buildEcommerceProductData(
                cartMaterialEntity,
                changedLine,
                materialInfo,
                clientId,
                language,
                materialPrice,
                materialRecommendation
              )
            )
          );
      }

      // Removed existing line from material
      Object.values(previousMaterial.lines.entities)
        .filter(
          (previousLine) =>
            !changedMaterial.lines.find((line) => line.uom === previousLine.uom)
        )
        .forEach((previousLine) =>
          removeFromCartLines.push(
            buildEcommerceProductData(
              previousMaterial,
              previousLine,
              materialInfo,
              clientId,
              language,
              materialPrice,
              materialRecommendation,
              true
            )
          )
        );
    } else if (cartMaterialEntity) {
      // New material or an old material being restored
      changedMaterial.lines.forEach((changedLine) =>
        addToCartLines.push(
          buildEcommerceProductData(
            cartMaterialEntity,
            changedLine,
            materialInfo,
            clientId,
            language,
            materialPrice,
            materialRecommendation
          )
        )
      );
    }
  });

  const events: EcommerceEvent[] = [];
  if (addToCartLines.length > 0) {
    events.push(buildEcommerceAddToCartEvent(addToCartLines, list, currency));
  }
  if (removeFromCartLines.length > 0) {
    events.push(
      buildEcommerceRemoveFromCartEvent(removeFromCartLines, list, currency)
    );
  }

  return events;
}

export function buildEcommerceProductsDataFromCart(
  cart: CartEntityState,
  materialInfos: Map<string, MaterialRecord>,
  materialPrices: Map<string, CombinedPricingRecord>,
  clientId: string,
  language: Language
): EcommerceProductData[] {
  const productsData: EcommerceProductData[] = [];
  const cartEntities: Dictionary<CartMaterialState> =
    cart?.materials?.entities ?? {};
  Object.values(cartEntities)
    .filter((material) => !material.isDeleted)
    .forEach((material) => {
      const materialInfo = materialInfos.get(material.materialNumber);
      const materialPrice = materialPrices.get(material.materialNumber);

      Object.values(material.lines.entities)
        .filter((line) => line.quantity > 0)
        .forEach((line) =>
          productsData.push(
            buildEcommerceProductData(
              material,
              line,
              materialInfo,
              clientId,
              language,
              materialPrice
            )
          )
        );
    });

  return productsData;
}

export function buildEcommerceProductData(
  cartMaterial: CartMaterialState,
  cartLine: CartLineState,
  materialInfo: MaterialRecord,
  clientId: string,
  language: Language,
  combinedPricingRecord?: CombinedPricingRecord,
  materialRecommendation?: MaterialRecommendationState,
  isRemovingFromCart = false
): EcommerceProductData | undefined {
  if (!cartMaterial) {
    return undefined;
  }

  const ecommerceProductData: EcommerceProductData = {
    id: cartMaterial.materialNumber,
    name: null,
    brand: null,
    category: 'empty',
    variant: cartLine.uom,
    price: null,
    quantity:
      isRemovingFromCart || cartMaterial.isDeleted ? 0 : cartLine.quantity,
  };

  if (materialInfo) {
    ecommerceProductData.name = materialInfo.description?.en;

    ecommerceProductData.brand = materialInfo.brand?.en;
  }

  if (combinedPricingRecord) {
    ecommerceProductData.price = getCombinedPrice(
      combinedPricingRecord,
      cartLine.uom
    );
  }

  if (
    !!cartMaterial.originTrackingId &&
    cartMaterial.originTrackingId.trim().length > 0
  ) {
    ecommerceProductData.dimension25 = cartMaterial.originTrackingId;
  }

  if (cartMaterial.isAddedFromCriticalItem) {
    ecommerceProductData.dimension30 = true;
  }
  if (cartMaterial.isAddedFromMaterialComparison) {
    ecommerceProductData.dimension40 = true;
  }

  if (materialRecommendation) {
    ecommerceProductData.dimension48 = materialRecommendation.title[language];
    ecommerceProductData.dimension49 = clientId;
  }

  return ecommerceProductData;
}

export function buildEcommerceImpressionData(
  materialNumber: string,
  materialInfo: MaterialRecord,
  materialRecommendation: MaterialRecommendationState,
  clientId: string,
  language: Language
): EcommerceImpressionData {
  return {
    id: materialNumber,
    name: materialInfo.description?.en,
    list: materialRecommendation.name[language],
    brand: materialInfo.brand?.en,
    position: materialRecommendation.position,
    dimension48: materialRecommendation.title[language],
    dimension49: clientId,
  };
}

export function buildEcommercePromotionData(
  hrefUrl: string
): EcommercePromotionData {
  try {
    const serializer = new DefaultUrlSerializer();
    const treeQueryParamMap = serializer.parse(hrefUrl).queryParamMap;

    return {
      id: treeQueryParamMap.get('promo_id'),
      name: treeQueryParamMap.get('promo_name'),
      creative: treeQueryParamMap.get('promo_creative'),
      position: treeQueryParamMap.get('promo_position'),
    };
  } catch (err) {
    console.error('Parsing error for Promotional Code', err);
  }
  return {
    id: '',
    name: '',
    creative: '',
    position: '',
  };
}

function getCombinedPrice(
  combinedPrice: CombinedPricingRecord,
  uom: string
): number {
  const uomPrice = combinedPrice?.unitPrices?.find(
    (unitPrice) => unitPrice.salesUom === uom
  );
  return uomPrice ? uomPrice.price : 0;
}
