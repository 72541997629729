import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MarketingGuideRecord } from '../../services/marketing-guide/models/marketing-guide-record';

export interface MarketingGuideState {
  marketingGuideRecords: EntityState<MarketingGuideRecordState>;
}

export interface MarketingGuideRecordState {
  pathUrl: string;
  hasLoaded: boolean;
  searchText?: string;
  record?: MarketingGuideRecord;
}

export const marketingGuideAdapter = createEntityAdapter<MarketingGuideRecordState>(
  {
    selectId: (state) => state.pathUrl,
  }
);

export const initialMarketingGuideState: MarketingGuideState = {
  marketingGuideRecords: marketingGuideAdapter.getInitialState(),
};
