<form class="search-wrapper" (ngSubmit)="performSearch(searchText.value)">
  <input
    type="text"
    class="search-box"
    [ngClass]="{
      disabled: isDisabled
    }"
    [placeholder]="placeholderText | translate"
    #searchText
    name="searchText"
    [(ngModel)]="inputText"
    (ngModelChange)="
      updateAriaLabelAndPlaceholderText(); getAutocompleteSuggestions($event)
    "
    autocomplete="off"
    [matAutocomplete]="auto"
    [attr.aria-label]="ariaText | translate"
    [maxlength]="MAX_INPUT_LENGTH"
    [disabled]="isDisabled"
  />
  <button
    class="clear-icon"
    type="button"
    [attr.aria-label]="'SEARCH.CANCEL' | translate"
    (click)="clearAndFocus()"
    *ngIf="inputText && inputText.length"
    [disabled]="isDisabled"
  >
    <mat-icon svgIcon="clear_icon"></mat-icon>
  </button>
  <button
    class="search-button"
    [ngClass]="{
      disabled: isDisabled
    }"
    type="button"
    (click)="performSearch(searchText.value)"
    [attr.aria-label]="'SEARCH.TITLE' | translate"
    [disabled]="isDisabled"
  >
    <mat-icon svgIcon="search_icon_v2"></mat-icon
    >{{ (!isMobile ? 'SEARCH.TITLE' : '') | translate }}
  </button>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="performSearch($event.option.value)"
    [disableRipple]="true"
  >
    <mat-option *ngFor="let option of options" [value]="option">
      <div innerHtml="{{ option | highlight: inputText }}"></div>
    </mat-option>
  </mat-autocomplete>
</form>
