import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MaterialFlag,
  MaterialFlagType,
} from '../../../../material-flag/material-flag';
import { SessionFacade } from '../../../../core/store/session/session.facade';
import { first } from 'rxjs/operators';
import moment from 'moment';
import { Locale } from 'src/app/core/services/session/models/session-record';
import { NaooDeliveryOrShipPipe } from '../../../../shared/pipes/delivery-or-ship.pipe';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { LocalizedUtilities } from '../../../../shared/utilities/localized-utilities';

@Component({
  selector: 'naoo-material-flag',
  templateUrl: './material-flag.component.html',
  styleUrls: ['./material-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialFlagComponent implements OnInit {
  @Input() materialFlag: MaterialFlag;
  @Input() index: number;

  @Input() set currentLocale(locale: Locale) {
    this._currentLocale = locale;
    this.setFlagProperties();
  }

  @ViewChild('flag') flagElementRef: ElementRef;

  flagText: string;
  flagClass: string;
  tooltipClass: string;
  tooltipText: string = undefined;

  private readonly centerTooltip = 'center-tooltip';
  private readonly centerVariableWidthTooltip = 'center-variable-width-tooltip';
  private readonly leftTooltip = 'left-tooltip';
  private readonly leftVariableWidthTooltip = 'left-variable-width-tooltip';
  private readonly foodBuyGrey = 'food-buy-grey';
  private timeZone: string;
  private _currentLocale: Locale;
  private isTooltipFocused: boolean;

  constructor(
    private sessionFacade: SessionFacade,
    private localizationService: LocalizationService,
    private deliveryOrShipPipe: NaooDeliveryOrShipPipe
  ) {}

  ngOnInit() {
    this.sessionFacade
      .getLoadedActiveCustomerTimeZone()
      .pipe(first())
      .subscribe((timeZone) => {
        this.timeZone = timeZone;
        this.setFlagProperties();
      });
  }

  onBlur(): void {
    this.isTooltipFocused = false;
  }

  onClick(): void {
    if (this.isTooltipFocused) {
      this.flagElementRef.nativeElement.blur();
    } else {
      this.isTooltipFocused = true;
    }
  }

  private getTooltipClass(variableWidth: boolean): string {
    if (this.index <= 2) {
      return variableWidth ? this.leftVariableWidthTooltip : this.leftTooltip;
    }
    return variableWidth ? this.centerVariableWidthTooltip : this.centerTooltip;
  }

  private setFlagProperties() {
    switch (this.materialFlag.type) {
      case MaterialFlagType.Entitlement:
        this.flagText = 'MATERIAL_FLAG.ENTITLEMENT';
        this.flagClass = this.materialFlag.entitlementParams.shieldColor;
        this.tooltipText = this.localizationService.instant(
          this.materialFlag.entitlementParams.descriptionKey
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.Contract:
        this.flagText = 'MATERIAL_FLAG.CONTRACT_FLAG';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.CONTRACT_TOOLTIP'
        );
        this.flagClass = 'blue';
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.EarlyCutoff:
        this.flagText = 'MATERIAL_FLAG.EARLY_CUTOFF';
        this.flagClass = 'red';
        this.tooltipText = this.generateEarlyCutoffText();
        this.tooltipClass = this.getTooltipClass(false);
        break;
      case MaterialFlagType.Local:
        this.flagText = 'MATERIAL_FLAG.LOCAL_FLAG';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.LOCAL_TOOLTIP'
        );
        this.flagClass = 'light-green';
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.OrderGuide:
        this.flagText = 'MATERIAL_FLAG.ORDER_GUIDE';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.ORDER_GUIDE_TOOLTIP'
        );
        this.flagClass = 'green';
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.SpecialOrder:
        this.flagText = 'MATERIAL_FLAG.SPECIAL_ORDER';
        this.flagClass = 'yellow';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.SPECIAL_ORDER_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(false);
        break;
      case MaterialFlagType.DropShip:
        this.flagText = 'MATERIAL_FLAG.DROP_SHIP';
        this.flagClass = 'orderve-orange';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.DROP_SHIP_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(false);
        break;
      case MaterialFlagType.Avendra:
        this.flagText = 'MATERIAL_FLAG.AVENDRA';
        this.flagClass = 'light-blue';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.AVENDRA_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.BidLockedItem:
        this.flagText = 'MATERIAL_FLAG.BID_LOCKED_ITEM';
        this.flagClass = 'light-purple';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.BID_LOCKED_ITEM_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.CommittedManufacturerAllowance:
        this.flagText = 'MATERIAL_FLAG.COMMITTED_MANUFACTURER_ALLOWANCE';
        this.flagClass = 'light-pink';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.COMMITTED_MANUFACTURER_ALLOWANCE_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.PreferredItem:
        this.flagText = 'MATERIAL_FLAG.PREFERRED_ITEM';
        this.flagClass = 'light-orange';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.PREFERRED_ITEM_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.Rebate:
        this.flagText = 'MATERIAL_FLAG.REBATE';
        this.flagClass = 'rebate-green';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.REBATE_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.FoodBuy_S1:
        this.flagText = 'MATERIAL_FLAG.FOOD_BUY_S1';
        this.flagClass = this.foodBuyGrey;
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.FOOD_BUY_S1_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.FoodBuy_S2:
        this.flagText = 'MATERIAL_FLAG.FOOD_BUY_S2';
        this.flagClass = this.foodBuyGrey;
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.FOOD_BUY_S2_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.FoodBuy_S3:
        this.flagText = 'MATERIAL_FLAG.FOOD_BUY_S3';
        this.flagClass = this.foodBuyGrey;
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.FOOD_BUY_S3_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.MWBEVendor:
        this.flagText = 'MATERIAL_FLAG.MWBE_VENDOR';
        this.flagClass = 'light-brown';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.MWBE_VENDOR_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(false);
        break;
      case MaterialFlagType.ContractPrice:
        this.flagText = 'MATERIAL_FLAG.CONTRACT_PRICE';
        this.flagClass = 'orange';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.CONTRACT_PRICE_TOOLTIP'
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      case MaterialFlagType.Bulk:
        this.flagText = this.localizationService.instant('MATERIAL_FLAG.BULK', {
          threshold: this.materialFlag.bulkParams.threshold,
        });
        this.flagClass = 'green';
        this.tooltipText = this.localizationService.instant(
          'MATERIAL_FLAG.BULK_TOOLTIP',
          {
            threshold: this.materialFlag.bulkParams.threshold,
            price: LocalizedUtilities.getLocalizedPrice(
              this._currentLocale,
              this.materialFlag.bulkParams.price
            ),
          }
        );
        this.tooltipClass = this.getTooltipClass(true);
        break;
      default:
    }
  }

  private generateEarlyCutoffText(): string {
    if (
      !!this.materialFlag.earlyCutoffParams &&
      !this.materialFlag.earlyCutoffParams.hasShipDate
    ) {
      return this.localizationService.instant(
        this.deliveryOrShipPipe.transform('MATERIAL_FLAG_NO_DATE')
      );
    }

    if (
      !!this.materialFlag.earlyCutoffParams &&
      !this.materialFlag.earlyCutoffParams.haveCutoffsLoaded
    ) {
      return this.localizationService.instant(
        'MATERIAL_FLAG.EARLY_CUTOFF_TITLE'
      );
    }

    if (!this.materialFlag.earlyCutoffParams?.cutoffDateTime) {
      return this.localizationService.instant('MATERIAL_FLAG.ERROR');
    }

    const orderBy = this.localizationService.instant('MATERIAL_FLAG.ORDER_BY');
    const on = this.localizationService.instant('MATERIAL_FLAG.ON');

    const momentTimeZone = moment.tz(
      this.materialFlag.earlyCutoffParams.cutoffDateTime,
      this.timeZone
    );
    momentTimeZone.locale(this._currentLocale);

    const timeZoneAbbr = this.localizationService.instant(
      'TIMEZONES.' + momentTimeZone.zoneAbbr()
    );

    if (Locale.en_CA === this._currentLocale) {
      return `${orderBy} ${momentTimeZone.format(
        'LT'
      )} ${timeZoneAbbr} ${on} ${momentTimeZone.format('dddd, MMMM Do')}`;
    } else if (Locale.fr_CA === this._currentLocale) {
      const dayName = momentTimeZone.format('dddd');
      const date = momentTimeZone.format('DD MMMM LT');

      return `${orderBy} ${dayName} ${on} ${date} ${timeZoneAbbr}`;
    }
  }
}
