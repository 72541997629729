import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientJsonpModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapsNaooComponent } from './component/google-maps/google-maps.component';
import { GoogleAutocompleteNaooComponent } from './component/google-autocomplete/google-autocomplete.component';
import { GoogleMapsNaooContainerComponent } from './component/google-maps-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { OrderMethodModalSharedModule } from '../modals/order-method-modal/shared/order-method-modal-shared.module';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    GoogleMapsNaooComponent,
    GoogleAutocompleteNaooComponent,
    GoogleMapsNaooContainerComponent,
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
    TranslateModule,
    OrderMethodModalSharedModule,
    SharedModule,
  ],
  exports: [GoogleMapsNaooContainerComponent],
})
export class GoogleMapsNaooModule {}
