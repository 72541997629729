import { EMPTY, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { NaooConstants } from '../NaooConstants';
import { AuthService } from '../services/auth/auth.service';
import { environment } from '../../../environments/environment';
import { getCookie } from '../utilities/cookie-utilities';

@Injectable()
export class NaooSecurityInterceptor implements HttpInterceptor {
  private readonly httpStatusMapping: Map<number, number> = new Map([
    [218, 418],
    [220, 520],
  ]);

  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken();
    if (environment.usesWiremock && authToken) {
      request = request.clone({
        setHeaders: {
          [NaooConstants.X_AUTH_TOKEN_HEADER]: authToken,
        },
        withCredentials: false,
      });
      // Adding with credentials here for good measure
    } else if (request.url.match(window.location.hostname)) {
      request = request.clone({
        setHeaders: {
          [NaooConstants.X_XSRF_HEADER]: getCookie(NaooConstants.XSRF_TOKEN),
          [NaooConstants.X_REQUESTED_WITH_HEADER]: 'XMLHttpRequest',
        },
        withCredentials: true,
      });
    }
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.rethrowHttpErrorResponse(event);
        }
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          const authError = this.handleErrorResponse(err);
          if (authError) {
            return EMPTY;
          }
        }
        return observableThrowError(err);
      })
    );
  }

  private rethrowHttpErrorResponse(event: HttpResponse<any>) {
    if (this.httpStatusMapping.has(event.status)) {
      throw new HttpErrorResponse({
        error: event.body,
        headers: event.headers,
        status: this.httpStatusMapping.get(event.status),
        statusText: event.statusText,
        url: event.url,
      });
    }
  }

  private handleErrorResponse(err: HttpErrorResponse): boolean {
    // if we are an authentication error, BFF will hand us back a header of Login-Location,
    // whose value is the URL of the login page for whatever authentication scheme the BFF allows (e.g., CAS, Fake Login)
    const loginLocation = err.headers.get(NaooConstants.LOGIN_LOCATION_HEADER);
    const isAuthError = loginLocation
      ? loginLocation.indexOf('http') !== -1
      : false;
    if (isAuthError) {
      this.authService.processAuthError(loginLocation);
    }
    return isAuthError;
  }
}
