import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CartCounts,
  getCartLineCount,
  getTotalQuantity,
  selectAllCartMaterials,
  selectAllOrderableCartMaterials,
  selectCartAvailability,
  selectCartEntity,
  selectCartInventory,
  selectCartProductInfo,
  selectCurrentCartMaterialNumbers,
  selectFocusedMaterial,
  selectFulfillmentType,
  selectInvalidDropShipOrders,
  selectIsCartLoaded,
  selectPoNumber,
} from 'src/app/core/store/cart/cart.selectors';
import { selectRemainingCriticalItemsInfo } from 'src/app/core/store/critical-items/critical-items.selector';
import {
  selectOrderConfirmationEmailNotifications,
  selectOrderConfirmationEmailNotificationsOptOut,
} from 'src/app/core/store/customer-preferences/customer-preferences.selectors';
import { MaterialInfoRecordState } from 'src/app/core/store/material-info/material-info.state';
import { InventoryAvailabilityRecordState } from 'src/app/core/store/inventory-availability/inventory-availability.state';
import { transformMaterialWarningProperties } from 'src/app/core/store/material-warning/material-warning-transformer';
import {
  selectConsiderInventory,
  selectMaterialCutoffEntities,
} from 'src/app/core/store/material-cutoff/material-cutoff.selectors';
import {
  selectActiveCustomer,
  selectCanPlaceIspuOrExpressOrder,
  selectSession,
} from 'src/app/core/store/session/session.selectors';
import { MaterialWarningType } from 'src/app/core/store/material-warning/material-warning';
import { selectAllMaterialWarningEntityStates } from 'src/app/core/store/material-warning/material-warning.selectors';
import { SessionRecord } from 'src/app/core/services/session/models/session-record';
import {
  CartMaterialState,
  FocusedMaterialState,
} from 'src/app/core/store/cart/cart.state';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import {
  hasOpenSpecialOrders,
  selectSpecialOrders,
} from 'src/app/core/store/special-orders/special-orders.selectors';
import {
  selectAllEntitlementMaterialDetails,
  selectOverallocatedMaterialDetails,
} from '../entitlement/entitlement.selectors';
import {
  cartReviewSectionAdapter,
  CartReviewSectionGroup,
  CartReviewSectionName,
  CartReviewState,
  OrderConfirmationEmailOptIn,
} from './cart-review.state';
import {
  getUnavailableMaterials,
  isCartReviewLoading,
} from './transform/cart-review-util';
import { selectAllMaterialAvailabilityRecords } from '../material-availability/material-availability.selectors';
import { StockType } from '../../services/material-availability/model/material-availabilities-record';
import { DeliveryScheduleEntryRecord } from '../../services/delivery-schedule/models/delivery-schedules-record';
import {
  selectHasDeliverySchedulesFinishedLoading,
  selectHasSubmittableDeliveryFulfillment,
} from '../delivery-schedule/delivery-schedule.selectors';
import { SplitOrder } from '../../services/cart-order/models/cart-order';
import { StoreRecord } from '../../services/store/model/store-record';
import { formatDateWithoutTimeZone } from '../../../shared/utilities/date-utilities';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { ExpressDeliveryWindow } from '../../services/express-schedules/models/express-schedule-record';
import {
  selectCurrentExpressDeliveryWindow,
  selectHasExpressSchedulesLoaded,
  selectHasSubmittableExpressFulfillment,
} from '../express-schedules/express-schedules.selectors';
import {
  ActiveStoreFulfillment,
  selectActiveStoreFulfillment,
} from '../store/store.selectors';
import { SelectableButton } from '../../../cart/selectable-button/selectable-button.component';
import { NotificationRecord } from '../../services/customer-notification-preferences/models/customer-notification-preferences-record';
import {
  selectCurrentPickupLoaded,
  selectHasSubmittablePickupFulfillment,
} from '../pickup-schedules/pickup-schedules.selectors';
import { selectContextualRouteDate } from '../route-date/route-date.selectors';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';

export interface CartReview {
  groups: CartReviewSectionGroup[];
  isOffline: boolean;
  emailOptIn?: OrderConfirmationEmailOptIn;
  selectedCreditCard?: boolean;
  enablePaymentOption?: boolean;
  storeOrderPaymentDetails?: StoreOrderPaymentOptions;
  invalidDropShipOrders?: SplitOrder[];
  failedDropShipSplitMaterials?: string[];
}

export interface StoreOrderPaymentOptions {
  paymentChoices: SelectableButton[];
  paymentRequired: boolean;
  paymentAvailable: boolean;
  checkoutId: string;
}

export interface CartReviewTransformerData {
  materialInfos: MaterialInfoRecordState[];
  availabilities: MaterialAvailabilityRecordState[];
  criticalItemInfos: MaterialInfoRecordState[];
  warnings: CartReviewWarning[];
  session: SessionRecord;
  headerData: CartReviewTransformerHeaderData;
  failedDropShipSplitMaterials?: string[];
  storeData: CartReviewTransformerStoreData;
}

export interface CartReviewTransformerStoreData {
  fulfillmentType: FulfillmentType;
  store?: StoreRecord;
  currentExpressDeliveryWindow?: ExpressDeliveryWindow;
}

export interface CartReviewTransformerHeaderData {
  cartReviewHeaderData: CartReviewHeaderData;
  emailNotifications: NotificationRecord[];
  emailOptOuts: string[];
}

export interface CartReviewData {
  cartMaterials: CartMaterialState[];
  infos: MaterialInfoRecordState[];
  availabilities: MaterialAvailabilityRecordState[];
  inventories: InventoryAvailabilityRecordState[];
  focusedMaterial: FocusedMaterialState;
  cartReviewHeaderData: CartReviewHeaderData;
}

export interface CartReviewHeaderData {
  customerHeaderInfo: CustomerHeaderInfo;
  routeDate?: Date;
  poNumber?: string;
}

export interface CustomerHeaderInfo {
  customerName: string;
  customerId: string;
}

export interface CartReviewWarning {
  type: MaterialWarningType;
  materialNumber: string;
}

export interface StoreCountsContext {
  cartCounts: CartCounts[];
  dateHasBeenResolved: boolean;
  name: CartReviewSectionName;
}

const selectCartReviewFeature = createFeatureSelector<CartReviewState>(
  'cartReview'
);

/**
 * returns if the user's credit payment/preAuthorization has completed
 *  - returns true if credit payment is not applicable.
 */
export const selectCreditPaymentActionCompleted = createSelector(
  selectCartReviewFeature,
  (state) => !state.selectedCreditCard || state.aciExecutePaymentResolved
);

export const selectCreditWidgetLoadingState = createSelector(
  selectCartReviewFeature,
  (state) => state.creditWidgetLoadingState
);

const selectCartReviewHeaderData = createSelector(
  selectActiveCustomer,
  selectContextualRouteDate,
  selectPoNumber,
  (selectedCustomer, routeDate, poNumber): CartReviewHeaderData => {
    return {
      customerHeaderInfo: {
        customerName: selectedCustomer?.name,
        customerId: selectedCustomer?.customerDisplayId,
      },
      routeDate,
      poNumber,
    };
  }
);

export const selectCartReviewData = createSelector(
  selectAllCartMaterials,
  selectCartProductInfo,
  selectCartAvailability,
  selectCartInventory,
  selectFocusedMaterial,
  selectCartReviewHeaderData,
  (
    cartMaterials: CartMaterialState[],
    infos: MaterialInfoRecordState[],
    availabilities: MaterialAvailabilityRecordState[],
    inventories: InventoryAvailabilityRecordState[],
    focusedMaterial: FocusedMaterialState,
    cartReviewHeaderData: CartReviewHeaderData
  ): CartReviewData => {
    return {
      cartMaterials,
      infos,
      availabilities,
      inventories,
      focusedMaterial,
      cartReviewHeaderData,
    };
  }
);

const selectCartWarningProperties = createSelector(
  selectCartReviewData,
  selectContextualRouteDate,
  selectConsiderInventory,
  selectSpecialOrders,
  selectMaterialCutoffEntities,
  selectActiveCustomer,
  selectAllEntitlementMaterialDetails,
  selectOverallocatedMaterialDetails,
  selectCartEntity,
  (
    cartMaterialData,
    routeDate,
    considerInventory,
    specialOrders,
    cutoffs,
    activeCustomer,
    entitlementMaterialDetails,
    overallocatedMaterialsDetails,
    cartEntityState
  ): CartReviewWarning[] => {
    return cartMaterialData.cartMaterials
      .map((cartMaterial) => {
        const infoForCartMaterial = cartMaterialData.infos.find(
          (info) =>
            !!info &&
            info.materialNumber === cartMaterial.materialNumber &&
            !!info.record
        );
        const availabilityForCartMaterial = cartMaterialData.availabilities.find(
          (availability) =>
            !!availability &&
            availability.materialNumber === cartMaterial.materialNumber &&
            !!availability.record
        );
        const inventoryForCartMaterial = cartMaterialData.inventories.find(
          (inventory) =>
            !!inventory &&
            inventory.materialNumber === cartMaterial.materialNumber &&
            !!inventory.record
        );

        if (!infoForCartMaterial || !availabilityForCartMaterial) {
          return undefined;
        }

        const inventoryRecord = inventoryForCartMaterial
          ? inventoryForCartMaterial.record
          : undefined;
        return {
          materialNumber: cartMaterial.materialNumber,
          type: transformMaterialWarningProperties({
            materialNumber: cartMaterial.materialNumber,
            cartMaterial,
            materialRecord: infoForCartMaterial.record,
            availability: availabilityForCartMaterial.record,
            inventory: inventoryRecord,
            shouldConsiderInventory: considerInventory,
            routeDate,
            focusedMaterialState: cartMaterialData.focusedMaterial,
            hasOpenSpecialOrders: hasOpenSpecialOrders(
              cartMaterial.materialNumber,
              specialOrders
            ),
            cutoffs,
            activeCustomer,
            entitlementMaterialDetail:
              entitlementMaterialDetails?.entities[cartMaterial.materialNumber],
            overallocatedMaterialDetail:
              overallocatedMaterialsDetails?.entities[
                cartMaterial.materialNumber
              ],
            fulfillmentType: cartEntityState.fulfillmentType,
          }).warningType,
        };
      })
      .filter((cartReviewWarning) => !!cartReviewWarning);
  }
);

export const selectUnacknowledgedCartMaterialWarnings = createSelector(
  selectCartWarningProperties,
  selectAllMaterialWarningEntityStates,
  (cartReviewWarnings, materialWarnings): CartReviewWarning[] => {
    return cartReviewWarnings
      .map((warning) => {
        const warningState = materialWarnings[warning.materialNumber];
        if (
          !warningState ||
          !warningState.acknowledgedWarnings.includes(warning.type)
        ) {
          return warning;
        }
      })
      .filter((cartReviewWarning) => !!cartReviewWarning);
  }
);

export const selectIsCartLoadedForActiveCustomer = createSelector(
  selectActiveCustomer,
  selectIsCartLoaded,
  (sessionActiveCustomer, isCartLoaded): boolean =>
    !!sessionActiveCustomer && isCartLoaded
);

export const selectIsCartReviewLoading = createSelector(
  selectCartReviewFeature,
  (state: CartReviewState): boolean => state.isLoading
);

export const selectCartReview = createSelector(
  selectCartReviewFeature,
  selectInvalidDropShipOrders,
  selectCanPlaceIspuOrExpressOrder,
  selectIsOffline,
  (
    state: CartReviewState,
    invalidDropShipOrders: SplitOrder[],
    canPlaceIspuOrExpressOrder: boolean,
    isOffline: boolean
  ): CartReview | undefined =>
    state.isLoading
      ? undefined
      : {
          groups: cartReviewSectionAdapter
            .getSelectors()
            .selectAll(state.groups)
            .sort((a, b) => a.displayOrder - b.displayOrder),
          isOffline,
          emailOptIn: state.emailOptIn,
          invalidDropShipOrders,
          enablePaymentOption: canPlaceIspuOrExpressOrder,
          storeOrderPaymentDetails: state.storePaymentOptions,
          failedDropShipSplitMaterials: state.failedDropShipSplitMaterials,
        }
);

export const selectHasFailedFulfillmentSplitOrder = createSelector(
  selectCartReview,
  (cartReview): boolean => !!cartReview?.failedDropShipSplitMaterials?.length
);

export const selectCartDropshipMaterials = createSelector(
  selectCurrentCartMaterialNumbers,
  selectAllMaterialAvailabilityRecords,
  (cartMaterialNumbers, materialAvailability) => {
    return cartMaterialNumbers.filter(
      (materialNumber) =>
        StockType.DropShip ===
        materialAvailability[materialNumber]?.record?.stockType
    );
  }
);

export const selectIsSelectedFulfillmentValidForSubmission = createSelector(
  selectHasSubmittableDeliveryFulfillment,
  selectHasSubmittableExpressFulfillment,
  selectHasSubmittablePickupFulfillment,
  (
    hasValidFulfillmentDeliverySchedule,
    hasValidFulfillmentExpressSchedule,
    hasValidFulfillmentPickupSchedule
  ): boolean =>
    hasValidFulfillmentDeliverySchedule ||
    hasValidFulfillmentExpressSchedule ||
    hasValidFulfillmentPickupSchedule
);

export const selectFulfillmentStateIsLoaded = createSelector(
  selectHasDeliverySchedulesFinishedLoading,
  selectHasExpressSchedulesLoaded,
  selectCurrentPickupLoaded,
  (
    hasLoadedDeliverySchedule,
    hasLoadedExpressSchedule,
    hasLoadedPickupSchedule
  ) => {
    return (
      hasLoadedDeliverySchedule &&
      hasLoadedExpressSchedule &&
      hasLoadedPickupSchedule
    );
  }
);

export const selectIsCartReviewDataLoaded = createSelector(
  selectCartProductInfo,
  selectCartAvailability,
  selectCartInventory,
  selectRemainingCriticalItemsInfo,
  selectIsSelectedFulfillmentValidForSubmission,
  selectMaterialCutoffEntities,
  (
    materialInfos: MaterialInfoRecordState[],
    availabilities: MaterialAvailabilityRecordState[],
    inventories: InventoryAvailabilityRecordState[],
    criticalItemInfos: MaterialInfoRecordState[] | undefined,
    isSelectedFulfillmentValidForSubmission: boolean
  ): boolean => {
    const unavailableMaterials = getUnavailableMaterials(
      availabilities,
      materialInfos
    );
    return !isCartReviewLoading(
      materialInfos,
      availabilities,
      inventories,
      unavailableMaterials,
      criticalItemInfos,
      isSelectedFulfillmentValidForSubmission
    );
  }
);

const selectCartReviewTransformerHeaderData = createSelector(
  selectCartReviewHeaderData,
  selectOrderConfirmationEmailNotifications,
  selectOrderConfirmationEmailNotificationsOptOut,
  (
    cartReviewHeaderData: CartReviewHeaderData,
    emailNotifications: NotificationRecord[],
    emailOptOuts: string[]
  ): CartReviewTransformerHeaderData => {
    return {
      cartReviewHeaderData,
      emailNotifications,
      emailOptOuts,
    };
  }
);

export const selectCartReviewTransformerStoreData = createSelector(
  selectFulfillmentType,
  selectActiveStoreFulfillment,
  selectCurrentExpressDeliveryWindow,
  (
    fulfillmentType: FulfillmentType,
    activeStoreFulfillment?: ActiveStoreFulfillment,
    currentExpressDeliveryWindow?: ExpressDeliveryWindow
  ): CartReviewTransformerStoreData => {
    return {
      fulfillmentType,
      store: activeStoreFulfillment?.store,
      currentExpressDeliveryWindow,
    };
  }
);

export const selectCartReviewTransformerData = createSelector(
  selectCartProductInfo,
  selectCartAvailability,
  selectRemainingCriticalItemsInfo,
  selectUnacknowledgedCartMaterialWarnings,
  selectSession,
  selectCartReviewTransformerHeaderData,
  selectCartReviewTransformerStoreData,
  (
    materialInfos: MaterialInfoRecordState[],
    availabilities: MaterialAvailabilityRecordState[],
    criticalItemInfos: MaterialInfoRecordState[],
    warnings: CartReviewWarning[],
    session: SessionRecord,
    headerData: CartReviewTransformerHeaderData,
    storeData: CartReviewTransformerStoreData
  ): CartReviewTransformerData => {
    return {
      materialInfos,
      availabilities,
      criticalItemInfos,
      warnings,
      session,
      headerData,
      storeData,
    };
  }
);

export const selectSelectedCreditCardOption = createSelector(
  selectCartReviewFeature,
  (state): boolean => state.selectedCreditCard
);

export const selectCutoffSplitNextValidDeliverySchedules = createSelector(
  selectCartReviewFeature,
  (state): DeliveryScheduleEntryRecord[] | undefined => {
    if (state.isLoading) {
      return undefined;
    }
    const group =
      state.groups?.entities[CartReviewSectionName.CutoffSplitItemsSection];
    return group?.sections?.length
      ? group.sections[0].nextValidDeliverySchedules
      : [];
  }
);

const selectCartReviewSectionMaterialMap = createSelector(
  selectCartReviewFeature,
  (cartReviewState) => {
    const cartReviewSectionMaterialMap = new Map<
      CartReviewSectionName,
      string[][]
    >();

    const cartReviewGroups = cartReviewSectionAdapter
      .getSelectors()
      .selectAll(cartReviewState.groups);

    cartReviewGroups.forEach((group) => {
      const sectionAggregateMaterialNumbers = group.sections.map((section) =>
        section.materialNumbers.map(
          (materialRows) => materialRows.value as string
        )
      );
      cartReviewSectionMaterialMap.set(
        group.name,
        sectionAggregateMaterialNumbers
      );
    });

    return cartReviewSectionMaterialMap;
  }
);

const selectTotalQuantityCartReviewSections = createSelector(
  selectCartReviewSectionMaterialMap,
  selectAllOrderableCartMaterials,
  (cartReviewSectionMaterialMap, cartMaterials) => {
    const totalQuantityForCartReviewSections = new Map<
      CartReviewSectionName,
      number[]
    >();

    cartReviewSectionMaterialMap.forEach(
      (sectionAggregateMaterialsNumbers, sectionName) => {
        const totalQuantitiesForSections: number[] = [];

        for (let i = 0; i < sectionAggregateMaterialsNumbers.length; i++) {
          totalQuantitiesForSections[i] = cartMaterials
            .filter((material) =>
              sectionAggregateMaterialsNumbers[i].includes(
                material.materialNumber
              )
            )
            .reduce(
              (totalQuantity, cartMaterial) =>
                getTotalQuantity(totalQuantity, cartMaterial),
              0
            );
        }

        totalQuantityForCartReviewSections.set(
          sectionName,
          totalQuantitiesForSections
        );
      }
    );

    return totalQuantityForCartReviewSections;
  }
);

const selectLineCountForCartReviewSections = createSelector(
  selectCartReviewSectionMaterialMap,
  selectAllOrderableCartMaterials,
  (cartReviewSectionMaterialMap, cartMaterials) => {
    const lineCountsForCartReviewSections = new Map<
      CartReviewSectionName,
      number[]
    >();

    cartReviewSectionMaterialMap.forEach(
      (sectionAggregateMaterialsNumbers, sectionName) => {
        const lineCountsForSections: number[] = [];

        for (let i = 0; i < sectionAggregateMaterialsNumbers.length; i++) {
          lineCountsForSections[i] = cartMaterials
            .filter((material) =>
              sectionAggregateMaterialsNumbers[i].includes(
                material.materialNumber
              )
            )
            .reduce((totalLineCount, cartMaterial) => {
              return getCartLineCount(totalLineCount, cartMaterial);
            }, 0);
        }

        lineCountsForCartReviewSections.set(sectionName, lineCountsForSections);
      }
    );

    return lineCountsForCartReviewSections;
  }
);

const selectCartReviewGroupByName = (name: CartReviewSectionName) =>
  createSelector(selectCartReviewFeature, (cartReviewState: CartReviewState):
    | CartReviewSectionGroup
    | undefined =>
    cartReviewSectionAdapter
      .getSelectors()
      .selectAll(cartReviewState.groups)
      .find((group) => name === group.name)
  );

const selectCartReviewStoreGroup = createSelector(
  selectCartReviewGroupByName(CartReviewSectionName.ExpressItemsSection),
  selectCartReviewGroupByName(CartReviewSectionName.PickupItemsSection),
  (
    expressGroup: CartReviewSectionGroup,
    pickupGroup: CartReviewSectionGroup
  ): CartReviewSectionGroup | undefined => expressGroup ?? pickupGroup
);

const selectEstimatedDeliveryDatesForCartReviewSections = createSelector(
  selectCartReviewStoreGroup,
  (
    cartReviewGroup: CartReviewSectionGroup
  ): Map<CartReviewSectionName, string[]> => {
    const name = cartReviewGroup?.name;
    const sections = cartReviewGroup?.sections ?? [];
    const sectionEstimateDatesMap = new Map<CartReviewSectionName, string[]>();

    if (CartReviewSectionName.ExpressItemsSection === name) {
      sectionEstimateDatesMap.set(
        CartReviewSectionName.ExpressItemsSection,
        sections.map((section) =>
          formatDateWithoutTimeZone(
            section.expressResolutionDate?.deliveryWindowStart
          )
        )
      );
    } else if (CartReviewSectionName.PickupItemsSection === name) {
      sectionEstimateDatesMap.set(
        CartReviewSectionName.PickupItemsSection,
        sections.map((section) =>
          formatDateWithoutTimeZone(section.pickupResolutionDate?.format())
        )
      );
    }
    return sectionEstimateDatesMap;
  }
);

export const selectCartCountsForCartReview = createSelector(
  selectLineCountForCartReviewSections,
  selectTotalQuantityCartReviewSections,
  selectEstimatedDeliveryDatesForCartReviewSections,
  (
    cartLineCountSectionMap,
    totalQuantitySectionMap,
    estimateDeliveryDatesSectionMap
  ): Map<CartReviewSectionName, CartCounts[]> => {
    const result = new Map<CartReviewSectionName, CartCounts[]>();
    const cartReviewSectionNames: CartReviewSectionName[] = [
      ...new Set([
        ...cartLineCountSectionMap.keys(),
        ...totalQuantitySectionMap.keys(),
        ...estimateDeliveryDatesSectionMap.keys(),
      ]),
    ];

    for (const name of cartReviewSectionNames) {
      const lineCountsForName = cartLineCountSectionMap.get(name);
      const totalQuantityForName = totalQuantitySectionMap.get(name);
      const estimateDatesForName = estimateDeliveryDatesSectionMap.get(name);
      const cartCountsForName = [];

      const length = Math.max(
        lineCountsForName?.length ?? 0,
        totalQuantityForName?.length ?? 0,
        estimateDatesForName?.length ?? 0
      );

      for (let i = 0; i < length; i++) {
        cartCountsForName.push({
          cartLineCount: lineCountsForName?.[i] ?? 0,
          totalQuantity: totalQuantityForName?.[i] ?? 0,
          estimatedDeliveryDate: estimateDatesForName?.[i] ?? '',
        });
      }

      result.set(name, cartCountsForName);
    }

    return result;
  }
);

export const selectCartCountsForCartReviewForSection = (
  sectionName: CartReviewSectionName
) =>
  createSelector(selectCartCountsForCartReview, (cartCountsMap): CartCounts[] =>
    cartCountsMap.get(sectionName)
  );

export const selectCartReviewStoreCountsContext = createSelector(
  selectCartReviewStoreGroup,
  selectCartCountsForCartReviewForSection(
    CartReviewSectionName.ExpressItemsSection
  ),
  selectCartCountsForCartReviewForSection(
    CartReviewSectionName.PickupItemsSection
  ),
  (
    cartReviewGroup: CartReviewSectionGroup,
    expressCartCounts: CartCounts[],
    pickupCartCounts: CartCounts[]
  ): StoreCountsContext => {
    const isExpress =
      CartReviewSectionName.ExpressItemsSection === cartReviewGroup?.name;
    const sections = cartReviewGroup?.sections ?? [];
    const dateHasBeenResolved = sections.every((section, idx) => {
      const isResolutionDateValid = isExpress
        ? !!section.expressResolutionDate
        : !!section.pickupResolutionDate;
      const cartCounts = isExpress ? expressCartCounts : pickupCartCounts;
      const isSectionEmpty = cartCounts[idx].cartLineCount === 0;
      return isResolutionDateValid || isSectionEmpty;
    });
    return {
      cartCounts: isExpress ? expressCartCounts : pickupCartCounts,
      dateHasBeenResolved,
      name: cartReviewGroup?.name,
    };
  }
);

export const selectDateHasBeenResolved = createSelector(
  selectCartReviewStoreCountsContext,
  (context) => context.dateHasBeenResolved
);
