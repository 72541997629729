import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MultipleCartsState,
  SavedCart,
  SavedCarts,
  SubmittedCart,
  SubmittedCartStatus,
} from './multiple-carts.state';
import {
  CartMaterialRecord,
  CartRecord,
} from '../../services/cart/models/cart-record';
import { parseDate } from '../../../shared/utilities/date-utilities';
import { MultipleCartsRecord } from '../../services/multiple-carts/models/multiple-carts-record';
import {
  selectCartMaterialQuantities,
  selectIsDoneUpdating,
  selectLineCount,
} from '../cart/cart.selectors';
import { NaooConstants } from '../../../shared/NaooConstants';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';
import { selectActiveCustomer } from '../session/session.selectors';

const selectMultipleCarts = createFeatureSelector<MultipleCartsState>(
  'multipleCarts'
);

const selectAllCarts = createSelector(
  selectMultipleCarts,
  (state: MultipleCartsState) => state.carts
);

export const selectIsLoadingSavedCarts = createSelector(
  selectMultipleCarts,
  (state: MultipleCartsState) => state.isLoading
);

export const selectSavedCarts = createSelector(
  selectAllCarts,
  selectLineCount,
  selectIsOffline,
  (
    multipleCartsRecord: MultipleCartsRecord,
    cartLineCount: number,
    isOffline: boolean
  ) => createSavedCarts(multipleCartsRecord, cartLineCount, isOffline)
);

export const selectCustomerAndCartQuantities = createSelector(
  selectIsDoneUpdating,
  selectActiveCustomer,
  selectCartMaterialQuantities,
  (isDoneUpdating, customer, cartMaterialQuantities) => ({
    isDoneUpdating,
    customer,
    cartMaterialQuantities,
  })
);

function createSavedCarts(
  multipleCartsRecord: MultipleCartsRecord,
  cartLineCount: number,
  isOffline: boolean
): SavedCarts {
  if (multipleCartsRecord) {
    return {
      shouldPromptActivateModal: cartLineCount > 0,
      submittedForApproval: createSavedCartArray(
        multipleCartsRecord.submittedForApprovalCarts,
        true
      ) as SubmittedCart[],
      punchedOutCarts: createSavedCartArray(
        multipleCartsRecord.punchedOutCarts,
        false
      ),
      saved: createSavedCartArray(multipleCartsRecord.savedCarts, false),
      isOffline: isOffline,
    };
  } else {
    return undefined;
  }
}

function createSavedCartArray(
  cartRecords: CartRecord[],
  isSubmitted: boolean
): SavedCart[] | SubmittedCart[] {
  return cartRecords
    .slice()
    .sort((a: CartRecord, b: CartRecord) => {
      return a.lastUpdatedTimestamp > b.lastUpdatedTimestamp ? -1 : 1;
    })
    .map((cart) => {
      const {
        id,
        materials,
        lastUpdatedTimestamp,
        lastUpdatedByUserFullName,
        lastUpdatedByUserEmail,
        customerPoNumber,
        fulfillmentType,
        storeFulfillment,
        truckFulfillment,
        status,
      } = cart;

      const cartObject = {
        id,
        materials,
        totalQuantity: calculateTotalQuantity(materials),
        lastUpdatedTimestamp: parseDate(lastUpdatedTimestamp),
        lastUpdatedUserName: lastUpdatedByUserFullName,
        lastUpdatedUserEmail: lastUpdatedByUserEmail,
        customerPoNumber,
        fulfillmentType,
        storeFulfillment,
        truckFulfillment: truckFulfillment
          ? {
              routeDate: parseDate(truckFulfillment.routeDate),
              customerArrivalDate: parseDate(
                truckFulfillment.customerArrivalDate
              ),
            }
          : undefined,
      };

      if (isSubmitted) {
        Object.assign(cartObject, {
          submittedCartStatus: getSubmittedCartStatus(status),
        });
      }
      return cartObject;
    });
}

function calculateTotalQuantity(cartMaterials: CartMaterialRecord[]): number {
  return cartMaterials.reduce((totalQuantity, material) => {
    return (
      totalQuantity +
      material.lines.reduce((totalLines, line) => {
        return totalLines + line.quantity;
      }, 0)
    );
  }, 0);
}

function getSubmittedCartStatus(cartStatus: string): SubmittedCartStatus {
  switch (cartStatus) {
    case NaooConstants.CartStatus.Active:
    case NaooConstants.CartStatus.PunchedOut:
      return SubmittedCartStatus.InReview;
    case NaooConstants.CartStatus.Deleted:
      return SubmittedCartStatus.Deleted;
    case NaooConstants.CartStatus.Saved:
      return SubmittedCartStatus.AwaitingApproval;
    case NaooConstants.CartStatus.Submitted:
      return SubmittedCartStatus.SubmittedAndApproved;
    default:
      return SubmittedCartStatus.None;
  }
}
