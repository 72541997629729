import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaterialRow } from '../../../../core/store/material-row/models/material-row';
import { MaterialFlag } from '../../../../material-flag/material-flag';

@Component({
  selector: 'naoo-material-flag-container',
  templateUrl: './material-flag-container.component.html',
  styleUrls: ['./material-flag-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialFlagContainerComponent {
  @Input() materialRow: MaterialRow;
  @Input() maximumNumberOfFlags: number;

  get materialFlagsToDisplay(): MaterialFlag[] {
    if (
      this.maximumNumberOfFlags !== undefined &&
      this.maximumNumberOfFlags !== null
    ) {
      return this.materialRow.flags.slice(0, this.maximumNumberOfFlags);
    }
    return this.materialRow.flags;
  }
}
