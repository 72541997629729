import { Injectable } from '@angular/core';
import { CartFacade } from '../core/store/cart/cart.facade';
import { OfflineModeFacade } from '../core/store/offline-mode/offline-mode.facade';
import { NaooAnalyticsManager } from '../shared/analytics/NaooAnalyticsManager';
import { filter, first, pairwise } from 'rxjs/operators';
import { race } from 'rxjs';
import { CartQuantityUpdate } from '../core/store/cart/cart.actions';
import { Router } from '@angular/router';
import { ToastMessageService } from '../shared/services/toast-message/toast-message.service';
import { FulfillmentModalService } from '../shared/services/fulfillment-modal/fulfillment-modal.service';
import { FulfillmentType } from '../core/services/cart/models/cart-record';

@Injectable({ providedIn: 'root' })
export class ReOrderService {
  private static readonly ANALYTICS_CATEGORY = 'Reorder From History';
  private static readonly ANALYTICS_ACTION_COMPLETE = 'Completed';
  private static readonly ANALYTICS_LABEL_ITEMS_ADDED =
    'Items Added To Cart Modal';

  constructor(
    private cartFacade: CartFacade,
    private offlineModeFacade: OfflineModeFacade,
    private analyticsManager: NaooAnalyticsManager,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private fulfillmentModalService: FulfillmentModalService
  ) {}

  public addMaterialsToCart(
    updates: CartQuantityUpdate[],
    newItems?: number
  ): void {
    this.cartFacade.updateCartQuantities(updates);

    const isDoneUpdating$ = this.cartFacade.isDoneUpdating().pipe(
      filter((val) => !!val),
      first()
    );

    const isOffline$ = this.offlineModeFacade.getIsOffline().pipe(
      filter((val) => !!val),
      first()
    );

    race(isDoneUpdating$, isOffline$).subscribe(() => {
      // Get the latest two values to calculate estimated cost added to cart
      this.cartFacade
        .getCartPriceTotals()
        .pipe(pairwise(), first())
        .subscribe(([previousTotals, currentTotals]) => {
          const currentCost = currentTotals.estimatedCost;
          const previousCost = previousTotals.estimatedCost;
          this.trackEstimatedCost(currentCost - previousCost);
        });
    });

    const displayToast = () => {
      this.toastMessageService.showCartImportToastMessage(
        newItems || updates.length,
        true
      );
    };

    this.cartFacade
      .getSelectedFulfillmentType()
      .pipe(first())
      .subscribe((fulfillmentType) => {
        if (fulfillmentType === FulfillmentType.NONE || !fulfillmentType) {
          this.fulfillmentModalService.openFulfillmentModal(
            false,
            false,
            false,
            false,
            false,
            false,
            displayToast
          );
        } else {
          displayToast();
        }
      });
  }

  private trackEstimatedCost(estimatedCost: number): void {
    this.analyticsManager.trackAnalyticsEvent({
      action: ReOrderService.ANALYTICS_ACTION_COMPLETE,
      category: ReOrderService.ANALYTICS_CATEGORY,
      label: ReOrderService.ANALYTICS_LABEL_ITEMS_ADDED,
      value: estimatedCost,
    });
  }
}
