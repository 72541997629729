import { Observable } from 'rxjs';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Injectable } from '@angular/core';
import { SessionRecord } from './models/session-record';
import { UpdateSessionRequest } from './models/update-session-request';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  static readonly GET_SESSION_ENDPOINT = '/api/v4/session';

  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getSession(): Observable<SessionRecord> {
    return this.httpClient.get<SessionRecord>(
      `${this.webBffService.getBff() + SessionService.GET_SESSION_ENDPOINT}`
    );
  }

  updateSession(
    updateSessionRequest: UpdateSessionRequest
  ): Observable<SessionRecord> {
    return this.httpClient.put<SessionRecord>(
      `${this.webBffService.getBff() + SessionService.GET_SESSION_ENDPOINT}`,
      updateSessionRequest
    );
  }
}
