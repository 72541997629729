import {
  EntitlementRecordState,
  EntitlementState,
  initialEntitlementState,
  initialMaterialDetailRecordsState,
  initialOverallocatedMaterialDetailsState,
  materialDetailAdapter,
  OverallocatedMaterialDetail,
  overallocatedMaterialDetailAdapter,
} from './entitlement.state';
import { EntitlementActions } from './entitlement.actions';
import { parseDate } from '../../../shared/utilities/date-utilities';
import { createReducer, on } from '@ngrx/store';
import { YearEntitlement } from '../../services/entitlement/models/entitlement';

export const entitlementReducer = createReducer(
  initialEntitlementState,
  on(
    EntitlementActions.refreshEntitlement,
    (state): EntitlementState => refreshEntitlement(state)
  ),
  on(
    EntitlementActions.abortEntitlement,
    (state): EntitlementState => abortEntitlement(state)
  ),
  on(
    EntitlementActions.getEntitlementSuccess,
    (state, action): EntitlementState => getEntitlementSuccess(state, action)
  ),
  on(
    EntitlementActions.setSelectedEntitlementMaterial,
    (state, action): EntitlementState =>
      selectEntitlementMaterial(state, action.materialNumber)
  ),
  on(
    EntitlementActions.clearSelectedEntitlementMaterial,
    (state): EntitlementState => selectEntitlementMaterial(state)
  ),
  on(
    EntitlementActions.setOverallocatedMaterialTotals,
    (state, action): EntitlementState =>
      setOverallocatedMaterialsMap(state, action.overallocatedMaterialsMap)
  ),
  on(
    EntitlementActions.clearOverallocatedMaterialTotals,
    (state): EntitlementState => setOverallocatedMaterialsMap(state)
  ),
  on(
    EntitlementActions.clearEntitlement,
    (): EntitlementState => initialEntitlementState
  ),
  on(EntitlementActions.getEntitlement, (state): EntitlementState => state)
);

function refreshEntitlement(state: EntitlementState) {
  return {
    ...state,
    isLoading: true,
  };
}

function abortEntitlement(state: EntitlementState) {
  return {
    ...state,
    isLoading: false,
    entitlementRecordState: undefined as EntitlementRecordState,
    selectedMaterialNumber: undefined as string,
  };
}

function getEntitlementSuccess(
  state: EntitlementState,
  action: {
    yearEntitlement: YearEntitlement;
  }
) {
  return {
    ...state,
    isLoading: false,
    entitlementRecordState: {
      beginEffectiveDate: parseDate(action.yearEntitlement.beginEffectiveDate),
      endEffectiveDate: parseDate(action.yearEntitlement.endEffectiveDate),
      materialDetailRecords: materialDetailAdapter.upsertMany(
        action.yearEntitlement.entitlementMaterialDetails,
        initialMaterialDetailRecordsState
      ),
    },
  };
}

function selectEntitlementMaterial(
  state: EntitlementState,
  materialNumber?: string
) {
  return {
    ...state,
    selectedMaterialNumber: materialNumber,
  };
}

function setOverallocatedMaterialsMap(
  state: EntitlementState,
  overallocatedMaterialsMap?: Map<string, object>
) {
  if (!overallocatedMaterialsMap) {
    return {
      ...state,
      overallocatedMaterialDetails: initialOverallocatedMaterialDetailsState,
    };
  }

  const overallocatedMaterialDetails: OverallocatedMaterialDetail[] = [];

  Array.from(overallocatedMaterialsMap.entries()).forEach(([key, value]) => {
    overallocatedMaterialDetails.push({
      materialNumber: key,
      maxCommodityCasesAmount: (value as unknown) as number,
    });
  });

  return {
    ...state,
    overallocatedMaterialDetails: overallocatedMaterialDetailAdapter.upsertMany(
      overallocatedMaterialDetails,
      initialOverallocatedMaterialDetailsState
    ),
  };
}
