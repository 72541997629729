import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { SalesCriticalItem } from './model/sales-critical-items';

export interface SalesCriticalItemsGuideRecord {
  salesCriticalItems: SalesCriticalItem[];
}

@Injectable({ providedIn: 'root' })
export class SalesCriticalItemsService {
  static readonly SALES_CRITICAL_ITEMS_ENDPOINT =
    '/api/v1/lists/sales-critical-items';

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService
  ) {}

  getSalesCriticalItems(
    customerArrivalDate?: string
  ): Observable<SalesCriticalItemsGuideRecord> {
    const requestParameters = customerArrivalDate
      ? { routeDate: customerArrivalDate }
      : {};
    return this.httpClient.get<SalesCriticalItemsGuideRecord>(
      this.webBffService.getBff() +
        SalesCriticalItemsService.SALES_CRITICAL_ITEMS_ENDPOINT,
      {
        params: requestParameters,
      }
    );
  }
}
