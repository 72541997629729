import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { SalesCriticalItemsService } from '../../services/sales-critical-items/sales-critical-items.service';
import { Observable, of } from 'rxjs';
import {
  catchError,
  delayWhen,
  first,
  map,
  mergeMap,
  takeUntil,
} from 'rxjs/operators';
import {
  selectCustomerArrivalDate,
  selectIsCartLoaded,
} from '../cart/cart.selectors';
import { formatDate } from '../../../shared/utilities/date-utilities';
import { SalesCriticalItemsActions } from './sales-critical-items.actions';

@Injectable()
export class SalesCriticalItemsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private salesCriticalItemsService: SalesCriticalItemsService
  ) {}

  getSalesCriticalItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SalesCriticalItemsActions.getSalesCriticalItems),
      delayWhen(() =>
        this.store
          .select(selectIsCartLoaded)
          .pipe(first((isLoaded) => isLoaded))
      ),
      concatLatestFrom(() => this.store.select(selectCustomerArrivalDate)),
      mergeMap(([_, customerArrivalDate]) =>
        this.salesCriticalItemsService
          .getSalesCriticalItems(formatDate(customerArrivalDate))
          .pipe(
            mergeMap((salesCriticalItems) => [
              SalesCriticalItemsActions.getSalesCriticalItemsSuccess(
                salesCriticalItems.salesCriticalItems
              ),
            ]),
            catchError(() =>
              of(SalesCriticalItemsActions.getSalesCriticalItemsFailure())
            ),
            takeUntil(
              this.actions$.pipe(
                ofType(SalesCriticalItemsActions.refreshSalesCriticalItems)
              )
            )
          )
      )
    );
  });

  refreshSalesCriticalItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SalesCriticalItemsActions.refreshSalesCriticalItems),
      map(() => {
        return SalesCriticalItemsActions.getSalesCriticalItems();
      })
    );
  });
}
