import { NaooConstants } from '../NaooConstants';

export interface CartLine {
  uom: string;
  quantity: number;
}

export class CartMaterial {
  materialNumber: string;
  lines: CartLine[];
  deleted: boolean;
  restored: boolean;
  fromCIBox: boolean;
  fromPCTable: boolean;
  originTrackingId: string | null;

  constructor(
    materialNumber: string,
    lines: CartLine[] = [],
    restored: boolean = false,
    fromCIBox: boolean = false,
    fromPCTable: boolean = false,
    deleted: boolean = false,
    originTrackingId: string = null
  ) {
    this.materialNumber = materialNumber;
    this.lines = lines;
    this.restored = restored;
    this.fromCIBox = fromCIBox;
    this.fromPCTable = fromPCTable;
    this.deleted = deleted;
    this.originTrackingId = originTrackingId;
  }

  get legacyCaseQuantity(): number {
    const foundLine = this.lines
      ? this.lines.find((line) => line.uom === NaooConstants.Uom.Case)
      : undefined;
    return foundLine ? foundLine.quantity : 0;
  }

  get legacyUnitQuantity(): number {
    const foundLine = this.lines
      ? this.lines.find((line) => line.uom === NaooConstants.Uom.Unit)
      : undefined;
    return foundLine ? foundLine.quantity : 0;
  }
}
