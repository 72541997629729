import { Injectable } from '@angular/core';
import { JsPdfService } from '../js-pdf/js-pdf.service';
import {
  HorizontalAlignment,
  materialRowDataPointStyles,
  PageOrientation,
  SectionType,
  tableStyles,
} from './models/export-materials-print-styles';
import * as PrintConstants from './models/export-materials-print-constants';
import { ExportMaterialsRow } from './models/export-materials-row';
import {
  ExportDataPoint,
  untranslatedDataPoints,
} from '../../models/export/export-properties';
import { NaooConstants } from '../../NaooConstants';
import { formatDate } from '@angular/common';
import { dateFormats } from '../../utilities/date-utilities';
import { from, Observable, of } from 'rxjs';
import {
  ExportCategoryGrouping,
  ExportMaterial,
  ExportMaterialsData,
} from './models/export-materials';
import { LocalizationService } from '../translation/localization.service';
import { NaooBrandPipe } from '../../pipes/naoo-brand.pipe';
import { CustomerBrand } from '../../../core/services/session/models/session-record';
import { jsPDF } from 'jspdf';

interface ExportCategory {
  name: string;
  materials: ExportMaterial[];
}

@Injectable({
  providedIn: 'root',
})
export class ExportMaterialsPrintService {
  constructor(
    private jsPdfService: JsPdfService,
    private localizationService: LocalizationService,
    private naooBrandPipe: NaooBrandPipe
  ) {}

  printFile(
    exportMaterialsData: ExportMaterialsData,
    headerDataPoints: ExportDataPoint[]
  ): Observable<void> {
    const pageOrientation = this.getPageOrientation(headerDataPoints);
    if (pageOrientation === PageOrientation.Invalid) {
      return of();
    }

    from(this.jsPdfService.getJsPdfInstance(pageOrientation)).subscribe(
      (pdfDoc) => {
        this.drawPdf(
          pdfDoc,
          exportMaterialsData,
          headerDataPoints,
          pageOrientation
        );
        this.generatePdf(pdfDoc, exportMaterialsData.fileName);
      }
    );
    return of();
  }

  getPageOrientation(headerDataPoints: ExportDataPoint[]): PageOrientation {
    const totalWidth = headerDataPoints.reduce((prev, current) => {
      return +prev + +materialRowDataPointStyles[current].cellWidth;
    }, 0);

    if (totalWidth <= PrintConstants.tableWidth.portrait) {
      return PageOrientation.Portrait;
    } else if (totalWidth <= PrintConstants.tableWidth.landscape) {
      return PageOrientation.Landscape;
    } else {
      return PageOrientation.Invalid;
    }
  }

  private drawPdf(
    pdfDoc: jsPDF & {
      autoTable?: (opts: object) => void;
      lastAutoTable?: { finalY?: number };
    },
    exportMaterialsData: ExportMaterialsData,
    headerDataPoints: ExportDataPoint[],
    pageOrientation: PageOrientation
  ) {
    const categories = this.buildExportCategories(
      exportMaterialsData.materials,
      exportMaterialsData.categoryGroupings
    );
    let currentPageNumber = 0;
    categories.forEach((category) => {
      const currentYPosition = this.drawCategoryNameIfAble(pdfDoc, category);
      pdfDoc.autoTable({
        ...tableStyles,
        head: this.buildTableHeader(headerDataPoints, category),
        body: this.buildTableBody(category),
        startY: currentYPosition + PrintConstants.categoryHeaderBottomMargin,
        didParseCell: (jsPdfContext: any) => {
          this.alignColumnHeadIfNeeded(jsPdfContext);
        },
        didDrawCell: (jsPdfContext: any) => {
          this.drawQuantityDividerIfNeeded(jsPdfContext);
          this.drawUnitBoxIfNeeded(jsPdfContext, category);
        },
        didDrawPage: () => {
          if (this.isANewPage(pdfDoc, currentPageNumber)) {
            this.drawPageHeader(
              pdfDoc,
              category,
              exportMaterialsData,
              pageOrientation
            );
            this.drawPageFooter(pdfDoc, pageOrientation);
            currentPageNumber++;
          }
        },
      });
    });

    if (typeof pdfDoc.putTotalPages === 'function') {
      pdfDoc.putTotalPages(PrintConstants.footerTotalPagesPlaceholder);
    }

    if (pdfDoc.lastAutoTable) {
      pdfDoc.lastAutoTable.finalY = undefined;
    }
  }

  private drawCategoryNameIfAble(
    pdfDoc: jsPDF,
    category: ExportCategory
  ): number {
    let currentYPosition = this.getNewCategoryYCoordinate(pdfDoc);
    if (
      this.getPageHeight(pdfDoc) - currentYPosition >=
      PrintConstants.pageBottomThreshold
    ) {
      pdfDoc.setFontSize(PrintConstants.fontSize.large);
      pdfDoc.setFont(PrintConstants.font, PrintConstants.fontStyle.bold);
      pdfDoc.setCharSpace(PrintConstants.textCharSpace.body);

      pdfDoc.text(
        category.name.toUpperCase(),
        PrintConstants.pageMargin.left,
        currentYPosition
      );
    } else {
      // Prevents category header from getting printed at the bottom of a page
      currentYPosition += PrintConstants.pageBottomThreshold;
    }

    return currentYPosition;
  }

  private alignColumnHeadIfNeeded(jsPdfContext: any): void {
    if (jsPdfContext.section !== SectionType.Head) {
      return;
    }

    switch (jsPdfContext.column.index) {
      case ExportDataPoint.Qt1:
      case ExportDataPoint.Qt2:
      case ExportDataPoint.Qt3:
      case ExportDataPoint.Qt4:
      case ExportDataPoint.Qt5:
      case ExportDataPoint.Qt6:
      case ExportDataPoint.Qt7:
      case ExportDataPoint.Qt8:
      case ExportDataPoint.Qt9:
      case ExportDataPoint.Qt10:
      case ExportDataPoint.Unit:
        jsPdfContext.cell.styles.halign = HorizontalAlignment.Center;
        break;
      case ExportDataPoint.CasePrice:
      case ExportDataPoint.UnitPrice:
        jsPdfContext.cell.styles.halign = HorizontalAlignment.Right;
        break;
    }
  }

  private drawQuantityDividerIfNeeded(jsPdfContext: any): void {
    const isQuantityColumn =
      jsPdfContext.section === SectionType.Body &&
      [
        ExportDataPoint.Qt1,
        ExportDataPoint.Qt2,
        ExportDataPoint.Qt3,
        ExportDataPoint.Qt4,
        ExportDataPoint.Qt5,
        ExportDataPoint.Qt6,
        ExportDataPoint.Qt7,
        ExportDataPoint.Qt8,
        ExportDataPoint.Qt9,
        ExportDataPoint.Qt10,
      ].includes(jsPdfContext.column.dataKey);
    if (!isQuantityColumn) {
      return;
    }
    // Draw divider line for 'I/O' cells in table body
    jsPdfContext.doc.line(
      jsPdfContext.cell.x + jsPdfContext.cell.width / 2,
      jsPdfContext.cell.y +
        jsPdfContext.cell.height -
        PrintConstants.quantityDividerLineOffset,
      jsPdfContext.cell.x + jsPdfContext.cell.width / 2,
      jsPdfContext.cell.y + jsPdfContext.cell.height
    );
  }

  private drawUnitBoxIfNeeded(
    jsPdfContext: any,
    category: ExportCategory
  ): void {
    const isPackUomColumn =
      jsPdfContext.section === SectionType.Body &&
      jsPdfContext.column.index === ExportDataPoint.PackUom;
    if (!isPackUomColumn) {
      return;
    }

    const isUnitOrderable = category.materials[
      jsPdfContext.row.index
    ].orderableUnits.includes(NaooConstants.Uom.Unit);
    if (!isUnitOrderable) {
      return;
    }

    // Draw checkbox for 'Unit' cells in table body
    const xCoordinate =
      jsPdfContext.cell.x +
      (jsPdfContext.cell.width - PrintConstants.unitBoxSize) / 2;
    const yCoordinate =
      jsPdfContext.cell.y +
      jsPdfContext.cell.height / 2 -
      PrintConstants.unitBoxSize / 2;

    jsPdfContext.doc.roundedRect(
      xCoordinate,
      yCoordinate,
      PrintConstants.unitBoxSize,
      PrintConstants.unitBoxSize,
      PrintConstants.unitBoxRadius,
      PrintConstants.unitBoxRadius,
      null
    );
  }

  private drawPageHeader(
    pdfDoc: any,
    category: ExportCategory,
    exportMaterialsData: ExportMaterialsData,
    pageOrientation: PageOrientation
  ): void {
    const originalCharSpace = pdfDoc.getCharSpace();
    pdfDoc.setFont(PrintConstants.font, PrintConstants.fontStyle.bold);
    pdfDoc.setCharSpace(PrintConstants.textCharSpace.body);
    pdfDoc.setLineWidth(PrintConstants.headerFooterLineHeight);

    const fileName = exportMaterialsData.fileName.toUpperCase();
    pdfDoc.text(
      fileName,
      this.calculateRightAlignedTextCoordinate(pdfDoc, fileName),
      PrintConstants.titleMarginTop
    );

    const customerInfo = `${exportMaterialsData.customer.name} #${exportMaterialsData.customer.number}`;
    pdfDoc.setFont(PrintConstants.font, PrintConstants.fontStyle.normal);
    pdfDoc.text(
      customerInfo,
      this.calculateRightAlignedTextCoordinate(pdfDoc, customerInfo),
      PrintConstants.subtitleMarginTop
    );

    pdfDoc.setDrawColor(0, 0, 0);
    pdfDoc.line(
      PrintConstants.pageMargin.left,
      PrintConstants.headerHeight,
      PrintConstants.pageMargin.right +
        (pageOrientation === PageOrientation.Portrait
          ? PrintConstants.tableWidth.portrait
          : PrintConstants.tableWidth.landscape),
      PrintConstants.headerHeight
    );

    pdfDoc.addImage(
      this.getLogoImageElement(exportMaterialsData.customer.brand),
      PrintConstants.logo.fileType,
      PrintConstants.pageMargin.left,
      PrintConstants.logo.margin,
      PrintConstants.logo.width,
      PrintConstants.logo.height
    );

    pdfDoc.setFont(PrintConstants.font, PrintConstants.fontStyle.bold);
    if (pdfDoc.internal.getNumberOfPages() > 1) {
      pdfDoc.text(
        category.name.toUpperCase(),
        PrintConstants.pageMargin.left,
        PrintConstants.headerHeight + PrintConstants.categorySectionMarginTop
      );
    }

    pdfDoc.setCharSpace(originalCharSpace);
  }

  private drawPageFooter(pdfDoc: any, pageOrientation: PageOrientation): void {
    const pageHeight = this.getPageHeight(pdfDoc);
    const originalCharSpace = pdfDoc.getCharSpace();
    pdfDoc.setCharSpace(PrintConstants.textCharSpace.footer);
    pdfDoc.setFontSize(PrintConstants.fontSize.medium);
    pdfDoc.setDrawColor(220, 220, 220);
    pdfDoc.setLineWidth(PrintConstants.headerFooterLineHeight);

    pdfDoc.line(
      PrintConstants.pageMargin.left,
      pageHeight - PrintConstants.pageMargin.bottom,
      PrintConstants.pageMargin.right +
        (pageOrientation === PageOrientation.Portrait
          ? PrintConstants.tableWidth.portrait
          : PrintConstants.tableWidth.landscape),
      pageHeight - PrintConstants.pageMargin.bottom
    );

    const footerDate = this.getCurrentDate(
      dateFormats[this.localizationService.currentLocale].shortDate
    );
    pdfDoc.text(
      footerDate,
      PrintConstants.pageMargin.left,
      pageHeight - PrintConstants.footerMarginBottom
    );

    const footerText = this.localizationService.parser.interpolate(
      this.localizationService.instant(PrintConstants.footerTranslationKey),
      {
        currentPage: pdfDoc.internal.getNumberOfPages(),
        totalPages: PrintConstants.footerTotalPagesPlaceholder,
      }
    );
    pdfDoc.text(
      footerText,
      this.calculateRightAlignedTextCoordinate(pdfDoc, footerText),
      pageHeight - PrintConstants.footerMarginBottom
    );

    pdfDoc.setCharSpace(originalCharSpace);
  }

  private generatePdf(pdfDoc: any, fileName: string): void {
    const fileNameDate = this.getCurrentDate(
      dateFormats[this.localizationService.currentLocale].exportDate
    );
    const fullFileName = `${fileName}_${fileNameDate}.pdf`;

    pdfDoc.autoPrint();
    pdfDoc.save(fullFileName);
  }

  private buildExportCategories(
    exportMaterials: ExportMaterial[],
    categoryGroupings: ExportCategoryGrouping[]
  ): ExportCategory[] {
    return categoryGroupings.map((categoryGrouping) => ({
      name: categoryGrouping.categoryName,
      materials: exportMaterials.filter((exportMaterial) =>
        categoryGrouping.materialNumbers.includes(exportMaterial.materialNumber)
      ),
    }));
  }

  private buildTableHeader(
    headerDataPoints: ExportDataPoint[],
    category: ExportCategory
  ): ExportMaterialsRow[] {
    if (category.materials.length < 1) {
      return [];
    }

    const exportRow: ExportMaterialsRow = {};
    headerDataPoints.forEach((headerDataPoint) => {
      exportRow[headerDataPoint] = this.localizationService.instant(
        `PRINT.TABLE_HEADERS.${headerDataPoint}`
      );
    });
    return [exportRow];
  }

  private buildTableBody(category: ExportCategory): ExportMaterialsRow[] {
    const exportMaterialRows: ExportMaterialsRow[] = [];
    category.materials.forEach((exportMaterial) => {
      const exportMaterialRow: ExportMaterialsRow = {};
      Array.from(exportMaterial.data.entries()).forEach(([key, value]) => {
        if (key === ExportDataPoint.PackUom) {
          exportMaterialRow[key] = ''; // Will later draw box for this column
        } else if (untranslatedDataPoints.includes(key)) {
          exportMaterialRow[key] = this.localizationService.instant(value);
        } else {
          exportMaterialRow[key] = value;
        }
      });
      exportMaterialRows.push(exportMaterialRow);
    });

    return exportMaterialRows;
  }

  private getNewCategoryYCoordinate(pdfDoc: any): number {
    return (
      (pdfDoc.lastAutoTable?.finalY ?? PrintConstants.headerHeight) +
      PrintConstants.categorySectionMarginTop
    );
  }

  private getPageHeight(pdfDoc: any): number {
    return (
      pdfDoc.internal.pageSize.height ?? pdfDoc.internal.pageSize.getHeight()
    );
  }

  private calculateRightAlignedTextCoordinate(doc: any, text: string): number {
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.getFontSize()) /
      doc.internal.scaleFactor;
    return (
      doc.internal.pageSize.width - textWidth - PrintConstants.pageMargin.right
    );
  }

  private isANewPage(pdfDoc: any, currentPageNumber: number): boolean {
    return currentPageNumber !== pdfDoc.internal.getNumberOfPages();
  }

  private getCurrentDate(format: string): string {
    return formatDate(
      Date.now(),
      format,
      this.localizationService.currentLocale,
      ''
    );
  }

  private getLogoImageElement(brand: CustomerBrand): HTMLImageElement {
    const imageElement = new Image();
    imageElement.crossOrigin = 'Anonymous';
    imageElement.src = this.localizationService.instant(
      this.naooBrandPipe.transform('PRINT.LOGO', brand)
    );
    return imageElement;
  }
}
