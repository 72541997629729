import { Injectable } from '@angular/core';
import { MaterialRelatedService } from '../../services/material-related/material-related.service';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { ErrorActions } from '../error/error.actions';
import { MaterialRelatedActions } from './material-related.actions';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import {
  MaterialRelatedListRecord,
  MaterialRelatedRecord,
} from '../../services/material-related/model/material-related-record';
import { SharedActions } from '../shared/shared.actions';
import { chunkArray } from '../../../shared/utilities/array-utilities';
import { MaterialInfoActions } from '../material-info/material-info.actions';

@Injectable()
export class MaterialRelatedEffects {
  private readonly maxMaterialsPerRequest = 1000;

  constructor(
    private actions$: Actions,
    private materialRelatedService: MaterialRelatedService
  ) {}

  loadMaterialRelated$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialRelatedActions.loadMaterialRelated),

      mergeMap((action) => {
        const allMaterialNumbers: string[] = action.materialNumbers;
        if (allMaterialNumbers.length < this.maxMaterialsPerRequest) {
          return of(
            MaterialRelatedActions.getMaterialRelated(allMaterialNumbers)
          );
        }

        const dispatchActions: Action[] = [];

        chunkArray(
          allMaterialNumbers,
          this.maxMaterialsPerRequest
        ).forEach((idBatch) =>
          dispatchActions.push(
            MaterialRelatedActions.getMaterialRelated(idBatch)
          )
        );

        return dispatchActions;
      })
    );
  });

  getMaterialRelated$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialRelatedActions.getMaterialRelated),
      mergeMap((action) => {
        if (action.materialNumbers.length === 0) {
          return of(
            SharedActions.noOperation('There are no requested material related')
          );
        }

        return this.materialRelatedService
          .getMaterialRelated(action.materialNumbers)
          .pipe(
            mergeMap((records) => {
              if (records.related.length !== action.materialNumbers.length) {
                records.related.push(
                  ...this.buildDefaultRelatedMaterials(
                    records,
                    action.materialNumbers
                  )
                );
              }
              const allSubstitutes: string[] = records.related.reduce(
                (accumulator, relatedMaterialRecord) =>
                  accumulator.concat(relatedMaterialRecord.substitutes),
                []
              );
              const allSimilar: string[] = records.related.reduce(
                (accumulator, relatedMaterialRecord) =>
                  accumulator.concat(relatedMaterialRecord.similar),
                []
              );

              const allMaterials = [...allSubstitutes, ...allSimilar];
              return [
                MaterialRelatedActions.getMaterialRelatedSuccess(records),
                MaterialAvailabilityActions.loadMaterialAvailability(
                  allMaterials
                ),
                MaterialInfoActions.loadMaterialInfo(allMaterials),
              ];
            }),
            catchError((error) => {
              return of(
                MaterialRelatedActions.getMaterialRelatedError(
                  action.materialNumbers
                ),
                ErrorActions.silentError(error)
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  MaterialRelatedActions.loadMaterialRelated,
                  MaterialRelatedActions.clearMaterialRelated
                )
              )
            )
          );
      })
    );
  });

  private buildDefaultRelatedMaterials(
    records: MaterialRelatedListRecord,
    materialNumbers: string[]
  ): MaterialRelatedRecord[] {
    const materialNumbersFromResponse: string[] = records.related.map(
      (related) => related.materialNumber
    );
    const materialNumbersWithNoRelated: string[] = materialNumbers.filter(
      (materialNumber) => !materialNumbersFromResponse.includes(materialNumber)
    );

    return materialNumbersWithNoRelated.map((materialNumber) => {
      return { materialNumber, substitutes: [], similar: [] };
    });
  }
}
