import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  marketingGuideAdapter,
  MarketingGuideRecordState,
  MarketingGuideState,
} from './marketing-guide.state';
import { MarketingGuideRecord } from '../../services/marketing-guide/models/marketing-guide-record';
import { MarketingGuideViewModel } from './models/marketing-guide';
import {
  getMarketingGuideMaterials,
  MarketingGuideTransformer,
} from './marketing-guide.transformer';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { selectCustomerMaterialRecord } from '../customer-material/customer-material.selector';
import { selectLanguage } from '../session/session.selectors';
import { selectPreferredMaterialView } from '../material-row/material-view.selectors';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';

const selectMarketingGuideState = createFeatureSelector<MarketingGuideState>(
  'marketingGuide'
);

export const selectMarketingGuideRecordStates = createSelector(
  selectMarketingGuideState,
  (state: MarketingGuideState): MarketingGuideRecordState[] =>
    state
      ? marketingGuideAdapter
          .getSelectors()
          .selectAll(state.marketingGuideRecords)
      : []
);

export const selectMarketingGuideRecordState = (pathUrl: string) =>
  createSelector(
    selectMarketingGuideRecordStates,
    (records: MarketingGuideRecordState[]): MarketingGuideRecordState =>
      records.find((record) => record.pathUrl === pathUrl)
  );

export const selectMarketingGuideViewModel = (pathUrl: string) =>
  createSelector(
    selectMarketingGuideRecordState(pathUrl),
    selectPreferredMaterialView,
    selectAllMaterialInfoRecordStates,
    selectCustomerMaterialRecord,
    selectIsOffline,
    selectLanguage,
    (
      record,
      preferredView,
      materialInfo,
      customerMaterial,
      isOffline,
      currentLanguage
    ): MarketingGuideViewModel =>
      MarketingGuideTransformer.transformMarketingGuide(
        record,
        preferredView,
        materialInfo,
        customerMaterial,
        isOffline,
        currentLanguage
      )
  );

export const selectIsMarketingGuideLoaded = (pathUrl: string) =>
  createSelector(
    selectMarketingGuideRecordState(pathUrl),
    (recordState: MarketingGuideRecordState): boolean => recordState?.hasLoaded
  );

export const selectMarketingGuideRecord = (pathUrl: string) =>
  createSelector(
    selectMarketingGuideRecordState(pathUrl),
    (
      recordState: MarketingGuideRecordState
    ): MarketingGuideRecord | undefined => recordState?.record
  );

export const selectMarketingGuideMaterials = createSelector(
  selectMarketingGuideRecordStates,
  (recordStates: MarketingGuideRecordState[]): string[] =>
    recordStates.flatMap((state) =>
      state.record ? getMarketingGuideMaterials(state.record) : []
    )
);

export const selectMarketingGuideExportMaterials = (
  pathUrl: string,
  isPrint: boolean
) =>
  createSelector(
    selectMarketingGuideRecord(pathUrl),
    selectAllMaterialInfoRecordStates,
    selectCustomerMaterialRecord,
    selectLanguage,
    (record, materialInfos, materialRecord, language): ExportMaterialsInput =>
      MarketingGuideTransformer.transformMarketingGuideExportMaterials(
        record,
        materialInfos,
        materialRecord,
        language,
        isPrint
      )
  );
