import { Injectable } from '@angular/core';
import { convertToParamMap, ParamMap, UrlSerializer } from '@angular/router';
import { NaooLogger } from '../../logger/NaooLogger.service';
import { NaooConstants } from '../../NaooConstants';

@Injectable({ providedIn: 'root' })
export class UrlSerializerService {
  constructor(
    private urlSerializer: UrlSerializer,
    private logger: NaooLogger
  ) {}

  buildParamMapFromUrl(url: string): ParamMap {
    if (url) {
      try {
        return this.urlSerializer.parse(url).queryParamMap;
      } catch (error) {
        this.logger.error(`Parsing error for url`, JSON.stringify(error));
      }
    }

    return convertToParamMap([]);
  }

  getMaterialNumbersFromUrl(url: string): string[] {
    if (!url) {
      return [];
    }
    if (url.startsWith(NaooConstants.PDP_PATH_WITH_TRAILING_SLASH)) {
      return [url.substring(NaooConstants.PDP_PATH_WITH_TRAILING_SLASH.length)];
    }
    return this.getSearchTextMaterials(url);
  }

  private getSearchTextMaterials(url: string) {
    return (
      this.buildParamMapFromUrl(url)
        .get('searchText')
        ?.split(' ')
        .filter((materialNumber) => !isNaN(Number(materialNumber))) ?? []
    );
  }
}
