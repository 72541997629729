<ng-container *ngIf="filteredViewModel?.isLoading; else spacer">
  <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
</ng-container>
<div class="table-info">
  <naoo-date-range-selector
    [selectedStartDate]="filteredViewModel?.startDate"
    (dateRangeEmitter)="dateRangeEmitter.emit($event)"
  ></naoo-date-range-selector>
  <naoo-search-field
    *ngIf="!filteredViewModel?.shouldDisplayErrorPage"
    class="search-field"
    #searchField
    (searchTextEmitter)="searchValueEmitter.emit($event)"
    [value]="searchTerm"
    [searchPlaceholderText]="'PURCHASE_HISTORY.SEARCH.PLACEHOLDER'"
    [searchHintText]="'PURCHASE_HISTORY.SEARCH.HINT'"
  ></naoo-search-field>
</div>
<div class="drop-shadow-spacer"></div>
<ng-container [ngTemplateOutlet]="spacer"></ng-container>
<ng-container
  *ngIf="filteredViewModel?.purchases?.length"
  [ngTemplateOutlet]="purchases"
></ng-container>
<ng-container
  *ngIf="filteredViewModel?.shouldDisplayNoResults"
  [ngTemplateOutlet]="noResults"
></ng-container>
<ng-container
  *ngIf="filteredViewModel?.shouldDisplayErrorPage"
  [ngTemplateOutlet]="errorPage"
></ng-container>

<ng-template #purchases>
  <article class="store-purchase-row purchase-header">
    <div class="purchase-cell date">
      {{ 'PURCHASE_HISTORY.TITLES.DATE' | translate }}
    </div>
    <div class="purchase-cell store-address">
      {{ 'PURCHASE_HISTORY.TITLES.STORE_INFORMATION' | translate }}
    </div>
    <div class="purchase-cell points header">
      <naoo-go-points-logo
        [context]="'purchase-history-header'"
      ></naoo-go-points-logo>
    </div>
    <div class="purchase-cell total">
      {{ 'PURCHASE_HISTORY.TITLES.TOTAL' | translate }}
    </div>
    <div class="purchase-cell store-purchase-details"></div>
  </article>
  <virtual-scroller
    #scroll
    [scrollAnimationTime]="0"
    [compareItems]="compareViewModels"
    [items]="filteredViewModel?.purchases"
    [enableUnequalChildrenSizes]="true"
    [parentScroll]="parentScrollElement"
  >
    <article
      class="store-purchase-row purchase-main"
      *ngFor="
        let storePurchase of scroll.viewPortItems;
        trackBy: trackByPurchaseKey
      "
      (click)="selectStorePurchaseDetails(storePurchase)"
    >
      <div class="purchase-cell date">
        <div class="date-label">
          {{ 'PURCHASE_HISTORY.TITLES.DATE' | translate }}
        </div>
        <div>
          <div class="date-value">
            {{ storePurchase.saleDate | naooDate: 'shortDate':'UTC' }}
          </div>
        </div>
      </div>
      <div class="purchase-cell store-address">
        <div class="store-address-label">
          {{ 'PURCHASE_HISTORY.TITLES.STORE_INFORMATION' | translate }}
        </div>
        <div class="store-address-info">
          <naoo-store-display
            [storeRecord]="storePurchase.storeRecord"
            [customerTimeZone]="filteredViewModel.customerTimezone"
            [isMobileHeader]="isTabletWidth"
            [context]="storeDisplayContext"
          >
          </naoo-store-display>
        </div>
      </div>
      <div class="purchase-cell points">
        <div class="points-label">
          <naoo-go-points-logo
            *ngIf="isTabletWidth"
            [context]="'purchase-history-tablet'"
          ></naoo-go-points-logo>
        </div>
        <div class="points-info">
          <div class="points-value">
            {{ storePurchase.pointsEarned }}
          </div>
        </div>
      </div>
      <div class="purchase-cell total">
        <div class="total-label">
          {{ 'PURCHASE_HISTORY.TITLES.TOTAL' | translate }}
        </div>
        <div class="total-info">
          <div class="total-value">
            {{ storePurchase.netTransactionAmount | naooprice }}
          </div>
        </div>
      </div>
      <div class="purchase-cell store-purchase-details">
        <a
          href="javascript:void(0)"
          (click)="storePurchaseKeyEmitter.emit(storePurchase)"
          >{{ 'PURCHASE_HISTORY.TITLES.VIEW_DETAILS' | translate }}</a
        >
      </div>
    </article>
  </virtual-scroller>
</ng-template>

<ng-template #noResults>
  <naoo-error-state
    alt="{{ 'PURCHASE_HISTORY.NO_RESULTS.ALT' | translate }}"
    errorImageSource="assets/images/empty-v2.svg"
    errorTitle="{{ 'PURCHASE_HISTORY.NO_RESULTS.TITLE' | translate }}"
    errorSubtitle="{{ 'PURCHASE_HISTORY.NO_RESULTS.SUBTITLE' | translate }}"
    [showErrorButton]="false"
  >
  </naoo-error-state>
</ng-template>

<ng-template #errorPage>
  <naoo-error-state
    class="error-page"
    alt="{{ 'PURCHASE_HISTORY.ERROR.ALT' | translate }}"
    errorImageSource="assets/images/full-error.svg"
    errorTitle="{{ 'PURCHASE_HISTORY.ERROR.TITLE' | translate }}"
    errorSubtitle="{{ 'PURCHASE_HISTORY.ERROR.SUBTITLE' | translate }}"
    [showErrorButton]="false"
  >
  </naoo-error-state>
</ng-template>

<ng-template #spacer>
  <div class="drop-shadow-spacer"></div>
</ng-template>
