import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { NaooConstants } from '../../NaooConstants';
import { CONTENT_SCROLL } from '../scrollable-content/scrollable-content.service';

export class NaooPage {
  scrollY: BehaviorSubject<number>;
  pageOffset?: number;
  shouldRestoreScrollPosition?: boolean;

  constructor() {
    this.scrollY = new BehaviorSubject<number>(0);
  }
}

@Injectable({ providedIn: 'root' })
export class RouterExtrasService {
  private pageMap = new Map<string, NaooPage>();
  private criticalItemsSectionPoppedState = false;
  private scrollToElementIndex = 0;

  currentUrl = '';
  lastUrl = '';
  poppedState = false;

  constructor(
    router: Router,
    locationStrategy: LocationStrategy,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element
  ) {
    locationStrategy.onPopState(() => {
      this.poppedState = true;
      this.handleCriticalItemsPoppedState();
      this.setRestoreScrollPosition(window.location.href, true);
    });

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart && !this.poppedState) {
        this.setScrollY(
          window.location.href,
          this.parentScrollElement.scrollTop
        );
      }
      if (event instanceof NavigationEnd && !this.poppedState) {
        this.parentScrollElement.scrollTo(0, 0);
      }
      this.handleCriticalItemsPoppedState(event);
      this.poppedState = false;
    });
  }

  public getPage(page: string): NaooPage {
    let pageState = this.pageMap.get(page);
    if (!pageState) {
      pageState = new NaooPage();
      this.pageMap.set(page, pageState);
    }
    return pageState;
  }

  public setPage(page: string, state: NaooPage) {
    if (state) {
      this.pageMap.set(page, state);
    }
  }

  public setScrollY(page: string, scrollYPosition: number): void {
    const currentPage = this.getPage(page);
    currentPage.scrollY.next(scrollYPosition);
  }

  public getScrollY(page: string): number {
    return this.getPage(page).scrollY.getValue();
  }

  public setPageOffset(page: string, pageOffset: number): void {
    const currentPage = this.getPage(page);
    currentPage.pageOffset = pageOffset;
  }

  public getPageOffset(page: string): number {
    return this.getPage(page).pageOffset || 1;
  }

  public getRestoreScrollPosition(page: string): boolean {
    return this.getPage(page).shouldRestoreScrollPosition || false;
  }

  public getScrollToElementIndex(): number {
    return this.scrollToElementIndex;
  }

  public setScrollToElementIndex(index: number) {
    this.scrollToElementIndex = index;
  }

  public restoreScrollPosition(page: string): void {
    setTimeout(() => {
      this.setRestoreScrollPosition(page, false);
      this.parentScrollElement.scrollTo(0, this.getScrollY(page));
    }, 100);
  }

  public getCriticalItemSectionPoppedState(): boolean {
    return this.criticalItemsSectionPoppedState;
  }

  public setRestoreScrollPosition(
    page: string,
    shouldRestoreScrollPosition: boolean
  ): void {
    const currentPage = this.getPage(page);
    currentPage.shouldRestoreScrollPosition = shouldRestoreScrollPosition;
  }

  private handleCriticalItemsPoppedState(event?: Event) {
    if (event) {
      if (event instanceof NavigationEnd) {
        this.lastUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;
        if (
          !this.lastUrl.includes(NaooConstants.PRODUCT_DETAILS_PATH) ||
          this.currentUrl !== NaooConstants.CART_PATH
        ) {
          this.criticalItemsSectionPoppedState = false;
        }
      }
    } else if (
      this.currentUrl.includes(NaooConstants.PRODUCT_DETAILS_PATH) &&
      this.lastUrl === NaooConstants.CART_PATH
    ) {
      this.criticalItemsSectionPoppedState = true;
    }
  }
}
