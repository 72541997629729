import { Injectable } from '@angular/core';
import {
  HeaderError,
  HeaderTotals,
  LineDetail,
  Order,
  OrderConfirmation,
  OrderConfirmationHeader,
  OrderConfirmationOrders,
  OrderItem,
  OrderItemIssues,
  QuantityConfirmedIcon,
  ShipToAddress,
  StatusClass,
} from './models/order-confirmation';
import { CustomerMaterialRecord } from '../../core/services/customer-material/model/customer-material-record';
import {
  OrderConfirmationCompleteResponse,
  OrderConfirmationRecord,
  Severity,
} from '../../core/services/order-confirmation/models/order-confirmation-record';
import {
  OrderType,
  RequestedDeliveryType,
} from '../../shared/models/order-type';
import { MaterialInfo } from '../../shared/models/material-info';
import {
  CartOrder,
  CartOrderMaterialRecord,
} from '../../core/services/cart-order/models/cart-order';
import {
  CurrentSystem,
  SessionActiveCustomer,
} from '../../core/services/session/models/session-record';
import { OrderConfirmationTransformationUtil } from './order-confirmation-transformation.util';
import {
  CombinedPricingRecord,
  CombinedUnitPriceRecord,
  PricingSource,
} from '../../core/store/material-price/material-price.util';
import { CombinedPriceCalculation } from '../../core/store/material-price/material-price.facade';
import { StoreRecord } from '../../core/services/store/model/store-record';

interface OrderData extends OrderItemIssues {
  headerErrors: HeaderError[];
  order: Order;
  unitTotalsMap: Map<string, number>;
}

interface OrderTotals {
  unitTotalsMap: Map<string, number>;
  lineCount: number;
  itemCount: number;
  estimatedCost: number;
}

interface ProcessedOrders {
  headerErrors: HeaderError[];
  stockOrders: Order[];
  dropShipOrders: Order[];
  expressOrders: Order[];
  ispuOrders: Order[];
  nonStockOrders: Order[];
  errorMaterials: OrderItem[];
  warningMaterials: OrderItem[];
  orderTotals: OrderTotals;
  totalDiscountAmount: number;
  shipToAddress: ShipToAddress;
  submittedOn: Date;
}

@Injectable({
  providedIn: 'root',
})
export class OrderConfirmationTransformationService {
  constructor(
    private orderDataTransformationService: OrderConfirmationTransformationUtil
  ) {}

  public transformOrderConfirmation(
    orderConfirmationCompleteResponse: OrderConfirmationCompleteResponse,
    customer: SessionActiveCustomer,
    storeRecords: Map<string, StoreRecord>,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialInfoMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord,
    materialRelatedRouteDate?: Date
  ): OrderConfirmation {
    if (CurrentSystem.Sap === customer.currentSystem) {
      // read prices from response
      combinedPriceMap = this.getSapPrices(orderConfirmationCompleteResponse);
    }

    const processedOrders = this.processOrdersFromResponse(
      orderConfirmationCompleteResponse,
      materialInfoMap,
      storeRecords,
      customerMaterial,
      customer
    );

    const {
      orderTotals,
      totalDiscountAmount,
      shipToAddress,
      errorMaterials,
      warningMaterials,
      stockOrders,
      nonStockOrders,
      dropShipOrders,
      expressOrders,
      ispuOrders,
      headerErrors,
    } = processedOrders;

    const stillProcessingMaterials = this.transformStillProcessingOrderItems(
      orderConfirmationCompleteResponse.processingCartOrders,
      combinedPriceMap,
      materialInfoMap,
      customerMaterial
    );

    const submittedOn =
      processedOrders.submittedOn ??
      this.buildSubmittedOnDateFromCartOrderList(
        orderConfirmationCompleteResponse.processingCartOrders
      );

    const orders: OrderConfirmationOrders = {
      errorMaterials,
      warningMaterials,
      stillProcessingMaterials,
      mainOrders: this.pickOrders(stockOrders, ispuOrders, expressOrders),
      nonStockOrders,
      dropShipOrders,
    };

    const shipToLabel =
      ispuOrders?.length > 0
        ? 'ORDER_CONFIRMATION.HEADER.STORE_LOCATION'
        : 'ORDER_CONFIRMATION.HEADER.SHIP_TO';

    const header = this.buildOrderConfirmationHeader(
      orders,
      shipToAddress,
      submittedOn,
      orderTotals,
      customer,
      combinedPriceMap,
      totalDiscountAmount,
      shipToLabel
    );

    return {
      headerErrors,
      header,
      orders,
      materialRelatedRouteDate,
    };
  }

  private processOrdersFromResponse(
    orderConfirmationCompleteResponse: OrderConfirmationCompleteResponse,
    materialInfoMap: Map<string, MaterialInfo>,
    storeRecords: Map<string, StoreRecord>,
    customerMaterial: CustomerMaterialRecord,
    customer: SessionActiveCustomer
  ): ProcessedOrders {
    const unitTotalsMap = new Map<string, number>();
    const stockOrders: Order[] = [];
    const dropShipOrders: Order[] = [];
    const ispuOrders: Order[] = [];
    const expressOrders: Order[] = [];
    let headerErrors: HeaderError[] = [];
    let nonStockOrders: Order[] = [];
    let errorMaterials: OrderItem[] = [];
    let warningMaterials: OrderItem[] = [];
    let lineCount = 0;
    let itemCount = 0;
    let estimatedCost = 0;
    let shipToAddress: ShipToAddress;
    let submittedOn: Date;
    let totalDiscountAmount = 0;

    const isSapCustomer = CurrentSystem.Sap === customer.currentSystem;
    orderConfirmationCompleteResponse.orders.forEach(
      (orderConfirmationRecord) => {
        const orderData = this.orderDataTransformationService.transformOrderConfirmationOrderData(
          orderConfirmationRecord,
          materialInfoMap,
          storeRecords,
          customerMaterial,
          customer
        );
        headerErrors = headerErrors.concat(orderData.headerErrors);

        nonStockOrders = this.categorizeOrders(
          orderData,
          orderConfirmationRecord,
          stockOrders,
          nonStockOrders,
          dropShipOrders,
          expressOrders,
          ispuOrders,
          isSapCustomer
        );
        errorMaterials = errorMaterials.concat(orderData.errorMaterials);
        warningMaterials = warningMaterials.concat(orderData.warningMaterials);

        // Aggregate UoM totals for all order types
        orderData.unitTotalsMap.forEach((total, uom) => {
          const existingQuantity = unitTotalsMap.get(uom) ?? 0;
          unitTotalsMap.set(uom, existingQuantity + total);
        });

        lineCount += orderConfirmationRecord.totalLines;
        itemCount += orderConfirmationRecord.totalQuantityOrdered;
        estimatedCost += orderConfirmationRecord.totalPrice;
        totalDiscountAmount += orderConfirmationRecord.totalDiscountAmount;

        if (!shipToAddress) {
          shipToAddress = this.orderDataTransformationService.buildShipToAddress(
            orderConfirmationRecord,
            storeRecords
          );
        }
        if (!submittedOn) {
          submittedOn = orderConfirmationRecord.submittedDateTime;
        }
      }
    );

    const orderTotals: OrderTotals = {
      lineCount,
      itemCount,
      estimatedCost,
      unitTotalsMap,
    };

    if (!shipToAddress) {
      shipToAddress = this.buildShipToAddressFromCustomer(customer);
    }

    if (dropShipOrders.length > 1) {
      let count = 1;
      dropShipOrders.forEach(
        (dropShipOrder) => (dropShipOrder.dropShipOrderIndex = count++)
      );
    }
    dropShipOrders.forEach((order) => {
      if (this.addressesAreEqual(shipToAddress, order.shipToAddress)) {
        order.shipToAddress = undefined;
      }
    });

    return {
      headerErrors,
      nonStockOrders,
      stockOrders,
      dropShipOrders,
      expressOrders,
      ispuOrders,
      errorMaterials,
      warningMaterials,
      orderTotals,
      totalDiscountAmount,
      shipToAddress,
      submittedOn,
    };
  }

  private addressesAreEqual(
    first: ShipToAddress,
    second: ShipToAddress
  ): boolean {
    return (
      first.shipAddress1 === second.shipAddress1 &&
      first.shipAddress2 === second.shipAddress2 &&
      first.city === second.city &&
      first.province === second.province &&
      first.zipCode === second.zipCode
    );
  }

  private categorizeOrders(
    orderData: OrderData,
    orderConfirmationRecord: OrderConfirmationRecord,
    stockOrders: Order[],
    nonStockOrders: Order[],
    dropShipOrders: Order[],
    expressOrders: Order[],
    ispuOrders: Order[],
    isSapCustomer: boolean
  ) {
    if (orderData.order.items.length > 0) {
      switch (orderConfirmationRecord.orderType) {
        case OrderType.Stock:
          stockOrders.push(orderData.order);
          break;
        case OrderType.NonStock:
          isSapCustomer
            ? nonStockOrders.push(orderData.order)
            : (nonStockOrders = this.combineOrder(
                orderData.order,
                nonStockOrders
              ));
          break;
        case OrderType.DropShip:
          dropShipOrders.push(orderData.order);
          break;
        case OrderType.StoreFulfillment:
          const deliveryType =
            orderConfirmationRecord.storeFulfillment?.requestedDeliveryType;
          if (RequestedDeliveryType.EXPRESS === deliveryType) {
            expressOrders.push(orderData.order);
          } else {
            ispuOrders.push(orderData.order);
          }
          break;
      }
    }

    // Sort All Orders
    stockOrders.sort(this.sortByRequestedCustomerArrivalDate);
    dropShipOrders.sort(this.sortByRequestedCustomerArrivalDate);
    expressOrders.sort(this.sortByDeliveryWindowStartTimestamp);
    ispuOrders.sort(this.sortByRequestedPickupTimestamp);

    return nonStockOrders;
  }

  private getSapPrices(
    orderConfirmationCompleteResponse: OrderConfirmationCompleteResponse
  ): Map<string, CombinedPricingRecord> {
    const sapPricingMap = new Map<string, CombinedPricingRecord>();
    orderConfirmationCompleteResponse.orders.forEach(
      (orderConfirmationRecord) => {
        const materialLinesByMaterial = this.orderDataTransformationService.groupMaterialLinesByMaterial(
          orderConfirmationRecord.materials
        );
        materialLinesByMaterial.forEach((materials, materialNumber) => {
          let sapPricingRecord: CombinedPricingRecord;
          materials.forEach((material) => {
            if (!sapPricingRecord) {
              sapPricingRecord = {
                materialNumber,
                isCatchWeight: material.catchweightIndicator,
                weightUom: material.catchWeightUom,
                hasLoaded: true,
                unitPrices: [],
              };
            }
            const sapUnitPrice: CombinedUnitPriceRecord = {
              salesUom: material.uom,
              price: material.price,
              pricingSource: PricingSource.Sap,
              catchWeightPrice: material.catchWeightPrice,
            };
            sapPricingRecord.unitPrices.push(sapUnitPrice);
          });
          sapPricingMap.set(materialNumber, sapPricingRecord);
        });
      }
    );
    return sapPricingMap;
  }

  private sortByRequestedCustomerArrivalDate(
    order1: Order,
    order2: Order
  ): number {
    return (
      Date.parse(order1.requestedCustomerArrivalDate) -
      Date.parse(order2.requestedCustomerArrivalDate)
    );
  }

  private sortByDeliveryWindowStartTimestamp(
    order1: Order,
    order2: Order
  ): number {
    return (
      Date.parse(order1.deliveryWindowStartTimestamp) -
      Date.parse(order2.deliveryWindowStartTimestamp)
    );
  }

  private sortByRequestedPickupTimestamp(order1: Order, order2: Order): number {
    return (
      Date.parse(order1.requestedPickupTimestamp) -
      Date.parse(order2.requestedPickupTimestamp)
    );
  }

  private buildSubmittedOnDateFromCartOrderList(cartOrders: CartOrder[]): Date {
    return cartOrders
      .map((cartOrder) => cartOrder.submitTimestamp)
      .filter((submitTimeStamp) => !!submitTimeStamp)
      .slice()
      .pop();
  }

  private buildOrderConfirmationHeader(
    orders: OrderConfirmationOrders,
    shipToAddress: ShipToAddress,
    submittedOn: Date,
    orderTotals: OrderTotals,
    customer: SessionActiveCustomer,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    totalDiscountAmount: number,
    shipToLabel: string
  ): OrderConfirmationHeader {
    return {
      customerUnitName: customer.name,
      customerNumber: customer.customerDisplayId,
      customerTimeZone: customer.timeZone,
      shipToAddress,
      submittedOn,
      headerTotals: this.buildHeaderTotals(
        orders,
        orderTotals,
        combinedPriceMap,
        totalDiscountAmount
      ),
      shipToLabel,
    };
  }

  private buildShipToAddressFromCustomer(
    customer: SessionActiveCustomer
  ): ShipToAddress {
    const address = customer.address;
    return {
      shipAddress1: address.line1 ?? '',
      shipAddress2: address.line2 ?? '',
      city: address.city ?? '',
      province: address.provinceState ?? '',
      zipCode: address.postalCode ?? '',
    };
  }

  private buildHeaderTotals(
    orderConfirmationOrders: OrderConfirmationOrders,
    orderTotals: OrderTotals,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    totalDiscountAmount = 0
  ): HeaderTotals {
    let { lineCount, itemCount, estimatedCost } = orderTotals;

    const processingOrderTotals = this.getOrderItemTotals(
      orderConfirmationOrders.stillProcessingMaterials,
      combinedPriceMap
    );
    lineCount += processingOrderTotals.lineCount;
    itemCount += processingOrderTotals.itemCount;
    estimatedCost += processingOrderTotals.estimatedCost;

    const unitTotalsMap = new Map<string, number>();
    orderTotals.unitTotalsMap.forEach((quantity, uom) => {
      const existingQuantity = unitTotalsMap.get(uom) ?? 0;
      unitTotalsMap.set(uom, existingQuantity + quantity);
    });
    processingOrderTotals.unitTotalsMap.forEach((quantity, uom) => {
      const existingQuantity = unitTotalsMap.get(uom) ?? 0;
      unitTotalsMap.set(uom, existingQuantity + quantity);
    });

    return {
      lineCount,
      itemCount,
      estimatedCost,
      totalDiscountAmount,
    };
  }

  private getOrderItemTotals(
    orderItems: OrderItem[],
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    filteredMaterialNumbers: string[] = []
  ): OrderTotals {
    let lineCount = 0;
    let itemCount = 0;
    let estimatedCost = 0;
    const unitTotalsMap = new Map<string, number>();

    orderItems
      .filter((orderItem) => {
        return !filteredMaterialNumbers.includes(orderItem.itemDetail.id);
      })
      .forEach((orderItem) => {
        const unitTotalsMapForOrderItem = new Map<string, number>();
        orderItem.orderLines.forEach((lineDetail) => {
          const existingQuantityForOrderItem =
            unitTotalsMapForOrderItem.get(lineDetail.uom) ?? 0;
          unitTotalsMapForOrderItem.set(
            lineDetail.uom,
            existingQuantityForOrderItem + lineDetail.quantityOrdered
          );
          const existingQuantity = unitTotalsMap.get(lineDetail.uom) ?? 0;
          unitTotalsMap.set(
            lineDetail.uom,
            existingQuantity + lineDetail.quantityOrdered
          );
          itemCount += lineDetail.quantityOrdered;
          lineCount++;
        });

        const materialPrice = combinedPriceMap.get(orderItem.itemDetail.id);
        const calculatedPrice = this.calculatePrice(
          materialPrice,
          unitTotalsMapForOrderItem
        );

        estimatedCost += Array.from(calculatedPrice.lineTotals.values()).reduce(
          (acc, total) => acc + total,
          0
        );
      });

    return { unitTotalsMap, lineCount, itemCount, estimatedCost };
  }

  private calculatePrice(
    combinedPrice: CombinedPricingRecord,
    unitQuantities: Map<string, number>
  ): CombinedPriceCalculation {
    const unitPriceCalculations: [string, number][] = combinedPrice
      ? combinedPrice.unitPrices.map((unit) => {
          const unitTotal =
            (unit.price ?? 0) * (unitQuantities.get(unit.salesUom) ?? 0);
          return [unit.salesUom, unitTotal];
        })
      : [];
    return new CombinedPriceCalculation(
      combinedPrice,
      new Map(unitPriceCalculations)
    );
  }

  private transformStillProcessingOrderItems(
    cartOrders: CartOrder[],
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialInfoMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord
  ): OrderItem[] {
    return cartOrders.reduce((acc, order) => {
      const orderItems: OrderItem[] = order.materials.map(
        (cartOrderMaterial) => {
          const { materialNumber } = cartOrderMaterial;
          const materialInfo = materialInfoMap.get(materialNumber);
          const combinedPrice = combinedPriceMap.get(materialNumber);
          const customerMaterialInfo = customerMaterial[materialNumber];
          const unitTotalsMapForPrice = this.transformCartOrderMaterialToUnitTotalsMap(
            cartOrderMaterial
          );
          const calculatedPrice = this.calculatePrice(
            combinedPrice,
            unitTotalsMapForPrice
          );
          const orderLines: LineDetail[] = [];
          unitTotalsMapForPrice.forEach(
            (quantityOrdered: number, uom: string) => {
              const totalLinePrice = calculatedPrice?.lineTotals?.get(uom);
              const price = this.getDisplayPrice(combinedPrice, uom);
              const catchWeightUom = combinedPrice?.isCatchWeight
                ? combinedPrice?.weightUom
                : undefined;
              const displayCode = materialInfo.units.find(
                (unit) => unit.uom === uom
              )?.displayCode;
              const discountAmount = 0;

              orderLines.push({
                uom,
                displayCode,
                catchWeightUom,
                quantityOrdered,
                price,
                totalLinePrice,
                lineSeverity: Severity.Warning,
                quantityConfirmedIcon: QuantityConfirmedIcon.Warning,
                quantityConfirmedClass: StatusClass.Warning,
                discountAmount,
              });
            }
          );
          const itemDetail = this.orderDataTransformationService.transformItemDetail(
            materialInfo,
            customerMaterialInfo,
            orderLines
          );

          return { orderLines, itemDetail };
        }
      );
      return acc.concat(orderItems);
    }, []);
  }

  private transformCartOrderMaterialToUnitTotalsMap(
    cartOrderMaterial: CartOrderMaterialRecord
  ) {
    const unitTotalsMap = new Map<string, number>();
    cartOrderMaterial.lines.forEach((line) => {
      const existingQuantity = unitTotalsMap.get(line.uom) ?? 0;
      unitTotalsMap.set(line.uom, existingQuantity + line.quantity);
    });
    return unitTotalsMap;
  }

  private getDisplayPrice(
    combinedPrice: CombinedPricingRecord,
    uom: string
  ): number | undefined {
    const unitPrice = combinedPrice?.unitPrices.find(
      (unit) => unit.salesUom === uom
    );
    return combinedPrice?.isCatchWeight
      ? unitPrice?.catchWeightPrice
      : unitPrice?.price;
  }

  private createMapOfOrderItemsByMaterial(
    orderItems: OrderItem[]
  ): Map<string, OrderItem> {
    const mapOfOrderItems = new Map<string, OrderItem>();
    orderItems.forEach((item) => {
      const materialNumber = item.itemDetail.id;
      const existingOrderItem = mapOfOrderItems.get(materialNumber);
      const orderLines = item.orderLines.concat(
        existingOrderItem?.orderLines ?? []
      );
      const itemDetail = existingOrderItem?.itemDetail ?? item.itemDetail;
      const orderItem: OrderItem = {
        itemDetail,
        orderLines,
      };
      mapOfOrderItems.set(materialNumber, orderItem);
    });
    return mapOfOrderItems;
  }

  private combineOrder(
    order: Order,
    nonStockOrders: Order[] | undefined
  ): Order[] {
    const combinedNonStockOrder =
      nonStockOrders.length > 0 ? nonStockOrders[0] : null;
    const mapOfOrderItems = this.createMapOfOrderItemsByMaterial(
      order.items.concat(combinedNonStockOrder?.items ?? [])
    );
    const items: OrderItem[] = [];
    mapOfOrderItems.forEach((orderItem, _) => {
      items.push(orderItem);
    });
    return [
      {
        totalQuantity:
          (order.totalQuantity ?? 0) +
          (combinedNonStockOrder?.totalQuantity ?? 0),
        estimatedTotal:
          (order.estimatedTotal ?? 0) +
          (combinedNonStockOrder?.estimatedTotal ?? 0),
        items,
        orderNumber: combinedNonStockOrder?.orderNumber ?? order.orderNumber,
        poNumber: combinedNonStockOrder?.poNumber ?? order.poNumber,
        requestedCustomerArrivalDate:
          combinedNonStockOrder?.requestedCustomerArrivalDate ??
          order.requestedCustomerArrivalDate,
      },
    ];
  }

  private pickOrders(
    stockOrders: Order[],
    ispuOrders: Order[],
    expressOrders: Order[]
  ): Order[] {
    if (stockOrders?.length) {
      return stockOrders;
    } else if (ispuOrders?.length) {
      return ispuOrders;
    } else {
      return expressOrders;
    }
  }
}
