import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  NutritionBlock,
  NutritionInfo,
  NutritionLabel,
} from 'src/app/core/store/mygfs-nutrition/models/mygfs-nutrition';
import { MaterialDetailsSection } from '../../../core/store/material-details/models/material-details.model';

@Component({
  selector: 'naoo-mygfs-nutrition-container',
  template: `<naoo-mygfs-nutrition-content [blockGroups]="blockGroups">
  </naoo-mygfs-nutrition-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MygfsNutritionContainerComponent {
  private readonly mineralsLabel = 'Minerals';
  private readonly schoolEquivalentsLabel = 'School Equivalents';
  private readonly fatSolubleVitaminsLabel = 'Fat Soluble Vitamins';
  private readonly waterSolubleVitaminsLabel = 'Water Soluble Vitamins';

  @Input() materialNumber: string;
  @Input() section: MaterialDetailsSection;

  @Input()
  set nutritionLabel(nutritionLabel: NutritionLabel) {
    const nutritionInfos = nutritionLabel?.nutritionInfos;
    const selectedInfo = nutritionInfos ? nutritionInfos[0] : undefined;
    const schoolEquivalents = this.findNutritionBlock(
      selectedInfo,
      this.schoolEquivalentsLabel
    );
    const minerals = this.findNutritionBlock(selectedInfo, this.mineralsLabel);
    const fatSolubleVitamins = this.findNutritionBlock(
      selectedInfo,
      this.fatSolubleVitaminsLabel
    );
    const waterSolubleVitamins = this.findNutritionBlock(
      selectedInfo,
      this.waterSolubleVitaminsLabel
    );

    if (MaterialDetailsSection.SchoolEquivalents === this.section) {
      this.blockGroups = [[schoolEquivalents]];
    } else if (MaterialDetailsSection.VitaminsAndMinerals === this.section) {
      this.blockGroups = [
        [minerals],
        [fatSolubleVitamins, waterSolubleVitamins],
      ];
    } else {
      this.blockGroups = [
        [schoolEquivalents],
        [minerals],
        [fatSolubleVitamins, waterSolubleVitamins],
      ];
    }
  }

  blockGroups: NutritionBlock[][];

  private findNutritionBlock(
    selectedInfo: NutritionInfo | undefined,
    name: string
  ): NutritionBlock | undefined {
    return selectedInfo?.nutritionBlocks.find(
      (nutritionBlock) => nutritionBlock.label === name
    );
  }
}
