import { Inject, Injectable } from '@angular/core';
import { GordonNowChatModel, GordonNowData } from './gordon-now-chat.model';
import { EnvironmentSpecificService } from '../services/environment-specific/environment-specific.service';
import { NavigationEnd, Router } from '@angular/router';
import { NaooConstants } from '../NaooConstants';
import { DOCUMENT } from '@angular/common';
import { NaooLogger } from '../logger/NaooLogger.service';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GordonNowChatService {
  private readonly attempts = 10;
  private readonly initialDelay = 100; // ms
  private readonly maxDelay = 1500; // ms

  private hasCalledInit: boolean;
  private chatModel: GordonNowChatModel;

  constructor(
    private logger: NaooLogger,
    private router: Router,
    private environmentSpecificService: EnvironmentSpecificService,
    private _window: Window,
    @Inject(DOCUMENT) private _document: Document
  ) {
    if (this.environmentSpecificService.isGordonNowEnabled()) {
      const script = _document.createElement('script');
      const attributes = this.environmentSpecificService.getGordonNowChatScriptAttributes();
      for (const [key, value] of attributes.entries()) {
        script.setAttribute(key, value);
      }
      _document.head.appendChild(script);

      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          if (!this.chatModel) {
            return;
          }
          const isMcu =
            NaooConstants.CUSTOMER_UNIT_SELECTION_PATH ===
            event.urlAfterRedirects;
          if (isMcu !== this.chatModel.hidden) {
            this.updateGordonNow({
              hidden: isMcu,
            });
          }
        });
    }
  }

  updateState(gnData: GordonNowData): void {
    if (gnData) {
      const chatModel = {
        chatChannel: this.environmentSpecificService.getGordonNowChatChannel(
          gnData.brand
        ),
        customerUnitKey: gnData.customerUnitKey,
        hidden: false,
        language: {
          override: gnData.language,
        },
        logRocket: {
          appId: this.environmentSpecificService.getLogRocketAppId(),
          isEnabled: true,
          parentIframeDomain: `${this._window.location.protocol}//${this._window.location.hostname}`,
        },
      };
      this.updateGordonNow(chatModel);
    }
  }

  isChatbotHidden(): boolean | undefined {
    return this.chatModel?.hidden;
  }

  updateGordonNow(partialChatModel: Partial<GordonNowChatModel>): void {
    if (this.chatModel?.hidden === partialChatModel.hidden) {
      return;
    }

    if (this.environmentSpecificService.isGordonNowEnabled()) {
      this.chatModel = { ...this.chatModel, ...partialChatModel };
      this.retry(this.attempts, this.initialDelay, () => {
        if (this.hasCalledInit) {
          this._window.__gordonNowChatUi.updateState(this.chatModel);
        } else {
          this.hasCalledInit = this._window.__gordonNowChatUi.init(
            this.chatModel
          );
        }
      });
    }
  }

  private retry(attempts: number, delay: number, fn: () => void): void {
    try {
      fn();
    } catch (error) {
      if (attempts > 1) {
        const exponentialBackoff = Math.min(delay * 2, this.maxDelay);
        setTimeout(
          () => this.retry(attempts - 1, exponentialBackoff, fn),
          exponentialBackoff
        );
      } else {
        this.logger.error(
          `Unable to communicate with Gordon Now Chat => ${error}`
        );
      }
    }
  }
}
