import { Injectable, OnDestroy } from '@angular/core';
import {
  Language,
  Locale,
} from 'src/app/core/services/session/models/session-record';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { SessionFacade } from 'src/app/core/store/session/session.facade';
import { takeUntil } from 'rxjs/operators';

export interface LocalizationServiceInterface {
  currentLanguage: string;
  currentLocale: string;
  parser: TranslateParser;
  language(): Observable<Language>;
  locale(): Observable<Locale>;
  use(language: Language): void;
  instant(
    key: string | string[],
    interpolateParams?: object,
    language?: string
  ): any;
  get(
    key: string | Array<string>,
    interpolateParams?: object
  ): Observable<string | any>;
  setTranslation(
    lang: Language,
    translations: object,
    shouldMerge?: boolean
  ): void;
  addLangs(langs: Array<string>): void;
  setDefaultLang(lang: Language): void;
  set(key: string, value: string, lang?: Language): void;
}

@Injectable({ providedIn: 'root' })
export class LocalizationService
  implements LocalizationServiceInterface, OnDestroy {
  private _currentLanguage$ = new BehaviorSubject<Language>(Language.en);
  private _currentLocale$ = new BehaviorSubject<Locale>(Locale.en_CA);

  private destroyed$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private sessionFacade: SessionFacade
  ) {
    this.sessionFacade
      .getLoadedSession()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sessionRecord) => {
        this._currentLanguage$.next(sessionRecord.language);

        if (sessionRecord.activeCustomer) {
          this._currentLocale$.next(sessionRecord.activeCustomer.locale);
        }
      });
  }

  get currentLanguage(): Language {
    return this._currentLanguage$.value;
  }

  get currentLocale(): Locale {
    return this._currentLocale$.value;
  }

  get parser(): TranslateParser {
    return this.translateService.parser;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setDefaultLang(lang: Language): void {
    this.translateService.setDefaultLang(lang);
  }

  addLangs(langs: string[]): void {
    this.translateService.addLangs(langs);
  }

  setTranslation(
    lang: Language,
    translations: object,
    shouldMerge?: boolean
  ): void {
    this.translateService.setTranslation(lang, translations, shouldMerge);
  }

  get(key: string | string[], interpolateParams?: object): Observable<any> {
    return this.translateService.get(key, interpolateParams);
  }

  instant(
    key: string | string[],
    interpolateParams?: object,
    language?: Language
  ): string {
    if (!key) {
      return '';
    }
    if (!language) {
      return this.translateService.instant(key, interpolateParams);
    }
    this.translateService.use(language);
    const value: string = this.translateService.instant(key, interpolateParams);
    this.translateService.use(this.currentLanguage);
    return value;
  }

  language(): Observable<Language> {
    return this._currentLanguage$.asObservable();
  }

  locale(): Observable<Locale> {
    return this._currentLocale$.asObservable();
  }

  use(language: Language): void {
    this.translateService.use(language);
    this.sessionFacade.updateLanguage(language);
  }

  set(key: string, value: string, lang?: Language): void {
    this.translateService.set(key, value, lang);
  }
}
