import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SpecialOrderRowViewModel } from './special-order-row-view-model';
import { CONTENT_SCROLL } from '../../../shared/services/scrollable-content/scrollable-content.service';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { IconState } from '../../../shared/action-icon/action-icon.component';
import { PrintService } from '../../../shared/services/print/print.service';
import { SpecialOrdersFacade } from '../../../core/store/special-orders/special-orders.facade';
import { SpecialOrdersStatus } from '../../../core/store/special-orders/special-orders.state';
import { LoadingService } from 'src/app/shared/services/loading-service/loading.service';
import { SpecialOrderInfo } from '../../../core/store/special-orders/special-orders.selectors';
import { VirtualScrollerComponent } from '../../../vendor/ngx-virtual-scroller/virtual-scroller';

@Component({
  selector: 'naoo-special-order',
  templateUrl: './special-order.component.html',
  styleUrls: ['./special-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialOrderComponent implements OnInit, OnDestroy {
  @ViewChild('scroll', { static: false })
  virtualScroll: VirtualScrollerComponent;
  specialOrderInfo: SpecialOrderInfo;
  shouldRenderPrint$: Subject<boolean> = new BehaviorSubject(false);
  iconState: IconState = IconState.Disabled;

  private destroyed$ = new Subject<void>();

  constructor(
    private specialOrdersFacade: SpecialOrdersFacade,
    private changeDetector: ChangeDetectorRef,
    private printService: PrintService,
    private loadingService: LoadingService,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element
  ) {}

  get hasSpecialOrdersError(): boolean {
    return (
      !!this.specialOrderInfo &&
      this.specialOrderInfo.status === SpecialOrdersStatus.Error
    );
  }

  ngOnInit() {
    this.loadingService.start();
    this.specialOrdersFacade.refreshSpecialOrders();

    this.specialOrdersFacade
      .getLoadedSpecialOrderInfo()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((specialOrderInfo) => {
        this.specialOrderInfo = specialOrderInfo;
        this.iconState = IconState.Enabled;
        this.loadingService.stop();
        this.changeDetector.markForCheck();
      });

    this.printService.setUp(
      () => {
        this.shouldRenderPrint$.next(true);
        return of(true);
      },
      () => {
        this.shouldRenderPrint$.next(false);
      }
    );
  }

  ngOnDestroy(): void {
    this.loadingService.stop();
    this.printService.tearDown();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  compareViewModels(
    one: SpecialOrderRowViewModel,
    two: SpecialOrderRowViewModel
  ): boolean {
    return !!(
      one &&
      two &&
      one.materialInfo &&
      two.materialInfo &&
      one.materialInfo.materialNumber === two.materialInfo.materialNumber
    );
  }

  trackByMaterialNumber(
    index: number,
    viewModel: SpecialOrderRowViewModel
  ): string {
    return viewModel.materialNumber;
  }

  onPrintIconClick(): void {
    this.printService.print();
  }
}
