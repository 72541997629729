import { Injectable } from '@angular/core';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaterialUnitsRecord } from './models/material-units-record';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';

interface MaterialUnitsResponse {
  displayCodes: MaterialUnitsRecord[];
}

@Injectable({ providedIn: 'root' })
export class MaterialUnitsService {
  constructor(
    private http: NaooHttpClient,
    private webBffService: WebBffService
  ) {}

  getMaterialUnitsInformation(): Observable<MaterialUnitsRecord[]> {
    return this.http
      .get<MaterialUnitsResponse>(
        this.webBffService.getBff() + '/api/v1/materials/units'
      )
      .pipe(map((response: MaterialUnitsResponse) => response.displayCodes));
  }
}
