import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrderGuideChangeHistoryEffects } from './order-guide-change-history.effects';
import { OrderGuideChangeHistoryReducer } from './order-guide-change-history.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'orderGuideChangeHistory',
      OrderGuideChangeHistoryReducer
    ),
    EffectsModule.forFeature([OrderGuideChangeHistoryEffects]),
  ],
})
export class OrderGuideChangeHistoryStoreModule {}
