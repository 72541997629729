import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreActions } from './store.actions';
import { Observable } from 'rxjs';
import {
  ActiveStoreFulfillment,
  selectActiveStoreFulfillment,
  selectAllStoreRecords,
  selectLoadedStoreRecord,
  selectLoadedStoreRecords,
} from './store.selectors';
import { StoreRecord } from '../../services/store/model/store-record';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StoreFacade {
  constructor(private store: Store) {}

  loadStores(): void {
    this.store.dispatch(StoreActions.loadStores());
  }

  getLoadedStoreRecord(storePlantId: string): Observable<StoreRecord> {
    return this.store
      .select(selectLoadedStoreRecord(storePlantId))
      .pipe(filter((record) => !!record));
  }

  getLoadedStoreRecords(
    storePlantIds: string[]
  ): Observable<Map<string, StoreRecord>> {
    return this.store
      .select(selectLoadedStoreRecords(storePlantIds))
      .pipe(filter((records) => !!records));
  }

  getAllStoreRecords(): Observable<StoreRecord[]> {
    return this.store.select(selectAllStoreRecords);
  }

  getActiveStoreFulfillment(): Observable<ActiveStoreFulfillment> {
    return this.store.select(selectActiveStoreFulfillment);
  }
}
