import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialCutoffEntryRecord } from '../../services/material-cutoff/models/material-cutoff-record';

export interface MaterialCutoffState {
  cutoffs: EntityState<MaterialCutoffRecordState>;
  hasLoadedAllCutoffs: boolean; // Only used by MyGfs+SAP
  considerInventory: boolean;
}

export interface MaterialCutoffRecordState {
  cutoffCode: string;
  status: MaterialCutoffRecordStatus;
  record?: MaterialCutoffEntryRecord;
}

export enum MaterialCutoffRecordStatus {
  Queued = 'Queued',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
  Cleared = 'Cleared',
}

export interface MaterialCutoffEntryRecordState {
  status: MaterialCutoffRecordStatus;
  record?: MaterialCutoffEntryRecord;
}

export const materialCutoffAdapter = createEntityAdapter<MaterialCutoffRecordState>(
  {
    selectId: (materialCutoff) => materialCutoff.cutoffCode,
  }
);

export const initialMaterialCutoffState: MaterialCutoffState = {
  cutoffs: materialCutoffAdapter.getInitialState(),
  hasLoadedAllCutoffs: false,
  considerInventory: undefined,
};
