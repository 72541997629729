import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { NaooSessionStorage } from '../../../shared/storage/session-storage/session-storage.service';
import { MaterialComparisonActions } from './material-comparison.actions';
import { map } from 'rxjs/operators';
import { selectCurrentCompositeCustomerId } from '../session/session.selectors';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { selectMaterialComparisonState } from './material-comparison.selectors';
import { Observable } from 'rxjs';
import { MaterialComparisonState } from './material-comparison.state';
import { SharedActions } from '../shared/shared.actions';

interface MaterialComparisonStorage {
  isEnabled: boolean;
  materialNumbers: string[];
}

interface MaterialComparisonStorageMap {
  [compositeCustomerId: string]: MaterialComparisonStorage;
}

@Injectable()
export class MaterialComparisonEffects {
  private readonly materialComparisonKey = 'materialComparisons';
  private readonly toggleComparisonAnalyticsEvent: AnalyticsEventInfo = {
    action: 'click',
    category: 'product comparison',
    label: 'compare toggle - on',
  };

  constructor(
    private actions$: Actions,
    private store: Store,
    private analytics: NaooAnalyticsManager,
    private naooSessionStorage: NaooSessionStorage
  ) {}

  toggleMaterialComparison$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MaterialComparisonActions.toggleMaterialComparison),
        concatLatestFrom(() => [
          this.store.select(selectMaterialComparisonState),
          this.store.select(selectCurrentCompositeCustomerId),
        ]),
        map(([action, currentMaterialComparisonState, compositeCustomerId]) => {
          if (action.isEnabled) {
            this.analytics.trackAnalyticsEvent(
              this.toggleComparisonAnalyticsEvent
            );
          }
          this.saveToSessionStorage(
            compositeCustomerId,
            currentMaterialComparisonState
          );
        })
      );
    },
    { dispatch: false }
  );

  saveToSessionStorage$: Observable<void> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MaterialComparisonActions.addMaterialComparison,
          MaterialComparisonActions.loadFromMaterialList,
          MaterialComparisonActions.removeAllMaterialComparisons,
          MaterialComparisonActions.removeMaterialComparison
        ),
        concatLatestFrom(() => [
          this.store.select(selectMaterialComparisonState),
          this.store.select(selectCurrentCompositeCustomerId),
        ]),
        map(
          ([_action, currentMaterialComparisonState, compositeCustomerId]) => {
            this.saveToSessionStorage(
              compositeCustomerId,
              currentMaterialComparisonState
            );
          }
        )
      );
    },
    { dispatch: false }
  );

  hydrateMaterialComparison$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialComparisonActions.hydrateMaterialComparison),
      concatLatestFrom(() =>
        this.store.select(selectCurrentCompositeCustomerId)
      ),
      map(([_action, compositeCustomerId]) => {
        const comparisonStorageMap: MaterialComparisonStorageMap = this.loadFromSessionStorage();
        const comparisonStorage: MaterialComparisonStorage =
          comparisonStorageMap[compositeCustomerId];
        if (comparisonStorage) {
          return MaterialComparisonActions.hydrateMaterialComparisonSuccess(
            comparisonStorage.isEnabled,
            comparisonStorage.materialNumbers
          );
        } else {
          return SharedActions.noOperation(
            'Current customer has no state saved.'
          );
        }
      })
    );
  });

  private saveToSessionStorage(
    compositeCustomerId: string,
    materialComparisonState: MaterialComparisonState
  ) {
    const storageMap: MaterialComparisonStorageMap = this.loadFromSessionStorage();
    storageMap[compositeCustomerId] = {
      isEnabled: materialComparisonState.isEnabled,
      materialNumbers: materialComparisonState.materialNumbers,
    };

    this.naooSessionStorage.setItem(
      this.materialComparisonKey,
      JSON.stringify(storageMap)
    );
  }

  private loadFromSessionStorage(): MaterialComparisonStorageMap {
    const storageMapString: string = this.naooSessionStorage.getItem(
      this.materialComparisonKey
    );
    return storageMapString ? JSON.parse(storageMapString) : {};
  }
}
