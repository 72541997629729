import moment from 'moment-timezone';

export class DeliverySchedule {
  static dateString(date: Date): string {
    if (!date) {
      return '';
    }
    const monthString = this.zeroPadDateString(date.getUTCMonth() + 1);
    const dateString = this.zeroPadDateString(date.getUTCDate());
    return date.getUTCFullYear() + '-' + monthString + '-' + dateString;
  }

  static dateStringAsDate(dateString: string): Date {
    if (!dateString) {
      return null;
    }
    const [year, month, day] = dateString
      .split('-')
      .map((str) => parseInt(str, 10));
    return new Date(Date.UTC(year, month - 1, day));
  }

  private static zeroPadDateString(number: number): string {
    return number.toString().padStart(2, '0');
  }

  constructor(
    public routeDate: string, // routeDate is returned as a string with no Timezone information from Retalix
    public customerArrivalDate: string,
    public cutoffDateTime: moment.Moment,
    public routeId: string
  ) {}

  routeDateAsDate(): Date {
    return DeliverySchedule.dateStringAsDate(this.routeDate);
  }

  customerArrivalDateAsDate(): Date {
    return DeliverySchedule.dateStringAsDate(this.customerArrivalDate);
  }
}
