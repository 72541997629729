import { createEntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { Localized } from '../../../shared/models/localized';

export interface EcommerceAnalyticsState {
  materials: EntityState<EcommerceAnalyticsMaterialState>;
  behavioralAdvertising: boolean;
}

export interface EcommerceAnalyticsMaterialState {
  materialNumber: string;
  recommendation: MaterialRecommendationState;
  sponsoredSearch?: MaterialSponsoredSearchState;
}

export interface MaterialRecommendationState {
  name: Localized<string>;
  title: Localized<string>;
  position: number;
}

export interface MaterialSponsoredSearchState {
  materialNumber: string;
  searchTerm: string;
  hasBanners: boolean;
  hasMaterials: boolean;
  campaignId: string;
  sponsored: boolean;
}

export const ecommerceAnalyticsMaterialAdapter = createEntityAdapter<EcommerceAnalyticsMaterialState>(
  {
    selectId: (state) => state.materialNumber,
  }
);

export const initialEcommerceAnalyticsState: EcommerceAnalyticsState = {
  materials: ecommerceAnalyticsMaterialAdapter.getInitialState(),
  behavioralAdvertising: false,
};
