import {
  initialMaterialRowState,
  MaterialRowState,
} from './material-row.state';
import { MaterialRowActions } from './material-row.actions';
import { MaterialListStyle } from './models/material-row';
import { createReducer, on } from '@ngrx/store';

export const materialRowReducer = createReducer(
  initialMaterialRowState,
  on(
    MaterialRowActions.updatePreferredMaterialView,
    (state, action): MaterialRowState =>
      updatePreferredMaterialView(state, action.viewType)
  ),
  on(
    MaterialRowActions.hydratePreferredMaterialView,
    (): MaterialRowState => initialMaterialRowState
  )
);

function updatePreferredMaterialView(
  state: MaterialRowState,
  viewType: MaterialListStyle
): MaterialRowState {
  return { ...state, preferredMaterialView: viewType };
}
