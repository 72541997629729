<naoo-modal-header
  [title]="modalTitle | translate"
  [showCloseButton]="true"
  [stickyHeader]="true"
  (closeModalEmitter)="closeModal()"
>
</naoo-modal-header>

<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="itemException">
    <span class="material-list-header">
      {{ materials?.length }}
      {{
        'ORDER_CONFIRMATION.ITEM_EXCEPTIONS.MODAL.NUMBER_OF_SUBSTITUTES'
          | translate
      }}
    </span>

    <div class="exceptions-container">
      <naoo-error-materials-header [canFindRelated]="false">
      </naoo-error-materials-header>
      <naoo-order-issue-row
        [orderItem]="itemException"
        [isExpandedView]="false"
      >
      </naoo-order-issue-row>
    </div>
  </ng-container>

  <ng-container *ngIf="materialWithSubstitutes">
    <div class="materials-container">
      <div class="materials-header-container" *ngIf="!isMobile">
        <naoo-material-list-header
          [listStyle]="listStyle"
          [context]="context"
          [displayGoPointsLogo]="false"
          [isLoyaltyProgramEligible]="isLoyaltyProgramEligible$ | async"
        ></naoo-material-list-header>
      </div>
      <ng-container *ngIf="!itemException">
        <naoo-material-row-container
          class="base-material"
          [ngClass]="{
            'has-results': materials?.length
          }"
          [materialNumber]="
            materialWithSubstitutes.materialRowOptions.materialNumber
          "
          [context]="context"
          [listStyle]="listStyle"
          [isMobile]="isMobile"
          [isLast]="false"
          [displayGoPointsLogo]="false"
          [recommendationEngineName]="recommendationEngineName"
        ></naoo-material-row-container>
      </ng-container>
      <ng-container *ngIf="materials?.length; else empty">
        <naoo-material-row-container
          class="related-material"
          *ngFor="let i of materials; let isLast = last"
          [materialNumber]="i"
          [context]="context"
          [listStyle]="listStyle"
          [isMobile]="isMobile"
          [isLast]="isLast"
          [displayGoPointsLogo]="false"
          [recommendationEngineName]="recommendationEngineName"
        ></naoo-material-row-container>
      </ng-container>
    </div>
    <div class="buttons-container">
      <button (click)="closeModal()" class="done-button">
        {{ 'DONE' | translate }}
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="loading-container">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  </div>
</ng-template>

<ng-template #empty>
  <div class="empty-container"></div>
</ng-template>
