import { first, takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { naooAnimations } from '../../shared/animations/animations';
import { NaooConstants } from '../../shared/NaooConstants';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { EcommerceAnalyticsFacade } from '../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { CartPriceTotals } from '../../core/store/material-price/material-price.selectors';
import { MaterialPriceFacade } from '../../core/store/material-price/material-price.facade';

@Component({
  selector: 'naoo-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss'],
  animations: [naooAnimations.badgeIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartIconComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  badgeAnimationState: string | null;
  showBadge = false;
  estimatedTotalCost = 0;
  cartWCAGTitle = 'CART.TITLE';
  itemsInCart = 0;
  isCalculatingInitialTotalCost = true;
  isCartLoaded = false;

  @Input() wideLayout = false;
  @Input() isMobile = false;

  constructor(
    private router: Router,
    private cartFacade: CartFacade,
    private materialPriceFacade: MaterialPriceFacade,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cartFacade
      .getCartPriceTotals()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((cartPriceTotals: CartPriceTotals) => {
        this.estimatedTotalCost = cartPriceTotals.estimatedCost;
        this.animateCountChange(cartPriceTotals.cartCounts.totalQuantity);
        this.updateTotalValue(cartPriceTotals.cartCounts.totalQuantity);
        this.changeDetector.markForCheck();
      });

    this.cartFacade
      .getLoadedCart()
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe((cart) => {
        this.isCartLoaded = true;
        const materialNumbers = cart.materials.map((x) => x.materialNumber);
        if (materialNumbers.length === 0) {
          this.isCalculatingInitialTotalCost = false;
        } else {
          this.materialPriceFacade
            .getLoadedCombinedPrices(materialNumbers)
            .pipe(first(), takeUntil(this.destroyed$))
            .subscribe(() => {
              this.isCalculatingInitialTotalCost = false;
            });
        }
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  animationComplete() {
    setTimeout(() => {
      this.badgeAnimationState = null;
      this.showBadge = this.itemsInCart > 0;
      // check if this subject is still active
      // as this component may be destroyed before animation complete occurs
      // then calling detectChanges will cause an error
      if (!this.destroyed$.isStopped) {
        this.changeDetector.detectChanges();
      }
    });
  }

  navigateToCart() {
    const cartPath = NaooConstants.CART_PATH;
    if (!this.router.isActive(cartPath, true)) {
      this.ecommerceAnalyticsFacade.trackCheckoutCartEvent();
    }

    this.router.navigateByUrl(cartPath);
  }

  private animateCountChange(newTotal: number) {
    const isFirstValueZero = !this.itemsInCart && newTotal === 0;
    const countDidNotChange = isFirstValueZero || newTotal === this.itemsInCart;
    if (countDidNotChange) {
      // do not animate a non-change.
      return;
    }

    this.showBadge = true;

    if (this.itemsInCart === 0) {
      if (newTotal > 0) {
        // Total items has increased from 0
        this.badgeAnimationState = 'grow';
      }
    } else {
      if (newTotal === 0) {
        // Total items has decreased to 0
        this.badgeAnimationState = 'shrink';
      } else {
        // Total items has changed and is not 0
        this.badgeAnimationState = 'heartbeat';
      }
    }
  }

  private updateTotalValue(newTotal: number) {
    this.updateWcagTextForTotalAmount(newTotal);
    this.itemsInCart = newTotal;
  }

  private updateWcagTextForTotalAmount(newTotal: number) {
    if (newTotal === 1) {
      this.cartWCAGTitle = 'CART.TITLE_WCAG';
    } else if (newTotal > 1) {
      this.cartWCAGTitle = 'CART.TITLE_WCAG_PLURAL';
    } else {
      this.cartWCAGTitle = 'CART.TITLE';
    }
  }
}
