import {
  CartEntityState,
  cartLineAdapter,
  CartLineState,
  cartMaterialAdapter,
  CartMaterialState,
  CartState,
  CartUpdateType,
  initialCartLineState,
  initialCartMaterialState,
  initialCartState,
} from './cart.state';
import { CartActions, CartQuantityUpdate } from './cart.actions';
import { EntityState, Update } from '@ngrx/entity';
import { NaooConstants } from '../../../shared/NaooConstants';
import { filterNonEmptyCartLines } from '../../../shared/utilities/cart-material-utilities';
import {
  SplitOrder,
  SplitOrderType,
} from '../../services/cart-order/models/cart-order';
import {
  CartUpdateRequest,
  FulfillmentType,
} from '../../services/cart/models/cart-record';
import { createReducer, on } from '@ngrx/store';
import { fulfillmentDataChanged } from './cart.reducer-util';

export const cartReducer = createReducer(
  initialCartState,
  on(CartActions.blurCartQuantity, (state): CartState => state),
  on(
    CartActions.updateCart,
    (state, action): CartState => updateCart(state, action)
  ),
  on(
    CartActions.clearEarlyCutoffSplitOrder,
    (state): CartState => clearEarlyCutOffSplitOrder(state)
  ),
  on(
    CartActions.removeCouponFromCart,
    (state, action): CartState => removeCouponFromCart(state, action)
  ),
  on(
    CartActions.addCouponToCart,
    (state, action): CartState => addCouponToCart(state, action)
  ),
  on(
    CartActions.refreshCartSuccess,
    (state, action): CartState => refreshCartSuccess(state, action)
  ),
  on(CartActions.refreshCart, (): CartState => initialCartState),
  on(
    CartActions.blurCartQuantitySuccess,
    (state): CartState => blurCartQuantitySuccess(state)
  ),
  on(
    CartActions.focusCartQuantity,
    (state, action): CartState => focusCartQuantity(state, action)
  ),
  on(
    CartActions.clearDeletedCartMaterials,
    (state): CartState => clearDeletedCartMaterials(state)
  ),
  on(
    CartActions.deleteCartMaterial,
    (state, action): CartState => deleteCartMaterial(state, action)
  ),
  on(
    CartActions.updateCartQuantities,
    (state, action): CartState => updateCartQuantities(state, action)
  ),
  on(
    CartActions.clearCartMaterials,
    (state): CartState => clearCartMaterials(state)
  ),
  on(
    CartActions.updateCartMaterials,
    (state, action): CartState => updateCartMaterials(state, action)
  ),
  on(
    CartActions.updateDropShipSiteId,
    (state, action): CartState => updateDropShipSiteId(state, action)
  ),
  on(
    CartActions.resetSplitOrders,
    (state): CartState => clearSplitOrders(state)
  ),
  on(
    CartActions.updateSplitOrder,
    (state, action): CartState => updateSplitOrder(state, action)
  ),
  on(
    CartActions.updatePoNumber,
    (state, action): CartState => updatePoNumber(state, action)
  ),
  on(
    CartActions.updateRouteDateSuccess,
    (state, action): CartState => updateRouteDateSuccess(state, action)
  ),
  on(CartActions.syncOfflineCart, (state): CartState => syncOfflineCart(state)),
  on(
    CartActions.updateCartSuccess,
    (state, action): CartState => updateCartSuccess(state, action)
  ),
  on(
    CartActions.updateCartOnline,
    (state, action): CartState => updateCartOnline(state, action)
  ),
  on(
    CartActions.restoreCartMaterial,
    (state, action): CartState => restoreCartMaterial(state, action)
  ),
  on(CartActions.clearCriticalItemSectionFlag, (state) =>
    clearCriticalItemSectionFlag(state)
  )
);

function refreshCartSuccess(
  state: CartState,
  action: {
    cart: CartEntityState;
  }
): CartState {
  return {
    ...state,
    cart: action.cart,
    analyticsPreviousCart: action.cart,
  };
}

function updateCartOnline(
  state: CartState,
  action: {
    cartUpdateRequest: CartUpdateRequest;
    updateType: CartUpdateType;
    isFulfillmentDataChanged?: boolean;
  }
): CartState {
  return {
    ...state,
    queuedMaterialNumbers: state.queuedMaterialNumbers.filter(
      (queuedId) =>
        !(action.cartUpdateRequest.materials || [])
          .map((material) => material.materialNumber)
          .includes(queuedId)
    ),
    outstandingUpdateRequests: state.outstandingUpdateRequests + 1,
  };
}

function updateCartSuccess(
  state: CartState,
  action: {
    cart: CartEntityState;
  }
): CartState {
  const remainingRequests = state.outstandingUpdateRequests - 1;
  if (remainingRequests <= 0 && state.queuedMaterialNumbers.length === 0) {
    return {
      ...state,
      cart: action.cart,
      analyticsPreviousCart: action.cart,
      queuedMaterialNumbers: [],
      outstandingUpdateRequests: 0,
      hasPendingFulfillmentChange: false,
    };
  }
  return {
    ...state,
    analyticsPreviousCart: action.cart,
    outstandingUpdateRequests: remainingRequests,
    hasPendingFulfillmentChange: false,
  };
}

function syncOfflineCart(state: CartState): CartState {
  return {
    ...state,
    outstandingUpdateRequests: 0,
  };
}

function updateRouteDateSuccess(
  state: CartState,
  action: {
    routeDate: Date;
    customerArrivalDate?: Date;
  }
): CartState {
  return {
    ...state,
    cart: {
      ...state.cart,
      truckFulfillment: {
        routeDate: action.routeDate,
        customerArrivalDate: action.customerArrivalDate,
      },
    },
  };
}

function updatePoNumber(
  state: CartState,
  action: {
    poNumber: string;
  }
): CartState {
  return {
    ...state,
    cart: {
      ...state.cart,
      customerPoNumber: action.poNumber,
    },
  };
}

function updateSplitOrder(
  state: CartState,
  action: {
    splitOrder: SplitOrder;
  }
): CartState {
  const splitOrders = getSplitOrdersForUpdate(
    state.cart?.splitOrders,
    action.splitOrder
  );
  return {
    ...state,
    cart: {
      ...state.cart,
      splitOrders,
    },
  };
}

function updateDropShipSiteId(
  state: CartState,
  action: {
    siteId: string;
  }
): CartState {
  return {
    ...state,
    cart: {
      ...state.cart,
      splitOrders: undefined,
      selectedDropShipSiteId: action.siteId,
    },
  };
}

function clearSplitOrders(state: CartState): CartState {
  return {
    ...state,
    cart: {
      ...state.cart,
      splitOrders: undefined,
    },
  };
}

function clearEarlyCutOffSplitOrder(state: CartState): CartState {
  const nonEarlyCutOffOrders = state.cart?.splitOrders?.filter(
    (order) => order?.orderType !== SplitOrderType.EARLY_CUTOFF
  );

  return {
    ...state,
    cart: {
      ...state.cart,
      splitOrders: nonEarlyCutOffOrders,
    },
  };
}

function updateCartMaterials(
  state: CartState,
  action: {
    materialNumbersToUpdate: string[];
  }
): CartState {
  const uniqueCartMaterialNumbers = new Set(
    state.queuedMaterialNumbers.concat(action.materialNumbersToUpdate)
  );

  return {
    ...state,
    queuedMaterialNumbers: Array.from(uniqueCartMaterialNumbers),
  };
}

function updateCartQuantities(
  state: CartState,
  action: {
    cartQuantityUpdates: CartQuantityUpdate[];
  }
): CartState {
  const emptyCartMaterialNumbers: string[] = [];
  const updatedCartMaterials: CartMaterialState[] = [];
  action.cartQuantityUpdates.forEach(
    (cartQuantityUpdate: CartQuantityUpdate) => {
      const existingCartMaterialState =
        state.cart.materials.entities[cartQuantityUpdate.materialNumber];

      let updatedCartLines = getUpdatedCartLines(
        existingCartMaterialState,
        cartQuantityUpdate
      );

      if (existingCartMaterialState) {
        if (cartQuantityUpdate.merge) {
          updatedCartLines = merge(
            updatedCartLines,
            existingCartMaterialState.lines,
            cartQuantityUpdate
          );
        }
        const nonEmptyLines = filterNonEmptyCartLines(
          updatedCartLines.entities
        );

        if (
          nonEmptyLines.length === 0 &&
          !existingCartMaterialState.isDeleted
        ) {
          emptyCartMaterialNumbers.push(cartQuantityUpdate.materialNumber);
        }

        updatedCartMaterials.push({
          materialNumber: existingCartMaterialState.materialNumber,
          lines: cartLineAdapter.setAll(nonEmptyLines, initialCartLineState),
          isDeleted: false,
          isRestored: existingCartMaterialState.isRestored,
          isAddedFromCriticalItem: !!cartQuantityUpdate.isAddedFromCriticalItem,
          isAddedFromCriticalItemsSection: !!cartQuantityUpdate.isAddedFromCriticalItem,
          isAddedFromMaterialComparison: !!cartQuantityUpdate.isAddedFromMaterialComparison,
          isRollUpConversion: !!cartQuantityUpdate.isRollUpConversion,
          originTrackingId: existingCartMaterialState.originTrackingId,
        });
      } else {
        const nonEmptyLines = filterNonEmptyCartLines(
          updatedCartLines.entities
        );

        updatedCartMaterials.push({
          materialNumber: cartQuantityUpdate.materialNumber,
          lines: cartLineAdapter.setAll(nonEmptyLines, initialCartLineState),
          isDeleted: false,
          isRestored: false,
          isAddedFromCriticalItem: !!cartQuantityUpdate.isAddedFromCriticalItem,
          isAddedFromCriticalItemsSection: !!cartQuantityUpdate.isAddedFromCriticalItem,
          isAddedFromMaterialComparison: !!cartQuantityUpdate.isAddedFromMaterialComparison,
          isRollUpConversion: !!cartQuantityUpdate.isRollUpConversion,
          originTrackingId: null,
        });
      }
    }
  );

  const upsertedCartMaterials = cartMaterialAdapter.upsertMany(
    updatedCartMaterials,
    state.cart.materials
  );

  return {
    ...state,
    cart: {
      ...state.cart,
      materials: cartMaterialAdapter.removeMany(
        emptyCartMaterialNumbers,
        upsertedCartMaterials
      ),
    },
  };
}

function deleteCartMaterial(
  state: CartState,
  action: {
    materialNumber: string;
  }
): CartState {
  return {
    ...state,
    cart: {
      ...state.cart,
      materials: cartMaterialAdapter.updateOne(
        {
          id: action.materialNumber,
          changes: {
            isDeleted: true,
            isRestored: false,
          },
        },
        state.cart.materials
      ),
    },
  };
}

function restoreCartMaterial(
  state: CartState,
  action: {
    materialNumber: string;
  }
): CartState {
  return {
    ...state,
    cart: {
      ...state.cart,
      materials: cartMaterialAdapter.updateOne(
        {
          id: action.materialNumber,
          changes: {
            isDeleted: false,
            isRestored: true,
          },
        },
        state.cart.materials
      ),
    },
  };
}

function clearCriticalItemSectionFlag(state: CartState): CartState {
  const cartMaterials: Update<CartMaterialState>[] = state.cart.materials.ids.map(
    (id) => {
      return {
        id: id.toString(),
        changes: {
          isAddedFromCriticalItemsSection: false,
        },
      };
    }
  );

  return {
    ...state,
    cart: {
      ...state.cart,
      materials: cartMaterialAdapter.updateMany(
        cartMaterials,
        state.cart.materials
      ),
    },
  };
}

function clearCartMaterials(state: CartState): CartState {
  const uniqueCartMaterialNumbers = new Set(
    state.queuedMaterialNumbers.concat(state.cart.materials.ids as string[])
  );
  return {
    ...state,
    // Need to add all current material numbers to queue so we know which items we're deleting.
    queuedMaterialNumbers: Array.from(uniqueCartMaterialNumbers),
    cart: {
      ...state.cart,
      // Set to initial material state to update the UI immediately.
      materials: initialCartMaterialState,
    },
  };
}

function clearDeletedCartMaterials(state: CartState): CartState {
  if (!state.cart?.materials) {
    return {
      ...state,
    };
  }

  const deletedMaterialNumbers = Object.values(state.cart.materials.entities)
    .filter((cartMaterial) => cartMaterial.isDeleted)
    .map((cartMaterial) => cartMaterial.materialNumber);

  return {
    ...state,
    cart: {
      ...state.cart,
      materials: cartMaterialAdapter.removeMany(
        deletedMaterialNumbers,
        state.cart.materials
      ),
    },
  };
}

function focusCartQuantity(
  state: CartState,
  action: {
    materialNumber: string;
  }
): CartState {
  const existingCartMaterial =
    state.cart?.materials.entities[action.materialNumber];

  return {
    ...state,
    focusedMaterial: {
      materialNumber: action.materialNumber,
      lines: existingCartMaterial
        ? existingCartMaterial.lines
        : initialCartLineState,
      isRollUpConversion: existingCartMaterial
        ? existingCartMaterial.isRollUpConversion
        : false,
    },
  };
}

function blurCartQuantitySuccess(state: CartState): CartState {
  return {
    ...state,
    focusedMaterial: undefined,
  };
}

function getUpdatedCartLines(
  existingCartMaterialState: CartMaterialState,
  cartQuantityUpdate: CartQuantityUpdate
): EntityState<CartLineState> {
  if (!existingCartMaterialState || existingCartMaterialState.isDeleted) {
    return cartLineAdapter.setAll(
      cartQuantityUpdate.lines,
      initialCartLineState
    );
  } else {
    return cartLineAdapter.upsertMany(
      cartQuantityUpdate.lines,
      existingCartMaterialState.lines
    );
  }
}

function merge(
  lines: EntityState<CartLineState>,
  previousLines: EntityState<CartLineState>,
  quantityUpdate: CartQuantityUpdate
): EntityState<CartLineState> {
  quantityUpdate.lines.forEach((line) => {
    const previousQuantity = !previousLines.entities[line.uom]
      ? 0
      : previousLines.entities[line.uom].quantity;
    lines = cartLineAdapter.upsertOne(
      {
        uom: line.uom,
        quantity: line.quantity + previousQuantity,
      },
      lines
    );
  });

  return lines;
}

function addCouponToCart(
  state: CartState,
  action: { couponCodeToAdd: string }
) {
  const existingCouponCodes: string[] = state.cart.couponCodes.map((coupon) =>
    coupon.toUpperCase()
  );
  const couponCodeToAdd: string = action.couponCodeToAdd.toUpperCase();

  if (
    !existingCouponCodes.includes(couponCodeToAdd) &&
    existingCouponCodes.length < NaooConstants.maximumCartCoupons
  ) {
    existingCouponCodes.push(couponCodeToAdd);
  }

  return {
    ...state,
    cart: {
      ...state.cart,
      couponCodes: existingCouponCodes,
    },
  };
}

function removeCouponFromCart(
  state: CartState,
  action: {
    couponCodeToRemove: string;
  }
) {
  const existingCouponCodes = state.cart.couponCodes.map((coupon) =>
    coupon.toUpperCase()
  );

  return {
    ...state,
    cart: {
      ...state.cart,
      couponCodes: existingCouponCodes.filter(
        (coupon) => coupon !== action.couponCodeToRemove.toUpperCase()
      ),
    },
  };
}

function updateCart(
  state: CartState,
  action: {
    cartUpdateRequest: CartUpdateRequest;
    updateType: CartUpdateType;
  }
): CartState {
  const updateRequest = action.cartUpdateRequest;
  if (fulfillmentDataChanged(updateRequest, state.cart, action.updateType)) {
    return updateFulfillmentType(state, updateRequest.fulfillmentType);
  }

  return state;
}

function getSplitOrdersForUpdate(
  existingSplitOrders: SplitOrder[],
  actionSplitOrder: SplitOrder
): SplitOrder[] {
  if (!existingSplitOrders?.length) {
    return [actionSplitOrder];
  }
  const isSplitEarlyCutoff = (splitOrder: SplitOrder) =>
    SplitOrderType.EARLY_CUTOFF === splitOrder?.orderType;

  const isSplitMatchingShipmentId = (splitOrder: SplitOrder) =>
    !!splitOrder?.carrierFulfillment?.shipmentId &&
    splitOrder?.carrierFulfillment?.shipmentId ===
      actionSplitOrder?.carrierFulfillment?.shipmentId;

  const anyExistingEarlyCutoff = existingSplitOrders.some(isSplitEarlyCutoff);

  const anyExistingMatchingShipmentId = existingSplitOrders.some(
    isSplitMatchingShipmentId
  );

  const isActionEarlyCutoff = isSplitEarlyCutoff(actionSplitOrder);

  if (
    (isActionEarlyCutoff && anyExistingEarlyCutoff) ||
    anyExistingMatchingShipmentId
  ) {
    return existingSplitOrders.map((splitOrder) =>
      isSplitMatchingShipmentId(splitOrder) ||
      (isSplitEarlyCutoff(splitOrder) && isActionEarlyCutoff)
        ? actionSplitOrder
        : splitOrder
    );
  } else {
    return [...existingSplitOrders, actionSplitOrder];
  }
}

function updateFulfillmentType(
  state: CartState,
  fulfillmentType: FulfillmentType
) {
  const shouldClearCoupons: boolean = FulfillmentType.TRUCK !== fulfillmentType;
  return {
    ...state,
    cart: {
      ...state.cart,
      fulfillmentType,
      couponCodes: shouldClearCoupons ? [] : state.cart.couponCodes,
    },
    hasPendingFulfillmentChange: true,
  };
}
