import { MultipleCartsActions } from './multiple-carts.actions';
import {
  initialMultipleCartsState,
  MultipleCartsState,
} from './multiple-carts.state';
import { createReducer, on } from '@ngrx/store';
import { MultipleCartsRecord } from '../../services/multiple-carts/models/multiple-carts-record';

export const multipleCartsReducer = createReducer(
  initialMultipleCartsState,
  on(
    MultipleCartsActions.refreshSavedCarts,
    (): MultipleCartsState => initialMultipleCartsState
  ),
  on(
    MultipleCartsActions.getSavedCarts,
    (state): MultipleCartsState => getSavedCarts(state)
  ),
  on(
    MultipleCartsActions.getSavedCartsSuccess,
    (state, action): MultipleCartsState => getSavedCartsSuccess(state, action)
  ),
  on(
    MultipleCartsActions.getSavedCartsError,
    (state): MultipleCartsState => getSavedCartsError(state)
  )
);

function getSavedCarts(state: MultipleCartsState): MultipleCartsState {
  return {
    ...state,
    isLoading: true,
  };
}

function getSavedCartsSuccess(
  state: MultipleCartsState,
  action: {
    multipleCartsRecord: MultipleCartsRecord;
    promptForApprover?: boolean;
  }
) {
  return {
    isLoading: false,
    carts: action.multipleCartsRecord,
  };
}

function getSavedCartsError(state: MultipleCartsState) {
  return {
    ...state,
    isLoading: false,
  };
}
