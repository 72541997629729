import {
  initialMygfsNutritionState,
  mygfsNutritionAdapter,
  MygfsNutritionRecordState,
  MygfsNutritionRecordStatus,
  MygfsNutritionState,
} from './mygfs-nutrition.state';
import { MygfsNutritionActions } from './mygfs-nutrition.actions';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { NutritionLabel } from './models/mygfs-nutrition';

export const mygfsNutritionReducer = createReducer(
  initialMygfsNutritionState,
  on(
    MygfsNutritionActions.loadMygfsNutrition,
    (state, action): MygfsNutritionState =>
      loadMygfsNutrition(state, action.materialNumbers)
  ),
  on(
    MygfsNutritionActions.getMygfsNutrition,
    (state, action): MygfsNutritionState => getMygfsNutrition(state, action)
  ),
  on(
    MygfsNutritionActions.getMygfsNutritionSuccess,
    (state, action): MygfsNutritionState =>
      getMygfsNutritionSuccess(state, action)
  ),
  on(
    MygfsNutritionActions.getMygfsNutritionError,
    (state, action): MygfsNutritionState =>
      getMygfsNutritionError(state, action)
  ),
  on(
    MygfsNutritionActions.clearMygfsNutrition,
    (): MygfsNutritionState => initialMygfsNutritionState
  )
);

export function loadMygfsNutrition(
  state: MygfsNutritionState,
  materialNumbers: string[]
): MygfsNutritionState {
  const upsertedEntities = materialNumbers
    .filter(
      (id) =>
        (!!id && !state.nutritionRecords.entities[id]) ||
        MygfsNutritionRecordStatus.Error ===
          state.nutritionRecords.entities[id]?.status
    )
    .map(
      (id) =>
        <MygfsNutritionRecordState>{
          materialNumber: id,
          status: MygfsNutritionRecordStatus.Queued,
          record: undefined,
        }
    );

  return {
    nutritionRecords: mygfsNutritionAdapter.upsertMany(
      upsertedEntities,
      state.nutritionRecords
    ),
  };
}

export function getMygfsNutrition(
  state: MygfsNutritionState,
  action: { materialNumber: string }
): MygfsNutritionState {
  if (
    !!state.nutritionRecords.entities[action.materialNumber] &&
    state.nutritionRecords.entities[action.materialNumber]?.status !==
      MygfsNutritionRecordStatus.Queued
  ) {
    return state;
  }

  const newRecordState: MygfsNutritionRecordState = {
    materialNumber: action.materialNumber,
    status: MygfsNutritionRecordStatus.Requested,
    record: undefined,
  };

  return {
    nutritionRecords: mygfsNutritionAdapter.upsertOne(
      newRecordState,
      state.nutritionRecords
    ),
  };
}

export function getMygfsNutritionSuccess(
  state: MygfsNutritionState,
  action: {
    materialNumber: string;
    nutritionLabel: NutritionLabel;
  }
): MygfsNutritionState {
  const recordUpate: Update<MygfsNutritionRecordState> = {
    id: action.materialNumber,
    changes: {
      status: MygfsNutritionRecordStatus.Success,
      record: action.nutritionLabel,
    },
  };

  return {
    nutritionRecords: mygfsNutritionAdapter.updateOne(
      recordUpate,
      state.nutritionRecords
    ),
  };
}

export function getMygfsNutritionError(
  state: MygfsNutritionState,
  action: { materialNumber: string }
): MygfsNutritionState {
  const recordUpdate: Update<MygfsNutritionRecordState> = {
    id: action.materialNumber,
    changes: {
      status: MygfsNutritionRecordStatus.Error,
      record: undefined,
    },
  };

  return {
    nutritionRecords: mygfsNutritionAdapter.updateOne(
      recordUpdate,
      state.nutritionRecords
    ),
  };
}
