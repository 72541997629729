import { initialSessionState, SessionState } from './session.state';
import { SessionActions } from './session.actions';
import { createReducer, on } from '@ngrx/store';

export const sessionReducer = createReducer(
  initialSessionState,
  on(
    SessionActions.getSessionSuccess,
    SessionActions.updateSessionSuccess,
    (state, action): SessionState => ({
      ...state,
      sessionRecord: action.sessionRecord,
    })
  ),
  on(
    SessionActions.refreshCustomerDataSuccess,
    (state, action): SessionState => ({
      ...state,
      previousSelectedCompositeCustomerId: action.compositeCustomerId,
      isCustomerUpdating: false,
    })
  ),
  on(
    SessionActions.updateSelectedCustomer,
    (state, action): SessionState => ({
      ...state,
      isCustomerUpdating:
        action.compositeCustomerId !==
        state.previousSelectedCompositeCustomerId,
    })
  ),
  on(
    SessionActions.updateSession,
    (state, action): SessionState => ({
      ...state,
      sessionRecord: {
        ...state.sessionRecord,
        language: action.updateSessionRequest.language,
      },
    })
  ),
  on(
    SessionActions.updateSelectedLanguage,
    SessionActions.getSession,
    SessionActions.refreshCustomerData,
    (state): SessionState => state
  )
);
