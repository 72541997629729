import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date/date.service';

@Pipe({
  name: 'naooTimeRange',
  pure: false,
})
export class NaooTimeRangePipe implements PipeTransform {
  constructor(private dateService: DateService) {}

  transform(
    startTime: string | undefined,
    untilTime: string | undefined,
    userTimeZone: string
  ): string | undefined {
    return this.dateService.getTimeRange(startTime, untilTime, userTimeZone);
  }
}
