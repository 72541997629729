import { createActionGroup, emptyProps } from '@ngrx/store';
import {
  CustomerPreferencesRecord,
  ExportDetailsRecord,
  UpdateCustomerPreference,
} from '../../services/customer-preferences/models/customer-preferences-record';
import { Language } from '../../services/session/models/session-record';

export const CustomerPreferencesActions = createActionGroup({
  source: 'Customer Preferences',
  events: {
    'Get Customer Preferences': emptyProps(),
    'Get Customer Preferences Success': (
      customerPreferences: CustomerPreferencesRecord
    ) => ({ customerPreferences }),
    'Get Customer Preferences Failure': emptyProps(),
    'Update Customer Preferences Success': (
      customerPreferenceRecord: CustomerPreferencesRecord
    ) => ({ customerPreferenceRecord }),
    'Order Confirmation Email Opt Out': (
      orderConfirmationEmailNotificationsOptOut: string
    ) => ({ orderConfirmationEmailNotificationsOptOut }),
    'Order Confirmation Email Opt In': (email: string, language: Language) => ({
      email,
      language,
    }),
    'Refresh Customer Preferences': emptyProps(),
    'Update Export Details Preferences': (
      exportDetail: ExportDetailsRecord
    ) => ({ exportDetail }),
    'Update Customer Preference': (
      updatedCustomerPreferences: UpdateCustomerPreference
    ) => ({ updatedCustomerPreferences }),
  },
});
