import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { CONTENT_SCROLL } from '../scrollable-content/scrollable-content.service';

export enum Move {
  Prev,
  Next,
}

export enum QtyBoxType {
  QTY = 'quantity-box',
  PAR = 'quantity-box-decimal',
}

@Injectable({ providedIn: 'root' })
export class ListScrollService {
  static readonly DATA_QTY_ID_ATTR = 'data-qty-id';
  categorySelectedSource = new Subject<string>();
  scrollToCategory$ = this.categorySelectedSource.asObservable();
  private _pageId: string;

  scrollIndexSource = new BehaviorSubject<number>(0);

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element
  ) {}

  getScrollIndex(): Observable<number> {
    return this.scrollIndexSource.asObservable();
  }

  get pageId(): string {
    return this._pageId;
  }

  selectCategory(categoryName: string) {
    this.categorySelectedSource.next(categoryName);
  }

  goToCategory(categoryName: string) {
    const element = this._document.getElementById(categoryName);
    if (element && !this.checkVisible(element)) {
      element.scrollIntoView(true);
    }
  }

  moveToBox(moveDirection: Move, type: QtyBoxType) {
    // get active element (focused)
    const activeInputBox = this._document.activeElement;
    // get references to all qty boxes currently rendered on the page

    const boxes: Element[] = Array.from(
      this._document.getElementsByClassName(type)
    );

    // find the index of the focused qty box in the references of qty boxes on the page
    const activeIndex = boxes.findIndex(
      (qtyBox: Element) =>
        qtyBox.getAttribute(ListScrollService.DATA_QTY_ID_ATTR) ===
        activeInputBox.getAttribute(ListScrollService.DATA_QTY_ID_ATTR)
    );

    const indexToFocus =
      moveDirection === Move.Next ? activeIndex + 1 : activeIndex - 1;
    // if there's a previous or next qty box, focus on it
    this.smoothScroll(boxes[indexToFocus] as HTMLInputElement, moveDirection);
  }

  smoothScroll(elm: HTMLElement, moveDirection: Move) {
    const shouldScrollIntoView = moveDirection !== Move.Next;
    if (elm) {
      if (!this.checkVisible(elm)) {
        elm.scrollIntoView(shouldScrollIntoView);
      }
      elm.focus();
    }
  }

  scrollTo(x: number, y: number): void {
    this.parentScrollElement.scrollTo(x, y);
  }

  scrollBy(x: number, y: number): void {
    this.parentScrollElement.scrollBy(x, y);
  }

  scrollByOptions(options: ScrollToOptions) {
    this.parentScrollElement.scrollBy(options);
  }

  scrollDownSmoothly(numOfPixels: number) {
    this.scrollByOptions({
      top: numOfPixels,
      behavior: 'smooth',
    });
  }

  scrollSmoothlyToCenter(element: HTMLElement) {
    this.parentScrollElement.scrollTo({
      top: element.offsetTop - this._document.documentElement.clientHeight / 2,
      behavior: 'smooth',
    });
  }

  scrollToTop(shouldSmoothScroll: boolean = false) {
    this.scrollByOptions({
      top: -this.parentScrollElement.scrollTop,
      behavior: shouldSmoothScroll ? 'smooth' : 'auto',
    });
  }

  scrollSmoothlyToBottom(): void {
    this.parentScrollElement.scrollTo({
      top: this._document.body.scrollHeight * 2,
      behavior: 'smooth',
    });
  }

  private checkVisible(elm: HTMLElement): boolean {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0 || rect.top < 25);
  }
}
