import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MaterialAttribute } from '../../shared/models/material-additional-info';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { Language } from 'src/app/core/services/session/models/session-record';

export interface ProductAttributeDefinition {
  description: {
    en: string;
    fr: string;
  };
}

@Component({
  selector: 'naoo-product-attributes-modal',
  templateUrl: './product-attributes-modal.component.html',
  styleUrls: ['./product-attributes-modal.component.scss'],
})
export class ProductAttributesModalComponent
  implements OnInit, OnDestroy, AfterViewInit {
  materialAttributes: MaterialAttribute[];
  attributes: Map<string, ProductAttributeDefinition>;
  currentLanguage: Language;
  private isMobile: boolean;
  private HEADER_HEIGHT = 78;
  private MOBILE_HEIGHT = 498;
  private DESKTOP_HEIGHT = 698;
  @ViewChild('modalHeader', { static: true }) modalHeader: ElementRef;
  @ViewChild('modalContent', { static: true }) modalContent: ElementRef;

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      materialAttributes: MaterialAttribute[];
    },
    private dialogRef: MatDialogRef<ProductAttributesModalComponent>,
    private httpClient: HttpClient,
    private localizationService: LocalizationService,
    private deviceIdentifierService: DeviceIdentifierService
  ) {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
      });
  }

  ngOnInit() {
    this.materialAttributes = this.data.materialAttributes;

    this.currentLanguage = this.localizationService.currentLanguage;

    this.httpClient
      .get('assets/data/product-attributes.json')
      .subscribe(
        (productAttributes: Map<string, ProductAttributeDefinition>) => {
          this.attributes = productAttributes;
        }
      );
  }

  ngAfterViewInit() {
    const modalHeight = this.isMobile
      ? this.MOBILE_HEIGHT
      : this.DESKTOP_HEIGHT;
    const headerHeight =
      this.modalHeader.nativeElement.clientHeight || this.HEADER_HEIGHT;
    const contentMaxHeight = modalHeight - headerHeight;
    this.modalContent.nativeElement.style.maxHeight = contentMaxHeight + 'px';
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  closeModal() {
    this.dialogRef.close();
  }

  trackByMaterialAttribute(
    index: number,
    materialAttribute: MaterialAttribute
  ): string {
    return materialAttribute.value;
  }
}
