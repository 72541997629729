<div class="attribute-container">
  <ng-container
    *ngFor="let attribute of attributes"
    [ngTemplateOutletContext]="{
      attribute,
    }"
    [ngTemplateOutlet]="section"
  >
  </ng-container>
</div>

<ng-template #bodyForDescription>
  <ng-container *ngFor="let material of materialNumbers">
    <div class="section-wrapper">
      <ng-container *ngIf="shouldRenderDescription(material); else placeholder">
        <div class="section-content">
          <span
            class="selling-paragraph"
            *ngFor="let paragraph of getDetails(material).sellingParagraphs"
          >
            {{ paragraph }}
            <br />
          </span>
          <ul>
            <li
              class="selling-bullet"
              *ngFor="let bullet of getDetails(material).sellingBulletPoints"
            >
              {{ bullet }}
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #bodyForPricePerWeight>
  <ng-container *ngFor="let material of materialNumbers">
    <div class="section-wrapper">
      <ng-container
        *ngIf="shouldRenderPricePerWeight(material); else placeholder"
      >
        <div class="section-content">
          {{
            getDetails(material).price
              | naooprice: getDetails(material).weightUom
          }}
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #bodyForIngredients>
  <ng-container *ngFor="let material of materialNumbers">
    <div class="section-wrapper">
      <ng-container *ngIf="getDetails(material)?.ingredients; else placeholder">
        <div class="section-content">
          {{ getDetails(material).ingredients }}
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #bodyForPackagingAndStorage>
  <ng-container *ngFor="let material of materialNumbers">
    <div class="section-wrapper">
      <ng-container *ngIf="getDetails(material); else placeholder">
        <div class="section-content">
          <div class="weight" *ngIf="getDetails(material)?.baseUomWeight">
            <div class="packaging-field-header">
              {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.WEIGHT' | translate }}
            </div>
            <span>{{ getDetails(material).baseUomWeight | naooweight }}</span>
          </div>
          <div class="dimensions" *ngIf="getDimensions(material)">
            <div class="packaging-field-header">
              {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS' | translate }}
            </div>
            <span>
              {{
                'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_IN'
                  | translate
                    : { dimension: getDimensions(material).dimensionsIn }
              }}
              <br />
              {{
                'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_CM'
                  | translate
                    : { dimension: getDimensions(material).dimensionsCm }
              }}
            </span>
          </div>
          <div class="packaging" *ngIf="getDetails(material)?.packagingStorage">
            <div class="packaging-field-header">
              {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.PACKAGING' | translate }}
            </div>
            <span>{{ getDetails(material).packagingStorage }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #bodyForBrand>
  <ng-container *ngFor="let material of materialNumbers">
    <div class="section-wrapper">
      <ng-container *ngIf="getDetails(material)?.brand; else placeholder">
        <div class="section-content">
          {{ getDetails(material).brand }}
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #bodyForAttributes>
  <ng-container *ngFor="let material of materialNumbers">
    <div class="section-wrapper">
      <ng-container
        *ngIf="getDetails(material)?.attributes?.length; else placeholder"
      >
        <div class="section-content">
          <ul>
            <li *ngFor="let a of getDetails(material).attributes">
              {{ a }}
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #bodyForNutritionFacts>
  <ng-container *ngFor="let material of materialNumbers">
    <div class="section-wrapper">
      <ng-container
        *ngIf="shouldRenderNutritionInfo(material); else placeholder"
      >
        <div class="section-content">
          <naoo-nutrition-details
            *ngIf="this.getDetails(material)?.nutritionalInfo"
            [nutritionDetails]="getDetails(material).nutritionalInfo"
            [hideBorder]="true"
          >
          </naoo-nutrition-details>
          <naoo-mygfs-nutrition-facts-container
            *ngIf="this.getDetails(material)?.nutritionLabel"
            [materialNumber]="material"
            [nutritionLabel]="getDetails(material).nutritionLabel"
          >
          </naoo-mygfs-nutrition-facts-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #bodyForAllergens>
  <ng-container *ngFor="let material of materialNumbers">
    <div class="section-wrapper">
      <ng-container
        *ngIf="getDetails(material)?.allergenInfo; else placeholder"
      >
        <div class="section-content">
          <naoo-allergen-details
            [allergenInfo]="getDetails(material)?.allergenInfo"
            [hideBorder]="true"
          >
          </naoo-allergen-details>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #bodyForServingSuggestions>
  <ng-container *ngFor="let material of materialNumbers">
    <div class="section-wrapper">
      <ng-container
        *ngIf="getDetails(material)?.servingSuggestions; else placeholder"
      >
        <div class="section-content">
          {{ getDetails(material).servingSuggestions }}
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #section let-attribute="attribute">
  <ng-container *ngIf="shouldRenderExpansionPanel(attribute)">
    <naoo-generic-expansion-panel
      [headerTitle]="attribute"
      [startExpanded]="shouldStartExpanded(attribute)"
    >
      <div
        class="body-content"
        [ngClass]="getClassForComparisonAttribute(attribute)"
      >
        <ng-container [ngSwitch]="attribute">
          <ng-container
            *ngSwitchCase="ComparisonAttribute.Description"
            [ngTemplateOutlet]="bodyForDescription"
          ></ng-container>
          <ng-container
            *ngSwitchCase="ComparisonAttribute.PricePerWeight"
            [ngTemplateOutlet]="bodyForPricePerWeight"
          ></ng-container>
          <ng-container
            *ngSwitchCase="ComparisonAttribute.Ingredients"
            [ngTemplateOutlet]="bodyForIngredients"
          ></ng-container>
          <ng-container
            *ngSwitchCase="ComparisonAttribute.PackagingAndStorage"
            [ngTemplateOutlet]="bodyForPackagingAndStorage"
          ></ng-container>
          <ng-container
            *ngSwitchCase="ComparisonAttribute.Brand"
            [ngTemplateOutlet]="bodyForBrand"
          ></ng-container>
          <ng-container
            *ngSwitchCase="ComparisonAttribute.Attributes"
            [ngTemplateOutlet]="bodyForAttributes"
          ></ng-container>
          <ng-container
            *ngSwitchCase="ComparisonAttribute.NutritionFacts"
            [ngTemplateOutlet]="bodyForNutritionFacts"
          ></ng-container>
          <ng-container
            *ngSwitchCase="ComparisonAttribute.Allergens"
            [ngTemplateOutlet]="bodyForAllergens"
          ></ng-container>
          <ng-container
            *ngSwitchCase="ComparisonAttribute.ServingSuggestions"
            [ngTemplateOutlet]="bodyForServingSuggestions"
          ></ng-container>
        </ng-container>
      </div>
    </naoo-generic-expansion-panel>
    <br />
  </ng-container>
</ng-template>

<ng-template #placeholder>
  <div class="section-content">&ndash;</div>
</ng-template>
