import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  mygfsNutritionAdapter,
  MygfsNutritionRecordState,
  MygfsNutritionState,
} from './mygfs-nutrition.state';
import { Dictionary } from '@ngrx/entity';

const selectMygfsNutritionFeature = createFeatureSelector<MygfsNutritionState>(
  'mygfsNutrition'
);

export const selectAllMygfsNutritionRecords = createSelector(
  selectMygfsNutritionFeature,
  (state) =>
    mygfsNutritionAdapter.getSelectors().selectEntities(state.nutritionRecords)
);

export const selectMygfsNutritionRecord = (materialNumber: string) =>
  createSelector(
    selectMygfsNutritionFeature,
    (records) => records.nutritionRecords.entities[materialNumber]
  );

export const selectLoadedMygfsNutrition = (materialNumber: string) =>
  createSelector(
    selectMygfsNutritionRecord(materialNumber),
    (nutritionRecord) => nutritionRecord?.record
  );

export const selectMygfsNutritionRecordDictionary = (
  materialNumbers: string[]
) =>
  createSelector(selectAllMygfsNutritionRecords, (records) => {
    const dictionary: Dictionary<MygfsNutritionRecordState> = {};
    materialNumbers.forEach((materialNumber: string | number) => {
      dictionary[materialNumber] = records[materialNumber];
    });
    return dictionary;
  });
