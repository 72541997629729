import {
  animate,
  AnimationTriggerMetadata,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

// Disabling no-duplicate-string because while the strings are duplicate, they are not meant to be shared.
/* eslint-disable sonarjs/no-duplicate-string */
export const naooAnimations: {
  readonly displayWarning: AnimationTriggerMetadata;
  readonly badgeIconV1: AnimationTriggerMetadata;
  readonly badgeIcon: AnimationTriggerMetadata;
  readonly submitFooter: AnimationTriggerMetadata;
  readonly moveRow: AnimationTriggerMetadata;
  readonly highlightRow: AnimationTriggerMetadata;
  readonly highlightCategory: AnimationTriggerMetadata;
  readonly rotate: AnimationTriggerMetadata;
  readonly fadeAndSlide: AnimationTriggerMetadata;
  readonly showHide: AnimationTriggerMetadata;
} = {
  displayWarning: trigger('displayWarning', [
    state('animateInFromTop', style({})),
    transition('* => animateInFromTop', [
      animate(
        '250ms ease-in',
        keyframes([
          style({
            opacity: 0,
            transform: 'translateY(-100%)',
            offset: 0,
          }),
          style({
            opacity: 1,
            transform: 'translateY(0%)',
            offset: 1.0,
          }),
        ])
      ),
    ]),
    state('animateOutFromBottom', style({})),
    transition('* => animateOutFromBottom', [
      animate(
        '250ms ease-in',
        keyframes([
          style({
            opacity: 1,
            transform: 'translateY(0%)',
            offset: 0,
          }),
          style({
            opacity: 0,
            transform: 'translateY(-100%)',
            offset: 1.0,
          }),
        ])
      ),
    ]),
  ]),
  badgeIconV1: trigger('badgeIconV1', [
    state('grow', style({})),
    transition('* => grow', [
      animate(
        '250ms ease-in',
        keyframes([
          style({
            opacity: 0,
            transform: 'scale(0) translateX(48%)',
            offset: 0,
          }),
          style({
            opacity: 1,
            transform: 'scale(1) translateX(48%)',
            offset: 1.0,
          }),
        ])
      ),
    ]),
    state('heartbeat', style({})),
    transition('* => heartbeat', [
      animate(
        400,
        keyframes([
          style({
            transform: 'scale(1) translateX(48%)',
            offset: 0,
          }),
          style({
            transform: 'scale(1.3) translateX(48%)',
            offset: 0.5,
          }),
          style({
            transform: 'scale(1) translateX(48%)',
            offset: 1.0,
          }),
        ])
      ),
    ]),
    state('shrink', style({})),
    transition('* => shrink', [
      animate(
        '200ms ease-out',
        keyframes([
          style({
            opacity: 1,
            transform: 'scale(1) translateX(48%)',
            offset: 0,
          }),
          style({
            opacity: 0,
            transform: 'scale(0) translateX(48%)',
            offset: 1.0,
          }),
        ])
      ),
    ]),
  ]),
  badgeIcon: trigger('badgeIcon', [
    state('grow', style({})),
    transition('* => grow', [
      animate(
        '250ms ease-in',
        keyframes([
          style({
            opacity: 0,
            transform: 'scale(0)',
            offset: 0,
          }),
          style({
            opacity: 1,
            transform: 'scale(1)',
            offset: 1.0,
          }),
        ])
      ),
    ]),
    state('heartbeat', style({})),
    transition('* => heartbeat', [
      animate(
        400,
        keyframes([
          style({
            transform: 'scale(1)',
            offset: 0,
          }),
          style({
            transform: 'scale(1.3)',
            offset: 0.5,
          }),
          style({
            transform: 'scale(1)',
            offset: 1.0,
          }),
        ])
      ),
    ]),
    state('shrink', style({})),
    transition('* => shrink', [
      animate(
        '200ms ease-out',
        keyframes([
          style({
            opacity: 1,
            transform: 'scale(1)',
            offset: 0,
          }),
          style({
            opacity: 0,
            transform: 'scale(0)',
            offset: 1.0,
          }),
        ])
      ),
    ]),
  ]),
  submitFooter: trigger('submitFooter', [
    state(
      'fadeIn',
      style({
        opacity: 1,
        'pointer-events': 'auto',
      })
    ),
    state(
      'fadeOut',
      style({
        opacity: 0,
        'pointer-events': 'none',
      })
    ),
    transition('*=>*', animate('250ms')),
  ]),
  moveRow: trigger('moveRow', [
    state('moveDown', style({})),
    transition('* => moveDown', [
      animate(
        '300ms ease-out',
        keyframes([
          style({
            transform: 'translateY(-100%)',
          }),
          style({
            transform: 'translateY(0%)',
          }),
        ])
      ),
    ]),
    state('moveUp', style({})),
    transition('* => moveUp', [
      animate(
        '300ms ease-out',
        keyframes([
          style({
            transform: 'translateY(100%)',
          }),
          style({
            transform: 'translateY(0%)',
          }),
        ])
      ),
    ]),
  ]),
  highlightRow: trigger('highlightRow', [
    transition(
      '* => highlighted',
      animate(
        '1000ms cubic-bezier(.28,.96,.61,.04)',
        keyframes([
          style({
            backgroundColor: 'rgba(43, 90, 135, 0)',
            borderColor: 'rgba(43, 90, 135, 0)',
            borderWidth: '1px',
            borderStyle: 'solid',
            zIndex: 1,
            opacity: 1,
          }),
          style({
            backgroundColor: 'rgba(43, 90, 135, 0.2)',
            borderColor: 'rgba(43, 90, 135, 1)',
            borderWidth: '1px',
            borderStyle: 'solid',
            zIndex: 1,
            opacity: 1,
          }),
          style({
            backgroundColor: 'rgba(43, 90, 135, 0)',
            borderColor: 'rgba(43, 90, 135, 0)',
            borderWidth: '1px',
            borderStyle: 'solid',
            zIndex: 1,
            opacity: 1,
          }),
        ])
      )
    ),
  ]),
  highlightCategory: trigger('highlightCategory', [
    transition(
      '* => highlighted',
      animate(
        '1500ms cubic-bezier(0,1,1,0)',
        keyframes([
          style({
            boxShadow: '0 0 8px 4px rgba(43, 90, 135, 0)',
            opacity: 1,
          }),
          style({
            boxShadow: '0 0 8px 4px rgba(43, 90, 135, 0.5)',
            opacity: 1,
          }),
          style({
            boxShadow: '0 0 8px 4px rgba(43, 90, 135, 0)',
            opacity: 1,
          }),
        ])
      )
    ),
  ]),
  rotate: trigger('rotate', [
    state(
      '180',
      style({
        transform: 'rotate(180deg)',
      })
    ),
    state(
      'default',
      style({
        transform: 'initial',
      })
    ),
    transition('default <=> 180', animate('125ms ease-in')),
    transition('* => *', animate('0ms')),
  ]),
  fadeAndSlide: trigger('fadeAndSlide', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateY(-100%)' }),
      animate(250, style({ opacity: 1, transform: 'translateY(0%)' })),
    ]),
    transition(':leave', [
      animate(250, style({ opacity: 0, transform: 'translateY(-100%)' })),
    ]),
  ]),
  showHide: trigger('showHide', [
    state(
      'show',
      style({
        height: '*',
      })
    ),
    state(
      'hide',
      style({
        height: '0px',
      })
    ),
    transition('show => hide', animate('250ms ease-in-out')),
    transition('hide => show', animate('250ms ease-in-out')),
  ]),
};
