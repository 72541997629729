import {
  CustomerBrand,
  Language,
} from '../../../services/session/models/session-record';
import {
  MaterialAdditionalInfo,
  MaterialAttribute,
} from '../../../../shared/models/material-additional-info';
import { MaterialInfo } from '../../../../shared/models/material-info';
import { MaterialMovementDetails } from '../../../../shared/models/material-movement-entry';
import { NutritionLabel } from '../../mygfs-nutrition/models/mygfs-nutrition';
import { FulfillmentType } from '../../../services/cart/models/cart-record';
import { MaterialRecommendations } from '../../../../shared/services/material-recommendations/models/material-recommendations';
import { MaterialRow } from '../../material-row/models/material-row';
import { MaterialAvailability } from '../../../../shared/models/material-availability';

export interface MaterialDetailsPageViewModel {
  cartFulfillmentType?: FulfillmentType;
  customerBrand?: CustomerBrand;
  customerMaterialNumber?: string;
  currentLanguage?: Language;
  displayableDimensionsCm?: string;
  displayableDimensionsIn?: string;
  gtin?: string;
  isGfsUsCustomerBrand?: boolean;
  materialAdditionalInfo?: MaterialAdditionalInfo;
  materialAdditionalInfoList: MaterialDetailsSection[];
  materialAttributes?: MaterialAttribute[];
  materialAvailability?: MaterialAvailability;
  materialInfo?: MaterialInfo;
  materialMovementDetails?: MaterialMovementDetails;
  materialRow?: MaterialRow;
  nutritionLabel?: NutritionLabel;
  recommendedMaterials?: MaterialRecommendations[];
  orderableComplimentaryMaterials?: string[];
}

export enum MaterialDetailsSection {
  Allergens = 'allergens',
  ForMoreInformation = 'forMoreInformation',
  Ingredients = 'ingredients',
  MarketingTips = 'marketingTips',
  MaterialAttributes = 'materialAttributes',
  MaterialDimensions = 'materialDimensions',
  MaterialMovement = 'materialMovement',
  MaterialYield = 'materialYield',
  NutritionFacts = 'nutritionFacts',
  NutritionFactsPrint = 'nutritionFactsPrint',
  PreparationSuggestions = 'preparationSuggestions',
  SchoolEquivalents = 'schoolEquivalents',
  VitaminsAndMinerals = 'vitaminsAndMinerals',
  SellingBullets = 'sellingBulletPoints',
  SellingParagraphs = 'sellingParagraphs',
  ServingSuggestions = 'servingSuggestions',
  ShelfLife = 'shelfLife',
  ThawInstructions = 'thawInstructions',
  VendorInfo = 'vendorInfo',
}
