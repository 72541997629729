import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Language } from '../../services/session/models/session-record';
import {
  NotificationMethodCode,
  ReminderPreference,
} from '../../../account/preferences/shared/models/customer-notifications';
import {
  CustomerNotificationPreferencesRecord,
  NotificationSubscriptionType,
} from '../../services/customer-notification-preferences/models/customer-notification-preferences-record';

export class CustomerNotificationsConstants {
  public static newNotificationOrdinal = -1;
  public static noneSelectedOrdinal: number = undefined;
}

export interface CustomerNotificationPreferencesState {
  hasError?: boolean;
  hasRefreshed?: boolean;
  customerNotificationPreferences?: CustomerNotificationPreferencesRecord;
  email: NotificationsState;
  sms: NotificationsState;
}

export interface NotificationsState {
  notifications: EntityState<NotificationEntryState>;
  editingOrdinal?: number;
  hasLoaded: boolean;
  nextOrdinal: number;
}

export interface NotificationEntryState {
  type: NotificationMethodCode;
  ordinal: number;
  contactInfo: string;
  name?: string;
  languages?: Language[];
  subscriptionTypes?: NotificationSubscriptionType[];
  customerReminders?: ReminderPreference[];
}

export const customerNotificationEntryAdapter = createEntityAdapter<NotificationEntryState>(
  {
    selectId: (notification) => notification.ordinal,
  }
);

export const initialContactInfo: NotificationsState = {
  notifications: customerNotificationEntryAdapter.getInitialState(),
  editingOrdinal: CustomerNotificationsConstants.noneSelectedOrdinal,
  hasLoaded: false,
  nextOrdinal: 0,
};

export const initialCustomerNotificationsState: CustomerNotificationPreferencesState = {
  customerNotificationPreferences: undefined,
  hasError: false,
  hasRefreshed: false,
  email: initialContactInfo,
  sms: initialContactInfo,
};
