import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderItem } from '../shared/models/order-confirmation';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import {
  MaterialRow,
  MaterialRowContext,
} from '../../core/store/material-row/models/material-row';
import { MaterialRecommendationsFacade } from '../../core/store/material-recommendations/material-recommendations.facade';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MaterialRecommendations } from '../../shared/services/material-recommendations/models/material-recommendations';
import { MaterialRelatedFacade } from '../../core/store/material-related/material-related.facade';
import { MaterialAvailabilityFacade } from '../../core/store/material-availability/material-availability.facade';
import { MaterialInfoFacade } from '../../core/store/material-info/material-info.facade';
import { MaterialPriceFacade } from '../../core/store/material-price/material-price.facade';
import { LastOrderedFacade } from '../../core/store/last-ordered/last-ordered.facade';
import { SessionFacade } from '../../core/store/session/session.facade';
import { takeUntil } from 'rxjs/operators';
import { CurrentSystem } from '../../core/services/session/models/session-record';
import { MaterialRelated } from '../../core/store/material-related/models/material-related';
import { MaterialRowFacade } from '../../core/store/material-row/material-row.facade';
import { Page } from '../../core/store/material-recommendations/material-recommendations.state';

@Component({
  selector: 'naoo-material-related-container',
  template: `
    <naoo-material-related-content
      [itemException]="data.itemException"
      [materialWithSubstitutes]="materialWithSubstitutes"
      [isLoading]="materialRecommendationsLoading$ | async"
      [isMobile]="data.isMobile"
      [materialRecommendations]="materialRecommendations$ | async"
      (closeButtonClicked)="closeModal()"
    >
    </naoo-material-related-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialRelatedContainerComponent implements OnInit, OnDestroy {
  private readonly openAnalyticsLabel = 'open find substitutes modal';
  private readonly closeAnalyticsLabel = 'close find substitutes modal';

  private destroyed$ = new Subject<void>();
  private materialRow: MaterialRow;

  materialRecommendations$: Observable<MaterialRecommendations[]>;
  materialRecommendationsLoading$: Observable<boolean>;

  /**
   * @deprecated remove in NAOO-31456 when Retalix is no longer supported
   */
  materialRelatedDataLoaded: boolean;

  // eslint-disable-next-line max-params
  constructor(
    public dialogRef: MatDialogRef<MaterialRelatedContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      itemException: OrderItem;
      materialWithSubstitutes: MaterialRow;
      isMobile: boolean;
    },
    private materialRelatedFacade: MaterialRelatedFacade,
    private materialAvailabilityFacade: MaterialAvailabilityFacade,
    private materialInfoFacade: MaterialInfoFacade,
    private materialPriceFacade: MaterialPriceFacade,
    private lastOrderedFacade: LastOrderedFacade,
    private analytics: NaooAnalyticsManager,
    private sessionFacade: SessionFacade,
    private materialRecommendationsFacade: MaterialRecommendationsFacade,
    private materialRowFacade: MaterialRowFacade
  ) {}

  ngOnInit(): void {
    this.trackAnalytics(this.openAnalyticsLabel);
    this.sessionFacade
      .getLoadedCurrentSystem()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currentSystem) => {
        if (CurrentSystem.Retalix === currentSystem) {
          this.initLegacyForRetalix();
        } else {
          this.init();
        }
      });

    if (!this.data.materialWithSubstitutes) {
      this.materialRowFacade
        .getMaterialRow({
          context: MaterialRowContext.Substitutes,
          materialNumber: this.materialNumber,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (materialRow: MaterialRow) => (this.materialRow = materialRow)
        );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  closeModal(): void {
    this.trackAnalytics(this.closeAnalyticsLabel);
    this.dialogRef.close();
  }

  get materialNumber(): string {
    if (this.data.itemException) {
      return this.data.itemException.itemDetail.id;
    } else {
      return this.data.materialWithSubstitutes.materialRowOptions
        .materialNumber;
    }
  }

  get materialWithSubstitutes(): MaterialRow {
    return this.data.materialWithSubstitutes || this.materialRow;
  }

  private init(): void {
    this.materialRecommendationsFacade.loadMaterialRecommendations(
      Page.RELATED,
      this.materialNumber
    );
    this.materialRecommendations$ = this.materialRecommendationsFacade.getLoadedMaterialRecommendations(
      Page.RELATED,
      true
    );
    this.materialRecommendationsLoading$ = this.materialRecommendationsFacade.isMaterialRecommendationsLoading(
      Page.RELATED
    );
  }

  /**
   * @deprecated remove in NAOO-31456 when Retalix is no longer supported
   */
  private initLegacyForRetalix(): void {
    const materialRecommendationsSubject$ = new BehaviorSubject<
      MaterialRecommendations[]
    >([]);
    const materialRecommendationsLoadingSubject$ = new BehaviorSubject<boolean>(
      true
    );

    this.materialRecommendations$ = materialRecommendationsSubject$;
    this.materialRecommendationsLoading$ = materialRecommendationsLoadingSubject$;

    this.materialRelatedFacade.loadMaterialRelated([this.materialNumber]);
    this.materialRelatedFacade
      .getMaterialRelated(this.materialNumber)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((materialRelated) => {
        if (materialRelated.isLoading) {
          this.loadMaterialDataIfNeeded(materialRelated);
        }
        materialRecommendationsSubject$.next([
          {
            name: {
              en: 'related_en_rec_1',
              fr: 'related_fr_rec_1',
            },
            title: {
              en: 'Similar Items',
              fr: 'Produits Similaires',
            },
            materialListRows: materialRelated.availableRelated,
          },
        ]);
        materialRecommendationsLoadingSubject$.next(false);
      });
  }

  /**
   * @deprecated remove in NAOO-31456 when Retalix is no longer supported
   */
  private loadMaterialDataIfNeeded(materialRelated: MaterialRelated) {
    if (
      this.materialRelatedDataLoaded ||
      materialRelated.availableRelated.length === 0
    ) {
      return;
    }
    this.materialRelatedDataLoaded = true;

    const materialNumbers = materialRelated.availableRelated.map(
      (substitute) => substitute.value as string
    );
    this.materialAvailabilityFacade.loadMaterialAvailabilities(materialNumbers);
    this.materialInfoFacade.loadMaterialInfos(materialNumbers);
    this.materialPriceFacade.loadMaterialPrices(materialNumbers);
    this.lastOrderedFacade.loadLastOrdered(materialNumbers);
  }

  private trackAnalytics(labelPrefix: string): void {
    this.analytics.trackAnalyticsEvent({
      action: 'click',
      category: 'Ecommerce',
      label: `${labelPrefix} - ${this.materialNumber}`,
    });
  }
}
