import { Injectable } from '@angular/core';
import { NaooHttpClient } from '../../../shared/http-client/naoo-http-client';
import { WebBffService } from '../../../shared/services/web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { StorePurchaseDetailsRecord } from './model/store-purchase-details-record';
import { StorePurchaseKey } from '../../../shared/models/store-purchase-key';

interface StorePurchaseDetailsRequest extends StorePurchaseKey {}

@Injectable({ providedIn: 'root' })
export class StorePurchaseDetailsService {
  constructor(
    private naooHttpClient: NaooHttpClient,
    private bffService: WebBffService
  ) {}

  getStorePurchaseDetails(
    storePurchaseDetailsRequest: StorePurchaseDetailsRequest
  ): Observable<StorePurchaseDetailsRecord> {
    return this.naooHttpClient.post<StorePurchaseDetailsRecord>(
      `${this.bffService.getBff()}/api/v1/orders/store-purchase-details`,
      storePurchaseDetailsRequest
    );
  }
}
