import { Injectable } from '@angular/core';
import {
  OrderInfo,
  OrderStatus,
  StoreFulfillmentDetails,
} from '../../../shared/models/order-info';
import { StatusSeverity } from '../../../shared/models/status-severity';
import {
  OrderType,
  RequestedDeliveryType,
} from '../../../shared/models/order-type';
import { NaooStringDefaulter } from '../../../shared/string-defaulter/naoo-string-defaulter';
import { NaooPricePipe } from '../../../shared/pipes/naoo-price.pipe';
import { SessionFacade } from '../../../core/store/session/session.facade';
import { OrderHistoryViewModel } from './order-history-view-model';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { NaooDecimalPipe } from 'src/app/shared/pipes/naoo-decimal.pipe';
import { CurrentSystem } from '../../../core/services/session/models/session-record';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from '../../../shared/services/date/date.service';

export const NON_BREAKING_SPACE_REGEX = /\u00A0/g;
export const TBD_KEY = 'ORDERS.HISTORY.TBD';
export const TRACKING_AVAILABLE_KEY =
  'ORDERS.HISTORY.TRACKING_AVAILABLE_MESSAGE';

@Injectable({ providedIn: 'root' })
export class OrderHistoryTransformationService {
  private naooStringDefaulter: NaooStringDefaulter;
  private naooPricePipe: NaooPricePipe;
  private naooDecimalPipe: NaooDecimalPipe;
  private userTimeZone: string;
  private currentSystem: CurrentSystem;

  constructor(
    private sessionFacade: SessionFacade,
    private localizationService: LocalizationService,
    private translateService: TranslateService,
    private dateService: DateService
  ) {
    this.naooStringDefaulter = new NaooStringDefaulter();
    this.naooPricePipe = new NaooPricePipe(this.localizationService);
    this.naooDecimalPipe = new NaooDecimalPipe(this.localizationService);

    this.sessionFacade.getLoadedActiveCustomerTimeZone().subscribe((ctz) => {
      this.userTimeZone = ctz;
    });

    this.sessionFacade.getLoadedCurrentSystem().subscribe((currentSystem) => {
      this.currentSystem = currentSystem;
    });
  }

  transform(order: OrderInfo): OrderHistoryViewModel {
    const calculatedDate = this.getCalculatedDate(order);
    const viewModel: OrderHistoryViewModel = {
      isTrackingAvailable: !!order.freightInformation?.[0]?.trackingNumbers
        ?.length,
      orderNumber: this.generateOrderNumber(order),
      orderType: order.orderType,
      submittedUserName: order.submittedUserName,
      orderDate: order.orderDate,
      orderDateTime: order.orderDateTime
        ? this.dateService.getLocalDateTime(
            order.orderDateTime,
            'monthDayTime',
            this.userTimeZone,
            false
          )
        : this.dateService.getLocalDateTime(
            order.orderDate,
            'monthDay',
            this.userTimeZone,
            false
          ),
      freightCarrier: order.freightInformation?.[0]?.freightCarrier,
      customerPurchaseOrder: order.customerPurchaseOrder,
      orderTypeKey: OrderHistoryTransformationService.getOrderTypeText(
        order.orderType,
        order.storeFulfillment?.requestedDeliveryType
      ),
      deliveryInfoDate: this.getDeliveryInfoDate(calculatedDate, order),
      deliveryInfoTime: this.getDeliveryInfoTime(order),
      deliveryInfoTracking: this.getDeliveryInfoTracking(order),
      totalWeight: order.totalWeight,
      totalWeightFull: this.getTotalWeightFull(
        order.totalWeight,
        order.totalWeightUom
      ),
      totalWeightTruncated: this.getTotalWeightTruncated(
        order.totalWeight,
        order.totalWeightUom,
        11
      ),
      totalPrice:
        CurrentSystem.Sap === this.currentSystem
          ? order.totalNetPrice
          : order.totalExtendedPrice,
      calculatedDate,
      orderStatusIcon: OrderHistoryTransformationService.getOrderStatusIcon(
        order
      ),
      orderStatusTooltip: order.orderBlockDescription,
      orderStatusText: order.orderStatusDescription,
      orderStatusShippingMessageKey: this.getOrderStatusShippingMessage(order),
      orderStatusIconColor: OrderHistoryTransformationService.getIconColor(
        order
      ),
      orderStatusTextColor: OrderHistoryTransformationService.getStatusTextColor(
        order
      ),
      searchableStrings: [''],
      routeParams: OrderHistoryTransformationService.getRouteParams(order),
    };

    viewModel.searchableStrings = this.generateSearchableStrings(viewModel);

    return viewModel;
  }

  private getDeliveryInfoDate(
    calculatedDate: string,
    order: OrderInfo
  ): string {
    if (
      CurrentSystem.Sap === this.currentSystem &&
      order.orderType === OrderType.Stock &&
      !!order?.shippedOnDate
    ) {
      return this.dateService.getLocalDateTime(
        order.shippedOnDate,
        'monthDay',
        this.userTimeZone,
        false
      );
    }
    return this.dateService.getLocalDateTime(
      calculatedDate,
      'monthDay',
      this.userTimeZone,
      false
    );
  }

  private getDeliveryInfoTime(order: OrderInfo): string {
    switch (this.currentSystem) {
      case CurrentSystem.Mygfs:
      case CurrentSystem.Retalix:
        return this.getNonSapDeliveryInfoTime(order);
      case CurrentSystem.Sap:
        return this.getSapDeliveryInfoTime(order);
      default:
        return null;
    }
  }

  private getDeliveryInfoTracking(order: OrderInfo): string {
    if (CurrentSystem.Sap === this.currentSystem) {
      return null;
    }
    const shippingMessage = this.getOrderStatusShippingMessage(order);
    const translatedShippingMessage = shippingMessage
      ? this.translateService.instant(shippingMessage)
      : undefined;
    const isTrackingAvailable = !!order.freightInformation?.[0]?.trackingNumbers
      ?.length;
    const translatedTrackingMessage = this.translateService.instant(
      TRACKING_AVAILABLE_KEY
    );

    if (isTrackingAvailable && translatedShippingMessage) {
      return `${translatedShippingMessage} · ${translatedTrackingMessage}`;
    } else if (isTrackingAvailable) {
      return translatedTrackingMessage;
    } else if (translatedShippingMessage) {
      return translatedShippingMessage;
    } else {
      return null;
    }
  }

  private getNonSapDeliveryInfoTime(order: OrderInfo): string {
    if (
      this.hasOrderStatus(order, [
        OrderStatus.CancelledByCustomer,
        OrderStatus.CancelledByRep,
        OrderStatus.CancelledByVendor,
        OrderStatus.Rejected,
      ]) ||
      !OrderHistoryTransformationService.hasValidEstimatedDeliveryTime(
        order.estimatedDeliveryTime
      )
    ) {
      return null;
    }
    return this.dateService.getTimeFromDate(
      order.estimatedDeliveryDate,
      order.estimatedDeliveryTime,
      this.userTimeZone
    );
  }

  private getSapDeliveryInfoTime(order: OrderInfo): string {
    const { orderType, storeFulfillment } = order;
    const requestedDeliveryType = storeFulfillment?.requestedDeliveryType;
    const isStoreFulfillment = OrderType.StoreFulfillment === orderType;

    if (
      isStoreFulfillment &&
      RequestedDeliveryType.EXPRESS === requestedDeliveryType
    ) {
      return this.getDeliveryTimeForExpress(
        storeFulfillment,
        this.userTimeZone
      );
    } else if (isStoreFulfillment) {
      // Assume it is ISPU if it is not RequestedDeliveryType.EXPRESS
      return this.getDeliveryTimeForPickup(storeFulfillment, this.userTimeZone);
    }
    return null;
  }

  private getDeliveryTimeForExpress(
    storeFulfillment: StoreFulfillmentDetails,
    timezone: string
  ): string {
    return this.dateService.getTimeRange(
      storeFulfillment.deliveryWindowStartTimestamp,
      storeFulfillment.deliveryWindowEndTimestamp,
      timezone
    );
  }

  private getDeliveryTimeForPickup(
    storeFulfillment: StoreFulfillmentDetails,
    timezone: string
  ): string {
    return this.dateService.getLocalDateTime(
      storeFulfillment.requestedPickupTimestamp,
      'time',
      timezone
    );
  }

  private roundTotalWeight(totalWeight: number): string {
    return this.naooDecimalPipe.transform(totalWeight, '1.2-2');
  }

  private getTotalWeightFull(
    totalWeight: number,
    totalWeightUom: string
  ): string {
    return totalWeight
      ? `${this.roundTotalWeight(totalWeight)} ${totalWeightUom}`
      : '';
  }

  private getTotalWeightTruncated(
    totalWeight: number,
    totalWeightUom: string,
    truncateToLength: number
  ): string {
    if (!totalWeight) {
      return '';
    }
    const roundTotalWeight = this.roundTotalWeight(totalWeight);
    if (roundTotalWeight.length <= truncateToLength) {
      return `${roundTotalWeight} ${totalWeightUom}`;
    }

    const part1 = roundTotalWeight.substr(0, 4).trim();
    const part2 = roundTotalWeight.substr(roundTotalWeight.length - 4);
    return `${part1}...${part2} ${totalWeightUom}`;
  }

  private hasOrderStatus(order: OrderInfo, orderStatuses: string[]): boolean {
    return orderStatuses.some((status) => status === order.orderStatus);
  }

  private static getOrderStatusIcon(order: OrderInfo): string {
    const orderType = order.orderType;
    const orderStatus = order.orderStatus;
    const orderSeverity = order.orderStatusSeverity;

    switch (orderSeverity) {
      case StatusSeverity.Success:
        if (orderStatus === OrderStatus.Delivered) {
          return 'delivered-icon';
        }
        if (orderStatus === OrderStatus.Invoiced) {
          return 'invoiced-icon-v1';
        }
        if (
          orderType === OrderType.DropShip ||
          orderType === OrderType.NonStock
        ) {
          if (orderStatus === OrderStatus.EnRoute) {
            return 'shipping-icon-v1';
          }
          return 'clock-icon-v1';
        }
        return 'success-icon-v1';
      case StatusSeverity.Warning:
        return 'warning-icon-v3';
      case StatusSeverity.Error:
        return 'exception-icon-v1';
      default:
        return 'clock-icon-v1';
    }
  }

  private getOrderStatusShippingMessage(order: OrderInfo): string | null {
    if (order.orderType === OrderType.DropShip) {
      return OrderHistoryTransformationService.getOrderStatusShippingMessageDropShip(
        order
      );
    } else {
      return this.getOrderStatusShippingMessageNonDropShip(order);
    }
  }

  private getOrderStatusShippingMessageNonDropShip(
    order: OrderInfo
  ): string | null {
    if (
      OrderHistoryTransformationService.hasValidEstimatedDeliveryTime(
        order.estimatedDeliveryTime
      ) ||
      this.hasOrderStatus(order, [
        OrderStatus.Rejected,
        OrderStatus.CancelledByCustomer,
        OrderStatus.CancelledByRep,
        OrderStatus.CancelledByVendor,
        OrderStatus.Invoiced,
      ])
    ) {
      return null;
    }
    return TBD_KEY;
  }

  private static getOrderStatusShippingMessageDropShip(
    order: OrderInfo
  ): string | null {
    const orderStatus = order.orderStatus;
    switch (orderStatus) {
      case OrderStatus.Requested:
        return TBD_KEY;
      case OrderStatus.OnOrder:
        return 'ORDERS.HISTORY.ON_ORDER_SHIPPING_MESSAGE';
      case OrderStatus.EnRoute:
        return 'ORDERS.HISTORY.EN_ROUTE_SHIPPING_MESSAGE';
      default:
        return null;
    }
  }

  private static hasValidEstimatedDeliveryTime(time: string): boolean {
    return time != null && time.trim().length > 0;
  }

  private static getIconColor(order: OrderInfo): string {
    const orderStatus = order.orderStatus;
    const orderType = order.orderType;
    const orderStatusSeverity = order.orderStatusSeverity;

    switch (orderStatusSeverity) {
      case StatusSeverity.Success:
        if (orderStatus === OrderStatus.Invoiced) {
          return 'purple';
        }
        if (
          orderStatus === OrderStatus.Delivered ||
          orderType === OrderType.NonStock ||
          orderType === OrderType.DropShip
        ) {
          return 'gray';
        }
        return 'green';
      case StatusSeverity.Warning:
        return 'yellow';
      case StatusSeverity.Error:
        return 'red';
      default:
        return 'gray';
    }
  }

  private static getStatusTextColor(order: OrderInfo): string {
    const orderStatusSeverity = order.orderStatusSeverity;
    if (orderStatusSeverity === StatusSeverity.Error) {
      return 'red';
    }
    return 'gray';
  }

  private static getOrderTypeText(
    orderType: string,
    requestedDeliveryType?: string
  ): string | null {
    switch (orderType) {
      case OrderType.Stock:
        return 'ORDERS.TYPES.STANDARD';
      case OrderType.DropShip:
        return 'ORDERS.TYPES.DROP_SHIP';
      case OrderType.NonStock:
        return 'ORDERS.TYPES.SPECIAL_ORDER';
      case OrderType.AutoShip:
        return 'ORDERS.TYPES.AUTO_SHIP';
      case OrderType.StoreFulfillment:
        return RequestedDeliveryType.EXPRESS === requestedDeliveryType
          ? 'ORDERS.TYPES.EXPRESS'
          : 'ORDERS.TYPES.ISPU';
      case OrderType.Invoiced:
      default:
        return null;
    }
  }

  private static getRouteParams(order: OrderInfo): string[] {
    const routeParams = [
      order.orderNumber,
      'details',
      order.orderType.toLowerCase(),
    ];
    if (order.groupNumber) {
      routeParams.push(order.groupNumber);
    }

    return routeParams;
  }

  private generateOrderNumber(order: OrderInfo): string {
    let orderNumber = order.orderNumber;
    if (order.groupNumber) {
      orderNumber += `-${order.groupNumber}`;
    } else if (
      OrderType.DropShip === order.orderType &&
      CurrentSystem.Retalix === this.currentSystem
    ) {
      orderNumber = '';
    }
    return orderNumber;
  }

  private generateSearchableStrings(
    viewModel: OrderHistoryViewModel
  ): string[] {
    const searchableStrings = [
      viewModel.orderNumber,
      viewModel.orderTypeKey
        ? this.localizationService.instant(viewModel.orderTypeKey)
        : null,
      viewModel.customerPurchaseOrder,
      this.naooStringDefaulter.getCurrentLanguageOnlyString(
        viewModel.orderStatusText,
        this.localizationService.currentLanguage
      ),
      viewModel.totalWeight
        ? this.naooDecimalPipe
            .transform(viewModel.totalWeight, '1.0-2')
            .replace(NON_BREAKING_SPACE_REGEX, ' ')
        : null,
      this.naooPricePipe
        .transform(viewModel.totalPrice, undefined, '')
        .replace(NON_BREAKING_SPACE_REGEX, ' '),
      viewModel.submittedUserName,
    ];
    if (viewModel.calculatedDate) {
      searchableStrings.push(
        this.dateService.getLocalDateTime(
          viewModel.calculatedDate,
          'monthDay',
          this.userTimeZone,
          false
        )
      );
    } else {
      searchableStrings.push(this.localizationService.instant(TBD_KEY));
    }
    if (viewModel.orderStatusShippingMessageKey) {
      searchableStrings.push(
        this.localizationService.instant(
          viewModel.orderStatusShippingMessageKey
        )
      );
    }
    if (viewModel.isTrackingAvailable) {
      searchableStrings.push(
        this.localizationService.instant(
          'ORDERS.HISTORY.TRACKING_AVAILABLE_MESSAGE'
        )
      );
    }

    return searchableStrings
      .filter((element) => element && element.length)
      .map((field) => field.toLowerCase());
  }

  private getCalculatedDate(order: OrderInfo): string | null {
    const storeFulfillment = order.storeFulfillment;
    return (
      order.actualDeliveryDate ||
      this.dateService.getDateTimeFromDateAndTime(
        order.estimatedDeliveryDate,
        order.estimatedDeliveryTime,
        this.userTimeZone
      ) ||
      order.estimatedDeliveryDate ||
      order.requestedCustomerArrivalDate ||
      order.shippedOnDate ||
      storeFulfillment?.deliveryWindowStartTimestamp ||
      storeFulfillment?.requestedPickupTimestamp ||
      order.orderDate
    );
  }
}
