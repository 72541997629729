import { createActionGroup, emptyProps } from '@ngrx/store';
import { StorePurchaseHistoryRequest } from '../../services/store-purchase-history/model/store-purchase-history-request';
import { StorePurchaseHistoryRecord } from '../../services/store-purchase-history/model/store-purchase-history-record';

export const StorePurchaseHistoryActions = createActionGroup({
  source: 'Store Purchase History',
  events: {
    'Load Store Purchase History': (startDate: string, endDate: string) => ({
      startDate,
      endDate,
    }),
    'Get Store Purchase History': (
      storePurchaseHistoryRequest: StorePurchaseHistoryRequest
    ) => ({ storePurchaseHistoryRequest }),
    'Get Store Purchase History Success': (
      storePurchaseHistoryRecords: StorePurchaseHistoryRecord[]
    ) => ({ storePurchaseHistoryRecords }),
    'Get Store Purchase History Error': emptyProps(),
    'Clear Store Purchase History': emptyProps(),
  },
});
