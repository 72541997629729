import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EnvironmentSpecificService } from '../../services/environment-specific/environment-specific.service';

@Component({
  selector: 'naoo-credit-card-modal',
  templateUrl: './credit-card-modal.component.html',
  styleUrls: ['./credit-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardModalComponent {
  constructor(
    private dialogRef: MatDialogRef<CreditCardModalComponent>,
    private windowService: Window,
    private environmentSpecificService: EnvironmentSpecificService
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }

  redirectToOnlinePayment(): void {
    this.windowService.open(
      this.environmentSpecificService.getOnlinePaymentUrl()
    );
  }
}
