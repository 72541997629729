import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaterialCutoff } from '../../../shared/models/material-cutoff';
import { Store } from '@ngrx/store';
import { MaterialCutoffActions } from './material-cutoff.actions';
import { selectMaterialCutoff } from './material-cutoff.selectors';
import { SessionFacade } from '../session/session.facade';
import { CurrentSystem } from '../../services/session/models/session-record';

@Injectable({ providedIn: 'root' })
export class MaterialCutoffFacade {
  private currentSystem: CurrentSystem;

  constructor(private store: Store, private sessionFacade: SessionFacade) {
    this.sessionFacade.getLoadedCurrentSystem().subscribe((currentSystem) => {
      this.currentSystem = currentSystem;
    });
  }

  getMaterialCutoffTime(cutoffCode: string): Observable<MaterialCutoff> {
    return this.store.select(selectMaterialCutoff(cutoffCode));
  }

  clearCache() {
    this.store.dispatch(
      MaterialCutoffActions.clearMaterialCutoffs(this.currentSystem)
    );
  }
}
