import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialRelatedRecord } from '../../services/material-related/model/material-related-record';

export interface MaterialRelatedState
  extends EntityState<MaterialRelatedRecordState> {}

export interface MaterialRelatedRecordState {
  materialNumber: string;
  hasLoaded: boolean;
  record?: MaterialRelatedRecord;
}

export const materialRelatedAdapter = createEntityAdapter<MaterialRelatedRecordState>(
  {
    selectId: (state) => state.materialNumber,
  }
);

export const initialMaterialRelatedState = materialRelatedAdapter.getInitialState();
