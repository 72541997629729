import { switchMap, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NavigationLink } from '../../shared/models/navigation-link';
import { MatDialog } from '@angular/material/dialog';
import { PrintService } from '../../shared/services/print/print.service';
import { IconState } from '../../shared/action-icon/action-icon.component';
import { CarouselImage } from '../image-carousel/image-carousel.component';
import { MaterialInfo } from '../../shared/models/material-info';
import { RouterExtrasService } from '../../shared/services/router-extras/router-extras.service';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { Localized } from '../../shared/models/localized';
import { EntitlementFacade } from '../../core/store/entitlement/entitlement.facade';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ProductAttributesModalComponent } from '../product-attributes-modal/product-attributes-modal.component';
import { ProductDetailsUtilities } from '../../shared/utilities/product-details-utilities';
import {
  MaterialDetailsPageViewModel,
  MaterialDetailsSection,
} from '../../core/store/material-details/models/material-details.model';
import { MaterialDetailsFacade } from '../../core/store/material-details/material-details.facade';
import { MaterialRow } from '../../core/store/material-row/models/material-row';
import { ProductDetailsData } from '../product-details-resolver/product-details-resolver';

@Component({
  selector: 'naoo-product-details-main',
  templateUrl: './product-details-main.component.html',
  styleUrls: ['./product-details-main.component.scss'],
})
export class ProductDetailsMainComponent implements OnDestroy, OnInit {
  static readonly LOAD_PRODUCT_INFO_FAILURE_TAG = 'loading product info';

  private readonly isAccordionViewBreakpoint = '(max-width: 1000px)';
  private readonly isMobileBreakpoint = '(max-width: 900px)';

  destroyed$ = new Subject<void>();
  isAccordionView: boolean;
  isMobile: boolean;
  materialRow: MaterialRow;
  printIconCurrentState: IconState;
  selectedCarouselImage: CarouselImage;
  materialDetailsViewModel: MaterialDetailsPageViewModel;

  // eslint-disable-next-line max-params
  constructor(
    public dialog: MatDialog,
    private _window: Window,
    private breakpointObserver: BreakpointObserver,
    private entitlementFacade: EntitlementFacade,
    private localizationService: LocalizationService,
    private printService: PrintService,
    private route: ActivatedRoute,
    private routerExtrasService: RouterExtrasService,
    private materialDetailsFacade: MaterialDetailsFacade
  ) {
    this.setAppDeviceType();
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        switchMap((data) => {
          this.selectedCarouselImage = undefined;
          const routeData = data as { detailsData: ProductDetailsData };
          return this.materialDetailsFacade.getMaterialDetailsPageViewModel(
            routeData.detailsData
          );
        })
      )
      .subscribe((materialDetailsViewModel) => {
        this.materialDetailsViewModel = materialDetailsViewModel;
        this.materialRow = materialDetailsViewModel?.materialRow;
      });

    this.observeBreakpoints();

    this.setupPrint();

    this.setupScroll();
  }

  ngOnDestroy(): void {
    this.printService.tearDown();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get ProductDetailsUtilities(): typeof ProductDetailsUtilities {
    return ProductDetailsUtilities;
  }

  get ProductDetailsSection(): typeof MaterialDetailsSection {
    return MaterialDetailsSection;
  }

  get materialDescription(): Localized<string> | undefined {
    return (
      this.materialDetailsViewModel?.materialAdditionalInfo?.description ??
      this.materialDetailsViewModel?.materialInfo?.description
    );
  }

  get materialInfo(): MaterialInfo {
    return this.materialDetailsViewModel?.materialInfo;
  }

  get displayLoyaltyPoints(): boolean {
    return !!(
      this.materialRow?.materialRowOptions.isLoyaltyProgramEligible &&
      this.materialRow.materialOrdering?.materialOrderingInfos?.[0]
        ?.loyaltyPoints
    );
  }

  isFallbackImage(): boolean {
    const images = this.materialDetailsViewModel?.materialAdditionalInfo
      ?.images;
    return !images?.en?.length && !images?.fr?.length;
  }

  isiOS(): boolean {
    return navigator.userAgent?.match(/ipad|ipod|iphone/i) != null;
  }

  onImageSelect(image: CarouselImage): void {
    this.selectedCarouselImage = image;
  }

  openCommodityDocket(): void {
    this.entitlementFacade.setSelectedMaterialNumber(
      this.materialDetailsViewModel?.materialInfo?.materialNumber
    );
  }

  openInfoModal(): void {
    this.dialog.open(ProductAttributesModalComponent, {
      id: 'product-attributes-modal',
      disableClose: true,
      panelClass: 'product-attributes-modal',
      data: {
        materialAttributes: this.materialDetailsViewModel?.materialAttributes,
      },
    });
  }

  printMaterialDetails(): void {
    this.printService.print();
  }

  getBackNavigationLink(): NavigationLink {
    return {
      name: `${this.localizationService.instant('NAVIGATION.BACK')}`,
    };
  }

  private observeBreakpoints(): void {
    this.breakpointObserver
      .observe([this.isMobileBreakpoint, this.isAccordionViewBreakpoint])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        const breakpoints = result.breakpoints;
        this.isMobile = breakpoints[this.isMobileBreakpoint];
        this.isAccordionView = breakpoints[this.isAccordionViewBreakpoint];
      });
  }

  private setAppDeviceType(): void {
    if (this.isiOS()) {
      const selected = Array.from(document.querySelectorAll('input'));
      document.body.addEventListener('touchstart', (event: TouchEvent) => {
        if (
          selected.filter((i) => i.contains(event.target as Node)).length ===
            0 &&
          document.activeElement instanceof HTMLElement
        ) {
          document.activeElement.blur();
        }
      });
    }
  }

  private setupPrint(): void {
    this.printIconCurrentState = IconState.Enabled;
    this.printService.setUp();
  }

  private setupScroll(): void {
    this.routerExtrasService.setScrollY(this._window.location.href, 0);
  }

  get unorderableText(): string {
    const salesStatus = this.materialDetailsViewModel?.materialAvailability
      ?.salesStatus?.[this.materialDetailsViewModel.currentLanguage];
    return salesStatus ?? 'PRODUCT.NOT_ORDERABLE';
  }
}
