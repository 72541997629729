import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DeviceIdentifierService } from '../services/device-identifier/device-identifier.service';
import { SearchableCustomer } from '../models/searchable-customer';
import { SessionCustomer } from '../../core/services/session/models/session-record';

export interface CustomerUnit {
  customerName: string;
  customerId: string;
  compositeId?: string;
  customerAddress: string;
  initialSelect?: boolean;
}

export interface CustomerSelectOptions {
  cardContainerHeight?: string;
  cardContainerHeightMobile?: string;
  rowHeight?: string;
  overflowY?: string;
  cols?: number;
  showTitle?: boolean;
}

@Component({
  selector: 'naoo-multi-customer-select',
  templateUrl: './multi-customer-select.component.html',
  styleUrls: ['./multi-customer-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiCustomerSelectComponent implements OnInit, OnDestroy {
  @Input() customerUnits: CustomerUnit[] = [];
  @Input() options: CustomerSelectOptions;
  @Output() selectedCustomers = new EventEmitter<CustomerUnit[]>();

  isMobile: boolean;
  customersForm: FormGroup;
  destroyed$ = new Subject();
  defaultOptions: CustomerSelectOptions = {
    cardContainerHeight: '134px',
    cardContainerHeightMobile: '134px',
    rowHeight: '65',
    overflowY: 'initial',
    cols: 3,
    showTitle: true,
  };
  numberOfColumns = new BehaviorSubject<number>(this.defaultOptions.cols);

  searchableCustomers: SearchableCustomer[] = [];
  searchText: string;
  customerFilter = { searchableCustomerAttributes: '' };

  get chosenCustomers(): CustomerUnit[] {
    const chosen = [];
    const customerUnits = this.customersForm.controls
      .customerUnits as FormArray;
    for (let i = 0; i < customerUnits.value.length; i++) {
      if (customerUnits.value[i]) {
        chosen.push(this.customerUnits[i]);
      }
    }
    return chosen;
  }

  constructor(private deviceIdentifier: DeviceIdentifierService) {}

  ngOnInit() {
    this.setDefaultOptions();
    this.customersForm = new FormGroup({
      selectAll: new FormControl(),
      customerUnits: new FormArray(
        this.customerUnits.map((customer) => {
          return new FormControl(customer.initialSelect);
        })
      ),
    });

    this.customersForm.controls.customerUnits.valueChanges
      .pipe(
        debounceTime(0), // This allows the `selectAll` method to debounce our setValue calls
        takeUntil(this.destroyed$)
      )
      .subscribe((toggled) => {
        this.customerToggled(toggled);
      });
    this.customerToggled(this.customersForm.controls.customerUnits.value); // Trigger toggle logic post-initialization

    this.customerUnits.forEach((customerUnit) => {
      const customer = {
        customerDisplayId: customerUnit.customerId,
        name: customerUnit.customerName,
        address: { line2: customerUnit.customerAddress },
      } as SessionCustomer;
      this.searchableCustomers.push(new SearchableCustomer(customer));
    });

    this.deviceIdentifier
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.options.overflowY = isMobile
          ? this.options.overflowY
          : this.defaultOptions.overflowY;
        this.options.cardContainerHeight = isMobile
          ? this.options.cardContainerHeightMobile
          : this.options.cardContainerHeight;
        this.setNumberOfGridColumns(isMobile);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  toggleSelectAll(checkboxChanged: MatCheckboxChange) {
    if (this.customerFilter.searchableCustomerAttributes.length === 0) {
      this.selectAll(checkboxChanged.checked);
    } else {
      this.toggleFiltered(checkboxChanged.checked);
    }
  }

  customerToggled(toggled?: boolean[]) {
    if (
      toggled &&
      this.customerFilter.searchableCustomerAttributes.length === 0
    ) {
      const allSelected = !toggled.some((bool) => !bool);
      this.customersForm.controls.selectAll.setValue(allSelected);
    } else {
      const filtered = this.searchableCustomers.filter((searchableCustomer) =>
        searchableCustomer.searchableCustomerAttributes
          .toUpperCase()
          .includes(
            this.customerFilter.searchableCustomerAttributes.toUpperCase()
          )
      );
      const allSelected =
        filtered.length === filtered.filter((item) => item.isChecked).length;
      this.customersForm.controls.selectAll.setValue(allSelected);
    }
    if (toggled) {
      this.emitSelectedCustomers();
    }
  }

  clear() {
    if (this.customerFilter.searchableCustomerAttributes.length === 0) {
      this.selectAll(false);
    } else {
      this.toggleFiltered(false);
    }
  }

  updateFilter(searchText: string) {
    this.searchText = searchText;
    this.customerFilter.searchableCustomerAttributes = searchText;
    this.customerToggled();
  }

  private setNumberOfGridColumns(isMobile: boolean) {
    if (isMobile) {
      this.numberOfColumns.next(1);
    } else {
      this.numberOfColumns.next(this.options.cols);
    }
  }

  private selectAll(select: boolean = true) {
    const customerUnits = this.customersForm.controls
      .customerUnits as FormArray;
    customerUnits.controls.forEach((customerUnit, i) => {
      if (customerUnits.controls.hasOwnProperty(i)) {
        customerUnit.setValue(select);
        this.searchableCustomers[i].isChecked = select;
      }
    });
  }

  private emitSelectedCustomers() {
    this.selectedCustomers.emit(this.chosenCustomers);
  }

  private setDefaultOptions() {
    this.options = Object.assign({}, this.defaultOptions, this.options);
  }

  private toggleFiltered(toggle: boolean) {
    this.searchableCustomers.forEach((customElements, i) => {
      if (
        customElements.searchableCustomerAttributes
          .toUpperCase()
          .includes(
            this.customerFilter.searchableCustomerAttributes.toUpperCase()
          )
      ) {
        this.searchableCustomers[i].isChecked = toggle;
        const customerUnits = this.customersForm.controls
          .customerUnits as FormArray;
        customerUnits.controls.forEach((customerUnit, index) => {
          if (customerUnits.controls.hasOwnProperty(i) && i === index) {
            customerUnit.setValue(toggle);
          }
        });
      }
    });
  }

  valueChange(
    searchCustomers: SearchableCustomer[],
    index: number,
    searchCustomer: SearchableCustomer,
    event: MatCheckboxChange
  ) {
    // set the two-way binding here for the specific unit with the event
    searchCustomer.isChecked = event.checked;
  }
}
