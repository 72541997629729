import { Dictionary, EntityState } from '@ngrx/entity';
import { CartEntityState, CartMaterialState } from '../cart/cart.state';
import { CartSortType } from './active-cart-summary.state';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { MaterialRecord } from '../../services/material-info/models/materials-info-record';
import { Localized } from '../../../shared/models/localized';
import { ActiveCartDetails } from './active-cart-summary.selectors';
import { ActiveCartSummary } from '../../../cart/cart-summary/active-cart/shared/models/active-cart-summary';
import { createMaterialListRow } from '../material-row/models/material-row';
import { Language } from '../../services/session/models/session-record';
import { getCollator } from 'src/app/shared/collator/collator';
import { LocalizedUtilities } from 'src/app/shared/utilities/localized-utilities';

export class ActiveCartSummaryTransformer {
  public static transformActiveCartSummary(
    cartEntityState: CartEntityState,
    infoMap: Dictionary<MaterialInfoRecordState>,
    isQuickAddEnabled: boolean,
    activeCartDetails: ActiveCartDetails,
    isOffline: boolean,
    currentLanguage: Language,
    criticalItems: string[]
  ): ActiveCartSummary {
    if (!cartEntityState?.materials) {
      return undefined;
    }

    const sortBy = activeCartDetails?.sortBy;

    const sortedCartMaterialNumbers = this.sortMaterials(
      cartEntityState.materials,
      sortBy,
      infoMap,
      currentLanguage
    )
      .filter((material) => !material.isAddedFromCriticalItemsSection)
      .map((material) => material.materialNumber);

    return {
      materialNumbers: sortedCartMaterialNumbers.map((materialNumber) =>
        createMaterialListRow(materialNumber)
      ),
      isQuickAddEnabled,
      hasOrderableMaterials: activeCartDetails.orderQuantity.items > 0,
      isOffline: isOffline,
      isPoRequired: activeCartDetails.isPoRequired,
      customerHeaderInfo: activeCartDetails.customerHeaderInfo,
      orderQuantity: activeCartDetails.orderQuantity,
      poNumber: activeCartDetails.poNumber,
      sortBy,
      criticalItems: criticalItems
        .filter(
          (materialNumber) =>
            !sortedCartMaterialNumbers.includes(materialNumber)
        )
        .map((materialNumber) => createMaterialListRow(materialNumber)),
    };
  }

  private static sortMaterials(
    materialsEntityState: EntityState<CartMaterialState>,
    by: CartSortType,
    infoMap: Dictionary<MaterialInfoRecordState>,
    currentLang: Language
  ): CartMaterialState[] {
    let sortByFunction: (
      material1: CartMaterialState,
      material2: CartMaterialState
    ) => number;
    switch (by) {
      case CartSortType.Brand:
        sortByFunction = (
          material1: CartMaterialState,
          material2: CartMaterialState
        ) =>
          this.sortByLocalizedInfoValue(
            material1,
            material2,
            infoMap,
            currentLang,
            (info) => info.brand
          );
        break;
      case CartSortType.Description:
        sortByFunction = (
          material1: CartMaterialState,
          material2: CartMaterialState
        ) =>
          this.sortByLocalizedInfoValue(
            material1,
            material2,
            infoMap,
            currentLang,
            (info) => info.description
          );
        break;
      case CartSortType.ItemCode:
        sortByFunction = this.sortByItemCode;
        break;
      default:
        return materialsEntityState.ids.map(
          (material) => materialsEntityState.entities[material]
        ); // this keeps the as added order
    }
    return Object.values(materialsEntityState.entities).sort(sortByFunction);
  }

  private static sortByItemCode(
    material1: CartMaterialState,
    material2: CartMaterialState
  ) {
    return +material1.materialNumber - +material2.materialNumber;
  }

  private static sortByLocalizedInfoValue(
    material1: CartMaterialState,
    material2: CartMaterialState,
    infoMap: Dictionary<MaterialInfoRecordState>,
    currentLang: Language,
    getInfoValue: (record: MaterialRecord) => Localized<string>
  ): number {
    const material1Info = infoMap[material1.materialNumber];
    const material2Info = infoMap[material2.materialNumber];
    const material1Available = !!material1Info && !!material1Info.record;
    const material2Available = !!material2Info && !!material2Info.record;

    if (material1Available && !material2Available) {
      return -1;
    } else if (!material1Available && material2Available) {
      return 1;
    } else if (!material1Available && !material2Available) {
      return this.sortByItemCode(material1, material2);
    }

    return getCollator(currentLang).compare(
      LocalizedUtilities.getLocalizedStringValue(
        getInfoValue(material1Info.record),
        currentLang
      ),
      LocalizedUtilities.getLocalizedStringValue(
        getInfoValue(material2Info.record),
        currentLang
      )
    );
  }
}
