import { StoreRecord } from '../../../core/services/store/model/store-record';
import { StoreMapMarkerOptions } from '../service/google-maps.service';
import LatLngLiteral = google.maps.LatLngLiteral;
import LatLngBounds = google.maps.LatLngBounds;
import LatLng = google.maps.LatLng;

interface StoreRecordDistance {
  storeRecord: StoreRecord;
  distance: number;
}
const R = 6371000; // meters

export function getLatLngLiteral(
  storeRecord: StoreRecord
): LatLngLiteral | undefined {
  return storeRecord?.address?.coordinates
    ? {
        lat: +storeRecord.address.coordinates.latitude,
        lng: +storeRecord.address.coordinates.longitude,
      }
    : undefined;
}

export function getSortedVisibleStoreRecords(
  storeMapMarkers: StoreMapMarkerOptions[],
  mapBounds: LatLngBounds
): StoreRecord[] {
  const storesWithDistances: StoreRecordDistance[] = [];
  const center: LatLng = mapBounds.getCenter();

  storeMapMarkers.forEach((value) => {
    if (mapBounds?.contains(value.position)) {
      storesWithDistances.push({
        storeRecord: value.storeRecord,
        distance: calculateMetersBetweenLatLngPoints(center, value.position),
      });
    }
  });
  storesWithDistances.sort((a, b) => (a.distance > b.distance ? 1 : -1));
  return storesWithDistances.map(
    (storeWithDistance) => storeWithDistance.storeRecord
  );
}

function calculateMetersBetweenLatLngPoints(
  center: LatLng,
  storeLatLong: LatLngLiteral
): number {
  const φ1 = valueToRadians(center.lat());
  const φ2 = valueToRadians(storeLatLong.lat);
  const Δφ = valueToRadians(storeLatLong.lat - center.lat());
  const Δλ = valueToRadians(storeLatLong.lng - center.lng());

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // return distance
}

function valueToRadians(value: number): number {
  return (value * Math.PI) / 180;
}
